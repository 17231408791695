@import "node_modules/styleguide/src/assets/sass/resources.sass";

.osg-icon-link__link {
  &:visited {
    color: currentColor;
  }
  &:hover {
    color: $osg-color-blue-hover;
  }
  &:focus {
    color: $osg-color-blue-hover;
  }
}
