@import "node_modules/styleguide/src/assets/sass/common.sass";

.hero-picture {
  width: 150px !important;
 // height: 150px;
  object-fit: contain;
  @include osg-mq-tablet-and-up() {
    width: 300px !important;
    // height: 300px;
  }
}
