body:not(.user-is-tabbing) {
  button:active,
  button:focus,
  .osg-button:focus,
  .osg-button:active,
  .button-with-icon-spinner:focus,
  a:focus input:focus,
  input:active input[type="radio"]:focus,
  input[type="checkbox"]:focus,
  textarea:focus,
  area:focus,
  select:focus,
  [tabindex="0"]:focus,
  a[href]:focus,
  area[href]:focus,
  iframe:focus,
  object:focus,
  embed:focus,
  *[tabindex="0"]:focus,
  *[contenteditable]:focus {
    outline: none;
  }
}
body.user-is-tabbing {
  button:active,
  button:focus,
  .osg-button:focus,
  .osg-button:active,
  .button-with-icon-spinner:focus,
  a:focus input:focus,
  input:active input[type="radio"]:focus,
  input[type="checkbox"]:focus,
  textarea:focus,
  area:focus,
  select:focus,
  [tabindex="0"]:focus,
  a[href]:focus,
  area[href]:focus,
  iframe:focus,
  object:focus,
  embed:focus,
  *[tabindex="0"]:focus,
  *[contenteditable]:focus,
  label.checkbox--focus--helper:focus-within {
    outline: none;
    z-index: 1000;
    outline-color: -moz-mac-focusring;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-width: 2px;
    outline-offset: 3px;
    outline-style: solid;
  }

  #maincontent:focus {
    outline: none;
  }

  footer:focus-within {
    [tabindex="0"]:focus,
    a:focus {
      outline-color: #b3f5ff;
    }
  }

  .footer-link:focus-within {
    color: #b3f5ff;
  }
}
