@import "node_modules/styleguide/src/assets/sass/common.sass";
.status-indicator {
  display: block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.stickersection {
  min-height: 350px;
  @include osg-mq-tablet-and-up() {
    min-height: 430px;
  }
}

.sticker-container + .sticker-group {
  margin-top: 10px;
  @include osg-mq-tablet-and-up() {
    margin-top: 20px;
  }
}

.sticker-container {
  display: grid;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  grid-template-columns: 25px 1fr 1fr minmax(min-content, max-content);
  box-sizing: border-box;
  gap: 5px 0px;
  align-content: center;
  @include osg-mq-tablet-and-up() {
    gap: 7px 0px;
  }

  .status-indicator {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    align-self: center;
  }

  .vehicle-regnumber {
    grid-row: 1 / span 1;
    grid-column: 2 / span 2;
    align-self: center;
    font-weight: bolder !important;
  }

  .vehicle-description {
    grid-row: 2 / span 1;
    grid-column: 2 / span 2;

    @include osg-mq-tablet-and-up() {
      display: none;
    }
    font-weight: bolder !important;
  }

  .sticker-name {
    grid-row: 1 / span 1;
    grid-column: 4 / span 1;
    justify-self: end;
    align-self: center;
  }

  .date-of-expire {
    grid-row: 2 / span 1;
    grid-column: 4 / span 1;
    justify-self: end;
    text-align: end;
  }
}
