@import "node_modules/styleguide/src/assets/sass/common.sass";

.section {
  padding-top: $osg-space-4;
  &:first-of-type {
    padding-top: $osg-space-3;
  }
  padding-bottom: $osg-space-6;
  @include osg-mq-tablet-and-up {
    padding-top: $osg-space-6;
  }

}
