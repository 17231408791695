@import "node_modules/styleguide/src/assets/sass/resources.sass";
.link-button {
  background: unset;
  border: unset;
  padding: unset;
  margin: unset;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.5em;
  border: solid currentColor 1em;
  border-width: 0 0 0.15em 0;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    border-color: $osg-color-blue-hover;
    color: $osg-color-blue-hover;
  }

  &:focus {
    color: $osg-color-blue-hover;
    border-color: $osg-color-blue-hover;
  }

  &:disabled {
    cursor: pointer;
    // background-color: #f9f9f9;
    border-color: #e1e1e1;
    color: #e1e1e1;
  }
}
