.overview-sticker-container-min-height {
  min-height: 804px;
}

.overview-tags-container {
  margin: 0;
  width: 100%;
  // min-height: 806px;
}

.overview-tags-loading > div {
  text-align: center;
  margin: 0px auto;
}

.overview-tags-container > thead > tr > th {
  table-layout: fixed;
  padding: 20px 0px;
  border-bottom: 2px solid #2c2c2c;
}
.overview-tags-container > tbody > tr > td {
  padding: 20px 0px;
  border-top: none;
}

.overview-tags-trow {
  border-bottom: 2px solid #e1e1e1;
}

.overview-tags-trow.overview-tags-trow-aapen {
  border: none;
}

.overview-tags-trow > td:first-child {
  padding: 20px 10px 20px 10px;
}

.overview-tags-trow > td {
  border: none;
}

.overview-tags-trow > td:last-child {
  padding: 20px 10px 20px 0px;
}

.overview-tags-trow:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

td.overview-tags-datails-col {
  padding: 0;
}

.overview-tags-trow-aapen > td {
  /* font-weight: bold; */
  background-color: #f9f9f9;
}

.overview-tags-datails-aapen {
  background-color: #f9f9f9;
  border-bottom: solid 1px #d7d7d7;
}

.overview-tags-tbody {
  background-color: #fff;
}

.overview-tags-trow > td {
  vertical-align: middle;
  padding: 10px;
}
.overview-tags-trow > td:last-child {
  text-align: right;
}

.overview-tags-trow.disabled {
  color: #e1e1e1;
}

.overview-tags-trow-detaljerbtn {
  margin-top: -4px;
  padding: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: #0071bb;
}

.overview-tags-trow-detaljerbtn:hover {
  border: none;
  background-color: transparent;
}
.overview-tags-trow-detaljerbtn:focus:active {
  border: none;
  background-color: transparent;
}
.overview-tags-trow-detaljerbtn:focus {
  border: none;
  background-color: transparent;
}
.overview-tags-trow-detaljerbtn:active {
  border: none;
  background-color: transparent;
}

.overview-button-caret {
  font-size: 1.2em !important;
  margin-left: 5px;
  height: 14.5px;
  transition: transform 0.3s ease-out;
}

.overview-button-caret-aapen {
  transform: rotate(-90deg);
}

.overview-button-caret-lukket {
  transform: rotate(90deg);
}

.mobile-only {
  display: none;
}

.oblat-detaljer-row {
  display: flex;
  flex-direction: row;
}
.oblat-detaljer-betaling {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  padding: 20px 0;
  width: 58%;
}
.oblat-detaljer-actions {
  display: flex;
  flex-direction: row;
  width: 42%;
  justify-content: flex-end;
  align-items: flex-end;
}
.oblat-detaljer-actions > button {
  margin: 12px 0 12px 7px;
  padding: 2px 7px;
}

.status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.overview-tags-status-td {
  display: flex;
  flex-direction: row;
  border: none;
}
/* mobile */

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }

  .overview-tags-header {
    padding: 0;
  }
  .overview-tags-header > td {
    padding: 0;
  }

  .oblat-detaljer-row {
    display: flex;
    flex-direction: column;
  }
  .oblat-detaljer-betaling {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
  }
  .oblat-detaljer-actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .oblat-detaljer-actions > button {
    margin: 5px;
  }
}

.minioversikt-table-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.minioversikt-row-oversikt-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.minioversikt-table-row > table {
  width: 100%;
  margin-bottom: 10px;
}
.kursiv-text {
  font-style: italic;
  color: #2b2b2b;
}
.minioversikt-table-row > span {
  margin-bottom: 10px;
  align-self: flex-start;
}

.minioversikt-foot {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.minioversikt-foot-item {
  align-self: flex-end;
}

.minioversikt-table-foot-row {
  text-align: center;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .minioversikt {
    width: 100%;
  }

  .minioversikt:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* x-small */

@media (max-width: 380px) {
  .hide-on-xs {
    display: none;
  }
}

.spinner-div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
