.button-with-icon-spinner {
  position: relative;

  &.loading:not(.circle) > &--spinner {
    display: inline-block;
    font-size: 1.3em;
  }

  &.loading.circle > &--spinner {
    display: inline-block;
    margin: auto;

    font-size: 1.3em;
    width: 1.3em;
    height: 1.3em;
  }
  // &--icon {
  //   position: absolute;
  //   margin: auto;
  //   display: inline-block;
  //   // font-size: 11.3em !important;
  // }
}
