.label {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #2a2859;
  margin-bottom: 25px;
}

.input-element {
  .input {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    display: block;
    box-sizing: border-box;
    height: 52px;
    width: 100%;
    padding: 15px 15px;
    outline: 2px #2a2859 solid;
    outline-offset: -2px;
    font-size: 16px;
    font-family: inherit;
    font-size: inherit;

    &:hover {
      outline-color: #1f42aa;
    }

    &:focus {
      outline: 4px #1f42aa solid;
      outline-offset: -4px;
    }

    &:disabled,
    &:read-only {
      outline: 2px #e1e1e1 solid;
      outline-offset: -2px;
      background-color: #f9f9f9;
    }

    &.state-danger {
      outline-color: #ff8274b3;
    }

    &.state-warning {
      outline-color: #f9c66bb3;
    }

    &.state-success {
      outline-color: #c7f6c9b3;
    }
  }
}
