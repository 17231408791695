@import "~styleguide/src/assets/sass/common/";

.searchfield-wrapper--faq {
  transition: top 0.3s ease-in-out;
  position: sticky;
  top: 125px;
  margin-right: 0;
  margin-left: auto;
  transform: translate(0px, -35px);
  @include osg-mq-tablet-and-up {
    top: 245px;
    transform: translate(-100px, -153px);
    width: calc(100% / 3);
  }
  background-color: white;
  z-index: 500;
}

.user-is-scrolling-down .searchfield-wrapper--faq {
  position: sticky;
  top: 40px;
  @include osg-mq-tablet-and-up {
    top: 160px;
    transform: translate(-100px, -153px);
  }
}

.navbar-has-focus .searchfield-wrapper--faq {
  top: 125px;
  transform: translate(0px, -35px);

  @include osg-mq-tablet-and-up {
    top: 245px;
    transform: translate(-100px, -153px);
    width: calc(100% / 3);
  }
}

.faq-content {
  @include osg-mq-tablet-and-up {
    transform: translate(0px, -55px);
  }
}
