.container {
  width: 100%;
}

@media (min-width: 801px) {
  .container {
    max-width: 801px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1216px) {
  .container {
    max-width: 1216px;
  }
}

@media (min-width: 1408px) {
  .container {
    max-width: 1408px;
  }
}

.heading-1 {
  font-size: 1.75rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.9px;
}

@media (min-width: 801px) {
  .heading-1 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-1 {
    font-size: 3.375rem !important;
  }
}

.heading-2 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px;
}

@media (min-width: 801px) {
  .heading-2 {
    font-size: 1.875rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-2 {
    font-size: 2.25rem !important;
  }
}

.heading-3 {
  font-size: 1.375rem !important;
  font-weight: 400 !important;
  letter-spacing: -0.3px;
}

@media (min-width: 801px) {
  .heading-3 {
    font-size: 1.625rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-3 {
    font-size: 1.875rem !important;
  }
}

.heading-4 {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.3px;
}

@media (min-width: 801px) {
  .heading-4 {
    font-size: 1.375rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-4 {
    font-size: 1.5rem !important;
  }
}

.heading-5 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.3px;
}

@media (min-width: 801px) {
  .heading-5 {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-5 {
    font-size: 1.125rem !important;
  }
}

.heading-modal {
  font-size: 1rem !important;
  font-weight: normal !important;
  letter-spacing: -0.2px;
}

@media (min-width: 801px) {
  .heading-modal {
    font-size: 1.625rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-modal {
    font-size: 1.75rem !important;
  }
}

.heading-modal-sub {
  font-size: .875rem !important;
  font-weight: 300 !important;
  letter-spacing: normal;
}

@media (min-width: 801px) {
  .heading-modal-sub {
    font-size: .875rem !important;
  }
}

@media (min-width: 1024px) {
  .heading-modal-sub {
    font-size: .875rem !important;
  }
}

.text-1 {
  font-size: 1.375rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-1 {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 1024px) {
  .text-1 {
    font-size: 1.875rem !important;
  }
}

.text-2 {
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-2 {
    font-size: 1.375rem !important;
  }
}

@media (min-width: 1024px) {
  .text-2 {
    font-size: 1.5rem !important;
  }
}

.text-3 {
  font-size: 1.125rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-3 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 1024px) {
  .text-3 {
    font-size: 1.25rem !important;
  }
}

.text-4 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-4 {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .text-4 {
    font-size: 1.125rem !important;
  }
}

.text-5 {
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-5 {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .text-5 {
    font-size: 1.125rem !important;
  }
}

.text-6 {
  font-size: .875rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-6 {
    font-size: .875rem !important;
  }
}

@media (min-width: 1024px) {
  .text-6 {
    font-size: .875rem !important;
  }
}

.text-7 {
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
}

@media (min-width: 801px) {
  .text-7 {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .text-7 {
    font-size: 1.125rem !important;
  }
}

.reset-style {
  all: initial;
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(5px * var(--space-y-reverse));
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(5px * var(--space-x-reverse));
  margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10px * var(--space-y-reverse));
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10px * var(--space-x-reverse));
  margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(15px * var(--space-y-reverse));
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(15px * var(--space-x-reverse));
  margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(30px * var(--space-y-reverse));
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(30px * var(--space-x-reverse));
  margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(50px * var(--space-y-reverse));
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(50px * var(--space-x-reverse));
  margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(75px * var(--space-y-reverse));
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(75px * var(--space-x-reverse));
  margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
}

.space-y-7 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(100px * var(--space-y-reverse));
}

.space-x-7 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(100px * var(--space-x-reverse));
  margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
}

.space-y-none > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse));
}

.space-x-none > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
}

.space-y-tiny > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10px * var(--space-y-reverse));
}

.space-x-tiny > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10px * var(--space-x-reverse));
  margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
}

.space-y-small > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(20px * var(--space-y-reverse));
}

.space-x-small > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(20px * var(--space-x-reverse));
  margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
}

.space-y-medium > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(30px * var(--space-y-reverse));
}

.space-x-medium > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(30px * var(--space-x-reverse));
  margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
}

.space-y-large > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(40px * var(--space-y-reverse));
}

.space-x-large > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(40px * var(--space-x-reverse));
  margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
}

.space-y-huge > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(60px * var(--space-y-reverse));
}

.space-x-huge > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(60px * var(--space-x-reverse));
  margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
}

.space-y-margin-label-height > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(26px * var(--space-y-reverse));
}

.space-x-margin-label-height > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(26px * var(--space-x-reverse));
  margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
}

.space-y-nav-height > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(80px * var(--space-y-reverse));
}

.space-x-nav-height > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(80px * var(--space-x-reverse));
  margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-5px * var(--space-y-reverse));
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-5px * var(--space-x-reverse));
  margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10px * var(--space-y-reverse));
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10px * var(--space-x-reverse));
  margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-15px * var(--space-y-reverse));
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-15px * var(--space-x-reverse));
  margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-30px * var(--space-y-reverse));
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-30px * var(--space-x-reverse));
  margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-50px * var(--space-y-reverse));
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-50px * var(--space-x-reverse));
  margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-75px * var(--space-y-reverse));
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-75px * var(--space-x-reverse));
  margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
}

.-space-y-7 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-100px * var(--space-y-reverse));
}

.-space-x-7 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-100px * var(--space-x-reverse));
  margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
}

.-space-y-none > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse));
}

.-space-x-none > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
}

.-space-y-tiny > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10px * var(--space-y-reverse));
}

.-space-x-tiny > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10px * var(--space-x-reverse));
  margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
}

.-space-y-small > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-20px * var(--space-y-reverse));
}

.-space-x-small > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-20px * var(--space-x-reverse));
  margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
}

.-space-y-medium > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-30px * var(--space-y-reverse));
}

.-space-x-medium > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-30px * var(--space-x-reverse));
  margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
}

.-space-y-large > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-40px * var(--space-y-reverse));
}

.-space-x-large > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-40px * var(--space-x-reverse));
  margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
}

.-space-y-huge > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-60px * var(--space-y-reverse));
}

.-space-x-huge > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-60px * var(--space-x-reverse));
  margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
}

.-space-y-margin-label-height > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-26px * var(--space-y-reverse));
}

.-space-x-margin-label-height > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-26px * var(--space-x-reverse));
  margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
}

.-space-y-nav-height > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-80px * var(--space-y-reverse));
}

.-space-x-nav-height > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-80px * var(--space-x-reverse));
  margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1;
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1;
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(0px * var(--divide-y-reverse));
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(2px * var(--divide-y-reverse));
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(4px * var(--divide-y-reverse));
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(8px * var(--divide-y-reverse));
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse));
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1;
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1;
}

.divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #FFFFFF;
  border-color: rgba(255, 255, 255, var(--divide-opacity));
}

.divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2c2c2c;
  border-color: rgba(44, 44, 44, var(--divide-opacity));
}

.divide-black-backdrop > :not(template) ~ :not(template) {
  border-color: #2c2c2c99;
}

.divide-beige > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f8f0dd;
  border-color: rgba(248, 240, 221, var(--divide-opacity));
}

.divide-beige-dark > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d0bfae;
  border-color: rgba(208, 191, 174, var(--divide-opacity));
}

.divide-blue > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6fe9ff;
  border-color: rgba(111, 233, 255, var(--divide-opacity));
}

.divide-blue-dark > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2a2859;
  border-color: rgba(42, 40, 89, var(--divide-opacity));
}

.divide-blue-light > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b3f5ff;
  border-color: rgba(179, 245, 255, var(--divide-opacity));
}

.divide-blue-hover > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1f42aa;
  border-color: rgba(31, 66, 170, var(--divide-opacity));
}

.divide-green > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #43f8b6;
  border-color: rgba(67, 248, 182, var(--divide-opacity));
}

.divide-green-light > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c7f6c9;
  border-color: rgba(199, 246, 201, var(--divide-opacity));
}

.divide-green-dark > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #034b45;
  border-color: rgba(3, 75, 69, var(--divide-opacity));
}

.divide-grey-10 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e6e6e6;
  border-color: rgba(230, 230, 230, var(--divide-opacity));
}

.divide-grey-20 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cccccc;
  border-color: rgba(204, 204, 204, var(--divide-opacity));
}

.divide-grey-30 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b3b3b3;
  border-color: rgba(179, 179, 179, var(--divide-opacity));
}

.divide-grey-40 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #999999;
  border-color: rgba(153, 153, 153, var(--divide-opacity));
}

.divide-grey-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #808080;
  border-color: rgba(128, 128, 128, var(--divide-opacity));
}

.divide-grey-60 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #666666;
  border-color: rgba(102, 102, 102, var(--divide-opacity));
}

.divide-grey-70 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4d4d4d;
  border-color: rgba(77, 77, 77, var(--divide-opacity));
}

.divide-grey-80 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #333333;
  border-color: rgba(51, 51, 51, var(--divide-opacity));
}

.divide-grey-90 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1a1a1a;
  border-color: rgba(26, 26, 26, var(--divide-opacity));
}

.divide-grey > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f2f2f2;
  border-color: rgba(242, 242, 242, var(--divide-opacity));
}

.divide-grey-light > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f9f9f9;
  border-color: rgba(249, 249, 249, var(--divide-opacity));
}

.divide-grey-dark > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e1e1e1;
  border-color: rgba(225, 225, 225, var(--divide-opacity));
}

.divide-purple-visited > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #8d50b1;
  border-color: rgba(141, 80, 177, var(--divide-opacity));
}

.divide-purple-visited-light > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e0adff;
  border-color: rgba(224, 173, 255, var(--divide-opacity));
}

.divide-red > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ff8274;
  border-color: rgba(255, 130, 116, var(--divide-opacity));
}

.divide-yellow > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f9c66b;
  border-color: rgba(249, 198, 107, var(--divide-opacity));
}

.divide-state-danger > :not(template) ~ :not(template) {
  border-color: #FF8274B3;
}

.divide-state-warning > :not(template) ~ :not(template) {
  border-color: #F9C66BB3;
}

.divide-state-success > :not(template) ~ :not(template) {
  border-color: #C7F6C9B3;
}

.divide-state-primary > :not(template) ~ :not(template) {
  border-color: #B3F5FFB3;
}

.divide-solid > :not(template) ~ :not(template) {
  border-style: solid;
}

.divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed;
}

.divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted;
}

.divide-double > :not(template) ~ :not(template) {
  border-style: double;
}

.divide-none > :not(template) ~ :not(template) {
  border-style: none;
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0;
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25;
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5;
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75;
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}

.bg-white {
  --bg-opacity: 1;
  background-color: #FFFFFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-black {
  --bg-opacity: 1;
  background-color: #2c2c2c;
  background-color: rgba(44, 44, 44, var(--bg-opacity));
}

.bg-black-backdrop {
  background-color: #2c2c2c99;
}

.bg-beige {
  --bg-opacity: 1;
  background-color: #f8f0dd;
  background-color: rgba(248, 240, 221, var(--bg-opacity));
}

.bg-beige-dark {
  --bg-opacity: 1;
  background-color: #d0bfae;
  background-color: rgba(208, 191, 174, var(--bg-opacity));
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #6fe9ff;
  background-color: rgba(111, 233, 255, var(--bg-opacity));
}

.bg-blue-dark {
  --bg-opacity: 1;
  background-color: #2a2859;
  background-color: rgba(42, 40, 89, var(--bg-opacity));
}

.bg-blue-light {
  --bg-opacity: 1;
  background-color: #b3f5ff;
  background-color: rgba(179, 245, 255, var(--bg-opacity));
}

.bg-blue-hover {
  --bg-opacity: 1;
  background-color: #1f42aa;
  background-color: rgba(31, 66, 170, var(--bg-opacity));
}

.bg-green {
  --bg-opacity: 1;
  background-color: #43f8b6;
  background-color: rgba(67, 248, 182, var(--bg-opacity));
}

.bg-green-light {
  --bg-opacity: 1;
  background-color: #c7f6c9;
  background-color: rgba(199, 246, 201, var(--bg-opacity));
}

.bg-green-dark {
  --bg-opacity: 1;
  background-color: #034b45;
  background-color: rgba(3, 75, 69, var(--bg-opacity));
}

.bg-grey-10 {
  --bg-opacity: 1;
  background-color: #e6e6e6;
  background-color: rgba(230, 230, 230, var(--bg-opacity));
}

.bg-grey-20 {
  --bg-opacity: 1;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, var(--bg-opacity));
}

.bg-grey-30 {
  --bg-opacity: 1;
  background-color: #b3b3b3;
  background-color: rgba(179, 179, 179, var(--bg-opacity));
}

.bg-grey-40 {
  --bg-opacity: 1;
  background-color: #999999;
  background-color: rgba(153, 153, 153, var(--bg-opacity));
}

.bg-grey-50 {
  --bg-opacity: 1;
  background-color: #808080;
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.bg-grey-60 {
  --bg-opacity: 1;
  background-color: #666666;
  background-color: rgba(102, 102, 102, var(--bg-opacity));
}

.bg-grey-70 {
  --bg-opacity: 1;
  background-color: #4d4d4d;
  background-color: rgba(77, 77, 77, var(--bg-opacity));
}

.bg-grey-80 {
  --bg-opacity: 1;
  background-color: #333333;
  background-color: rgba(51, 51, 51, var(--bg-opacity));
}

.bg-grey-90 {
  --bg-opacity: 1;
  background-color: #1a1a1a;
  background-color: rgba(26, 26, 26, var(--bg-opacity));
}

.bg-grey {
  --bg-opacity: 1;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, var(--bg-opacity));
}

.bg-grey-light {
  --bg-opacity: 1;
  background-color: #f9f9f9;
  background-color: rgba(249, 249, 249, var(--bg-opacity));
}

.bg-grey-dark {
  --bg-opacity: 1;
  background-color: #e1e1e1;
  background-color: rgba(225, 225, 225, var(--bg-opacity));
}

.bg-purple-visited {
  --bg-opacity: 1;
  background-color: #8d50b1;
  background-color: rgba(141, 80, 177, var(--bg-opacity));
}

.bg-purple-visited-light {
  --bg-opacity: 1;
  background-color: #e0adff;
  background-color: rgba(224, 173, 255, var(--bg-opacity));
}

.bg-red {
  --bg-opacity: 1;
  background-color: #ff8274;
  background-color: rgba(255, 130, 116, var(--bg-opacity));
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: #f9c66b;
  background-color: rgba(249, 198, 107, var(--bg-opacity));
}

.bg-state-danger {
  background-color: #FF8274B3;
}

.bg-state-warning {
  background-color: #F9C66BB3;
}

.bg-state-success {
  background-color: #C7F6C9B3;
}

.bg-state-primary {
  background-color: #B3F5FFB3;
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #FFFFFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.hover\:bg-black:hover {
  --bg-opacity: 1;
  background-color: #2c2c2c;
  background-color: rgba(44, 44, 44, var(--bg-opacity));
}

.hover\:bg-black-backdrop:hover {
  background-color: #2c2c2c99;
}

.hover\:bg-beige:hover {
  --bg-opacity: 1;
  background-color: #f8f0dd;
  background-color: rgba(248, 240, 221, var(--bg-opacity));
}

.hover\:bg-beige-dark:hover {
  --bg-opacity: 1;
  background-color: #d0bfae;
  background-color: rgba(208, 191, 174, var(--bg-opacity));
}

.hover\:bg-blue:hover {
  --bg-opacity: 1;
  background-color: #6fe9ff;
  background-color: rgba(111, 233, 255, var(--bg-opacity));
}

.hover\:bg-blue-dark:hover {
  --bg-opacity: 1;
  background-color: #2a2859;
  background-color: rgba(42, 40, 89, var(--bg-opacity));
}

.hover\:bg-blue-light:hover {
  --bg-opacity: 1;
  background-color: #b3f5ff;
  background-color: rgba(179, 245, 255, var(--bg-opacity));
}

.hover\:bg-blue-hover:hover {
  --bg-opacity: 1;
  background-color: #1f42aa;
  background-color: rgba(31, 66, 170, var(--bg-opacity));
}

.hover\:bg-green:hover {
  --bg-opacity: 1;
  background-color: #43f8b6;
  background-color: rgba(67, 248, 182, var(--bg-opacity));
}

.hover\:bg-green-light:hover {
  --bg-opacity: 1;
  background-color: #c7f6c9;
  background-color: rgba(199, 246, 201, var(--bg-opacity));
}

.hover\:bg-green-dark:hover {
  --bg-opacity: 1;
  background-color: #034b45;
  background-color: rgba(3, 75, 69, var(--bg-opacity));
}

.hover\:bg-grey-10:hover {
  --bg-opacity: 1;
  background-color: #e6e6e6;
  background-color: rgba(230, 230, 230, var(--bg-opacity));
}

.hover\:bg-grey-20:hover {
  --bg-opacity: 1;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, var(--bg-opacity));
}

.hover\:bg-grey-30:hover {
  --bg-opacity: 1;
  background-color: #b3b3b3;
  background-color: rgba(179, 179, 179, var(--bg-opacity));
}

.hover\:bg-grey-40:hover {
  --bg-opacity: 1;
  background-color: #999999;
  background-color: rgba(153, 153, 153, var(--bg-opacity));
}

.hover\:bg-grey-50:hover {
  --bg-opacity: 1;
  background-color: #808080;
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.hover\:bg-grey-60:hover {
  --bg-opacity: 1;
  background-color: #666666;
  background-color: rgba(102, 102, 102, var(--bg-opacity));
}

.hover\:bg-grey-70:hover {
  --bg-opacity: 1;
  background-color: #4d4d4d;
  background-color: rgba(77, 77, 77, var(--bg-opacity));
}

.hover\:bg-grey-80:hover {
  --bg-opacity: 1;
  background-color: #333333;
  background-color: rgba(51, 51, 51, var(--bg-opacity));
}

.hover\:bg-grey-90:hover {
  --bg-opacity: 1;
  background-color: #1a1a1a;
  background-color: rgba(26, 26, 26, var(--bg-opacity));
}

.hover\:bg-grey:hover {
  --bg-opacity: 1;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, var(--bg-opacity));
}

.hover\:bg-grey-light:hover {
  --bg-opacity: 1;
  background-color: #f9f9f9;
  background-color: rgba(249, 249, 249, var(--bg-opacity));
}

.hover\:bg-grey-dark:hover {
  --bg-opacity: 1;
  background-color: #e1e1e1;
  background-color: rgba(225, 225, 225, var(--bg-opacity));
}

.hover\:bg-purple-visited:hover {
  --bg-opacity: 1;
  background-color: #8d50b1;
  background-color: rgba(141, 80, 177, var(--bg-opacity));
}

.hover\:bg-purple-visited-light:hover {
  --bg-opacity: 1;
  background-color: #e0adff;
  background-color: rgba(224, 173, 255, var(--bg-opacity));
}

.hover\:bg-red:hover {
  --bg-opacity: 1;
  background-color: #ff8274;
  background-color: rgba(255, 130, 116, var(--bg-opacity));
}

.hover\:bg-yellow:hover {
  --bg-opacity: 1;
  background-color: #f9c66b;
  background-color: rgba(249, 198, 107, var(--bg-opacity));
}

.hover\:bg-state-danger:hover {
  background-color: #FF8274B3;
}

.hover\:bg-state-warning:hover {
  background-color: #F9C66BB3;
}

.hover\:bg-state-success:hover {
  background-color: #C7F6C9B3;
}

.hover\:bg-state-primary:hover {
  background-color: #B3F5FFB3;
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #FFFFFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.focus\:bg-black:focus {
  --bg-opacity: 1;
  background-color: #2c2c2c;
  background-color: rgba(44, 44, 44, var(--bg-opacity));
}

.focus\:bg-black-backdrop:focus {
  background-color: #2c2c2c99;
}

.focus\:bg-beige:focus {
  --bg-opacity: 1;
  background-color: #f8f0dd;
  background-color: rgba(248, 240, 221, var(--bg-opacity));
}

.focus\:bg-beige-dark:focus {
  --bg-opacity: 1;
  background-color: #d0bfae;
  background-color: rgba(208, 191, 174, var(--bg-opacity));
}

.focus\:bg-blue:focus {
  --bg-opacity: 1;
  background-color: #6fe9ff;
  background-color: rgba(111, 233, 255, var(--bg-opacity));
}

.focus\:bg-blue-dark:focus {
  --bg-opacity: 1;
  background-color: #2a2859;
  background-color: rgba(42, 40, 89, var(--bg-opacity));
}

.focus\:bg-blue-light:focus {
  --bg-opacity: 1;
  background-color: #b3f5ff;
  background-color: rgba(179, 245, 255, var(--bg-opacity));
}

.focus\:bg-blue-hover:focus {
  --bg-opacity: 1;
  background-color: #1f42aa;
  background-color: rgba(31, 66, 170, var(--bg-opacity));
}

.focus\:bg-green:focus {
  --bg-opacity: 1;
  background-color: #43f8b6;
  background-color: rgba(67, 248, 182, var(--bg-opacity));
}

.focus\:bg-green-light:focus {
  --bg-opacity: 1;
  background-color: #c7f6c9;
  background-color: rgba(199, 246, 201, var(--bg-opacity));
}

.focus\:bg-green-dark:focus {
  --bg-opacity: 1;
  background-color: #034b45;
  background-color: rgba(3, 75, 69, var(--bg-opacity));
}

.focus\:bg-grey-10:focus {
  --bg-opacity: 1;
  background-color: #e6e6e6;
  background-color: rgba(230, 230, 230, var(--bg-opacity));
}

.focus\:bg-grey-20:focus {
  --bg-opacity: 1;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, var(--bg-opacity));
}

.focus\:bg-grey-30:focus {
  --bg-opacity: 1;
  background-color: #b3b3b3;
  background-color: rgba(179, 179, 179, var(--bg-opacity));
}

.focus\:bg-grey-40:focus {
  --bg-opacity: 1;
  background-color: #999999;
  background-color: rgba(153, 153, 153, var(--bg-opacity));
}

.focus\:bg-grey-50:focus {
  --bg-opacity: 1;
  background-color: #808080;
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.focus\:bg-grey-60:focus {
  --bg-opacity: 1;
  background-color: #666666;
  background-color: rgba(102, 102, 102, var(--bg-opacity));
}

.focus\:bg-grey-70:focus {
  --bg-opacity: 1;
  background-color: #4d4d4d;
  background-color: rgba(77, 77, 77, var(--bg-opacity));
}

.focus\:bg-grey-80:focus {
  --bg-opacity: 1;
  background-color: #333333;
  background-color: rgba(51, 51, 51, var(--bg-opacity));
}

.focus\:bg-grey-90:focus {
  --bg-opacity: 1;
  background-color: #1a1a1a;
  background-color: rgba(26, 26, 26, var(--bg-opacity));
}

.focus\:bg-grey:focus {
  --bg-opacity: 1;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, var(--bg-opacity));
}

.focus\:bg-grey-light:focus {
  --bg-opacity: 1;
  background-color: #f9f9f9;
  background-color: rgba(249, 249, 249, var(--bg-opacity));
}

.focus\:bg-grey-dark:focus {
  --bg-opacity: 1;
  background-color: #e1e1e1;
  background-color: rgba(225, 225, 225, var(--bg-opacity));
}

.focus\:bg-purple-visited:focus {
  --bg-opacity: 1;
  background-color: #8d50b1;
  background-color: rgba(141, 80, 177, var(--bg-opacity));
}

.focus\:bg-purple-visited-light:focus {
  --bg-opacity: 1;
  background-color: #e0adff;
  background-color: rgba(224, 173, 255, var(--bg-opacity));
}

.focus\:bg-red:focus {
  --bg-opacity: 1;
  background-color: #ff8274;
  background-color: rgba(255, 130, 116, var(--bg-opacity));
}

.focus\:bg-yellow:focus {
  --bg-opacity: 1;
  background-color: #f9c66b;
  background-color: rgba(249, 198, 107, var(--bg-opacity));
}

.focus\:bg-state-danger:focus {
  background-color: #FF8274B3;
}

.focus\:bg-state-warning:focus {
  background-color: #F9C66BB3;
}

.focus\:bg-state-success:focus {
  background-color: #C7F6C9B3;
}

.focus\:bg-state-primary:focus {
  background-color: #B3F5FFB3;
}

.odd\:bg-white:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #FFFFFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.odd\:bg-black:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #2c2c2c;
  background-color: rgba(44, 44, 44, var(--bg-opacity));
}

.odd\:bg-black-backdrop:nth-child(odd) {
  background-color: #2c2c2c99;
}

.odd\:bg-beige:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f8f0dd;
  background-color: rgba(248, 240, 221, var(--bg-opacity));
}

.odd\:bg-beige-dark:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #d0bfae;
  background-color: rgba(208, 191, 174, var(--bg-opacity));
}

.odd\:bg-blue:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #6fe9ff;
  background-color: rgba(111, 233, 255, var(--bg-opacity));
}

.odd\:bg-blue-dark:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #2a2859;
  background-color: rgba(42, 40, 89, var(--bg-opacity));
}

.odd\:bg-blue-light:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #b3f5ff;
  background-color: rgba(179, 245, 255, var(--bg-opacity));
}

.odd\:bg-blue-hover:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1f42aa;
  background-color: rgba(31, 66, 170, var(--bg-opacity));
}

.odd\:bg-green:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #43f8b6;
  background-color: rgba(67, 248, 182, var(--bg-opacity));
}

.odd\:bg-green-light:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #c7f6c9;
  background-color: rgba(199, 246, 201, var(--bg-opacity));
}

.odd\:bg-green-dark:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #034b45;
  background-color: rgba(3, 75, 69, var(--bg-opacity));
}

.odd\:bg-grey-10:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e6e6e6;
  background-color: rgba(230, 230, 230, var(--bg-opacity));
}

.odd\:bg-grey-20:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, var(--bg-opacity));
}

.odd\:bg-grey-30:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #b3b3b3;
  background-color: rgba(179, 179, 179, var(--bg-opacity));
}

.odd\:bg-grey-40:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #999999;
  background-color: rgba(153, 153, 153, var(--bg-opacity));
}

.odd\:bg-grey-50:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #808080;
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.odd\:bg-grey-60:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #666666;
  background-color: rgba(102, 102, 102, var(--bg-opacity));
}

.odd\:bg-grey-70:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #4d4d4d;
  background-color: rgba(77, 77, 77, var(--bg-opacity));
}

.odd\:bg-grey-80:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #333333;
  background-color: rgba(51, 51, 51, var(--bg-opacity));
}

.odd\:bg-grey-90:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #1a1a1a;
  background-color: rgba(26, 26, 26, var(--bg-opacity));
}

.odd\:bg-grey:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, var(--bg-opacity));
}

.odd\:bg-grey-light:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f9f9f9;
  background-color: rgba(249, 249, 249, var(--bg-opacity));
}

.odd\:bg-grey-dark:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e1e1e1;
  background-color: rgba(225, 225, 225, var(--bg-opacity));
}

.odd\:bg-purple-visited:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #8d50b1;
  background-color: rgba(141, 80, 177, var(--bg-opacity));
}

.odd\:bg-purple-visited-light:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #e0adff;
  background-color: rgba(224, 173, 255, var(--bg-opacity));
}

.odd\:bg-red:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #ff8274;
  background-color: rgba(255, 130, 116, var(--bg-opacity));
}

.odd\:bg-yellow:nth-child(odd) {
  --bg-opacity: 1;
  background-color: #f9c66b;
  background-color: rgba(249, 198, 107, var(--bg-opacity));
}

.odd\:bg-state-danger:nth-child(odd) {
  background-color: #FF8274B3;
}

.odd\:bg-state-warning:nth-child(odd) {
  background-color: #F9C66BB3;
}

.odd\:bg-state-success:nth-child(odd) {
  background-color: #C7F6C9B3;
}

.odd\:bg-state-primary:nth-child(odd) {
  background-color: #B3F5FFB3;
}

.even\:bg-white:nth-child(even) {
  --bg-opacity: 1;
  background-color: #FFFFFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.even\:bg-black:nth-child(even) {
  --bg-opacity: 1;
  background-color: #2c2c2c;
  background-color: rgba(44, 44, 44, var(--bg-opacity));
}

.even\:bg-black-backdrop:nth-child(even) {
  background-color: #2c2c2c99;
}

.even\:bg-beige:nth-child(even) {
  --bg-opacity: 1;
  background-color: #f8f0dd;
  background-color: rgba(248, 240, 221, var(--bg-opacity));
}

.even\:bg-beige-dark:nth-child(even) {
  --bg-opacity: 1;
  background-color: #d0bfae;
  background-color: rgba(208, 191, 174, var(--bg-opacity));
}

.even\:bg-blue:nth-child(even) {
  --bg-opacity: 1;
  background-color: #6fe9ff;
  background-color: rgba(111, 233, 255, var(--bg-opacity));
}

.even\:bg-blue-dark:nth-child(even) {
  --bg-opacity: 1;
  background-color: #2a2859;
  background-color: rgba(42, 40, 89, var(--bg-opacity));
}

.even\:bg-blue-light:nth-child(even) {
  --bg-opacity: 1;
  background-color: #b3f5ff;
  background-color: rgba(179, 245, 255, var(--bg-opacity));
}

.even\:bg-blue-hover:nth-child(even) {
  --bg-opacity: 1;
  background-color: #1f42aa;
  background-color: rgba(31, 66, 170, var(--bg-opacity));
}

.even\:bg-green:nth-child(even) {
  --bg-opacity: 1;
  background-color: #43f8b6;
  background-color: rgba(67, 248, 182, var(--bg-opacity));
}

.even\:bg-green-light:nth-child(even) {
  --bg-opacity: 1;
  background-color: #c7f6c9;
  background-color: rgba(199, 246, 201, var(--bg-opacity));
}

.even\:bg-green-dark:nth-child(even) {
  --bg-opacity: 1;
  background-color: #034b45;
  background-color: rgba(3, 75, 69, var(--bg-opacity));
}

.even\:bg-grey-10:nth-child(even) {
  --bg-opacity: 1;
  background-color: #e6e6e6;
  background-color: rgba(230, 230, 230, var(--bg-opacity));
}

.even\:bg-grey-20:nth-child(even) {
  --bg-opacity: 1;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, var(--bg-opacity));
}

.even\:bg-grey-30:nth-child(even) {
  --bg-opacity: 1;
  background-color: #b3b3b3;
  background-color: rgba(179, 179, 179, var(--bg-opacity));
}

.even\:bg-grey-40:nth-child(even) {
  --bg-opacity: 1;
  background-color: #999999;
  background-color: rgba(153, 153, 153, var(--bg-opacity));
}

.even\:bg-grey-50:nth-child(even) {
  --bg-opacity: 1;
  background-color: #808080;
  background-color: rgba(128, 128, 128, var(--bg-opacity));
}

.even\:bg-grey-60:nth-child(even) {
  --bg-opacity: 1;
  background-color: #666666;
  background-color: rgba(102, 102, 102, var(--bg-opacity));
}

.even\:bg-grey-70:nth-child(even) {
  --bg-opacity: 1;
  background-color: #4d4d4d;
  background-color: rgba(77, 77, 77, var(--bg-opacity));
}

.even\:bg-grey-80:nth-child(even) {
  --bg-opacity: 1;
  background-color: #333333;
  background-color: rgba(51, 51, 51, var(--bg-opacity));
}

.even\:bg-grey-90:nth-child(even) {
  --bg-opacity: 1;
  background-color: #1a1a1a;
  background-color: rgba(26, 26, 26, var(--bg-opacity));
}

.even\:bg-grey:nth-child(even) {
  --bg-opacity: 1;
  background-color: #f2f2f2;
  background-color: rgba(242, 242, 242, var(--bg-opacity));
}

.even\:bg-grey-light:nth-child(even) {
  --bg-opacity: 1;
  background-color: #f9f9f9;
  background-color: rgba(249, 249, 249, var(--bg-opacity));
}

.even\:bg-grey-dark:nth-child(even) {
  --bg-opacity: 1;
  background-color: #e1e1e1;
  background-color: rgba(225, 225, 225, var(--bg-opacity));
}

.even\:bg-purple-visited:nth-child(even) {
  --bg-opacity: 1;
  background-color: #8d50b1;
  background-color: rgba(141, 80, 177, var(--bg-opacity));
}

.even\:bg-purple-visited-light:nth-child(even) {
  --bg-opacity: 1;
  background-color: #e0adff;
  background-color: rgba(224, 173, 255, var(--bg-opacity));
}

.even\:bg-red:nth-child(even) {
  --bg-opacity: 1;
  background-color: #ff8274;
  background-color: rgba(255, 130, 116, var(--bg-opacity));
}

.even\:bg-yellow:nth-child(even) {
  --bg-opacity: 1;
  background-color: #f9c66b;
  background-color: rgba(249, 198, 107, var(--bg-opacity));
}

.even\:bg-state-danger:nth-child(even) {
  background-color: #FF8274B3;
}

.even\:bg-state-warning:nth-child(even) {
  background-color: #F9C66BB3;
}

.even\:bg-state-success:nth-child(even) {
  background-color: #C7F6C9B3;
}

.even\:bg-state-primary:nth-child(even) {
  background-color: #B3F5FFB3;
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops));
}

.from-white {
  --gradient-from-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.from-black {
  --gradient-from-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.from-black-backdrop {
  --gradient-from-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.from-beige {
  --gradient-from-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.from-beige-dark {
  --gradient-from-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.from-blue {
  --gradient-from-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.from-blue-dark {
  --gradient-from-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.from-blue-light {
  --gradient-from-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.from-blue-hover {
  --gradient-from-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.from-green {
  --gradient-from-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.from-green-light {
  --gradient-from-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.from-green-dark {
  --gradient-from-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.from-grey-10 {
  --gradient-from-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.from-grey-20 {
  --gradient-from-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.from-grey-30 {
  --gradient-from-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.from-grey-40 {
  --gradient-from-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.from-grey-50 {
  --gradient-from-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.from-grey-60 {
  --gradient-from-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.from-grey-70 {
  --gradient-from-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.from-grey-80 {
  --gradient-from-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.from-grey-90 {
  --gradient-from-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.from-grey {
  --gradient-from-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.from-grey-light {
  --gradient-from-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.from-grey-dark {
  --gradient-from-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.from-purple-visited {
  --gradient-from-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.from-purple-visited-light {
  --gradient-from-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.from-red {
  --gradient-from-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.from-yellow {
  --gradient-from-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.from-state-danger {
  --gradient-from-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.from-state-warning {
  --gradient-from-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.from-state-success {
  --gradient-from-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.from-state-primary {
  --gradient-from-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.via-white {
  --gradient-via-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.via-black {
  --gradient-via-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.via-black-backdrop {
  --gradient-via-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.via-beige {
  --gradient-via-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.via-beige-dark {
  --gradient-via-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.via-blue {
  --gradient-via-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.via-blue-dark {
  --gradient-via-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.via-blue-light {
  --gradient-via-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.via-blue-hover {
  --gradient-via-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.via-green {
  --gradient-via-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.via-green-light {
  --gradient-via-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.via-green-dark {
  --gradient-via-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.via-grey-10 {
  --gradient-via-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.via-grey-20 {
  --gradient-via-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.via-grey-30 {
  --gradient-via-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.via-grey-40 {
  --gradient-via-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.via-grey-50 {
  --gradient-via-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.via-grey-60 {
  --gradient-via-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.via-grey-70 {
  --gradient-via-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.via-grey-80 {
  --gradient-via-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.via-grey-90 {
  --gradient-via-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.via-grey {
  --gradient-via-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.via-grey-light {
  --gradient-via-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.via-grey-dark {
  --gradient-via-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.via-purple-visited {
  --gradient-via-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.via-purple-visited-light {
  --gradient-via-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.via-red {
  --gradient-via-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.via-yellow {
  --gradient-via-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.via-state-danger {
  --gradient-via-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.via-state-warning {
  --gradient-via-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.via-state-success {
  --gradient-via-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.via-state-primary {
  --gradient-via-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.to-white {
  --gradient-to-color: #FFFFFF;
}

.to-black {
  --gradient-to-color: #2c2c2c;
}

.to-black-backdrop {
  --gradient-to-color: #2c2c2c99;
}

.to-beige {
  --gradient-to-color: #f8f0dd;
}

.to-beige-dark {
  --gradient-to-color: #d0bfae;
}

.to-blue {
  --gradient-to-color: #6fe9ff;
}

.to-blue-dark {
  --gradient-to-color: #2a2859;
}

.to-blue-light {
  --gradient-to-color: #b3f5ff;
}

.to-blue-hover {
  --gradient-to-color: #1f42aa;
}

.to-green {
  --gradient-to-color: #43f8b6;
}

.to-green-light {
  --gradient-to-color: #c7f6c9;
}

.to-green-dark {
  --gradient-to-color: #034b45;
}

.to-grey-10 {
  --gradient-to-color: #e6e6e6;
}

.to-grey-20 {
  --gradient-to-color: #cccccc;
}

.to-grey-30 {
  --gradient-to-color: #b3b3b3;
}

.to-grey-40 {
  --gradient-to-color: #999999;
}

.to-grey-50 {
  --gradient-to-color: #808080;
}

.to-grey-60 {
  --gradient-to-color: #666666;
}

.to-grey-70 {
  --gradient-to-color: #4d4d4d;
}

.to-grey-80 {
  --gradient-to-color: #333333;
}

.to-grey-90 {
  --gradient-to-color: #1a1a1a;
}

.to-grey {
  --gradient-to-color: #f2f2f2;
}

.to-grey-light {
  --gradient-to-color: #f9f9f9;
}

.to-grey-dark {
  --gradient-to-color: #e1e1e1;
}

.to-purple-visited {
  --gradient-to-color: #8d50b1;
}

.to-purple-visited-light {
  --gradient-to-color: #e0adff;
}

.to-red {
  --gradient-to-color: #ff8274;
}

.to-yellow {
  --gradient-to-color: #f9c66b;
}

.to-state-danger {
  --gradient-to-color: #FF8274B3;
}

.to-state-warning {
  --gradient-to-color: #F9C66BB3;
}

.to-state-success {
  --gradient-to-color: #C7F6C9B3;
}

.to-state-primary {
  --gradient-to-color: #B3F5FFB3;
}

.hover\:from-white:hover {
  --gradient-from-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:from-black:hover {
  --gradient-from-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.hover\:from-black-backdrop:hover {
  --gradient-from-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.hover\:from-beige:hover {
  --gradient-from-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.hover\:from-beige-dark:hover {
  --gradient-from-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.hover\:from-blue:hover {
  --gradient-from-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.hover\:from-blue-dark:hover {
  --gradient-from-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.hover\:from-blue-light:hover {
  --gradient-from-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.hover\:from-blue-hover:hover {
  --gradient-from-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.hover\:from-green:hover {
  --gradient-from-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.hover\:from-green-light:hover {
  --gradient-from-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.hover\:from-green-dark:hover {
  --gradient-from-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.hover\:from-grey-10:hover {
  --gradient-from-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.hover\:from-grey-20:hover {
  --gradient-from-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.hover\:from-grey-30:hover {
  --gradient-from-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.hover\:from-grey-40:hover {
  --gradient-from-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.hover\:from-grey-50:hover {
  --gradient-from-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.hover\:from-grey-60:hover {
  --gradient-from-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.hover\:from-grey-70:hover {
  --gradient-from-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.hover\:from-grey-80:hover {
  --gradient-from-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.hover\:from-grey-90:hover {
  --gradient-from-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.hover\:from-grey:hover {
  --gradient-from-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.hover\:from-grey-light:hover {
  --gradient-from-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.hover\:from-grey-dark:hover {
  --gradient-from-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.hover\:from-purple-visited:hover {
  --gradient-from-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.hover\:from-purple-visited-light:hover {
  --gradient-from-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.hover\:from-red:hover {
  --gradient-from-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.hover\:from-yellow:hover {
  --gradient-from-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.hover\:from-state-danger:hover {
  --gradient-from-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.hover\:from-state-warning:hover {
  --gradient-from-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.hover\:from-state-success:hover {
  --gradient-from-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.hover\:from-state-primary:hover {
  --gradient-from-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.hover\:via-white:hover {
  --gradient-via-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:via-black:hover {
  --gradient-via-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.hover\:via-black-backdrop:hover {
  --gradient-via-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.hover\:via-beige:hover {
  --gradient-via-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.hover\:via-beige-dark:hover {
  --gradient-via-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.hover\:via-blue:hover {
  --gradient-via-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.hover\:via-blue-dark:hover {
  --gradient-via-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.hover\:via-blue-light:hover {
  --gradient-via-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.hover\:via-blue-hover:hover {
  --gradient-via-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.hover\:via-green:hover {
  --gradient-via-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.hover\:via-green-light:hover {
  --gradient-via-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.hover\:via-green-dark:hover {
  --gradient-via-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.hover\:via-grey-10:hover {
  --gradient-via-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.hover\:via-grey-20:hover {
  --gradient-via-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.hover\:via-grey-30:hover {
  --gradient-via-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.hover\:via-grey-40:hover {
  --gradient-via-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.hover\:via-grey-50:hover {
  --gradient-via-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.hover\:via-grey-60:hover {
  --gradient-via-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.hover\:via-grey-70:hover {
  --gradient-via-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.hover\:via-grey-80:hover {
  --gradient-via-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.hover\:via-grey-90:hover {
  --gradient-via-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.hover\:via-grey:hover {
  --gradient-via-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.hover\:via-grey-light:hover {
  --gradient-via-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.hover\:via-grey-dark:hover {
  --gradient-via-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.hover\:via-purple-visited:hover {
  --gradient-via-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.hover\:via-purple-visited-light:hover {
  --gradient-via-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.hover\:via-red:hover {
  --gradient-via-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.hover\:via-yellow:hover {
  --gradient-via-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.hover\:via-state-danger:hover {
  --gradient-via-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.hover\:via-state-warning:hover {
  --gradient-via-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.hover\:via-state-success:hover {
  --gradient-via-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.hover\:via-state-primary:hover {
  --gradient-via-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.hover\:to-white:hover {
  --gradient-to-color: #FFFFFF;
}

.hover\:to-black:hover {
  --gradient-to-color: #2c2c2c;
}

.hover\:to-black-backdrop:hover {
  --gradient-to-color: #2c2c2c99;
}

.hover\:to-beige:hover {
  --gradient-to-color: #f8f0dd;
}

.hover\:to-beige-dark:hover {
  --gradient-to-color: #d0bfae;
}

.hover\:to-blue:hover {
  --gradient-to-color: #6fe9ff;
}

.hover\:to-blue-dark:hover {
  --gradient-to-color: #2a2859;
}

.hover\:to-blue-light:hover {
  --gradient-to-color: #b3f5ff;
}

.hover\:to-blue-hover:hover {
  --gradient-to-color: #1f42aa;
}

.hover\:to-green:hover {
  --gradient-to-color: #43f8b6;
}

.hover\:to-green-light:hover {
  --gradient-to-color: #c7f6c9;
}

.hover\:to-green-dark:hover {
  --gradient-to-color: #034b45;
}

.hover\:to-grey-10:hover {
  --gradient-to-color: #e6e6e6;
}

.hover\:to-grey-20:hover {
  --gradient-to-color: #cccccc;
}

.hover\:to-grey-30:hover {
  --gradient-to-color: #b3b3b3;
}

.hover\:to-grey-40:hover {
  --gradient-to-color: #999999;
}

.hover\:to-grey-50:hover {
  --gradient-to-color: #808080;
}

.hover\:to-grey-60:hover {
  --gradient-to-color: #666666;
}

.hover\:to-grey-70:hover {
  --gradient-to-color: #4d4d4d;
}

.hover\:to-grey-80:hover {
  --gradient-to-color: #333333;
}

.hover\:to-grey-90:hover {
  --gradient-to-color: #1a1a1a;
}

.hover\:to-grey:hover {
  --gradient-to-color: #f2f2f2;
}

.hover\:to-grey-light:hover {
  --gradient-to-color: #f9f9f9;
}

.hover\:to-grey-dark:hover {
  --gradient-to-color: #e1e1e1;
}

.hover\:to-purple-visited:hover {
  --gradient-to-color: #8d50b1;
}

.hover\:to-purple-visited-light:hover {
  --gradient-to-color: #e0adff;
}

.hover\:to-red:hover {
  --gradient-to-color: #ff8274;
}

.hover\:to-yellow:hover {
  --gradient-to-color: #f9c66b;
}

.hover\:to-state-danger:hover {
  --gradient-to-color: #FF8274B3;
}

.hover\:to-state-warning:hover {
  --gradient-to-color: #F9C66BB3;
}

.hover\:to-state-success:hover {
  --gradient-to-color: #C7F6C9B3;
}

.hover\:to-state-primary:hover {
  --gradient-to-color: #B3F5FFB3;
}

.focus\:from-white:focus {
  --gradient-from-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:from-black:focus {
  --gradient-from-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.focus\:from-black-backdrop:focus {
  --gradient-from-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.focus\:from-beige:focus {
  --gradient-from-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.focus\:from-beige-dark:focus {
  --gradient-from-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.focus\:from-blue:focus {
  --gradient-from-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.focus\:from-blue-dark:focus {
  --gradient-from-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.focus\:from-blue-light:focus {
  --gradient-from-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.focus\:from-blue-hover:focus {
  --gradient-from-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.focus\:from-green:focus {
  --gradient-from-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.focus\:from-green-light:focus {
  --gradient-from-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.focus\:from-green-dark:focus {
  --gradient-from-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.focus\:from-grey-10:focus {
  --gradient-from-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.focus\:from-grey-20:focus {
  --gradient-from-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.focus\:from-grey-30:focus {
  --gradient-from-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.focus\:from-grey-40:focus {
  --gradient-from-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.focus\:from-grey-50:focus {
  --gradient-from-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.focus\:from-grey-60:focus {
  --gradient-from-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.focus\:from-grey-70:focus {
  --gradient-from-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.focus\:from-grey-80:focus {
  --gradient-from-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.focus\:from-grey-90:focus {
  --gradient-from-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.focus\:from-grey:focus {
  --gradient-from-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.focus\:from-grey-light:focus {
  --gradient-from-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.focus\:from-grey-dark:focus {
  --gradient-from-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.focus\:from-purple-visited:focus {
  --gradient-from-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.focus\:from-purple-visited-light:focus {
  --gradient-from-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.focus\:from-red:focus {
  --gradient-from-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.focus\:from-yellow:focus {
  --gradient-from-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.focus\:from-state-danger:focus {
  --gradient-from-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.focus\:from-state-warning:focus {
  --gradient-from-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.focus\:from-state-success:focus {
  --gradient-from-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.focus\:from-state-primary:focus {
  --gradient-from-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.focus\:via-white:focus {
  --gradient-via-color: #FFFFFF;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:via-black:focus {
  --gradient-via-color: #2c2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.focus\:via-black-backdrop:focus {
  --gradient-via-color: #2c2c2c99;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
}

.focus\:via-beige:focus {
  --gradient-via-color: #f8f0dd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
}

.focus\:via-beige-dark:focus {
  --gradient-via-color: #d0bfae;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
}

.focus\:via-blue:focus {
  --gradient-via-color: #6fe9ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
}

.focus\:via-blue-dark:focus {
  --gradient-via-color: #2a2859;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
}

.focus\:via-blue-light:focus {
  --gradient-via-color: #b3f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.focus\:via-blue-hover:focus {
  --gradient-via-color: #1f42aa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
}

.focus\:via-green:focus {
  --gradient-via-color: #43f8b6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
}

.focus\:via-green-light:focus {
  --gradient-via-color: #c7f6c9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.focus\:via-green-dark:focus {
  --gradient-via-color: #034b45;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
}

.focus\:via-grey-10:focus {
  --gradient-via-color: #e6e6e6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
}

.focus\:via-grey-20:focus {
  --gradient-via-color: #cccccc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
}

.focus\:via-grey-30:focus {
  --gradient-via-color: #b3b3b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
}

.focus\:via-grey-40:focus {
  --gradient-via-color: #999999;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
}

.focus\:via-grey-50:focus {
  --gradient-via-color: #808080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
}

.focus\:via-grey-60:focus {
  --gradient-via-color: #666666;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
}

.focus\:via-grey-70:focus {
  --gradient-via-color: #4d4d4d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
}

.focus\:via-grey-80:focus {
  --gradient-via-color: #333333;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
}

.focus\:via-grey-90:focus {
  --gradient-via-color: #1a1a1a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
}

.focus\:via-grey:focus {
  --gradient-via-color: #f2f2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
}

.focus\:via-grey-light:focus {
  --gradient-via-color: #f9f9f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
}

.focus\:via-grey-dark:focus {
  --gradient-via-color: #e1e1e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
}

.focus\:via-purple-visited:focus {
  --gradient-via-color: #8d50b1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
}

.focus\:via-purple-visited-light:focus {
  --gradient-via-color: #e0adff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
}

.focus\:via-red:focus {
  --gradient-via-color: #ff8274;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.focus\:via-yellow:focus {
  --gradient-via-color: #f9c66b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.focus\:via-state-danger:focus {
  --gradient-via-color: #FF8274B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
}

.focus\:via-state-warning:focus {
  --gradient-via-color: #F9C66BB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
}

.focus\:via-state-success:focus {
  --gradient-via-color: #C7F6C9B3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
}

.focus\:via-state-primary:focus {
  --gradient-via-color: #B3F5FFB3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
}

.focus\:to-white:focus {
  --gradient-to-color: #FFFFFF;
}

.focus\:to-black:focus {
  --gradient-to-color: #2c2c2c;
}

.focus\:to-black-backdrop:focus {
  --gradient-to-color: #2c2c2c99;
}

.focus\:to-beige:focus {
  --gradient-to-color: #f8f0dd;
}

.focus\:to-beige-dark:focus {
  --gradient-to-color: #d0bfae;
}

.focus\:to-blue:focus {
  --gradient-to-color: #6fe9ff;
}

.focus\:to-blue-dark:focus {
  --gradient-to-color: #2a2859;
}

.focus\:to-blue-light:focus {
  --gradient-to-color: #b3f5ff;
}

.focus\:to-blue-hover:focus {
  --gradient-to-color: #1f42aa;
}

.focus\:to-green:focus {
  --gradient-to-color: #43f8b6;
}

.focus\:to-green-light:focus {
  --gradient-to-color: #c7f6c9;
}

.focus\:to-green-dark:focus {
  --gradient-to-color: #034b45;
}

.focus\:to-grey-10:focus {
  --gradient-to-color: #e6e6e6;
}

.focus\:to-grey-20:focus {
  --gradient-to-color: #cccccc;
}

.focus\:to-grey-30:focus {
  --gradient-to-color: #b3b3b3;
}

.focus\:to-grey-40:focus {
  --gradient-to-color: #999999;
}

.focus\:to-grey-50:focus {
  --gradient-to-color: #808080;
}

.focus\:to-grey-60:focus {
  --gradient-to-color: #666666;
}

.focus\:to-grey-70:focus {
  --gradient-to-color: #4d4d4d;
}

.focus\:to-grey-80:focus {
  --gradient-to-color: #333333;
}

.focus\:to-grey-90:focus {
  --gradient-to-color: #1a1a1a;
}

.focus\:to-grey:focus {
  --gradient-to-color: #f2f2f2;
}

.focus\:to-grey-light:focus {
  --gradient-to-color: #f9f9f9;
}

.focus\:to-grey-dark:focus {
  --gradient-to-color: #e1e1e1;
}

.focus\:to-purple-visited:focus {
  --gradient-to-color: #8d50b1;
}

.focus\:to-purple-visited-light:focus {
  --gradient-to-color: #e0adff;
}

.focus\:to-red:focus {
  --gradient-to-color: #ff8274;
}

.focus\:to-yellow:focus {
  --gradient-to-color: #f9c66b;
}

.focus\:to-state-danger:focus {
  --gradient-to-color: #FF8274B3;
}

.focus\:to-state-warning:focus {
  --gradient-to-color: #F9C66BB3;
}

.focus\:to-state-success:focus {
  --gradient-to-color: #C7F6C9B3;
}

.focus\:to-state-primary:focus {
  --gradient-to-color: #B3F5FFB3;
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-left-bottom {
  background-position: left bottom;
}

.bg-left-top {
  background-position: left top;
}

.bg-right {
  background-position: right;
}

.bg-right-bottom {
  background-position: right bottom;
}

.bg-right-top {
  background-position: right top;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-white {
  --border-opacity: 1;
  border-color: #FFFFFF;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.border-black {
  --border-opacity: 1;
  border-color: #2c2c2c;
  border-color: rgba(44, 44, 44, var(--border-opacity));
}

.border-black-backdrop {
  border-color: #2c2c2c99;
}

.border-beige {
  --border-opacity: 1;
  border-color: #f8f0dd;
  border-color: rgba(248, 240, 221, var(--border-opacity));
}

.border-beige-dark {
  --border-opacity: 1;
  border-color: #d0bfae;
  border-color: rgba(208, 191, 174, var(--border-opacity));
}

.border-blue {
  --border-opacity: 1;
  border-color: #6fe9ff;
  border-color: rgba(111, 233, 255, var(--border-opacity));
}

.border-blue-dark {
  --border-opacity: 1;
  border-color: #2a2859;
  border-color: rgba(42, 40, 89, var(--border-opacity));
}

.border-blue-light {
  --border-opacity: 1;
  border-color: #b3f5ff;
  border-color: rgba(179, 245, 255, var(--border-opacity));
}

.border-blue-hover {
  --border-opacity: 1;
  border-color: #1f42aa;
  border-color: rgba(31, 66, 170, var(--border-opacity));
}

.border-green {
  --border-opacity: 1;
  border-color: #43f8b6;
  border-color: rgba(67, 248, 182, var(--border-opacity));
}

.border-green-light {
  --border-opacity: 1;
  border-color: #c7f6c9;
  border-color: rgba(199, 246, 201, var(--border-opacity));
}

.border-green-dark {
  --border-opacity: 1;
  border-color: #034b45;
  border-color: rgba(3, 75, 69, var(--border-opacity));
}

.border-grey-10 {
  --border-opacity: 1;
  border-color: #e6e6e6;
  border-color: rgba(230, 230, 230, var(--border-opacity));
}

.border-grey-20 {
  --border-opacity: 1;
  border-color: #cccccc;
  border-color: rgba(204, 204, 204, var(--border-opacity));
}

.border-grey-30 {
  --border-opacity: 1;
  border-color: #b3b3b3;
  border-color: rgba(179, 179, 179, var(--border-opacity));
}

.border-grey-40 {
  --border-opacity: 1;
  border-color: #999999;
  border-color: rgba(153, 153, 153, var(--border-opacity));
}

.border-grey-50 {
  --border-opacity: 1;
  border-color: #808080;
  border-color: rgba(128, 128, 128, var(--border-opacity));
}

.border-grey-60 {
  --border-opacity: 1;
  border-color: #666666;
  border-color: rgba(102, 102, 102, var(--border-opacity));
}

.border-grey-70 {
  --border-opacity: 1;
  border-color: #4d4d4d;
  border-color: rgba(77, 77, 77, var(--border-opacity));
}

.border-grey-80 {
  --border-opacity: 1;
  border-color: #333333;
  border-color: rgba(51, 51, 51, var(--border-opacity));
}

.border-grey-90 {
  --border-opacity: 1;
  border-color: #1a1a1a;
  border-color: rgba(26, 26, 26, var(--border-opacity));
}

.border-grey {
  --border-opacity: 1;
  border-color: #f2f2f2;
  border-color: rgba(242, 242, 242, var(--border-opacity));
}

.border-grey-light {
  --border-opacity: 1;
  border-color: #f9f9f9;
  border-color: rgba(249, 249, 249, var(--border-opacity));
}

.border-grey-dark {
  --border-opacity: 1;
  border-color: #e1e1e1;
  border-color: rgba(225, 225, 225, var(--border-opacity));
}

.border-purple-visited {
  --border-opacity: 1;
  border-color: #8d50b1;
  border-color: rgba(141, 80, 177, var(--border-opacity));
}

.border-purple-visited-light {
  --border-opacity: 1;
  border-color: #e0adff;
  border-color: rgba(224, 173, 255, var(--border-opacity));
}

.border-red {
  --border-opacity: 1;
  border-color: #ff8274;
  border-color: rgba(255, 130, 116, var(--border-opacity));
}

.border-yellow {
  --border-opacity: 1;
  border-color: #f9c66b;
  border-color: rgba(249, 198, 107, var(--border-opacity));
}

.border-state-danger {
  border-color: #FF8274B3;
}

.border-state-warning {
  border-color: #F9C66BB3;
}

.border-state-success {
  border-color: #C7F6C9B3;
}

.border-state-primary {
  border-color: #B3F5FFB3;
}

.hover\:border-white:hover {
  --border-opacity: 1;
  border-color: #FFFFFF;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.hover\:border-black:hover {
  --border-opacity: 1;
  border-color: #2c2c2c;
  border-color: rgba(44, 44, 44, var(--border-opacity));
}

.hover\:border-black-backdrop:hover {
  border-color: #2c2c2c99;
}

.hover\:border-beige:hover {
  --border-opacity: 1;
  border-color: #f8f0dd;
  border-color: rgba(248, 240, 221, var(--border-opacity));
}

.hover\:border-beige-dark:hover {
  --border-opacity: 1;
  border-color: #d0bfae;
  border-color: rgba(208, 191, 174, var(--border-opacity));
}

.hover\:border-blue:hover {
  --border-opacity: 1;
  border-color: #6fe9ff;
  border-color: rgba(111, 233, 255, var(--border-opacity));
}

.hover\:border-blue-dark:hover {
  --border-opacity: 1;
  border-color: #2a2859;
  border-color: rgba(42, 40, 89, var(--border-opacity));
}

.hover\:border-blue-light:hover {
  --border-opacity: 1;
  border-color: #b3f5ff;
  border-color: rgba(179, 245, 255, var(--border-opacity));
}

.hover\:border-blue-hover:hover {
  --border-opacity: 1;
  border-color: #1f42aa;
  border-color: rgba(31, 66, 170, var(--border-opacity));
}

.hover\:border-green:hover {
  --border-opacity: 1;
  border-color: #43f8b6;
  border-color: rgba(67, 248, 182, var(--border-opacity));
}

.hover\:border-green-light:hover {
  --border-opacity: 1;
  border-color: #c7f6c9;
  border-color: rgba(199, 246, 201, var(--border-opacity));
}

.hover\:border-green-dark:hover {
  --border-opacity: 1;
  border-color: #034b45;
  border-color: rgba(3, 75, 69, var(--border-opacity));
}

.hover\:border-grey-10:hover {
  --border-opacity: 1;
  border-color: #e6e6e6;
  border-color: rgba(230, 230, 230, var(--border-opacity));
}

.hover\:border-grey-20:hover {
  --border-opacity: 1;
  border-color: #cccccc;
  border-color: rgba(204, 204, 204, var(--border-opacity));
}

.hover\:border-grey-30:hover {
  --border-opacity: 1;
  border-color: #b3b3b3;
  border-color: rgba(179, 179, 179, var(--border-opacity));
}

.hover\:border-grey-40:hover {
  --border-opacity: 1;
  border-color: #999999;
  border-color: rgba(153, 153, 153, var(--border-opacity));
}

.hover\:border-grey-50:hover {
  --border-opacity: 1;
  border-color: #808080;
  border-color: rgba(128, 128, 128, var(--border-opacity));
}

.hover\:border-grey-60:hover {
  --border-opacity: 1;
  border-color: #666666;
  border-color: rgba(102, 102, 102, var(--border-opacity));
}

.hover\:border-grey-70:hover {
  --border-opacity: 1;
  border-color: #4d4d4d;
  border-color: rgba(77, 77, 77, var(--border-opacity));
}

.hover\:border-grey-80:hover {
  --border-opacity: 1;
  border-color: #333333;
  border-color: rgba(51, 51, 51, var(--border-opacity));
}

.hover\:border-grey-90:hover {
  --border-opacity: 1;
  border-color: #1a1a1a;
  border-color: rgba(26, 26, 26, var(--border-opacity));
}

.hover\:border-grey:hover {
  --border-opacity: 1;
  border-color: #f2f2f2;
  border-color: rgba(242, 242, 242, var(--border-opacity));
}

.hover\:border-grey-light:hover {
  --border-opacity: 1;
  border-color: #f9f9f9;
  border-color: rgba(249, 249, 249, var(--border-opacity));
}

.hover\:border-grey-dark:hover {
  --border-opacity: 1;
  border-color: #e1e1e1;
  border-color: rgba(225, 225, 225, var(--border-opacity));
}

.hover\:border-purple-visited:hover {
  --border-opacity: 1;
  border-color: #8d50b1;
  border-color: rgba(141, 80, 177, var(--border-opacity));
}

.hover\:border-purple-visited-light:hover {
  --border-opacity: 1;
  border-color: #e0adff;
  border-color: rgba(224, 173, 255, var(--border-opacity));
}

.hover\:border-red:hover {
  --border-opacity: 1;
  border-color: #ff8274;
  border-color: rgba(255, 130, 116, var(--border-opacity));
}

.hover\:border-yellow:hover {
  --border-opacity: 1;
  border-color: #f9c66b;
  border-color: rgba(249, 198, 107, var(--border-opacity));
}

.hover\:border-state-danger:hover {
  border-color: #FF8274B3;
}

.hover\:border-state-warning:hover {
  border-color: #F9C66BB3;
}

.hover\:border-state-success:hover {
  border-color: #C7F6C9B3;
}

.hover\:border-state-primary:hover {
  border-color: #B3F5FFB3;
}

.focus\:border-white:focus {
  --border-opacity: 1;
  border-color: #FFFFFF;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.focus\:border-black:focus {
  --border-opacity: 1;
  border-color: #2c2c2c;
  border-color: rgba(44, 44, 44, var(--border-opacity));
}

.focus\:border-black-backdrop:focus {
  border-color: #2c2c2c99;
}

.focus\:border-beige:focus {
  --border-opacity: 1;
  border-color: #f8f0dd;
  border-color: rgba(248, 240, 221, var(--border-opacity));
}

.focus\:border-beige-dark:focus {
  --border-opacity: 1;
  border-color: #d0bfae;
  border-color: rgba(208, 191, 174, var(--border-opacity));
}

.focus\:border-blue:focus {
  --border-opacity: 1;
  border-color: #6fe9ff;
  border-color: rgba(111, 233, 255, var(--border-opacity));
}

.focus\:border-blue-dark:focus {
  --border-opacity: 1;
  border-color: #2a2859;
  border-color: rgba(42, 40, 89, var(--border-opacity));
}

.focus\:border-blue-light:focus {
  --border-opacity: 1;
  border-color: #b3f5ff;
  border-color: rgba(179, 245, 255, var(--border-opacity));
}

.focus\:border-blue-hover:focus {
  --border-opacity: 1;
  border-color: #1f42aa;
  border-color: rgba(31, 66, 170, var(--border-opacity));
}

.focus\:border-green:focus {
  --border-opacity: 1;
  border-color: #43f8b6;
  border-color: rgba(67, 248, 182, var(--border-opacity));
}

.focus\:border-green-light:focus {
  --border-opacity: 1;
  border-color: #c7f6c9;
  border-color: rgba(199, 246, 201, var(--border-opacity));
}

.focus\:border-green-dark:focus {
  --border-opacity: 1;
  border-color: #034b45;
  border-color: rgba(3, 75, 69, var(--border-opacity));
}

.focus\:border-grey-10:focus {
  --border-opacity: 1;
  border-color: #e6e6e6;
  border-color: rgba(230, 230, 230, var(--border-opacity));
}

.focus\:border-grey-20:focus {
  --border-opacity: 1;
  border-color: #cccccc;
  border-color: rgba(204, 204, 204, var(--border-opacity));
}

.focus\:border-grey-30:focus {
  --border-opacity: 1;
  border-color: #b3b3b3;
  border-color: rgba(179, 179, 179, var(--border-opacity));
}

.focus\:border-grey-40:focus {
  --border-opacity: 1;
  border-color: #999999;
  border-color: rgba(153, 153, 153, var(--border-opacity));
}

.focus\:border-grey-50:focus {
  --border-opacity: 1;
  border-color: #808080;
  border-color: rgba(128, 128, 128, var(--border-opacity));
}

.focus\:border-grey-60:focus {
  --border-opacity: 1;
  border-color: #666666;
  border-color: rgba(102, 102, 102, var(--border-opacity));
}

.focus\:border-grey-70:focus {
  --border-opacity: 1;
  border-color: #4d4d4d;
  border-color: rgba(77, 77, 77, var(--border-opacity));
}

.focus\:border-grey-80:focus {
  --border-opacity: 1;
  border-color: #333333;
  border-color: rgba(51, 51, 51, var(--border-opacity));
}

.focus\:border-grey-90:focus {
  --border-opacity: 1;
  border-color: #1a1a1a;
  border-color: rgba(26, 26, 26, var(--border-opacity));
}

.focus\:border-grey:focus {
  --border-opacity: 1;
  border-color: #f2f2f2;
  border-color: rgba(242, 242, 242, var(--border-opacity));
}

.focus\:border-grey-light:focus {
  --border-opacity: 1;
  border-color: #f9f9f9;
  border-color: rgba(249, 249, 249, var(--border-opacity));
}

.focus\:border-grey-dark:focus {
  --border-opacity: 1;
  border-color: #e1e1e1;
  border-color: rgba(225, 225, 225, var(--border-opacity));
}

.focus\:border-purple-visited:focus {
  --border-opacity: 1;
  border-color: #8d50b1;
  border-color: rgba(141, 80, 177, var(--border-opacity));
}

.focus\:border-purple-visited-light:focus {
  --border-opacity: 1;
  border-color: #e0adff;
  border-color: rgba(224, 173, 255, var(--border-opacity));
}

.focus\:border-red:focus {
  --border-opacity: 1;
  border-color: #ff8274;
  border-color: rgba(255, 130, 116, var(--border-opacity));
}

.focus\:border-yellow:focus {
  --border-opacity: 1;
  border-color: #f9c66b;
  border-color: rgba(249, 198, 107, var(--border-opacity));
}

.focus\:border-state-danger:focus {
  border-color: #FF8274B3;
}

.focus\:border-state-warning:focus {
  border-color: #F9C66BB3;
}

.focus\:border-state-success:focus {
  border-color: #C7F6C9B3;
}

.focus\:border-state-primary:focus {
  border-color: #B3F5FFB3;
}

.border-opacity-0 {
  --border-opacity: 0;
}

.border-opacity-25 {
  --border-opacity: 0.25;
}

.border-opacity-50 {
  --border-opacity: 0.5;
}

.border-opacity-75 {
  --border-opacity: 0.75;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0;
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25;
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5;
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75;
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1;
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0;
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25;
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5;
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75;
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.rounded-tl {
  border-top-left-radius: 0.25rem;
}

.rounded-tr {
  border-top-right-radius: 0.25rem;
}

.rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.hover\:border-0:hover {
  border-width: 0;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-4:hover {
  border-width: 4px;
}

.hover\:border-8:hover {
  border-width: 8px;
}

.hover\:border:hover {
  border-width: 1px;
}

.hover\:border-t-0:hover {
  border-top-width: 0;
}

.hover\:border-r-0:hover {
  border-right-width: 0;
}

.hover\:border-b-0:hover {
  border-bottom-width: 0;
}

.hover\:border-l-0:hover {
  border-left-width: 0;
}

.hover\:border-t-2:hover {
  border-top-width: 2px;
}

.hover\:border-r-2:hover {
  border-right-width: 2px;
}

.hover\:border-b-2:hover {
  border-bottom-width: 2px;
}

.hover\:border-l-2:hover {
  border-left-width: 2px;
}

.hover\:border-t-4:hover {
  border-top-width: 4px;
}

.hover\:border-r-4:hover {
  border-right-width: 4px;
}

.hover\:border-b-4:hover {
  border-bottom-width: 4px;
}

.hover\:border-l-4:hover {
  border-left-width: 4px;
}

.hover\:border-t-8:hover {
  border-top-width: 8px;
}

.hover\:border-r-8:hover {
  border-right-width: 8px;
}

.hover\:border-b-8:hover {
  border-bottom-width: 8px;
}

.hover\:border-l-8:hover {
  border-left-width: 8px;
}

.hover\:border-t:hover {
  border-top-width: 1px;
}

.hover\:border-r:hover {
  border-right-width: 1px;
}

.hover\:border-b:hover {
  border-bottom-width: 1px;
}

.hover\:border-l:hover {
  border-left-width: 1px;
}

.focus\:border-0:focus {
  border-width: 0;
}

.focus\:border-2:focus {
  border-width: 2px;
}

.focus\:border-4:focus {
  border-width: 4px;
}

.focus\:border-8:focus {
  border-width: 8px;
}

.focus\:border:focus {
  border-width: 1px;
}

.focus\:border-t-0:focus {
  border-top-width: 0;
}

.focus\:border-r-0:focus {
  border-right-width: 0;
}

.focus\:border-b-0:focus {
  border-bottom-width: 0;
}

.focus\:border-l-0:focus {
  border-left-width: 0;
}

.focus\:border-t-2:focus {
  border-top-width: 2px;
}

.focus\:border-r-2:focus {
  border-right-width: 2px;
}

.focus\:border-b-2:focus {
  border-bottom-width: 2px;
}

.focus\:border-l-2:focus {
  border-left-width: 2px;
}

.focus\:border-t-4:focus {
  border-top-width: 4px;
}

.focus\:border-r-4:focus {
  border-right-width: 4px;
}

.focus\:border-b-4:focus {
  border-bottom-width: 4px;
}

.focus\:border-l-4:focus {
  border-left-width: 4px;
}

.focus\:border-t-8:focus {
  border-top-width: 8px;
}

.focus\:border-r-8:focus {
  border-right-width: 8px;
}

.focus\:border-b-8:focus {
  border-bottom-width: 8px;
}

.focus\:border-l-8:focus {
  border-left-width: 8px;
}

.focus\:border-t:focus {
  border-top-width: 1px;
}

.focus\:border-r:focus {
  border-right-width: 1px;
}

.focus\:border-b:focus {
  border-bottom-width: 1px;
}

.focus\:border-l:focus {
  border-left-width: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.hover\:cursor-auto:hover {
  cursor: auto;
}

.hover\:cursor-default:hover {
  cursor: default;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:cursor-wait:hover {
  cursor: wait;
}

.hover\:cursor-text:hover {
  cursor: text;
}

.hover\:cursor-move:hover {
  cursor: move;
}

.hover\:cursor-not-allowed:hover {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.flex-col-reverse {
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.place-items-auto {
  place-items: auto;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch;
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.items-center {
  -webkit-align-items: center;
          align-items: center;
}

.items-baseline {
  -webkit-align-items: baseline;
          align-items: baseline;
}

.items-stretch {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.content-center {
  -webkit-align-content: center;
          align-content: center;
}

.content-start {
  -webkit-align-content: flex-start;
          align-content: flex-start;
}

.content-end {
  -webkit-align-content: flex-end;
          align-content: flex-end;
}

.content-between {
  -webkit-align-content: space-between;
          align-content: space-between;
}

.content-around {
  -webkit-align-content: space-around;
          align-content: space-around;
}

.content-evenly {
  -webkit-align-content: space-evenly;
          align-content: space-evenly;
}

.self-auto {
  -webkit-align-self: auto;
          align-self: auto;
}

.self-start {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.self-end {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.self-center {
  -webkit-align-self: center;
          align-self: center;
}

.self-stretch {
  -webkit-align-self: stretch;
          align-self: stretch;
}

.justify-items-auto {
  justify-items: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center;
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.justify-around {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.justify-evenly {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%;
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}

.flex-initial {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
}

.flex-none {
  -webkit-flex: none;
          flex: none;
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.flex-shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
}

.order-1 {
  -webkit-order: 1;
          order: 1;
}

.order-2 {
  -webkit-order: 2;
          order: 2;
}

.order-3 {
  -webkit-order: 3;
          order: 3;
}

.order-4 {
  -webkit-order: 4;
          order: 4;
}

.order-5 {
  -webkit-order: 5;
          order: 5;
}

.order-6 {
  -webkit-order: 6;
          order: 6;
}

.order-7 {
  -webkit-order: 7;
          order: 7;
}

.order-8 {
  -webkit-order: 8;
          order: 8;
}

.order-9 {
  -webkit-order: 9;
          order: 9;
}

.order-10 {
  -webkit-order: 10;
          order: 10;
}

.order-11 {
  -webkit-order: 11;
          order: 11;
}

.order-12 {
  -webkit-order: 12;
          order: 12;
}

.order-first {
  -webkit-order: -9999;
          order: -9999;
}

.order-last {
  -webkit-order: 9999;
          order: 9999;
}

.order-none {
  -webkit-order: 0;
          order: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.font-hairline {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.hover\:font-hairline:hover {
  font-weight: 100;
}

.hover\:font-thin:hover {
  font-weight: 200;
}

.hover\:font-light:hover {
  font-weight: 300;
}

.hover\:font-normal:hover {
  font-weight: 400;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:font-bold:hover {
  font-weight: 700;
}

.hover\:font-extrabold:hover {
  font-weight: 800;
}

.hover\:font-black:hover {
  font-weight: 900;
}

.focus\:font-hairline:focus {
  font-weight: 100;
}

.focus\:font-thin:focus {
  font-weight: 200;
}

.focus\:font-light:focus {
  font-weight: 300;
}

.focus\:font-normal:focus {
  font-weight: 400;
}

.focus\:font-medium:focus {
  font-weight: 500;
}

.focus\:font-semibold:focus {
  font-weight: 600;
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:font-extrabold:focus {
  font-weight: 800;
}

.focus\:font-black:focus {
  font-weight: 900;
}

.h-1 {
  height: 5px;
}

.h-2 {
  height: 10px;
}

.h-3 {
  height: 15px;
}

.h-4 {
  height: 30px;
}

.h-5 {
  height: 50px;
}

.h-6 {
  height: 75px;
}

.h-7 {
  height: 100px;
}

.h-logo-height-small {
  height: 55px;
}

.h-logo-height-large {
  height: 65px;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.m-1 {
  margin: 5px;
}

.m-2 {
  margin: 10px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 30px;
}

.m-5 {
  margin: 50px;
}

.m-6 {
  margin: 75px;
}

.m-7 {
  margin: 100px;
}

.m-auto {
  margin: auto;
}

.m-none {
  margin: 0px;
}

.m-tiny {
  margin: 10px;
}

.m-small {
  margin: 20px;
}

.m-medium {
  margin: 30px;
}

.m-large {
  margin: 40px;
}

.m-huge {
  margin: 60px;
}

.m-margin-label-height {
  margin: 26px;
}

.m-nav-height {
  margin: 80px;
}

.-m-1 {
  margin: -5px;
}

.-m-2 {
  margin: -10px;
}

.-m-3 {
  margin: -15px;
}

.-m-4 {
  margin: -30px;
}

.-m-5 {
  margin: -50px;
}

.-m-6 {
  margin: -75px;
}

.-m-7 {
  margin: -100px;
}

.-m-none {
  margin: 0px;
}

.-m-tiny {
  margin: -10px;
}

.-m-small {
  margin: -20px;
}

.-m-medium {
  margin: -30px;
}

.-m-large {
  margin: -40px;
}

.-m-huge {
  margin: -60px;
}

.-m-margin-label-height {
  margin: -26px;
}

.-m-nav-height {
  margin: -80px;
}

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-3 {
  margin-left: 15px;
  margin-right: 15px;
}

.my-4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-4 {
  margin-left: 30px;
  margin-right: 30px;
}

.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-5 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-6 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mx-6 {
  margin-left: 75px;
  margin-right: 75px;
}

.my-7 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mx-7 {
  margin-left: 100px;
  margin-right: 100px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-none {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-none {
  margin-left: 0px;
  margin-right: 0px;
}

.my-tiny {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-tiny {
  margin-left: 10px;
  margin-right: 10px;
}

.my-small {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-small {
  margin-left: 20px;
  margin-right: 20px;
}

.my-medium {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-medium {
  margin-left: 30px;
  margin-right: 30px;
}

.my-large {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-large {
  margin-left: 40px;
  margin-right: 40px;
}

.my-huge {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mx-huge {
  margin-left: 60px;
  margin-right: 60px;
}

.my-margin-label-height {
  margin-top: 26px;
  margin-bottom: 26px;
}

.mx-margin-label-height {
  margin-left: 26px;
  margin-right: 26px;
}

.my-nav-height {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mx-nav-height {
  margin-left: 80px;
  margin-right: 80px;
}

.-my-1 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.-mx-1 {
  margin-left: -5px;
  margin-right: -5px;
}

.-my-2 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-mx-2 {
  margin-left: -10px;
  margin-right: -10px;
}

.-my-3 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.-mx-3 {
  margin-left: -15px;
  margin-right: -15px;
}

.-my-4 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-mx-4 {
  margin-left: -30px;
  margin-right: -30px;
}

.-my-5 {
  margin-top: -50px;
  margin-bottom: -50px;
}

.-mx-5 {
  margin-left: -50px;
  margin-right: -50px;
}

.-my-6 {
  margin-top: -75px;
  margin-bottom: -75px;
}

.-mx-6 {
  margin-left: -75px;
  margin-right: -75px;
}

.-my-7 {
  margin-top: -100px;
  margin-bottom: -100px;
}

.-mx-7 {
  margin-left: -100px;
  margin-right: -100px;
}

.-my-none {
  margin-top: 0px;
  margin-bottom: 0px;
}

.-mx-none {
  margin-left: 0px;
  margin-right: 0px;
}

.-my-tiny {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-mx-tiny {
  margin-left: -10px;
  margin-right: -10px;
}

.-my-small {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-mx-small {
  margin-left: -20px;
  margin-right: -20px;
}

.-my-medium {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-mx-medium {
  margin-left: -30px;
  margin-right: -30px;
}

.-my-large {
  margin-top: -40px;
  margin-bottom: -40px;
}

.-mx-large {
  margin-left: -40px;
  margin-right: -40px;
}

.-my-huge {
  margin-top: -60px;
  margin-bottom: -60px;
}

.-mx-huge {
  margin-left: -60px;
  margin-right: -60px;
}

.-my-margin-label-height {
  margin-top: -26px;
  margin-bottom: -26px;
}

.-mx-margin-label-height {
  margin-left: -26px;
  margin-right: -26px;
}

.-my-nav-height {
  margin-top: -80px;
  margin-bottom: -80px;
}

.-mx-nav-height {
  margin-left: -80px;
  margin-right: -80px;
}

.mt-1 {
  margin-top: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.ml-2 {
  margin-left: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mr-3 {
  margin-right: 15px;
}

.mb-3 {
  margin-bottom: 15px;
}

.ml-3 {
  margin-left: 15px;
}

.mt-4 {
  margin-top: 30px;
}

.mr-4 {
  margin-right: 30px;
}

.mb-4 {
  margin-bottom: 30px;
}

.ml-4 {
  margin-left: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.mr-5 {
  margin-right: 50px;
}

.mb-5 {
  margin-bottom: 50px;
}

.ml-5 {
  margin-left: 50px;
}

.mt-6 {
  margin-top: 75px;
}

.mr-6 {
  margin-right: 75px;
}

.mb-6 {
  margin-bottom: 75px;
}

.ml-6 {
  margin-left: 75px;
}

.mt-7 {
  margin-top: 100px;
}

.mr-7 {
  margin-right: 100px;
}

.mb-7 {
  margin-bottom: 100px;
}

.ml-7 {
  margin-left: 100px;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-none {
  margin-top: 0px;
}

.mr-none {
  margin-right: 0px;
}

.mb-none {
  margin-bottom: 0px;
}

.ml-none {
  margin-left: 0px;
}

.mt-tiny {
  margin-top: 10px;
}

.mr-tiny {
  margin-right: 10px;
}

.mb-tiny {
  margin-bottom: 10px;
}

.ml-tiny {
  margin-left: 10px;
}

.mt-small {
  margin-top: 20px;
}

.mr-small {
  margin-right: 20px;
}

.mb-small {
  margin-bottom: 20px;
}

.ml-small {
  margin-left: 20px;
}

.mt-medium {
  margin-top: 30px;
}

.mr-medium {
  margin-right: 30px;
}

.mb-medium {
  margin-bottom: 30px;
}

.ml-medium {
  margin-left: 30px;
}

.mt-large {
  margin-top: 40px;
}

.mr-large {
  margin-right: 40px;
}

.mb-large {
  margin-bottom: 40px;
}

.ml-large {
  margin-left: 40px;
}

.mt-huge {
  margin-top: 60px;
}

.mr-huge {
  margin-right: 60px;
}

.mb-huge {
  margin-bottom: 60px;
}

.ml-huge {
  margin-left: 60px;
}

.mt-margin-label-height {
  margin-top: 26px;
}

.mr-margin-label-height {
  margin-right: 26px;
}

.mb-margin-label-height {
  margin-bottom: 26px;
}

.ml-margin-label-height {
  margin-left: 26px;
}

.mt-nav-height {
  margin-top: 80px;
}

.mr-nav-height {
  margin-right: 80px;
}

.mb-nav-height {
  margin-bottom: 80px;
}

.ml-nav-height {
  margin-left: 80px;
}

.-mt-1 {
  margin-top: -5px;
}

.-mr-1 {
  margin-right: -5px;
}

.-mb-1 {
  margin-bottom: -5px;
}

.-ml-1 {
  margin-left: -5px;
}

.-mt-2 {
  margin-top: -10px;
}

.-mr-2 {
  margin-right: -10px;
}

.-mb-2 {
  margin-bottom: -10px;
}

.-ml-2 {
  margin-left: -10px;
}

.-mt-3 {
  margin-top: -15px;
}

.-mr-3 {
  margin-right: -15px;
}

.-mb-3 {
  margin-bottom: -15px;
}

.-ml-3 {
  margin-left: -15px;
}

.-mt-4 {
  margin-top: -30px;
}

.-mr-4 {
  margin-right: -30px;
}

.-mb-4 {
  margin-bottom: -30px;
}

.-ml-4 {
  margin-left: -30px;
}

.-mt-5 {
  margin-top: -50px;
}

.-mr-5 {
  margin-right: -50px;
}

.-mb-5 {
  margin-bottom: -50px;
}

.-ml-5 {
  margin-left: -50px;
}

.-mt-6 {
  margin-top: -75px;
}

.-mr-6 {
  margin-right: -75px;
}

.-mb-6 {
  margin-bottom: -75px;
}

.-ml-6 {
  margin-left: -75px;
}

.-mt-7 {
  margin-top: -100px;
}

.-mr-7 {
  margin-right: -100px;
}

.-mb-7 {
  margin-bottom: -100px;
}

.-ml-7 {
  margin-left: -100px;
}

.-mt-none {
  margin-top: 0px;
}

.-mr-none {
  margin-right: 0px;
}

.-mb-none {
  margin-bottom: 0px;
}

.-ml-none {
  margin-left: 0px;
}

.-mt-tiny {
  margin-top: -10px;
}

.-mr-tiny {
  margin-right: -10px;
}

.-mb-tiny {
  margin-bottom: -10px;
}

.-ml-tiny {
  margin-left: -10px;
}

.-mt-small {
  margin-top: -20px;
}

.-mr-small {
  margin-right: -20px;
}

.-mb-small {
  margin-bottom: -20px;
}

.-ml-small {
  margin-left: -20px;
}

.-mt-medium {
  margin-top: -30px;
}

.-mr-medium {
  margin-right: -30px;
}

.-mb-medium {
  margin-bottom: -30px;
}

.-ml-medium {
  margin-left: -30px;
}

.-mt-large {
  margin-top: -40px;
}

.-mr-large {
  margin-right: -40px;
}

.-mb-large {
  margin-bottom: -40px;
}

.-ml-large {
  margin-left: -40px;
}

.-mt-huge {
  margin-top: -60px;
}

.-mr-huge {
  margin-right: -60px;
}

.-mb-huge {
  margin-bottom: -60px;
}

.-ml-huge {
  margin-left: -60px;
}

.-mt-margin-label-height {
  margin-top: -26px;
}

.-mr-margin-label-height {
  margin-right: -26px;
}

.-mb-margin-label-height {
  margin-bottom: -26px;
}

.-ml-margin-label-height {
  margin-left: -26px;
}

.-mt-nav-height {
  margin-top: -80px;
}

.-mr-nav-height {
  margin-right: -80px;
}

.-mb-nav-height {
  margin-bottom: -80px;
}

.-ml-nav-height {
  margin-left: -80px;
}

.hover\:m-1:hover {
  margin: 5px;
}

.hover\:m-2:hover {
  margin: 10px;
}

.hover\:m-3:hover {
  margin: 15px;
}

.hover\:m-4:hover {
  margin: 30px;
}

.hover\:m-5:hover {
  margin: 50px;
}

.hover\:m-6:hover {
  margin: 75px;
}

.hover\:m-7:hover {
  margin: 100px;
}

.hover\:m-auto:hover {
  margin: auto;
}

.hover\:m-none:hover {
  margin: 0px;
}

.hover\:m-tiny:hover {
  margin: 10px;
}

.hover\:m-small:hover {
  margin: 20px;
}

.hover\:m-medium:hover {
  margin: 30px;
}

.hover\:m-large:hover {
  margin: 40px;
}

.hover\:m-huge:hover {
  margin: 60px;
}

.hover\:m-margin-label-height:hover {
  margin: 26px;
}

.hover\:m-nav-height:hover {
  margin: 80px;
}

.hover\:-m-1:hover {
  margin: -5px;
}

.hover\:-m-2:hover {
  margin: -10px;
}

.hover\:-m-3:hover {
  margin: -15px;
}

.hover\:-m-4:hover {
  margin: -30px;
}

.hover\:-m-5:hover {
  margin: -50px;
}

.hover\:-m-6:hover {
  margin: -75px;
}

.hover\:-m-7:hover {
  margin: -100px;
}

.hover\:-m-none:hover {
  margin: 0px;
}

.hover\:-m-tiny:hover {
  margin: -10px;
}

.hover\:-m-small:hover {
  margin: -20px;
}

.hover\:-m-medium:hover {
  margin: -30px;
}

.hover\:-m-large:hover {
  margin: -40px;
}

.hover\:-m-huge:hover {
  margin: -60px;
}

.hover\:-m-margin-label-height:hover {
  margin: -26px;
}

.hover\:-m-nav-height:hover {
  margin: -80px;
}

.hover\:my-1:hover {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hover\:mx-1:hover {
  margin-left: 5px;
  margin-right: 5px;
}

.hover\:my-2:hover {
  margin-top: 10px;
  margin-bottom: 10px;
}

.hover\:mx-2:hover {
  margin-left: 10px;
  margin-right: 10px;
}

.hover\:my-3:hover {
  margin-top: 15px;
  margin-bottom: 15px;
}

.hover\:mx-3:hover {
  margin-left: 15px;
  margin-right: 15px;
}

.hover\:my-4:hover {
  margin-top: 30px;
  margin-bottom: 30px;
}

.hover\:mx-4:hover {
  margin-left: 30px;
  margin-right: 30px;
}

.hover\:my-5:hover {
  margin-top: 50px;
  margin-bottom: 50px;
}

.hover\:mx-5:hover {
  margin-left: 50px;
  margin-right: 50px;
}

.hover\:my-6:hover {
  margin-top: 75px;
  margin-bottom: 75px;
}

.hover\:mx-6:hover {
  margin-left: 75px;
  margin-right: 75px;
}

.hover\:my-7:hover {
  margin-top: 100px;
  margin-bottom: 100px;
}

.hover\:mx-7:hover {
  margin-left: 100px;
  margin-right: 100px;
}

.hover\:my-auto:hover {
  margin-top: auto;
  margin-bottom: auto;
}

.hover\:mx-auto:hover {
  margin-left: auto;
  margin-right: auto;
}

.hover\:my-none:hover {
  margin-top: 0px;
  margin-bottom: 0px;
}

.hover\:mx-none:hover {
  margin-left: 0px;
  margin-right: 0px;
}

.hover\:my-tiny:hover {
  margin-top: 10px;
  margin-bottom: 10px;
}

.hover\:mx-tiny:hover {
  margin-left: 10px;
  margin-right: 10px;
}

.hover\:my-small:hover {
  margin-top: 20px;
  margin-bottom: 20px;
}

.hover\:mx-small:hover {
  margin-left: 20px;
  margin-right: 20px;
}

.hover\:my-medium:hover {
  margin-top: 30px;
  margin-bottom: 30px;
}

.hover\:mx-medium:hover {
  margin-left: 30px;
  margin-right: 30px;
}

.hover\:my-large:hover {
  margin-top: 40px;
  margin-bottom: 40px;
}

.hover\:mx-large:hover {
  margin-left: 40px;
  margin-right: 40px;
}

.hover\:my-huge:hover {
  margin-top: 60px;
  margin-bottom: 60px;
}

.hover\:mx-huge:hover {
  margin-left: 60px;
  margin-right: 60px;
}

.hover\:my-margin-label-height:hover {
  margin-top: 26px;
  margin-bottom: 26px;
}

.hover\:mx-margin-label-height:hover {
  margin-left: 26px;
  margin-right: 26px;
}

.hover\:my-nav-height:hover {
  margin-top: 80px;
  margin-bottom: 80px;
}

.hover\:mx-nav-height:hover {
  margin-left: 80px;
  margin-right: 80px;
}

.hover\:-my-1:hover {
  margin-top: -5px;
  margin-bottom: -5px;
}

.hover\:-mx-1:hover {
  margin-left: -5px;
  margin-right: -5px;
}

.hover\:-my-2:hover {
  margin-top: -10px;
  margin-bottom: -10px;
}

.hover\:-mx-2:hover {
  margin-left: -10px;
  margin-right: -10px;
}

.hover\:-my-3:hover {
  margin-top: -15px;
  margin-bottom: -15px;
}

.hover\:-mx-3:hover {
  margin-left: -15px;
  margin-right: -15px;
}

.hover\:-my-4:hover {
  margin-top: -30px;
  margin-bottom: -30px;
}

.hover\:-mx-4:hover {
  margin-left: -30px;
  margin-right: -30px;
}

.hover\:-my-5:hover {
  margin-top: -50px;
  margin-bottom: -50px;
}

.hover\:-mx-5:hover {
  margin-left: -50px;
  margin-right: -50px;
}

.hover\:-my-6:hover {
  margin-top: -75px;
  margin-bottom: -75px;
}

.hover\:-mx-6:hover {
  margin-left: -75px;
  margin-right: -75px;
}

.hover\:-my-7:hover {
  margin-top: -100px;
  margin-bottom: -100px;
}

.hover\:-mx-7:hover {
  margin-left: -100px;
  margin-right: -100px;
}

.hover\:-my-none:hover {
  margin-top: 0px;
  margin-bottom: 0px;
}

.hover\:-mx-none:hover {
  margin-left: 0px;
  margin-right: 0px;
}

.hover\:-my-tiny:hover {
  margin-top: -10px;
  margin-bottom: -10px;
}

.hover\:-mx-tiny:hover {
  margin-left: -10px;
  margin-right: -10px;
}

.hover\:-my-small:hover {
  margin-top: -20px;
  margin-bottom: -20px;
}

.hover\:-mx-small:hover {
  margin-left: -20px;
  margin-right: -20px;
}

.hover\:-my-medium:hover {
  margin-top: -30px;
  margin-bottom: -30px;
}

.hover\:-mx-medium:hover {
  margin-left: -30px;
  margin-right: -30px;
}

.hover\:-my-large:hover {
  margin-top: -40px;
  margin-bottom: -40px;
}

.hover\:-mx-large:hover {
  margin-left: -40px;
  margin-right: -40px;
}

.hover\:-my-huge:hover {
  margin-top: -60px;
  margin-bottom: -60px;
}

.hover\:-mx-huge:hover {
  margin-left: -60px;
  margin-right: -60px;
}

.hover\:-my-margin-label-height:hover {
  margin-top: -26px;
  margin-bottom: -26px;
}

.hover\:-mx-margin-label-height:hover {
  margin-left: -26px;
  margin-right: -26px;
}

.hover\:-my-nav-height:hover {
  margin-top: -80px;
  margin-bottom: -80px;
}

.hover\:-mx-nav-height:hover {
  margin-left: -80px;
  margin-right: -80px;
}

.hover\:mt-1:hover {
  margin-top: 5px;
}

.hover\:mr-1:hover {
  margin-right: 5px;
}

.hover\:mb-1:hover {
  margin-bottom: 5px;
}

.hover\:ml-1:hover {
  margin-left: 5px;
}

.hover\:mt-2:hover {
  margin-top: 10px;
}

.hover\:mr-2:hover {
  margin-right: 10px;
}

.hover\:mb-2:hover {
  margin-bottom: 10px;
}

.hover\:ml-2:hover {
  margin-left: 10px;
}

.hover\:mt-3:hover {
  margin-top: 15px;
}

.hover\:mr-3:hover {
  margin-right: 15px;
}

.hover\:mb-3:hover {
  margin-bottom: 15px;
}

.hover\:ml-3:hover {
  margin-left: 15px;
}

.hover\:mt-4:hover {
  margin-top: 30px;
}

.hover\:mr-4:hover {
  margin-right: 30px;
}

.hover\:mb-4:hover {
  margin-bottom: 30px;
}

.hover\:ml-4:hover {
  margin-left: 30px;
}

.hover\:mt-5:hover {
  margin-top: 50px;
}

.hover\:mr-5:hover {
  margin-right: 50px;
}

.hover\:mb-5:hover {
  margin-bottom: 50px;
}

.hover\:ml-5:hover {
  margin-left: 50px;
}

.hover\:mt-6:hover {
  margin-top: 75px;
}

.hover\:mr-6:hover {
  margin-right: 75px;
}

.hover\:mb-6:hover {
  margin-bottom: 75px;
}

.hover\:ml-6:hover {
  margin-left: 75px;
}

.hover\:mt-7:hover {
  margin-top: 100px;
}

.hover\:mr-7:hover {
  margin-right: 100px;
}

.hover\:mb-7:hover {
  margin-bottom: 100px;
}

.hover\:ml-7:hover {
  margin-left: 100px;
}

.hover\:mt-auto:hover {
  margin-top: auto;
}

.hover\:mr-auto:hover {
  margin-right: auto;
}

.hover\:mb-auto:hover {
  margin-bottom: auto;
}

.hover\:ml-auto:hover {
  margin-left: auto;
}

.hover\:mt-none:hover {
  margin-top: 0px;
}

.hover\:mr-none:hover {
  margin-right: 0px;
}

.hover\:mb-none:hover {
  margin-bottom: 0px;
}

.hover\:ml-none:hover {
  margin-left: 0px;
}

.hover\:mt-tiny:hover {
  margin-top: 10px;
}

.hover\:mr-tiny:hover {
  margin-right: 10px;
}

.hover\:mb-tiny:hover {
  margin-bottom: 10px;
}

.hover\:ml-tiny:hover {
  margin-left: 10px;
}

.hover\:mt-small:hover {
  margin-top: 20px;
}

.hover\:mr-small:hover {
  margin-right: 20px;
}

.hover\:mb-small:hover {
  margin-bottom: 20px;
}

.hover\:ml-small:hover {
  margin-left: 20px;
}

.hover\:mt-medium:hover {
  margin-top: 30px;
}

.hover\:mr-medium:hover {
  margin-right: 30px;
}

.hover\:mb-medium:hover {
  margin-bottom: 30px;
}

.hover\:ml-medium:hover {
  margin-left: 30px;
}

.hover\:mt-large:hover {
  margin-top: 40px;
}

.hover\:mr-large:hover {
  margin-right: 40px;
}

.hover\:mb-large:hover {
  margin-bottom: 40px;
}

.hover\:ml-large:hover {
  margin-left: 40px;
}

.hover\:mt-huge:hover {
  margin-top: 60px;
}

.hover\:mr-huge:hover {
  margin-right: 60px;
}

.hover\:mb-huge:hover {
  margin-bottom: 60px;
}

.hover\:ml-huge:hover {
  margin-left: 60px;
}

.hover\:mt-margin-label-height:hover {
  margin-top: 26px;
}

.hover\:mr-margin-label-height:hover {
  margin-right: 26px;
}

.hover\:mb-margin-label-height:hover {
  margin-bottom: 26px;
}

.hover\:ml-margin-label-height:hover {
  margin-left: 26px;
}

.hover\:mt-nav-height:hover {
  margin-top: 80px;
}

.hover\:mr-nav-height:hover {
  margin-right: 80px;
}

.hover\:mb-nav-height:hover {
  margin-bottom: 80px;
}

.hover\:ml-nav-height:hover {
  margin-left: 80px;
}

.hover\:-mt-1:hover {
  margin-top: -5px;
}

.hover\:-mr-1:hover {
  margin-right: -5px;
}

.hover\:-mb-1:hover {
  margin-bottom: -5px;
}

.hover\:-ml-1:hover {
  margin-left: -5px;
}

.hover\:-mt-2:hover {
  margin-top: -10px;
}

.hover\:-mr-2:hover {
  margin-right: -10px;
}

.hover\:-mb-2:hover {
  margin-bottom: -10px;
}

.hover\:-ml-2:hover {
  margin-left: -10px;
}

.hover\:-mt-3:hover {
  margin-top: -15px;
}

.hover\:-mr-3:hover {
  margin-right: -15px;
}

.hover\:-mb-3:hover {
  margin-bottom: -15px;
}

.hover\:-ml-3:hover {
  margin-left: -15px;
}

.hover\:-mt-4:hover {
  margin-top: -30px;
}

.hover\:-mr-4:hover {
  margin-right: -30px;
}

.hover\:-mb-4:hover {
  margin-bottom: -30px;
}

.hover\:-ml-4:hover {
  margin-left: -30px;
}

.hover\:-mt-5:hover {
  margin-top: -50px;
}

.hover\:-mr-5:hover {
  margin-right: -50px;
}

.hover\:-mb-5:hover {
  margin-bottom: -50px;
}

.hover\:-ml-5:hover {
  margin-left: -50px;
}

.hover\:-mt-6:hover {
  margin-top: -75px;
}

.hover\:-mr-6:hover {
  margin-right: -75px;
}

.hover\:-mb-6:hover {
  margin-bottom: -75px;
}

.hover\:-ml-6:hover {
  margin-left: -75px;
}

.hover\:-mt-7:hover {
  margin-top: -100px;
}

.hover\:-mr-7:hover {
  margin-right: -100px;
}

.hover\:-mb-7:hover {
  margin-bottom: -100px;
}

.hover\:-ml-7:hover {
  margin-left: -100px;
}

.hover\:-mt-none:hover {
  margin-top: 0px;
}

.hover\:-mr-none:hover {
  margin-right: 0px;
}

.hover\:-mb-none:hover {
  margin-bottom: 0px;
}

.hover\:-ml-none:hover {
  margin-left: 0px;
}

.hover\:-mt-tiny:hover {
  margin-top: -10px;
}

.hover\:-mr-tiny:hover {
  margin-right: -10px;
}

.hover\:-mb-tiny:hover {
  margin-bottom: -10px;
}

.hover\:-ml-tiny:hover {
  margin-left: -10px;
}

.hover\:-mt-small:hover {
  margin-top: -20px;
}

.hover\:-mr-small:hover {
  margin-right: -20px;
}

.hover\:-mb-small:hover {
  margin-bottom: -20px;
}

.hover\:-ml-small:hover {
  margin-left: -20px;
}

.hover\:-mt-medium:hover {
  margin-top: -30px;
}

.hover\:-mr-medium:hover {
  margin-right: -30px;
}

.hover\:-mb-medium:hover {
  margin-bottom: -30px;
}

.hover\:-ml-medium:hover {
  margin-left: -30px;
}

.hover\:-mt-large:hover {
  margin-top: -40px;
}

.hover\:-mr-large:hover {
  margin-right: -40px;
}

.hover\:-mb-large:hover {
  margin-bottom: -40px;
}

.hover\:-ml-large:hover {
  margin-left: -40px;
}

.hover\:-mt-huge:hover {
  margin-top: -60px;
}

.hover\:-mr-huge:hover {
  margin-right: -60px;
}

.hover\:-mb-huge:hover {
  margin-bottom: -60px;
}

.hover\:-ml-huge:hover {
  margin-left: -60px;
}

.hover\:-mt-margin-label-height:hover {
  margin-top: -26px;
}

.hover\:-mr-margin-label-height:hover {
  margin-right: -26px;
}

.hover\:-mb-margin-label-height:hover {
  margin-bottom: -26px;
}

.hover\:-ml-margin-label-height:hover {
  margin-left: -26px;
}

.hover\:-mt-nav-height:hover {
  margin-top: -80px;
}

.hover\:-mr-nav-height:hover {
  margin-right: -80px;
}

.hover\:-mb-nav-height:hover {
  margin-bottom: -80px;
}

.hover\:-ml-nav-height:hover {
  margin-left: -80px;
}

.focus\:m-1:focus {
  margin: 5px;
}

.focus\:m-2:focus {
  margin: 10px;
}

.focus\:m-3:focus {
  margin: 15px;
}

.focus\:m-4:focus {
  margin: 30px;
}

.focus\:m-5:focus {
  margin: 50px;
}

.focus\:m-6:focus {
  margin: 75px;
}

.focus\:m-7:focus {
  margin: 100px;
}

.focus\:m-auto:focus {
  margin: auto;
}

.focus\:m-none:focus {
  margin: 0px;
}

.focus\:m-tiny:focus {
  margin: 10px;
}

.focus\:m-small:focus {
  margin: 20px;
}

.focus\:m-medium:focus {
  margin: 30px;
}

.focus\:m-large:focus {
  margin: 40px;
}

.focus\:m-huge:focus {
  margin: 60px;
}

.focus\:m-margin-label-height:focus {
  margin: 26px;
}

.focus\:m-nav-height:focus {
  margin: 80px;
}

.focus\:-m-1:focus {
  margin: -5px;
}

.focus\:-m-2:focus {
  margin: -10px;
}

.focus\:-m-3:focus {
  margin: -15px;
}

.focus\:-m-4:focus {
  margin: -30px;
}

.focus\:-m-5:focus {
  margin: -50px;
}

.focus\:-m-6:focus {
  margin: -75px;
}

.focus\:-m-7:focus {
  margin: -100px;
}

.focus\:-m-none:focus {
  margin: 0px;
}

.focus\:-m-tiny:focus {
  margin: -10px;
}

.focus\:-m-small:focus {
  margin: -20px;
}

.focus\:-m-medium:focus {
  margin: -30px;
}

.focus\:-m-large:focus {
  margin: -40px;
}

.focus\:-m-huge:focus {
  margin: -60px;
}

.focus\:-m-margin-label-height:focus {
  margin: -26px;
}

.focus\:-m-nav-height:focus {
  margin: -80px;
}

.focus\:my-1:focus {
  margin-top: 5px;
  margin-bottom: 5px;
}

.focus\:mx-1:focus {
  margin-left: 5px;
  margin-right: 5px;
}

.focus\:my-2:focus {
  margin-top: 10px;
  margin-bottom: 10px;
}

.focus\:mx-2:focus {
  margin-left: 10px;
  margin-right: 10px;
}

.focus\:my-3:focus {
  margin-top: 15px;
  margin-bottom: 15px;
}

.focus\:mx-3:focus {
  margin-left: 15px;
  margin-right: 15px;
}

.focus\:my-4:focus {
  margin-top: 30px;
  margin-bottom: 30px;
}

.focus\:mx-4:focus {
  margin-left: 30px;
  margin-right: 30px;
}

.focus\:my-5:focus {
  margin-top: 50px;
  margin-bottom: 50px;
}

.focus\:mx-5:focus {
  margin-left: 50px;
  margin-right: 50px;
}

.focus\:my-6:focus {
  margin-top: 75px;
  margin-bottom: 75px;
}

.focus\:mx-6:focus {
  margin-left: 75px;
  margin-right: 75px;
}

.focus\:my-7:focus {
  margin-top: 100px;
  margin-bottom: 100px;
}

.focus\:mx-7:focus {
  margin-left: 100px;
  margin-right: 100px;
}

.focus\:my-auto:focus {
  margin-top: auto;
  margin-bottom: auto;
}

.focus\:mx-auto:focus {
  margin-left: auto;
  margin-right: auto;
}

.focus\:my-none:focus {
  margin-top: 0px;
  margin-bottom: 0px;
}

.focus\:mx-none:focus {
  margin-left: 0px;
  margin-right: 0px;
}

.focus\:my-tiny:focus {
  margin-top: 10px;
  margin-bottom: 10px;
}

.focus\:mx-tiny:focus {
  margin-left: 10px;
  margin-right: 10px;
}

.focus\:my-small:focus {
  margin-top: 20px;
  margin-bottom: 20px;
}

.focus\:mx-small:focus {
  margin-left: 20px;
  margin-right: 20px;
}

.focus\:my-medium:focus {
  margin-top: 30px;
  margin-bottom: 30px;
}

.focus\:mx-medium:focus {
  margin-left: 30px;
  margin-right: 30px;
}

.focus\:my-large:focus {
  margin-top: 40px;
  margin-bottom: 40px;
}

.focus\:mx-large:focus {
  margin-left: 40px;
  margin-right: 40px;
}

.focus\:my-huge:focus {
  margin-top: 60px;
  margin-bottom: 60px;
}

.focus\:mx-huge:focus {
  margin-left: 60px;
  margin-right: 60px;
}

.focus\:my-margin-label-height:focus {
  margin-top: 26px;
  margin-bottom: 26px;
}

.focus\:mx-margin-label-height:focus {
  margin-left: 26px;
  margin-right: 26px;
}

.focus\:my-nav-height:focus {
  margin-top: 80px;
  margin-bottom: 80px;
}

.focus\:mx-nav-height:focus {
  margin-left: 80px;
  margin-right: 80px;
}

.focus\:-my-1:focus {
  margin-top: -5px;
  margin-bottom: -5px;
}

.focus\:-mx-1:focus {
  margin-left: -5px;
  margin-right: -5px;
}

.focus\:-my-2:focus {
  margin-top: -10px;
  margin-bottom: -10px;
}

.focus\:-mx-2:focus {
  margin-left: -10px;
  margin-right: -10px;
}

.focus\:-my-3:focus {
  margin-top: -15px;
  margin-bottom: -15px;
}

.focus\:-mx-3:focus {
  margin-left: -15px;
  margin-right: -15px;
}

.focus\:-my-4:focus {
  margin-top: -30px;
  margin-bottom: -30px;
}

.focus\:-mx-4:focus {
  margin-left: -30px;
  margin-right: -30px;
}

.focus\:-my-5:focus {
  margin-top: -50px;
  margin-bottom: -50px;
}

.focus\:-mx-5:focus {
  margin-left: -50px;
  margin-right: -50px;
}

.focus\:-my-6:focus {
  margin-top: -75px;
  margin-bottom: -75px;
}

.focus\:-mx-6:focus {
  margin-left: -75px;
  margin-right: -75px;
}

.focus\:-my-7:focus {
  margin-top: -100px;
  margin-bottom: -100px;
}

.focus\:-mx-7:focus {
  margin-left: -100px;
  margin-right: -100px;
}

.focus\:-my-none:focus {
  margin-top: 0px;
  margin-bottom: 0px;
}

.focus\:-mx-none:focus {
  margin-left: 0px;
  margin-right: 0px;
}

.focus\:-my-tiny:focus {
  margin-top: -10px;
  margin-bottom: -10px;
}

.focus\:-mx-tiny:focus {
  margin-left: -10px;
  margin-right: -10px;
}

.focus\:-my-small:focus {
  margin-top: -20px;
  margin-bottom: -20px;
}

.focus\:-mx-small:focus {
  margin-left: -20px;
  margin-right: -20px;
}

.focus\:-my-medium:focus {
  margin-top: -30px;
  margin-bottom: -30px;
}

.focus\:-mx-medium:focus {
  margin-left: -30px;
  margin-right: -30px;
}

.focus\:-my-large:focus {
  margin-top: -40px;
  margin-bottom: -40px;
}

.focus\:-mx-large:focus {
  margin-left: -40px;
  margin-right: -40px;
}

.focus\:-my-huge:focus {
  margin-top: -60px;
  margin-bottom: -60px;
}

.focus\:-mx-huge:focus {
  margin-left: -60px;
  margin-right: -60px;
}

.focus\:-my-margin-label-height:focus {
  margin-top: -26px;
  margin-bottom: -26px;
}

.focus\:-mx-margin-label-height:focus {
  margin-left: -26px;
  margin-right: -26px;
}

.focus\:-my-nav-height:focus {
  margin-top: -80px;
  margin-bottom: -80px;
}

.focus\:-mx-nav-height:focus {
  margin-left: -80px;
  margin-right: -80px;
}

.focus\:mt-1:focus {
  margin-top: 5px;
}

.focus\:mr-1:focus {
  margin-right: 5px;
}

.focus\:mb-1:focus {
  margin-bottom: 5px;
}

.focus\:ml-1:focus {
  margin-left: 5px;
}

.focus\:mt-2:focus {
  margin-top: 10px;
}

.focus\:mr-2:focus {
  margin-right: 10px;
}

.focus\:mb-2:focus {
  margin-bottom: 10px;
}

.focus\:ml-2:focus {
  margin-left: 10px;
}

.focus\:mt-3:focus {
  margin-top: 15px;
}

.focus\:mr-3:focus {
  margin-right: 15px;
}

.focus\:mb-3:focus {
  margin-bottom: 15px;
}

.focus\:ml-3:focus {
  margin-left: 15px;
}

.focus\:mt-4:focus {
  margin-top: 30px;
}

.focus\:mr-4:focus {
  margin-right: 30px;
}

.focus\:mb-4:focus {
  margin-bottom: 30px;
}

.focus\:ml-4:focus {
  margin-left: 30px;
}

.focus\:mt-5:focus {
  margin-top: 50px;
}

.focus\:mr-5:focus {
  margin-right: 50px;
}

.focus\:mb-5:focus {
  margin-bottom: 50px;
}

.focus\:ml-5:focus {
  margin-left: 50px;
}

.focus\:mt-6:focus {
  margin-top: 75px;
}

.focus\:mr-6:focus {
  margin-right: 75px;
}

.focus\:mb-6:focus {
  margin-bottom: 75px;
}

.focus\:ml-6:focus {
  margin-left: 75px;
}

.focus\:mt-7:focus {
  margin-top: 100px;
}

.focus\:mr-7:focus {
  margin-right: 100px;
}

.focus\:mb-7:focus {
  margin-bottom: 100px;
}

.focus\:ml-7:focus {
  margin-left: 100px;
}

.focus\:mt-auto:focus {
  margin-top: auto;
}

.focus\:mr-auto:focus {
  margin-right: auto;
}

.focus\:mb-auto:focus {
  margin-bottom: auto;
}

.focus\:ml-auto:focus {
  margin-left: auto;
}

.focus\:mt-none:focus {
  margin-top: 0px;
}

.focus\:mr-none:focus {
  margin-right: 0px;
}

.focus\:mb-none:focus {
  margin-bottom: 0px;
}

.focus\:ml-none:focus {
  margin-left: 0px;
}

.focus\:mt-tiny:focus {
  margin-top: 10px;
}

.focus\:mr-tiny:focus {
  margin-right: 10px;
}

.focus\:mb-tiny:focus {
  margin-bottom: 10px;
}

.focus\:ml-tiny:focus {
  margin-left: 10px;
}

.focus\:mt-small:focus {
  margin-top: 20px;
}

.focus\:mr-small:focus {
  margin-right: 20px;
}

.focus\:mb-small:focus {
  margin-bottom: 20px;
}

.focus\:ml-small:focus {
  margin-left: 20px;
}

.focus\:mt-medium:focus {
  margin-top: 30px;
}

.focus\:mr-medium:focus {
  margin-right: 30px;
}

.focus\:mb-medium:focus {
  margin-bottom: 30px;
}

.focus\:ml-medium:focus {
  margin-left: 30px;
}

.focus\:mt-large:focus {
  margin-top: 40px;
}

.focus\:mr-large:focus {
  margin-right: 40px;
}

.focus\:mb-large:focus {
  margin-bottom: 40px;
}

.focus\:ml-large:focus {
  margin-left: 40px;
}

.focus\:mt-huge:focus {
  margin-top: 60px;
}

.focus\:mr-huge:focus {
  margin-right: 60px;
}

.focus\:mb-huge:focus {
  margin-bottom: 60px;
}

.focus\:ml-huge:focus {
  margin-left: 60px;
}

.focus\:mt-margin-label-height:focus {
  margin-top: 26px;
}

.focus\:mr-margin-label-height:focus {
  margin-right: 26px;
}

.focus\:mb-margin-label-height:focus {
  margin-bottom: 26px;
}

.focus\:ml-margin-label-height:focus {
  margin-left: 26px;
}

.focus\:mt-nav-height:focus {
  margin-top: 80px;
}

.focus\:mr-nav-height:focus {
  margin-right: 80px;
}

.focus\:mb-nav-height:focus {
  margin-bottom: 80px;
}

.focus\:ml-nav-height:focus {
  margin-left: 80px;
}

.focus\:-mt-1:focus {
  margin-top: -5px;
}

.focus\:-mr-1:focus {
  margin-right: -5px;
}

.focus\:-mb-1:focus {
  margin-bottom: -5px;
}

.focus\:-ml-1:focus {
  margin-left: -5px;
}

.focus\:-mt-2:focus {
  margin-top: -10px;
}

.focus\:-mr-2:focus {
  margin-right: -10px;
}

.focus\:-mb-2:focus {
  margin-bottom: -10px;
}

.focus\:-ml-2:focus {
  margin-left: -10px;
}

.focus\:-mt-3:focus {
  margin-top: -15px;
}

.focus\:-mr-3:focus {
  margin-right: -15px;
}

.focus\:-mb-3:focus {
  margin-bottom: -15px;
}

.focus\:-ml-3:focus {
  margin-left: -15px;
}

.focus\:-mt-4:focus {
  margin-top: -30px;
}

.focus\:-mr-4:focus {
  margin-right: -30px;
}

.focus\:-mb-4:focus {
  margin-bottom: -30px;
}

.focus\:-ml-4:focus {
  margin-left: -30px;
}

.focus\:-mt-5:focus {
  margin-top: -50px;
}

.focus\:-mr-5:focus {
  margin-right: -50px;
}

.focus\:-mb-5:focus {
  margin-bottom: -50px;
}

.focus\:-ml-5:focus {
  margin-left: -50px;
}

.focus\:-mt-6:focus {
  margin-top: -75px;
}

.focus\:-mr-6:focus {
  margin-right: -75px;
}

.focus\:-mb-6:focus {
  margin-bottom: -75px;
}

.focus\:-ml-6:focus {
  margin-left: -75px;
}

.focus\:-mt-7:focus {
  margin-top: -100px;
}

.focus\:-mr-7:focus {
  margin-right: -100px;
}

.focus\:-mb-7:focus {
  margin-bottom: -100px;
}

.focus\:-ml-7:focus {
  margin-left: -100px;
}

.focus\:-mt-none:focus {
  margin-top: 0px;
}

.focus\:-mr-none:focus {
  margin-right: 0px;
}

.focus\:-mb-none:focus {
  margin-bottom: 0px;
}

.focus\:-ml-none:focus {
  margin-left: 0px;
}

.focus\:-mt-tiny:focus {
  margin-top: -10px;
}

.focus\:-mr-tiny:focus {
  margin-right: -10px;
}

.focus\:-mb-tiny:focus {
  margin-bottom: -10px;
}

.focus\:-ml-tiny:focus {
  margin-left: -10px;
}

.focus\:-mt-small:focus {
  margin-top: -20px;
}

.focus\:-mr-small:focus {
  margin-right: -20px;
}

.focus\:-mb-small:focus {
  margin-bottom: -20px;
}

.focus\:-ml-small:focus {
  margin-left: -20px;
}

.focus\:-mt-medium:focus {
  margin-top: -30px;
}

.focus\:-mr-medium:focus {
  margin-right: -30px;
}

.focus\:-mb-medium:focus {
  margin-bottom: -30px;
}

.focus\:-ml-medium:focus {
  margin-left: -30px;
}

.focus\:-mt-large:focus {
  margin-top: -40px;
}

.focus\:-mr-large:focus {
  margin-right: -40px;
}

.focus\:-mb-large:focus {
  margin-bottom: -40px;
}

.focus\:-ml-large:focus {
  margin-left: -40px;
}

.focus\:-mt-huge:focus {
  margin-top: -60px;
}

.focus\:-mr-huge:focus {
  margin-right: -60px;
}

.focus\:-mb-huge:focus {
  margin-bottom: -60px;
}

.focus\:-ml-huge:focus {
  margin-left: -60px;
}

.focus\:-mt-margin-label-height:focus {
  margin-top: -26px;
}

.focus\:-mr-margin-label-height:focus {
  margin-right: -26px;
}

.focus\:-mb-margin-label-height:focus {
  margin-bottom: -26px;
}

.focus\:-ml-margin-label-height:focus {
  margin-left: -26px;
}

.focus\:-mt-nav-height:focus {
  margin-top: -80px;
}

.focus\:-mr-nav-height:focus {
  margin-right: -80px;
}

.focus\:-mb-nav-height:focus {
  margin-bottom: -80px;
}

.focus\:-ml-nav-height:focus {
  margin-left: -80px;
}

.first\:m-1:first-child {
  margin: 5px;
}

.first\:m-2:first-child {
  margin: 10px;
}

.first\:m-3:first-child {
  margin: 15px;
}

.first\:m-4:first-child {
  margin: 30px;
}

.first\:m-5:first-child {
  margin: 50px;
}

.first\:m-6:first-child {
  margin: 75px;
}

.first\:m-7:first-child {
  margin: 100px;
}

.first\:m-auto:first-child {
  margin: auto;
}

.first\:m-none:first-child {
  margin: 0px;
}

.first\:m-tiny:first-child {
  margin: 10px;
}

.first\:m-small:first-child {
  margin: 20px;
}

.first\:m-medium:first-child {
  margin: 30px;
}

.first\:m-large:first-child {
  margin: 40px;
}

.first\:m-huge:first-child {
  margin: 60px;
}

.first\:m-margin-label-height:first-child {
  margin: 26px;
}

.first\:m-nav-height:first-child {
  margin: 80px;
}

.first\:-m-1:first-child {
  margin: -5px;
}

.first\:-m-2:first-child {
  margin: -10px;
}

.first\:-m-3:first-child {
  margin: -15px;
}

.first\:-m-4:first-child {
  margin: -30px;
}

.first\:-m-5:first-child {
  margin: -50px;
}

.first\:-m-6:first-child {
  margin: -75px;
}

.first\:-m-7:first-child {
  margin: -100px;
}

.first\:-m-none:first-child {
  margin: 0px;
}

.first\:-m-tiny:first-child {
  margin: -10px;
}

.first\:-m-small:first-child {
  margin: -20px;
}

.first\:-m-medium:first-child {
  margin: -30px;
}

.first\:-m-large:first-child {
  margin: -40px;
}

.first\:-m-huge:first-child {
  margin: -60px;
}

.first\:-m-margin-label-height:first-child {
  margin: -26px;
}

.first\:-m-nav-height:first-child {
  margin: -80px;
}

.first\:my-1:first-child {
  margin-top: 5px;
  margin-bottom: 5px;
}

.first\:mx-1:first-child {
  margin-left: 5px;
  margin-right: 5px;
}

.first\:my-2:first-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.first\:mx-2:first-child {
  margin-left: 10px;
  margin-right: 10px;
}

.first\:my-3:first-child {
  margin-top: 15px;
  margin-bottom: 15px;
}

.first\:mx-3:first-child {
  margin-left: 15px;
  margin-right: 15px;
}

.first\:my-4:first-child {
  margin-top: 30px;
  margin-bottom: 30px;
}

.first\:mx-4:first-child {
  margin-left: 30px;
  margin-right: 30px;
}

.first\:my-5:first-child {
  margin-top: 50px;
  margin-bottom: 50px;
}

.first\:mx-5:first-child {
  margin-left: 50px;
  margin-right: 50px;
}

.first\:my-6:first-child {
  margin-top: 75px;
  margin-bottom: 75px;
}

.first\:mx-6:first-child {
  margin-left: 75px;
  margin-right: 75px;
}

.first\:my-7:first-child {
  margin-top: 100px;
  margin-bottom: 100px;
}

.first\:mx-7:first-child {
  margin-left: 100px;
  margin-right: 100px;
}

.first\:my-auto:first-child {
  margin-top: auto;
  margin-bottom: auto;
}

.first\:mx-auto:first-child {
  margin-left: auto;
  margin-right: auto;
}

.first\:my-none:first-child {
  margin-top: 0px;
  margin-bottom: 0px;
}

.first\:mx-none:first-child {
  margin-left: 0px;
  margin-right: 0px;
}

.first\:my-tiny:first-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.first\:mx-tiny:first-child {
  margin-left: 10px;
  margin-right: 10px;
}

.first\:my-small:first-child {
  margin-top: 20px;
  margin-bottom: 20px;
}

.first\:mx-small:first-child {
  margin-left: 20px;
  margin-right: 20px;
}

.first\:my-medium:first-child {
  margin-top: 30px;
  margin-bottom: 30px;
}

.first\:mx-medium:first-child {
  margin-left: 30px;
  margin-right: 30px;
}

.first\:my-large:first-child {
  margin-top: 40px;
  margin-bottom: 40px;
}

.first\:mx-large:first-child {
  margin-left: 40px;
  margin-right: 40px;
}

.first\:my-huge:first-child {
  margin-top: 60px;
  margin-bottom: 60px;
}

.first\:mx-huge:first-child {
  margin-left: 60px;
  margin-right: 60px;
}

.first\:my-margin-label-height:first-child {
  margin-top: 26px;
  margin-bottom: 26px;
}

.first\:mx-margin-label-height:first-child {
  margin-left: 26px;
  margin-right: 26px;
}

.first\:my-nav-height:first-child {
  margin-top: 80px;
  margin-bottom: 80px;
}

.first\:mx-nav-height:first-child {
  margin-left: 80px;
  margin-right: 80px;
}

.first\:-my-1:first-child {
  margin-top: -5px;
  margin-bottom: -5px;
}

.first\:-mx-1:first-child {
  margin-left: -5px;
  margin-right: -5px;
}

.first\:-my-2:first-child {
  margin-top: -10px;
  margin-bottom: -10px;
}

.first\:-mx-2:first-child {
  margin-left: -10px;
  margin-right: -10px;
}

.first\:-my-3:first-child {
  margin-top: -15px;
  margin-bottom: -15px;
}

.first\:-mx-3:first-child {
  margin-left: -15px;
  margin-right: -15px;
}

.first\:-my-4:first-child {
  margin-top: -30px;
  margin-bottom: -30px;
}

.first\:-mx-4:first-child {
  margin-left: -30px;
  margin-right: -30px;
}

.first\:-my-5:first-child {
  margin-top: -50px;
  margin-bottom: -50px;
}

.first\:-mx-5:first-child {
  margin-left: -50px;
  margin-right: -50px;
}

.first\:-my-6:first-child {
  margin-top: -75px;
  margin-bottom: -75px;
}

.first\:-mx-6:first-child {
  margin-left: -75px;
  margin-right: -75px;
}

.first\:-my-7:first-child {
  margin-top: -100px;
  margin-bottom: -100px;
}

.first\:-mx-7:first-child {
  margin-left: -100px;
  margin-right: -100px;
}

.first\:-my-none:first-child {
  margin-top: 0px;
  margin-bottom: 0px;
}

.first\:-mx-none:first-child {
  margin-left: 0px;
  margin-right: 0px;
}

.first\:-my-tiny:first-child {
  margin-top: -10px;
  margin-bottom: -10px;
}

.first\:-mx-tiny:first-child {
  margin-left: -10px;
  margin-right: -10px;
}

.first\:-my-small:first-child {
  margin-top: -20px;
  margin-bottom: -20px;
}

.first\:-mx-small:first-child {
  margin-left: -20px;
  margin-right: -20px;
}

.first\:-my-medium:first-child {
  margin-top: -30px;
  margin-bottom: -30px;
}

.first\:-mx-medium:first-child {
  margin-left: -30px;
  margin-right: -30px;
}

.first\:-my-large:first-child {
  margin-top: -40px;
  margin-bottom: -40px;
}

.first\:-mx-large:first-child {
  margin-left: -40px;
  margin-right: -40px;
}

.first\:-my-huge:first-child {
  margin-top: -60px;
  margin-bottom: -60px;
}

.first\:-mx-huge:first-child {
  margin-left: -60px;
  margin-right: -60px;
}

.first\:-my-margin-label-height:first-child {
  margin-top: -26px;
  margin-bottom: -26px;
}

.first\:-mx-margin-label-height:first-child {
  margin-left: -26px;
  margin-right: -26px;
}

.first\:-my-nav-height:first-child {
  margin-top: -80px;
  margin-bottom: -80px;
}

.first\:-mx-nav-height:first-child {
  margin-left: -80px;
  margin-right: -80px;
}

.first\:mt-1:first-child {
  margin-top: 5px;
}

.first\:mr-1:first-child {
  margin-right: 5px;
}

.first\:mb-1:first-child {
  margin-bottom: 5px;
}

.first\:ml-1:first-child {
  margin-left: 5px;
}

.first\:mt-2:first-child {
  margin-top: 10px;
}

.first\:mr-2:first-child {
  margin-right: 10px;
}

.first\:mb-2:first-child {
  margin-bottom: 10px;
}

.first\:ml-2:first-child {
  margin-left: 10px;
}

.first\:mt-3:first-child {
  margin-top: 15px;
}

.first\:mr-3:first-child {
  margin-right: 15px;
}

.first\:mb-3:first-child {
  margin-bottom: 15px;
}

.first\:ml-3:first-child {
  margin-left: 15px;
}

.first\:mt-4:first-child {
  margin-top: 30px;
}

.first\:mr-4:first-child {
  margin-right: 30px;
}

.first\:mb-4:first-child {
  margin-bottom: 30px;
}

.first\:ml-4:first-child {
  margin-left: 30px;
}

.first\:mt-5:first-child {
  margin-top: 50px;
}

.first\:mr-5:first-child {
  margin-right: 50px;
}

.first\:mb-5:first-child {
  margin-bottom: 50px;
}

.first\:ml-5:first-child {
  margin-left: 50px;
}

.first\:mt-6:first-child {
  margin-top: 75px;
}

.first\:mr-6:first-child {
  margin-right: 75px;
}

.first\:mb-6:first-child {
  margin-bottom: 75px;
}

.first\:ml-6:first-child {
  margin-left: 75px;
}

.first\:mt-7:first-child {
  margin-top: 100px;
}

.first\:mr-7:first-child {
  margin-right: 100px;
}

.first\:mb-7:first-child {
  margin-bottom: 100px;
}

.first\:ml-7:first-child {
  margin-left: 100px;
}

.first\:mt-auto:first-child {
  margin-top: auto;
}

.first\:mr-auto:first-child {
  margin-right: auto;
}

.first\:mb-auto:first-child {
  margin-bottom: auto;
}

.first\:ml-auto:first-child {
  margin-left: auto;
}

.first\:mt-none:first-child {
  margin-top: 0px;
}

.first\:mr-none:first-child {
  margin-right: 0px;
}

.first\:mb-none:first-child {
  margin-bottom: 0px;
}

.first\:ml-none:first-child {
  margin-left: 0px;
}

.first\:mt-tiny:first-child {
  margin-top: 10px;
}

.first\:mr-tiny:first-child {
  margin-right: 10px;
}

.first\:mb-tiny:first-child {
  margin-bottom: 10px;
}

.first\:ml-tiny:first-child {
  margin-left: 10px;
}

.first\:mt-small:first-child {
  margin-top: 20px;
}

.first\:mr-small:first-child {
  margin-right: 20px;
}

.first\:mb-small:first-child {
  margin-bottom: 20px;
}

.first\:ml-small:first-child {
  margin-left: 20px;
}

.first\:mt-medium:first-child {
  margin-top: 30px;
}

.first\:mr-medium:first-child {
  margin-right: 30px;
}

.first\:mb-medium:first-child {
  margin-bottom: 30px;
}

.first\:ml-medium:first-child {
  margin-left: 30px;
}

.first\:mt-large:first-child {
  margin-top: 40px;
}

.first\:mr-large:first-child {
  margin-right: 40px;
}

.first\:mb-large:first-child {
  margin-bottom: 40px;
}

.first\:ml-large:first-child {
  margin-left: 40px;
}

.first\:mt-huge:first-child {
  margin-top: 60px;
}

.first\:mr-huge:first-child {
  margin-right: 60px;
}

.first\:mb-huge:first-child {
  margin-bottom: 60px;
}

.first\:ml-huge:first-child {
  margin-left: 60px;
}

.first\:mt-margin-label-height:first-child {
  margin-top: 26px;
}

.first\:mr-margin-label-height:first-child {
  margin-right: 26px;
}

.first\:mb-margin-label-height:first-child {
  margin-bottom: 26px;
}

.first\:ml-margin-label-height:first-child {
  margin-left: 26px;
}

.first\:mt-nav-height:first-child {
  margin-top: 80px;
}

.first\:mr-nav-height:first-child {
  margin-right: 80px;
}

.first\:mb-nav-height:first-child {
  margin-bottom: 80px;
}

.first\:ml-nav-height:first-child {
  margin-left: 80px;
}

.first\:-mt-1:first-child {
  margin-top: -5px;
}

.first\:-mr-1:first-child {
  margin-right: -5px;
}

.first\:-mb-1:first-child {
  margin-bottom: -5px;
}

.first\:-ml-1:first-child {
  margin-left: -5px;
}

.first\:-mt-2:first-child {
  margin-top: -10px;
}

.first\:-mr-2:first-child {
  margin-right: -10px;
}

.first\:-mb-2:first-child {
  margin-bottom: -10px;
}

.first\:-ml-2:first-child {
  margin-left: -10px;
}

.first\:-mt-3:first-child {
  margin-top: -15px;
}

.first\:-mr-3:first-child {
  margin-right: -15px;
}

.first\:-mb-3:first-child {
  margin-bottom: -15px;
}

.first\:-ml-3:first-child {
  margin-left: -15px;
}

.first\:-mt-4:first-child {
  margin-top: -30px;
}

.first\:-mr-4:first-child {
  margin-right: -30px;
}

.first\:-mb-4:first-child {
  margin-bottom: -30px;
}

.first\:-ml-4:first-child {
  margin-left: -30px;
}

.first\:-mt-5:first-child {
  margin-top: -50px;
}

.first\:-mr-5:first-child {
  margin-right: -50px;
}

.first\:-mb-5:first-child {
  margin-bottom: -50px;
}

.first\:-ml-5:first-child {
  margin-left: -50px;
}

.first\:-mt-6:first-child {
  margin-top: -75px;
}

.first\:-mr-6:first-child {
  margin-right: -75px;
}

.first\:-mb-6:first-child {
  margin-bottom: -75px;
}

.first\:-ml-6:first-child {
  margin-left: -75px;
}

.first\:-mt-7:first-child {
  margin-top: -100px;
}

.first\:-mr-7:first-child {
  margin-right: -100px;
}

.first\:-mb-7:first-child {
  margin-bottom: -100px;
}

.first\:-ml-7:first-child {
  margin-left: -100px;
}

.first\:-mt-none:first-child {
  margin-top: 0px;
}

.first\:-mr-none:first-child {
  margin-right: 0px;
}

.first\:-mb-none:first-child {
  margin-bottom: 0px;
}

.first\:-ml-none:first-child {
  margin-left: 0px;
}

.first\:-mt-tiny:first-child {
  margin-top: -10px;
}

.first\:-mr-tiny:first-child {
  margin-right: -10px;
}

.first\:-mb-tiny:first-child {
  margin-bottom: -10px;
}

.first\:-ml-tiny:first-child {
  margin-left: -10px;
}

.first\:-mt-small:first-child {
  margin-top: -20px;
}

.first\:-mr-small:first-child {
  margin-right: -20px;
}

.first\:-mb-small:first-child {
  margin-bottom: -20px;
}

.first\:-ml-small:first-child {
  margin-left: -20px;
}

.first\:-mt-medium:first-child {
  margin-top: -30px;
}

.first\:-mr-medium:first-child {
  margin-right: -30px;
}

.first\:-mb-medium:first-child {
  margin-bottom: -30px;
}

.first\:-ml-medium:first-child {
  margin-left: -30px;
}

.first\:-mt-large:first-child {
  margin-top: -40px;
}

.first\:-mr-large:first-child {
  margin-right: -40px;
}

.first\:-mb-large:first-child {
  margin-bottom: -40px;
}

.first\:-ml-large:first-child {
  margin-left: -40px;
}

.first\:-mt-huge:first-child {
  margin-top: -60px;
}

.first\:-mr-huge:first-child {
  margin-right: -60px;
}

.first\:-mb-huge:first-child {
  margin-bottom: -60px;
}

.first\:-ml-huge:first-child {
  margin-left: -60px;
}

.first\:-mt-margin-label-height:first-child {
  margin-top: -26px;
}

.first\:-mr-margin-label-height:first-child {
  margin-right: -26px;
}

.first\:-mb-margin-label-height:first-child {
  margin-bottom: -26px;
}

.first\:-ml-margin-label-height:first-child {
  margin-left: -26px;
}

.first\:-mt-nav-height:first-child {
  margin-top: -80px;
}

.first\:-mr-nav-height:first-child {
  margin-right: -80px;
}

.first\:-mb-nav-height:first-child {
  margin-bottom: -80px;
}

.first\:-ml-nav-height:first-child {
  margin-left: -80px;
}

.last\:m-1:last-child {
  margin: 5px;
}

.last\:m-2:last-child {
  margin: 10px;
}

.last\:m-3:last-child {
  margin: 15px;
}

.last\:m-4:last-child {
  margin: 30px;
}

.last\:m-5:last-child {
  margin: 50px;
}

.last\:m-6:last-child {
  margin: 75px;
}

.last\:m-7:last-child {
  margin: 100px;
}

.last\:m-auto:last-child {
  margin: auto;
}

.last\:m-none:last-child {
  margin: 0px;
}

.last\:m-tiny:last-child {
  margin: 10px;
}

.last\:m-small:last-child {
  margin: 20px;
}

.last\:m-medium:last-child {
  margin: 30px;
}

.last\:m-large:last-child {
  margin: 40px;
}

.last\:m-huge:last-child {
  margin: 60px;
}

.last\:m-margin-label-height:last-child {
  margin: 26px;
}

.last\:m-nav-height:last-child {
  margin: 80px;
}

.last\:-m-1:last-child {
  margin: -5px;
}

.last\:-m-2:last-child {
  margin: -10px;
}

.last\:-m-3:last-child {
  margin: -15px;
}

.last\:-m-4:last-child {
  margin: -30px;
}

.last\:-m-5:last-child {
  margin: -50px;
}

.last\:-m-6:last-child {
  margin: -75px;
}

.last\:-m-7:last-child {
  margin: -100px;
}

.last\:-m-none:last-child {
  margin: 0px;
}

.last\:-m-tiny:last-child {
  margin: -10px;
}

.last\:-m-small:last-child {
  margin: -20px;
}

.last\:-m-medium:last-child {
  margin: -30px;
}

.last\:-m-large:last-child {
  margin: -40px;
}

.last\:-m-huge:last-child {
  margin: -60px;
}

.last\:-m-margin-label-height:last-child {
  margin: -26px;
}

.last\:-m-nav-height:last-child {
  margin: -80px;
}

.last\:my-1:last-child {
  margin-top: 5px;
  margin-bottom: 5px;
}

.last\:mx-1:last-child {
  margin-left: 5px;
  margin-right: 5px;
}

.last\:my-2:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.last\:mx-2:last-child {
  margin-left: 10px;
  margin-right: 10px;
}

.last\:my-3:last-child {
  margin-top: 15px;
  margin-bottom: 15px;
}

.last\:mx-3:last-child {
  margin-left: 15px;
  margin-right: 15px;
}

.last\:my-4:last-child {
  margin-top: 30px;
  margin-bottom: 30px;
}

.last\:mx-4:last-child {
  margin-left: 30px;
  margin-right: 30px;
}

.last\:my-5:last-child {
  margin-top: 50px;
  margin-bottom: 50px;
}

.last\:mx-5:last-child {
  margin-left: 50px;
  margin-right: 50px;
}

.last\:my-6:last-child {
  margin-top: 75px;
  margin-bottom: 75px;
}

.last\:mx-6:last-child {
  margin-left: 75px;
  margin-right: 75px;
}

.last\:my-7:last-child {
  margin-top: 100px;
  margin-bottom: 100px;
}

.last\:mx-7:last-child {
  margin-left: 100px;
  margin-right: 100px;
}

.last\:my-auto:last-child {
  margin-top: auto;
  margin-bottom: auto;
}

.last\:mx-auto:last-child {
  margin-left: auto;
  margin-right: auto;
}

.last\:my-none:last-child {
  margin-top: 0px;
  margin-bottom: 0px;
}

.last\:mx-none:last-child {
  margin-left: 0px;
  margin-right: 0px;
}

.last\:my-tiny:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.last\:mx-tiny:last-child {
  margin-left: 10px;
  margin-right: 10px;
}

.last\:my-small:last-child {
  margin-top: 20px;
  margin-bottom: 20px;
}

.last\:mx-small:last-child {
  margin-left: 20px;
  margin-right: 20px;
}

.last\:my-medium:last-child {
  margin-top: 30px;
  margin-bottom: 30px;
}

.last\:mx-medium:last-child {
  margin-left: 30px;
  margin-right: 30px;
}

.last\:my-large:last-child {
  margin-top: 40px;
  margin-bottom: 40px;
}

.last\:mx-large:last-child {
  margin-left: 40px;
  margin-right: 40px;
}

.last\:my-huge:last-child {
  margin-top: 60px;
  margin-bottom: 60px;
}

.last\:mx-huge:last-child {
  margin-left: 60px;
  margin-right: 60px;
}

.last\:my-margin-label-height:last-child {
  margin-top: 26px;
  margin-bottom: 26px;
}

.last\:mx-margin-label-height:last-child {
  margin-left: 26px;
  margin-right: 26px;
}

.last\:my-nav-height:last-child {
  margin-top: 80px;
  margin-bottom: 80px;
}

.last\:mx-nav-height:last-child {
  margin-left: 80px;
  margin-right: 80px;
}

.last\:-my-1:last-child {
  margin-top: -5px;
  margin-bottom: -5px;
}

.last\:-mx-1:last-child {
  margin-left: -5px;
  margin-right: -5px;
}

.last\:-my-2:last-child {
  margin-top: -10px;
  margin-bottom: -10px;
}

.last\:-mx-2:last-child {
  margin-left: -10px;
  margin-right: -10px;
}

.last\:-my-3:last-child {
  margin-top: -15px;
  margin-bottom: -15px;
}

.last\:-mx-3:last-child {
  margin-left: -15px;
  margin-right: -15px;
}

.last\:-my-4:last-child {
  margin-top: -30px;
  margin-bottom: -30px;
}

.last\:-mx-4:last-child {
  margin-left: -30px;
  margin-right: -30px;
}

.last\:-my-5:last-child {
  margin-top: -50px;
  margin-bottom: -50px;
}

.last\:-mx-5:last-child {
  margin-left: -50px;
  margin-right: -50px;
}

.last\:-my-6:last-child {
  margin-top: -75px;
  margin-bottom: -75px;
}

.last\:-mx-6:last-child {
  margin-left: -75px;
  margin-right: -75px;
}

.last\:-my-7:last-child {
  margin-top: -100px;
  margin-bottom: -100px;
}

.last\:-mx-7:last-child {
  margin-left: -100px;
  margin-right: -100px;
}

.last\:-my-none:last-child {
  margin-top: 0px;
  margin-bottom: 0px;
}

.last\:-mx-none:last-child {
  margin-left: 0px;
  margin-right: 0px;
}

.last\:-my-tiny:last-child {
  margin-top: -10px;
  margin-bottom: -10px;
}

.last\:-mx-tiny:last-child {
  margin-left: -10px;
  margin-right: -10px;
}

.last\:-my-small:last-child {
  margin-top: -20px;
  margin-bottom: -20px;
}

.last\:-mx-small:last-child {
  margin-left: -20px;
  margin-right: -20px;
}

.last\:-my-medium:last-child {
  margin-top: -30px;
  margin-bottom: -30px;
}

.last\:-mx-medium:last-child {
  margin-left: -30px;
  margin-right: -30px;
}

.last\:-my-large:last-child {
  margin-top: -40px;
  margin-bottom: -40px;
}

.last\:-mx-large:last-child {
  margin-left: -40px;
  margin-right: -40px;
}

.last\:-my-huge:last-child {
  margin-top: -60px;
  margin-bottom: -60px;
}

.last\:-mx-huge:last-child {
  margin-left: -60px;
  margin-right: -60px;
}

.last\:-my-margin-label-height:last-child {
  margin-top: -26px;
  margin-bottom: -26px;
}

.last\:-mx-margin-label-height:last-child {
  margin-left: -26px;
  margin-right: -26px;
}

.last\:-my-nav-height:last-child {
  margin-top: -80px;
  margin-bottom: -80px;
}

.last\:-mx-nav-height:last-child {
  margin-left: -80px;
  margin-right: -80px;
}

.last\:mt-1:last-child {
  margin-top: 5px;
}

.last\:mr-1:last-child {
  margin-right: 5px;
}

.last\:mb-1:last-child {
  margin-bottom: 5px;
}

.last\:ml-1:last-child {
  margin-left: 5px;
}

.last\:mt-2:last-child {
  margin-top: 10px;
}

.last\:mr-2:last-child {
  margin-right: 10px;
}

.last\:mb-2:last-child {
  margin-bottom: 10px;
}

.last\:ml-2:last-child {
  margin-left: 10px;
}

.last\:mt-3:last-child {
  margin-top: 15px;
}

.last\:mr-3:last-child {
  margin-right: 15px;
}

.last\:mb-3:last-child {
  margin-bottom: 15px;
}

.last\:ml-3:last-child {
  margin-left: 15px;
}

.last\:mt-4:last-child {
  margin-top: 30px;
}

.last\:mr-4:last-child {
  margin-right: 30px;
}

.last\:mb-4:last-child {
  margin-bottom: 30px;
}

.last\:ml-4:last-child {
  margin-left: 30px;
}

.last\:mt-5:last-child {
  margin-top: 50px;
}

.last\:mr-5:last-child {
  margin-right: 50px;
}

.last\:mb-5:last-child {
  margin-bottom: 50px;
}

.last\:ml-5:last-child {
  margin-left: 50px;
}

.last\:mt-6:last-child {
  margin-top: 75px;
}

.last\:mr-6:last-child {
  margin-right: 75px;
}

.last\:mb-6:last-child {
  margin-bottom: 75px;
}

.last\:ml-6:last-child {
  margin-left: 75px;
}

.last\:mt-7:last-child {
  margin-top: 100px;
}

.last\:mr-7:last-child {
  margin-right: 100px;
}

.last\:mb-7:last-child {
  margin-bottom: 100px;
}

.last\:ml-7:last-child {
  margin-left: 100px;
}

.last\:mt-auto:last-child {
  margin-top: auto;
}

.last\:mr-auto:last-child {
  margin-right: auto;
}

.last\:mb-auto:last-child {
  margin-bottom: auto;
}

.last\:ml-auto:last-child {
  margin-left: auto;
}

.last\:mt-none:last-child {
  margin-top: 0px;
}

.last\:mr-none:last-child {
  margin-right: 0px;
}

.last\:mb-none:last-child {
  margin-bottom: 0px;
}

.last\:ml-none:last-child {
  margin-left: 0px;
}

.last\:mt-tiny:last-child {
  margin-top: 10px;
}

.last\:mr-tiny:last-child {
  margin-right: 10px;
}

.last\:mb-tiny:last-child {
  margin-bottom: 10px;
}

.last\:ml-tiny:last-child {
  margin-left: 10px;
}

.last\:mt-small:last-child {
  margin-top: 20px;
}

.last\:mr-small:last-child {
  margin-right: 20px;
}

.last\:mb-small:last-child {
  margin-bottom: 20px;
}

.last\:ml-small:last-child {
  margin-left: 20px;
}

.last\:mt-medium:last-child {
  margin-top: 30px;
}

.last\:mr-medium:last-child {
  margin-right: 30px;
}

.last\:mb-medium:last-child {
  margin-bottom: 30px;
}

.last\:ml-medium:last-child {
  margin-left: 30px;
}

.last\:mt-large:last-child {
  margin-top: 40px;
}

.last\:mr-large:last-child {
  margin-right: 40px;
}

.last\:mb-large:last-child {
  margin-bottom: 40px;
}

.last\:ml-large:last-child {
  margin-left: 40px;
}

.last\:mt-huge:last-child {
  margin-top: 60px;
}

.last\:mr-huge:last-child {
  margin-right: 60px;
}

.last\:mb-huge:last-child {
  margin-bottom: 60px;
}

.last\:ml-huge:last-child {
  margin-left: 60px;
}

.last\:mt-margin-label-height:last-child {
  margin-top: 26px;
}

.last\:mr-margin-label-height:last-child {
  margin-right: 26px;
}

.last\:mb-margin-label-height:last-child {
  margin-bottom: 26px;
}

.last\:ml-margin-label-height:last-child {
  margin-left: 26px;
}

.last\:mt-nav-height:last-child {
  margin-top: 80px;
}

.last\:mr-nav-height:last-child {
  margin-right: 80px;
}

.last\:mb-nav-height:last-child {
  margin-bottom: 80px;
}

.last\:ml-nav-height:last-child {
  margin-left: 80px;
}

.last\:-mt-1:last-child {
  margin-top: -5px;
}

.last\:-mr-1:last-child {
  margin-right: -5px;
}

.last\:-mb-1:last-child {
  margin-bottom: -5px;
}

.last\:-ml-1:last-child {
  margin-left: -5px;
}

.last\:-mt-2:last-child {
  margin-top: -10px;
}

.last\:-mr-2:last-child {
  margin-right: -10px;
}

.last\:-mb-2:last-child {
  margin-bottom: -10px;
}

.last\:-ml-2:last-child {
  margin-left: -10px;
}

.last\:-mt-3:last-child {
  margin-top: -15px;
}

.last\:-mr-3:last-child {
  margin-right: -15px;
}

.last\:-mb-3:last-child {
  margin-bottom: -15px;
}

.last\:-ml-3:last-child {
  margin-left: -15px;
}

.last\:-mt-4:last-child {
  margin-top: -30px;
}

.last\:-mr-4:last-child {
  margin-right: -30px;
}

.last\:-mb-4:last-child {
  margin-bottom: -30px;
}

.last\:-ml-4:last-child {
  margin-left: -30px;
}

.last\:-mt-5:last-child {
  margin-top: -50px;
}

.last\:-mr-5:last-child {
  margin-right: -50px;
}

.last\:-mb-5:last-child {
  margin-bottom: -50px;
}

.last\:-ml-5:last-child {
  margin-left: -50px;
}

.last\:-mt-6:last-child {
  margin-top: -75px;
}

.last\:-mr-6:last-child {
  margin-right: -75px;
}

.last\:-mb-6:last-child {
  margin-bottom: -75px;
}

.last\:-ml-6:last-child {
  margin-left: -75px;
}

.last\:-mt-7:last-child {
  margin-top: -100px;
}

.last\:-mr-7:last-child {
  margin-right: -100px;
}

.last\:-mb-7:last-child {
  margin-bottom: -100px;
}

.last\:-ml-7:last-child {
  margin-left: -100px;
}

.last\:-mt-none:last-child {
  margin-top: 0px;
}

.last\:-mr-none:last-child {
  margin-right: 0px;
}

.last\:-mb-none:last-child {
  margin-bottom: 0px;
}

.last\:-ml-none:last-child {
  margin-left: 0px;
}

.last\:-mt-tiny:last-child {
  margin-top: -10px;
}

.last\:-mr-tiny:last-child {
  margin-right: -10px;
}

.last\:-mb-tiny:last-child {
  margin-bottom: -10px;
}

.last\:-ml-tiny:last-child {
  margin-left: -10px;
}

.last\:-mt-small:last-child {
  margin-top: -20px;
}

.last\:-mr-small:last-child {
  margin-right: -20px;
}

.last\:-mb-small:last-child {
  margin-bottom: -20px;
}

.last\:-ml-small:last-child {
  margin-left: -20px;
}

.last\:-mt-medium:last-child {
  margin-top: -30px;
}

.last\:-mr-medium:last-child {
  margin-right: -30px;
}

.last\:-mb-medium:last-child {
  margin-bottom: -30px;
}

.last\:-ml-medium:last-child {
  margin-left: -30px;
}

.last\:-mt-large:last-child {
  margin-top: -40px;
}

.last\:-mr-large:last-child {
  margin-right: -40px;
}

.last\:-mb-large:last-child {
  margin-bottom: -40px;
}

.last\:-ml-large:last-child {
  margin-left: -40px;
}

.last\:-mt-huge:last-child {
  margin-top: -60px;
}

.last\:-mr-huge:last-child {
  margin-right: -60px;
}

.last\:-mb-huge:last-child {
  margin-bottom: -60px;
}

.last\:-ml-huge:last-child {
  margin-left: -60px;
}

.last\:-mt-margin-label-height:last-child {
  margin-top: -26px;
}

.last\:-mr-margin-label-height:last-child {
  margin-right: -26px;
}

.last\:-mb-margin-label-height:last-child {
  margin-bottom: -26px;
}

.last\:-ml-margin-label-height:last-child {
  margin-left: -26px;
}

.last\:-mt-nav-height:last-child {
  margin-top: -80px;
}

.last\:-mr-nav-height:last-child {
  margin-right: -80px;
}

.last\:-mb-nav-height:last-child {
  margin-bottom: -80px;
}

.last\:-ml-nav-height:last-child {
  margin-left: -80px;
}

.odd\:m-1:nth-child(odd) {
  margin: 5px;
}

.odd\:m-2:nth-child(odd) {
  margin: 10px;
}

.odd\:m-3:nth-child(odd) {
  margin: 15px;
}

.odd\:m-4:nth-child(odd) {
  margin: 30px;
}

.odd\:m-5:nth-child(odd) {
  margin: 50px;
}

.odd\:m-6:nth-child(odd) {
  margin: 75px;
}

.odd\:m-7:nth-child(odd) {
  margin: 100px;
}

.odd\:m-auto:nth-child(odd) {
  margin: auto;
}

.odd\:m-none:nth-child(odd) {
  margin: 0px;
}

.odd\:m-tiny:nth-child(odd) {
  margin: 10px;
}

.odd\:m-small:nth-child(odd) {
  margin: 20px;
}

.odd\:m-medium:nth-child(odd) {
  margin: 30px;
}

.odd\:m-large:nth-child(odd) {
  margin: 40px;
}

.odd\:m-huge:nth-child(odd) {
  margin: 60px;
}

.odd\:m-margin-label-height:nth-child(odd) {
  margin: 26px;
}

.odd\:m-nav-height:nth-child(odd) {
  margin: 80px;
}

.odd\:-m-1:nth-child(odd) {
  margin: -5px;
}

.odd\:-m-2:nth-child(odd) {
  margin: -10px;
}

.odd\:-m-3:nth-child(odd) {
  margin: -15px;
}

.odd\:-m-4:nth-child(odd) {
  margin: -30px;
}

.odd\:-m-5:nth-child(odd) {
  margin: -50px;
}

.odd\:-m-6:nth-child(odd) {
  margin: -75px;
}

.odd\:-m-7:nth-child(odd) {
  margin: -100px;
}

.odd\:-m-none:nth-child(odd) {
  margin: 0px;
}

.odd\:-m-tiny:nth-child(odd) {
  margin: -10px;
}

.odd\:-m-small:nth-child(odd) {
  margin: -20px;
}

.odd\:-m-medium:nth-child(odd) {
  margin: -30px;
}

.odd\:-m-large:nth-child(odd) {
  margin: -40px;
}

.odd\:-m-huge:nth-child(odd) {
  margin: -60px;
}

.odd\:-m-margin-label-height:nth-child(odd) {
  margin: -26px;
}

.odd\:-m-nav-height:nth-child(odd) {
  margin: -80px;
}

.odd\:my-1:nth-child(odd) {
  margin-top: 5px;
  margin-bottom: 5px;
}

.odd\:mx-1:nth-child(odd) {
  margin-left: 5px;
  margin-right: 5px;
}

.odd\:my-2:nth-child(odd) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.odd\:mx-2:nth-child(odd) {
  margin-left: 10px;
  margin-right: 10px;
}

.odd\:my-3:nth-child(odd) {
  margin-top: 15px;
  margin-bottom: 15px;
}

.odd\:mx-3:nth-child(odd) {
  margin-left: 15px;
  margin-right: 15px;
}

.odd\:my-4:nth-child(odd) {
  margin-top: 30px;
  margin-bottom: 30px;
}

.odd\:mx-4:nth-child(odd) {
  margin-left: 30px;
  margin-right: 30px;
}

.odd\:my-5:nth-child(odd) {
  margin-top: 50px;
  margin-bottom: 50px;
}

.odd\:mx-5:nth-child(odd) {
  margin-left: 50px;
  margin-right: 50px;
}

.odd\:my-6:nth-child(odd) {
  margin-top: 75px;
  margin-bottom: 75px;
}

.odd\:mx-6:nth-child(odd) {
  margin-left: 75px;
  margin-right: 75px;
}

.odd\:my-7:nth-child(odd) {
  margin-top: 100px;
  margin-bottom: 100px;
}

.odd\:mx-7:nth-child(odd) {
  margin-left: 100px;
  margin-right: 100px;
}

.odd\:my-auto:nth-child(odd) {
  margin-top: auto;
  margin-bottom: auto;
}

.odd\:mx-auto:nth-child(odd) {
  margin-left: auto;
  margin-right: auto;
}

.odd\:my-none:nth-child(odd) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.odd\:mx-none:nth-child(odd) {
  margin-left: 0px;
  margin-right: 0px;
}

.odd\:my-tiny:nth-child(odd) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.odd\:mx-tiny:nth-child(odd) {
  margin-left: 10px;
  margin-right: 10px;
}

.odd\:my-small:nth-child(odd) {
  margin-top: 20px;
  margin-bottom: 20px;
}

.odd\:mx-small:nth-child(odd) {
  margin-left: 20px;
  margin-right: 20px;
}

.odd\:my-medium:nth-child(odd) {
  margin-top: 30px;
  margin-bottom: 30px;
}

.odd\:mx-medium:nth-child(odd) {
  margin-left: 30px;
  margin-right: 30px;
}

.odd\:my-large:nth-child(odd) {
  margin-top: 40px;
  margin-bottom: 40px;
}

.odd\:mx-large:nth-child(odd) {
  margin-left: 40px;
  margin-right: 40px;
}

.odd\:my-huge:nth-child(odd) {
  margin-top: 60px;
  margin-bottom: 60px;
}

.odd\:mx-huge:nth-child(odd) {
  margin-left: 60px;
  margin-right: 60px;
}

.odd\:my-margin-label-height:nth-child(odd) {
  margin-top: 26px;
  margin-bottom: 26px;
}

.odd\:mx-margin-label-height:nth-child(odd) {
  margin-left: 26px;
  margin-right: 26px;
}

.odd\:my-nav-height:nth-child(odd) {
  margin-top: 80px;
  margin-bottom: 80px;
}

.odd\:mx-nav-height:nth-child(odd) {
  margin-left: 80px;
  margin-right: 80px;
}

.odd\:-my-1:nth-child(odd) {
  margin-top: -5px;
  margin-bottom: -5px;
}

.odd\:-mx-1:nth-child(odd) {
  margin-left: -5px;
  margin-right: -5px;
}

.odd\:-my-2:nth-child(odd) {
  margin-top: -10px;
  margin-bottom: -10px;
}

.odd\:-mx-2:nth-child(odd) {
  margin-left: -10px;
  margin-right: -10px;
}

.odd\:-my-3:nth-child(odd) {
  margin-top: -15px;
  margin-bottom: -15px;
}

.odd\:-mx-3:nth-child(odd) {
  margin-left: -15px;
  margin-right: -15px;
}

.odd\:-my-4:nth-child(odd) {
  margin-top: -30px;
  margin-bottom: -30px;
}

.odd\:-mx-4:nth-child(odd) {
  margin-left: -30px;
  margin-right: -30px;
}

.odd\:-my-5:nth-child(odd) {
  margin-top: -50px;
  margin-bottom: -50px;
}

.odd\:-mx-5:nth-child(odd) {
  margin-left: -50px;
  margin-right: -50px;
}

.odd\:-my-6:nth-child(odd) {
  margin-top: -75px;
  margin-bottom: -75px;
}

.odd\:-mx-6:nth-child(odd) {
  margin-left: -75px;
  margin-right: -75px;
}

.odd\:-my-7:nth-child(odd) {
  margin-top: -100px;
  margin-bottom: -100px;
}

.odd\:-mx-7:nth-child(odd) {
  margin-left: -100px;
  margin-right: -100px;
}

.odd\:-my-none:nth-child(odd) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.odd\:-mx-none:nth-child(odd) {
  margin-left: 0px;
  margin-right: 0px;
}

.odd\:-my-tiny:nth-child(odd) {
  margin-top: -10px;
  margin-bottom: -10px;
}

.odd\:-mx-tiny:nth-child(odd) {
  margin-left: -10px;
  margin-right: -10px;
}

.odd\:-my-small:nth-child(odd) {
  margin-top: -20px;
  margin-bottom: -20px;
}

.odd\:-mx-small:nth-child(odd) {
  margin-left: -20px;
  margin-right: -20px;
}

.odd\:-my-medium:nth-child(odd) {
  margin-top: -30px;
  margin-bottom: -30px;
}

.odd\:-mx-medium:nth-child(odd) {
  margin-left: -30px;
  margin-right: -30px;
}

.odd\:-my-large:nth-child(odd) {
  margin-top: -40px;
  margin-bottom: -40px;
}

.odd\:-mx-large:nth-child(odd) {
  margin-left: -40px;
  margin-right: -40px;
}

.odd\:-my-huge:nth-child(odd) {
  margin-top: -60px;
  margin-bottom: -60px;
}

.odd\:-mx-huge:nth-child(odd) {
  margin-left: -60px;
  margin-right: -60px;
}

.odd\:-my-margin-label-height:nth-child(odd) {
  margin-top: -26px;
  margin-bottom: -26px;
}

.odd\:-mx-margin-label-height:nth-child(odd) {
  margin-left: -26px;
  margin-right: -26px;
}

.odd\:-my-nav-height:nth-child(odd) {
  margin-top: -80px;
  margin-bottom: -80px;
}

.odd\:-mx-nav-height:nth-child(odd) {
  margin-left: -80px;
  margin-right: -80px;
}

.odd\:mt-1:nth-child(odd) {
  margin-top: 5px;
}

.odd\:mr-1:nth-child(odd) {
  margin-right: 5px;
}

.odd\:mb-1:nth-child(odd) {
  margin-bottom: 5px;
}

.odd\:ml-1:nth-child(odd) {
  margin-left: 5px;
}

.odd\:mt-2:nth-child(odd) {
  margin-top: 10px;
}

.odd\:mr-2:nth-child(odd) {
  margin-right: 10px;
}

.odd\:mb-2:nth-child(odd) {
  margin-bottom: 10px;
}

.odd\:ml-2:nth-child(odd) {
  margin-left: 10px;
}

.odd\:mt-3:nth-child(odd) {
  margin-top: 15px;
}

.odd\:mr-3:nth-child(odd) {
  margin-right: 15px;
}

.odd\:mb-3:nth-child(odd) {
  margin-bottom: 15px;
}

.odd\:ml-3:nth-child(odd) {
  margin-left: 15px;
}

.odd\:mt-4:nth-child(odd) {
  margin-top: 30px;
}

.odd\:mr-4:nth-child(odd) {
  margin-right: 30px;
}

.odd\:mb-4:nth-child(odd) {
  margin-bottom: 30px;
}

.odd\:ml-4:nth-child(odd) {
  margin-left: 30px;
}

.odd\:mt-5:nth-child(odd) {
  margin-top: 50px;
}

.odd\:mr-5:nth-child(odd) {
  margin-right: 50px;
}

.odd\:mb-5:nth-child(odd) {
  margin-bottom: 50px;
}

.odd\:ml-5:nth-child(odd) {
  margin-left: 50px;
}

.odd\:mt-6:nth-child(odd) {
  margin-top: 75px;
}

.odd\:mr-6:nth-child(odd) {
  margin-right: 75px;
}

.odd\:mb-6:nth-child(odd) {
  margin-bottom: 75px;
}

.odd\:ml-6:nth-child(odd) {
  margin-left: 75px;
}

.odd\:mt-7:nth-child(odd) {
  margin-top: 100px;
}

.odd\:mr-7:nth-child(odd) {
  margin-right: 100px;
}

.odd\:mb-7:nth-child(odd) {
  margin-bottom: 100px;
}

.odd\:ml-7:nth-child(odd) {
  margin-left: 100px;
}

.odd\:mt-auto:nth-child(odd) {
  margin-top: auto;
}

.odd\:mr-auto:nth-child(odd) {
  margin-right: auto;
}

.odd\:mb-auto:nth-child(odd) {
  margin-bottom: auto;
}

.odd\:ml-auto:nth-child(odd) {
  margin-left: auto;
}

.odd\:mt-none:nth-child(odd) {
  margin-top: 0px;
}

.odd\:mr-none:nth-child(odd) {
  margin-right: 0px;
}

.odd\:mb-none:nth-child(odd) {
  margin-bottom: 0px;
}

.odd\:ml-none:nth-child(odd) {
  margin-left: 0px;
}

.odd\:mt-tiny:nth-child(odd) {
  margin-top: 10px;
}

.odd\:mr-tiny:nth-child(odd) {
  margin-right: 10px;
}

.odd\:mb-tiny:nth-child(odd) {
  margin-bottom: 10px;
}

.odd\:ml-tiny:nth-child(odd) {
  margin-left: 10px;
}

.odd\:mt-small:nth-child(odd) {
  margin-top: 20px;
}

.odd\:mr-small:nth-child(odd) {
  margin-right: 20px;
}

.odd\:mb-small:nth-child(odd) {
  margin-bottom: 20px;
}

.odd\:ml-small:nth-child(odd) {
  margin-left: 20px;
}

.odd\:mt-medium:nth-child(odd) {
  margin-top: 30px;
}

.odd\:mr-medium:nth-child(odd) {
  margin-right: 30px;
}

.odd\:mb-medium:nth-child(odd) {
  margin-bottom: 30px;
}

.odd\:ml-medium:nth-child(odd) {
  margin-left: 30px;
}

.odd\:mt-large:nth-child(odd) {
  margin-top: 40px;
}

.odd\:mr-large:nth-child(odd) {
  margin-right: 40px;
}

.odd\:mb-large:nth-child(odd) {
  margin-bottom: 40px;
}

.odd\:ml-large:nth-child(odd) {
  margin-left: 40px;
}

.odd\:mt-huge:nth-child(odd) {
  margin-top: 60px;
}

.odd\:mr-huge:nth-child(odd) {
  margin-right: 60px;
}

.odd\:mb-huge:nth-child(odd) {
  margin-bottom: 60px;
}

.odd\:ml-huge:nth-child(odd) {
  margin-left: 60px;
}

.odd\:mt-margin-label-height:nth-child(odd) {
  margin-top: 26px;
}

.odd\:mr-margin-label-height:nth-child(odd) {
  margin-right: 26px;
}

.odd\:mb-margin-label-height:nth-child(odd) {
  margin-bottom: 26px;
}

.odd\:ml-margin-label-height:nth-child(odd) {
  margin-left: 26px;
}

.odd\:mt-nav-height:nth-child(odd) {
  margin-top: 80px;
}

.odd\:mr-nav-height:nth-child(odd) {
  margin-right: 80px;
}

.odd\:mb-nav-height:nth-child(odd) {
  margin-bottom: 80px;
}

.odd\:ml-nav-height:nth-child(odd) {
  margin-left: 80px;
}

.odd\:-mt-1:nth-child(odd) {
  margin-top: -5px;
}

.odd\:-mr-1:nth-child(odd) {
  margin-right: -5px;
}

.odd\:-mb-1:nth-child(odd) {
  margin-bottom: -5px;
}

.odd\:-ml-1:nth-child(odd) {
  margin-left: -5px;
}

.odd\:-mt-2:nth-child(odd) {
  margin-top: -10px;
}

.odd\:-mr-2:nth-child(odd) {
  margin-right: -10px;
}

.odd\:-mb-2:nth-child(odd) {
  margin-bottom: -10px;
}

.odd\:-ml-2:nth-child(odd) {
  margin-left: -10px;
}

.odd\:-mt-3:nth-child(odd) {
  margin-top: -15px;
}

.odd\:-mr-3:nth-child(odd) {
  margin-right: -15px;
}

.odd\:-mb-3:nth-child(odd) {
  margin-bottom: -15px;
}

.odd\:-ml-3:nth-child(odd) {
  margin-left: -15px;
}

.odd\:-mt-4:nth-child(odd) {
  margin-top: -30px;
}

.odd\:-mr-4:nth-child(odd) {
  margin-right: -30px;
}

.odd\:-mb-4:nth-child(odd) {
  margin-bottom: -30px;
}

.odd\:-ml-4:nth-child(odd) {
  margin-left: -30px;
}

.odd\:-mt-5:nth-child(odd) {
  margin-top: -50px;
}

.odd\:-mr-5:nth-child(odd) {
  margin-right: -50px;
}

.odd\:-mb-5:nth-child(odd) {
  margin-bottom: -50px;
}

.odd\:-ml-5:nth-child(odd) {
  margin-left: -50px;
}

.odd\:-mt-6:nth-child(odd) {
  margin-top: -75px;
}

.odd\:-mr-6:nth-child(odd) {
  margin-right: -75px;
}

.odd\:-mb-6:nth-child(odd) {
  margin-bottom: -75px;
}

.odd\:-ml-6:nth-child(odd) {
  margin-left: -75px;
}

.odd\:-mt-7:nth-child(odd) {
  margin-top: -100px;
}

.odd\:-mr-7:nth-child(odd) {
  margin-right: -100px;
}

.odd\:-mb-7:nth-child(odd) {
  margin-bottom: -100px;
}

.odd\:-ml-7:nth-child(odd) {
  margin-left: -100px;
}

.odd\:-mt-none:nth-child(odd) {
  margin-top: 0px;
}

.odd\:-mr-none:nth-child(odd) {
  margin-right: 0px;
}

.odd\:-mb-none:nth-child(odd) {
  margin-bottom: 0px;
}

.odd\:-ml-none:nth-child(odd) {
  margin-left: 0px;
}

.odd\:-mt-tiny:nth-child(odd) {
  margin-top: -10px;
}

.odd\:-mr-tiny:nth-child(odd) {
  margin-right: -10px;
}

.odd\:-mb-tiny:nth-child(odd) {
  margin-bottom: -10px;
}

.odd\:-ml-tiny:nth-child(odd) {
  margin-left: -10px;
}

.odd\:-mt-small:nth-child(odd) {
  margin-top: -20px;
}

.odd\:-mr-small:nth-child(odd) {
  margin-right: -20px;
}

.odd\:-mb-small:nth-child(odd) {
  margin-bottom: -20px;
}

.odd\:-ml-small:nth-child(odd) {
  margin-left: -20px;
}

.odd\:-mt-medium:nth-child(odd) {
  margin-top: -30px;
}

.odd\:-mr-medium:nth-child(odd) {
  margin-right: -30px;
}

.odd\:-mb-medium:nth-child(odd) {
  margin-bottom: -30px;
}

.odd\:-ml-medium:nth-child(odd) {
  margin-left: -30px;
}

.odd\:-mt-large:nth-child(odd) {
  margin-top: -40px;
}

.odd\:-mr-large:nth-child(odd) {
  margin-right: -40px;
}

.odd\:-mb-large:nth-child(odd) {
  margin-bottom: -40px;
}

.odd\:-ml-large:nth-child(odd) {
  margin-left: -40px;
}

.odd\:-mt-huge:nth-child(odd) {
  margin-top: -60px;
}

.odd\:-mr-huge:nth-child(odd) {
  margin-right: -60px;
}

.odd\:-mb-huge:nth-child(odd) {
  margin-bottom: -60px;
}

.odd\:-ml-huge:nth-child(odd) {
  margin-left: -60px;
}

.odd\:-mt-margin-label-height:nth-child(odd) {
  margin-top: -26px;
}

.odd\:-mr-margin-label-height:nth-child(odd) {
  margin-right: -26px;
}

.odd\:-mb-margin-label-height:nth-child(odd) {
  margin-bottom: -26px;
}

.odd\:-ml-margin-label-height:nth-child(odd) {
  margin-left: -26px;
}

.odd\:-mt-nav-height:nth-child(odd) {
  margin-top: -80px;
}

.odd\:-mr-nav-height:nth-child(odd) {
  margin-right: -80px;
}

.odd\:-mb-nav-height:nth-child(odd) {
  margin-bottom: -80px;
}

.odd\:-ml-nav-height:nth-child(odd) {
  margin-left: -80px;
}

.even\:m-1:nth-child(even) {
  margin: 5px;
}

.even\:m-2:nth-child(even) {
  margin: 10px;
}

.even\:m-3:nth-child(even) {
  margin: 15px;
}

.even\:m-4:nth-child(even) {
  margin: 30px;
}

.even\:m-5:nth-child(even) {
  margin: 50px;
}

.even\:m-6:nth-child(even) {
  margin: 75px;
}

.even\:m-7:nth-child(even) {
  margin: 100px;
}

.even\:m-auto:nth-child(even) {
  margin: auto;
}

.even\:m-none:nth-child(even) {
  margin: 0px;
}

.even\:m-tiny:nth-child(even) {
  margin: 10px;
}

.even\:m-small:nth-child(even) {
  margin: 20px;
}

.even\:m-medium:nth-child(even) {
  margin: 30px;
}

.even\:m-large:nth-child(even) {
  margin: 40px;
}

.even\:m-huge:nth-child(even) {
  margin: 60px;
}

.even\:m-margin-label-height:nth-child(even) {
  margin: 26px;
}

.even\:m-nav-height:nth-child(even) {
  margin: 80px;
}

.even\:-m-1:nth-child(even) {
  margin: -5px;
}

.even\:-m-2:nth-child(even) {
  margin: -10px;
}

.even\:-m-3:nth-child(even) {
  margin: -15px;
}

.even\:-m-4:nth-child(even) {
  margin: -30px;
}

.even\:-m-5:nth-child(even) {
  margin: -50px;
}

.even\:-m-6:nth-child(even) {
  margin: -75px;
}

.even\:-m-7:nth-child(even) {
  margin: -100px;
}

.even\:-m-none:nth-child(even) {
  margin: 0px;
}

.even\:-m-tiny:nth-child(even) {
  margin: -10px;
}

.even\:-m-small:nth-child(even) {
  margin: -20px;
}

.even\:-m-medium:nth-child(even) {
  margin: -30px;
}

.even\:-m-large:nth-child(even) {
  margin: -40px;
}

.even\:-m-huge:nth-child(even) {
  margin: -60px;
}

.even\:-m-margin-label-height:nth-child(even) {
  margin: -26px;
}

.even\:-m-nav-height:nth-child(even) {
  margin: -80px;
}

.even\:my-1:nth-child(even) {
  margin-top: 5px;
  margin-bottom: 5px;
}

.even\:mx-1:nth-child(even) {
  margin-left: 5px;
  margin-right: 5px;
}

.even\:my-2:nth-child(even) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.even\:mx-2:nth-child(even) {
  margin-left: 10px;
  margin-right: 10px;
}

.even\:my-3:nth-child(even) {
  margin-top: 15px;
  margin-bottom: 15px;
}

.even\:mx-3:nth-child(even) {
  margin-left: 15px;
  margin-right: 15px;
}

.even\:my-4:nth-child(even) {
  margin-top: 30px;
  margin-bottom: 30px;
}

.even\:mx-4:nth-child(even) {
  margin-left: 30px;
  margin-right: 30px;
}

.even\:my-5:nth-child(even) {
  margin-top: 50px;
  margin-bottom: 50px;
}

.even\:mx-5:nth-child(even) {
  margin-left: 50px;
  margin-right: 50px;
}

.even\:my-6:nth-child(even) {
  margin-top: 75px;
  margin-bottom: 75px;
}

.even\:mx-6:nth-child(even) {
  margin-left: 75px;
  margin-right: 75px;
}

.even\:my-7:nth-child(even) {
  margin-top: 100px;
  margin-bottom: 100px;
}

.even\:mx-7:nth-child(even) {
  margin-left: 100px;
  margin-right: 100px;
}

.even\:my-auto:nth-child(even) {
  margin-top: auto;
  margin-bottom: auto;
}

.even\:mx-auto:nth-child(even) {
  margin-left: auto;
  margin-right: auto;
}

.even\:my-none:nth-child(even) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.even\:mx-none:nth-child(even) {
  margin-left: 0px;
  margin-right: 0px;
}

.even\:my-tiny:nth-child(even) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.even\:mx-tiny:nth-child(even) {
  margin-left: 10px;
  margin-right: 10px;
}

.even\:my-small:nth-child(even) {
  margin-top: 20px;
  margin-bottom: 20px;
}

.even\:mx-small:nth-child(even) {
  margin-left: 20px;
  margin-right: 20px;
}

.even\:my-medium:nth-child(even) {
  margin-top: 30px;
  margin-bottom: 30px;
}

.even\:mx-medium:nth-child(even) {
  margin-left: 30px;
  margin-right: 30px;
}

.even\:my-large:nth-child(even) {
  margin-top: 40px;
  margin-bottom: 40px;
}

.even\:mx-large:nth-child(even) {
  margin-left: 40px;
  margin-right: 40px;
}

.even\:my-huge:nth-child(even) {
  margin-top: 60px;
  margin-bottom: 60px;
}

.even\:mx-huge:nth-child(even) {
  margin-left: 60px;
  margin-right: 60px;
}

.even\:my-margin-label-height:nth-child(even) {
  margin-top: 26px;
  margin-bottom: 26px;
}

.even\:mx-margin-label-height:nth-child(even) {
  margin-left: 26px;
  margin-right: 26px;
}

.even\:my-nav-height:nth-child(even) {
  margin-top: 80px;
  margin-bottom: 80px;
}

.even\:mx-nav-height:nth-child(even) {
  margin-left: 80px;
  margin-right: 80px;
}

.even\:-my-1:nth-child(even) {
  margin-top: -5px;
  margin-bottom: -5px;
}

.even\:-mx-1:nth-child(even) {
  margin-left: -5px;
  margin-right: -5px;
}

.even\:-my-2:nth-child(even) {
  margin-top: -10px;
  margin-bottom: -10px;
}

.even\:-mx-2:nth-child(even) {
  margin-left: -10px;
  margin-right: -10px;
}

.even\:-my-3:nth-child(even) {
  margin-top: -15px;
  margin-bottom: -15px;
}

.even\:-mx-3:nth-child(even) {
  margin-left: -15px;
  margin-right: -15px;
}

.even\:-my-4:nth-child(even) {
  margin-top: -30px;
  margin-bottom: -30px;
}

.even\:-mx-4:nth-child(even) {
  margin-left: -30px;
  margin-right: -30px;
}

.even\:-my-5:nth-child(even) {
  margin-top: -50px;
  margin-bottom: -50px;
}

.even\:-mx-5:nth-child(even) {
  margin-left: -50px;
  margin-right: -50px;
}

.even\:-my-6:nth-child(even) {
  margin-top: -75px;
  margin-bottom: -75px;
}

.even\:-mx-6:nth-child(even) {
  margin-left: -75px;
  margin-right: -75px;
}

.even\:-my-7:nth-child(even) {
  margin-top: -100px;
  margin-bottom: -100px;
}

.even\:-mx-7:nth-child(even) {
  margin-left: -100px;
  margin-right: -100px;
}

.even\:-my-none:nth-child(even) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.even\:-mx-none:nth-child(even) {
  margin-left: 0px;
  margin-right: 0px;
}

.even\:-my-tiny:nth-child(even) {
  margin-top: -10px;
  margin-bottom: -10px;
}

.even\:-mx-tiny:nth-child(even) {
  margin-left: -10px;
  margin-right: -10px;
}

.even\:-my-small:nth-child(even) {
  margin-top: -20px;
  margin-bottom: -20px;
}

.even\:-mx-small:nth-child(even) {
  margin-left: -20px;
  margin-right: -20px;
}

.even\:-my-medium:nth-child(even) {
  margin-top: -30px;
  margin-bottom: -30px;
}

.even\:-mx-medium:nth-child(even) {
  margin-left: -30px;
  margin-right: -30px;
}

.even\:-my-large:nth-child(even) {
  margin-top: -40px;
  margin-bottom: -40px;
}

.even\:-mx-large:nth-child(even) {
  margin-left: -40px;
  margin-right: -40px;
}

.even\:-my-huge:nth-child(even) {
  margin-top: -60px;
  margin-bottom: -60px;
}

.even\:-mx-huge:nth-child(even) {
  margin-left: -60px;
  margin-right: -60px;
}

.even\:-my-margin-label-height:nth-child(even) {
  margin-top: -26px;
  margin-bottom: -26px;
}

.even\:-mx-margin-label-height:nth-child(even) {
  margin-left: -26px;
  margin-right: -26px;
}

.even\:-my-nav-height:nth-child(even) {
  margin-top: -80px;
  margin-bottom: -80px;
}

.even\:-mx-nav-height:nth-child(even) {
  margin-left: -80px;
  margin-right: -80px;
}

.even\:mt-1:nth-child(even) {
  margin-top: 5px;
}

.even\:mr-1:nth-child(even) {
  margin-right: 5px;
}

.even\:mb-1:nth-child(even) {
  margin-bottom: 5px;
}

.even\:ml-1:nth-child(even) {
  margin-left: 5px;
}

.even\:mt-2:nth-child(even) {
  margin-top: 10px;
}

.even\:mr-2:nth-child(even) {
  margin-right: 10px;
}

.even\:mb-2:nth-child(even) {
  margin-bottom: 10px;
}

.even\:ml-2:nth-child(even) {
  margin-left: 10px;
}

.even\:mt-3:nth-child(even) {
  margin-top: 15px;
}

.even\:mr-3:nth-child(even) {
  margin-right: 15px;
}

.even\:mb-3:nth-child(even) {
  margin-bottom: 15px;
}

.even\:ml-3:nth-child(even) {
  margin-left: 15px;
}

.even\:mt-4:nth-child(even) {
  margin-top: 30px;
}

.even\:mr-4:nth-child(even) {
  margin-right: 30px;
}

.even\:mb-4:nth-child(even) {
  margin-bottom: 30px;
}

.even\:ml-4:nth-child(even) {
  margin-left: 30px;
}

.even\:mt-5:nth-child(even) {
  margin-top: 50px;
}

.even\:mr-5:nth-child(even) {
  margin-right: 50px;
}

.even\:mb-5:nth-child(even) {
  margin-bottom: 50px;
}

.even\:ml-5:nth-child(even) {
  margin-left: 50px;
}

.even\:mt-6:nth-child(even) {
  margin-top: 75px;
}

.even\:mr-6:nth-child(even) {
  margin-right: 75px;
}

.even\:mb-6:nth-child(even) {
  margin-bottom: 75px;
}

.even\:ml-6:nth-child(even) {
  margin-left: 75px;
}

.even\:mt-7:nth-child(even) {
  margin-top: 100px;
}

.even\:mr-7:nth-child(even) {
  margin-right: 100px;
}

.even\:mb-7:nth-child(even) {
  margin-bottom: 100px;
}

.even\:ml-7:nth-child(even) {
  margin-left: 100px;
}

.even\:mt-auto:nth-child(even) {
  margin-top: auto;
}

.even\:mr-auto:nth-child(even) {
  margin-right: auto;
}

.even\:mb-auto:nth-child(even) {
  margin-bottom: auto;
}

.even\:ml-auto:nth-child(even) {
  margin-left: auto;
}

.even\:mt-none:nth-child(even) {
  margin-top: 0px;
}

.even\:mr-none:nth-child(even) {
  margin-right: 0px;
}

.even\:mb-none:nth-child(even) {
  margin-bottom: 0px;
}

.even\:ml-none:nth-child(even) {
  margin-left: 0px;
}

.even\:mt-tiny:nth-child(even) {
  margin-top: 10px;
}

.even\:mr-tiny:nth-child(even) {
  margin-right: 10px;
}

.even\:mb-tiny:nth-child(even) {
  margin-bottom: 10px;
}

.even\:ml-tiny:nth-child(even) {
  margin-left: 10px;
}

.even\:mt-small:nth-child(even) {
  margin-top: 20px;
}

.even\:mr-small:nth-child(even) {
  margin-right: 20px;
}

.even\:mb-small:nth-child(even) {
  margin-bottom: 20px;
}

.even\:ml-small:nth-child(even) {
  margin-left: 20px;
}

.even\:mt-medium:nth-child(even) {
  margin-top: 30px;
}

.even\:mr-medium:nth-child(even) {
  margin-right: 30px;
}

.even\:mb-medium:nth-child(even) {
  margin-bottom: 30px;
}

.even\:ml-medium:nth-child(even) {
  margin-left: 30px;
}

.even\:mt-large:nth-child(even) {
  margin-top: 40px;
}

.even\:mr-large:nth-child(even) {
  margin-right: 40px;
}

.even\:mb-large:nth-child(even) {
  margin-bottom: 40px;
}

.even\:ml-large:nth-child(even) {
  margin-left: 40px;
}

.even\:mt-huge:nth-child(even) {
  margin-top: 60px;
}

.even\:mr-huge:nth-child(even) {
  margin-right: 60px;
}

.even\:mb-huge:nth-child(even) {
  margin-bottom: 60px;
}

.even\:ml-huge:nth-child(even) {
  margin-left: 60px;
}

.even\:mt-margin-label-height:nth-child(even) {
  margin-top: 26px;
}

.even\:mr-margin-label-height:nth-child(even) {
  margin-right: 26px;
}

.even\:mb-margin-label-height:nth-child(even) {
  margin-bottom: 26px;
}

.even\:ml-margin-label-height:nth-child(even) {
  margin-left: 26px;
}

.even\:mt-nav-height:nth-child(even) {
  margin-top: 80px;
}

.even\:mr-nav-height:nth-child(even) {
  margin-right: 80px;
}

.even\:mb-nav-height:nth-child(even) {
  margin-bottom: 80px;
}

.even\:ml-nav-height:nth-child(even) {
  margin-left: 80px;
}

.even\:-mt-1:nth-child(even) {
  margin-top: -5px;
}

.even\:-mr-1:nth-child(even) {
  margin-right: -5px;
}

.even\:-mb-1:nth-child(even) {
  margin-bottom: -5px;
}

.even\:-ml-1:nth-child(even) {
  margin-left: -5px;
}

.even\:-mt-2:nth-child(even) {
  margin-top: -10px;
}

.even\:-mr-2:nth-child(even) {
  margin-right: -10px;
}

.even\:-mb-2:nth-child(even) {
  margin-bottom: -10px;
}

.even\:-ml-2:nth-child(even) {
  margin-left: -10px;
}

.even\:-mt-3:nth-child(even) {
  margin-top: -15px;
}

.even\:-mr-3:nth-child(even) {
  margin-right: -15px;
}

.even\:-mb-3:nth-child(even) {
  margin-bottom: -15px;
}

.even\:-ml-3:nth-child(even) {
  margin-left: -15px;
}

.even\:-mt-4:nth-child(even) {
  margin-top: -30px;
}

.even\:-mr-4:nth-child(even) {
  margin-right: -30px;
}

.even\:-mb-4:nth-child(even) {
  margin-bottom: -30px;
}

.even\:-ml-4:nth-child(even) {
  margin-left: -30px;
}

.even\:-mt-5:nth-child(even) {
  margin-top: -50px;
}

.even\:-mr-5:nth-child(even) {
  margin-right: -50px;
}

.even\:-mb-5:nth-child(even) {
  margin-bottom: -50px;
}

.even\:-ml-5:nth-child(even) {
  margin-left: -50px;
}

.even\:-mt-6:nth-child(even) {
  margin-top: -75px;
}

.even\:-mr-6:nth-child(even) {
  margin-right: -75px;
}

.even\:-mb-6:nth-child(even) {
  margin-bottom: -75px;
}

.even\:-ml-6:nth-child(even) {
  margin-left: -75px;
}

.even\:-mt-7:nth-child(even) {
  margin-top: -100px;
}

.even\:-mr-7:nth-child(even) {
  margin-right: -100px;
}

.even\:-mb-7:nth-child(even) {
  margin-bottom: -100px;
}

.even\:-ml-7:nth-child(even) {
  margin-left: -100px;
}

.even\:-mt-none:nth-child(even) {
  margin-top: 0px;
}

.even\:-mr-none:nth-child(even) {
  margin-right: 0px;
}

.even\:-mb-none:nth-child(even) {
  margin-bottom: 0px;
}

.even\:-ml-none:nth-child(even) {
  margin-left: 0px;
}

.even\:-mt-tiny:nth-child(even) {
  margin-top: -10px;
}

.even\:-mr-tiny:nth-child(even) {
  margin-right: -10px;
}

.even\:-mb-tiny:nth-child(even) {
  margin-bottom: -10px;
}

.even\:-ml-tiny:nth-child(even) {
  margin-left: -10px;
}

.even\:-mt-small:nth-child(even) {
  margin-top: -20px;
}

.even\:-mr-small:nth-child(even) {
  margin-right: -20px;
}

.even\:-mb-small:nth-child(even) {
  margin-bottom: -20px;
}

.even\:-ml-small:nth-child(even) {
  margin-left: -20px;
}

.even\:-mt-medium:nth-child(even) {
  margin-top: -30px;
}

.even\:-mr-medium:nth-child(even) {
  margin-right: -30px;
}

.even\:-mb-medium:nth-child(even) {
  margin-bottom: -30px;
}

.even\:-ml-medium:nth-child(even) {
  margin-left: -30px;
}

.even\:-mt-large:nth-child(even) {
  margin-top: -40px;
}

.even\:-mr-large:nth-child(even) {
  margin-right: -40px;
}

.even\:-mb-large:nth-child(even) {
  margin-bottom: -40px;
}

.even\:-ml-large:nth-child(even) {
  margin-left: -40px;
}

.even\:-mt-huge:nth-child(even) {
  margin-top: -60px;
}

.even\:-mr-huge:nth-child(even) {
  margin-right: -60px;
}

.even\:-mb-huge:nth-child(even) {
  margin-bottom: -60px;
}

.even\:-ml-huge:nth-child(even) {
  margin-left: -60px;
}

.even\:-mt-margin-label-height:nth-child(even) {
  margin-top: -26px;
}

.even\:-mr-margin-label-height:nth-child(even) {
  margin-right: -26px;
}

.even\:-mb-margin-label-height:nth-child(even) {
  margin-bottom: -26px;
}

.even\:-ml-margin-label-height:nth-child(even) {
  margin-left: -26px;
}

.even\:-mt-nav-height:nth-child(even) {
  margin-top: -80px;
}

.even\:-mr-nav-height:nth-child(even) {
  margin-right: -80px;
}

.even\:-mb-nav-height:nth-child(even) {
  margin-bottom: -80px;
}

.even\:-ml-nav-height:nth-child(even) {
  margin-left: -80px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-tablet {
  max-width: 801px;
}

.max-w-screen-desktop {
  max-width: 1024px;
}

.max-w-screen-widescreen {
  max-width: 1216px;
}

.max-w-screen-fullhd {
  max-width: 1408px;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-0:hover {
  opacity: 0;
}

.hover\:opacity-25:hover {
  opacity: 0.25;
}

.hover\:opacity-50:hover {
  opacity: 0.5;
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:opacity-0:focus {
  opacity: 0;
}

.focus\:opacity-25:focus {
  opacity: 0.25;
}

.focus\:opacity-50:focus {
  opacity: 0.5;
}

.focus\:opacity-75:focus {
  opacity: 0.75;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.overscroll-auto {
  -ms-scroll-chaining: chained;
      overscroll-behavior: auto;
}

.overscroll-contain {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

.overscroll-none {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 30px;
}

.p-5 {
  padding: 50px;
}

.p-6 {
  padding: 75px;
}

.p-7 {
  padding: 100px;
}

.p-none {
  padding: 0px;
}

.p-tiny {
  padding: 10px;
}

.p-small {
  padding: 20px;
}

.p-medium {
  padding: 30px;
}

.p-large {
  padding: 40px;
}

.p-huge {
  padding: 60px;
}

.p-margin-label-height {
  padding: 26px;
}

.p-nav-height {
  padding: 80px;
}

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-4 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-4 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-5 {
  padding-left: 50px;
  padding-right: 50px;
}

.py-6 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.px-6 {
  padding-left: 75px;
  padding-right: 75px;
}

.py-7 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px-7 {
  padding-left: 100px;
  padding-right: 100px;
}

.py-none {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-none {
  padding-left: 0px;
  padding-right: 0px;
}

.py-tiny {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-tiny {
  padding-left: 10px;
  padding-right: 10px;
}

.py-small {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-small {
  padding-left: 20px;
  padding-right: 20px;
}

.py-medium {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-medium {
  padding-left: 30px;
  padding-right: 30px;
}

.py-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

.px-large {
  padding-left: 40px;
  padding-right: 40px;
}

.py-huge {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-huge {
  padding-left: 60px;
  padding-right: 60px;
}

.py-margin-label-height {
  padding-top: 26px;
  padding-bottom: 26px;
}

.px-margin-label-height {
  padding-left: 26px;
  padding-right: 26px;
}

.py-nav-height {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-nav-height {
  padding-left: 80px;
  padding-right: 80px;
}

.pt-1 {
  padding-top: 5px;
}

.pr-1 {
  padding-right: 5px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pl-1 {
  padding-left: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pr-2 {
  padding-right: 10px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pl-2 {
  padding-left: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pr-3 {
  padding-right: 15px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pl-3 {
  padding-left: 15px;
}

.pt-4 {
  padding-top: 30px;
}

.pr-4 {
  padding-right: 30px;
}

.pb-4 {
  padding-bottom: 30px;
}

.pl-4 {
  padding-left: 30px;
}

.pt-5 {
  padding-top: 50px;
}

.pr-5 {
  padding-right: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pl-5 {
  padding-left: 50px;
}

.pt-6 {
  padding-top: 75px;
}

.pr-6 {
  padding-right: 75px;
}

.pb-6 {
  padding-bottom: 75px;
}

.pl-6 {
  padding-left: 75px;
}

.pt-7 {
  padding-top: 100px;
}

.pr-7 {
  padding-right: 100px;
}

.pb-7 {
  padding-bottom: 100px;
}

.pl-7 {
  padding-left: 100px;
}

.pt-none {
  padding-top: 0px;
}

.pr-none {
  padding-right: 0px;
}

.pb-none {
  padding-bottom: 0px;
}

.pl-none {
  padding-left: 0px;
}

.pt-tiny {
  padding-top: 10px;
}

.pr-tiny {
  padding-right: 10px;
}

.pb-tiny {
  padding-bottom: 10px;
}

.pl-tiny {
  padding-left: 10px;
}

.pt-small {
  padding-top: 20px;
}

.pr-small {
  padding-right: 20px;
}

.pb-small {
  padding-bottom: 20px;
}

.pl-small {
  padding-left: 20px;
}

.pt-medium {
  padding-top: 30px;
}

.pr-medium {
  padding-right: 30px;
}

.pb-medium {
  padding-bottom: 30px;
}

.pl-medium {
  padding-left: 30px;
}

.pt-large {
  padding-top: 40px;
}

.pr-large {
  padding-right: 40px;
}

.pb-large {
  padding-bottom: 40px;
}

.pl-large {
  padding-left: 40px;
}

.pt-huge {
  padding-top: 60px;
}

.pr-huge {
  padding-right: 60px;
}

.pb-huge {
  padding-bottom: 60px;
}

.pl-huge {
  padding-left: 60px;
}

.pt-margin-label-height {
  padding-top: 26px;
}

.pr-margin-label-height {
  padding-right: 26px;
}

.pb-margin-label-height {
  padding-bottom: 26px;
}

.pl-margin-label-height {
  padding-left: 26px;
}

.pt-nav-height {
  padding-top: 80px;
}

.pr-nav-height {
  padding-right: 80px;
}

.pb-nav-height {
  padding-bottom: 80px;
}

.pl-nav-height {
  padding-left: 80px;
}

.hover\:p-1:hover {
  padding: 5px;
}

.hover\:p-2:hover {
  padding: 10px;
}

.hover\:p-3:hover {
  padding: 15px;
}

.hover\:p-4:hover {
  padding: 30px;
}

.hover\:p-5:hover {
  padding: 50px;
}

.hover\:p-6:hover {
  padding: 75px;
}

.hover\:p-7:hover {
  padding: 100px;
}

.hover\:p-none:hover {
  padding: 0px;
}

.hover\:p-tiny:hover {
  padding: 10px;
}

.hover\:p-small:hover {
  padding: 20px;
}

.hover\:p-medium:hover {
  padding: 30px;
}

.hover\:p-large:hover {
  padding: 40px;
}

.hover\:p-huge:hover {
  padding: 60px;
}

.hover\:p-margin-label-height:hover {
  padding: 26px;
}

.hover\:p-nav-height:hover {
  padding: 80px;
}

.hover\:py-1:hover {
  padding-top: 5px;
  padding-bottom: 5px;
}

.hover\:px-1:hover {
  padding-left: 5px;
  padding-right: 5px;
}

.hover\:py-2:hover {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hover\:px-2:hover {
  padding-left: 10px;
  padding-right: 10px;
}

.hover\:py-3:hover {
  padding-top: 15px;
  padding-bottom: 15px;
}

.hover\:px-3:hover {
  padding-left: 15px;
  padding-right: 15px;
}

.hover\:py-4:hover {
  padding-top: 30px;
  padding-bottom: 30px;
}

.hover\:px-4:hover {
  padding-left: 30px;
  padding-right: 30px;
}

.hover\:py-5:hover {
  padding-top: 50px;
  padding-bottom: 50px;
}

.hover\:px-5:hover {
  padding-left: 50px;
  padding-right: 50px;
}

.hover\:py-6:hover {
  padding-top: 75px;
  padding-bottom: 75px;
}

.hover\:px-6:hover {
  padding-left: 75px;
  padding-right: 75px;
}

.hover\:py-7:hover {
  padding-top: 100px;
  padding-bottom: 100px;
}

.hover\:px-7:hover {
  padding-left: 100px;
  padding-right: 100px;
}

.hover\:py-none:hover {
  padding-top: 0px;
  padding-bottom: 0px;
}

.hover\:px-none:hover {
  padding-left: 0px;
  padding-right: 0px;
}

.hover\:py-tiny:hover {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hover\:px-tiny:hover {
  padding-left: 10px;
  padding-right: 10px;
}

.hover\:py-small:hover {
  padding-top: 20px;
  padding-bottom: 20px;
}

.hover\:px-small:hover {
  padding-left: 20px;
  padding-right: 20px;
}

.hover\:py-medium:hover {
  padding-top: 30px;
  padding-bottom: 30px;
}

.hover\:px-medium:hover {
  padding-left: 30px;
  padding-right: 30px;
}

.hover\:py-large:hover {
  padding-top: 40px;
  padding-bottom: 40px;
}

.hover\:px-large:hover {
  padding-left: 40px;
  padding-right: 40px;
}

.hover\:py-huge:hover {
  padding-top: 60px;
  padding-bottom: 60px;
}

.hover\:px-huge:hover {
  padding-left: 60px;
  padding-right: 60px;
}

.hover\:py-margin-label-height:hover {
  padding-top: 26px;
  padding-bottom: 26px;
}

.hover\:px-margin-label-height:hover {
  padding-left: 26px;
  padding-right: 26px;
}

.hover\:py-nav-height:hover {
  padding-top: 80px;
  padding-bottom: 80px;
}

.hover\:px-nav-height:hover {
  padding-left: 80px;
  padding-right: 80px;
}

.hover\:pt-1:hover {
  padding-top: 5px;
}

.hover\:pr-1:hover {
  padding-right: 5px;
}

.hover\:pb-1:hover {
  padding-bottom: 5px;
}

.hover\:pl-1:hover {
  padding-left: 5px;
}

.hover\:pt-2:hover {
  padding-top: 10px;
}

.hover\:pr-2:hover {
  padding-right: 10px;
}

.hover\:pb-2:hover {
  padding-bottom: 10px;
}

.hover\:pl-2:hover {
  padding-left: 10px;
}

.hover\:pt-3:hover {
  padding-top: 15px;
}

.hover\:pr-3:hover {
  padding-right: 15px;
}

.hover\:pb-3:hover {
  padding-bottom: 15px;
}

.hover\:pl-3:hover {
  padding-left: 15px;
}

.hover\:pt-4:hover {
  padding-top: 30px;
}

.hover\:pr-4:hover {
  padding-right: 30px;
}

.hover\:pb-4:hover {
  padding-bottom: 30px;
}

.hover\:pl-4:hover {
  padding-left: 30px;
}

.hover\:pt-5:hover {
  padding-top: 50px;
}

.hover\:pr-5:hover {
  padding-right: 50px;
}

.hover\:pb-5:hover {
  padding-bottom: 50px;
}

.hover\:pl-5:hover {
  padding-left: 50px;
}

.hover\:pt-6:hover {
  padding-top: 75px;
}

.hover\:pr-6:hover {
  padding-right: 75px;
}

.hover\:pb-6:hover {
  padding-bottom: 75px;
}

.hover\:pl-6:hover {
  padding-left: 75px;
}

.hover\:pt-7:hover {
  padding-top: 100px;
}

.hover\:pr-7:hover {
  padding-right: 100px;
}

.hover\:pb-7:hover {
  padding-bottom: 100px;
}

.hover\:pl-7:hover {
  padding-left: 100px;
}

.hover\:pt-none:hover {
  padding-top: 0px;
}

.hover\:pr-none:hover {
  padding-right: 0px;
}

.hover\:pb-none:hover {
  padding-bottom: 0px;
}

.hover\:pl-none:hover {
  padding-left: 0px;
}

.hover\:pt-tiny:hover {
  padding-top: 10px;
}

.hover\:pr-tiny:hover {
  padding-right: 10px;
}

.hover\:pb-tiny:hover {
  padding-bottom: 10px;
}

.hover\:pl-tiny:hover {
  padding-left: 10px;
}

.hover\:pt-small:hover {
  padding-top: 20px;
}

.hover\:pr-small:hover {
  padding-right: 20px;
}

.hover\:pb-small:hover {
  padding-bottom: 20px;
}

.hover\:pl-small:hover {
  padding-left: 20px;
}

.hover\:pt-medium:hover {
  padding-top: 30px;
}

.hover\:pr-medium:hover {
  padding-right: 30px;
}

.hover\:pb-medium:hover {
  padding-bottom: 30px;
}

.hover\:pl-medium:hover {
  padding-left: 30px;
}

.hover\:pt-large:hover {
  padding-top: 40px;
}

.hover\:pr-large:hover {
  padding-right: 40px;
}

.hover\:pb-large:hover {
  padding-bottom: 40px;
}

.hover\:pl-large:hover {
  padding-left: 40px;
}

.hover\:pt-huge:hover {
  padding-top: 60px;
}

.hover\:pr-huge:hover {
  padding-right: 60px;
}

.hover\:pb-huge:hover {
  padding-bottom: 60px;
}

.hover\:pl-huge:hover {
  padding-left: 60px;
}

.hover\:pt-margin-label-height:hover {
  padding-top: 26px;
}

.hover\:pr-margin-label-height:hover {
  padding-right: 26px;
}

.hover\:pb-margin-label-height:hover {
  padding-bottom: 26px;
}

.hover\:pl-margin-label-height:hover {
  padding-left: 26px;
}

.hover\:pt-nav-height:hover {
  padding-top: 80px;
}

.hover\:pr-nav-height:hover {
  padding-right: 80px;
}

.hover\:pb-nav-height:hover {
  padding-bottom: 80px;
}

.hover\:pl-nav-height:hover {
  padding-left: 80px;
}

.focus\:p-1:focus {
  padding: 5px;
}

.focus\:p-2:focus {
  padding: 10px;
}

.focus\:p-3:focus {
  padding: 15px;
}

.focus\:p-4:focus {
  padding: 30px;
}

.focus\:p-5:focus {
  padding: 50px;
}

.focus\:p-6:focus {
  padding: 75px;
}

.focus\:p-7:focus {
  padding: 100px;
}

.focus\:p-none:focus {
  padding: 0px;
}

.focus\:p-tiny:focus {
  padding: 10px;
}

.focus\:p-small:focus {
  padding: 20px;
}

.focus\:p-medium:focus {
  padding: 30px;
}

.focus\:p-large:focus {
  padding: 40px;
}

.focus\:p-huge:focus {
  padding: 60px;
}

.focus\:p-margin-label-height:focus {
  padding: 26px;
}

.focus\:p-nav-height:focus {
  padding: 80px;
}

.focus\:py-1:focus {
  padding-top: 5px;
  padding-bottom: 5px;
}

.focus\:px-1:focus {
  padding-left: 5px;
  padding-right: 5px;
}

.focus\:py-2:focus {
  padding-top: 10px;
  padding-bottom: 10px;
}

.focus\:px-2:focus {
  padding-left: 10px;
  padding-right: 10px;
}

.focus\:py-3:focus {
  padding-top: 15px;
  padding-bottom: 15px;
}

.focus\:px-3:focus {
  padding-left: 15px;
  padding-right: 15px;
}

.focus\:py-4:focus {
  padding-top: 30px;
  padding-bottom: 30px;
}

.focus\:px-4:focus {
  padding-left: 30px;
  padding-right: 30px;
}

.focus\:py-5:focus {
  padding-top: 50px;
  padding-bottom: 50px;
}

.focus\:px-5:focus {
  padding-left: 50px;
  padding-right: 50px;
}

.focus\:py-6:focus {
  padding-top: 75px;
  padding-bottom: 75px;
}

.focus\:px-6:focus {
  padding-left: 75px;
  padding-right: 75px;
}

.focus\:py-7:focus {
  padding-top: 100px;
  padding-bottom: 100px;
}

.focus\:px-7:focus {
  padding-left: 100px;
  padding-right: 100px;
}

.focus\:py-none:focus {
  padding-top: 0px;
  padding-bottom: 0px;
}

.focus\:px-none:focus {
  padding-left: 0px;
  padding-right: 0px;
}

.focus\:py-tiny:focus {
  padding-top: 10px;
  padding-bottom: 10px;
}

.focus\:px-tiny:focus {
  padding-left: 10px;
  padding-right: 10px;
}

.focus\:py-small:focus {
  padding-top: 20px;
  padding-bottom: 20px;
}

.focus\:px-small:focus {
  padding-left: 20px;
  padding-right: 20px;
}

.focus\:py-medium:focus {
  padding-top: 30px;
  padding-bottom: 30px;
}

.focus\:px-medium:focus {
  padding-left: 30px;
  padding-right: 30px;
}

.focus\:py-large:focus {
  padding-top: 40px;
  padding-bottom: 40px;
}

.focus\:px-large:focus {
  padding-left: 40px;
  padding-right: 40px;
}

.focus\:py-huge:focus {
  padding-top: 60px;
  padding-bottom: 60px;
}

.focus\:px-huge:focus {
  padding-left: 60px;
  padding-right: 60px;
}

.focus\:py-margin-label-height:focus {
  padding-top: 26px;
  padding-bottom: 26px;
}

.focus\:px-margin-label-height:focus {
  padding-left: 26px;
  padding-right: 26px;
}

.focus\:py-nav-height:focus {
  padding-top: 80px;
  padding-bottom: 80px;
}

.focus\:px-nav-height:focus {
  padding-left: 80px;
  padding-right: 80px;
}

.focus\:pt-1:focus {
  padding-top: 5px;
}

.focus\:pr-1:focus {
  padding-right: 5px;
}

.focus\:pb-1:focus {
  padding-bottom: 5px;
}

.focus\:pl-1:focus {
  padding-left: 5px;
}

.focus\:pt-2:focus {
  padding-top: 10px;
}

.focus\:pr-2:focus {
  padding-right: 10px;
}

.focus\:pb-2:focus {
  padding-bottom: 10px;
}

.focus\:pl-2:focus {
  padding-left: 10px;
}

.focus\:pt-3:focus {
  padding-top: 15px;
}

.focus\:pr-3:focus {
  padding-right: 15px;
}

.focus\:pb-3:focus {
  padding-bottom: 15px;
}

.focus\:pl-3:focus {
  padding-left: 15px;
}

.focus\:pt-4:focus {
  padding-top: 30px;
}

.focus\:pr-4:focus {
  padding-right: 30px;
}

.focus\:pb-4:focus {
  padding-bottom: 30px;
}

.focus\:pl-4:focus {
  padding-left: 30px;
}

.focus\:pt-5:focus {
  padding-top: 50px;
}

.focus\:pr-5:focus {
  padding-right: 50px;
}

.focus\:pb-5:focus {
  padding-bottom: 50px;
}

.focus\:pl-5:focus {
  padding-left: 50px;
}

.focus\:pt-6:focus {
  padding-top: 75px;
}

.focus\:pr-6:focus {
  padding-right: 75px;
}

.focus\:pb-6:focus {
  padding-bottom: 75px;
}

.focus\:pl-6:focus {
  padding-left: 75px;
}

.focus\:pt-7:focus {
  padding-top: 100px;
}

.focus\:pr-7:focus {
  padding-right: 100px;
}

.focus\:pb-7:focus {
  padding-bottom: 100px;
}

.focus\:pl-7:focus {
  padding-left: 100px;
}

.focus\:pt-none:focus {
  padding-top: 0px;
}

.focus\:pr-none:focus {
  padding-right: 0px;
}

.focus\:pb-none:focus {
  padding-bottom: 0px;
}

.focus\:pl-none:focus {
  padding-left: 0px;
}

.focus\:pt-tiny:focus {
  padding-top: 10px;
}

.focus\:pr-tiny:focus {
  padding-right: 10px;
}

.focus\:pb-tiny:focus {
  padding-bottom: 10px;
}

.focus\:pl-tiny:focus {
  padding-left: 10px;
}

.focus\:pt-small:focus {
  padding-top: 20px;
}

.focus\:pr-small:focus {
  padding-right: 20px;
}

.focus\:pb-small:focus {
  padding-bottom: 20px;
}

.focus\:pl-small:focus {
  padding-left: 20px;
}

.focus\:pt-medium:focus {
  padding-top: 30px;
}

.focus\:pr-medium:focus {
  padding-right: 30px;
}

.focus\:pb-medium:focus {
  padding-bottom: 30px;
}

.focus\:pl-medium:focus {
  padding-left: 30px;
}

.focus\:pt-large:focus {
  padding-top: 40px;
}

.focus\:pr-large:focus {
  padding-right: 40px;
}

.focus\:pb-large:focus {
  padding-bottom: 40px;
}

.focus\:pl-large:focus {
  padding-left: 40px;
}

.focus\:pt-huge:focus {
  padding-top: 60px;
}

.focus\:pr-huge:focus {
  padding-right: 60px;
}

.focus\:pb-huge:focus {
  padding-bottom: 60px;
}

.focus\:pl-huge:focus {
  padding-left: 60px;
}

.focus\:pt-margin-label-height:focus {
  padding-top: 26px;
}

.focus\:pr-margin-label-height:focus {
  padding-right: 26px;
}

.focus\:pb-margin-label-height:focus {
  padding-bottom: 26px;
}

.focus\:pl-margin-label-height:focus {
  padding-left: 26px;
}

.focus\:pt-nav-height:focus {
  padding-top: 80px;
}

.focus\:pr-nav-height:focus {
  padding-right: 80px;
}

.focus\:pb-nav-height:focus {
  padding-bottom: 80px;
}

.focus\:pl-nav-height:focus {
  padding-left: 80px;
}

.first\:p-1:first-child {
  padding: 5px;
}

.first\:p-2:first-child {
  padding: 10px;
}

.first\:p-3:first-child {
  padding: 15px;
}

.first\:p-4:first-child {
  padding: 30px;
}

.first\:p-5:first-child {
  padding: 50px;
}

.first\:p-6:first-child {
  padding: 75px;
}

.first\:p-7:first-child {
  padding: 100px;
}

.first\:p-none:first-child {
  padding: 0px;
}

.first\:p-tiny:first-child {
  padding: 10px;
}

.first\:p-small:first-child {
  padding: 20px;
}

.first\:p-medium:first-child {
  padding: 30px;
}

.first\:p-large:first-child {
  padding: 40px;
}

.first\:p-huge:first-child {
  padding: 60px;
}

.first\:p-margin-label-height:first-child {
  padding: 26px;
}

.first\:p-nav-height:first-child {
  padding: 80px;
}

.first\:py-1:first-child {
  padding-top: 5px;
  padding-bottom: 5px;
}

.first\:px-1:first-child {
  padding-left: 5px;
  padding-right: 5px;
}

.first\:py-2:first-child {
  padding-top: 10px;
  padding-bottom: 10px;
}

.first\:px-2:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.first\:py-3:first-child {
  padding-top: 15px;
  padding-bottom: 15px;
}

.first\:px-3:first-child {
  padding-left: 15px;
  padding-right: 15px;
}

.first\:py-4:first-child {
  padding-top: 30px;
  padding-bottom: 30px;
}

.first\:px-4:first-child {
  padding-left: 30px;
  padding-right: 30px;
}

.first\:py-5:first-child {
  padding-top: 50px;
  padding-bottom: 50px;
}

.first\:px-5:first-child {
  padding-left: 50px;
  padding-right: 50px;
}

.first\:py-6:first-child {
  padding-top: 75px;
  padding-bottom: 75px;
}

.first\:px-6:first-child {
  padding-left: 75px;
  padding-right: 75px;
}

.first\:py-7:first-child {
  padding-top: 100px;
  padding-bottom: 100px;
}

.first\:px-7:first-child {
  padding-left: 100px;
  padding-right: 100px;
}

.first\:py-none:first-child {
  padding-top: 0px;
  padding-bottom: 0px;
}

.first\:px-none:first-child {
  padding-left: 0px;
  padding-right: 0px;
}

.first\:py-tiny:first-child {
  padding-top: 10px;
  padding-bottom: 10px;
}

.first\:px-tiny:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.first\:py-small:first-child {
  padding-top: 20px;
  padding-bottom: 20px;
}

.first\:px-small:first-child {
  padding-left: 20px;
  padding-right: 20px;
}

.first\:py-medium:first-child {
  padding-top: 30px;
  padding-bottom: 30px;
}

.first\:px-medium:first-child {
  padding-left: 30px;
  padding-right: 30px;
}

.first\:py-large:first-child {
  padding-top: 40px;
  padding-bottom: 40px;
}

.first\:px-large:first-child {
  padding-left: 40px;
  padding-right: 40px;
}

.first\:py-huge:first-child {
  padding-top: 60px;
  padding-bottom: 60px;
}

.first\:px-huge:first-child {
  padding-left: 60px;
  padding-right: 60px;
}

.first\:py-margin-label-height:first-child {
  padding-top: 26px;
  padding-bottom: 26px;
}

.first\:px-margin-label-height:first-child {
  padding-left: 26px;
  padding-right: 26px;
}

.first\:py-nav-height:first-child {
  padding-top: 80px;
  padding-bottom: 80px;
}

.first\:px-nav-height:first-child {
  padding-left: 80px;
  padding-right: 80px;
}

.first\:pt-1:first-child {
  padding-top: 5px;
}

.first\:pr-1:first-child {
  padding-right: 5px;
}

.first\:pb-1:first-child {
  padding-bottom: 5px;
}

.first\:pl-1:first-child {
  padding-left: 5px;
}

.first\:pt-2:first-child {
  padding-top: 10px;
}

.first\:pr-2:first-child {
  padding-right: 10px;
}

.first\:pb-2:first-child {
  padding-bottom: 10px;
}

.first\:pl-2:first-child {
  padding-left: 10px;
}

.first\:pt-3:first-child {
  padding-top: 15px;
}

.first\:pr-3:first-child {
  padding-right: 15px;
}

.first\:pb-3:first-child {
  padding-bottom: 15px;
}

.first\:pl-3:first-child {
  padding-left: 15px;
}

.first\:pt-4:first-child {
  padding-top: 30px;
}

.first\:pr-4:first-child {
  padding-right: 30px;
}

.first\:pb-4:first-child {
  padding-bottom: 30px;
}

.first\:pl-4:first-child {
  padding-left: 30px;
}

.first\:pt-5:first-child {
  padding-top: 50px;
}

.first\:pr-5:first-child {
  padding-right: 50px;
}

.first\:pb-5:first-child {
  padding-bottom: 50px;
}

.first\:pl-5:first-child {
  padding-left: 50px;
}

.first\:pt-6:first-child {
  padding-top: 75px;
}

.first\:pr-6:first-child {
  padding-right: 75px;
}

.first\:pb-6:first-child {
  padding-bottom: 75px;
}

.first\:pl-6:first-child {
  padding-left: 75px;
}

.first\:pt-7:first-child {
  padding-top: 100px;
}

.first\:pr-7:first-child {
  padding-right: 100px;
}

.first\:pb-7:first-child {
  padding-bottom: 100px;
}

.first\:pl-7:first-child {
  padding-left: 100px;
}

.first\:pt-none:first-child {
  padding-top: 0px;
}

.first\:pr-none:first-child {
  padding-right: 0px;
}

.first\:pb-none:first-child {
  padding-bottom: 0px;
}

.first\:pl-none:first-child {
  padding-left: 0px;
}

.first\:pt-tiny:first-child {
  padding-top: 10px;
}

.first\:pr-tiny:first-child {
  padding-right: 10px;
}

.first\:pb-tiny:first-child {
  padding-bottom: 10px;
}

.first\:pl-tiny:first-child {
  padding-left: 10px;
}

.first\:pt-small:first-child {
  padding-top: 20px;
}

.first\:pr-small:first-child {
  padding-right: 20px;
}

.first\:pb-small:first-child {
  padding-bottom: 20px;
}

.first\:pl-small:first-child {
  padding-left: 20px;
}

.first\:pt-medium:first-child {
  padding-top: 30px;
}

.first\:pr-medium:first-child {
  padding-right: 30px;
}

.first\:pb-medium:first-child {
  padding-bottom: 30px;
}

.first\:pl-medium:first-child {
  padding-left: 30px;
}

.first\:pt-large:first-child {
  padding-top: 40px;
}

.first\:pr-large:first-child {
  padding-right: 40px;
}

.first\:pb-large:first-child {
  padding-bottom: 40px;
}

.first\:pl-large:first-child {
  padding-left: 40px;
}

.first\:pt-huge:first-child {
  padding-top: 60px;
}

.first\:pr-huge:first-child {
  padding-right: 60px;
}

.first\:pb-huge:first-child {
  padding-bottom: 60px;
}

.first\:pl-huge:first-child {
  padding-left: 60px;
}

.first\:pt-margin-label-height:first-child {
  padding-top: 26px;
}

.first\:pr-margin-label-height:first-child {
  padding-right: 26px;
}

.first\:pb-margin-label-height:first-child {
  padding-bottom: 26px;
}

.first\:pl-margin-label-height:first-child {
  padding-left: 26px;
}

.first\:pt-nav-height:first-child {
  padding-top: 80px;
}

.first\:pr-nav-height:first-child {
  padding-right: 80px;
}

.first\:pb-nav-height:first-child {
  padding-bottom: 80px;
}

.first\:pl-nav-height:first-child {
  padding-left: 80px;
}

.last\:p-1:last-child {
  padding: 5px;
}

.last\:p-2:last-child {
  padding: 10px;
}

.last\:p-3:last-child {
  padding: 15px;
}

.last\:p-4:last-child {
  padding: 30px;
}

.last\:p-5:last-child {
  padding: 50px;
}

.last\:p-6:last-child {
  padding: 75px;
}

.last\:p-7:last-child {
  padding: 100px;
}

.last\:p-none:last-child {
  padding: 0px;
}

.last\:p-tiny:last-child {
  padding: 10px;
}

.last\:p-small:last-child {
  padding: 20px;
}

.last\:p-medium:last-child {
  padding: 30px;
}

.last\:p-large:last-child {
  padding: 40px;
}

.last\:p-huge:last-child {
  padding: 60px;
}

.last\:p-margin-label-height:last-child {
  padding: 26px;
}

.last\:p-nav-height:last-child {
  padding: 80px;
}

.last\:py-1:last-child {
  padding-top: 5px;
  padding-bottom: 5px;
}

.last\:px-1:last-child {
  padding-left: 5px;
  padding-right: 5px;
}

.last\:py-2:last-child {
  padding-top: 10px;
  padding-bottom: 10px;
}

.last\:px-2:last-child {
  padding-left: 10px;
  padding-right: 10px;
}

.last\:py-3:last-child {
  padding-top: 15px;
  padding-bottom: 15px;
}

.last\:px-3:last-child {
  padding-left: 15px;
  padding-right: 15px;
}

.last\:py-4:last-child {
  padding-top: 30px;
  padding-bottom: 30px;
}

.last\:px-4:last-child {
  padding-left: 30px;
  padding-right: 30px;
}

.last\:py-5:last-child {
  padding-top: 50px;
  padding-bottom: 50px;
}

.last\:px-5:last-child {
  padding-left: 50px;
  padding-right: 50px;
}

.last\:py-6:last-child {
  padding-top: 75px;
  padding-bottom: 75px;
}

.last\:px-6:last-child {
  padding-left: 75px;
  padding-right: 75px;
}

.last\:py-7:last-child {
  padding-top: 100px;
  padding-bottom: 100px;
}

.last\:px-7:last-child {
  padding-left: 100px;
  padding-right: 100px;
}

.last\:py-none:last-child {
  padding-top: 0px;
  padding-bottom: 0px;
}

.last\:px-none:last-child {
  padding-left: 0px;
  padding-right: 0px;
}

.last\:py-tiny:last-child {
  padding-top: 10px;
  padding-bottom: 10px;
}

.last\:px-tiny:last-child {
  padding-left: 10px;
  padding-right: 10px;
}

.last\:py-small:last-child {
  padding-top: 20px;
  padding-bottom: 20px;
}

.last\:px-small:last-child {
  padding-left: 20px;
  padding-right: 20px;
}

.last\:py-medium:last-child {
  padding-top: 30px;
  padding-bottom: 30px;
}

.last\:px-medium:last-child {
  padding-left: 30px;
  padding-right: 30px;
}

.last\:py-large:last-child {
  padding-top: 40px;
  padding-bottom: 40px;
}

.last\:px-large:last-child {
  padding-left: 40px;
  padding-right: 40px;
}

.last\:py-huge:last-child {
  padding-top: 60px;
  padding-bottom: 60px;
}

.last\:px-huge:last-child {
  padding-left: 60px;
  padding-right: 60px;
}

.last\:py-margin-label-height:last-child {
  padding-top: 26px;
  padding-bottom: 26px;
}

.last\:px-margin-label-height:last-child {
  padding-left: 26px;
  padding-right: 26px;
}

.last\:py-nav-height:last-child {
  padding-top: 80px;
  padding-bottom: 80px;
}

.last\:px-nav-height:last-child {
  padding-left: 80px;
  padding-right: 80px;
}

.last\:pt-1:last-child {
  padding-top: 5px;
}

.last\:pr-1:last-child {
  padding-right: 5px;
}

.last\:pb-1:last-child {
  padding-bottom: 5px;
}

.last\:pl-1:last-child {
  padding-left: 5px;
}

.last\:pt-2:last-child {
  padding-top: 10px;
}

.last\:pr-2:last-child {
  padding-right: 10px;
}

.last\:pb-2:last-child {
  padding-bottom: 10px;
}

.last\:pl-2:last-child {
  padding-left: 10px;
}

.last\:pt-3:last-child {
  padding-top: 15px;
}

.last\:pr-3:last-child {
  padding-right: 15px;
}

.last\:pb-3:last-child {
  padding-bottom: 15px;
}

.last\:pl-3:last-child {
  padding-left: 15px;
}

.last\:pt-4:last-child {
  padding-top: 30px;
}

.last\:pr-4:last-child {
  padding-right: 30px;
}

.last\:pb-4:last-child {
  padding-bottom: 30px;
}

.last\:pl-4:last-child {
  padding-left: 30px;
}

.last\:pt-5:last-child {
  padding-top: 50px;
}

.last\:pr-5:last-child {
  padding-right: 50px;
}

.last\:pb-5:last-child {
  padding-bottom: 50px;
}

.last\:pl-5:last-child {
  padding-left: 50px;
}

.last\:pt-6:last-child {
  padding-top: 75px;
}

.last\:pr-6:last-child {
  padding-right: 75px;
}

.last\:pb-6:last-child {
  padding-bottom: 75px;
}

.last\:pl-6:last-child {
  padding-left: 75px;
}

.last\:pt-7:last-child {
  padding-top: 100px;
}

.last\:pr-7:last-child {
  padding-right: 100px;
}

.last\:pb-7:last-child {
  padding-bottom: 100px;
}

.last\:pl-7:last-child {
  padding-left: 100px;
}

.last\:pt-none:last-child {
  padding-top: 0px;
}

.last\:pr-none:last-child {
  padding-right: 0px;
}

.last\:pb-none:last-child {
  padding-bottom: 0px;
}

.last\:pl-none:last-child {
  padding-left: 0px;
}

.last\:pt-tiny:last-child {
  padding-top: 10px;
}

.last\:pr-tiny:last-child {
  padding-right: 10px;
}

.last\:pb-tiny:last-child {
  padding-bottom: 10px;
}

.last\:pl-tiny:last-child {
  padding-left: 10px;
}

.last\:pt-small:last-child {
  padding-top: 20px;
}

.last\:pr-small:last-child {
  padding-right: 20px;
}

.last\:pb-small:last-child {
  padding-bottom: 20px;
}

.last\:pl-small:last-child {
  padding-left: 20px;
}

.last\:pt-medium:last-child {
  padding-top: 30px;
}

.last\:pr-medium:last-child {
  padding-right: 30px;
}

.last\:pb-medium:last-child {
  padding-bottom: 30px;
}

.last\:pl-medium:last-child {
  padding-left: 30px;
}

.last\:pt-large:last-child {
  padding-top: 40px;
}

.last\:pr-large:last-child {
  padding-right: 40px;
}

.last\:pb-large:last-child {
  padding-bottom: 40px;
}

.last\:pl-large:last-child {
  padding-left: 40px;
}

.last\:pt-huge:last-child {
  padding-top: 60px;
}

.last\:pr-huge:last-child {
  padding-right: 60px;
}

.last\:pb-huge:last-child {
  padding-bottom: 60px;
}

.last\:pl-huge:last-child {
  padding-left: 60px;
}

.last\:pt-margin-label-height:last-child {
  padding-top: 26px;
}

.last\:pr-margin-label-height:last-child {
  padding-right: 26px;
}

.last\:pb-margin-label-height:last-child {
  padding-bottom: 26px;
}

.last\:pl-margin-label-height:last-child {
  padding-left: 26px;
}

.last\:pt-nav-height:last-child {
  padding-top: 80px;
}

.last\:pr-nav-height:last-child {
  padding-right: 80px;
}

.last\:pb-nav-height:last-child {
  padding-bottom: 80px;
}

.last\:pl-nav-height:last-child {
  padding-left: 80px;
}

.placeholder-white::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-white:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-white::placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.placeholder-black::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.placeholder-black:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.placeholder-black::placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.placeholder-black-backdrop::-webkit-input-placeholder {
  color: #2c2c2c99;
}

.placeholder-black-backdrop:-ms-input-placeholder {
  color: #2c2c2c99;
}

.placeholder-black-backdrop::placeholder {
  color: #2c2c2c99;
}

.placeholder-beige::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.placeholder-beige:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.placeholder-beige::placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.placeholder-beige-dark::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.placeholder-beige-dark:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.placeholder-beige-dark::placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.placeholder-blue::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.placeholder-blue:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.placeholder-blue::placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.placeholder-blue-dark::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.placeholder-blue-dark:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.placeholder-blue-dark::placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.placeholder-blue-light::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-light:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-light::placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.placeholder-blue-hover::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.placeholder-blue-hover:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.placeholder-blue-hover::placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.placeholder-green::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.placeholder-green:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.placeholder-green::placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.placeholder-green-light::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.placeholder-green-light:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.placeholder-green-light::placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.placeholder-green-dark::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.placeholder-green-dark:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.placeholder-green-dark::placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.placeholder-grey-10::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.placeholder-grey-10:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.placeholder-grey-10::placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.placeholder-grey-20::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.placeholder-grey-20:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.placeholder-grey-20::placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.placeholder-grey-30::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.placeholder-grey-30:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.placeholder-grey-30::placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.placeholder-grey-40::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.placeholder-grey-40:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.placeholder-grey-40::placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.placeholder-grey-50::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.placeholder-grey-50:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.placeholder-grey-50::placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.placeholder-grey-60::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.placeholder-grey-60:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.placeholder-grey-60::placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.placeholder-grey-70::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.placeholder-grey-70:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.placeholder-grey-70::placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.placeholder-grey-80::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.placeholder-grey-80:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.placeholder-grey-80::placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.placeholder-grey-90::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.placeholder-grey-90:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.placeholder-grey-90::placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.placeholder-grey::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.placeholder-grey:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.placeholder-grey::placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.placeholder-grey-light::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.placeholder-grey-light:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.placeholder-grey-light::placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.placeholder-grey-dark::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.placeholder-grey-dark:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.placeholder-grey-dark::placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.placeholder-purple-visited::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.placeholder-purple-visited:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.placeholder-purple-visited::placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.placeholder-purple-visited-light::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.placeholder-purple-visited-light:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.placeholder-purple-visited-light::placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.placeholder-red::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.placeholder-red:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.placeholder-red::placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.placeholder-yellow::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.placeholder-yellow:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.placeholder-yellow::placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.placeholder-state-danger::-webkit-input-placeholder {
  color: #FF8274B3;
}

.placeholder-state-danger:-ms-input-placeholder {
  color: #FF8274B3;
}

.placeholder-state-danger::placeholder {
  color: #FF8274B3;
}

.placeholder-state-warning::-webkit-input-placeholder {
  color: #F9C66BB3;
}

.placeholder-state-warning:-ms-input-placeholder {
  color: #F9C66BB3;
}

.placeholder-state-warning::placeholder {
  color: #F9C66BB3;
}

.placeholder-state-success::-webkit-input-placeholder {
  color: #C7F6C9B3;
}

.placeholder-state-success:-ms-input-placeholder {
  color: #C7F6C9B3;
}

.placeholder-state-success::placeholder {
  color: #C7F6C9B3;
}

.placeholder-state-primary::-webkit-input-placeholder {
  color: #B3F5FFB3;
}

.placeholder-state-primary:-ms-input-placeholder {
  color: #B3F5FFB3;
}

.placeholder-state-primary::placeholder {
  color: #B3F5FFB3;
}

.focus\:placeholder-white:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-white:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-white:focus::placeholder {
  --placeholder-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.focus\:placeholder-black:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--placeholder-opacity));
}

.focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
  color: #2c2c2c99;
}

.focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
  color: #2c2c2c99;
}

.focus\:placeholder-black-backdrop:focus::placeholder {
  color: #2c2c2c99;
}

.focus\:placeholder-beige:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.focus\:placeholder-beige:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.focus\:placeholder-beige:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--placeholder-opacity));
}

.focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.focus\:placeholder-beige-dark:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--placeholder-opacity));
}

.focus\:placeholder-blue:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.focus\:placeholder-blue-dark:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--placeholder-opacity));
}

.focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-light:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-light:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--placeholder-opacity));
}

.focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.focus\:placeholder-blue-hover:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--placeholder-opacity));
}

.focus\:placeholder-green:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.focus\:placeholder-green:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.focus\:placeholder-green:focus::placeholder {
  --placeholder-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--placeholder-opacity));
}

.focus\:placeholder-green-light:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.focus\:placeholder-green-light:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.focus\:placeholder-green-light:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--placeholder-opacity));
}

.focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.focus\:placeholder-green-dark:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.focus\:placeholder-green-dark:focus::placeholder {
  --placeholder-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--placeholder-opacity));
}

.focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.focus\:placeholder-grey-10:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.focus\:placeholder-grey-10:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--placeholder-opacity));
}

.focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.focus\:placeholder-grey-20:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.focus\:placeholder-grey-20:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--placeholder-opacity));
}

.focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.focus\:placeholder-grey-30:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.focus\:placeholder-grey-30:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--placeholder-opacity));
}

.focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.focus\:placeholder-grey-40:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.focus\:placeholder-grey-40:focus::placeholder {
  --placeholder-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--placeholder-opacity));
}

.focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-grey-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-grey-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--placeholder-opacity));
}

.focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.focus\:placeholder-grey-60:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.focus\:placeholder-grey-60:focus::placeholder {
  --placeholder-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--placeholder-opacity));
}

.focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.focus\:placeholder-grey-70:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.focus\:placeholder-grey-70:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--placeholder-opacity));
}

.focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.focus\:placeholder-grey-80:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.focus\:placeholder-grey-80:focus::placeholder {
  --placeholder-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--placeholder-opacity));
}

.focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.focus\:placeholder-grey-90:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.focus\:placeholder-grey-90:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--placeholder-opacity));
}

.focus\:placeholder-grey:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-grey:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-grey:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--placeholder-opacity));
}

.focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.focus\:placeholder-grey-light:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.focus\:placeholder-grey-light:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--placeholder-opacity));
}

.focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.focus\:placeholder-grey-dark:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.focus\:placeholder-purple-visited-light:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--placeholder-opacity));
}

.focus\:placeholder-red:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.focus\:placeholder-red:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.focus\:placeholder-red:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--placeholder-opacity));
}

.focus\:placeholder-yellow:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.focus\:placeholder-yellow:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.focus\:placeholder-yellow:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--placeholder-opacity));
}

.focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
  color: #FF8274B3;
}

.focus\:placeholder-state-danger:focus:-ms-input-placeholder {
  color: #FF8274B3;
}

.focus\:placeholder-state-danger:focus::placeholder {
  color: #FF8274B3;
}

.focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
  color: #F9C66BB3;
}

.focus\:placeholder-state-warning:focus:-ms-input-placeholder {
  color: #F9C66BB3;
}

.focus\:placeholder-state-warning:focus::placeholder {
  color: #F9C66BB3;
}

.focus\:placeholder-state-success:focus::-webkit-input-placeholder {
  color: #C7F6C9B3;
}

.focus\:placeholder-state-success:focus:-ms-input-placeholder {
  color: #C7F6C9B3;
}

.focus\:placeholder-state-success:focus::placeholder {
  color: #C7F6C9B3;
}

.focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
  color: #B3F5FFB3;
}

.focus\:placeholder-state-primary:focus:-ms-input-placeholder {
  color: #B3F5FFB3;
}

.focus\:placeholder-state-primary:focus::placeholder {
  color: #B3F5FFB3;
}

.placeholder-opacity-0::-webkit-input-placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-0:-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-25::-webkit-input-placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-25:-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25;
}

.placeholder-opacity-50::-webkit-input-placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-50:-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5;
}

.placeholder-opacity-75::-webkit-input-placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-75:-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75;
}

.placeholder-opacity-100::-webkit-input-placeholder {
  --placeholder-opacity: 1;
}

.placeholder-opacity-100:-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-x-0 {
  right: 0;
  left: 0;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-x-auto {
  right: auto;
  left: auto;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-auto {
  top: auto;
}

.right-auto {
  right: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.shadow-none {
  box-shadow: none;
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.hover\:shadow-none:hover {
  box-shadow: none;
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\:shadow-none:focus {
  box-shadow: none;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1;
}

.stroke-2 {
  stroke-width: 2;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-white {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-black {
  --text-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--text-opacity));
}

.text-black-backdrop {
  color: #2c2c2c99;
}

.text-beige {
  --text-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--text-opacity));
}

.text-beige-dark {
  --text-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--text-opacity));
}

.text-blue {
  --text-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--text-opacity));
}

.text-blue-dark {
  --text-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--text-opacity));
}

.text-blue-light {
  --text-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--text-opacity));
}

.text-blue-hover {
  --text-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--text-opacity));
}

.text-green {
  --text-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--text-opacity));
}

.text-green-light {
  --text-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--text-opacity));
}

.text-green-dark {
  --text-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--text-opacity));
}

.text-grey-10 {
  --text-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--text-opacity));
}

.text-grey-20 {
  --text-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--text-opacity));
}

.text-grey-30 {
  --text-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--text-opacity));
}

.text-grey-40 {
  --text-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--text-opacity));
}

.text-grey-50 {
  --text-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--text-opacity));
}

.text-grey-60 {
  --text-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--text-opacity));
}

.text-grey-70 {
  --text-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--text-opacity));
}

.text-grey-80 {
  --text-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--text-opacity));
}

.text-grey-90 {
  --text-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--text-opacity));
}

.text-grey {
  --text-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--text-opacity));
}

.text-grey-light {
  --text-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--text-opacity));
}

.text-grey-dark {
  --text-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--text-opacity));
}

.text-purple-visited {
  --text-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--text-opacity));
}

.text-purple-visited-light {
  --text-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--text-opacity));
}

.text-red {
  --text-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--text-opacity));
}

.text-yellow {
  --text-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--text-opacity));
}

.text-state-danger {
  color: #FF8274B3;
}

.text-state-warning {
  color: #F9C66BB3;
}

.text-state-success {
  color: #C7F6C9B3;
}

.text-state-primary {
  color: #B3F5FFB3;
}

.focus\:text-white:focus {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.focus\:text-black:focus {
  --text-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--text-opacity));
}

.focus\:text-black-backdrop:focus {
  color: #2c2c2c99;
}

.focus\:text-beige:focus {
  --text-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--text-opacity));
}

.focus\:text-beige-dark:focus {
  --text-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--text-opacity));
}

.focus\:text-blue:focus {
  --text-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--text-opacity));
}

.focus\:text-blue-dark:focus {
  --text-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--text-opacity));
}

.focus\:text-blue-light:focus {
  --text-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--text-opacity));
}

.focus\:text-blue-hover:focus {
  --text-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--text-opacity));
}

.focus\:text-green:focus {
  --text-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--text-opacity));
}

.focus\:text-green-light:focus {
  --text-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--text-opacity));
}

.focus\:text-green-dark:focus {
  --text-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--text-opacity));
}

.focus\:text-grey-10:focus {
  --text-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--text-opacity));
}

.focus\:text-grey-20:focus {
  --text-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--text-opacity));
}

.focus\:text-grey-30:focus {
  --text-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--text-opacity));
}

.focus\:text-grey-40:focus {
  --text-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--text-opacity));
}

.focus\:text-grey-50:focus {
  --text-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--text-opacity));
}

.focus\:text-grey-60:focus {
  --text-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--text-opacity));
}

.focus\:text-grey-70:focus {
  --text-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--text-opacity));
}

.focus\:text-grey-80:focus {
  --text-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--text-opacity));
}

.focus\:text-grey-90:focus {
  --text-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--text-opacity));
}

.focus\:text-grey:focus {
  --text-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--text-opacity));
}

.focus\:text-grey-light:focus {
  --text-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--text-opacity));
}

.focus\:text-grey-dark:focus {
  --text-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--text-opacity));
}

.focus\:text-purple-visited:focus {
  --text-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--text-opacity));
}

.focus\:text-purple-visited-light:focus {
  --text-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--text-opacity));
}

.focus\:text-red:focus {
  --text-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--text-opacity));
}

.focus\:text-yellow:focus {
  --text-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--text-opacity));
}

.focus\:text-state-danger:focus {
  color: #FF8274B3;
}

.focus\:text-state-warning:focus {
  color: #F9C66BB3;
}

.focus\:text-state-success:focus {
  color: #C7F6C9B3;
}

.focus\:text-state-primary:focus {
  color: #B3F5FFB3;
}

.visited\:text-white:visited {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.visited\:text-black:visited {
  --text-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--text-opacity));
}

.visited\:text-black-backdrop:visited {
  color: #2c2c2c99;
}

.visited\:text-beige:visited {
  --text-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--text-opacity));
}

.visited\:text-beige-dark:visited {
  --text-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--text-opacity));
}

.visited\:text-blue:visited {
  --text-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--text-opacity));
}

.visited\:text-blue-dark:visited {
  --text-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--text-opacity));
}

.visited\:text-blue-light:visited {
  --text-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--text-opacity));
}

.visited\:text-blue-hover:visited {
  --text-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--text-opacity));
}

.visited\:text-green:visited {
  --text-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--text-opacity));
}

.visited\:text-green-light:visited {
  --text-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--text-opacity));
}

.visited\:text-green-dark:visited {
  --text-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--text-opacity));
}

.visited\:text-grey-10:visited {
  --text-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--text-opacity));
}

.visited\:text-grey-20:visited {
  --text-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--text-opacity));
}

.visited\:text-grey-30:visited {
  --text-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--text-opacity));
}

.visited\:text-grey-40:visited {
  --text-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--text-opacity));
}

.visited\:text-grey-50:visited {
  --text-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--text-opacity));
}

.visited\:text-grey-60:visited {
  --text-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--text-opacity));
}

.visited\:text-grey-70:visited {
  --text-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--text-opacity));
}

.visited\:text-grey-80:visited {
  --text-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--text-opacity));
}

.visited\:text-grey-90:visited {
  --text-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--text-opacity));
}

.visited\:text-grey:visited {
  --text-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--text-opacity));
}

.visited\:text-grey-light:visited {
  --text-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--text-opacity));
}

.visited\:text-grey-dark:visited {
  --text-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--text-opacity));
}

.visited\:text-purple-visited:visited {
  --text-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--text-opacity));
}

.visited\:text-purple-visited-light:visited {
  --text-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--text-opacity));
}

.visited\:text-red:visited {
  --text-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--text-opacity));
}

.visited\:text-yellow:visited {
  --text-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--text-opacity));
}

.visited\:text-state-danger:visited {
  color: #FF8274B3;
}

.visited\:text-state-warning:visited {
  color: #F9C66BB3;
}

.visited\:text-state-success:visited {
  color: #C7F6C9B3;
}

.visited\:text-state-primary:visited {
  color: #B3F5FFB3;
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.hover\:text-black:hover {
  --text-opacity: 1;
  color: #2c2c2c;
  color: rgba(44, 44, 44, var(--text-opacity));
}

.hover\:text-black-backdrop:hover {
  color: #2c2c2c99;
}

.hover\:text-beige:hover {
  --text-opacity: 1;
  color: #f8f0dd;
  color: rgba(248, 240, 221, var(--text-opacity));
}

.hover\:text-beige-dark:hover {
  --text-opacity: 1;
  color: #d0bfae;
  color: rgba(208, 191, 174, var(--text-opacity));
}

.hover\:text-blue:hover {
  --text-opacity: 1;
  color: #6fe9ff;
  color: rgba(111, 233, 255, var(--text-opacity));
}

.hover\:text-blue-dark:hover {
  --text-opacity: 1;
  color: #2a2859;
  color: rgba(42, 40, 89, var(--text-opacity));
}

.hover\:text-blue-light:hover {
  --text-opacity: 1;
  color: #b3f5ff;
  color: rgba(179, 245, 255, var(--text-opacity));
}

.hover\:text-blue-hover:hover {
  --text-opacity: 1;
  color: #1f42aa;
  color: rgba(31, 66, 170, var(--text-opacity));
}

.hover\:text-green:hover {
  --text-opacity: 1;
  color: #43f8b6;
  color: rgba(67, 248, 182, var(--text-opacity));
}

.hover\:text-green-light:hover {
  --text-opacity: 1;
  color: #c7f6c9;
  color: rgba(199, 246, 201, var(--text-opacity));
}

.hover\:text-green-dark:hover {
  --text-opacity: 1;
  color: #034b45;
  color: rgba(3, 75, 69, var(--text-opacity));
}

.hover\:text-grey-10:hover {
  --text-opacity: 1;
  color: #e6e6e6;
  color: rgba(230, 230, 230, var(--text-opacity));
}

.hover\:text-grey-20:hover {
  --text-opacity: 1;
  color: #cccccc;
  color: rgba(204, 204, 204, var(--text-opacity));
}

.hover\:text-grey-30:hover {
  --text-opacity: 1;
  color: #b3b3b3;
  color: rgba(179, 179, 179, var(--text-opacity));
}

.hover\:text-grey-40:hover {
  --text-opacity: 1;
  color: #999999;
  color: rgba(153, 153, 153, var(--text-opacity));
}

.hover\:text-grey-50:hover {
  --text-opacity: 1;
  color: #808080;
  color: rgba(128, 128, 128, var(--text-opacity));
}

.hover\:text-grey-60:hover {
  --text-opacity: 1;
  color: #666666;
  color: rgba(102, 102, 102, var(--text-opacity));
}

.hover\:text-grey-70:hover {
  --text-opacity: 1;
  color: #4d4d4d;
  color: rgba(77, 77, 77, var(--text-opacity));
}

.hover\:text-grey-80:hover {
  --text-opacity: 1;
  color: #333333;
  color: rgba(51, 51, 51, var(--text-opacity));
}

.hover\:text-grey-90:hover {
  --text-opacity: 1;
  color: #1a1a1a;
  color: rgba(26, 26, 26, var(--text-opacity));
}

.hover\:text-grey:hover {
  --text-opacity: 1;
  color: #f2f2f2;
  color: rgba(242, 242, 242, var(--text-opacity));
}

.hover\:text-grey-light:hover {
  --text-opacity: 1;
  color: #f9f9f9;
  color: rgba(249, 249, 249, var(--text-opacity));
}

.hover\:text-grey-dark:hover {
  --text-opacity: 1;
  color: #e1e1e1;
  color: rgba(225, 225, 225, var(--text-opacity));
}

.hover\:text-purple-visited:hover {
  --text-opacity: 1;
  color: #8d50b1;
  color: rgba(141, 80, 177, var(--text-opacity));
}

.hover\:text-purple-visited-light:hover {
  --text-opacity: 1;
  color: #e0adff;
  color: rgba(224, 173, 255, var(--text-opacity));
}

.hover\:text-red:hover {
  --text-opacity: 1;
  color: #ff8274;
  color: rgba(255, 130, 116, var(--text-opacity));
}

.hover\:text-yellow:hover {
  --text-opacity: 1;
  color: #f9c66b;
  color: rgba(249, 198, 107, var(--text-opacity));
}

.hover\:text-state-danger:hover {
  color: #FF8274B3;
}

.hover\:text-state-warning:hover {
  color: #F9C66BB3;
}

.hover\:text-state-success:hover {
  color: #C7F6C9B3;
}

.hover\:text-state-primary:hover {
  color: #B3F5FFB3;
}

.text-opacity-0 {
  --text-opacity: 0;
}

.text-opacity-25 {
  --text-opacity: 0.25;
}

.text-opacity-50 {
  --text-opacity: 0.5;
}

.text-opacity-75 {
  --text-opacity: 0.75;
}

.text-opacity-100 {
  --text-opacity: 1;
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0;
}

.hover\:text-opacity-25:hover {
  --text-opacity: 0.25;
}

.hover\:text-opacity-50:hover {
  --text-opacity: 0.5;
}

.hover\:text-opacity-75:hover {
  --text-opacity: 0.75;
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1;
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0;
}

.focus\:text-opacity-25:focus {
  --text-opacity: 0.25;
}

.focus\:text-opacity-50:focus {
  --text-opacity: 0.5;
}

.focus\:text-opacity-75:focus {
  --text-opacity: 0.75;
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal;
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero;
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums;
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums;
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums;
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums;
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions;
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select-text {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.select-all {
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-1 {
  width: 5px;
}

.w-2 {
  width: 10px;
}

.w-3 {
  width: 15px;
}

.w-4 {
  width: 30px;
}

.w-5 {
  width: 50px;
}

.w-6 {
  width: 75px;
}

.w-7 {
  width: 100px;
}

.w-auto {
  width: auto;
}

.w-none {
  width: 0px;
}

.w-tiny {
  width: 10px;
}

.w-small {
  width: 20px;
}

.w-medium {
  width: 30px;
}

.w-large {
  width: 40px;
}

.w-huge {
  width: 60px;
}

.w-margin-label-height {
  width: 26px;
}

.w-nav-height {
  width: 80px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-widescreen-content-max-width {
  width: 1146px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.z-nav {
  z-index: 90;
}

.z-menu {
  z-index: 100;
}

.z-popup {
  z-index: 110;
}

.z-modalBackdrop {
  z-index: 120;
}

.z-modal {
  z-index: 130;
}

.gap-0 {
  grid-gap: 0px;
  gap: 0px;
}

.gap-1 {
  grid-gap: 5px;
  gap: 5px;
}

.gap-2 {
  grid-gap: 10px;
  gap: 10px;
}

.gap-3 {
  grid-gap: 15px;
  gap: 15px;
}

.gap-4 {
  grid-gap: 30px;
  gap: 30px;
}

.gap-5 {
  grid-gap: 50px;
  gap: 50px;
}

.gap-6 {
  grid-gap: 75px;
  gap: 75px;
}

.gap-7 {
  grid-gap: 100px;
  gap: 100px;
}

.gap-none {
  grid-gap: 0px;
  gap: 0px;
}

.gap-tiny {
  grid-gap: 10px;
  gap: 10px;
}

.gap-small {
  grid-gap: 20px;
  gap: 20px;
}

.gap-medium {
  grid-gap: 30px;
  gap: 30px;
}

.gap-large {
  grid-gap: 40px;
  gap: 40px;
}

.gap-huge {
  grid-gap: 60px;
  gap: 60px;
}

.gap-margin-label-height {
  grid-gap: 26px;
  gap: 26px;
}

.gap-nav-height {
  grid-gap: 80px;
  gap: 80px;
}

.gap {
  grid-gap: 32px;
  gap: 32px;
}

.col-gap-0 {
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
}

.col-gap-1 {
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.col-gap-2 {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.col-gap-3 {
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.col-gap-4 {
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.col-gap-5 {
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.col-gap-6 {
  grid-column-gap: 75px;
  -webkit-column-gap: 75px;
          column-gap: 75px;
}

.col-gap-7 {
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
}

.col-gap-none {
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
}

.col-gap-tiny {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.col-gap-small {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.col-gap-medium {
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.col-gap-large {
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

.col-gap-huge {
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}

.col-gap-margin-label-height {
  grid-column-gap: 26px;
  -webkit-column-gap: 26px;
          column-gap: 26px;
}

.col-gap-nav-height {
  grid-column-gap: 80px;
  -webkit-column-gap: 80px;
          column-gap: 80px;
}

.col-gap {
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
}

.gap-x-0 {
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
}

.gap-x-1 {
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.gap-x-2 {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.gap-x-3 {
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.gap-x-4 {
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.gap-x-5 {
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.gap-x-6 {
  grid-column-gap: 75px;
  -webkit-column-gap: 75px;
          column-gap: 75px;
}

.gap-x-7 {
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
}

.gap-x-none {
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
}

.gap-x-tiny {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.gap-x-small {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.gap-x-medium {
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.gap-x-large {
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

.gap-x-huge {
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}

.gap-x-margin-label-height {
  grid-column-gap: 26px;
  -webkit-column-gap: 26px;
          column-gap: 26px;
}

.gap-x-nav-height {
  grid-column-gap: 80px;
  -webkit-column-gap: 80px;
          column-gap: 80px;
}

.gap-x {
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
}

.row-gap-0 {
  grid-row-gap: 0px;
  row-gap: 0px;
}

.row-gap-1 {
  grid-row-gap: 5px;
  row-gap: 5px;
}

.row-gap-2 {
  grid-row-gap: 10px;
  row-gap: 10px;
}

.row-gap-3 {
  grid-row-gap: 15px;
  row-gap: 15px;
}

.row-gap-4 {
  grid-row-gap: 30px;
  row-gap: 30px;
}

.row-gap-5 {
  grid-row-gap: 50px;
  row-gap: 50px;
}

.row-gap-6 {
  grid-row-gap: 75px;
  row-gap: 75px;
}

.row-gap-7 {
  grid-row-gap: 100px;
  row-gap: 100px;
}

.row-gap-none {
  grid-row-gap: 0px;
  row-gap: 0px;
}

.row-gap-tiny {
  grid-row-gap: 10px;
  row-gap: 10px;
}

.row-gap-small {
  grid-row-gap: 20px;
  row-gap: 20px;
}

.row-gap-medium {
  grid-row-gap: 30px;
  row-gap: 30px;
}

.row-gap-large {
  grid-row-gap: 40px;
  row-gap: 40px;
}

.row-gap-huge {
  grid-row-gap: 60px;
  row-gap: 60px;
}

.row-gap-margin-label-height {
  grid-row-gap: 26px;
  row-gap: 26px;
}

.row-gap-nav-height {
  grid-row-gap: 80px;
  row-gap: 80px;
}

.row-gap {
  grid-row-gap: 32px;
  row-gap: 32px;
}

.gap-y-0 {
  grid-row-gap: 0px;
  row-gap: 0px;
}

.gap-y-1 {
  grid-row-gap: 5px;
  row-gap: 5px;
}

.gap-y-2 {
  grid-row-gap: 10px;
  row-gap: 10px;
}

.gap-y-3 {
  grid-row-gap: 15px;
  row-gap: 15px;
}

.gap-y-4 {
  grid-row-gap: 30px;
  row-gap: 30px;
}

.gap-y-5 {
  grid-row-gap: 50px;
  row-gap: 50px;
}

.gap-y-6 {
  grid-row-gap: 75px;
  row-gap: 75px;
}

.gap-y-7 {
  grid-row-gap: 100px;
  row-gap: 100px;
}

.gap-y-none {
  grid-row-gap: 0px;
  row-gap: 0px;
}

.gap-y-tiny {
  grid-row-gap: 10px;
  row-gap: 10px;
}

.gap-y-small {
  grid-row-gap: 20px;
  row-gap: 20px;
}

.gap-y-medium {
  grid-row-gap: 30px;
  row-gap: 30px;
}

.gap-y-large {
  grid-row-gap: 40px;
  row-gap: 40px;
}

.gap-y-huge {
  grid-row-gap: 60px;
  row-gap: 60px;
}

.gap-y-margin-label-height {
  grid-row-gap: 26px;
  row-gap: 26px;
}

.gap-y-nav-height {
  grid-row-gap: 80px;
  row-gap: 80px;
}

.gap-y {
  grid-row-gap: 32px;
  row-gap: 32px;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transform-none {
  -webkit-transform: none;
          transform: none;
}

.origin-center {
  -webkit-transform-origin: center;
          transform-origin: center;
}

.origin-top {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.origin-top-right {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.origin-right {
  -webkit-transform-origin: right;
          transform-origin: right;
}

.origin-bottom-right {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.origin-bottom {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.origin-bottom-left {
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.origin-left {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.origin-top-left {
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.scale-50 {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.scale-75 {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.scale-90 {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.scale-x-0 {
  --transform-scale-x: 0;
}

.scale-x-50 {
  --transform-scale-x: .5;
}

.scale-x-75 {
  --transform-scale-x: .75;
}

.scale-x-90 {
  --transform-scale-x: .9;
}

.scale-x-95 {
  --transform-scale-x: .95;
}

.scale-x-100 {
  --transform-scale-x: 1;
}

.scale-x-105 {
  --transform-scale-x: 1.05;
}

.scale-x-110 {
  --transform-scale-x: 1.1;
}

.scale-x-125 {
  --transform-scale-x: 1.25;
}

.scale-x-150 {
  --transform-scale-x: 1.5;
}

.scale-y-0 {
  --transform-scale-y: 0;
}

.scale-y-50 {
  --transform-scale-y: .5;
}

.scale-y-75 {
  --transform-scale-y: .75;
}

.scale-y-90 {
  --transform-scale-y: .9;
}

.scale-y-95 {
  --transform-scale-y: .95;
}

.scale-y-100 {
  --transform-scale-y: 1;
}

.scale-y-105 {
  --transform-scale-y: 1.05;
}

.scale-y-110 {
  --transform-scale-y: 1.1;
}

.scale-y-125 {
  --transform-scale-y: 1.25;
}

.scale-y-150 {
  --transform-scale-y: 1.5;
}

.hover\:scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.hover\:scale-50:hover {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.hover\:scale-75:hover {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.hover\:scale-90:hover {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.hover\:scale-95:hover {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.hover\:scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0;
}

.hover\:scale-x-50:hover {
  --transform-scale-x: .5;
}

.hover\:scale-x-75:hover {
  --transform-scale-x: .75;
}

.hover\:scale-x-90:hover {
  --transform-scale-x: .9;
}

.hover\:scale-x-95:hover {
  --transform-scale-x: .95;
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1;
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05;
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1;
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25;
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5;
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0;
}

.hover\:scale-y-50:hover {
  --transform-scale-y: .5;
}

.hover\:scale-y-75:hover {
  --transform-scale-y: .75;
}

.hover\:scale-y-90:hover {
  --transform-scale-y: .9;
}

.hover\:scale-y-95:hover {
  --transform-scale-y: .95;
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1;
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05;
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1;
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25;
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5;
}

.focus\:scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.focus\:scale-50:focus {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.focus\:scale-75:focus {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.focus\:scale-90:focus {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.focus\:scale-95:focus {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.focus\:scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0;
}

.focus\:scale-x-50:focus {
  --transform-scale-x: .5;
}

.focus\:scale-x-75:focus {
  --transform-scale-x: .75;
}

.focus\:scale-x-90:focus {
  --transform-scale-x: .9;
}

.focus\:scale-x-95:focus {
  --transform-scale-x: .95;
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1;
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05;
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1;
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25;
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5;
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0;
}

.focus\:scale-y-50:focus {
  --transform-scale-y: .5;
}

.focus\:scale-y-75:focus {
  --transform-scale-y: .75;
}

.focus\:scale-y-90:focus {
  --transform-scale-y: .9;
}

.focus\:scale-y-95:focus {
  --transform-scale-y: .95;
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1;
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05;
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1;
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25;
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5;
}

.rotate-0 {
  --transform-rotate: 0;
}

.rotate-1 {
  --transform-rotate: 1deg;
}

.rotate-2 {
  --transform-rotate: 2deg;
}

.rotate-3 {
  --transform-rotate: 3deg;
}

.rotate-6 {
  --transform-rotate: 6deg;
}

.rotate-12 {
  --transform-rotate: 12deg;
}

.rotate-45 {
  --transform-rotate: 45deg;
}

.rotate-90 {
  --transform-rotate: 90deg;
}

.rotate-180 {
  --transform-rotate: 180deg;
}

.-rotate-180 {
  --transform-rotate: -180deg;
}

.-rotate-90 {
  --transform-rotate: -90deg;
}

.-rotate-45 {
  --transform-rotate: -45deg;
}

.-rotate-12 {
  --transform-rotate: -12deg;
}

.-rotate-6 {
  --transform-rotate: -6deg;
}

.-rotate-3 {
  --transform-rotate: -3deg;
}

.-rotate-2 {
  --transform-rotate: -2deg;
}

.-rotate-1 {
  --transform-rotate: -1deg;
}

.hover\:rotate-0:hover {
  --transform-rotate: 0;
}

.hover\:rotate-1:hover {
  --transform-rotate: 1deg;
}

.hover\:rotate-2:hover {
  --transform-rotate: 2deg;
}

.hover\:rotate-3:hover {
  --transform-rotate: 3deg;
}

.hover\:rotate-6:hover {
  --transform-rotate: 6deg;
}

.hover\:rotate-12:hover {
  --transform-rotate: 12deg;
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg;
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg;
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg;
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg;
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg;
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg;
}

.hover\:-rotate-12:hover {
  --transform-rotate: -12deg;
}

.hover\:-rotate-6:hover {
  --transform-rotate: -6deg;
}

.hover\:-rotate-3:hover {
  --transform-rotate: -3deg;
}

.hover\:-rotate-2:hover {
  --transform-rotate: -2deg;
}

.hover\:-rotate-1:hover {
  --transform-rotate: -1deg;
}

.focus\:rotate-0:focus {
  --transform-rotate: 0;
}

.focus\:rotate-1:focus {
  --transform-rotate: 1deg;
}

.focus\:rotate-2:focus {
  --transform-rotate: 2deg;
}

.focus\:rotate-3:focus {
  --transform-rotate: 3deg;
}

.focus\:rotate-6:focus {
  --transform-rotate: 6deg;
}

.focus\:rotate-12:focus {
  --transform-rotate: 12deg;
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg;
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg;
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg;
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg;
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg;
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg;
}

.focus\:-rotate-12:focus {
  --transform-rotate: -12deg;
}

.focus\:-rotate-6:focus {
  --transform-rotate: -6deg;
}

.focus\:-rotate-3:focus {
  --transform-rotate: -3deg;
}

.focus\:-rotate-2:focus {
  --transform-rotate: -2deg;
}

.focus\:-rotate-1:focus {
  --transform-rotate: -1deg;
}

.translate-x-1 {
  --transform-translate-x: 5px;
}

.translate-x-2 {
  --transform-translate-x: 10px;
}

.translate-x-3 {
  --transform-translate-x: 15px;
}

.translate-x-4 {
  --transform-translate-x: 30px;
}

.translate-x-5 {
  --transform-translate-x: 50px;
}

.translate-x-6 {
  --transform-translate-x: 75px;
}

.translate-x-7 {
  --transform-translate-x: 100px;
}

.translate-x-none {
  --transform-translate-x: 0px;
}

.translate-x-tiny {
  --transform-translate-x: 10px;
}

.translate-x-small {
  --transform-translate-x: 20px;
}

.translate-x-medium {
  --transform-translate-x: 30px;
}

.translate-x-large {
  --transform-translate-x: 40px;
}

.translate-x-huge {
  --transform-translate-x: 60px;
}

.translate-x-margin-label-height {
  --transform-translate-x: 26px;
}

.translate-x-nav-height {
  --transform-translate-x: 80px;
}

.-translate-x-1 {
  --transform-translate-x: -5px;
}

.-translate-x-2 {
  --transform-translate-x: -10px;
}

.-translate-x-3 {
  --transform-translate-x: -15px;
}

.-translate-x-4 {
  --transform-translate-x: -30px;
}

.-translate-x-5 {
  --transform-translate-x: -50px;
}

.-translate-x-6 {
  --transform-translate-x: -75px;
}

.-translate-x-7 {
  --transform-translate-x: -100px;
}

.-translate-x-none {
  --transform-translate-x: 0px;
}

.-translate-x-tiny {
  --transform-translate-x: -10px;
}

.-translate-x-small {
  --transform-translate-x: -20px;
}

.-translate-x-medium {
  --transform-translate-x: -30px;
}

.-translate-x-large {
  --transform-translate-x: -40px;
}

.-translate-x-huge {
  --transform-translate-x: -60px;
}

.-translate-x-margin-label-height {
  --transform-translate-x: -26px;
}

.-translate-x-nav-height {
  --transform-translate-x: -80px;
}

.-translate-x-full {
  --transform-translate-x: -100%;
}

.-translate-x-1\/2 {
  --transform-translate-x: -50%;
}

.translate-x-1\/2 {
  --transform-translate-x: 50%;
}

.translate-x-full {
  --transform-translate-x: 100%;
}

.translate-y-1 {
  --transform-translate-y: 5px;
}

.translate-y-2 {
  --transform-translate-y: 10px;
}

.translate-y-3 {
  --transform-translate-y: 15px;
}

.translate-y-4 {
  --transform-translate-y: 30px;
}

.translate-y-5 {
  --transform-translate-y: 50px;
}

.translate-y-6 {
  --transform-translate-y: 75px;
}

.translate-y-7 {
  --transform-translate-y: 100px;
}

.translate-y-none {
  --transform-translate-y: 0px;
}

.translate-y-tiny {
  --transform-translate-y: 10px;
}

.translate-y-small {
  --transform-translate-y: 20px;
}

.translate-y-medium {
  --transform-translate-y: 30px;
}

.translate-y-large {
  --transform-translate-y: 40px;
}

.translate-y-huge {
  --transform-translate-y: 60px;
}

.translate-y-margin-label-height {
  --transform-translate-y: 26px;
}

.translate-y-nav-height {
  --transform-translate-y: 80px;
}

.-translate-y-1 {
  --transform-translate-y: -5px;
}

.-translate-y-2 {
  --transform-translate-y: -10px;
}

.-translate-y-3 {
  --transform-translate-y: -15px;
}

.-translate-y-4 {
  --transform-translate-y: -30px;
}

.-translate-y-5 {
  --transform-translate-y: -50px;
}

.-translate-y-6 {
  --transform-translate-y: -75px;
}

.-translate-y-7 {
  --transform-translate-y: -100px;
}

.-translate-y-none {
  --transform-translate-y: 0px;
}

.-translate-y-tiny {
  --transform-translate-y: -10px;
}

.-translate-y-small {
  --transform-translate-y: -20px;
}

.-translate-y-medium {
  --transform-translate-y: -30px;
}

.-translate-y-large {
  --transform-translate-y: -40px;
}

.-translate-y-huge {
  --transform-translate-y: -60px;
}

.-translate-y-margin-label-height {
  --transform-translate-y: -26px;
}

.-translate-y-nav-height {
  --transform-translate-y: -80px;
}

.-translate-y-full {
  --transform-translate-y: -100%;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%;
}

.translate-y-1\/2 {
  --transform-translate-y: 50%;
}

.translate-y-full {
  --transform-translate-y: 100%;
}

.hover\:translate-x-1:hover {
  --transform-translate-x: 5px;
}

.hover\:translate-x-2:hover {
  --transform-translate-x: 10px;
}

.hover\:translate-x-3:hover {
  --transform-translate-x: 15px;
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 30px;
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 50px;
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 75px;
}

.hover\:translate-x-7:hover {
  --transform-translate-x: 100px;
}

.hover\:translate-x-none:hover {
  --transform-translate-x: 0px;
}

.hover\:translate-x-tiny:hover {
  --transform-translate-x: 10px;
}

.hover\:translate-x-small:hover {
  --transform-translate-x: 20px;
}

.hover\:translate-x-medium:hover {
  --transform-translate-x: 30px;
}

.hover\:translate-x-large:hover {
  --transform-translate-x: 40px;
}

.hover\:translate-x-huge:hover {
  --transform-translate-x: 60px;
}

.hover\:translate-x-margin-label-height:hover {
  --transform-translate-x: 26px;
}

.hover\:translate-x-nav-height:hover {
  --transform-translate-x: 80px;
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -5px;
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -10px;
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -15px;
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -30px;
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -50px;
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -75px;
}

.hover\:-translate-x-7:hover {
  --transform-translate-x: -100px;
}

.hover\:-translate-x-none:hover {
  --transform-translate-x: 0px;
}

.hover\:-translate-x-tiny:hover {
  --transform-translate-x: -10px;
}

.hover\:-translate-x-small:hover {
  --transform-translate-x: -20px;
}

.hover\:-translate-x-medium:hover {
  --transform-translate-x: -30px;
}

.hover\:-translate-x-large:hover {
  --transform-translate-x: -40px;
}

.hover\:-translate-x-huge:hover {
  --transform-translate-x: -60px;
}

.hover\:-translate-x-margin-label-height:hover {
  --transform-translate-x: -26px;
}

.hover\:-translate-x-nav-height:hover {
  --transform-translate-x: -80px;
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100%;
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50%;
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100%;
}

.hover\:translate-y-1:hover {
  --transform-translate-y: 5px;
}

.hover\:translate-y-2:hover {
  --transform-translate-y: 10px;
}

.hover\:translate-y-3:hover {
  --transform-translate-y: 15px;
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 30px;
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 50px;
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 75px;
}

.hover\:translate-y-7:hover {
  --transform-translate-y: 100px;
}

.hover\:translate-y-none:hover {
  --transform-translate-y: 0px;
}

.hover\:translate-y-tiny:hover {
  --transform-translate-y: 10px;
}

.hover\:translate-y-small:hover {
  --transform-translate-y: 20px;
}

.hover\:translate-y-medium:hover {
  --transform-translate-y: 30px;
}

.hover\:translate-y-large:hover {
  --transform-translate-y: 40px;
}

.hover\:translate-y-huge:hover {
  --transform-translate-y: 60px;
}

.hover\:translate-y-margin-label-height:hover {
  --transform-translate-y: 26px;
}

.hover\:translate-y-nav-height:hover {
  --transform-translate-y: 80px;
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -5px;
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -10px;
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -15px;
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -30px;
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -50px;
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -75px;
}

.hover\:-translate-y-7:hover {
  --transform-translate-y: -100px;
}

.hover\:-translate-y-none:hover {
  --transform-translate-y: 0px;
}

.hover\:-translate-y-tiny:hover {
  --transform-translate-y: -10px;
}

.hover\:-translate-y-small:hover {
  --transform-translate-y: -20px;
}

.hover\:-translate-y-medium:hover {
  --transform-translate-y: -30px;
}

.hover\:-translate-y-large:hover {
  --transform-translate-y: -40px;
}

.hover\:-translate-y-huge:hover {
  --transform-translate-y: -60px;
}

.hover\:-translate-y-margin-label-height:hover {
  --transform-translate-y: -26px;
}

.hover\:-translate-y-nav-height:hover {
  --transform-translate-y: -80px;
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100%;
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50%;
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100%;
}

.focus\:translate-x-1:focus {
  --transform-translate-x: 5px;
}

.focus\:translate-x-2:focus {
  --transform-translate-x: 10px;
}

.focus\:translate-x-3:focus {
  --transform-translate-x: 15px;
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 30px;
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 50px;
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 75px;
}

.focus\:translate-x-7:focus {
  --transform-translate-x: 100px;
}

.focus\:translate-x-none:focus {
  --transform-translate-x: 0px;
}

.focus\:translate-x-tiny:focus {
  --transform-translate-x: 10px;
}

.focus\:translate-x-small:focus {
  --transform-translate-x: 20px;
}

.focus\:translate-x-medium:focus {
  --transform-translate-x: 30px;
}

.focus\:translate-x-large:focus {
  --transform-translate-x: 40px;
}

.focus\:translate-x-huge:focus {
  --transform-translate-x: 60px;
}

.focus\:translate-x-margin-label-height:focus {
  --transform-translate-x: 26px;
}

.focus\:translate-x-nav-height:focus {
  --transform-translate-x: 80px;
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -5px;
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -10px;
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -15px;
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -30px;
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -50px;
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -75px;
}

.focus\:-translate-x-7:focus {
  --transform-translate-x: -100px;
}

.focus\:-translate-x-none:focus {
  --transform-translate-x: 0px;
}

.focus\:-translate-x-tiny:focus {
  --transform-translate-x: -10px;
}

.focus\:-translate-x-small:focus {
  --transform-translate-x: -20px;
}

.focus\:-translate-x-medium:focus {
  --transform-translate-x: -30px;
}

.focus\:-translate-x-large:focus {
  --transform-translate-x: -40px;
}

.focus\:-translate-x-huge:focus {
  --transform-translate-x: -60px;
}

.focus\:-translate-x-margin-label-height:focus {
  --transform-translate-x: -26px;
}

.focus\:-translate-x-nav-height:focus {
  --transform-translate-x: -80px;
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100%;
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50%;
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100%;
}

.focus\:translate-y-1:focus {
  --transform-translate-y: 5px;
}

.focus\:translate-y-2:focus {
  --transform-translate-y: 10px;
}

.focus\:translate-y-3:focus {
  --transform-translate-y: 15px;
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 30px;
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 50px;
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 75px;
}

.focus\:translate-y-7:focus {
  --transform-translate-y: 100px;
}

.focus\:translate-y-none:focus {
  --transform-translate-y: 0px;
}

.focus\:translate-y-tiny:focus {
  --transform-translate-y: 10px;
}

.focus\:translate-y-small:focus {
  --transform-translate-y: 20px;
}

.focus\:translate-y-medium:focus {
  --transform-translate-y: 30px;
}

.focus\:translate-y-large:focus {
  --transform-translate-y: 40px;
}

.focus\:translate-y-huge:focus {
  --transform-translate-y: 60px;
}

.focus\:translate-y-margin-label-height:focus {
  --transform-translate-y: 26px;
}

.focus\:translate-y-nav-height:focus {
  --transform-translate-y: 80px;
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -5px;
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -10px;
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -15px;
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -30px;
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -50px;
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -75px;
}

.focus\:-translate-y-7:focus {
  --transform-translate-y: -100px;
}

.focus\:-translate-y-none:focus {
  --transform-translate-y: 0px;
}

.focus\:-translate-y-tiny:focus {
  --transform-translate-y: -10px;
}

.focus\:-translate-y-small:focus {
  --transform-translate-y: -20px;
}

.focus\:-translate-y-medium:focus {
  --transform-translate-y: -30px;
}

.focus\:-translate-y-large:focus {
  --transform-translate-y: -40px;
}

.focus\:-translate-y-huge:focus {
  --transform-translate-y: -60px;
}

.focus\:-translate-y-margin-label-height:focus {
  --transform-translate-y: -26px;
}

.focus\:-translate-y-nav-height:focus {
  --transform-translate-y: -80px;
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100%;
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50%;
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100%;
}

.skew-x-0 {
  --transform-skew-x: 0;
}

.skew-x-1 {
  --transform-skew-x: 1deg;
}

.skew-x-2 {
  --transform-skew-x: 2deg;
}

.skew-x-3 {
  --transform-skew-x: 3deg;
}

.skew-x-6 {
  --transform-skew-x: 6deg;
}

.skew-x-12 {
  --transform-skew-x: 12deg;
}

.-skew-x-12 {
  --transform-skew-x: -12deg;
}

.-skew-x-6 {
  --transform-skew-x: -6deg;
}

.-skew-x-3 {
  --transform-skew-x: -3deg;
}

.-skew-x-2 {
  --transform-skew-x: -2deg;
}

.-skew-x-1 {
  --transform-skew-x: -1deg;
}

.skew-y-0 {
  --transform-skew-y: 0;
}

.skew-y-1 {
  --transform-skew-y: 1deg;
}

.skew-y-2 {
  --transform-skew-y: 2deg;
}

.skew-y-3 {
  --transform-skew-y: 3deg;
}

.skew-y-6 {
  --transform-skew-y: 6deg;
}

.skew-y-12 {
  --transform-skew-y: 12deg;
}

.-skew-y-12 {
  --transform-skew-y: -12deg;
}

.-skew-y-6 {
  --transform-skew-y: -6deg;
}

.-skew-y-3 {
  --transform-skew-y: -3deg;
}

.-skew-y-2 {
  --transform-skew-y: -2deg;
}

.-skew-y-1 {
  --transform-skew-y: -1deg;
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0;
}

.hover\:skew-x-1:hover {
  --transform-skew-x: 1deg;
}

.hover\:skew-x-2:hover {
  --transform-skew-x: 2deg;
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg;
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg;
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg;
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg;
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg;
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg;
}

.hover\:-skew-x-2:hover {
  --transform-skew-x: -2deg;
}

.hover\:-skew-x-1:hover {
  --transform-skew-x: -1deg;
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0;
}

.hover\:skew-y-1:hover {
  --transform-skew-y: 1deg;
}

.hover\:skew-y-2:hover {
  --transform-skew-y: 2deg;
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg;
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg;
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg;
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg;
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg;
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg;
}

.hover\:-skew-y-2:hover {
  --transform-skew-y: -2deg;
}

.hover\:-skew-y-1:hover {
  --transform-skew-y: -1deg;
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0;
}

.focus\:skew-x-1:focus {
  --transform-skew-x: 1deg;
}

.focus\:skew-x-2:focus {
  --transform-skew-x: 2deg;
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg;
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg;
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg;
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg;
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg;
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg;
}

.focus\:-skew-x-2:focus {
  --transform-skew-x: -2deg;
}

.focus\:-skew-x-1:focus {
  --transform-skew-x: -1deg;
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0;
}

.focus\:skew-y-1:focus {
  --transform-skew-y: 1deg;
}

.focus\:skew-y-2:focus {
  --transform-skew-y: 2deg;
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg;
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg;
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg;
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg;
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg;
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg;
}

.focus\:-skew-y-2:focus {
  --transform-skew-y: -2deg;
}

.focus\:-skew-y-1:focus {
  --transform-skew-y: -1deg;
}

.transition-none {
  transition-property: none;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-shadow {
  transition-property: box-shadow;
}

.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.duration-700 {
  transition-duration: 700ms;
}

.duration-1000 {
  transition-duration: 1000ms;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-150 {
  transition-delay: 150ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-none {
  -webkit-animation: none;
          animation: none;
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

.animate-ping {
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-bounce {
  -webkit-animation: bounce 1s infinite;
          animation: bounce 1s infinite;
}

@media (max-width: 800px) {
  .mobile-only\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .mobile-only\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .mobile-only\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .mobile-only\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .mobile-only\:container {
      max-width: 1408px;
    }
  }

  .mobile-only\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .mobile-only\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .mobile-only\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .mobile-only\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .mobile-only\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .mobile-only\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .mobile-only\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .mobile-only\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .mobile-only\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .mobile-only\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .mobile-only\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .mobile-only\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .mobile-only\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .mobile-only\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .mobile-only\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .mobile-only\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .mobile-only\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .mobile-only\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .mobile-only\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .mobile-only\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .mobile-only\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .mobile-only\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .mobile-only\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .mobile-only\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .mobile-only\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .mobile-only\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .mobile-only\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .mobile-only\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .mobile-only\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .mobile-only\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .mobile-only\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .mobile-only\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .mobile-only\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .mobile-only\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .mobile-only\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .mobile-only\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .mobile-only\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .mobile-only\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .mobile-only\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .mobile-only\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .mobile-only\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .mobile-only\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .mobile-only\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .mobile-only\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .mobile-only\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .mobile-only\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .mobile-only\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .mobile-only\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .mobile-only\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .mobile-only\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .mobile-only\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .mobile-only\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .mobile-only\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .mobile-only\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .mobile-only\:bg-fixed {
    background-attachment: fixed;
  }

  .mobile-only\:bg-local {
    background-attachment: local;
  }

  .mobile-only\:bg-scroll {
    background-attachment: scroll;
  }

  .mobile-only\:bg-clip-border {
    background-clip: border-box;
  }

  .mobile-only\:bg-clip-padding {
    background-clip: padding-box;
  }

  .mobile-only\:bg-clip-content {
    background-clip: content-box;
  }

  .mobile-only\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .mobile-only\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .mobile-only\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .mobile-only\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .mobile-only\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .mobile-only\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .mobile-only\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .mobile-only\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .mobile-only\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .mobile-only\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .mobile-only\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .mobile-only\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .mobile-only\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .mobile-only\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .mobile-only\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .mobile-only\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .mobile-only\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .mobile-only\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .mobile-only\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .mobile-only\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .mobile-only\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .mobile-only\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .mobile-only\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .mobile-only\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .mobile-only\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .mobile-only\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .mobile-only\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .mobile-only\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .mobile-only\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .mobile-only\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .mobile-only\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .mobile-only\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .mobile-only\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .mobile-only\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .mobile-only\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .mobile-only\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .mobile-only\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .mobile-only\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .mobile-only\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .mobile-only\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .mobile-only\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .mobile-only\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .mobile-only\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .mobile-only\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .mobile-only\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .mobile-only\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .mobile-only\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .mobile-only\:bg-none {
    background-image: none;
  }

  .mobile-only\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .mobile-only\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .mobile-only\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .mobile-only\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .mobile-only\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .mobile-only\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .mobile-only\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .mobile-only\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .mobile-only\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .mobile-only\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .mobile-only\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .mobile-only\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .mobile-only\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .mobile-only\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .mobile-only\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .mobile-only\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .mobile-only\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .mobile-only\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .mobile-only\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .mobile-only\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .mobile-only\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .mobile-only\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .mobile-only\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .mobile-only\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .mobile-only\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .mobile-only\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .mobile-only\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .mobile-only\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .mobile-only\:to-red {
    --gradient-to-color: #ff8274;
  }

  .mobile-only\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .mobile-only\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .mobile-only\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .mobile-only\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .mobile-only\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .mobile-only\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .mobile-only\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .mobile-only\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .mobile-only\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .mobile-only\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .mobile-only\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .mobile-only\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .mobile-only\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .mobile-only\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .mobile-only\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .mobile-only\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .mobile-only\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .mobile-only\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .mobile-only\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .mobile-only\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .mobile-only\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .mobile-only\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .mobile-only\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .mobile-only\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .mobile-only\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .mobile-only\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .mobile-only\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .mobile-only\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .mobile-only\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .mobile-only\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .mobile-only\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .mobile-only\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .mobile-only\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .mobile-only\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .mobile-only\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .mobile-only\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .mobile-only\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .mobile-only\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .mobile-only\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .mobile-only\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .mobile-only\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .mobile-only\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .mobile-only\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .mobile-only\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .mobile-only\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .mobile-only\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .mobile-only\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .mobile-only\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .mobile-only\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .mobile-only\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .mobile-only\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .mobile-only\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .mobile-only\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .mobile-only\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .mobile-only\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .mobile-only\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .mobile-only\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .mobile-only\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .mobile-only\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .mobile-only\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .mobile-only\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .mobile-only\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .mobile-only\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .mobile-only\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .mobile-only\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .mobile-only\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .mobile-only\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .mobile-only\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .mobile-only\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .mobile-only\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .mobile-only\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .mobile-only\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .mobile-only\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .mobile-only\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .mobile-only\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .mobile-only\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .mobile-only\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .mobile-only\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .mobile-only\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .mobile-only\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .mobile-only\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .mobile-only\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .mobile-only\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .mobile-only\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .mobile-only\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .mobile-only\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .mobile-only\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .mobile-only\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .mobile-only\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .mobile-only\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .mobile-only\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .mobile-only\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .mobile-only\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .mobile-only\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .mobile-only\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .mobile-only\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .mobile-only\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .mobile-only\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .mobile-only\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .mobile-only\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .mobile-only\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .mobile-only\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .mobile-only\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .mobile-only\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .mobile-only\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .mobile-only\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .mobile-only\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .mobile-only\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .mobile-only\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .mobile-only\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .mobile-only\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .mobile-only\:bg-bottom {
    background-position: bottom;
  }

  .mobile-only\:bg-center {
    background-position: center;
  }

  .mobile-only\:bg-left {
    background-position: left;
  }

  .mobile-only\:bg-left-bottom {
    background-position: left bottom;
  }

  .mobile-only\:bg-left-top {
    background-position: left top;
  }

  .mobile-only\:bg-right {
    background-position: right;
  }

  .mobile-only\:bg-right-bottom {
    background-position: right bottom;
  }

  .mobile-only\:bg-right-top {
    background-position: right top;
  }

  .mobile-only\:bg-top {
    background-position: top;
  }

  .mobile-only\:bg-repeat {
    background-repeat: repeat;
  }

  .mobile-only\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .mobile-only\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .mobile-only\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .mobile-only\:bg-repeat-round {
    background-repeat: round;
  }

  .mobile-only\:bg-repeat-space {
    background-repeat: space;
  }

  .mobile-only\:bg-auto {
    background-size: auto;
  }

  .mobile-only\:bg-cover {
    background-size: cover;
  }

  .mobile-only\:bg-contain {
    background-size: contain;
  }

  .mobile-only\:border-collapse {
    border-collapse: collapse;
  }

  .mobile-only\:border-separate {
    border-collapse: separate;
  }

  .mobile-only\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .mobile-only\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .mobile-only\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .mobile-only\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .mobile-only\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .mobile-only\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .mobile-only\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .mobile-only\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .mobile-only\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .mobile-only\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .mobile-only\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .mobile-only\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .mobile-only\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .mobile-only\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .mobile-only\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .mobile-only\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .mobile-only\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .mobile-only\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .mobile-only\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .mobile-only\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .mobile-only\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .mobile-only\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .mobile-only\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .mobile-only\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .mobile-only\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .mobile-only\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .mobile-only\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .mobile-only\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .mobile-only\:border-state-danger {
    border-color: #FF8274B3;
  }

  .mobile-only\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .mobile-only\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .mobile-only\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .mobile-only\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .mobile-only\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .mobile-only\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .mobile-only\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .mobile-only\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .mobile-only\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .mobile-only\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .mobile-only\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .mobile-only\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .mobile-only\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .mobile-only\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .mobile-only\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .mobile-only\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .mobile-only\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .mobile-only\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .mobile-only\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .mobile-only\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .mobile-only\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .mobile-only\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .mobile-only\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .mobile-only\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .mobile-only\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .mobile-only\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .mobile-only\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .mobile-only\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .mobile-only\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .mobile-only\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .mobile-only\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .mobile-only\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .mobile-only\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .mobile-only\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .mobile-only\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .mobile-only\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .mobile-only\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .mobile-only\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .mobile-only\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .mobile-only\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .mobile-only\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .mobile-only\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .mobile-only\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .mobile-only\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .mobile-only\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .mobile-only\:border-opacity-0 {
    --border-opacity: 0;
  }

  .mobile-only\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .mobile-only\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .mobile-only\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .mobile-only\:border-opacity-100 {
    --border-opacity: 1;
  }

  .mobile-only\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .mobile-only\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .mobile-only\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .mobile-only\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .mobile-only\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .mobile-only\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .mobile-only\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .mobile-only\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .mobile-only\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .mobile-only\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .mobile-only\:rounded-none {
    border-radius: 0;
  }

  .mobile-only\:rounded-sm {
    border-radius: 0.125rem;
  }

  .mobile-only\:rounded {
    border-radius: 0.25rem;
  }

  .mobile-only\:rounded-md {
    border-radius: 0.375rem;
  }

  .mobile-only\:rounded-lg {
    border-radius: 0.5rem;
  }

  .mobile-only\:rounded-xl {
    border-radius: 0.75rem;
  }

  .mobile-only\:rounded-2xl {
    border-radius: 1rem;
  }

  .mobile-only\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .mobile-only\:rounded-full {
    border-radius: 9999px;
  }

  .mobile-only\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mobile-only\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mobile-only\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mobile-only\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mobile-only\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .mobile-only\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .mobile-only\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .mobile-only\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .mobile-only\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .mobile-only\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .mobile-only\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .mobile-only\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .mobile-only\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .mobile-only\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .mobile-only\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .mobile-only\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .mobile-only\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .mobile-only\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .mobile-only\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .mobile-only\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .mobile-only\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .mobile-only\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .mobile-only\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .mobile-only\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .mobile-only\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .mobile-only\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .mobile-only\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .mobile-only\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .mobile-only\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .mobile-only\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .mobile-only\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .mobile-only\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .mobile-only\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .mobile-only\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .mobile-only\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .mobile-only\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .mobile-only\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .mobile-only\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .mobile-only\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .mobile-only\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .mobile-only\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .mobile-only\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .mobile-only\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .mobile-only\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .mobile-only\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .mobile-only\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .mobile-only\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .mobile-only\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .mobile-only\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .mobile-only\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .mobile-only\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .mobile-only\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .mobile-only\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .mobile-only\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .mobile-only\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .mobile-only\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .mobile-only\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .mobile-only\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .mobile-only\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .mobile-only\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .mobile-only\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .mobile-only\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .mobile-only\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .mobile-only\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .mobile-only\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .mobile-only\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .mobile-only\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .mobile-only\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .mobile-only\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .mobile-only\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .mobile-only\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .mobile-only\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .mobile-only\:border-solid {
    border-style: solid;
  }

  .mobile-only\:border-dashed {
    border-style: dashed;
  }

  .mobile-only\:border-dotted {
    border-style: dotted;
  }

  .mobile-only\:border-double {
    border-style: double;
  }

  .mobile-only\:border-none {
    border-style: none;
  }

  .mobile-only\:border-0 {
    border-width: 0;
  }

  .mobile-only\:border-2 {
    border-width: 2px;
  }

  .mobile-only\:border-4 {
    border-width: 4px;
  }

  .mobile-only\:border-8 {
    border-width: 8px;
  }

  .mobile-only\:border {
    border-width: 1px;
  }

  .mobile-only\:border-t-0 {
    border-top-width: 0;
  }

  .mobile-only\:border-r-0 {
    border-right-width: 0;
  }

  .mobile-only\:border-b-0 {
    border-bottom-width: 0;
  }

  .mobile-only\:border-l-0 {
    border-left-width: 0;
  }

  .mobile-only\:border-t-2 {
    border-top-width: 2px;
  }

  .mobile-only\:border-r-2 {
    border-right-width: 2px;
  }

  .mobile-only\:border-b-2 {
    border-bottom-width: 2px;
  }

  .mobile-only\:border-l-2 {
    border-left-width: 2px;
  }

  .mobile-only\:border-t-4 {
    border-top-width: 4px;
  }

  .mobile-only\:border-r-4 {
    border-right-width: 4px;
  }

  .mobile-only\:border-b-4 {
    border-bottom-width: 4px;
  }

  .mobile-only\:border-l-4 {
    border-left-width: 4px;
  }

  .mobile-only\:border-t-8 {
    border-top-width: 8px;
  }

  .mobile-only\:border-r-8 {
    border-right-width: 8px;
  }

  .mobile-only\:border-b-8 {
    border-bottom-width: 8px;
  }

  .mobile-only\:border-l-8 {
    border-left-width: 8px;
  }

  .mobile-only\:border-t {
    border-top-width: 1px;
  }

  .mobile-only\:border-r {
    border-right-width: 1px;
  }

  .mobile-only\:border-b {
    border-bottom-width: 1px;
  }

  .mobile-only\:border-l {
    border-left-width: 1px;
  }

  .mobile-only\:hover\:border-0:hover {
    border-width: 0;
  }

  .mobile-only\:hover\:border-2:hover {
    border-width: 2px;
  }

  .mobile-only\:hover\:border-4:hover {
    border-width: 4px;
  }

  .mobile-only\:hover\:border-8:hover {
    border-width: 8px;
  }

  .mobile-only\:hover\:border:hover {
    border-width: 1px;
  }

  .mobile-only\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .mobile-only\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .mobile-only\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .mobile-only\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .mobile-only\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .mobile-only\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .mobile-only\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .mobile-only\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .mobile-only\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .mobile-only\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .mobile-only\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .mobile-only\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .mobile-only\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .mobile-only\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .mobile-only\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .mobile-only\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .mobile-only\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .mobile-only\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .mobile-only\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .mobile-only\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .mobile-only\:focus\:border-0:focus {
    border-width: 0;
  }

  .mobile-only\:focus\:border-2:focus {
    border-width: 2px;
  }

  .mobile-only\:focus\:border-4:focus {
    border-width: 4px;
  }

  .mobile-only\:focus\:border-8:focus {
    border-width: 8px;
  }

  .mobile-only\:focus\:border:focus {
    border-width: 1px;
  }

  .mobile-only\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .mobile-only\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .mobile-only\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .mobile-only\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .mobile-only\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .mobile-only\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .mobile-only\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .mobile-only\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .mobile-only\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .mobile-only\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .mobile-only\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .mobile-only\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .mobile-only\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .mobile-only\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .mobile-only\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .mobile-only\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .mobile-only\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .mobile-only\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .mobile-only\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .mobile-only\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .mobile-only\:box-border {
    box-sizing: border-box;
  }

  .mobile-only\:box-content {
    box-sizing: content-box;
  }

  .mobile-only\:cursor-auto {
    cursor: auto;
  }

  .mobile-only\:cursor-default {
    cursor: default;
  }

  .mobile-only\:cursor-pointer {
    cursor: pointer;
  }

  .mobile-only\:cursor-wait {
    cursor: wait;
  }

  .mobile-only\:cursor-text {
    cursor: text;
  }

  .mobile-only\:cursor-move {
    cursor: move;
  }

  .mobile-only\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .mobile-only\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .mobile-only\:hover\:cursor-default:hover {
    cursor: default;
  }

  .mobile-only\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .mobile-only\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .mobile-only\:hover\:cursor-text:hover {
    cursor: text;
  }

  .mobile-only\:hover\:cursor-move:hover {
    cursor: move;
  }

  .mobile-only\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .mobile-only\:block {
    display: block;
  }

  .mobile-only\:inline-block {
    display: inline-block;
  }

  .mobile-only\:inline {
    display: inline;
  }

  .mobile-only\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .mobile-only\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .mobile-only\:table {
    display: table;
  }

  .mobile-only\:table-caption {
    display: table-caption;
  }

  .mobile-only\:table-cell {
    display: table-cell;
  }

  .mobile-only\:table-column {
    display: table-column;
  }

  .mobile-only\:table-column-group {
    display: table-column-group;
  }

  .mobile-only\:table-footer-group {
    display: table-footer-group;
  }

  .mobile-only\:table-header-group {
    display: table-header-group;
  }

  .mobile-only\:table-row-group {
    display: table-row-group;
  }

  .mobile-only\:table-row {
    display: table-row;
  }

  .mobile-only\:flow-root {
    display: flow-root;
  }

  .mobile-only\:grid {
    display: grid;
  }

  .mobile-only\:inline-grid {
    display: inline-grid;
  }

  .mobile-only\:contents {
    display: contents;
  }

  .mobile-only\:hidden {
    display: none;
  }

  .mobile-only\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .mobile-only\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .mobile-only\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .mobile-only\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .mobile-only\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .mobile-only\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .mobile-only\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .mobile-only\:place-items-auto {
    place-items: auto;
  }

  .mobile-only\:place-items-start {
    place-items: start;
  }

  .mobile-only\:place-items-end {
    place-items: end;
  }

  .mobile-only\:place-items-center {
    place-items: center;
  }

  .mobile-only\:place-items-stretch {
    place-items: stretch;
  }

  .mobile-only\:place-content-center {
    place-content: center;
  }

  .mobile-only\:place-content-start {
    place-content: start;
  }

  .mobile-only\:place-content-end {
    place-content: end;
  }

  .mobile-only\:place-content-between {
    place-content: space-between;
  }

  .mobile-only\:place-content-around {
    place-content: space-around;
  }

  .mobile-only\:place-content-evenly {
    place-content: space-evenly;
  }

  .mobile-only\:place-content-stretch {
    place-content: stretch;
  }

  .mobile-only\:place-self-auto {
    place-self: auto;
  }

  .mobile-only\:place-self-start {
    place-self: start;
  }

  .mobile-only\:place-self-end {
    place-self: end;
  }

  .mobile-only\:place-self-center {
    place-self: center;
  }

  .mobile-only\:place-self-stretch {
    place-self: stretch;
  }

  .mobile-only\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .mobile-only\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .mobile-only\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .mobile-only\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .mobile-only\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .mobile-only\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .mobile-only\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .mobile-only\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .mobile-only\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .mobile-only\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .mobile-only\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .mobile-only\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .mobile-only\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .mobile-only\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .mobile-only\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .mobile-only\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .mobile-only\:justify-items-auto {
    justify-items: auto;
  }

  .mobile-only\:justify-items-start {
    justify-items: start;
  }

  .mobile-only\:justify-items-end {
    justify-items: end;
  }

  .mobile-only\:justify-items-center {
    justify-items: center;
  }

  .mobile-only\:justify-items-stretch {
    justify-items: stretch;
  }

  .mobile-only\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .mobile-only\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .mobile-only\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .mobile-only\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .mobile-only\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .mobile-only\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .mobile-only\:justify-self-auto {
    justify-self: auto;
  }

  .mobile-only\:justify-self-start {
    justify-self: start;
  }

  .mobile-only\:justify-self-end {
    justify-self: end;
  }

  .mobile-only\:justify-self-center {
    justify-self: center;
  }

  .mobile-only\:justify-self-stretch {
    justify-self: stretch;
  }

  .mobile-only\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .mobile-only\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .mobile-only\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .mobile-only\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .mobile-only\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .mobile-only\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .mobile-only\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .mobile-only\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .mobile-only\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .mobile-only\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .mobile-only\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .mobile-only\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .mobile-only\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .mobile-only\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .mobile-only\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .mobile-only\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .mobile-only\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .mobile-only\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .mobile-only\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .mobile-only\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .mobile-only\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .mobile-only\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .mobile-only\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .mobile-only\:float-right {
    float: right;
  }

  .mobile-only\:float-left {
    float: left;
  }

  .mobile-only\:float-none {
    float: none;
  }

  .mobile-only\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .mobile-only\:clear-left {
    clear: left;
  }

  .mobile-only\:clear-right {
    clear: right;
  }

  .mobile-only\:clear-both {
    clear: both;
  }

  .mobile-only\:clear-none {
    clear: none;
  }

  .mobile-only\:font-hairline {
    font-weight: 100;
  }

  .mobile-only\:font-thin {
    font-weight: 200;
  }

  .mobile-only\:font-light {
    font-weight: 300;
  }

  .mobile-only\:font-normal {
    font-weight: 400;
  }

  .mobile-only\:font-medium {
    font-weight: 500;
  }

  .mobile-only\:font-semibold {
    font-weight: 600;
  }

  .mobile-only\:font-bold {
    font-weight: 700;
  }

  .mobile-only\:font-extrabold {
    font-weight: 800;
  }

  .mobile-only\:font-black {
    font-weight: 900;
  }

  .mobile-only\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .mobile-only\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .mobile-only\:hover\:font-light:hover {
    font-weight: 300;
  }

  .mobile-only\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .mobile-only\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .mobile-only\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .mobile-only\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .mobile-only\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .mobile-only\:hover\:font-black:hover {
    font-weight: 900;
  }

  .mobile-only\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .mobile-only\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .mobile-only\:focus\:font-light:focus {
    font-weight: 300;
  }

  .mobile-only\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .mobile-only\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .mobile-only\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .mobile-only\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .mobile-only\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .mobile-only\:focus\:font-black:focus {
    font-weight: 900;
  }

  .mobile-only\:h-1 {
    height: 5px;
  }

  .mobile-only\:h-2 {
    height: 10px;
  }

  .mobile-only\:h-3 {
    height: 15px;
  }

  .mobile-only\:h-4 {
    height: 30px;
  }

  .mobile-only\:h-5 {
    height: 50px;
  }

  .mobile-only\:h-6 {
    height: 75px;
  }

  .mobile-only\:h-7 {
    height: 100px;
  }

  .mobile-only\:h-logo-height-small {
    height: 55px;
  }

  .mobile-only\:h-logo-height-large {
    height: 65px;
  }

  .mobile-only\:leading-3 {
    line-height: .75rem;
  }

  .mobile-only\:leading-4 {
    line-height: 1rem;
  }

  .mobile-only\:leading-5 {
    line-height: 1.25rem;
  }

  .mobile-only\:leading-6 {
    line-height: 1.5rem;
  }

  .mobile-only\:leading-7 {
    line-height: 1.75rem;
  }

  .mobile-only\:leading-8 {
    line-height: 2rem;
  }

  .mobile-only\:leading-9 {
    line-height: 2.25rem;
  }

  .mobile-only\:leading-10 {
    line-height: 2.5rem;
  }

  .mobile-only\:leading-none {
    line-height: 1;
  }

  .mobile-only\:leading-tight {
    line-height: 1.25;
  }

  .mobile-only\:leading-snug {
    line-height: 1.375;
  }

  .mobile-only\:leading-normal {
    line-height: 1.5;
  }

  .mobile-only\:leading-relaxed {
    line-height: 1.625;
  }

  .mobile-only\:leading-loose {
    line-height: 2;
  }

  .mobile-only\:list-inside {
    list-style-position: inside;
  }

  .mobile-only\:list-outside {
    list-style-position: outside;
  }

  .mobile-only\:list-none {
    list-style-type: none;
  }

  .mobile-only\:list-disc {
    list-style-type: disc;
  }

  .mobile-only\:list-decimal {
    list-style-type: decimal;
  }

  .mobile-only\:m-1 {
    margin: 5px;
  }

  .mobile-only\:m-2 {
    margin: 10px;
  }

  .mobile-only\:m-3 {
    margin: 15px;
  }

  .mobile-only\:m-4 {
    margin: 30px;
  }

  .mobile-only\:m-5 {
    margin: 50px;
  }

  .mobile-only\:m-6 {
    margin: 75px;
  }

  .mobile-only\:m-7 {
    margin: 100px;
  }

  .mobile-only\:m-auto {
    margin: auto;
  }

  .mobile-only\:m-none {
    margin: 0px;
  }

  .mobile-only\:m-tiny {
    margin: 10px;
  }

  .mobile-only\:m-small {
    margin: 20px;
  }

  .mobile-only\:m-medium {
    margin: 30px;
  }

  .mobile-only\:m-large {
    margin: 40px;
  }

  .mobile-only\:m-huge {
    margin: 60px;
  }

  .mobile-only\:m-margin-label-height {
    margin: 26px;
  }

  .mobile-only\:m-nav-height {
    margin: 80px;
  }

  .mobile-only\:-m-1 {
    margin: -5px;
  }

  .mobile-only\:-m-2 {
    margin: -10px;
  }

  .mobile-only\:-m-3 {
    margin: -15px;
  }

  .mobile-only\:-m-4 {
    margin: -30px;
  }

  .mobile-only\:-m-5 {
    margin: -50px;
  }

  .mobile-only\:-m-6 {
    margin: -75px;
  }

  .mobile-only\:-m-7 {
    margin: -100px;
  }

  .mobile-only\:-m-none {
    margin: 0px;
  }

  .mobile-only\:-m-tiny {
    margin: -10px;
  }

  .mobile-only\:-m-small {
    margin: -20px;
  }

  .mobile-only\:-m-medium {
    margin: -30px;
  }

  .mobile-only\:-m-large {
    margin: -40px;
  }

  .mobile-only\:-m-huge {
    margin: -60px;
  }

  .mobile-only\:-m-margin-label-height {
    margin: -26px;
  }

  .mobile-only\:-m-nav-height {
    margin: -80px;
  }

  .mobile-only\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:mt-1 {
    margin-top: 5px;
  }

  .mobile-only\:mr-1 {
    margin-right: 5px;
  }

  .mobile-only\:mb-1 {
    margin-bottom: 5px;
  }

  .mobile-only\:ml-1 {
    margin-left: 5px;
  }

  .mobile-only\:mt-2 {
    margin-top: 10px;
  }

  .mobile-only\:mr-2 {
    margin-right: 10px;
  }

  .mobile-only\:mb-2 {
    margin-bottom: 10px;
  }

  .mobile-only\:ml-2 {
    margin-left: 10px;
  }

  .mobile-only\:mt-3 {
    margin-top: 15px;
  }

  .mobile-only\:mr-3 {
    margin-right: 15px;
  }

  .mobile-only\:mb-3 {
    margin-bottom: 15px;
  }

  .mobile-only\:ml-3 {
    margin-left: 15px;
  }

  .mobile-only\:mt-4 {
    margin-top: 30px;
  }

  .mobile-only\:mr-4 {
    margin-right: 30px;
  }

  .mobile-only\:mb-4 {
    margin-bottom: 30px;
  }

  .mobile-only\:ml-4 {
    margin-left: 30px;
  }

  .mobile-only\:mt-5 {
    margin-top: 50px;
  }

  .mobile-only\:mr-5 {
    margin-right: 50px;
  }

  .mobile-only\:mb-5 {
    margin-bottom: 50px;
  }

  .mobile-only\:ml-5 {
    margin-left: 50px;
  }

  .mobile-only\:mt-6 {
    margin-top: 75px;
  }

  .mobile-only\:mr-6 {
    margin-right: 75px;
  }

  .mobile-only\:mb-6 {
    margin-bottom: 75px;
  }

  .mobile-only\:ml-6 {
    margin-left: 75px;
  }

  .mobile-only\:mt-7 {
    margin-top: 100px;
  }

  .mobile-only\:mr-7 {
    margin-right: 100px;
  }

  .mobile-only\:mb-7 {
    margin-bottom: 100px;
  }

  .mobile-only\:ml-7 {
    margin-left: 100px;
  }

  .mobile-only\:mt-auto {
    margin-top: auto;
  }

  .mobile-only\:mr-auto {
    margin-right: auto;
  }

  .mobile-only\:mb-auto {
    margin-bottom: auto;
  }

  .mobile-only\:ml-auto {
    margin-left: auto;
  }

  .mobile-only\:mt-none {
    margin-top: 0px;
  }

  .mobile-only\:mr-none {
    margin-right: 0px;
  }

  .mobile-only\:mb-none {
    margin-bottom: 0px;
  }

  .mobile-only\:ml-none {
    margin-left: 0px;
  }

  .mobile-only\:mt-tiny {
    margin-top: 10px;
  }

  .mobile-only\:mr-tiny {
    margin-right: 10px;
  }

  .mobile-only\:mb-tiny {
    margin-bottom: 10px;
  }

  .mobile-only\:ml-tiny {
    margin-left: 10px;
  }

  .mobile-only\:mt-small {
    margin-top: 20px;
  }

  .mobile-only\:mr-small {
    margin-right: 20px;
  }

  .mobile-only\:mb-small {
    margin-bottom: 20px;
  }

  .mobile-only\:ml-small {
    margin-left: 20px;
  }

  .mobile-only\:mt-medium {
    margin-top: 30px;
  }

  .mobile-only\:mr-medium {
    margin-right: 30px;
  }

  .mobile-only\:mb-medium {
    margin-bottom: 30px;
  }

  .mobile-only\:ml-medium {
    margin-left: 30px;
  }

  .mobile-only\:mt-large {
    margin-top: 40px;
  }

  .mobile-only\:mr-large {
    margin-right: 40px;
  }

  .mobile-only\:mb-large {
    margin-bottom: 40px;
  }

  .mobile-only\:ml-large {
    margin-left: 40px;
  }

  .mobile-only\:mt-huge {
    margin-top: 60px;
  }

  .mobile-only\:mr-huge {
    margin-right: 60px;
  }

  .mobile-only\:mb-huge {
    margin-bottom: 60px;
  }

  .mobile-only\:ml-huge {
    margin-left: 60px;
  }

  .mobile-only\:mt-margin-label-height {
    margin-top: 26px;
  }

  .mobile-only\:mr-margin-label-height {
    margin-right: 26px;
  }

  .mobile-only\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .mobile-only\:ml-margin-label-height {
    margin-left: 26px;
  }

  .mobile-only\:mt-nav-height {
    margin-top: 80px;
  }

  .mobile-only\:mr-nav-height {
    margin-right: 80px;
  }

  .mobile-only\:mb-nav-height {
    margin-bottom: 80px;
  }

  .mobile-only\:ml-nav-height {
    margin-left: 80px;
  }

  .mobile-only\:-mt-1 {
    margin-top: -5px;
  }

  .mobile-only\:-mr-1 {
    margin-right: -5px;
  }

  .mobile-only\:-mb-1 {
    margin-bottom: -5px;
  }

  .mobile-only\:-ml-1 {
    margin-left: -5px;
  }

  .mobile-only\:-mt-2 {
    margin-top: -10px;
  }

  .mobile-only\:-mr-2 {
    margin-right: -10px;
  }

  .mobile-only\:-mb-2 {
    margin-bottom: -10px;
  }

  .mobile-only\:-ml-2 {
    margin-left: -10px;
  }

  .mobile-only\:-mt-3 {
    margin-top: -15px;
  }

  .mobile-only\:-mr-3 {
    margin-right: -15px;
  }

  .mobile-only\:-mb-3 {
    margin-bottom: -15px;
  }

  .mobile-only\:-ml-3 {
    margin-left: -15px;
  }

  .mobile-only\:-mt-4 {
    margin-top: -30px;
  }

  .mobile-only\:-mr-4 {
    margin-right: -30px;
  }

  .mobile-only\:-mb-4 {
    margin-bottom: -30px;
  }

  .mobile-only\:-ml-4 {
    margin-left: -30px;
  }

  .mobile-only\:-mt-5 {
    margin-top: -50px;
  }

  .mobile-only\:-mr-5 {
    margin-right: -50px;
  }

  .mobile-only\:-mb-5 {
    margin-bottom: -50px;
  }

  .mobile-only\:-ml-5 {
    margin-left: -50px;
  }

  .mobile-only\:-mt-6 {
    margin-top: -75px;
  }

  .mobile-only\:-mr-6 {
    margin-right: -75px;
  }

  .mobile-only\:-mb-6 {
    margin-bottom: -75px;
  }

  .mobile-only\:-ml-6 {
    margin-left: -75px;
  }

  .mobile-only\:-mt-7 {
    margin-top: -100px;
  }

  .mobile-only\:-mr-7 {
    margin-right: -100px;
  }

  .mobile-only\:-mb-7 {
    margin-bottom: -100px;
  }

  .mobile-only\:-ml-7 {
    margin-left: -100px;
  }

  .mobile-only\:-mt-none {
    margin-top: 0px;
  }

  .mobile-only\:-mr-none {
    margin-right: 0px;
  }

  .mobile-only\:-mb-none {
    margin-bottom: 0px;
  }

  .mobile-only\:-ml-none {
    margin-left: 0px;
  }

  .mobile-only\:-mt-tiny {
    margin-top: -10px;
  }

  .mobile-only\:-mr-tiny {
    margin-right: -10px;
  }

  .mobile-only\:-mb-tiny {
    margin-bottom: -10px;
  }

  .mobile-only\:-ml-tiny {
    margin-left: -10px;
  }

  .mobile-only\:-mt-small {
    margin-top: -20px;
  }

  .mobile-only\:-mr-small {
    margin-right: -20px;
  }

  .mobile-only\:-mb-small {
    margin-bottom: -20px;
  }

  .mobile-only\:-ml-small {
    margin-left: -20px;
  }

  .mobile-only\:-mt-medium {
    margin-top: -30px;
  }

  .mobile-only\:-mr-medium {
    margin-right: -30px;
  }

  .mobile-only\:-mb-medium {
    margin-bottom: -30px;
  }

  .mobile-only\:-ml-medium {
    margin-left: -30px;
  }

  .mobile-only\:-mt-large {
    margin-top: -40px;
  }

  .mobile-only\:-mr-large {
    margin-right: -40px;
  }

  .mobile-only\:-mb-large {
    margin-bottom: -40px;
  }

  .mobile-only\:-ml-large {
    margin-left: -40px;
  }

  .mobile-only\:-mt-huge {
    margin-top: -60px;
  }

  .mobile-only\:-mr-huge {
    margin-right: -60px;
  }

  .mobile-only\:-mb-huge {
    margin-bottom: -60px;
  }

  .mobile-only\:-ml-huge {
    margin-left: -60px;
  }

  .mobile-only\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .mobile-only\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .mobile-only\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .mobile-only\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .mobile-only\:-mt-nav-height {
    margin-top: -80px;
  }

  .mobile-only\:-mr-nav-height {
    margin-right: -80px;
  }

  .mobile-only\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .mobile-only\:-ml-nav-height {
    margin-left: -80px;
  }

  .mobile-only\:hover\:m-1:hover {
    margin: 5px;
  }

  .mobile-only\:hover\:m-2:hover {
    margin: 10px;
  }

  .mobile-only\:hover\:m-3:hover {
    margin: 15px;
  }

  .mobile-only\:hover\:m-4:hover {
    margin: 30px;
  }

  .mobile-only\:hover\:m-5:hover {
    margin: 50px;
  }

  .mobile-only\:hover\:m-6:hover {
    margin: 75px;
  }

  .mobile-only\:hover\:m-7:hover {
    margin: 100px;
  }

  .mobile-only\:hover\:m-auto:hover {
    margin: auto;
  }

  .mobile-only\:hover\:m-none:hover {
    margin: 0px;
  }

  .mobile-only\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .mobile-only\:hover\:m-small:hover {
    margin: 20px;
  }

  .mobile-only\:hover\:m-medium:hover {
    margin: 30px;
  }

  .mobile-only\:hover\:m-large:hover {
    margin: 40px;
  }

  .mobile-only\:hover\:m-huge:hover {
    margin: 60px;
  }

  .mobile-only\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .mobile-only\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .mobile-only\:hover\:-m-1:hover {
    margin: -5px;
  }

  .mobile-only\:hover\:-m-2:hover {
    margin: -10px;
  }

  .mobile-only\:hover\:-m-3:hover {
    margin: -15px;
  }

  .mobile-only\:hover\:-m-4:hover {
    margin: -30px;
  }

  .mobile-only\:hover\:-m-5:hover {
    margin: -50px;
  }

  .mobile-only\:hover\:-m-6:hover {
    margin: -75px;
  }

  .mobile-only\:hover\:-m-7:hover {
    margin: -100px;
  }

  .mobile-only\:hover\:-m-none:hover {
    margin: 0px;
  }

  .mobile-only\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .mobile-only\:hover\:-m-small:hover {
    margin: -20px;
  }

  .mobile-only\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .mobile-only\:hover\:-m-large:hover {
    margin: -40px;
  }

  .mobile-only\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .mobile-only\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .mobile-only\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .mobile-only\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .mobile-only\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .mobile-only\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .mobile-only\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .mobile-only\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .mobile-only\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .mobile-only\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .mobile-only\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .mobile-only\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .mobile-only\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .mobile-only\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .mobile-only\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .mobile-only\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .mobile-only\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .mobile-only\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .mobile-only\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .mobile-only\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .mobile-only\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .mobile-only\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .mobile-only\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .mobile-only\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .mobile-only\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .mobile-only\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .mobile-only\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .mobile-only\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .mobile-only\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .mobile-only\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .mobile-only\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .mobile-only\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .mobile-only\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .mobile-only\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .mobile-only\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .mobile-only\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .mobile-only\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .mobile-only\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .mobile-only\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .mobile-only\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .mobile-only\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .mobile-only\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .mobile-only\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .mobile-only\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .mobile-only\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .mobile-only\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .mobile-only\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .mobile-only\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .mobile-only\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .mobile-only\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .mobile-only\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .mobile-only\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .mobile-only\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .mobile-only\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .mobile-only\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .mobile-only\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .mobile-only\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .mobile-only\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .mobile-only\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .mobile-only\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .mobile-only\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .mobile-only\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .mobile-only\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .mobile-only\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .mobile-only\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .mobile-only\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .mobile-only\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .mobile-only\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .mobile-only\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .mobile-only\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .mobile-only\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .mobile-only\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .mobile-only\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .mobile-only\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .mobile-only\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .mobile-only\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .mobile-only\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .mobile-only\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .mobile-only\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .mobile-only\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .mobile-only\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .mobile-only\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .mobile-only\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .mobile-only\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .mobile-only\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .mobile-only\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .mobile-only\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .mobile-only\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .mobile-only\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .mobile-only\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .mobile-only\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .mobile-only\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .mobile-only\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .mobile-only\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .mobile-only\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .mobile-only\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .mobile-only\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .mobile-only\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .mobile-only\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .mobile-only\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .mobile-only\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .mobile-only\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .mobile-only\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .mobile-only\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .mobile-only\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .mobile-only\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .mobile-only\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .mobile-only\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .mobile-only\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .mobile-only\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .mobile-only\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .mobile-only\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .mobile-only\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .mobile-only\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .mobile-only\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .mobile-only\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .mobile-only\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .mobile-only\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .mobile-only\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .mobile-only\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .mobile-only\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .mobile-only\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .mobile-only\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .mobile-only\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .mobile-only\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .mobile-only\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .mobile-only\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .mobile-only\:focus\:m-1:focus {
    margin: 5px;
  }

  .mobile-only\:focus\:m-2:focus {
    margin: 10px;
  }

  .mobile-only\:focus\:m-3:focus {
    margin: 15px;
  }

  .mobile-only\:focus\:m-4:focus {
    margin: 30px;
  }

  .mobile-only\:focus\:m-5:focus {
    margin: 50px;
  }

  .mobile-only\:focus\:m-6:focus {
    margin: 75px;
  }

  .mobile-only\:focus\:m-7:focus {
    margin: 100px;
  }

  .mobile-only\:focus\:m-auto:focus {
    margin: auto;
  }

  .mobile-only\:focus\:m-none:focus {
    margin: 0px;
  }

  .mobile-only\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .mobile-only\:focus\:m-small:focus {
    margin: 20px;
  }

  .mobile-only\:focus\:m-medium:focus {
    margin: 30px;
  }

  .mobile-only\:focus\:m-large:focus {
    margin: 40px;
  }

  .mobile-only\:focus\:m-huge:focus {
    margin: 60px;
  }

  .mobile-only\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .mobile-only\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .mobile-only\:focus\:-m-1:focus {
    margin: -5px;
  }

  .mobile-only\:focus\:-m-2:focus {
    margin: -10px;
  }

  .mobile-only\:focus\:-m-3:focus {
    margin: -15px;
  }

  .mobile-only\:focus\:-m-4:focus {
    margin: -30px;
  }

  .mobile-only\:focus\:-m-5:focus {
    margin: -50px;
  }

  .mobile-only\:focus\:-m-6:focus {
    margin: -75px;
  }

  .mobile-only\:focus\:-m-7:focus {
    margin: -100px;
  }

  .mobile-only\:focus\:-m-none:focus {
    margin: 0px;
  }

  .mobile-only\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .mobile-only\:focus\:-m-small:focus {
    margin: -20px;
  }

  .mobile-only\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .mobile-only\:focus\:-m-large:focus {
    margin: -40px;
  }

  .mobile-only\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .mobile-only\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .mobile-only\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .mobile-only\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .mobile-only\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .mobile-only\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .mobile-only\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .mobile-only\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .mobile-only\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .mobile-only\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .mobile-only\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .mobile-only\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .mobile-only\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .mobile-only\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .mobile-only\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .mobile-only\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .mobile-only\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .mobile-only\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .mobile-only\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .mobile-only\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .mobile-only\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .mobile-only\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .mobile-only\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .mobile-only\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .mobile-only\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .mobile-only\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .mobile-only\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .mobile-only\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .mobile-only\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .mobile-only\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .mobile-only\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .mobile-only\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .mobile-only\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .mobile-only\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .mobile-only\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .mobile-only\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .mobile-only\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .mobile-only\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .mobile-only\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .mobile-only\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .mobile-only\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .mobile-only\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .mobile-only\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .mobile-only\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .mobile-only\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .mobile-only\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .mobile-only\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .mobile-only\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .mobile-only\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .mobile-only\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .mobile-only\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .mobile-only\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .mobile-only\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .mobile-only\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .mobile-only\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .mobile-only\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .mobile-only\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .mobile-only\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .mobile-only\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .mobile-only\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .mobile-only\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .mobile-only\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .mobile-only\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .mobile-only\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .mobile-only\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .mobile-only\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .mobile-only\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .mobile-only\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .mobile-only\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .mobile-only\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .mobile-only\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .mobile-only\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .mobile-only\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .mobile-only\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .mobile-only\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .mobile-only\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .mobile-only\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .mobile-only\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .mobile-only\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .mobile-only\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .mobile-only\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .mobile-only\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .mobile-only\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .mobile-only\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .mobile-only\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .mobile-only\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .mobile-only\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .mobile-only\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .mobile-only\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .mobile-only\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .mobile-only\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .mobile-only\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .mobile-only\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .mobile-only\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .mobile-only\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .mobile-only\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .mobile-only\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .mobile-only\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .mobile-only\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .mobile-only\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .mobile-only\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .mobile-only\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .mobile-only\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .mobile-only\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .mobile-only\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .mobile-only\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .mobile-only\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .mobile-only\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .mobile-only\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .mobile-only\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .mobile-only\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .mobile-only\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .mobile-only\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .mobile-only\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .mobile-only\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .mobile-only\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .mobile-only\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .mobile-only\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .mobile-only\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .mobile-only\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .mobile-only\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .mobile-only\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .mobile-only\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .mobile-only\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .mobile-only\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .mobile-only\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .mobile-only\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .mobile-only\:first\:m-1:first-child {
    margin: 5px;
  }

  .mobile-only\:first\:m-2:first-child {
    margin: 10px;
  }

  .mobile-only\:first\:m-3:first-child {
    margin: 15px;
  }

  .mobile-only\:first\:m-4:first-child {
    margin: 30px;
  }

  .mobile-only\:first\:m-5:first-child {
    margin: 50px;
  }

  .mobile-only\:first\:m-6:first-child {
    margin: 75px;
  }

  .mobile-only\:first\:m-7:first-child {
    margin: 100px;
  }

  .mobile-only\:first\:m-auto:first-child {
    margin: auto;
  }

  .mobile-only\:first\:m-none:first-child {
    margin: 0px;
  }

  .mobile-only\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .mobile-only\:first\:m-small:first-child {
    margin: 20px;
  }

  .mobile-only\:first\:m-medium:first-child {
    margin: 30px;
  }

  .mobile-only\:first\:m-large:first-child {
    margin: 40px;
  }

  .mobile-only\:first\:m-huge:first-child {
    margin: 60px;
  }

  .mobile-only\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .mobile-only\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .mobile-only\:first\:-m-1:first-child {
    margin: -5px;
  }

  .mobile-only\:first\:-m-2:first-child {
    margin: -10px;
  }

  .mobile-only\:first\:-m-3:first-child {
    margin: -15px;
  }

  .mobile-only\:first\:-m-4:first-child {
    margin: -30px;
  }

  .mobile-only\:first\:-m-5:first-child {
    margin: -50px;
  }

  .mobile-only\:first\:-m-6:first-child {
    margin: -75px;
  }

  .mobile-only\:first\:-m-7:first-child {
    margin: -100px;
  }

  .mobile-only\:first\:-m-none:first-child {
    margin: 0px;
  }

  .mobile-only\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .mobile-only\:first\:-m-small:first-child {
    margin: -20px;
  }

  .mobile-only\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .mobile-only\:first\:-m-large:first-child {
    margin: -40px;
  }

  .mobile-only\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .mobile-only\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .mobile-only\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .mobile-only\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .mobile-only\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .mobile-only\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .mobile-only\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .mobile-only\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .mobile-only\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .mobile-only\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .mobile-only\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .mobile-only\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .mobile-only\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .mobile-only\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .mobile-only\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .mobile-only\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .mobile-only\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .mobile-only\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .mobile-only\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .mobile-only\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .mobile-only\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .mobile-only\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .mobile-only\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .mobile-only\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .mobile-only\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .mobile-only\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .mobile-only\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .mobile-only\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .mobile-only\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .mobile-only\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .mobile-only\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .mobile-only\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .mobile-only\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .mobile-only\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .mobile-only\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .mobile-only\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .mobile-only\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .mobile-only\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .mobile-only\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .mobile-only\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .mobile-only\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .mobile-only\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .mobile-only\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .mobile-only\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .mobile-only\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .mobile-only\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .mobile-only\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .mobile-only\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .mobile-only\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .mobile-only\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .mobile-only\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .mobile-only\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .mobile-only\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .mobile-only\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .mobile-only\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .mobile-only\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .mobile-only\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .mobile-only\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .mobile-only\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .mobile-only\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .mobile-only\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .mobile-only\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .mobile-only\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .mobile-only\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .mobile-only\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .mobile-only\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .mobile-only\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .mobile-only\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .mobile-only\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .mobile-only\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .mobile-only\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .mobile-only\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .mobile-only\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .mobile-only\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .mobile-only\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .mobile-only\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .mobile-only\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .mobile-only\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .mobile-only\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .mobile-only\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .mobile-only\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .mobile-only\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .mobile-only\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .mobile-only\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .mobile-only\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .mobile-only\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .mobile-only\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .mobile-only\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .mobile-only\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .mobile-only\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .mobile-only\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .mobile-only\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .mobile-only\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .mobile-only\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .mobile-only\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .mobile-only\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .mobile-only\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .mobile-only\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .mobile-only\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .mobile-only\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .mobile-only\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .mobile-only\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .mobile-only\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .mobile-only\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .mobile-only\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .mobile-only\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .mobile-only\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .mobile-only\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .mobile-only\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .mobile-only\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .mobile-only\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .mobile-only\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .mobile-only\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .mobile-only\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .mobile-only\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .mobile-only\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .mobile-only\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .mobile-only\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .mobile-only\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .mobile-only\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .mobile-only\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .mobile-only\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .mobile-only\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .mobile-only\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .mobile-only\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .mobile-only\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .mobile-only\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .mobile-only\:last\:m-1:last-child {
    margin: 5px;
  }

  .mobile-only\:last\:m-2:last-child {
    margin: 10px;
  }

  .mobile-only\:last\:m-3:last-child {
    margin: 15px;
  }

  .mobile-only\:last\:m-4:last-child {
    margin: 30px;
  }

  .mobile-only\:last\:m-5:last-child {
    margin: 50px;
  }

  .mobile-only\:last\:m-6:last-child {
    margin: 75px;
  }

  .mobile-only\:last\:m-7:last-child {
    margin: 100px;
  }

  .mobile-only\:last\:m-auto:last-child {
    margin: auto;
  }

  .mobile-only\:last\:m-none:last-child {
    margin: 0px;
  }

  .mobile-only\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .mobile-only\:last\:m-small:last-child {
    margin: 20px;
  }

  .mobile-only\:last\:m-medium:last-child {
    margin: 30px;
  }

  .mobile-only\:last\:m-large:last-child {
    margin: 40px;
  }

  .mobile-only\:last\:m-huge:last-child {
    margin: 60px;
  }

  .mobile-only\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .mobile-only\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .mobile-only\:last\:-m-1:last-child {
    margin: -5px;
  }

  .mobile-only\:last\:-m-2:last-child {
    margin: -10px;
  }

  .mobile-only\:last\:-m-3:last-child {
    margin: -15px;
  }

  .mobile-only\:last\:-m-4:last-child {
    margin: -30px;
  }

  .mobile-only\:last\:-m-5:last-child {
    margin: -50px;
  }

  .mobile-only\:last\:-m-6:last-child {
    margin: -75px;
  }

  .mobile-only\:last\:-m-7:last-child {
    margin: -100px;
  }

  .mobile-only\:last\:-m-none:last-child {
    margin: 0px;
  }

  .mobile-only\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .mobile-only\:last\:-m-small:last-child {
    margin: -20px;
  }

  .mobile-only\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .mobile-only\:last\:-m-large:last-child {
    margin: -40px;
  }

  .mobile-only\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .mobile-only\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .mobile-only\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .mobile-only\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .mobile-only\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .mobile-only\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .mobile-only\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .mobile-only\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .mobile-only\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .mobile-only\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .mobile-only\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .mobile-only\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .mobile-only\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .mobile-only\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .mobile-only\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .mobile-only\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .mobile-only\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .mobile-only\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .mobile-only\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .mobile-only\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .mobile-only\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .mobile-only\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .mobile-only\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .mobile-only\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .mobile-only\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .mobile-only\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .mobile-only\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .mobile-only\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .mobile-only\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .mobile-only\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .mobile-only\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .mobile-only\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .mobile-only\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .mobile-only\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .mobile-only\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .mobile-only\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .mobile-only\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .mobile-only\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .mobile-only\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .mobile-only\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .mobile-only\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .mobile-only\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .mobile-only\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .mobile-only\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .mobile-only\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .mobile-only\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .mobile-only\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .mobile-only\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .mobile-only\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .mobile-only\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .mobile-only\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .mobile-only\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .mobile-only\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .mobile-only\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .mobile-only\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .mobile-only\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .mobile-only\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .mobile-only\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .mobile-only\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .mobile-only\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .mobile-only\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .mobile-only\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .mobile-only\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .mobile-only\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .mobile-only\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .mobile-only\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .mobile-only\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .mobile-only\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .mobile-only\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .mobile-only\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .mobile-only\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .mobile-only\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .mobile-only\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .mobile-only\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .mobile-only\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .mobile-only\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .mobile-only\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .mobile-only\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .mobile-only\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .mobile-only\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .mobile-only\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .mobile-only\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .mobile-only\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .mobile-only\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .mobile-only\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .mobile-only\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .mobile-only\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .mobile-only\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .mobile-only\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .mobile-only\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .mobile-only\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .mobile-only\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .mobile-only\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .mobile-only\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .mobile-only\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .mobile-only\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .mobile-only\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .mobile-only\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .mobile-only\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .mobile-only\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .mobile-only\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .mobile-only\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .mobile-only\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .mobile-only\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .mobile-only\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .mobile-only\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .mobile-only\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .mobile-only\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .mobile-only\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .mobile-only\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .mobile-only\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .mobile-only\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .mobile-only\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .mobile-only\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .mobile-only\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .mobile-only\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .mobile-only\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .mobile-only\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .mobile-only\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .mobile-only\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .mobile-only\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .mobile-only\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .mobile-only\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .mobile-only\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .mobile-only\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .mobile-only\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .mobile-only\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .mobile-only\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .mobile-only\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .mobile-only\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .mobile-only\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .mobile-only\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .mobile-only\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .mobile-only\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .mobile-only\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .mobile-only\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .mobile-only\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .mobile-only\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .mobile-only\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .mobile-only\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .mobile-only\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .mobile-only\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .mobile-only\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .mobile-only\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .mobile-only\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .mobile-only\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .mobile-only\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .mobile-only\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .mobile-only\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .mobile-only\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .mobile-only\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .mobile-only\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .mobile-only\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .mobile-only\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .mobile-only\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .mobile-only\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .mobile-only\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .mobile-only\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .mobile-only\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .mobile-only\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .mobile-only\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .mobile-only\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .mobile-only\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .mobile-only\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .mobile-only\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .mobile-only\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .mobile-only\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .mobile-only\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .mobile-only\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .mobile-only\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .mobile-only\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .mobile-only\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .mobile-only\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .mobile-only\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .mobile-only\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .mobile-only\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .mobile-only\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .mobile-only\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .mobile-only\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .mobile-only\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .mobile-only\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .mobile-only\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .mobile-only\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .mobile-only\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .mobile-only\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .mobile-only\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .mobile-only\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .mobile-only\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .mobile-only\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .mobile-only\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .mobile-only\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .mobile-only\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .mobile-only\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .mobile-only\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .mobile-only\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .mobile-only\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .mobile-only\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .mobile-only\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .mobile-only\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .mobile-only\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .mobile-only\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .mobile-only\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .mobile-only\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .mobile-only\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .mobile-only\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .mobile-only\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .mobile-only\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .mobile-only\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .mobile-only\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .mobile-only\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .mobile-only\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .mobile-only\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .mobile-only\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .mobile-only\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .mobile-only\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .mobile-only\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .mobile-only\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .mobile-only\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .mobile-only\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .mobile-only\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .mobile-only\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .mobile-only\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .mobile-only\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .mobile-only\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .mobile-only\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .mobile-only\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .mobile-only\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .mobile-only\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .mobile-only\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .mobile-only\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .mobile-only\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .mobile-only\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .mobile-only\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .mobile-only\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .mobile-only\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .mobile-only\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .mobile-only\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .mobile-only\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .mobile-only\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .mobile-only\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .mobile-only\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .mobile-only\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .mobile-only\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .mobile-only\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .mobile-only\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .mobile-only\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .mobile-only\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .mobile-only\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .mobile-only\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .mobile-only\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .mobile-only\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .mobile-only\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .mobile-only\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .mobile-only\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .mobile-only\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .mobile-only\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .mobile-only\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .mobile-only\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .mobile-only\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .mobile-only\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .mobile-only\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .mobile-only\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .mobile-only\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .mobile-only\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .mobile-only\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .mobile-only\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .mobile-only\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .mobile-only\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .mobile-only\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .mobile-only\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .mobile-only\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .mobile-only\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .mobile-only\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .mobile-only\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .mobile-only\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .mobile-only\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .mobile-only\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .mobile-only\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .mobile-only\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .mobile-only\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .mobile-only\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .mobile-only\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .mobile-only\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .mobile-only\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .mobile-only\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .mobile-only\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .mobile-only\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .mobile-only\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .mobile-only\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .mobile-only\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .mobile-only\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .mobile-only\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .mobile-only\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .mobile-only\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .mobile-only\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .mobile-only\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .mobile-only\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .mobile-only\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .mobile-only\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .mobile-only\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .mobile-only\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .mobile-only\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .mobile-only\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .mobile-only\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .mobile-only\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .mobile-only\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .mobile-only\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .mobile-only\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .mobile-only\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .mobile-only\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .mobile-only\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .mobile-only\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .mobile-only\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .mobile-only\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-only\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mobile-only\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mobile-only\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mobile-only\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mobile-only\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mobile-only\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mobile-only\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mobile-only\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mobile-only\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mobile-only\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mobile-only\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-only\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-only\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-only\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-only\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-only\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-only\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-only\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mobile-only\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobile-only\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mobile-only\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mobile-only\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .mobile-only\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .mobile-only\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mobile-only\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mobile-only\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .mobile-only\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mobile-only\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .mobile-only\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-only\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mobile-only\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .mobile-only\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .mobile-only\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .mobile-only\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .mobile-only\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .mobile-only\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mobile-only\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mobile-only\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .mobile-only\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .mobile-only\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .mobile-only\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobile-only\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .mobile-only\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-only\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .mobile-only\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .mobile-only\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .mobile-only\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .mobile-only\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .mobile-only\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .mobile-only\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .mobile-only\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .mobile-only\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .mobile-only\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .mobile-only\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .mobile-only\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .mobile-only\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .mobile-only\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .mobile-only\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .mobile-only\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .mobile-only\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .mobile-only\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .mobile-only\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .mobile-only\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .mobile-only\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .mobile-only\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .mobile-only\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .mobile-only\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .mobile-only\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .mobile-only\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .mobile-only\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .mobile-only\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .mobile-only\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .mobile-only\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .mobile-only\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .mobile-only\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .mobile-only\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .mobile-only\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .mobile-only\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .mobile-only\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .mobile-only\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .mobile-only\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .mobile-only\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .mobile-only\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .mobile-only\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .mobile-only\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .mobile-only\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .mobile-only\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .mobile-only\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .mobile-only\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .mobile-only\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .mobile-only\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .mobile-only\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .mobile-only\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .mobile-only\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .mobile-only\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .mobile-only\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .mobile-only\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .mobile-only\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .mobile-only\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .mobile-only\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .mobile-only\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .mobile-only\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .mobile-only\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .mobile-only\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .mobile-only\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .mobile-only\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .mobile-only\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .mobile-only\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .mobile-only\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .mobile-only\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .mobile-only\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .mobile-only\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .mobile-only\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .mobile-only\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .mobile-only\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .mobile-only\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .mobile-only\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .mobile-only\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .mobile-only\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .mobile-only\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .mobile-only\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .mobile-only\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .mobile-only\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .mobile-only\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .mobile-only\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .mobile-only\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .mobile-only\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .mobile-only\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .mobile-only\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .mobile-only\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .mobile-only\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .mobile-only\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .mobile-only\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .mobile-only\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .mobile-only\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .mobile-only\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .mobile-only\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .mobile-only\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .mobile-only\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .mobile-only\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .mobile-only\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .mobile-only\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .mobile-only\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .mobile-only\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .mobile-only\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .mobile-only\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .mobile-only\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .mobile-only\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .mobile-only\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .mobile-only\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .mobile-only\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .mobile-only\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .mobile-only\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .mobile-only\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .mobile-only\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .mobile-only\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .mobile-only\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .mobile-only\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .mobile-only\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .mobile-only\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .mobile-only\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .mobile-only\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .mobile-only\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .mobile-only\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .mobile-only\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .mobile-only\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .mobile-only\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .mobile-only\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .mobile-only\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .mobile-only\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .mobile-only\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .mobile-only\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .mobile-only\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .mobile-only\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .mobile-only\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .mobile-only\:max-h-full {
    max-height: 100%;
  }

  .mobile-only\:max-h-screen {
    max-height: 100vh;
  }

  .mobile-only\:max-w-none {
    max-width: none;
  }

  .mobile-only\:max-w-xs {
    max-width: 20rem;
  }

  .mobile-only\:max-w-sm {
    max-width: 24rem;
  }

  .mobile-only\:max-w-md {
    max-width: 28rem;
  }

  .mobile-only\:max-w-lg {
    max-width: 32rem;
  }

  .mobile-only\:max-w-xl {
    max-width: 36rem;
  }

  .mobile-only\:max-w-2xl {
    max-width: 42rem;
  }

  .mobile-only\:max-w-3xl {
    max-width: 48rem;
  }

  .mobile-only\:max-w-4xl {
    max-width: 56rem;
  }

  .mobile-only\:max-w-5xl {
    max-width: 64rem;
  }

  .mobile-only\:max-w-6xl {
    max-width: 72rem;
  }

  .mobile-only\:max-w-full {
    max-width: 100%;
  }

  .mobile-only\:max-w-screen-tablet {
    max-width: 801px;
  }

  .mobile-only\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .mobile-only\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .mobile-only\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .mobile-only\:min-h-0 {
    min-height: 0;
  }

  .mobile-only\:min-h-full {
    min-height: 100%;
  }

  .mobile-only\:min-h-screen {
    min-height: 100vh;
  }

  .mobile-only\:min-w-0 {
    min-width: 0;
  }

  .mobile-only\:min-w-full {
    min-width: 100%;
  }

  .mobile-only\:object-contain {
    object-fit: contain;
  }

  .mobile-only\:object-cover {
    object-fit: cover;
  }

  .mobile-only\:object-fill {
    object-fit: fill;
  }

  .mobile-only\:object-none {
    object-fit: none;
  }

  .mobile-only\:object-scale-down {
    object-fit: scale-down;
  }

  .mobile-only\:object-bottom {
    object-position: bottom;
  }

  .mobile-only\:object-center {
    object-position: center;
  }

  .mobile-only\:object-left {
    object-position: left;
  }

  .mobile-only\:object-left-bottom {
    object-position: left bottom;
  }

  .mobile-only\:object-left-top {
    object-position: left top;
  }

  .mobile-only\:object-right {
    object-position: right;
  }

  .mobile-only\:object-right-bottom {
    object-position: right bottom;
  }

  .mobile-only\:object-right-top {
    object-position: right top;
  }

  .mobile-only\:object-top {
    object-position: top;
  }

  .mobile-only\:opacity-0 {
    opacity: 0;
  }

  .mobile-only\:opacity-25 {
    opacity: 0.25;
  }

  .mobile-only\:opacity-50 {
    opacity: 0.5;
  }

  .mobile-only\:opacity-75 {
    opacity: 0.75;
  }

  .mobile-only\:opacity-100 {
    opacity: 1;
  }

  .mobile-only\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .mobile-only\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .mobile-only\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .mobile-only\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .mobile-only\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .mobile-only\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .mobile-only\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .mobile-only\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .mobile-only\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .mobile-only\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .mobile-only\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .mobile-only\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .mobile-only\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .mobile-only\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .mobile-only\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .mobile-only\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .mobile-only\:overflow-auto {
    overflow: auto;
  }

  .mobile-only\:overflow-hidden {
    overflow: hidden;
  }

  .mobile-only\:overflow-visible {
    overflow: visible;
  }

  .mobile-only\:overflow-scroll {
    overflow: scroll;
  }

  .mobile-only\:overflow-x-auto {
    overflow-x: auto;
  }

  .mobile-only\:overflow-y-auto {
    overflow-y: auto;
  }

  .mobile-only\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .mobile-only\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .mobile-only\:overflow-x-visible {
    overflow-x: visible;
  }

  .mobile-only\:overflow-y-visible {
    overflow-y: visible;
  }

  .mobile-only\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .mobile-only\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .mobile-only\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .mobile-only\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .mobile-only\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .mobile-only\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .mobile-only\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .mobile-only\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .mobile-only\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .mobile-only\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .mobile-only\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .mobile-only\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .mobile-only\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .mobile-only\:p-1 {
    padding: 5px;
  }

  .mobile-only\:p-2 {
    padding: 10px;
  }

  .mobile-only\:p-3 {
    padding: 15px;
  }

  .mobile-only\:p-4 {
    padding: 30px;
  }

  .mobile-only\:p-5 {
    padding: 50px;
  }

  .mobile-only\:p-6 {
    padding: 75px;
  }

  .mobile-only\:p-7 {
    padding: 100px;
  }

  .mobile-only\:p-none {
    padding: 0px;
  }

  .mobile-only\:p-tiny {
    padding: 10px;
  }

  .mobile-only\:p-small {
    padding: 20px;
  }

  .mobile-only\:p-medium {
    padding: 30px;
  }

  .mobile-only\:p-large {
    padding: 40px;
  }

  .mobile-only\:p-huge {
    padding: 60px;
  }

  .mobile-only\:p-margin-label-height {
    padding: 26px;
  }

  .mobile-only\:p-nav-height {
    padding: 80px;
  }

  .mobile-only\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mobile-only\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-only\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mobile-only\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-only\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mobile-only\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .mobile-only\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .mobile-only\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .mobile-only\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-only\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-only\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-only\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-only\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-only\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-only\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-only\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-only\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-only\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-only\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .mobile-only\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .mobile-only\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-only\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-only\:pt-1 {
    padding-top: 5px;
  }

  .mobile-only\:pr-1 {
    padding-right: 5px;
  }

  .mobile-only\:pb-1 {
    padding-bottom: 5px;
  }

  .mobile-only\:pl-1 {
    padding-left: 5px;
  }

  .mobile-only\:pt-2 {
    padding-top: 10px;
  }

  .mobile-only\:pr-2 {
    padding-right: 10px;
  }

  .mobile-only\:pb-2 {
    padding-bottom: 10px;
  }

  .mobile-only\:pl-2 {
    padding-left: 10px;
  }

  .mobile-only\:pt-3 {
    padding-top: 15px;
  }

  .mobile-only\:pr-3 {
    padding-right: 15px;
  }

  .mobile-only\:pb-3 {
    padding-bottom: 15px;
  }

  .mobile-only\:pl-3 {
    padding-left: 15px;
  }

  .mobile-only\:pt-4 {
    padding-top: 30px;
  }

  .mobile-only\:pr-4 {
    padding-right: 30px;
  }

  .mobile-only\:pb-4 {
    padding-bottom: 30px;
  }

  .mobile-only\:pl-4 {
    padding-left: 30px;
  }

  .mobile-only\:pt-5 {
    padding-top: 50px;
  }

  .mobile-only\:pr-5 {
    padding-right: 50px;
  }

  .mobile-only\:pb-5 {
    padding-bottom: 50px;
  }

  .mobile-only\:pl-5 {
    padding-left: 50px;
  }

  .mobile-only\:pt-6 {
    padding-top: 75px;
  }

  .mobile-only\:pr-6 {
    padding-right: 75px;
  }

  .mobile-only\:pb-6 {
    padding-bottom: 75px;
  }

  .mobile-only\:pl-6 {
    padding-left: 75px;
  }

  .mobile-only\:pt-7 {
    padding-top: 100px;
  }

  .mobile-only\:pr-7 {
    padding-right: 100px;
  }

  .mobile-only\:pb-7 {
    padding-bottom: 100px;
  }

  .mobile-only\:pl-7 {
    padding-left: 100px;
  }

  .mobile-only\:pt-none {
    padding-top: 0px;
  }

  .mobile-only\:pr-none {
    padding-right: 0px;
  }

  .mobile-only\:pb-none {
    padding-bottom: 0px;
  }

  .mobile-only\:pl-none {
    padding-left: 0px;
  }

  .mobile-only\:pt-tiny {
    padding-top: 10px;
  }

  .mobile-only\:pr-tiny {
    padding-right: 10px;
  }

  .mobile-only\:pb-tiny {
    padding-bottom: 10px;
  }

  .mobile-only\:pl-tiny {
    padding-left: 10px;
  }

  .mobile-only\:pt-small {
    padding-top: 20px;
  }

  .mobile-only\:pr-small {
    padding-right: 20px;
  }

  .mobile-only\:pb-small {
    padding-bottom: 20px;
  }

  .mobile-only\:pl-small {
    padding-left: 20px;
  }

  .mobile-only\:pt-medium {
    padding-top: 30px;
  }

  .mobile-only\:pr-medium {
    padding-right: 30px;
  }

  .mobile-only\:pb-medium {
    padding-bottom: 30px;
  }

  .mobile-only\:pl-medium {
    padding-left: 30px;
  }

  .mobile-only\:pt-large {
    padding-top: 40px;
  }

  .mobile-only\:pr-large {
    padding-right: 40px;
  }

  .mobile-only\:pb-large {
    padding-bottom: 40px;
  }

  .mobile-only\:pl-large {
    padding-left: 40px;
  }

  .mobile-only\:pt-huge {
    padding-top: 60px;
  }

  .mobile-only\:pr-huge {
    padding-right: 60px;
  }

  .mobile-only\:pb-huge {
    padding-bottom: 60px;
  }

  .mobile-only\:pl-huge {
    padding-left: 60px;
  }

  .mobile-only\:pt-margin-label-height {
    padding-top: 26px;
  }

  .mobile-only\:pr-margin-label-height {
    padding-right: 26px;
  }

  .mobile-only\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .mobile-only\:pl-margin-label-height {
    padding-left: 26px;
  }

  .mobile-only\:pt-nav-height {
    padding-top: 80px;
  }

  .mobile-only\:pr-nav-height {
    padding-right: 80px;
  }

  .mobile-only\:pb-nav-height {
    padding-bottom: 80px;
  }

  .mobile-only\:pl-nav-height {
    padding-left: 80px;
  }

  .mobile-only\:hover\:p-1:hover {
    padding: 5px;
  }

  .mobile-only\:hover\:p-2:hover {
    padding: 10px;
  }

  .mobile-only\:hover\:p-3:hover {
    padding: 15px;
  }

  .mobile-only\:hover\:p-4:hover {
    padding: 30px;
  }

  .mobile-only\:hover\:p-5:hover {
    padding: 50px;
  }

  .mobile-only\:hover\:p-6:hover {
    padding: 75px;
  }

  .mobile-only\:hover\:p-7:hover {
    padding: 100px;
  }

  .mobile-only\:hover\:p-none:hover {
    padding: 0px;
  }

  .mobile-only\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .mobile-only\:hover\:p-small:hover {
    padding: 20px;
  }

  .mobile-only\:hover\:p-medium:hover {
    padding: 30px;
  }

  .mobile-only\:hover\:p-large:hover {
    padding: 40px;
  }

  .mobile-only\:hover\:p-huge:hover {
    padding: 60px;
  }

  .mobile-only\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .mobile-only\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .mobile-only\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mobile-only\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-only\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mobile-only\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-only\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mobile-only\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .mobile-only\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .mobile-only\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .mobile-only\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-only\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-only\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-only\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-only\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-only\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-only\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-only\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-only\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-only\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-only\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .mobile-only\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .mobile-only\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-only\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-only\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .mobile-only\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .mobile-only\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .mobile-only\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .mobile-only\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .mobile-only\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .mobile-only\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .mobile-only\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .mobile-only\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .mobile-only\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .mobile-only\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .mobile-only\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .mobile-only\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .mobile-only\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .mobile-only\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .mobile-only\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .mobile-only\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .mobile-only\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .mobile-only\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .mobile-only\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .mobile-only\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .mobile-only\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .mobile-only\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .mobile-only\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .mobile-only\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .mobile-only\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .mobile-only\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .mobile-only\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .mobile-only\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .mobile-only\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .mobile-only\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .mobile-only\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .mobile-only\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .mobile-only\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .mobile-only\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .mobile-only\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .mobile-only\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .mobile-only\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .mobile-only\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .mobile-only\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .mobile-only\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .mobile-only\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .mobile-only\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .mobile-only\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .mobile-only\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .mobile-only\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .mobile-only\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .mobile-only\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .mobile-only\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .mobile-only\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .mobile-only\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .mobile-only\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .mobile-only\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .mobile-only\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .mobile-only\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .mobile-only\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .mobile-only\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .mobile-only\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .mobile-only\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .mobile-only\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .mobile-only\:focus\:p-1:focus {
    padding: 5px;
  }

  .mobile-only\:focus\:p-2:focus {
    padding: 10px;
  }

  .mobile-only\:focus\:p-3:focus {
    padding: 15px;
  }

  .mobile-only\:focus\:p-4:focus {
    padding: 30px;
  }

  .mobile-only\:focus\:p-5:focus {
    padding: 50px;
  }

  .mobile-only\:focus\:p-6:focus {
    padding: 75px;
  }

  .mobile-only\:focus\:p-7:focus {
    padding: 100px;
  }

  .mobile-only\:focus\:p-none:focus {
    padding: 0px;
  }

  .mobile-only\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .mobile-only\:focus\:p-small:focus {
    padding: 20px;
  }

  .mobile-only\:focus\:p-medium:focus {
    padding: 30px;
  }

  .mobile-only\:focus\:p-large:focus {
    padding: 40px;
  }

  .mobile-only\:focus\:p-huge:focus {
    padding: 60px;
  }

  .mobile-only\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .mobile-only\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .mobile-only\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mobile-only\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-only\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mobile-only\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-only\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mobile-only\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .mobile-only\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .mobile-only\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .mobile-only\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-only\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-only\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-only\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-only\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-only\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-only\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-only\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-only\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-only\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-only\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .mobile-only\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .mobile-only\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-only\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-only\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .mobile-only\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .mobile-only\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .mobile-only\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .mobile-only\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .mobile-only\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .mobile-only\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .mobile-only\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .mobile-only\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .mobile-only\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .mobile-only\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .mobile-only\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .mobile-only\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .mobile-only\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .mobile-only\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .mobile-only\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .mobile-only\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .mobile-only\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .mobile-only\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .mobile-only\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .mobile-only\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .mobile-only\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .mobile-only\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .mobile-only\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .mobile-only\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .mobile-only\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .mobile-only\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .mobile-only\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .mobile-only\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .mobile-only\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .mobile-only\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .mobile-only\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .mobile-only\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .mobile-only\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .mobile-only\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .mobile-only\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .mobile-only\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .mobile-only\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .mobile-only\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .mobile-only\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .mobile-only\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .mobile-only\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .mobile-only\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .mobile-only\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .mobile-only\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .mobile-only\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .mobile-only\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .mobile-only\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .mobile-only\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .mobile-only\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .mobile-only\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .mobile-only\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .mobile-only\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .mobile-only\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .mobile-only\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .mobile-only\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .mobile-only\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .mobile-only\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .mobile-only\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .mobile-only\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .mobile-only\:first\:p-1:first-child {
    padding: 5px;
  }

  .mobile-only\:first\:p-2:first-child {
    padding: 10px;
  }

  .mobile-only\:first\:p-3:first-child {
    padding: 15px;
  }

  .mobile-only\:first\:p-4:first-child {
    padding: 30px;
  }

  .mobile-only\:first\:p-5:first-child {
    padding: 50px;
  }

  .mobile-only\:first\:p-6:first-child {
    padding: 75px;
  }

  .mobile-only\:first\:p-7:first-child {
    padding: 100px;
  }

  .mobile-only\:first\:p-none:first-child {
    padding: 0px;
  }

  .mobile-only\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .mobile-only\:first\:p-small:first-child {
    padding: 20px;
  }

  .mobile-only\:first\:p-medium:first-child {
    padding: 30px;
  }

  .mobile-only\:first\:p-large:first-child {
    padding: 40px;
  }

  .mobile-only\:first\:p-huge:first-child {
    padding: 60px;
  }

  .mobile-only\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .mobile-only\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .mobile-only\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mobile-only\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-only\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mobile-only\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-only\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mobile-only\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .mobile-only\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .mobile-only\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .mobile-only\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-only\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-only\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-only\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-only\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-only\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-only\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-only\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-only\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-only\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-only\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .mobile-only\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .mobile-only\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-only\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-only\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .mobile-only\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .mobile-only\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .mobile-only\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .mobile-only\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .mobile-only\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .mobile-only\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .mobile-only\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .mobile-only\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .mobile-only\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .mobile-only\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .mobile-only\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .mobile-only\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .mobile-only\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .mobile-only\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .mobile-only\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .mobile-only\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .mobile-only\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .mobile-only\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .mobile-only\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .mobile-only\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .mobile-only\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .mobile-only\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .mobile-only\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .mobile-only\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .mobile-only\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .mobile-only\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .mobile-only\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .mobile-only\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .mobile-only\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .mobile-only\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .mobile-only\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .mobile-only\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .mobile-only\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .mobile-only\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .mobile-only\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .mobile-only\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .mobile-only\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .mobile-only\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .mobile-only\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .mobile-only\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .mobile-only\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .mobile-only\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .mobile-only\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .mobile-only\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .mobile-only\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .mobile-only\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .mobile-only\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .mobile-only\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .mobile-only\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .mobile-only\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .mobile-only\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .mobile-only\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .mobile-only\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .mobile-only\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .mobile-only\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .mobile-only\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .mobile-only\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .mobile-only\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .mobile-only\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .mobile-only\:last\:p-1:last-child {
    padding: 5px;
  }

  .mobile-only\:last\:p-2:last-child {
    padding: 10px;
  }

  .mobile-only\:last\:p-3:last-child {
    padding: 15px;
  }

  .mobile-only\:last\:p-4:last-child {
    padding: 30px;
  }

  .mobile-only\:last\:p-5:last-child {
    padding: 50px;
  }

  .mobile-only\:last\:p-6:last-child {
    padding: 75px;
  }

  .mobile-only\:last\:p-7:last-child {
    padding: 100px;
  }

  .mobile-only\:last\:p-none:last-child {
    padding: 0px;
  }

  .mobile-only\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .mobile-only\:last\:p-small:last-child {
    padding: 20px;
  }

  .mobile-only\:last\:p-medium:last-child {
    padding: 30px;
  }

  .mobile-only\:last\:p-large:last-child {
    padding: 40px;
  }

  .mobile-only\:last\:p-huge:last-child {
    padding: 60px;
  }

  .mobile-only\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .mobile-only\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .mobile-only\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mobile-only\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-only\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mobile-only\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-only\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mobile-only\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .mobile-only\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .mobile-only\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .mobile-only\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mobile-only\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .mobile-only\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mobile-only\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-only\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-only\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-only\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-only\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-only\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile-only\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-only\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mobile-only\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-only\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .mobile-only\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mobile-only\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .mobile-only\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .mobile-only\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .mobile-only\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .mobile-only\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .mobile-only\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .mobile-only\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .mobile-only\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .mobile-only\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .mobile-only\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .mobile-only\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .mobile-only\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .mobile-only\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .mobile-only\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .mobile-only\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .mobile-only\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .mobile-only\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .mobile-only\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .mobile-only\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .mobile-only\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .mobile-only\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .mobile-only\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .mobile-only\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .mobile-only\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .mobile-only\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .mobile-only\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .mobile-only\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .mobile-only\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .mobile-only\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .mobile-only\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .mobile-only\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .mobile-only\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .mobile-only\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .mobile-only\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .mobile-only\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .mobile-only\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .mobile-only\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .mobile-only\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .mobile-only\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .mobile-only\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .mobile-only\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .mobile-only\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .mobile-only\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .mobile-only\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .mobile-only\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .mobile-only\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .mobile-only\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .mobile-only\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .mobile-only\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .mobile-only\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .mobile-only\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .mobile-only\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .mobile-only\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .mobile-only\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .mobile-only\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .mobile-only\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .mobile-only\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .mobile-only\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .mobile-only\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .mobile-only\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .mobile-only\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .mobile-only\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .mobile-only\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .mobile-only\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .mobile-only\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .mobile-only\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .mobile-only\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .mobile-only\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .mobile-only\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .mobile-only\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .mobile-only\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .mobile-only\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .mobile-only\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .mobile-only\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .mobile-only\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .mobile-only\:pointer-events-none {
    pointer-events: none;
  }

  .mobile-only\:pointer-events-auto {
    pointer-events: auto;
  }

  .mobile-only\:static {
    position: static;
  }

  .mobile-only\:fixed {
    position: fixed;
  }

  .mobile-only\:absolute {
    position: absolute;
  }

  .mobile-only\:relative {
    position: relative;
  }

  .mobile-only\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .mobile-only\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .mobile-only\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .mobile-only\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .mobile-only\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .mobile-only\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .mobile-only\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .mobile-only\:top-0 {
    top: 0;
  }

  .mobile-only\:right-0 {
    right: 0;
  }

  .mobile-only\:bottom-0 {
    bottom: 0;
  }

  .mobile-only\:left-0 {
    left: 0;
  }

  .mobile-only\:top-auto {
    top: auto;
  }

  .mobile-only\:right-auto {
    right: auto;
  }

  .mobile-only\:bottom-auto {
    bottom: auto;
  }

  .mobile-only\:left-auto {
    left: auto;
  }

  .mobile-only\:resize-none {
    resize: none;
  }

  .mobile-only\:resize-y {
    resize: vertical;
  }

  .mobile-only\:resize-x {
    resize: horizontal;
  }

  .mobile-only\:resize {
    resize: both;
  }

  .mobile-only\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .mobile-only\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .mobile-only\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .mobile-only\:shadow-none {
    box-shadow: none;
  }

  .mobile-only\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .mobile-only\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .mobile-only\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .mobile-only\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .mobile-only\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .mobile-only\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .mobile-only\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .mobile-only\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .mobile-only\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .mobile-only\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .mobile-only\:fill-current {
    fill: currentColor;
  }

  .mobile-only\:stroke-current {
    stroke: currentColor;
  }

  .mobile-only\:stroke-0 {
    stroke-width: 0;
  }

  .mobile-only\:stroke-1 {
    stroke-width: 1;
  }

  .mobile-only\:stroke-2 {
    stroke-width: 2;
  }

  .mobile-only\:table-auto {
    table-layout: auto;
  }

  .mobile-only\:table-fixed {
    table-layout: fixed;
  }

  .mobile-only\:text-left {
    text-align: left;
  }

  .mobile-only\:text-center {
    text-align: center;
  }

  .mobile-only\:text-right {
    text-align: right;
  }

  .mobile-only\:text-justify {
    text-align: justify;
  }

  .mobile-only\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .mobile-only\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .mobile-only\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .mobile-only\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .mobile-only\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .mobile-only\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .mobile-only\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .mobile-only\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .mobile-only\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .mobile-only\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .mobile-only\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .mobile-only\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .mobile-only\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .mobile-only\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .mobile-only\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .mobile-only\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .mobile-only\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .mobile-only\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .mobile-only\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .mobile-only\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .mobile-only\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .mobile-only\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .mobile-only\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .mobile-only\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .mobile-only\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .mobile-only\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .mobile-only\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .mobile-only\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .mobile-only\:text-state-danger {
    color: #FF8274B3;
  }

  .mobile-only\:text-state-warning {
    color: #F9C66BB3;
  }

  .mobile-only\:text-state-success {
    color: #C7F6C9B3;
  }

  .mobile-only\:text-state-primary {
    color: #B3F5FFB3;
  }

  .mobile-only\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .mobile-only\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .mobile-only\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .mobile-only\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .mobile-only\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .mobile-only\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .mobile-only\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .mobile-only\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .mobile-only\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .mobile-only\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .mobile-only\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .mobile-only\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .mobile-only\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .mobile-only\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .mobile-only\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .mobile-only\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .mobile-only\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .mobile-only\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .mobile-only\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .mobile-only\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .mobile-only\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .mobile-only\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .mobile-only\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .mobile-only\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .mobile-only\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .mobile-only\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .mobile-only\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .mobile-only\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .mobile-only\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .mobile-only\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .mobile-only\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .mobile-only\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .mobile-only\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .mobile-only\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .mobile-only\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .mobile-only\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .mobile-only\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .mobile-only\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .mobile-only\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .mobile-only\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .mobile-only\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .mobile-only\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .mobile-only\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .mobile-only\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .mobile-only\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .mobile-only\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .mobile-only\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .mobile-only\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .mobile-only\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .mobile-only\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .mobile-only\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .mobile-only\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .mobile-only\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .mobile-only\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .mobile-only\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .mobile-only\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .mobile-only\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .mobile-only\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .mobile-only\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .mobile-only\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .mobile-only\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .mobile-only\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .mobile-only\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .mobile-only\:text-opacity-0 {
    --text-opacity: 0;
  }

  .mobile-only\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .mobile-only\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .mobile-only\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .mobile-only\:text-opacity-100 {
    --text-opacity: 1;
  }

  .mobile-only\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .mobile-only\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .mobile-only\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .mobile-only\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .mobile-only\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .mobile-only\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .mobile-only\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .mobile-only\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .mobile-only\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .mobile-only\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .mobile-only\:italic {
    font-style: italic;
  }

  .mobile-only\:not-italic {
    font-style: normal;
  }

  .mobile-only\:uppercase {
    text-transform: uppercase;
  }

  .mobile-only\:lowercase {
    text-transform: lowercase;
  }

  .mobile-only\:capitalize {
    text-transform: capitalize;
  }

  .mobile-only\:normal-case {
    text-transform: none;
  }

  .mobile-only\:underline {
    text-decoration: underline;
  }

  .mobile-only\:line-through {
    text-decoration: line-through;
  }

  .mobile-only\:no-underline {
    text-decoration: none;
  }

  .mobile-only\:hover\:underline:hover {
    text-decoration: underline;
  }

  .mobile-only\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .mobile-only\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .mobile-only\:focus\:underline:focus {
    text-decoration: underline;
  }

  .mobile-only\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .mobile-only\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .mobile-only\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .mobile-only\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .mobile-only\:ordinal, .mobile-only\:slashed-zero, .mobile-only\:lining-nums, .mobile-only\:oldstyle-nums, .mobile-only\:proportional-nums, .mobile-only\:tabular-nums, .mobile-only\:diagonal-fractions, .mobile-only\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .mobile-only\:normal-nums {
    font-variant-numeric: normal;
  }

  .mobile-only\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .mobile-only\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .mobile-only\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .mobile-only\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .mobile-only\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .mobile-only\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .mobile-only\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .mobile-only\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .mobile-only\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .mobile-only\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .mobile-only\:tracking-normal {
    letter-spacing: 0;
  }

  .mobile-only\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .mobile-only\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .mobile-only\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .mobile-only\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .mobile-only\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .mobile-only\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .mobile-only\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .mobile-only\:align-baseline {
    vertical-align: baseline;
  }

  .mobile-only\:align-top {
    vertical-align: top;
  }

  .mobile-only\:align-middle {
    vertical-align: middle;
  }

  .mobile-only\:align-bottom {
    vertical-align: bottom;
  }

  .mobile-only\:align-text-top {
    vertical-align: text-top;
  }

  .mobile-only\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .mobile-only\:visible {
    visibility: visible;
  }

  .mobile-only\:invisible {
    visibility: hidden;
  }

  .mobile-only\:whitespace-normal {
    white-space: normal;
  }

  .mobile-only\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .mobile-only\:whitespace-pre {
    white-space: pre;
  }

  .mobile-only\:whitespace-pre-line {
    white-space: pre-line;
  }

  .mobile-only\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .mobile-only\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .mobile-only\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .mobile-only\:break-all {
    word-break: break-all;
  }

  .mobile-only\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mobile-only\:w-1 {
    width: 5px;
  }

  .mobile-only\:w-2 {
    width: 10px;
  }

  .mobile-only\:w-3 {
    width: 15px;
  }

  .mobile-only\:w-4 {
    width: 30px;
  }

  .mobile-only\:w-5 {
    width: 50px;
  }

  .mobile-only\:w-6 {
    width: 75px;
  }

  .mobile-only\:w-7 {
    width: 100px;
  }

  .mobile-only\:w-auto {
    width: auto;
  }

  .mobile-only\:w-none {
    width: 0px;
  }

  .mobile-only\:w-tiny {
    width: 10px;
  }

  .mobile-only\:w-small {
    width: 20px;
  }

  .mobile-only\:w-medium {
    width: 30px;
  }

  .mobile-only\:w-large {
    width: 40px;
  }

  .mobile-only\:w-huge {
    width: 60px;
  }

  .mobile-only\:w-margin-label-height {
    width: 26px;
  }

  .mobile-only\:w-nav-height {
    width: 80px;
  }

  .mobile-only\:w-1\/2 {
    width: 50%;
  }

  .mobile-only\:w-1\/3 {
    width: 33.333333%;
  }

  .mobile-only\:w-2\/3 {
    width: 66.666667%;
  }

  .mobile-only\:w-1\/4 {
    width: 25%;
  }

  .mobile-only\:w-2\/4 {
    width: 50%;
  }

  .mobile-only\:w-3\/4 {
    width: 75%;
  }

  .mobile-only\:w-1\/5 {
    width: 20%;
  }

  .mobile-only\:w-2\/5 {
    width: 40%;
  }

  .mobile-only\:w-3\/5 {
    width: 60%;
  }

  .mobile-only\:w-4\/5 {
    width: 80%;
  }

  .mobile-only\:w-1\/6 {
    width: 16.666667%;
  }

  .mobile-only\:w-2\/6 {
    width: 33.333333%;
  }

  .mobile-only\:w-3\/6 {
    width: 50%;
  }

  .mobile-only\:w-4\/6 {
    width: 66.666667%;
  }

  .mobile-only\:w-5\/6 {
    width: 83.333333%;
  }

  .mobile-only\:w-1\/12 {
    width: 8.333333%;
  }

  .mobile-only\:w-2\/12 {
    width: 16.666667%;
  }

  .mobile-only\:w-3\/12 {
    width: 25%;
  }

  .mobile-only\:w-4\/12 {
    width: 33.333333%;
  }

  .mobile-only\:w-5\/12 {
    width: 41.666667%;
  }

  .mobile-only\:w-6\/12 {
    width: 50%;
  }

  .mobile-only\:w-7\/12 {
    width: 58.333333%;
  }

  .mobile-only\:w-8\/12 {
    width: 66.666667%;
  }

  .mobile-only\:w-9\/12 {
    width: 75%;
  }

  .mobile-only\:w-10\/12 {
    width: 83.333333%;
  }

  .mobile-only\:w-11\/12 {
    width: 91.666667%;
  }

  .mobile-only\:w-full {
    width: 100%;
  }

  .mobile-only\:w-screen {
    width: 100vw;
  }

  .mobile-only\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .mobile-only\:z-0 {
    z-index: 0;
  }

  .mobile-only\:z-10 {
    z-index: 10;
  }

  .mobile-only\:z-20 {
    z-index: 20;
  }

  .mobile-only\:z-30 {
    z-index: 30;
  }

  .mobile-only\:z-40 {
    z-index: 40;
  }

  .mobile-only\:z-50 {
    z-index: 50;
  }

  .mobile-only\:z-auto {
    z-index: auto;
  }

  .mobile-only\:z-nav {
    z-index: 90;
  }

  .mobile-only\:z-menu {
    z-index: 100;
  }

  .mobile-only\:z-popup {
    z-index: 110;
  }

  .mobile-only\:z-modalBackdrop {
    z-index: 120;
  }

  .mobile-only\:z-modal {
    z-index: 130;
  }

  .mobile-only\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .mobile-only\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .mobile-only\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .mobile-only\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .mobile-only\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .mobile-only\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .mobile-only\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .mobile-only\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .mobile-only\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .mobile-only\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .mobile-only\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .mobile-only\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .mobile-only\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .mobile-only\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .mobile-only\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .mobile-only\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .mobile-only\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .mobile-only\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .mobile-only\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .mobile-only\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .mobile-only\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .mobile-only\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .mobile-only\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .mobile-only\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .mobile-only\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .mobile-only\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .mobile-only\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .mobile-only\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .mobile-only\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .mobile-only\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .mobile-only\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .mobile-only\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .mobile-only\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .mobile-only\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .mobile-only\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .mobile-only\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .mobile-only\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .mobile-only\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .mobile-only\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .mobile-only\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .mobile-only\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .mobile-only\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .mobile-only\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .mobile-only\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .mobile-only\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .mobile-only\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .mobile-only\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .mobile-only\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .mobile-only\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .mobile-only\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .mobile-only\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .mobile-only\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .mobile-only\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .mobile-only\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .mobile-only\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .mobile-only\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .mobile-only\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .mobile-only\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .mobile-only\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .mobile-only\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .mobile-only\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .mobile-only\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .mobile-only\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .mobile-only\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .mobile-only\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .mobile-only\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .mobile-only\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .mobile-only\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .mobile-only\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .mobile-only\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .mobile-only\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .mobile-only\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .mobile-only\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .mobile-only\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .mobile-only\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .mobile-only\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .mobile-only\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .mobile-only\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .mobile-only\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .mobile-only\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .mobile-only\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .mobile-only\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .mobile-only\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .mobile-only\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .mobile-only\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .mobile-only\:grid-flow-row {
    grid-auto-flow: row;
  }

  .mobile-only\:grid-flow-col {
    grid-auto-flow: column;
  }

  .mobile-only\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .mobile-only\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .mobile-only\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .mobile-only\:grid-cols-none {
    grid-template-columns: none;
  }

  .mobile-only\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .mobile-only\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .mobile-only\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .mobile-only\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .mobile-only\:col-auto {
    grid-column: auto;
  }

  .mobile-only\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .mobile-only\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .mobile-only\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .mobile-only\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .mobile-only\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .mobile-only\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .mobile-only\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .mobile-only\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .mobile-only\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .mobile-only\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .mobile-only\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .mobile-only\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .mobile-only\:col-span-full {
    grid-column: 1 / -1;
  }

  .mobile-only\:col-start-1 {
    grid-column-start: 1;
  }

  .mobile-only\:col-start-2 {
    grid-column-start: 2;
  }

  .mobile-only\:col-start-3 {
    grid-column-start: 3;
  }

  .mobile-only\:col-start-4 {
    grid-column-start: 4;
  }

  .mobile-only\:col-start-5 {
    grid-column-start: 5;
  }

  .mobile-only\:col-start-6 {
    grid-column-start: 6;
  }

  .mobile-only\:col-start-7 {
    grid-column-start: 7;
  }

  .mobile-only\:col-start-8 {
    grid-column-start: 8;
  }

  .mobile-only\:col-start-9 {
    grid-column-start: 9;
  }

  .mobile-only\:col-start-10 {
    grid-column-start: 10;
  }

  .mobile-only\:col-start-11 {
    grid-column-start: 11;
  }

  .mobile-only\:col-start-12 {
    grid-column-start: 12;
  }

  .mobile-only\:col-start-13 {
    grid-column-start: 13;
  }

  .mobile-only\:col-start-auto {
    grid-column-start: auto;
  }

  .mobile-only\:col-end-1 {
    grid-column-end: 1;
  }

  .mobile-only\:col-end-2 {
    grid-column-end: 2;
  }

  .mobile-only\:col-end-3 {
    grid-column-end: 3;
  }

  .mobile-only\:col-end-4 {
    grid-column-end: 4;
  }

  .mobile-only\:col-end-5 {
    grid-column-end: 5;
  }

  .mobile-only\:col-end-6 {
    grid-column-end: 6;
  }

  .mobile-only\:col-end-7 {
    grid-column-end: 7;
  }

  .mobile-only\:col-end-8 {
    grid-column-end: 8;
  }

  .mobile-only\:col-end-9 {
    grid-column-end: 9;
  }

  .mobile-only\:col-end-10 {
    grid-column-end: 10;
  }

  .mobile-only\:col-end-11 {
    grid-column-end: 11;
  }

  .mobile-only\:col-end-12 {
    grid-column-end: 12;
  }

  .mobile-only\:col-end-13 {
    grid-column-end: 13;
  }

  .mobile-only\:col-end-auto {
    grid-column-end: auto;
  }

  .mobile-only\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .mobile-only\:grid-rows-none {
    grid-template-rows: none;
  }

  .mobile-only\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .mobile-only\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .mobile-only\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .mobile-only\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .mobile-only\:row-auto {
    grid-row: auto;
  }

  .mobile-only\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .mobile-only\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .mobile-only\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .mobile-only\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .mobile-only\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .mobile-only\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .mobile-only\:row-span-full {
    grid-row: 1 / -1;
  }

  .mobile-only\:row-start-1 {
    grid-row-start: 1;
  }

  .mobile-only\:row-start-2 {
    grid-row-start: 2;
  }

  .mobile-only\:row-start-3 {
    grid-row-start: 3;
  }

  .mobile-only\:row-start-4 {
    grid-row-start: 4;
  }

  .mobile-only\:row-start-5 {
    grid-row-start: 5;
  }

  .mobile-only\:row-start-6 {
    grid-row-start: 6;
  }

  .mobile-only\:row-start-7 {
    grid-row-start: 7;
  }

  .mobile-only\:row-start-auto {
    grid-row-start: auto;
  }

  .mobile-only\:row-end-1 {
    grid-row-end: 1;
  }

  .mobile-only\:row-end-2 {
    grid-row-end: 2;
  }

  .mobile-only\:row-end-3 {
    grid-row-end: 3;
  }

  .mobile-only\:row-end-4 {
    grid-row-end: 4;
  }

  .mobile-only\:row-end-5 {
    grid-row-end: 5;
  }

  .mobile-only\:row-end-6 {
    grid-row-end: 6;
  }

  .mobile-only\:row-end-7 {
    grid-row-end: 7;
  }

  .mobile-only\:row-end-auto {
    grid-row-end: auto;
  }

  .mobile-only\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .mobile-only\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .mobile-only\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .mobile-only\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .mobile-only\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .mobile-only\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .mobile-only\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .mobile-only\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .mobile-only\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .mobile-only\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .mobile-only\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .mobile-only\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .mobile-only\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .mobile-only\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .mobile-only\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .mobile-only\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .mobile-only\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .mobile-only\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .mobile-only\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .mobile-only\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .mobile-only\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .mobile-only\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .mobile-only\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .mobile-only\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .mobile-only\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .mobile-only\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .mobile-only\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .mobile-only\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .mobile-only\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .mobile-only\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .mobile-only\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .mobile-only\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .mobile-only\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .mobile-only\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .mobile-only\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .mobile-only\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .mobile-only\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .mobile-only\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .mobile-only\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .mobile-only\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .mobile-only\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .mobile-only\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .mobile-only\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .mobile-only\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .mobile-only\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .mobile-only\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .mobile-only\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .mobile-only\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .mobile-only\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .mobile-only\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .mobile-only\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .mobile-only\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .mobile-only\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .mobile-only\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .mobile-only\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .mobile-only\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .mobile-only\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .mobile-only\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .mobile-only\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .mobile-only\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .mobile-only\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .mobile-only\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .mobile-only\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .mobile-only\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .mobile-only\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .mobile-only\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .mobile-only\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .mobile-only\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .mobile-only\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .mobile-only\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .mobile-only\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .mobile-only\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .mobile-only\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .mobile-only\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .mobile-only\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .mobile-only\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .mobile-only\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .mobile-only\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .mobile-only\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .mobile-only\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .mobile-only\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .mobile-only\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .mobile-only\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .mobile-only\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .mobile-only\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .mobile-only\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .mobile-only\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .mobile-only\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .mobile-only\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .mobile-only\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .mobile-only\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .mobile-only\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .mobile-only\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .mobile-only\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .mobile-only\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .mobile-only\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .mobile-only\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .mobile-only\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .mobile-only\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .mobile-only\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .mobile-only\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .mobile-only\:rotate-0 {
    --transform-rotate: 0;
  }

  .mobile-only\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .mobile-only\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .mobile-only\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .mobile-only\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .mobile-only\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .mobile-only\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .mobile-only\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .mobile-only\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .mobile-only\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .mobile-only\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .mobile-only\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .mobile-only\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .mobile-only\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .mobile-only\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .mobile-only\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .mobile-only\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .mobile-only\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .mobile-only\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .mobile-only\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .mobile-only\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .mobile-only\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .mobile-only\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .mobile-only\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .mobile-only\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .mobile-only\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .mobile-only\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .mobile-only\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .mobile-only\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .mobile-only\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .mobile-only\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .mobile-only\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .mobile-only\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .mobile-only\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .mobile-only\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .mobile-only\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .mobile-only\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .mobile-only\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .mobile-only\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .mobile-only\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .mobile-only\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .mobile-only\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .mobile-only\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .mobile-only\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .mobile-only\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .mobile-only\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .mobile-only\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .mobile-only\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .mobile-only\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .mobile-only\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .mobile-only\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .mobile-only\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .mobile-only\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .mobile-only\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .mobile-only\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .mobile-only\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .mobile-only\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .mobile-only\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .mobile-only\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .mobile-only\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .mobile-only\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .mobile-only\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .mobile-only\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .mobile-only\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .mobile-only\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .mobile-only\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .mobile-only\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .mobile-only\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .mobile-only\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .mobile-only\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .mobile-only\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .mobile-only\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .mobile-only\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .mobile-only\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .mobile-only\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .mobile-only\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .mobile-only\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .mobile-only\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .mobile-only\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .mobile-only\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .mobile-only\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .mobile-only\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .mobile-only\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .mobile-only\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .mobile-only\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .mobile-only\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .mobile-only\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .mobile-only\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .mobile-only\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .mobile-only\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .mobile-only\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .mobile-only\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .mobile-only\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .mobile-only\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .mobile-only\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .mobile-only\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .mobile-only\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .mobile-only\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .mobile-only\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .mobile-only\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .mobile-only\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .mobile-only\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .mobile-only\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .mobile-only\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .mobile-only\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .mobile-only\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .mobile-only\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .mobile-only\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .mobile-only\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .mobile-only\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .mobile-only\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .mobile-only\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .mobile-only\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .mobile-only\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .mobile-only\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .mobile-only\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .mobile-only\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .mobile-only\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .mobile-only\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .mobile-only\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .mobile-only\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .mobile-only\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .mobile-only\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .mobile-only\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .mobile-only\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .mobile-only\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .mobile-only\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .mobile-only\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .mobile-only\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .mobile-only\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .mobile-only\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .mobile-only\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .mobile-only\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .mobile-only\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .mobile-only\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .mobile-only\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .mobile-only\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .mobile-only\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .mobile-only\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .mobile-only\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .mobile-only\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .mobile-only\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .mobile-only\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .mobile-only\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .mobile-only\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .mobile-only\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .mobile-only\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .mobile-only\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .mobile-only\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .mobile-only\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .mobile-only\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .mobile-only\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .mobile-only\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .mobile-only\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .mobile-only\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .mobile-only\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .mobile-only\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .mobile-only\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .mobile-only\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .mobile-only\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .mobile-only\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .mobile-only\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .mobile-only\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .mobile-only\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .mobile-only\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .mobile-only\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .mobile-only\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .mobile-only\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .mobile-only\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .mobile-only\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .mobile-only\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .mobile-only\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .mobile-only\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .mobile-only\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .mobile-only\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .mobile-only\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .mobile-only\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .mobile-only\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .mobile-only\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .mobile-only\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .mobile-only\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .mobile-only\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .mobile-only\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .mobile-only\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .mobile-only\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .mobile-only\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .mobile-only\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .mobile-only\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .mobile-only\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .mobile-only\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .mobile-only\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .mobile-only\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .mobile-only\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .mobile-only\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .mobile-only\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .mobile-only\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .mobile-only\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .mobile-only\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .mobile-only\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .mobile-only\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .mobile-only\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .mobile-only\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .mobile-only\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .mobile-only\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .mobile-only\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .mobile-only\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .mobile-only\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .mobile-only\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .mobile-only\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .mobile-only\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .mobile-only\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .mobile-only\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .mobile-only\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .mobile-only\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .mobile-only\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .mobile-only\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .mobile-only\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .mobile-only\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .mobile-only\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .mobile-only\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .mobile-only\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .mobile-only\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .mobile-only\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .mobile-only\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .mobile-only\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .mobile-only\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .mobile-only\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .mobile-only\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .mobile-only\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .mobile-only\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .mobile-only\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .mobile-only\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .mobile-only\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .mobile-only\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .mobile-only\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .mobile-only\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .mobile-only\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .mobile-only\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .mobile-only\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .mobile-only\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .mobile-only\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .mobile-only\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .mobile-only\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .mobile-only\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .mobile-only\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .mobile-only\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .mobile-only\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .mobile-only\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .mobile-only\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .mobile-only\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .mobile-only\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .mobile-only\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .mobile-only\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .mobile-only\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .mobile-only\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .mobile-only\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .mobile-only\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .mobile-only\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .mobile-only\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .mobile-only\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .mobile-only\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .mobile-only\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .mobile-only\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .mobile-only\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .mobile-only\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .mobile-only\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .mobile-only\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .mobile-only\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .mobile-only\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .mobile-only\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .mobile-only\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .mobile-only\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .mobile-only\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .mobile-only\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .mobile-only\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .mobile-only\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .mobile-only\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .mobile-only\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .mobile-only\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .mobile-only\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .mobile-only\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .mobile-only\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .mobile-only\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .mobile-only\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .mobile-only\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .mobile-only\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .mobile-only\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .mobile-only\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .mobile-only\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .mobile-only\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .mobile-only\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .mobile-only\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .mobile-only\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .mobile-only\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .mobile-only\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .mobile-only\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .mobile-only\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .mobile-only\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .mobile-only\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .mobile-only\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .mobile-only\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .mobile-only\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .mobile-only\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .mobile-only\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .mobile-only\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .mobile-only\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .mobile-only\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .mobile-only\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .mobile-only\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .mobile-only\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .mobile-only\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .mobile-only\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .mobile-only\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .mobile-only\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .mobile-only\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .mobile-only\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .mobile-only\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .mobile-only\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .mobile-only\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .mobile-only\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .mobile-only\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .mobile-only\:transition-none {
    transition-property: none;
  }

  .mobile-only\:transition-all {
    transition-property: all;
  }

  .mobile-only\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .mobile-only\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .mobile-only\:transition-opacity {
    transition-property: opacity;
  }

  .mobile-only\:transition-shadow {
    transition-property: box-shadow;
  }

  .mobile-only\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .mobile-only\:ease-linear {
    transition-timing-function: linear;
  }

  .mobile-only\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .mobile-only\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .mobile-only\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mobile-only\:duration-75 {
    transition-duration: 75ms;
  }

  .mobile-only\:duration-100 {
    transition-duration: 100ms;
  }

  .mobile-only\:duration-150 {
    transition-duration: 150ms;
  }

  .mobile-only\:duration-200 {
    transition-duration: 200ms;
  }

  .mobile-only\:duration-300 {
    transition-duration: 300ms;
  }

  .mobile-only\:duration-500 {
    transition-duration: 500ms;
  }

  .mobile-only\:duration-700 {
    transition-duration: 700ms;
  }

  .mobile-only\:duration-1000 {
    transition-duration: 1000ms;
  }

  .mobile-only\:delay-75 {
    transition-delay: 75ms;
  }

  .mobile-only\:delay-100 {
    transition-delay: 100ms;
  }

  .mobile-only\:delay-150 {
    transition-delay: 150ms;
  }

  .mobile-only\:delay-200 {
    transition-delay: 200ms;
  }

  .mobile-only\:delay-300 {
    transition-delay: 300ms;
  }

  .mobile-only\:delay-500 {
    transition-delay: 500ms;
  }

  .mobile-only\:delay-700 {
    transition-delay: 700ms;
  }

  .mobile-only\:delay-1000 {
    transition-delay: 1000ms;
  }

  .mobile-only\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .mobile-only\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .mobile-only\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .mobile-only\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .mobile-only\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}

@media (min-width: 801px) {
  .tablet\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .tablet\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .tablet\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .tablet\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .tablet\:container {
      max-width: 1408px;
    }
  }

  .tablet\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .tablet\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .tablet\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .tablet\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .tablet\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .tablet\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .tablet\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .tablet\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .tablet\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .tablet\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .tablet\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .tablet\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .tablet\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .tablet\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .tablet\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .tablet\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .tablet\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .tablet\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .tablet\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .tablet\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .tablet\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .tablet\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .tablet\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .tablet\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .tablet\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .tablet\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .tablet\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .tablet\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .tablet\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .tablet\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .tablet\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .tablet\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .tablet\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .tablet\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .tablet\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .tablet\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .tablet\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .tablet\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .tablet\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .tablet\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .tablet\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .tablet\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .tablet\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .tablet\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .tablet\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .tablet\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .tablet\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .tablet\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .tablet\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .tablet\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .tablet\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .tablet\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .tablet\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .tablet\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .tablet\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .tablet\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .tablet\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .tablet\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .tablet\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .tablet\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .tablet\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .tablet\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .tablet\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .tablet\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .tablet\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .tablet\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .tablet\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .tablet\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .tablet\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .tablet\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .tablet\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .tablet\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .tablet\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .tablet\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .tablet\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .tablet\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .tablet\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .tablet\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .tablet\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .tablet\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .tablet\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .tablet\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .tablet\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .tablet\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .tablet\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .tablet\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .tablet\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .tablet\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .tablet\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .tablet\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .tablet\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .tablet\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .tablet\:bg-fixed {
    background-attachment: fixed;
  }

  .tablet\:bg-local {
    background-attachment: local;
  }

  .tablet\:bg-scroll {
    background-attachment: scroll;
  }

  .tablet\:bg-clip-border {
    background-clip: border-box;
  }

  .tablet\:bg-clip-padding {
    background-clip: padding-box;
  }

  .tablet\:bg-clip-content {
    background-clip: content-box;
  }

  .tablet\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .tablet\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .tablet\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .tablet\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .tablet\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .tablet\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .tablet\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .tablet\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .tablet\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .tablet\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .tablet\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .tablet\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .tablet\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .tablet\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .tablet\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .tablet\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .tablet\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .tablet\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .tablet\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .tablet\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .tablet\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .tablet\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .tablet\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .tablet\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .tablet\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .tablet\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .tablet\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .tablet\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .tablet\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .tablet\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .tablet\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .tablet\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .tablet\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .tablet\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .tablet\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .tablet\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .tablet\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .tablet\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .tablet\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .tablet\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .tablet\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .tablet\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .tablet\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .tablet\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .tablet\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .tablet\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .tablet\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .tablet\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .tablet\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .tablet\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .tablet\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .tablet\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .tablet\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .tablet\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .tablet\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .tablet\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .tablet\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .tablet\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .tablet\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .tablet\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .tablet\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .tablet\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .tablet\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .tablet\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .tablet\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .tablet\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .tablet\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .tablet\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .tablet\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .tablet\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .tablet\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .tablet\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .tablet\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .tablet\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .tablet\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .tablet\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .tablet\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .tablet\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .tablet\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .tablet\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .tablet\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .tablet\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .tablet\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .tablet\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .tablet\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .tablet\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .tablet\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .tablet\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .tablet\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .tablet\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .tablet\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .tablet\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .tablet\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .tablet\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .tablet\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .tablet\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .tablet\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .tablet\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .tablet\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .tablet\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .tablet\:bg-none {
    background-image: none;
  }

  .tablet\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .tablet\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .tablet\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .tablet\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .tablet\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .tablet\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .tablet\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .tablet\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .tablet\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .tablet\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .tablet\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .tablet\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .tablet\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .tablet\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .tablet\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .tablet\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .tablet\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .tablet\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .tablet\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .tablet\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .tablet\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .tablet\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .tablet\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .tablet\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .tablet\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .tablet\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .tablet\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .tablet\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .tablet\:to-red {
    --gradient-to-color: #ff8274;
  }

  .tablet\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .tablet\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .tablet\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .tablet\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .tablet\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .tablet\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .tablet\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .tablet\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .tablet\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .tablet\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .tablet\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .tablet\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .tablet\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .tablet\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .tablet\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .tablet\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .tablet\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .tablet\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .tablet\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .tablet\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .tablet\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .tablet\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .tablet\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .tablet\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .tablet\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .tablet\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .tablet\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .tablet\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .tablet\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .tablet\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .tablet\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .tablet\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .tablet\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .tablet\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .tablet\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .tablet\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .tablet\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .tablet\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .tablet\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .tablet\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .tablet\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .tablet\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .tablet\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .tablet\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .tablet\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .tablet\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .tablet\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .tablet\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .tablet\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .tablet\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .tablet\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .tablet\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .tablet\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .tablet\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .tablet\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .tablet\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .tablet\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .tablet\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .tablet\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .tablet\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .tablet\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .tablet\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .tablet\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .tablet\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .tablet\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .tablet\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .tablet\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .tablet\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .tablet\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .tablet\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .tablet\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .tablet\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .tablet\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .tablet\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .tablet\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .tablet\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .tablet\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .tablet\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .tablet\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .tablet\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .tablet\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .tablet\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .tablet\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .tablet\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .tablet\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .tablet\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .tablet\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .tablet\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .tablet\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .tablet\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .tablet\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .tablet\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .tablet\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .tablet\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .tablet\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .tablet\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .tablet\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .tablet\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .tablet\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .tablet\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .tablet\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .tablet\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .tablet\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .tablet\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .tablet\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .tablet\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .tablet\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .tablet\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .tablet\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .tablet\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .tablet\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .tablet\:bg-bottom {
    background-position: bottom;
  }

  .tablet\:bg-center {
    background-position: center;
  }

  .tablet\:bg-left {
    background-position: left;
  }

  .tablet\:bg-left-bottom {
    background-position: left bottom;
  }

  .tablet\:bg-left-top {
    background-position: left top;
  }

  .tablet\:bg-right {
    background-position: right;
  }

  .tablet\:bg-right-bottom {
    background-position: right bottom;
  }

  .tablet\:bg-right-top {
    background-position: right top;
  }

  .tablet\:bg-top {
    background-position: top;
  }

  .tablet\:bg-repeat {
    background-repeat: repeat;
  }

  .tablet\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .tablet\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .tablet\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .tablet\:bg-repeat-round {
    background-repeat: round;
  }

  .tablet\:bg-repeat-space {
    background-repeat: space;
  }

  .tablet\:bg-auto {
    background-size: auto;
  }

  .tablet\:bg-cover {
    background-size: cover;
  }

  .tablet\:bg-contain {
    background-size: contain;
  }

  .tablet\:border-collapse {
    border-collapse: collapse;
  }

  .tablet\:border-separate {
    border-collapse: separate;
  }

  .tablet\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .tablet\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .tablet\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .tablet\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .tablet\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .tablet\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .tablet\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .tablet\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .tablet\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .tablet\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .tablet\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .tablet\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .tablet\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .tablet\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .tablet\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .tablet\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .tablet\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .tablet\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .tablet\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .tablet\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .tablet\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .tablet\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .tablet\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .tablet\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .tablet\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .tablet\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .tablet\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .tablet\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .tablet\:border-state-danger {
    border-color: #FF8274B3;
  }

  .tablet\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .tablet\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .tablet\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .tablet\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .tablet\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .tablet\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .tablet\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .tablet\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .tablet\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .tablet\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .tablet\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .tablet\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .tablet\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .tablet\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .tablet\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .tablet\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .tablet\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .tablet\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .tablet\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .tablet\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .tablet\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .tablet\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .tablet\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .tablet\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .tablet\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .tablet\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .tablet\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .tablet\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .tablet\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .tablet\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .tablet\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .tablet\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .tablet\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .tablet\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .tablet\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .tablet\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .tablet\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .tablet\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .tablet\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .tablet\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .tablet\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .tablet\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .tablet\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .tablet\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .tablet\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .tablet\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .tablet\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .tablet\:border-opacity-0 {
    --border-opacity: 0;
  }

  .tablet\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .tablet\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .tablet\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .tablet\:border-opacity-100 {
    --border-opacity: 1;
  }

  .tablet\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .tablet\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .tablet\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .tablet\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .tablet\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .tablet\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .tablet\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .tablet\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .tablet\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .tablet\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .tablet\:rounded-none {
    border-radius: 0;
  }

  .tablet\:rounded-sm {
    border-radius: 0.125rem;
  }

  .tablet\:rounded {
    border-radius: 0.25rem;
  }

  .tablet\:rounded-md {
    border-radius: 0.375rem;
  }

  .tablet\:rounded-lg {
    border-radius: 0.5rem;
  }

  .tablet\:rounded-xl {
    border-radius: 0.75rem;
  }

  .tablet\:rounded-2xl {
    border-radius: 1rem;
  }

  .tablet\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .tablet\:rounded-full {
    border-radius: 9999px;
  }

  .tablet\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .tablet\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tablet\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .tablet\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .tablet\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .tablet\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .tablet\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .tablet\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .tablet\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .tablet\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .tablet\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .tablet\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .tablet\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .tablet\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .tablet\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .tablet\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .tablet\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .tablet\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .tablet\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .tablet\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .tablet\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .tablet\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .tablet\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .tablet\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .tablet\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .tablet\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .tablet\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .tablet\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .tablet\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .tablet\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .tablet\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .tablet\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .tablet\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .tablet\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .tablet\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .tablet\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .tablet\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .tablet\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .tablet\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .tablet\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .tablet\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .tablet\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .tablet\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .tablet\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .tablet\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .tablet\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .tablet\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .tablet\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .tablet\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .tablet\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .tablet\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .tablet\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .tablet\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .tablet\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .tablet\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .tablet\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .tablet\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .tablet\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .tablet\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .tablet\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .tablet\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .tablet\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .tablet\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .tablet\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .tablet\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .tablet\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .tablet\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .tablet\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .tablet\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .tablet\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .tablet\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .tablet\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .tablet\:border-solid {
    border-style: solid;
  }

  .tablet\:border-dashed {
    border-style: dashed;
  }

  .tablet\:border-dotted {
    border-style: dotted;
  }

  .tablet\:border-double {
    border-style: double;
  }

  .tablet\:border-none {
    border-style: none;
  }

  .tablet\:border-0 {
    border-width: 0;
  }

  .tablet\:border-2 {
    border-width: 2px;
  }

  .tablet\:border-4 {
    border-width: 4px;
  }

  .tablet\:border-8 {
    border-width: 8px;
  }

  .tablet\:border {
    border-width: 1px;
  }

  .tablet\:border-t-0 {
    border-top-width: 0;
  }

  .tablet\:border-r-0 {
    border-right-width: 0;
  }

  .tablet\:border-b-0 {
    border-bottom-width: 0;
  }

  .tablet\:border-l-0 {
    border-left-width: 0;
  }

  .tablet\:border-t-2 {
    border-top-width: 2px;
  }

  .tablet\:border-r-2 {
    border-right-width: 2px;
  }

  .tablet\:border-b-2 {
    border-bottom-width: 2px;
  }

  .tablet\:border-l-2 {
    border-left-width: 2px;
  }

  .tablet\:border-t-4 {
    border-top-width: 4px;
  }

  .tablet\:border-r-4 {
    border-right-width: 4px;
  }

  .tablet\:border-b-4 {
    border-bottom-width: 4px;
  }

  .tablet\:border-l-4 {
    border-left-width: 4px;
  }

  .tablet\:border-t-8 {
    border-top-width: 8px;
  }

  .tablet\:border-r-8 {
    border-right-width: 8px;
  }

  .tablet\:border-b-8 {
    border-bottom-width: 8px;
  }

  .tablet\:border-l-8 {
    border-left-width: 8px;
  }

  .tablet\:border-t {
    border-top-width: 1px;
  }

  .tablet\:border-r {
    border-right-width: 1px;
  }

  .tablet\:border-b {
    border-bottom-width: 1px;
  }

  .tablet\:border-l {
    border-left-width: 1px;
  }

  .tablet\:hover\:border-0:hover {
    border-width: 0;
  }

  .tablet\:hover\:border-2:hover {
    border-width: 2px;
  }

  .tablet\:hover\:border-4:hover {
    border-width: 4px;
  }

  .tablet\:hover\:border-8:hover {
    border-width: 8px;
  }

  .tablet\:hover\:border:hover {
    border-width: 1px;
  }

  .tablet\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .tablet\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .tablet\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .tablet\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .tablet\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .tablet\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .tablet\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .tablet\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .tablet\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .tablet\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .tablet\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .tablet\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .tablet\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .tablet\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .tablet\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .tablet\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .tablet\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .tablet\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .tablet\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .tablet\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .tablet\:focus\:border-0:focus {
    border-width: 0;
  }

  .tablet\:focus\:border-2:focus {
    border-width: 2px;
  }

  .tablet\:focus\:border-4:focus {
    border-width: 4px;
  }

  .tablet\:focus\:border-8:focus {
    border-width: 8px;
  }

  .tablet\:focus\:border:focus {
    border-width: 1px;
  }

  .tablet\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .tablet\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .tablet\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .tablet\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .tablet\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .tablet\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .tablet\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .tablet\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .tablet\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .tablet\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .tablet\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .tablet\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .tablet\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .tablet\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .tablet\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .tablet\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .tablet\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .tablet\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .tablet\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .tablet\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .tablet\:box-border {
    box-sizing: border-box;
  }

  .tablet\:box-content {
    box-sizing: content-box;
  }

  .tablet\:cursor-auto {
    cursor: auto;
  }

  .tablet\:cursor-default {
    cursor: default;
  }

  .tablet\:cursor-pointer {
    cursor: pointer;
  }

  .tablet\:cursor-wait {
    cursor: wait;
  }

  .tablet\:cursor-text {
    cursor: text;
  }

  .tablet\:cursor-move {
    cursor: move;
  }

  .tablet\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .tablet\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .tablet\:hover\:cursor-default:hover {
    cursor: default;
  }

  .tablet\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .tablet\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .tablet\:hover\:cursor-text:hover {
    cursor: text;
  }

  .tablet\:hover\:cursor-move:hover {
    cursor: move;
  }

  .tablet\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .tablet\:block {
    display: block;
  }

  .tablet\:inline-block {
    display: inline-block;
  }

  .tablet\:inline {
    display: inline;
  }

  .tablet\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .tablet\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .tablet\:table {
    display: table;
  }

  .tablet\:table-caption {
    display: table-caption;
  }

  .tablet\:table-cell {
    display: table-cell;
  }

  .tablet\:table-column {
    display: table-column;
  }

  .tablet\:table-column-group {
    display: table-column-group;
  }

  .tablet\:table-footer-group {
    display: table-footer-group;
  }

  .tablet\:table-header-group {
    display: table-header-group;
  }

  .tablet\:table-row-group {
    display: table-row-group;
  }

  .tablet\:table-row {
    display: table-row;
  }

  .tablet\:flow-root {
    display: flow-root;
  }

  .tablet\:grid {
    display: grid;
  }

  .tablet\:inline-grid {
    display: inline-grid;
  }

  .tablet\:contents {
    display: contents;
  }

  .tablet\:hidden {
    display: none;
  }

  .tablet\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .tablet\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .tablet\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .tablet\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .tablet\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .tablet\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .tablet\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .tablet\:place-items-auto {
    place-items: auto;
  }

  .tablet\:place-items-start {
    place-items: start;
  }

  .tablet\:place-items-end {
    place-items: end;
  }

  .tablet\:place-items-center {
    place-items: center;
  }

  .tablet\:place-items-stretch {
    place-items: stretch;
  }

  .tablet\:place-content-center {
    place-content: center;
  }

  .tablet\:place-content-start {
    place-content: start;
  }

  .tablet\:place-content-end {
    place-content: end;
  }

  .tablet\:place-content-between {
    place-content: space-between;
  }

  .tablet\:place-content-around {
    place-content: space-around;
  }

  .tablet\:place-content-evenly {
    place-content: space-evenly;
  }

  .tablet\:place-content-stretch {
    place-content: stretch;
  }

  .tablet\:place-self-auto {
    place-self: auto;
  }

  .tablet\:place-self-start {
    place-self: start;
  }

  .tablet\:place-self-end {
    place-self: end;
  }

  .tablet\:place-self-center {
    place-self: center;
  }

  .tablet\:place-self-stretch {
    place-self: stretch;
  }

  .tablet\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .tablet\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .tablet\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .tablet\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .tablet\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .tablet\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .tablet\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .tablet\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .tablet\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .tablet\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .tablet\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .tablet\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .tablet\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .tablet\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .tablet\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .tablet\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .tablet\:justify-items-auto {
    justify-items: auto;
  }

  .tablet\:justify-items-start {
    justify-items: start;
  }

  .tablet\:justify-items-end {
    justify-items: end;
  }

  .tablet\:justify-items-center {
    justify-items: center;
  }

  .tablet\:justify-items-stretch {
    justify-items: stretch;
  }

  .tablet\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .tablet\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .tablet\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .tablet\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .tablet\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .tablet\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .tablet\:justify-self-auto {
    justify-self: auto;
  }

  .tablet\:justify-self-start {
    justify-self: start;
  }

  .tablet\:justify-self-end {
    justify-self: end;
  }

  .tablet\:justify-self-center {
    justify-self: center;
  }

  .tablet\:justify-self-stretch {
    justify-self: stretch;
  }

  .tablet\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .tablet\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .tablet\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .tablet\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .tablet\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .tablet\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .tablet\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .tablet\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .tablet\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .tablet\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .tablet\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .tablet\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .tablet\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .tablet\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .tablet\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .tablet\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .tablet\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .tablet\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .tablet\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .tablet\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .tablet\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .tablet\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .tablet\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .tablet\:float-right {
    float: right;
  }

  .tablet\:float-left {
    float: left;
  }

  .tablet\:float-none {
    float: none;
  }

  .tablet\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .tablet\:clear-left {
    clear: left;
  }

  .tablet\:clear-right {
    clear: right;
  }

  .tablet\:clear-both {
    clear: both;
  }

  .tablet\:clear-none {
    clear: none;
  }

  .tablet\:font-hairline {
    font-weight: 100;
  }

  .tablet\:font-thin {
    font-weight: 200;
  }

  .tablet\:font-light {
    font-weight: 300;
  }

  .tablet\:font-normal {
    font-weight: 400;
  }

  .tablet\:font-medium {
    font-weight: 500;
  }

  .tablet\:font-semibold {
    font-weight: 600;
  }

  .tablet\:font-bold {
    font-weight: 700;
  }

  .tablet\:font-extrabold {
    font-weight: 800;
  }

  .tablet\:font-black {
    font-weight: 900;
  }

  .tablet\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .tablet\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .tablet\:hover\:font-light:hover {
    font-weight: 300;
  }

  .tablet\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .tablet\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .tablet\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .tablet\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .tablet\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .tablet\:hover\:font-black:hover {
    font-weight: 900;
  }

  .tablet\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .tablet\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .tablet\:focus\:font-light:focus {
    font-weight: 300;
  }

  .tablet\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .tablet\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .tablet\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .tablet\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .tablet\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .tablet\:focus\:font-black:focus {
    font-weight: 900;
  }

  .tablet\:h-1 {
    height: 5px;
  }

  .tablet\:h-2 {
    height: 10px;
  }

  .tablet\:h-3 {
    height: 15px;
  }

  .tablet\:h-4 {
    height: 30px;
  }

  .tablet\:h-5 {
    height: 50px;
  }

  .tablet\:h-6 {
    height: 75px;
  }

  .tablet\:h-7 {
    height: 100px;
  }

  .tablet\:h-logo-height-small {
    height: 55px;
  }

  .tablet\:h-logo-height-large {
    height: 65px;
  }

  .tablet\:leading-3 {
    line-height: .75rem;
  }

  .tablet\:leading-4 {
    line-height: 1rem;
  }

  .tablet\:leading-5 {
    line-height: 1.25rem;
  }

  .tablet\:leading-6 {
    line-height: 1.5rem;
  }

  .tablet\:leading-7 {
    line-height: 1.75rem;
  }

  .tablet\:leading-8 {
    line-height: 2rem;
  }

  .tablet\:leading-9 {
    line-height: 2.25rem;
  }

  .tablet\:leading-10 {
    line-height: 2.5rem;
  }

  .tablet\:leading-none {
    line-height: 1;
  }

  .tablet\:leading-tight {
    line-height: 1.25;
  }

  .tablet\:leading-snug {
    line-height: 1.375;
  }

  .tablet\:leading-normal {
    line-height: 1.5;
  }

  .tablet\:leading-relaxed {
    line-height: 1.625;
  }

  .tablet\:leading-loose {
    line-height: 2;
  }

  .tablet\:list-inside {
    list-style-position: inside;
  }

  .tablet\:list-outside {
    list-style-position: outside;
  }

  .tablet\:list-none {
    list-style-type: none;
  }

  .tablet\:list-disc {
    list-style-type: disc;
  }

  .tablet\:list-decimal {
    list-style-type: decimal;
  }

  .tablet\:m-1 {
    margin: 5px;
  }

  .tablet\:m-2 {
    margin: 10px;
  }

  .tablet\:m-3 {
    margin: 15px;
  }

  .tablet\:m-4 {
    margin: 30px;
  }

  .tablet\:m-5 {
    margin: 50px;
  }

  .tablet\:m-6 {
    margin: 75px;
  }

  .tablet\:m-7 {
    margin: 100px;
  }

  .tablet\:m-auto {
    margin: auto;
  }

  .tablet\:m-none {
    margin: 0px;
  }

  .tablet\:m-tiny {
    margin: 10px;
  }

  .tablet\:m-small {
    margin: 20px;
  }

  .tablet\:m-medium {
    margin: 30px;
  }

  .tablet\:m-large {
    margin: 40px;
  }

  .tablet\:m-huge {
    margin: 60px;
  }

  .tablet\:m-margin-label-height {
    margin: 26px;
  }

  .tablet\:m-nav-height {
    margin: 80px;
  }

  .tablet\:-m-1 {
    margin: -5px;
  }

  .tablet\:-m-2 {
    margin: -10px;
  }

  .tablet\:-m-3 {
    margin: -15px;
  }

  .tablet\:-m-4 {
    margin: -30px;
  }

  .tablet\:-m-5 {
    margin: -50px;
  }

  .tablet\:-m-6 {
    margin: -75px;
  }

  .tablet\:-m-7 {
    margin: -100px;
  }

  .tablet\:-m-none {
    margin: 0px;
  }

  .tablet\:-m-tiny {
    margin: -10px;
  }

  .tablet\:-m-small {
    margin: -20px;
  }

  .tablet\:-m-medium {
    margin: -30px;
  }

  .tablet\:-m-large {
    margin: -40px;
  }

  .tablet\:-m-huge {
    margin: -60px;
  }

  .tablet\:-m-margin-label-height {
    margin: -26px;
  }

  .tablet\:-m-nav-height {
    margin: -80px;
  }

  .tablet\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:mt-1 {
    margin-top: 5px;
  }

  .tablet\:mr-1 {
    margin-right: 5px;
  }

  .tablet\:mb-1 {
    margin-bottom: 5px;
  }

  .tablet\:ml-1 {
    margin-left: 5px;
  }

  .tablet\:mt-2 {
    margin-top: 10px;
  }

  .tablet\:mr-2 {
    margin-right: 10px;
  }

  .tablet\:mb-2 {
    margin-bottom: 10px;
  }

  .tablet\:ml-2 {
    margin-left: 10px;
  }

  .tablet\:mt-3 {
    margin-top: 15px;
  }

  .tablet\:mr-3 {
    margin-right: 15px;
  }

  .tablet\:mb-3 {
    margin-bottom: 15px;
  }

  .tablet\:ml-3 {
    margin-left: 15px;
  }

  .tablet\:mt-4 {
    margin-top: 30px;
  }

  .tablet\:mr-4 {
    margin-right: 30px;
  }

  .tablet\:mb-4 {
    margin-bottom: 30px;
  }

  .tablet\:ml-4 {
    margin-left: 30px;
  }

  .tablet\:mt-5 {
    margin-top: 50px;
  }

  .tablet\:mr-5 {
    margin-right: 50px;
  }

  .tablet\:mb-5 {
    margin-bottom: 50px;
  }

  .tablet\:ml-5 {
    margin-left: 50px;
  }

  .tablet\:mt-6 {
    margin-top: 75px;
  }

  .tablet\:mr-6 {
    margin-right: 75px;
  }

  .tablet\:mb-6 {
    margin-bottom: 75px;
  }

  .tablet\:ml-6 {
    margin-left: 75px;
  }

  .tablet\:mt-7 {
    margin-top: 100px;
  }

  .tablet\:mr-7 {
    margin-right: 100px;
  }

  .tablet\:mb-7 {
    margin-bottom: 100px;
  }

  .tablet\:ml-7 {
    margin-left: 100px;
  }

  .tablet\:mt-auto {
    margin-top: auto;
  }

  .tablet\:mr-auto {
    margin-right: auto;
  }

  .tablet\:mb-auto {
    margin-bottom: auto;
  }

  .tablet\:ml-auto {
    margin-left: auto;
  }

  .tablet\:mt-none {
    margin-top: 0px;
  }

  .tablet\:mr-none {
    margin-right: 0px;
  }

  .tablet\:mb-none {
    margin-bottom: 0px;
  }

  .tablet\:ml-none {
    margin-left: 0px;
  }

  .tablet\:mt-tiny {
    margin-top: 10px;
  }

  .tablet\:mr-tiny {
    margin-right: 10px;
  }

  .tablet\:mb-tiny {
    margin-bottom: 10px;
  }

  .tablet\:ml-tiny {
    margin-left: 10px;
  }

  .tablet\:mt-small {
    margin-top: 20px;
  }

  .tablet\:mr-small {
    margin-right: 20px;
  }

  .tablet\:mb-small {
    margin-bottom: 20px;
  }

  .tablet\:ml-small {
    margin-left: 20px;
  }

  .tablet\:mt-medium {
    margin-top: 30px;
  }

  .tablet\:mr-medium {
    margin-right: 30px;
  }

  .tablet\:mb-medium {
    margin-bottom: 30px;
  }

  .tablet\:ml-medium {
    margin-left: 30px;
  }

  .tablet\:mt-large {
    margin-top: 40px;
  }

  .tablet\:mr-large {
    margin-right: 40px;
  }

  .tablet\:mb-large {
    margin-bottom: 40px;
  }

  .tablet\:ml-large {
    margin-left: 40px;
  }

  .tablet\:mt-huge {
    margin-top: 60px;
  }

  .tablet\:mr-huge {
    margin-right: 60px;
  }

  .tablet\:mb-huge {
    margin-bottom: 60px;
  }

  .tablet\:ml-huge {
    margin-left: 60px;
  }

  .tablet\:mt-margin-label-height {
    margin-top: 26px;
  }

  .tablet\:mr-margin-label-height {
    margin-right: 26px;
  }

  .tablet\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .tablet\:ml-margin-label-height {
    margin-left: 26px;
  }

  .tablet\:mt-nav-height {
    margin-top: 80px;
  }

  .tablet\:mr-nav-height {
    margin-right: 80px;
  }

  .tablet\:mb-nav-height {
    margin-bottom: 80px;
  }

  .tablet\:ml-nav-height {
    margin-left: 80px;
  }

  .tablet\:-mt-1 {
    margin-top: -5px;
  }

  .tablet\:-mr-1 {
    margin-right: -5px;
  }

  .tablet\:-mb-1 {
    margin-bottom: -5px;
  }

  .tablet\:-ml-1 {
    margin-left: -5px;
  }

  .tablet\:-mt-2 {
    margin-top: -10px;
  }

  .tablet\:-mr-2 {
    margin-right: -10px;
  }

  .tablet\:-mb-2 {
    margin-bottom: -10px;
  }

  .tablet\:-ml-2 {
    margin-left: -10px;
  }

  .tablet\:-mt-3 {
    margin-top: -15px;
  }

  .tablet\:-mr-3 {
    margin-right: -15px;
  }

  .tablet\:-mb-3 {
    margin-bottom: -15px;
  }

  .tablet\:-ml-3 {
    margin-left: -15px;
  }

  .tablet\:-mt-4 {
    margin-top: -30px;
  }

  .tablet\:-mr-4 {
    margin-right: -30px;
  }

  .tablet\:-mb-4 {
    margin-bottom: -30px;
  }

  .tablet\:-ml-4 {
    margin-left: -30px;
  }

  .tablet\:-mt-5 {
    margin-top: -50px;
  }

  .tablet\:-mr-5 {
    margin-right: -50px;
  }

  .tablet\:-mb-5 {
    margin-bottom: -50px;
  }

  .tablet\:-ml-5 {
    margin-left: -50px;
  }

  .tablet\:-mt-6 {
    margin-top: -75px;
  }

  .tablet\:-mr-6 {
    margin-right: -75px;
  }

  .tablet\:-mb-6 {
    margin-bottom: -75px;
  }

  .tablet\:-ml-6 {
    margin-left: -75px;
  }

  .tablet\:-mt-7 {
    margin-top: -100px;
  }

  .tablet\:-mr-7 {
    margin-right: -100px;
  }

  .tablet\:-mb-7 {
    margin-bottom: -100px;
  }

  .tablet\:-ml-7 {
    margin-left: -100px;
  }

  .tablet\:-mt-none {
    margin-top: 0px;
  }

  .tablet\:-mr-none {
    margin-right: 0px;
  }

  .tablet\:-mb-none {
    margin-bottom: 0px;
  }

  .tablet\:-ml-none {
    margin-left: 0px;
  }

  .tablet\:-mt-tiny {
    margin-top: -10px;
  }

  .tablet\:-mr-tiny {
    margin-right: -10px;
  }

  .tablet\:-mb-tiny {
    margin-bottom: -10px;
  }

  .tablet\:-ml-tiny {
    margin-left: -10px;
  }

  .tablet\:-mt-small {
    margin-top: -20px;
  }

  .tablet\:-mr-small {
    margin-right: -20px;
  }

  .tablet\:-mb-small {
    margin-bottom: -20px;
  }

  .tablet\:-ml-small {
    margin-left: -20px;
  }

  .tablet\:-mt-medium {
    margin-top: -30px;
  }

  .tablet\:-mr-medium {
    margin-right: -30px;
  }

  .tablet\:-mb-medium {
    margin-bottom: -30px;
  }

  .tablet\:-ml-medium {
    margin-left: -30px;
  }

  .tablet\:-mt-large {
    margin-top: -40px;
  }

  .tablet\:-mr-large {
    margin-right: -40px;
  }

  .tablet\:-mb-large {
    margin-bottom: -40px;
  }

  .tablet\:-ml-large {
    margin-left: -40px;
  }

  .tablet\:-mt-huge {
    margin-top: -60px;
  }

  .tablet\:-mr-huge {
    margin-right: -60px;
  }

  .tablet\:-mb-huge {
    margin-bottom: -60px;
  }

  .tablet\:-ml-huge {
    margin-left: -60px;
  }

  .tablet\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .tablet\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .tablet\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .tablet\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .tablet\:-mt-nav-height {
    margin-top: -80px;
  }

  .tablet\:-mr-nav-height {
    margin-right: -80px;
  }

  .tablet\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .tablet\:-ml-nav-height {
    margin-left: -80px;
  }

  .tablet\:hover\:m-1:hover {
    margin: 5px;
  }

  .tablet\:hover\:m-2:hover {
    margin: 10px;
  }

  .tablet\:hover\:m-3:hover {
    margin: 15px;
  }

  .tablet\:hover\:m-4:hover {
    margin: 30px;
  }

  .tablet\:hover\:m-5:hover {
    margin: 50px;
  }

  .tablet\:hover\:m-6:hover {
    margin: 75px;
  }

  .tablet\:hover\:m-7:hover {
    margin: 100px;
  }

  .tablet\:hover\:m-auto:hover {
    margin: auto;
  }

  .tablet\:hover\:m-none:hover {
    margin: 0px;
  }

  .tablet\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .tablet\:hover\:m-small:hover {
    margin: 20px;
  }

  .tablet\:hover\:m-medium:hover {
    margin: 30px;
  }

  .tablet\:hover\:m-large:hover {
    margin: 40px;
  }

  .tablet\:hover\:m-huge:hover {
    margin: 60px;
  }

  .tablet\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .tablet\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .tablet\:hover\:-m-1:hover {
    margin: -5px;
  }

  .tablet\:hover\:-m-2:hover {
    margin: -10px;
  }

  .tablet\:hover\:-m-3:hover {
    margin: -15px;
  }

  .tablet\:hover\:-m-4:hover {
    margin: -30px;
  }

  .tablet\:hover\:-m-5:hover {
    margin: -50px;
  }

  .tablet\:hover\:-m-6:hover {
    margin: -75px;
  }

  .tablet\:hover\:-m-7:hover {
    margin: -100px;
  }

  .tablet\:hover\:-m-none:hover {
    margin: 0px;
  }

  .tablet\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .tablet\:hover\:-m-small:hover {
    margin: -20px;
  }

  .tablet\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .tablet\:hover\:-m-large:hover {
    margin: -40px;
  }

  .tablet\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .tablet\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .tablet\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .tablet\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .tablet\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .tablet\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .tablet\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .tablet\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .tablet\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .tablet\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .tablet\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .tablet\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .tablet\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .tablet\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .tablet\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .tablet\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .tablet\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .tablet\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .tablet\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .tablet\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .tablet\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .tablet\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .tablet\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .tablet\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .tablet\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .tablet\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .tablet\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .tablet\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .tablet\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .tablet\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .tablet\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .tablet\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .tablet\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .tablet\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .tablet\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .tablet\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .tablet\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .tablet\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .tablet\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .tablet\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .tablet\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .tablet\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .tablet\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .tablet\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .tablet\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .tablet\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .tablet\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .tablet\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .tablet\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .tablet\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .tablet\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .tablet\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .tablet\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .tablet\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .tablet\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .tablet\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .tablet\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .tablet\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .tablet\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .tablet\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .tablet\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .tablet\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .tablet\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .tablet\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .tablet\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .tablet\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .tablet\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .tablet\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .tablet\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .tablet\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .tablet\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .tablet\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .tablet\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .tablet\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .tablet\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .tablet\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .tablet\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .tablet\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .tablet\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .tablet\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .tablet\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .tablet\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .tablet\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .tablet\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .tablet\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .tablet\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .tablet\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .tablet\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .tablet\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .tablet\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .tablet\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .tablet\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .tablet\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .tablet\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .tablet\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .tablet\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .tablet\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .tablet\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .tablet\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .tablet\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .tablet\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .tablet\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .tablet\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .tablet\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .tablet\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .tablet\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .tablet\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .tablet\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .tablet\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .tablet\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .tablet\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .tablet\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .tablet\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .tablet\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .tablet\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .tablet\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .tablet\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .tablet\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .tablet\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .tablet\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .tablet\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .tablet\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .tablet\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .tablet\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .tablet\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .tablet\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .tablet\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .tablet\:focus\:m-1:focus {
    margin: 5px;
  }

  .tablet\:focus\:m-2:focus {
    margin: 10px;
  }

  .tablet\:focus\:m-3:focus {
    margin: 15px;
  }

  .tablet\:focus\:m-4:focus {
    margin: 30px;
  }

  .tablet\:focus\:m-5:focus {
    margin: 50px;
  }

  .tablet\:focus\:m-6:focus {
    margin: 75px;
  }

  .tablet\:focus\:m-7:focus {
    margin: 100px;
  }

  .tablet\:focus\:m-auto:focus {
    margin: auto;
  }

  .tablet\:focus\:m-none:focus {
    margin: 0px;
  }

  .tablet\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .tablet\:focus\:m-small:focus {
    margin: 20px;
  }

  .tablet\:focus\:m-medium:focus {
    margin: 30px;
  }

  .tablet\:focus\:m-large:focus {
    margin: 40px;
  }

  .tablet\:focus\:m-huge:focus {
    margin: 60px;
  }

  .tablet\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .tablet\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .tablet\:focus\:-m-1:focus {
    margin: -5px;
  }

  .tablet\:focus\:-m-2:focus {
    margin: -10px;
  }

  .tablet\:focus\:-m-3:focus {
    margin: -15px;
  }

  .tablet\:focus\:-m-4:focus {
    margin: -30px;
  }

  .tablet\:focus\:-m-5:focus {
    margin: -50px;
  }

  .tablet\:focus\:-m-6:focus {
    margin: -75px;
  }

  .tablet\:focus\:-m-7:focus {
    margin: -100px;
  }

  .tablet\:focus\:-m-none:focus {
    margin: 0px;
  }

  .tablet\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .tablet\:focus\:-m-small:focus {
    margin: -20px;
  }

  .tablet\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .tablet\:focus\:-m-large:focus {
    margin: -40px;
  }

  .tablet\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .tablet\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .tablet\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .tablet\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .tablet\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .tablet\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .tablet\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .tablet\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .tablet\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .tablet\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .tablet\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .tablet\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .tablet\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .tablet\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .tablet\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .tablet\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .tablet\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .tablet\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .tablet\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .tablet\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .tablet\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .tablet\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .tablet\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .tablet\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .tablet\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .tablet\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .tablet\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .tablet\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .tablet\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .tablet\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .tablet\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .tablet\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .tablet\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .tablet\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .tablet\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .tablet\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .tablet\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .tablet\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .tablet\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .tablet\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .tablet\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .tablet\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .tablet\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .tablet\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .tablet\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .tablet\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .tablet\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .tablet\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .tablet\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .tablet\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .tablet\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .tablet\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .tablet\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .tablet\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .tablet\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .tablet\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .tablet\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .tablet\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .tablet\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .tablet\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .tablet\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .tablet\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .tablet\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .tablet\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .tablet\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .tablet\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .tablet\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .tablet\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .tablet\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .tablet\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .tablet\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .tablet\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .tablet\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .tablet\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .tablet\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .tablet\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .tablet\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .tablet\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .tablet\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .tablet\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .tablet\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .tablet\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .tablet\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .tablet\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .tablet\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .tablet\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .tablet\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .tablet\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .tablet\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .tablet\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .tablet\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .tablet\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .tablet\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .tablet\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .tablet\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .tablet\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .tablet\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .tablet\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .tablet\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .tablet\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .tablet\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .tablet\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .tablet\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .tablet\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .tablet\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .tablet\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .tablet\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .tablet\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .tablet\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .tablet\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .tablet\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .tablet\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .tablet\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .tablet\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .tablet\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .tablet\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .tablet\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .tablet\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .tablet\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .tablet\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .tablet\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .tablet\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .tablet\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .tablet\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .tablet\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .tablet\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .tablet\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .tablet\:first\:m-1:first-child {
    margin: 5px;
  }

  .tablet\:first\:m-2:first-child {
    margin: 10px;
  }

  .tablet\:first\:m-3:first-child {
    margin: 15px;
  }

  .tablet\:first\:m-4:first-child {
    margin: 30px;
  }

  .tablet\:first\:m-5:first-child {
    margin: 50px;
  }

  .tablet\:first\:m-6:first-child {
    margin: 75px;
  }

  .tablet\:first\:m-7:first-child {
    margin: 100px;
  }

  .tablet\:first\:m-auto:first-child {
    margin: auto;
  }

  .tablet\:first\:m-none:first-child {
    margin: 0px;
  }

  .tablet\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .tablet\:first\:m-small:first-child {
    margin: 20px;
  }

  .tablet\:first\:m-medium:first-child {
    margin: 30px;
  }

  .tablet\:first\:m-large:first-child {
    margin: 40px;
  }

  .tablet\:first\:m-huge:first-child {
    margin: 60px;
  }

  .tablet\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .tablet\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .tablet\:first\:-m-1:first-child {
    margin: -5px;
  }

  .tablet\:first\:-m-2:first-child {
    margin: -10px;
  }

  .tablet\:first\:-m-3:first-child {
    margin: -15px;
  }

  .tablet\:first\:-m-4:first-child {
    margin: -30px;
  }

  .tablet\:first\:-m-5:first-child {
    margin: -50px;
  }

  .tablet\:first\:-m-6:first-child {
    margin: -75px;
  }

  .tablet\:first\:-m-7:first-child {
    margin: -100px;
  }

  .tablet\:first\:-m-none:first-child {
    margin: 0px;
  }

  .tablet\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .tablet\:first\:-m-small:first-child {
    margin: -20px;
  }

  .tablet\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .tablet\:first\:-m-large:first-child {
    margin: -40px;
  }

  .tablet\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .tablet\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .tablet\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .tablet\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .tablet\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .tablet\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .tablet\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .tablet\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .tablet\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .tablet\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .tablet\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .tablet\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .tablet\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .tablet\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .tablet\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .tablet\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .tablet\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .tablet\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .tablet\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .tablet\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .tablet\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .tablet\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .tablet\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .tablet\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .tablet\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .tablet\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .tablet\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .tablet\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .tablet\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .tablet\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .tablet\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .tablet\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .tablet\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .tablet\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .tablet\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .tablet\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .tablet\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .tablet\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .tablet\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .tablet\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .tablet\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .tablet\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .tablet\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .tablet\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .tablet\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .tablet\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .tablet\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .tablet\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .tablet\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .tablet\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .tablet\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .tablet\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .tablet\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .tablet\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .tablet\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .tablet\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .tablet\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .tablet\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .tablet\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .tablet\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .tablet\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .tablet\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .tablet\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .tablet\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .tablet\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .tablet\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .tablet\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .tablet\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .tablet\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .tablet\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .tablet\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .tablet\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .tablet\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .tablet\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .tablet\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .tablet\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .tablet\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .tablet\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .tablet\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .tablet\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .tablet\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .tablet\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .tablet\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .tablet\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .tablet\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .tablet\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .tablet\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .tablet\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .tablet\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .tablet\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .tablet\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .tablet\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .tablet\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .tablet\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .tablet\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .tablet\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .tablet\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .tablet\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .tablet\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .tablet\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .tablet\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .tablet\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .tablet\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .tablet\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .tablet\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .tablet\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .tablet\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .tablet\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .tablet\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .tablet\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .tablet\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .tablet\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .tablet\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .tablet\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .tablet\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .tablet\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .tablet\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .tablet\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .tablet\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .tablet\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .tablet\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .tablet\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .tablet\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .tablet\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .tablet\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .tablet\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .tablet\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .tablet\:last\:m-1:last-child {
    margin: 5px;
  }

  .tablet\:last\:m-2:last-child {
    margin: 10px;
  }

  .tablet\:last\:m-3:last-child {
    margin: 15px;
  }

  .tablet\:last\:m-4:last-child {
    margin: 30px;
  }

  .tablet\:last\:m-5:last-child {
    margin: 50px;
  }

  .tablet\:last\:m-6:last-child {
    margin: 75px;
  }

  .tablet\:last\:m-7:last-child {
    margin: 100px;
  }

  .tablet\:last\:m-auto:last-child {
    margin: auto;
  }

  .tablet\:last\:m-none:last-child {
    margin: 0px;
  }

  .tablet\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .tablet\:last\:m-small:last-child {
    margin: 20px;
  }

  .tablet\:last\:m-medium:last-child {
    margin: 30px;
  }

  .tablet\:last\:m-large:last-child {
    margin: 40px;
  }

  .tablet\:last\:m-huge:last-child {
    margin: 60px;
  }

  .tablet\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .tablet\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .tablet\:last\:-m-1:last-child {
    margin: -5px;
  }

  .tablet\:last\:-m-2:last-child {
    margin: -10px;
  }

  .tablet\:last\:-m-3:last-child {
    margin: -15px;
  }

  .tablet\:last\:-m-4:last-child {
    margin: -30px;
  }

  .tablet\:last\:-m-5:last-child {
    margin: -50px;
  }

  .tablet\:last\:-m-6:last-child {
    margin: -75px;
  }

  .tablet\:last\:-m-7:last-child {
    margin: -100px;
  }

  .tablet\:last\:-m-none:last-child {
    margin: 0px;
  }

  .tablet\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .tablet\:last\:-m-small:last-child {
    margin: -20px;
  }

  .tablet\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .tablet\:last\:-m-large:last-child {
    margin: -40px;
  }

  .tablet\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .tablet\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .tablet\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .tablet\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .tablet\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .tablet\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .tablet\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .tablet\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .tablet\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .tablet\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .tablet\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .tablet\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .tablet\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .tablet\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .tablet\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .tablet\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .tablet\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .tablet\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .tablet\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .tablet\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .tablet\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .tablet\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .tablet\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .tablet\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .tablet\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .tablet\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .tablet\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .tablet\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .tablet\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .tablet\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .tablet\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .tablet\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .tablet\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .tablet\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .tablet\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .tablet\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .tablet\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .tablet\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .tablet\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .tablet\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .tablet\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .tablet\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .tablet\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .tablet\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .tablet\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .tablet\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .tablet\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .tablet\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .tablet\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .tablet\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .tablet\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .tablet\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .tablet\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .tablet\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .tablet\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .tablet\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .tablet\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .tablet\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .tablet\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .tablet\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .tablet\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .tablet\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .tablet\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .tablet\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .tablet\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .tablet\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .tablet\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .tablet\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .tablet\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .tablet\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .tablet\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .tablet\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .tablet\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .tablet\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .tablet\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .tablet\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .tablet\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .tablet\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .tablet\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .tablet\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .tablet\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .tablet\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .tablet\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .tablet\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .tablet\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .tablet\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .tablet\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .tablet\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .tablet\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .tablet\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .tablet\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .tablet\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .tablet\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .tablet\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .tablet\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .tablet\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .tablet\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .tablet\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .tablet\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .tablet\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .tablet\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .tablet\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .tablet\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .tablet\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .tablet\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .tablet\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .tablet\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .tablet\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .tablet\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .tablet\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .tablet\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .tablet\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .tablet\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .tablet\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .tablet\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .tablet\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .tablet\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .tablet\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .tablet\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .tablet\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .tablet\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .tablet\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .tablet\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .tablet\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .tablet\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .tablet\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .tablet\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .tablet\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .tablet\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .tablet\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .tablet\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .tablet\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .tablet\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .tablet\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .tablet\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .tablet\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .tablet\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .tablet\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .tablet\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .tablet\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .tablet\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .tablet\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .tablet\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .tablet\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .tablet\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .tablet\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .tablet\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .tablet\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .tablet\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .tablet\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .tablet\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .tablet\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .tablet\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .tablet\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .tablet\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .tablet\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .tablet\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .tablet\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .tablet\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .tablet\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .tablet\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .tablet\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .tablet\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .tablet\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .tablet\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .tablet\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .tablet\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .tablet\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .tablet\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .tablet\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .tablet\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .tablet\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .tablet\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .tablet\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .tablet\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .tablet\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .tablet\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .tablet\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .tablet\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .tablet\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .tablet\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .tablet\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .tablet\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .tablet\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .tablet\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .tablet\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .tablet\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .tablet\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .tablet\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .tablet\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .tablet\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .tablet\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .tablet\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .tablet\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .tablet\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .tablet\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .tablet\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .tablet\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .tablet\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .tablet\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .tablet\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .tablet\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .tablet\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .tablet\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .tablet\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .tablet\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .tablet\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .tablet\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .tablet\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .tablet\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .tablet\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .tablet\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .tablet\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .tablet\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .tablet\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .tablet\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .tablet\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .tablet\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .tablet\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .tablet\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .tablet\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .tablet\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .tablet\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .tablet\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .tablet\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .tablet\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .tablet\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .tablet\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .tablet\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .tablet\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .tablet\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .tablet\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .tablet\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .tablet\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .tablet\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .tablet\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .tablet\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .tablet\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .tablet\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .tablet\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .tablet\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .tablet\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .tablet\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .tablet\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .tablet\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .tablet\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .tablet\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .tablet\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .tablet\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .tablet\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .tablet\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .tablet\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .tablet\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .tablet\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .tablet\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .tablet\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .tablet\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .tablet\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .tablet\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .tablet\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .tablet\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .tablet\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .tablet\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .tablet\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .tablet\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .tablet\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .tablet\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .tablet\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .tablet\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .tablet\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .tablet\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .tablet\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .tablet\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .tablet\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .tablet\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .tablet\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .tablet\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .tablet\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .tablet\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .tablet\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .tablet\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .tablet\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .tablet\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .tablet\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .tablet\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .tablet\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .tablet\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .tablet\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .tablet\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .tablet\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .tablet\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .tablet\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .tablet\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .tablet\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .tablet\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .tablet\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .tablet\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .tablet\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .tablet\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .tablet\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .tablet\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .tablet\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .tablet\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .tablet\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .tablet\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .tablet\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .tablet\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .tablet\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .tablet\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .tablet\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .tablet\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .tablet\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .tablet\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .tablet\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tablet\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tablet\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tablet\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tablet\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tablet\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .tablet\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .tablet\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .tablet\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tablet\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .tablet\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .tablet\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .tablet\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tablet\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tablet\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tablet\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .tablet\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tablet\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .tablet\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .tablet\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .tablet\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .tablet\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .tablet\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .tablet\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .tablet\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .tablet\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .tablet\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .tablet\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tablet\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .tablet\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tablet\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .tablet\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .tablet\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .tablet\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .tablet\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .tablet\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .tablet\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .tablet\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .tablet\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .tablet\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .tablet\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .tablet\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tablet\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .tablet\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .tablet\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .tablet\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .tablet\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .tablet\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .tablet\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .tablet\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .tablet\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .tablet\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .tablet\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .tablet\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .tablet\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .tablet\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .tablet\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .tablet\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .tablet\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .tablet\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .tablet\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .tablet\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .tablet\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .tablet\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .tablet\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .tablet\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .tablet\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .tablet\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .tablet\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .tablet\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .tablet\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .tablet\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .tablet\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .tablet\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .tablet\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .tablet\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .tablet\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .tablet\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .tablet\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .tablet\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .tablet\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .tablet\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .tablet\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .tablet\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .tablet\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .tablet\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .tablet\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .tablet\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .tablet\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .tablet\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .tablet\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .tablet\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .tablet\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .tablet\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .tablet\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .tablet\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .tablet\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .tablet\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .tablet\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .tablet\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .tablet\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .tablet\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .tablet\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .tablet\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .tablet\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .tablet\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .tablet\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .tablet\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .tablet\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .tablet\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .tablet\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .tablet\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .tablet\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .tablet\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .tablet\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .tablet\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .tablet\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .tablet\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .tablet\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .tablet\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .tablet\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .tablet\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .tablet\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .tablet\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .tablet\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .tablet\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .tablet\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .tablet\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .tablet\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .tablet\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .tablet\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .tablet\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .tablet\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .tablet\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .tablet\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .tablet\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .tablet\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .tablet\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .tablet\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .tablet\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .tablet\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .tablet\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .tablet\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .tablet\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .tablet\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .tablet\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .tablet\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .tablet\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .tablet\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .tablet\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .tablet\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .tablet\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .tablet\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .tablet\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .tablet\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .tablet\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .tablet\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .tablet\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .tablet\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .tablet\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .tablet\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .tablet\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .tablet\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .tablet\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .tablet\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .tablet\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .tablet\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .tablet\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .tablet\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .tablet\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .tablet\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .tablet\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .tablet\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .tablet\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .tablet\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .tablet\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .tablet\:max-h-full {
    max-height: 100%;
  }

  .tablet\:max-h-screen {
    max-height: 100vh;
  }

  .tablet\:max-w-none {
    max-width: none;
  }

  .tablet\:max-w-xs {
    max-width: 20rem;
  }

  .tablet\:max-w-sm {
    max-width: 24rem;
  }

  .tablet\:max-w-md {
    max-width: 28rem;
  }

  .tablet\:max-w-lg {
    max-width: 32rem;
  }

  .tablet\:max-w-xl {
    max-width: 36rem;
  }

  .tablet\:max-w-2xl {
    max-width: 42rem;
  }

  .tablet\:max-w-3xl {
    max-width: 48rem;
  }

  .tablet\:max-w-4xl {
    max-width: 56rem;
  }

  .tablet\:max-w-5xl {
    max-width: 64rem;
  }

  .tablet\:max-w-6xl {
    max-width: 72rem;
  }

  .tablet\:max-w-full {
    max-width: 100%;
  }

  .tablet\:max-w-screen-tablet {
    max-width: 801px;
  }

  .tablet\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .tablet\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .tablet\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .tablet\:min-h-0 {
    min-height: 0;
  }

  .tablet\:min-h-full {
    min-height: 100%;
  }

  .tablet\:min-h-screen {
    min-height: 100vh;
  }

  .tablet\:min-w-0 {
    min-width: 0;
  }

  .tablet\:min-w-full {
    min-width: 100%;
  }

  .tablet\:object-contain {
    object-fit: contain;
  }

  .tablet\:object-cover {
    object-fit: cover;
  }

  .tablet\:object-fill {
    object-fit: fill;
  }

  .tablet\:object-none {
    object-fit: none;
  }

  .tablet\:object-scale-down {
    object-fit: scale-down;
  }

  .tablet\:object-bottom {
    object-position: bottom;
  }

  .tablet\:object-center {
    object-position: center;
  }

  .tablet\:object-left {
    object-position: left;
  }

  .tablet\:object-left-bottom {
    object-position: left bottom;
  }

  .tablet\:object-left-top {
    object-position: left top;
  }

  .tablet\:object-right {
    object-position: right;
  }

  .tablet\:object-right-bottom {
    object-position: right bottom;
  }

  .tablet\:object-right-top {
    object-position: right top;
  }

  .tablet\:object-top {
    object-position: top;
  }

  .tablet\:opacity-0 {
    opacity: 0;
  }

  .tablet\:opacity-25 {
    opacity: 0.25;
  }

  .tablet\:opacity-50 {
    opacity: 0.5;
  }

  .tablet\:opacity-75 {
    opacity: 0.75;
  }

  .tablet\:opacity-100 {
    opacity: 1;
  }

  .tablet\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .tablet\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .tablet\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .tablet\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .tablet\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .tablet\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .tablet\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .tablet\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .tablet\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .tablet\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .tablet\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .tablet\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .tablet\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .tablet\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .tablet\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .tablet\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .tablet\:overflow-auto {
    overflow: auto;
  }

  .tablet\:overflow-hidden {
    overflow: hidden;
  }

  .tablet\:overflow-visible {
    overflow: visible;
  }

  .tablet\:overflow-scroll {
    overflow: scroll;
  }

  .tablet\:overflow-x-auto {
    overflow-x: auto;
  }

  .tablet\:overflow-y-auto {
    overflow-y: auto;
  }

  .tablet\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .tablet\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .tablet\:overflow-x-visible {
    overflow-x: visible;
  }

  .tablet\:overflow-y-visible {
    overflow-y: visible;
  }

  .tablet\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .tablet\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .tablet\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .tablet\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .tablet\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .tablet\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .tablet\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .tablet\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .tablet\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .tablet\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .tablet\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .tablet\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .tablet\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .tablet\:p-1 {
    padding: 5px;
  }

  .tablet\:p-2 {
    padding: 10px;
  }

  .tablet\:p-3 {
    padding: 15px;
  }

  .tablet\:p-4 {
    padding: 30px;
  }

  .tablet\:p-5 {
    padding: 50px;
  }

  .tablet\:p-6 {
    padding: 75px;
  }

  .tablet\:p-7 {
    padding: 100px;
  }

  .tablet\:p-none {
    padding: 0px;
  }

  .tablet\:p-tiny {
    padding: 10px;
  }

  .tablet\:p-small {
    padding: 20px;
  }

  .tablet\:p-medium {
    padding: 30px;
  }

  .tablet\:p-large {
    padding: 40px;
  }

  .tablet\:p-huge {
    padding: 60px;
  }

  .tablet\:p-margin-label-height {
    padding: 26px;
  }

  .tablet\:p-nav-height {
    padding: 80px;
  }

  .tablet\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tablet\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tablet\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablet\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tablet\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tablet\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tablet\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .tablet\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .tablet\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .tablet\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet\:pt-1 {
    padding-top: 5px;
  }

  .tablet\:pr-1 {
    padding-right: 5px;
  }

  .tablet\:pb-1 {
    padding-bottom: 5px;
  }

  .tablet\:pl-1 {
    padding-left: 5px;
  }

  .tablet\:pt-2 {
    padding-top: 10px;
  }

  .tablet\:pr-2 {
    padding-right: 10px;
  }

  .tablet\:pb-2 {
    padding-bottom: 10px;
  }

  .tablet\:pl-2 {
    padding-left: 10px;
  }

  .tablet\:pt-3 {
    padding-top: 15px;
  }

  .tablet\:pr-3 {
    padding-right: 15px;
  }

  .tablet\:pb-3 {
    padding-bottom: 15px;
  }

  .tablet\:pl-3 {
    padding-left: 15px;
  }

  .tablet\:pt-4 {
    padding-top: 30px;
  }

  .tablet\:pr-4 {
    padding-right: 30px;
  }

  .tablet\:pb-4 {
    padding-bottom: 30px;
  }

  .tablet\:pl-4 {
    padding-left: 30px;
  }

  .tablet\:pt-5 {
    padding-top: 50px;
  }

  .tablet\:pr-5 {
    padding-right: 50px;
  }

  .tablet\:pb-5 {
    padding-bottom: 50px;
  }

  .tablet\:pl-5 {
    padding-left: 50px;
  }

  .tablet\:pt-6 {
    padding-top: 75px;
  }

  .tablet\:pr-6 {
    padding-right: 75px;
  }

  .tablet\:pb-6 {
    padding-bottom: 75px;
  }

  .tablet\:pl-6 {
    padding-left: 75px;
  }

  .tablet\:pt-7 {
    padding-top: 100px;
  }

  .tablet\:pr-7 {
    padding-right: 100px;
  }

  .tablet\:pb-7 {
    padding-bottom: 100px;
  }

  .tablet\:pl-7 {
    padding-left: 100px;
  }

  .tablet\:pt-none {
    padding-top: 0px;
  }

  .tablet\:pr-none {
    padding-right: 0px;
  }

  .tablet\:pb-none {
    padding-bottom: 0px;
  }

  .tablet\:pl-none {
    padding-left: 0px;
  }

  .tablet\:pt-tiny {
    padding-top: 10px;
  }

  .tablet\:pr-tiny {
    padding-right: 10px;
  }

  .tablet\:pb-tiny {
    padding-bottom: 10px;
  }

  .tablet\:pl-tiny {
    padding-left: 10px;
  }

  .tablet\:pt-small {
    padding-top: 20px;
  }

  .tablet\:pr-small {
    padding-right: 20px;
  }

  .tablet\:pb-small {
    padding-bottom: 20px;
  }

  .tablet\:pl-small {
    padding-left: 20px;
  }

  .tablet\:pt-medium {
    padding-top: 30px;
  }

  .tablet\:pr-medium {
    padding-right: 30px;
  }

  .tablet\:pb-medium {
    padding-bottom: 30px;
  }

  .tablet\:pl-medium {
    padding-left: 30px;
  }

  .tablet\:pt-large {
    padding-top: 40px;
  }

  .tablet\:pr-large {
    padding-right: 40px;
  }

  .tablet\:pb-large {
    padding-bottom: 40px;
  }

  .tablet\:pl-large {
    padding-left: 40px;
  }

  .tablet\:pt-huge {
    padding-top: 60px;
  }

  .tablet\:pr-huge {
    padding-right: 60px;
  }

  .tablet\:pb-huge {
    padding-bottom: 60px;
  }

  .tablet\:pl-huge {
    padding-left: 60px;
  }

  .tablet\:pt-margin-label-height {
    padding-top: 26px;
  }

  .tablet\:pr-margin-label-height {
    padding-right: 26px;
  }

  .tablet\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .tablet\:pl-margin-label-height {
    padding-left: 26px;
  }

  .tablet\:pt-nav-height {
    padding-top: 80px;
  }

  .tablet\:pr-nav-height {
    padding-right: 80px;
  }

  .tablet\:pb-nav-height {
    padding-bottom: 80px;
  }

  .tablet\:pl-nav-height {
    padding-left: 80px;
  }

  .tablet\:hover\:p-1:hover {
    padding: 5px;
  }

  .tablet\:hover\:p-2:hover {
    padding: 10px;
  }

  .tablet\:hover\:p-3:hover {
    padding: 15px;
  }

  .tablet\:hover\:p-4:hover {
    padding: 30px;
  }

  .tablet\:hover\:p-5:hover {
    padding: 50px;
  }

  .tablet\:hover\:p-6:hover {
    padding: 75px;
  }

  .tablet\:hover\:p-7:hover {
    padding: 100px;
  }

  .tablet\:hover\:p-none:hover {
    padding: 0px;
  }

  .tablet\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .tablet\:hover\:p-small:hover {
    padding: 20px;
  }

  .tablet\:hover\:p-medium:hover {
    padding: 30px;
  }

  .tablet\:hover\:p-large:hover {
    padding: 40px;
  }

  .tablet\:hover\:p-huge:hover {
    padding: 60px;
  }

  .tablet\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .tablet\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .tablet\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tablet\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tablet\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablet\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tablet\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tablet\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tablet\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .tablet\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .tablet\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .tablet\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .tablet\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .tablet\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .tablet\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .tablet\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .tablet\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .tablet\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .tablet\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .tablet\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .tablet\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .tablet\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .tablet\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .tablet\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .tablet\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .tablet\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .tablet\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .tablet\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .tablet\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .tablet\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .tablet\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .tablet\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .tablet\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .tablet\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .tablet\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .tablet\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .tablet\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .tablet\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .tablet\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .tablet\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .tablet\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .tablet\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .tablet\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .tablet\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .tablet\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .tablet\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .tablet\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .tablet\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .tablet\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .tablet\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .tablet\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .tablet\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .tablet\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .tablet\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .tablet\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .tablet\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .tablet\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .tablet\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .tablet\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .tablet\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .tablet\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .tablet\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .tablet\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .tablet\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .tablet\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .tablet\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .tablet\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .tablet\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .tablet\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .tablet\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .tablet\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .tablet\:focus\:p-1:focus {
    padding: 5px;
  }

  .tablet\:focus\:p-2:focus {
    padding: 10px;
  }

  .tablet\:focus\:p-3:focus {
    padding: 15px;
  }

  .tablet\:focus\:p-4:focus {
    padding: 30px;
  }

  .tablet\:focus\:p-5:focus {
    padding: 50px;
  }

  .tablet\:focus\:p-6:focus {
    padding: 75px;
  }

  .tablet\:focus\:p-7:focus {
    padding: 100px;
  }

  .tablet\:focus\:p-none:focus {
    padding: 0px;
  }

  .tablet\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .tablet\:focus\:p-small:focus {
    padding: 20px;
  }

  .tablet\:focus\:p-medium:focus {
    padding: 30px;
  }

  .tablet\:focus\:p-large:focus {
    padding: 40px;
  }

  .tablet\:focus\:p-huge:focus {
    padding: 60px;
  }

  .tablet\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .tablet\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .tablet\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tablet\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tablet\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablet\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tablet\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tablet\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tablet\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .tablet\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .tablet\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .tablet\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .tablet\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .tablet\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .tablet\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .tablet\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .tablet\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .tablet\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .tablet\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .tablet\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .tablet\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .tablet\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .tablet\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .tablet\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .tablet\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .tablet\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .tablet\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .tablet\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .tablet\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .tablet\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .tablet\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .tablet\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .tablet\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .tablet\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .tablet\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .tablet\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .tablet\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .tablet\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .tablet\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .tablet\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .tablet\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .tablet\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .tablet\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .tablet\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .tablet\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .tablet\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .tablet\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .tablet\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .tablet\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .tablet\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .tablet\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .tablet\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .tablet\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .tablet\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .tablet\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .tablet\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .tablet\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .tablet\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .tablet\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .tablet\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .tablet\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .tablet\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .tablet\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .tablet\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .tablet\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .tablet\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .tablet\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .tablet\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .tablet\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .tablet\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .tablet\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .tablet\:first\:p-1:first-child {
    padding: 5px;
  }

  .tablet\:first\:p-2:first-child {
    padding: 10px;
  }

  .tablet\:first\:p-3:first-child {
    padding: 15px;
  }

  .tablet\:first\:p-4:first-child {
    padding: 30px;
  }

  .tablet\:first\:p-5:first-child {
    padding: 50px;
  }

  .tablet\:first\:p-6:first-child {
    padding: 75px;
  }

  .tablet\:first\:p-7:first-child {
    padding: 100px;
  }

  .tablet\:first\:p-none:first-child {
    padding: 0px;
  }

  .tablet\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .tablet\:first\:p-small:first-child {
    padding: 20px;
  }

  .tablet\:first\:p-medium:first-child {
    padding: 30px;
  }

  .tablet\:first\:p-large:first-child {
    padding: 40px;
  }

  .tablet\:first\:p-huge:first-child {
    padding: 60px;
  }

  .tablet\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .tablet\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .tablet\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tablet\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tablet\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablet\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tablet\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tablet\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tablet\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .tablet\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .tablet\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .tablet\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .tablet\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .tablet\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .tablet\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .tablet\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .tablet\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .tablet\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .tablet\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .tablet\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .tablet\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .tablet\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .tablet\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .tablet\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .tablet\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .tablet\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .tablet\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .tablet\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .tablet\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .tablet\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .tablet\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .tablet\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .tablet\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .tablet\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .tablet\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .tablet\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .tablet\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .tablet\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .tablet\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .tablet\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .tablet\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .tablet\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .tablet\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .tablet\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .tablet\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .tablet\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .tablet\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .tablet\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .tablet\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .tablet\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .tablet\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .tablet\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .tablet\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .tablet\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .tablet\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .tablet\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .tablet\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .tablet\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .tablet\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .tablet\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .tablet\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .tablet\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .tablet\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .tablet\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .tablet\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .tablet\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .tablet\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .tablet\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .tablet\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .tablet\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .tablet\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .tablet\:last\:p-1:last-child {
    padding: 5px;
  }

  .tablet\:last\:p-2:last-child {
    padding: 10px;
  }

  .tablet\:last\:p-3:last-child {
    padding: 15px;
  }

  .tablet\:last\:p-4:last-child {
    padding: 30px;
  }

  .tablet\:last\:p-5:last-child {
    padding: 50px;
  }

  .tablet\:last\:p-6:last-child {
    padding: 75px;
  }

  .tablet\:last\:p-7:last-child {
    padding: 100px;
  }

  .tablet\:last\:p-none:last-child {
    padding: 0px;
  }

  .tablet\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .tablet\:last\:p-small:last-child {
    padding: 20px;
  }

  .tablet\:last\:p-medium:last-child {
    padding: 30px;
  }

  .tablet\:last\:p-large:last-child {
    padding: 40px;
  }

  .tablet\:last\:p-huge:last-child {
    padding: 60px;
  }

  .tablet\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .tablet\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .tablet\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tablet\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .tablet\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tablet\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tablet\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tablet\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tablet\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .tablet\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .tablet\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .tablet\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tablet\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tablet\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tablet\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tablet\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tablet\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tablet\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tablet\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tablet\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tablet\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tablet\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tablet\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .tablet\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .tablet\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .tablet\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tablet\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tablet\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .tablet\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .tablet\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .tablet\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .tablet\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .tablet\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .tablet\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .tablet\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .tablet\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .tablet\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .tablet\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .tablet\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .tablet\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .tablet\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .tablet\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .tablet\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .tablet\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .tablet\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .tablet\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .tablet\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .tablet\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .tablet\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .tablet\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .tablet\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .tablet\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .tablet\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .tablet\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .tablet\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .tablet\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .tablet\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .tablet\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .tablet\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .tablet\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .tablet\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .tablet\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .tablet\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .tablet\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .tablet\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .tablet\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .tablet\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .tablet\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .tablet\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .tablet\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .tablet\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .tablet\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .tablet\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .tablet\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .tablet\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .tablet\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .tablet\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .tablet\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .tablet\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .tablet\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .tablet\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .tablet\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .tablet\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .tablet\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .tablet\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .tablet\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .tablet\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .tablet\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .tablet\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .tablet\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .tablet\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .tablet\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .tablet\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .tablet\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .tablet\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .tablet\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .tablet\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .tablet\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .tablet\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .tablet\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .tablet\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .tablet\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .tablet\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .tablet\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .tablet\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .tablet\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .tablet\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .tablet\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .tablet\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .tablet\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .tablet\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .tablet\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .tablet\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .tablet\:pointer-events-none {
    pointer-events: none;
  }

  .tablet\:pointer-events-auto {
    pointer-events: auto;
  }

  .tablet\:static {
    position: static;
  }

  .tablet\:fixed {
    position: fixed;
  }

  .tablet\:absolute {
    position: absolute;
  }

  .tablet\:relative {
    position: relative;
  }

  .tablet\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .tablet\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .tablet\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .tablet\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .tablet\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .tablet\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .tablet\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .tablet\:top-0 {
    top: 0;
  }

  .tablet\:right-0 {
    right: 0;
  }

  .tablet\:bottom-0 {
    bottom: 0;
  }

  .tablet\:left-0 {
    left: 0;
  }

  .tablet\:top-auto {
    top: auto;
  }

  .tablet\:right-auto {
    right: auto;
  }

  .tablet\:bottom-auto {
    bottom: auto;
  }

  .tablet\:left-auto {
    left: auto;
  }

  .tablet\:resize-none {
    resize: none;
  }

  .tablet\:resize-y {
    resize: vertical;
  }

  .tablet\:resize-x {
    resize: horizontal;
  }

  .tablet\:resize {
    resize: both;
  }

  .tablet\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .tablet\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .tablet\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .tablet\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .tablet\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .tablet\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .tablet\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .tablet\:shadow-none {
    box-shadow: none;
  }

  .tablet\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .tablet\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .tablet\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .tablet\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .tablet\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .tablet\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .tablet\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .tablet\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .tablet\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .tablet\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .tablet\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .tablet\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .tablet\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .tablet\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .tablet\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .tablet\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .tablet\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .tablet\:fill-current {
    fill: currentColor;
  }

  .tablet\:stroke-current {
    stroke: currentColor;
  }

  .tablet\:stroke-0 {
    stroke-width: 0;
  }

  .tablet\:stroke-1 {
    stroke-width: 1;
  }

  .tablet\:stroke-2 {
    stroke-width: 2;
  }

  .tablet\:table-auto {
    table-layout: auto;
  }

  .tablet\:table-fixed {
    table-layout: fixed;
  }

  .tablet\:text-left {
    text-align: left;
  }

  .tablet\:text-center {
    text-align: center;
  }

  .tablet\:text-right {
    text-align: right;
  }

  .tablet\:text-justify {
    text-align: justify;
  }

  .tablet\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .tablet\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .tablet\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .tablet\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .tablet\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .tablet\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .tablet\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .tablet\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .tablet\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .tablet\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .tablet\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .tablet\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .tablet\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .tablet\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .tablet\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .tablet\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .tablet\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .tablet\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .tablet\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .tablet\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .tablet\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .tablet\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .tablet\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .tablet\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .tablet\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .tablet\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .tablet\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .tablet\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .tablet\:text-state-danger {
    color: #FF8274B3;
  }

  .tablet\:text-state-warning {
    color: #F9C66BB3;
  }

  .tablet\:text-state-success {
    color: #C7F6C9B3;
  }

  .tablet\:text-state-primary {
    color: #B3F5FFB3;
  }

  .tablet\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .tablet\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .tablet\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .tablet\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .tablet\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .tablet\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .tablet\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .tablet\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .tablet\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .tablet\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .tablet\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .tablet\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .tablet\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .tablet\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .tablet\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .tablet\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .tablet\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .tablet\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .tablet\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .tablet\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .tablet\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .tablet\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .tablet\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .tablet\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .tablet\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .tablet\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .tablet\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .tablet\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .tablet\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .tablet\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .tablet\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .tablet\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .tablet\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .tablet\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .tablet\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .tablet\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .tablet\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .tablet\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .tablet\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .tablet\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .tablet\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .tablet\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .tablet\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .tablet\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .tablet\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .tablet\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .tablet\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .tablet\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .tablet\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .tablet\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .tablet\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .tablet\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .tablet\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .tablet\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .tablet\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .tablet\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .tablet\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .tablet\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .tablet\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .tablet\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .tablet\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .tablet\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .tablet\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .tablet\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .tablet\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .tablet\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .tablet\:text-opacity-0 {
    --text-opacity: 0;
  }

  .tablet\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .tablet\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .tablet\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .tablet\:text-opacity-100 {
    --text-opacity: 1;
  }

  .tablet\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .tablet\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .tablet\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .tablet\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .tablet\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .tablet\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .tablet\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .tablet\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .tablet\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .tablet\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .tablet\:italic {
    font-style: italic;
  }

  .tablet\:not-italic {
    font-style: normal;
  }

  .tablet\:uppercase {
    text-transform: uppercase;
  }

  .tablet\:lowercase {
    text-transform: lowercase;
  }

  .tablet\:capitalize {
    text-transform: capitalize;
  }

  .tablet\:normal-case {
    text-transform: none;
  }

  .tablet\:underline {
    text-decoration: underline;
  }

  .tablet\:line-through {
    text-decoration: line-through;
  }

  .tablet\:no-underline {
    text-decoration: none;
  }

  .tablet\:hover\:underline:hover {
    text-decoration: underline;
  }

  .tablet\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .tablet\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .tablet\:focus\:underline:focus {
    text-decoration: underline;
  }

  .tablet\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .tablet\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .tablet\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .tablet\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .tablet\:ordinal, .tablet\:slashed-zero, .tablet\:lining-nums, .tablet\:oldstyle-nums, .tablet\:proportional-nums, .tablet\:tabular-nums, .tablet\:diagonal-fractions, .tablet\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .tablet\:normal-nums {
    font-variant-numeric: normal;
  }

  .tablet\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .tablet\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .tablet\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .tablet\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .tablet\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .tablet\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .tablet\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .tablet\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .tablet\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .tablet\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .tablet\:tracking-normal {
    letter-spacing: 0;
  }

  .tablet\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .tablet\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .tablet\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .tablet\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .tablet\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .tablet\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .tablet\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .tablet\:align-baseline {
    vertical-align: baseline;
  }

  .tablet\:align-top {
    vertical-align: top;
  }

  .tablet\:align-middle {
    vertical-align: middle;
  }

  .tablet\:align-bottom {
    vertical-align: bottom;
  }

  .tablet\:align-text-top {
    vertical-align: text-top;
  }

  .tablet\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .tablet\:visible {
    visibility: visible;
  }

  .tablet\:invisible {
    visibility: hidden;
  }

  .tablet\:whitespace-normal {
    white-space: normal;
  }

  .tablet\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .tablet\:whitespace-pre {
    white-space: pre;
  }

  .tablet\:whitespace-pre-line {
    white-space: pre-line;
  }

  .tablet\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .tablet\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .tablet\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .tablet\:break-all {
    word-break: break-all;
  }

  .tablet\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tablet\:w-1 {
    width: 5px;
  }

  .tablet\:w-2 {
    width: 10px;
  }

  .tablet\:w-3 {
    width: 15px;
  }

  .tablet\:w-4 {
    width: 30px;
  }

  .tablet\:w-5 {
    width: 50px;
  }

  .tablet\:w-6 {
    width: 75px;
  }

  .tablet\:w-7 {
    width: 100px;
  }

  .tablet\:w-auto {
    width: auto;
  }

  .tablet\:w-none {
    width: 0px;
  }

  .tablet\:w-tiny {
    width: 10px;
  }

  .tablet\:w-small {
    width: 20px;
  }

  .tablet\:w-medium {
    width: 30px;
  }

  .tablet\:w-large {
    width: 40px;
  }

  .tablet\:w-huge {
    width: 60px;
  }

  .tablet\:w-margin-label-height {
    width: 26px;
  }

  .tablet\:w-nav-height {
    width: 80px;
  }

  .tablet\:w-1\/2 {
    width: 50%;
  }

  .tablet\:w-1\/3 {
    width: 33.333333%;
  }

  .tablet\:w-2\/3 {
    width: 66.666667%;
  }

  .tablet\:w-1\/4 {
    width: 25%;
  }

  .tablet\:w-2\/4 {
    width: 50%;
  }

  .tablet\:w-3\/4 {
    width: 75%;
  }

  .tablet\:w-1\/5 {
    width: 20%;
  }

  .tablet\:w-2\/5 {
    width: 40%;
  }

  .tablet\:w-3\/5 {
    width: 60%;
  }

  .tablet\:w-4\/5 {
    width: 80%;
  }

  .tablet\:w-1\/6 {
    width: 16.666667%;
  }

  .tablet\:w-2\/6 {
    width: 33.333333%;
  }

  .tablet\:w-3\/6 {
    width: 50%;
  }

  .tablet\:w-4\/6 {
    width: 66.666667%;
  }

  .tablet\:w-5\/6 {
    width: 83.333333%;
  }

  .tablet\:w-1\/12 {
    width: 8.333333%;
  }

  .tablet\:w-2\/12 {
    width: 16.666667%;
  }

  .tablet\:w-3\/12 {
    width: 25%;
  }

  .tablet\:w-4\/12 {
    width: 33.333333%;
  }

  .tablet\:w-5\/12 {
    width: 41.666667%;
  }

  .tablet\:w-6\/12 {
    width: 50%;
  }

  .tablet\:w-7\/12 {
    width: 58.333333%;
  }

  .tablet\:w-8\/12 {
    width: 66.666667%;
  }

  .tablet\:w-9\/12 {
    width: 75%;
  }

  .tablet\:w-10\/12 {
    width: 83.333333%;
  }

  .tablet\:w-11\/12 {
    width: 91.666667%;
  }

  .tablet\:w-full {
    width: 100%;
  }

  .tablet\:w-screen {
    width: 100vw;
  }

  .tablet\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .tablet\:z-0 {
    z-index: 0;
  }

  .tablet\:z-10 {
    z-index: 10;
  }

  .tablet\:z-20 {
    z-index: 20;
  }

  .tablet\:z-30 {
    z-index: 30;
  }

  .tablet\:z-40 {
    z-index: 40;
  }

  .tablet\:z-50 {
    z-index: 50;
  }

  .tablet\:z-auto {
    z-index: auto;
  }

  .tablet\:z-nav {
    z-index: 90;
  }

  .tablet\:z-menu {
    z-index: 100;
  }

  .tablet\:z-popup {
    z-index: 110;
  }

  .tablet\:z-modalBackdrop {
    z-index: 120;
  }

  .tablet\:z-modal {
    z-index: 130;
  }

  .tablet\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .tablet\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .tablet\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .tablet\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .tablet\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .tablet\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .tablet\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .tablet\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .tablet\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .tablet\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .tablet\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .tablet\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .tablet\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .tablet\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .tablet\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .tablet\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .tablet\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .tablet\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .tablet\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .tablet\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .tablet\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .tablet\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .tablet\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .tablet\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .tablet\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .tablet\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .tablet\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .tablet\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .tablet\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .tablet\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .tablet\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .tablet\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .tablet\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .tablet\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .tablet\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .tablet\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .tablet\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .tablet\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .tablet\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .tablet\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .tablet\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .tablet\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .tablet\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .tablet\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .tablet\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .tablet\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .tablet\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .tablet\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .tablet\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .tablet\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .tablet\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .tablet\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .tablet\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .tablet\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .tablet\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .tablet\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .tablet\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .tablet\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .tablet\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .tablet\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .tablet\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .tablet\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .tablet\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .tablet\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .tablet\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .tablet\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .tablet\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .tablet\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .tablet\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .tablet\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .tablet\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .tablet\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .tablet\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .tablet\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .tablet\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .tablet\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .tablet\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .tablet\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .tablet\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .tablet\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .tablet\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .tablet\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .tablet\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .tablet\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .tablet\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .tablet\:grid-flow-row {
    grid-auto-flow: row;
  }

  .tablet\:grid-flow-col {
    grid-auto-flow: column;
  }

  .tablet\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .tablet\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .tablet\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .tablet\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .tablet\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .tablet\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .tablet\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .tablet\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .tablet\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .tablet\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .tablet\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .tablet\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .tablet\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .tablet\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .tablet\:grid-cols-none {
    grid-template-columns: none;
  }

  .tablet\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .tablet\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .tablet\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .tablet\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .tablet\:col-auto {
    grid-column: auto;
  }

  .tablet\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .tablet\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .tablet\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .tablet\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .tablet\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .tablet\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .tablet\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .tablet\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .tablet\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .tablet\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .tablet\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .tablet\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .tablet\:col-span-full {
    grid-column: 1 / -1;
  }

  .tablet\:col-start-1 {
    grid-column-start: 1;
  }

  .tablet\:col-start-2 {
    grid-column-start: 2;
  }

  .tablet\:col-start-3 {
    grid-column-start: 3;
  }

  .tablet\:col-start-4 {
    grid-column-start: 4;
  }

  .tablet\:col-start-5 {
    grid-column-start: 5;
  }

  .tablet\:col-start-6 {
    grid-column-start: 6;
  }

  .tablet\:col-start-7 {
    grid-column-start: 7;
  }

  .tablet\:col-start-8 {
    grid-column-start: 8;
  }

  .tablet\:col-start-9 {
    grid-column-start: 9;
  }

  .tablet\:col-start-10 {
    grid-column-start: 10;
  }

  .tablet\:col-start-11 {
    grid-column-start: 11;
  }

  .tablet\:col-start-12 {
    grid-column-start: 12;
  }

  .tablet\:col-start-13 {
    grid-column-start: 13;
  }

  .tablet\:col-start-auto {
    grid-column-start: auto;
  }

  .tablet\:col-end-1 {
    grid-column-end: 1;
  }

  .tablet\:col-end-2 {
    grid-column-end: 2;
  }

  .tablet\:col-end-3 {
    grid-column-end: 3;
  }

  .tablet\:col-end-4 {
    grid-column-end: 4;
  }

  .tablet\:col-end-5 {
    grid-column-end: 5;
  }

  .tablet\:col-end-6 {
    grid-column-end: 6;
  }

  .tablet\:col-end-7 {
    grid-column-end: 7;
  }

  .tablet\:col-end-8 {
    grid-column-end: 8;
  }

  .tablet\:col-end-9 {
    grid-column-end: 9;
  }

  .tablet\:col-end-10 {
    grid-column-end: 10;
  }

  .tablet\:col-end-11 {
    grid-column-end: 11;
  }

  .tablet\:col-end-12 {
    grid-column-end: 12;
  }

  .tablet\:col-end-13 {
    grid-column-end: 13;
  }

  .tablet\:col-end-auto {
    grid-column-end: auto;
  }

  .tablet\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .tablet\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .tablet\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .tablet\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .tablet\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .tablet\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .tablet\:grid-rows-none {
    grid-template-rows: none;
  }

  .tablet\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .tablet\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .tablet\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .tablet\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .tablet\:row-auto {
    grid-row: auto;
  }

  .tablet\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .tablet\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .tablet\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .tablet\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .tablet\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .tablet\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .tablet\:row-span-full {
    grid-row: 1 / -1;
  }

  .tablet\:row-start-1 {
    grid-row-start: 1;
  }

  .tablet\:row-start-2 {
    grid-row-start: 2;
  }

  .tablet\:row-start-3 {
    grid-row-start: 3;
  }

  .tablet\:row-start-4 {
    grid-row-start: 4;
  }

  .tablet\:row-start-5 {
    grid-row-start: 5;
  }

  .tablet\:row-start-6 {
    grid-row-start: 6;
  }

  .tablet\:row-start-7 {
    grid-row-start: 7;
  }

  .tablet\:row-start-auto {
    grid-row-start: auto;
  }

  .tablet\:row-end-1 {
    grid-row-end: 1;
  }

  .tablet\:row-end-2 {
    grid-row-end: 2;
  }

  .tablet\:row-end-3 {
    grid-row-end: 3;
  }

  .tablet\:row-end-4 {
    grid-row-end: 4;
  }

  .tablet\:row-end-5 {
    grid-row-end: 5;
  }

  .tablet\:row-end-6 {
    grid-row-end: 6;
  }

  .tablet\:row-end-7 {
    grid-row-end: 7;
  }

  .tablet\:row-end-auto {
    grid-row-end: auto;
  }

  .tablet\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .tablet\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .tablet\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .tablet\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .tablet\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .tablet\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .tablet\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .tablet\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .tablet\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .tablet\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .tablet\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .tablet\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .tablet\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .tablet\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .tablet\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .tablet\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .tablet\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .tablet\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .tablet\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .tablet\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .tablet\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .tablet\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .tablet\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .tablet\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .tablet\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .tablet\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .tablet\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .tablet\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .tablet\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .tablet\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .tablet\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .tablet\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .tablet\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .tablet\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .tablet\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .tablet\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .tablet\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .tablet\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .tablet\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .tablet\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .tablet\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .tablet\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .tablet\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .tablet\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .tablet\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .tablet\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .tablet\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .tablet\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .tablet\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .tablet\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .tablet\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .tablet\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .tablet\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .tablet\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .tablet\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .tablet\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .tablet\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .tablet\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .tablet\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .tablet\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .tablet\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .tablet\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .tablet\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .tablet\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .tablet\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .tablet\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .tablet\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .tablet\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .tablet\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .tablet\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .tablet\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .tablet\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .tablet\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .tablet\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .tablet\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .tablet\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .tablet\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .tablet\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .tablet\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .tablet\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .tablet\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .tablet\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .tablet\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .tablet\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .tablet\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .tablet\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .tablet\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .tablet\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .tablet\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .tablet\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .tablet\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .tablet\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .tablet\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .tablet\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .tablet\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .tablet\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .tablet\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .tablet\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .tablet\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .tablet\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .tablet\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .tablet\:rotate-0 {
    --transform-rotate: 0;
  }

  .tablet\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .tablet\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .tablet\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .tablet\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .tablet\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .tablet\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .tablet\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .tablet\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .tablet\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .tablet\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .tablet\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .tablet\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .tablet\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .tablet\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .tablet\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .tablet\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .tablet\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .tablet\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .tablet\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .tablet\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .tablet\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .tablet\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .tablet\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .tablet\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .tablet\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .tablet\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .tablet\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .tablet\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .tablet\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .tablet\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .tablet\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .tablet\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .tablet\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .tablet\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .tablet\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .tablet\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .tablet\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .tablet\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .tablet\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .tablet\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .tablet\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .tablet\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .tablet\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .tablet\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .tablet\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .tablet\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .tablet\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .tablet\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .tablet\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .tablet\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .tablet\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .tablet\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .tablet\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .tablet\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .tablet\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .tablet\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .tablet\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .tablet\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .tablet\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .tablet\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .tablet\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .tablet\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .tablet\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .tablet\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .tablet\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .tablet\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .tablet\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .tablet\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .tablet\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .tablet\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .tablet\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .tablet\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .tablet\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .tablet\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .tablet\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .tablet\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .tablet\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .tablet\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .tablet\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .tablet\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .tablet\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .tablet\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .tablet\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .tablet\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .tablet\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .tablet\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .tablet\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .tablet\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .tablet\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .tablet\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .tablet\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .tablet\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .tablet\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .tablet\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .tablet\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .tablet\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .tablet\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .tablet\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .tablet\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .tablet\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .tablet\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .tablet\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .tablet\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .tablet\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .tablet\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .tablet\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .tablet\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .tablet\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .tablet\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .tablet\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .tablet\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .tablet\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .tablet\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .tablet\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .tablet\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .tablet\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .tablet\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .tablet\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .tablet\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .tablet\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .tablet\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .tablet\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .tablet\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .tablet\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .tablet\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .tablet\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .tablet\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .tablet\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .tablet\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .tablet\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .tablet\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .tablet\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .tablet\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .tablet\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .tablet\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .tablet\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .tablet\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .tablet\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .tablet\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .tablet\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .tablet\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .tablet\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .tablet\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .tablet\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .tablet\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .tablet\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .tablet\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .tablet\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .tablet\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .tablet\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .tablet\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .tablet\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .tablet\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .tablet\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .tablet\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .tablet\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .tablet\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .tablet\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .tablet\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .tablet\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .tablet\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .tablet\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .tablet\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .tablet\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .tablet\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .tablet\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .tablet\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .tablet\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .tablet\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .tablet\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .tablet\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .tablet\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .tablet\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .tablet\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .tablet\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .tablet\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .tablet\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .tablet\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .tablet\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .tablet\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .tablet\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .tablet\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .tablet\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .tablet\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .tablet\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .tablet\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .tablet\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .tablet\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .tablet\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .tablet\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .tablet\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .tablet\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .tablet\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .tablet\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .tablet\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .tablet\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .tablet\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .tablet\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .tablet\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .tablet\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .tablet\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .tablet\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .tablet\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .tablet\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .tablet\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .tablet\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .tablet\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .tablet\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .tablet\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .tablet\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .tablet\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .tablet\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .tablet\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .tablet\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .tablet\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .tablet\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .tablet\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .tablet\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .tablet\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .tablet\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .tablet\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .tablet\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .tablet\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .tablet\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .tablet\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .tablet\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .tablet\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .tablet\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .tablet\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .tablet\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .tablet\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .tablet\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .tablet\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .tablet\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .tablet\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .tablet\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .tablet\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .tablet\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .tablet\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .tablet\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .tablet\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .tablet\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .tablet\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .tablet\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .tablet\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .tablet\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .tablet\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .tablet\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .tablet\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .tablet\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .tablet\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .tablet\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .tablet\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .tablet\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .tablet\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .tablet\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .tablet\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .tablet\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .tablet\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .tablet\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .tablet\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .tablet\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .tablet\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .tablet\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .tablet\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .tablet\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .tablet\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .tablet\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .tablet\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .tablet\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .tablet\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .tablet\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .tablet\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .tablet\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .tablet\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .tablet\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .tablet\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .tablet\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .tablet\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .tablet\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .tablet\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .tablet\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .tablet\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .tablet\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .tablet\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .tablet\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .tablet\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .tablet\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .tablet\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .tablet\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .tablet\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .tablet\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .tablet\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .tablet\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .tablet\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .tablet\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .tablet\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .tablet\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .tablet\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .tablet\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .tablet\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .tablet\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .tablet\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .tablet\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .tablet\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .tablet\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .tablet\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .tablet\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .tablet\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .tablet\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .tablet\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .tablet\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .tablet\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .tablet\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .tablet\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .tablet\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .tablet\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .tablet\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .tablet\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .tablet\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .tablet\:transition-none {
    transition-property: none;
  }

  .tablet\:transition-all {
    transition-property: all;
  }

  .tablet\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .tablet\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .tablet\:transition-opacity {
    transition-property: opacity;
  }

  .tablet\:transition-shadow {
    transition-property: box-shadow;
  }

  .tablet\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .tablet\:ease-linear {
    transition-timing-function: linear;
  }

  .tablet\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .tablet\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .tablet\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .tablet\:duration-75 {
    transition-duration: 75ms;
  }

  .tablet\:duration-100 {
    transition-duration: 100ms;
  }

  .tablet\:duration-150 {
    transition-duration: 150ms;
  }

  .tablet\:duration-200 {
    transition-duration: 200ms;
  }

  .tablet\:duration-300 {
    transition-duration: 300ms;
  }

  .tablet\:duration-500 {
    transition-duration: 500ms;
  }

  .tablet\:duration-700 {
    transition-duration: 700ms;
  }

  .tablet\:duration-1000 {
    transition-duration: 1000ms;
  }

  .tablet\:delay-75 {
    transition-delay: 75ms;
  }

  .tablet\:delay-100 {
    transition-delay: 100ms;
  }

  .tablet\:delay-150 {
    transition-delay: 150ms;
  }

  .tablet\:delay-200 {
    transition-delay: 200ms;
  }

  .tablet\:delay-300 {
    transition-delay: 300ms;
  }

  .tablet\:delay-500 {
    transition-delay: 500ms;
  }

  .tablet\:delay-700 {
    transition-delay: 700ms;
  }

  .tablet\:delay-1000 {
    transition-delay: 1000ms;
  }

  .tablet\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .tablet\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .tablet\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .tablet\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .tablet\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}

@media (min-width: 1024px) {
  .desktop\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .desktop\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .desktop\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .desktop\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .desktop\:container {
      max-width: 1408px;
    }
  }

  .desktop\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .desktop\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .desktop\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .desktop\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .desktop\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .desktop\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .desktop\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .desktop\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .desktop\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .desktop\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .desktop\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .desktop\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .desktop\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .desktop\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .desktop\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .desktop\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .desktop\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .desktop\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .desktop\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .desktop\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .desktop\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .desktop\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .desktop\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .desktop\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .desktop\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .desktop\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .desktop\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .desktop\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .desktop\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .desktop\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .desktop\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .desktop\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .desktop\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .desktop\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .desktop\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .desktop\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .desktop\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .desktop\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .desktop\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .desktop\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .desktop\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .desktop\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .desktop\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .desktop\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .desktop\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .desktop\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .desktop\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .desktop\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .desktop\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .desktop\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .desktop\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .desktop\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .desktop\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .desktop\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .desktop\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .desktop\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .desktop\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .desktop\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .desktop\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .desktop\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .desktop\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .desktop\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .desktop\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .desktop\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .desktop\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .desktop\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .desktop\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .desktop\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .desktop\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .desktop\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .desktop\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .desktop\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .desktop\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .desktop\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .desktop\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .desktop\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .desktop\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .desktop\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .desktop\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .desktop\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .desktop\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .desktop\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .desktop\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .desktop\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .desktop\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .desktop\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .desktop\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .desktop\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .desktop\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .desktop\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .desktop\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .desktop\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .desktop\:bg-fixed {
    background-attachment: fixed;
  }

  .desktop\:bg-local {
    background-attachment: local;
  }

  .desktop\:bg-scroll {
    background-attachment: scroll;
  }

  .desktop\:bg-clip-border {
    background-clip: border-box;
  }

  .desktop\:bg-clip-padding {
    background-clip: padding-box;
  }

  .desktop\:bg-clip-content {
    background-clip: content-box;
  }

  .desktop\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .desktop\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .desktop\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .desktop\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .desktop\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .desktop\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .desktop\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .desktop\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .desktop\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .desktop\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .desktop\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .desktop\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .desktop\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .desktop\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .desktop\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .desktop\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .desktop\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .desktop\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .desktop\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .desktop\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .desktop\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .desktop\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .desktop\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .desktop\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .desktop\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .desktop\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .desktop\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .desktop\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .desktop\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .desktop\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .desktop\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .desktop\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .desktop\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .desktop\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .desktop\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .desktop\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .desktop\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .desktop\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .desktop\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .desktop\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .desktop\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .desktop\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .desktop\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .desktop\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .desktop\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .desktop\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .desktop\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .desktop\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .desktop\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .desktop\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .desktop\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .desktop\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .desktop\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .desktop\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .desktop\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .desktop\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .desktop\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .desktop\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .desktop\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .desktop\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .desktop\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .desktop\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .desktop\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .desktop\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .desktop\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .desktop\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .desktop\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .desktop\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .desktop\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .desktop\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .desktop\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .desktop\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .desktop\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .desktop\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .desktop\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .desktop\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .desktop\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .desktop\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .desktop\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .desktop\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .desktop\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .desktop\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .desktop\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .desktop\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .desktop\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .desktop\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .desktop\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .desktop\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .desktop\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .desktop\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .desktop\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .desktop\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .desktop\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .desktop\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .desktop\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .desktop\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .desktop\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .desktop\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .desktop\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .desktop\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .desktop\:bg-none {
    background-image: none;
  }

  .desktop\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .desktop\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .desktop\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .desktop\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .desktop\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .desktop\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .desktop\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .desktop\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .desktop\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .desktop\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .desktop\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .desktop\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .desktop\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .desktop\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .desktop\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .desktop\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .desktop\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .desktop\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .desktop\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .desktop\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .desktop\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .desktop\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .desktop\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .desktop\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .desktop\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .desktop\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .desktop\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .desktop\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .desktop\:to-red {
    --gradient-to-color: #ff8274;
  }

  .desktop\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .desktop\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .desktop\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .desktop\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .desktop\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .desktop\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .desktop\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .desktop\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .desktop\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .desktop\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .desktop\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .desktop\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .desktop\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .desktop\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .desktop\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .desktop\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .desktop\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .desktop\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .desktop\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .desktop\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .desktop\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .desktop\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .desktop\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .desktop\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .desktop\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .desktop\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .desktop\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .desktop\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .desktop\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .desktop\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .desktop\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .desktop\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .desktop\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .desktop\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .desktop\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .desktop\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .desktop\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .desktop\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .desktop\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .desktop\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .desktop\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .desktop\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .desktop\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .desktop\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .desktop\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .desktop\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .desktop\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .desktop\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .desktop\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .desktop\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .desktop\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .desktop\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .desktop\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .desktop\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .desktop\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .desktop\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .desktop\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .desktop\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .desktop\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .desktop\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .desktop\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .desktop\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .desktop\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .desktop\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .desktop\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .desktop\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .desktop\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .desktop\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .desktop\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .desktop\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .desktop\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .desktop\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .desktop\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .desktop\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .desktop\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .desktop\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .desktop\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .desktop\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .desktop\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .desktop\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .desktop\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .desktop\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .desktop\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .desktop\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .desktop\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .desktop\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .desktop\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .desktop\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .desktop\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .desktop\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .desktop\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .desktop\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .desktop\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .desktop\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .desktop\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .desktop\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .desktop\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .desktop\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .desktop\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .desktop\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .desktop\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .desktop\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .desktop\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .desktop\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .desktop\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .desktop\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .desktop\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .desktop\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .desktop\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .desktop\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .desktop\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .desktop\:bg-bottom {
    background-position: bottom;
  }

  .desktop\:bg-center {
    background-position: center;
  }

  .desktop\:bg-left {
    background-position: left;
  }

  .desktop\:bg-left-bottom {
    background-position: left bottom;
  }

  .desktop\:bg-left-top {
    background-position: left top;
  }

  .desktop\:bg-right {
    background-position: right;
  }

  .desktop\:bg-right-bottom {
    background-position: right bottom;
  }

  .desktop\:bg-right-top {
    background-position: right top;
  }

  .desktop\:bg-top {
    background-position: top;
  }

  .desktop\:bg-repeat {
    background-repeat: repeat;
  }

  .desktop\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .desktop\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .desktop\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .desktop\:bg-repeat-round {
    background-repeat: round;
  }

  .desktop\:bg-repeat-space {
    background-repeat: space;
  }

  .desktop\:bg-auto {
    background-size: auto;
  }

  .desktop\:bg-cover {
    background-size: cover;
  }

  .desktop\:bg-contain {
    background-size: contain;
  }

  .desktop\:border-collapse {
    border-collapse: collapse;
  }

  .desktop\:border-separate {
    border-collapse: separate;
  }

  .desktop\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .desktop\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .desktop\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .desktop\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .desktop\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .desktop\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .desktop\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .desktop\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .desktop\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .desktop\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .desktop\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .desktop\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .desktop\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .desktop\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .desktop\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .desktop\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .desktop\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .desktop\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .desktop\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .desktop\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .desktop\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .desktop\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .desktop\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .desktop\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .desktop\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .desktop\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .desktop\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .desktop\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .desktop\:border-state-danger {
    border-color: #FF8274B3;
  }

  .desktop\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .desktop\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .desktop\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .desktop\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .desktop\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .desktop\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .desktop\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .desktop\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .desktop\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .desktop\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .desktop\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .desktop\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .desktop\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .desktop\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .desktop\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .desktop\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .desktop\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .desktop\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .desktop\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .desktop\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .desktop\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .desktop\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .desktop\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .desktop\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .desktop\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .desktop\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .desktop\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .desktop\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .desktop\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .desktop\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .desktop\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .desktop\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .desktop\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .desktop\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .desktop\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .desktop\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .desktop\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .desktop\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .desktop\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .desktop\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .desktop\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .desktop\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .desktop\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .desktop\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .desktop\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .desktop\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .desktop\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .desktop\:border-opacity-0 {
    --border-opacity: 0;
  }

  .desktop\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .desktop\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .desktop\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .desktop\:border-opacity-100 {
    --border-opacity: 1;
  }

  .desktop\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .desktop\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .desktop\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .desktop\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .desktop\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .desktop\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .desktop\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .desktop\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .desktop\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .desktop\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .desktop\:rounded-none {
    border-radius: 0;
  }

  .desktop\:rounded-sm {
    border-radius: 0.125rem;
  }

  .desktop\:rounded {
    border-radius: 0.25rem;
  }

  .desktop\:rounded-md {
    border-radius: 0.375rem;
  }

  .desktop\:rounded-lg {
    border-radius: 0.5rem;
  }

  .desktop\:rounded-xl {
    border-radius: 0.75rem;
  }

  .desktop\:rounded-2xl {
    border-radius: 1rem;
  }

  .desktop\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .desktop\:rounded-full {
    border-radius: 9999px;
  }

  .desktop\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .desktop\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .desktop\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .desktop\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .desktop\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .desktop\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .desktop\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .desktop\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .desktop\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .desktop\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .desktop\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .desktop\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .desktop\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .desktop\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .desktop\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .desktop\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .desktop\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .desktop\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .desktop\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .desktop\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .desktop\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .desktop\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .desktop\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .desktop\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .desktop\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .desktop\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .desktop\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .desktop\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .desktop\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .desktop\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .desktop\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .desktop\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .desktop\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .desktop\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .desktop\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .desktop\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .desktop\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .desktop\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .desktop\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .desktop\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .desktop\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .desktop\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .desktop\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .desktop\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .desktop\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .desktop\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .desktop\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .desktop\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .desktop\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .desktop\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .desktop\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .desktop\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .desktop\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .desktop\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .desktop\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .desktop\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .desktop\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .desktop\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .desktop\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .desktop\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .desktop\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .desktop\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .desktop\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .desktop\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .desktop\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .desktop\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .desktop\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .desktop\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .desktop\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .desktop\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .desktop\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .desktop\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .desktop\:border-solid {
    border-style: solid;
  }

  .desktop\:border-dashed {
    border-style: dashed;
  }

  .desktop\:border-dotted {
    border-style: dotted;
  }

  .desktop\:border-double {
    border-style: double;
  }

  .desktop\:border-none {
    border-style: none;
  }

  .desktop\:border-0 {
    border-width: 0;
  }

  .desktop\:border-2 {
    border-width: 2px;
  }

  .desktop\:border-4 {
    border-width: 4px;
  }

  .desktop\:border-8 {
    border-width: 8px;
  }

  .desktop\:border {
    border-width: 1px;
  }

  .desktop\:border-t-0 {
    border-top-width: 0;
  }

  .desktop\:border-r-0 {
    border-right-width: 0;
  }

  .desktop\:border-b-0 {
    border-bottom-width: 0;
  }

  .desktop\:border-l-0 {
    border-left-width: 0;
  }

  .desktop\:border-t-2 {
    border-top-width: 2px;
  }

  .desktop\:border-r-2 {
    border-right-width: 2px;
  }

  .desktop\:border-b-2 {
    border-bottom-width: 2px;
  }

  .desktop\:border-l-2 {
    border-left-width: 2px;
  }

  .desktop\:border-t-4 {
    border-top-width: 4px;
  }

  .desktop\:border-r-4 {
    border-right-width: 4px;
  }

  .desktop\:border-b-4 {
    border-bottom-width: 4px;
  }

  .desktop\:border-l-4 {
    border-left-width: 4px;
  }

  .desktop\:border-t-8 {
    border-top-width: 8px;
  }

  .desktop\:border-r-8 {
    border-right-width: 8px;
  }

  .desktop\:border-b-8 {
    border-bottom-width: 8px;
  }

  .desktop\:border-l-8 {
    border-left-width: 8px;
  }

  .desktop\:border-t {
    border-top-width: 1px;
  }

  .desktop\:border-r {
    border-right-width: 1px;
  }

  .desktop\:border-b {
    border-bottom-width: 1px;
  }

  .desktop\:border-l {
    border-left-width: 1px;
  }

  .desktop\:hover\:border-0:hover {
    border-width: 0;
  }

  .desktop\:hover\:border-2:hover {
    border-width: 2px;
  }

  .desktop\:hover\:border-4:hover {
    border-width: 4px;
  }

  .desktop\:hover\:border-8:hover {
    border-width: 8px;
  }

  .desktop\:hover\:border:hover {
    border-width: 1px;
  }

  .desktop\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .desktop\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .desktop\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .desktop\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .desktop\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .desktop\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .desktop\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .desktop\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .desktop\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .desktop\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .desktop\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .desktop\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .desktop\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .desktop\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .desktop\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .desktop\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .desktop\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .desktop\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .desktop\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .desktop\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .desktop\:focus\:border-0:focus {
    border-width: 0;
  }

  .desktop\:focus\:border-2:focus {
    border-width: 2px;
  }

  .desktop\:focus\:border-4:focus {
    border-width: 4px;
  }

  .desktop\:focus\:border-8:focus {
    border-width: 8px;
  }

  .desktop\:focus\:border:focus {
    border-width: 1px;
  }

  .desktop\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .desktop\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .desktop\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .desktop\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .desktop\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .desktop\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .desktop\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .desktop\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .desktop\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .desktop\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .desktop\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .desktop\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .desktop\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .desktop\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .desktop\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .desktop\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .desktop\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .desktop\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .desktop\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .desktop\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .desktop\:box-border {
    box-sizing: border-box;
  }

  .desktop\:box-content {
    box-sizing: content-box;
  }

  .desktop\:cursor-auto {
    cursor: auto;
  }

  .desktop\:cursor-default {
    cursor: default;
  }

  .desktop\:cursor-pointer {
    cursor: pointer;
  }

  .desktop\:cursor-wait {
    cursor: wait;
  }

  .desktop\:cursor-text {
    cursor: text;
  }

  .desktop\:cursor-move {
    cursor: move;
  }

  .desktop\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .desktop\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .desktop\:hover\:cursor-default:hover {
    cursor: default;
  }

  .desktop\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .desktop\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .desktop\:hover\:cursor-text:hover {
    cursor: text;
  }

  .desktop\:hover\:cursor-move:hover {
    cursor: move;
  }

  .desktop\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .desktop\:block {
    display: block;
  }

  .desktop\:inline-block {
    display: inline-block;
  }

  .desktop\:inline {
    display: inline;
  }

  .desktop\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .desktop\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .desktop\:table {
    display: table;
  }

  .desktop\:table-caption {
    display: table-caption;
  }

  .desktop\:table-cell {
    display: table-cell;
  }

  .desktop\:table-column {
    display: table-column;
  }

  .desktop\:table-column-group {
    display: table-column-group;
  }

  .desktop\:table-footer-group {
    display: table-footer-group;
  }

  .desktop\:table-header-group {
    display: table-header-group;
  }

  .desktop\:table-row-group {
    display: table-row-group;
  }

  .desktop\:table-row {
    display: table-row;
  }

  .desktop\:flow-root {
    display: flow-root;
  }

  .desktop\:grid {
    display: grid;
  }

  .desktop\:inline-grid {
    display: inline-grid;
  }

  .desktop\:contents {
    display: contents;
  }

  .desktop\:hidden {
    display: none;
  }

  .desktop\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .desktop\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .desktop\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .desktop\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .desktop\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .desktop\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .desktop\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .desktop\:place-items-auto {
    place-items: auto;
  }

  .desktop\:place-items-start {
    place-items: start;
  }

  .desktop\:place-items-end {
    place-items: end;
  }

  .desktop\:place-items-center {
    place-items: center;
  }

  .desktop\:place-items-stretch {
    place-items: stretch;
  }

  .desktop\:place-content-center {
    place-content: center;
  }

  .desktop\:place-content-start {
    place-content: start;
  }

  .desktop\:place-content-end {
    place-content: end;
  }

  .desktop\:place-content-between {
    place-content: space-between;
  }

  .desktop\:place-content-around {
    place-content: space-around;
  }

  .desktop\:place-content-evenly {
    place-content: space-evenly;
  }

  .desktop\:place-content-stretch {
    place-content: stretch;
  }

  .desktop\:place-self-auto {
    place-self: auto;
  }

  .desktop\:place-self-start {
    place-self: start;
  }

  .desktop\:place-self-end {
    place-self: end;
  }

  .desktop\:place-self-center {
    place-self: center;
  }

  .desktop\:place-self-stretch {
    place-self: stretch;
  }

  .desktop\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .desktop\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .desktop\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .desktop\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .desktop\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .desktop\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .desktop\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .desktop\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .desktop\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .desktop\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .desktop\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .desktop\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .desktop\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .desktop\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .desktop\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .desktop\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .desktop\:justify-items-auto {
    justify-items: auto;
  }

  .desktop\:justify-items-start {
    justify-items: start;
  }

  .desktop\:justify-items-end {
    justify-items: end;
  }

  .desktop\:justify-items-center {
    justify-items: center;
  }

  .desktop\:justify-items-stretch {
    justify-items: stretch;
  }

  .desktop\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .desktop\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .desktop\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .desktop\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .desktop\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .desktop\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .desktop\:justify-self-auto {
    justify-self: auto;
  }

  .desktop\:justify-self-start {
    justify-self: start;
  }

  .desktop\:justify-self-end {
    justify-self: end;
  }

  .desktop\:justify-self-center {
    justify-self: center;
  }

  .desktop\:justify-self-stretch {
    justify-self: stretch;
  }

  .desktop\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .desktop\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .desktop\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .desktop\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .desktop\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .desktop\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .desktop\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .desktop\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .desktop\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .desktop\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .desktop\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .desktop\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .desktop\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .desktop\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .desktop\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .desktop\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .desktop\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .desktop\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .desktop\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .desktop\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .desktop\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .desktop\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .desktop\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .desktop\:float-right {
    float: right;
  }

  .desktop\:float-left {
    float: left;
  }

  .desktop\:float-none {
    float: none;
  }

  .desktop\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .desktop\:clear-left {
    clear: left;
  }

  .desktop\:clear-right {
    clear: right;
  }

  .desktop\:clear-both {
    clear: both;
  }

  .desktop\:clear-none {
    clear: none;
  }

  .desktop\:font-hairline {
    font-weight: 100;
  }

  .desktop\:font-thin {
    font-weight: 200;
  }

  .desktop\:font-light {
    font-weight: 300;
  }

  .desktop\:font-normal {
    font-weight: 400;
  }

  .desktop\:font-medium {
    font-weight: 500;
  }

  .desktop\:font-semibold {
    font-weight: 600;
  }

  .desktop\:font-bold {
    font-weight: 700;
  }

  .desktop\:font-extrabold {
    font-weight: 800;
  }

  .desktop\:font-black {
    font-weight: 900;
  }

  .desktop\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .desktop\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .desktop\:hover\:font-light:hover {
    font-weight: 300;
  }

  .desktop\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .desktop\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .desktop\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .desktop\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .desktop\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .desktop\:hover\:font-black:hover {
    font-weight: 900;
  }

  .desktop\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .desktop\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .desktop\:focus\:font-light:focus {
    font-weight: 300;
  }

  .desktop\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .desktop\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .desktop\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .desktop\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .desktop\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .desktop\:focus\:font-black:focus {
    font-weight: 900;
  }

  .desktop\:h-1 {
    height: 5px;
  }

  .desktop\:h-2 {
    height: 10px;
  }

  .desktop\:h-3 {
    height: 15px;
  }

  .desktop\:h-4 {
    height: 30px;
  }

  .desktop\:h-5 {
    height: 50px;
  }

  .desktop\:h-6 {
    height: 75px;
  }

  .desktop\:h-7 {
    height: 100px;
  }

  .desktop\:h-logo-height-small {
    height: 55px;
  }

  .desktop\:h-logo-height-large {
    height: 65px;
  }

  .desktop\:leading-3 {
    line-height: .75rem;
  }

  .desktop\:leading-4 {
    line-height: 1rem;
  }

  .desktop\:leading-5 {
    line-height: 1.25rem;
  }

  .desktop\:leading-6 {
    line-height: 1.5rem;
  }

  .desktop\:leading-7 {
    line-height: 1.75rem;
  }

  .desktop\:leading-8 {
    line-height: 2rem;
  }

  .desktop\:leading-9 {
    line-height: 2.25rem;
  }

  .desktop\:leading-10 {
    line-height: 2.5rem;
  }

  .desktop\:leading-none {
    line-height: 1;
  }

  .desktop\:leading-tight {
    line-height: 1.25;
  }

  .desktop\:leading-snug {
    line-height: 1.375;
  }

  .desktop\:leading-normal {
    line-height: 1.5;
  }

  .desktop\:leading-relaxed {
    line-height: 1.625;
  }

  .desktop\:leading-loose {
    line-height: 2;
  }

  .desktop\:list-inside {
    list-style-position: inside;
  }

  .desktop\:list-outside {
    list-style-position: outside;
  }

  .desktop\:list-none {
    list-style-type: none;
  }

  .desktop\:list-disc {
    list-style-type: disc;
  }

  .desktop\:list-decimal {
    list-style-type: decimal;
  }

  .desktop\:m-1 {
    margin: 5px;
  }

  .desktop\:m-2 {
    margin: 10px;
  }

  .desktop\:m-3 {
    margin: 15px;
  }

  .desktop\:m-4 {
    margin: 30px;
  }

  .desktop\:m-5 {
    margin: 50px;
  }

  .desktop\:m-6 {
    margin: 75px;
  }

  .desktop\:m-7 {
    margin: 100px;
  }

  .desktop\:m-auto {
    margin: auto;
  }

  .desktop\:m-none {
    margin: 0px;
  }

  .desktop\:m-tiny {
    margin: 10px;
  }

  .desktop\:m-small {
    margin: 20px;
  }

  .desktop\:m-medium {
    margin: 30px;
  }

  .desktop\:m-large {
    margin: 40px;
  }

  .desktop\:m-huge {
    margin: 60px;
  }

  .desktop\:m-margin-label-height {
    margin: 26px;
  }

  .desktop\:m-nav-height {
    margin: 80px;
  }

  .desktop\:-m-1 {
    margin: -5px;
  }

  .desktop\:-m-2 {
    margin: -10px;
  }

  .desktop\:-m-3 {
    margin: -15px;
  }

  .desktop\:-m-4 {
    margin: -30px;
  }

  .desktop\:-m-5 {
    margin: -50px;
  }

  .desktop\:-m-6 {
    margin: -75px;
  }

  .desktop\:-m-7 {
    margin: -100px;
  }

  .desktop\:-m-none {
    margin: 0px;
  }

  .desktop\:-m-tiny {
    margin: -10px;
  }

  .desktop\:-m-small {
    margin: -20px;
  }

  .desktop\:-m-medium {
    margin: -30px;
  }

  .desktop\:-m-large {
    margin: -40px;
  }

  .desktop\:-m-huge {
    margin: -60px;
  }

  .desktop\:-m-margin-label-height {
    margin: -26px;
  }

  .desktop\:-m-nav-height {
    margin: -80px;
  }

  .desktop\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:mt-1 {
    margin-top: 5px;
  }

  .desktop\:mr-1 {
    margin-right: 5px;
  }

  .desktop\:mb-1 {
    margin-bottom: 5px;
  }

  .desktop\:ml-1 {
    margin-left: 5px;
  }

  .desktop\:mt-2 {
    margin-top: 10px;
  }

  .desktop\:mr-2 {
    margin-right: 10px;
  }

  .desktop\:mb-2 {
    margin-bottom: 10px;
  }

  .desktop\:ml-2 {
    margin-left: 10px;
  }

  .desktop\:mt-3 {
    margin-top: 15px;
  }

  .desktop\:mr-3 {
    margin-right: 15px;
  }

  .desktop\:mb-3 {
    margin-bottom: 15px;
  }

  .desktop\:ml-3 {
    margin-left: 15px;
  }

  .desktop\:mt-4 {
    margin-top: 30px;
  }

  .desktop\:mr-4 {
    margin-right: 30px;
  }

  .desktop\:mb-4 {
    margin-bottom: 30px;
  }

  .desktop\:ml-4 {
    margin-left: 30px;
  }

  .desktop\:mt-5 {
    margin-top: 50px;
  }

  .desktop\:mr-5 {
    margin-right: 50px;
  }

  .desktop\:mb-5 {
    margin-bottom: 50px;
  }

  .desktop\:ml-5 {
    margin-left: 50px;
  }

  .desktop\:mt-6 {
    margin-top: 75px;
  }

  .desktop\:mr-6 {
    margin-right: 75px;
  }

  .desktop\:mb-6 {
    margin-bottom: 75px;
  }

  .desktop\:ml-6 {
    margin-left: 75px;
  }

  .desktop\:mt-7 {
    margin-top: 100px;
  }

  .desktop\:mr-7 {
    margin-right: 100px;
  }

  .desktop\:mb-7 {
    margin-bottom: 100px;
  }

  .desktop\:ml-7 {
    margin-left: 100px;
  }

  .desktop\:mt-auto {
    margin-top: auto;
  }

  .desktop\:mr-auto {
    margin-right: auto;
  }

  .desktop\:mb-auto {
    margin-bottom: auto;
  }

  .desktop\:ml-auto {
    margin-left: auto;
  }

  .desktop\:mt-none {
    margin-top: 0px;
  }

  .desktop\:mr-none {
    margin-right: 0px;
  }

  .desktop\:mb-none {
    margin-bottom: 0px;
  }

  .desktop\:ml-none {
    margin-left: 0px;
  }

  .desktop\:mt-tiny {
    margin-top: 10px;
  }

  .desktop\:mr-tiny {
    margin-right: 10px;
  }

  .desktop\:mb-tiny {
    margin-bottom: 10px;
  }

  .desktop\:ml-tiny {
    margin-left: 10px;
  }

  .desktop\:mt-small {
    margin-top: 20px;
  }

  .desktop\:mr-small {
    margin-right: 20px;
  }

  .desktop\:mb-small {
    margin-bottom: 20px;
  }

  .desktop\:ml-small {
    margin-left: 20px;
  }

  .desktop\:mt-medium {
    margin-top: 30px;
  }

  .desktop\:mr-medium {
    margin-right: 30px;
  }

  .desktop\:mb-medium {
    margin-bottom: 30px;
  }

  .desktop\:ml-medium {
    margin-left: 30px;
  }

  .desktop\:mt-large {
    margin-top: 40px;
  }

  .desktop\:mr-large {
    margin-right: 40px;
  }

  .desktop\:mb-large {
    margin-bottom: 40px;
  }

  .desktop\:ml-large {
    margin-left: 40px;
  }

  .desktop\:mt-huge {
    margin-top: 60px;
  }

  .desktop\:mr-huge {
    margin-right: 60px;
  }

  .desktop\:mb-huge {
    margin-bottom: 60px;
  }

  .desktop\:ml-huge {
    margin-left: 60px;
  }

  .desktop\:mt-margin-label-height {
    margin-top: 26px;
  }

  .desktop\:mr-margin-label-height {
    margin-right: 26px;
  }

  .desktop\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .desktop\:ml-margin-label-height {
    margin-left: 26px;
  }

  .desktop\:mt-nav-height {
    margin-top: 80px;
  }

  .desktop\:mr-nav-height {
    margin-right: 80px;
  }

  .desktop\:mb-nav-height {
    margin-bottom: 80px;
  }

  .desktop\:ml-nav-height {
    margin-left: 80px;
  }

  .desktop\:-mt-1 {
    margin-top: -5px;
  }

  .desktop\:-mr-1 {
    margin-right: -5px;
  }

  .desktop\:-mb-1 {
    margin-bottom: -5px;
  }

  .desktop\:-ml-1 {
    margin-left: -5px;
  }

  .desktop\:-mt-2 {
    margin-top: -10px;
  }

  .desktop\:-mr-2 {
    margin-right: -10px;
  }

  .desktop\:-mb-2 {
    margin-bottom: -10px;
  }

  .desktop\:-ml-2 {
    margin-left: -10px;
  }

  .desktop\:-mt-3 {
    margin-top: -15px;
  }

  .desktop\:-mr-3 {
    margin-right: -15px;
  }

  .desktop\:-mb-3 {
    margin-bottom: -15px;
  }

  .desktop\:-ml-3 {
    margin-left: -15px;
  }

  .desktop\:-mt-4 {
    margin-top: -30px;
  }

  .desktop\:-mr-4 {
    margin-right: -30px;
  }

  .desktop\:-mb-4 {
    margin-bottom: -30px;
  }

  .desktop\:-ml-4 {
    margin-left: -30px;
  }

  .desktop\:-mt-5 {
    margin-top: -50px;
  }

  .desktop\:-mr-5 {
    margin-right: -50px;
  }

  .desktop\:-mb-5 {
    margin-bottom: -50px;
  }

  .desktop\:-ml-5 {
    margin-left: -50px;
  }

  .desktop\:-mt-6 {
    margin-top: -75px;
  }

  .desktop\:-mr-6 {
    margin-right: -75px;
  }

  .desktop\:-mb-6 {
    margin-bottom: -75px;
  }

  .desktop\:-ml-6 {
    margin-left: -75px;
  }

  .desktop\:-mt-7 {
    margin-top: -100px;
  }

  .desktop\:-mr-7 {
    margin-right: -100px;
  }

  .desktop\:-mb-7 {
    margin-bottom: -100px;
  }

  .desktop\:-ml-7 {
    margin-left: -100px;
  }

  .desktop\:-mt-none {
    margin-top: 0px;
  }

  .desktop\:-mr-none {
    margin-right: 0px;
  }

  .desktop\:-mb-none {
    margin-bottom: 0px;
  }

  .desktop\:-ml-none {
    margin-left: 0px;
  }

  .desktop\:-mt-tiny {
    margin-top: -10px;
  }

  .desktop\:-mr-tiny {
    margin-right: -10px;
  }

  .desktop\:-mb-tiny {
    margin-bottom: -10px;
  }

  .desktop\:-ml-tiny {
    margin-left: -10px;
  }

  .desktop\:-mt-small {
    margin-top: -20px;
  }

  .desktop\:-mr-small {
    margin-right: -20px;
  }

  .desktop\:-mb-small {
    margin-bottom: -20px;
  }

  .desktop\:-ml-small {
    margin-left: -20px;
  }

  .desktop\:-mt-medium {
    margin-top: -30px;
  }

  .desktop\:-mr-medium {
    margin-right: -30px;
  }

  .desktop\:-mb-medium {
    margin-bottom: -30px;
  }

  .desktop\:-ml-medium {
    margin-left: -30px;
  }

  .desktop\:-mt-large {
    margin-top: -40px;
  }

  .desktop\:-mr-large {
    margin-right: -40px;
  }

  .desktop\:-mb-large {
    margin-bottom: -40px;
  }

  .desktop\:-ml-large {
    margin-left: -40px;
  }

  .desktop\:-mt-huge {
    margin-top: -60px;
  }

  .desktop\:-mr-huge {
    margin-right: -60px;
  }

  .desktop\:-mb-huge {
    margin-bottom: -60px;
  }

  .desktop\:-ml-huge {
    margin-left: -60px;
  }

  .desktop\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .desktop\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .desktop\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .desktop\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .desktop\:-mt-nav-height {
    margin-top: -80px;
  }

  .desktop\:-mr-nav-height {
    margin-right: -80px;
  }

  .desktop\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .desktop\:-ml-nav-height {
    margin-left: -80px;
  }

  .desktop\:hover\:m-1:hover {
    margin: 5px;
  }

  .desktop\:hover\:m-2:hover {
    margin: 10px;
  }

  .desktop\:hover\:m-3:hover {
    margin: 15px;
  }

  .desktop\:hover\:m-4:hover {
    margin: 30px;
  }

  .desktop\:hover\:m-5:hover {
    margin: 50px;
  }

  .desktop\:hover\:m-6:hover {
    margin: 75px;
  }

  .desktop\:hover\:m-7:hover {
    margin: 100px;
  }

  .desktop\:hover\:m-auto:hover {
    margin: auto;
  }

  .desktop\:hover\:m-none:hover {
    margin: 0px;
  }

  .desktop\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .desktop\:hover\:m-small:hover {
    margin: 20px;
  }

  .desktop\:hover\:m-medium:hover {
    margin: 30px;
  }

  .desktop\:hover\:m-large:hover {
    margin: 40px;
  }

  .desktop\:hover\:m-huge:hover {
    margin: 60px;
  }

  .desktop\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .desktop\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .desktop\:hover\:-m-1:hover {
    margin: -5px;
  }

  .desktop\:hover\:-m-2:hover {
    margin: -10px;
  }

  .desktop\:hover\:-m-3:hover {
    margin: -15px;
  }

  .desktop\:hover\:-m-4:hover {
    margin: -30px;
  }

  .desktop\:hover\:-m-5:hover {
    margin: -50px;
  }

  .desktop\:hover\:-m-6:hover {
    margin: -75px;
  }

  .desktop\:hover\:-m-7:hover {
    margin: -100px;
  }

  .desktop\:hover\:-m-none:hover {
    margin: 0px;
  }

  .desktop\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .desktop\:hover\:-m-small:hover {
    margin: -20px;
  }

  .desktop\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .desktop\:hover\:-m-large:hover {
    margin: -40px;
  }

  .desktop\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .desktop\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .desktop\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .desktop\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .desktop\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .desktop\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .desktop\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .desktop\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .desktop\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .desktop\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .desktop\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .desktop\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .desktop\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .desktop\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .desktop\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .desktop\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .desktop\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .desktop\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .desktop\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .desktop\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .desktop\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .desktop\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .desktop\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .desktop\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .desktop\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .desktop\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .desktop\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .desktop\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .desktop\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .desktop\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .desktop\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .desktop\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .desktop\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .desktop\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .desktop\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .desktop\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .desktop\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .desktop\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .desktop\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .desktop\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .desktop\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .desktop\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .desktop\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .desktop\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .desktop\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .desktop\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .desktop\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .desktop\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .desktop\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .desktop\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .desktop\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .desktop\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .desktop\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .desktop\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .desktop\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .desktop\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .desktop\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .desktop\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .desktop\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .desktop\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .desktop\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .desktop\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .desktop\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .desktop\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .desktop\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .desktop\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .desktop\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .desktop\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .desktop\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .desktop\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .desktop\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .desktop\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .desktop\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .desktop\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .desktop\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .desktop\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .desktop\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .desktop\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .desktop\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .desktop\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .desktop\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .desktop\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .desktop\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .desktop\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .desktop\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .desktop\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .desktop\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .desktop\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .desktop\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .desktop\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .desktop\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .desktop\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .desktop\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .desktop\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .desktop\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .desktop\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .desktop\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .desktop\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .desktop\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .desktop\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .desktop\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .desktop\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .desktop\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .desktop\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .desktop\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .desktop\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .desktop\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .desktop\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .desktop\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .desktop\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .desktop\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .desktop\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .desktop\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .desktop\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .desktop\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .desktop\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .desktop\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .desktop\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .desktop\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .desktop\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .desktop\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .desktop\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .desktop\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .desktop\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .desktop\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .desktop\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .desktop\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .desktop\:focus\:m-1:focus {
    margin: 5px;
  }

  .desktop\:focus\:m-2:focus {
    margin: 10px;
  }

  .desktop\:focus\:m-3:focus {
    margin: 15px;
  }

  .desktop\:focus\:m-4:focus {
    margin: 30px;
  }

  .desktop\:focus\:m-5:focus {
    margin: 50px;
  }

  .desktop\:focus\:m-6:focus {
    margin: 75px;
  }

  .desktop\:focus\:m-7:focus {
    margin: 100px;
  }

  .desktop\:focus\:m-auto:focus {
    margin: auto;
  }

  .desktop\:focus\:m-none:focus {
    margin: 0px;
  }

  .desktop\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .desktop\:focus\:m-small:focus {
    margin: 20px;
  }

  .desktop\:focus\:m-medium:focus {
    margin: 30px;
  }

  .desktop\:focus\:m-large:focus {
    margin: 40px;
  }

  .desktop\:focus\:m-huge:focus {
    margin: 60px;
  }

  .desktop\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .desktop\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .desktop\:focus\:-m-1:focus {
    margin: -5px;
  }

  .desktop\:focus\:-m-2:focus {
    margin: -10px;
  }

  .desktop\:focus\:-m-3:focus {
    margin: -15px;
  }

  .desktop\:focus\:-m-4:focus {
    margin: -30px;
  }

  .desktop\:focus\:-m-5:focus {
    margin: -50px;
  }

  .desktop\:focus\:-m-6:focus {
    margin: -75px;
  }

  .desktop\:focus\:-m-7:focus {
    margin: -100px;
  }

  .desktop\:focus\:-m-none:focus {
    margin: 0px;
  }

  .desktop\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .desktop\:focus\:-m-small:focus {
    margin: -20px;
  }

  .desktop\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .desktop\:focus\:-m-large:focus {
    margin: -40px;
  }

  .desktop\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .desktop\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .desktop\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .desktop\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .desktop\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .desktop\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .desktop\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .desktop\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .desktop\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .desktop\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .desktop\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .desktop\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .desktop\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .desktop\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .desktop\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .desktop\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .desktop\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .desktop\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .desktop\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .desktop\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .desktop\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .desktop\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .desktop\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .desktop\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .desktop\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .desktop\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .desktop\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .desktop\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .desktop\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .desktop\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .desktop\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .desktop\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .desktop\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .desktop\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .desktop\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .desktop\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .desktop\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .desktop\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .desktop\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .desktop\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .desktop\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .desktop\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .desktop\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .desktop\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .desktop\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .desktop\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .desktop\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .desktop\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .desktop\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .desktop\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .desktop\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .desktop\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .desktop\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .desktop\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .desktop\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .desktop\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .desktop\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .desktop\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .desktop\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .desktop\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .desktop\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .desktop\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .desktop\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .desktop\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .desktop\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .desktop\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .desktop\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .desktop\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .desktop\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .desktop\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .desktop\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .desktop\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .desktop\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .desktop\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .desktop\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .desktop\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .desktop\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .desktop\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .desktop\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .desktop\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .desktop\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .desktop\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .desktop\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .desktop\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .desktop\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .desktop\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .desktop\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .desktop\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .desktop\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .desktop\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .desktop\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .desktop\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .desktop\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .desktop\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .desktop\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .desktop\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .desktop\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .desktop\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .desktop\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .desktop\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .desktop\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .desktop\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .desktop\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .desktop\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .desktop\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .desktop\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .desktop\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .desktop\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .desktop\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .desktop\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .desktop\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .desktop\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .desktop\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .desktop\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .desktop\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .desktop\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .desktop\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .desktop\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .desktop\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .desktop\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .desktop\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .desktop\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .desktop\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .desktop\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .desktop\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .desktop\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .desktop\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .desktop\:first\:m-1:first-child {
    margin: 5px;
  }

  .desktop\:first\:m-2:first-child {
    margin: 10px;
  }

  .desktop\:first\:m-3:first-child {
    margin: 15px;
  }

  .desktop\:first\:m-4:first-child {
    margin: 30px;
  }

  .desktop\:first\:m-5:first-child {
    margin: 50px;
  }

  .desktop\:first\:m-6:first-child {
    margin: 75px;
  }

  .desktop\:first\:m-7:first-child {
    margin: 100px;
  }

  .desktop\:first\:m-auto:first-child {
    margin: auto;
  }

  .desktop\:first\:m-none:first-child {
    margin: 0px;
  }

  .desktop\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .desktop\:first\:m-small:first-child {
    margin: 20px;
  }

  .desktop\:first\:m-medium:first-child {
    margin: 30px;
  }

  .desktop\:first\:m-large:first-child {
    margin: 40px;
  }

  .desktop\:first\:m-huge:first-child {
    margin: 60px;
  }

  .desktop\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .desktop\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .desktop\:first\:-m-1:first-child {
    margin: -5px;
  }

  .desktop\:first\:-m-2:first-child {
    margin: -10px;
  }

  .desktop\:first\:-m-3:first-child {
    margin: -15px;
  }

  .desktop\:first\:-m-4:first-child {
    margin: -30px;
  }

  .desktop\:first\:-m-5:first-child {
    margin: -50px;
  }

  .desktop\:first\:-m-6:first-child {
    margin: -75px;
  }

  .desktop\:first\:-m-7:first-child {
    margin: -100px;
  }

  .desktop\:first\:-m-none:first-child {
    margin: 0px;
  }

  .desktop\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .desktop\:first\:-m-small:first-child {
    margin: -20px;
  }

  .desktop\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .desktop\:first\:-m-large:first-child {
    margin: -40px;
  }

  .desktop\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .desktop\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .desktop\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .desktop\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .desktop\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .desktop\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .desktop\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .desktop\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .desktop\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .desktop\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .desktop\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .desktop\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .desktop\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .desktop\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .desktop\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .desktop\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .desktop\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .desktop\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .desktop\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .desktop\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .desktop\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .desktop\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .desktop\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .desktop\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .desktop\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .desktop\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .desktop\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .desktop\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .desktop\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .desktop\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .desktop\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .desktop\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .desktop\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .desktop\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .desktop\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .desktop\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .desktop\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .desktop\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .desktop\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .desktop\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .desktop\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .desktop\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .desktop\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .desktop\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .desktop\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .desktop\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .desktop\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .desktop\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .desktop\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .desktop\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .desktop\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .desktop\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .desktop\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .desktop\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .desktop\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .desktop\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .desktop\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .desktop\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .desktop\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .desktop\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .desktop\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .desktop\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .desktop\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .desktop\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .desktop\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .desktop\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .desktop\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .desktop\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .desktop\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .desktop\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .desktop\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .desktop\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .desktop\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .desktop\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .desktop\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .desktop\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .desktop\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .desktop\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .desktop\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .desktop\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .desktop\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .desktop\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .desktop\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .desktop\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .desktop\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .desktop\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .desktop\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .desktop\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .desktop\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .desktop\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .desktop\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .desktop\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .desktop\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .desktop\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .desktop\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .desktop\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .desktop\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .desktop\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .desktop\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .desktop\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .desktop\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .desktop\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .desktop\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .desktop\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .desktop\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .desktop\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .desktop\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .desktop\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .desktop\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .desktop\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .desktop\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .desktop\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .desktop\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .desktop\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .desktop\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .desktop\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .desktop\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .desktop\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .desktop\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .desktop\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .desktop\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .desktop\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .desktop\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .desktop\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .desktop\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .desktop\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .desktop\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .desktop\:last\:m-1:last-child {
    margin: 5px;
  }

  .desktop\:last\:m-2:last-child {
    margin: 10px;
  }

  .desktop\:last\:m-3:last-child {
    margin: 15px;
  }

  .desktop\:last\:m-4:last-child {
    margin: 30px;
  }

  .desktop\:last\:m-5:last-child {
    margin: 50px;
  }

  .desktop\:last\:m-6:last-child {
    margin: 75px;
  }

  .desktop\:last\:m-7:last-child {
    margin: 100px;
  }

  .desktop\:last\:m-auto:last-child {
    margin: auto;
  }

  .desktop\:last\:m-none:last-child {
    margin: 0px;
  }

  .desktop\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .desktop\:last\:m-small:last-child {
    margin: 20px;
  }

  .desktop\:last\:m-medium:last-child {
    margin: 30px;
  }

  .desktop\:last\:m-large:last-child {
    margin: 40px;
  }

  .desktop\:last\:m-huge:last-child {
    margin: 60px;
  }

  .desktop\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .desktop\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .desktop\:last\:-m-1:last-child {
    margin: -5px;
  }

  .desktop\:last\:-m-2:last-child {
    margin: -10px;
  }

  .desktop\:last\:-m-3:last-child {
    margin: -15px;
  }

  .desktop\:last\:-m-4:last-child {
    margin: -30px;
  }

  .desktop\:last\:-m-5:last-child {
    margin: -50px;
  }

  .desktop\:last\:-m-6:last-child {
    margin: -75px;
  }

  .desktop\:last\:-m-7:last-child {
    margin: -100px;
  }

  .desktop\:last\:-m-none:last-child {
    margin: 0px;
  }

  .desktop\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .desktop\:last\:-m-small:last-child {
    margin: -20px;
  }

  .desktop\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .desktop\:last\:-m-large:last-child {
    margin: -40px;
  }

  .desktop\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .desktop\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .desktop\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .desktop\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .desktop\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .desktop\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .desktop\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .desktop\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .desktop\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .desktop\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .desktop\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .desktop\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .desktop\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .desktop\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .desktop\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .desktop\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .desktop\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .desktop\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .desktop\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .desktop\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .desktop\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .desktop\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .desktop\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .desktop\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .desktop\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .desktop\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .desktop\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .desktop\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .desktop\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .desktop\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .desktop\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .desktop\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .desktop\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .desktop\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .desktop\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .desktop\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .desktop\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .desktop\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .desktop\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .desktop\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .desktop\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .desktop\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .desktop\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .desktop\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .desktop\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .desktop\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .desktop\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .desktop\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .desktop\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .desktop\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .desktop\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .desktop\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .desktop\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .desktop\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .desktop\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .desktop\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .desktop\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .desktop\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .desktop\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .desktop\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .desktop\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .desktop\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .desktop\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .desktop\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .desktop\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .desktop\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .desktop\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .desktop\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .desktop\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .desktop\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .desktop\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .desktop\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .desktop\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .desktop\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .desktop\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .desktop\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .desktop\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .desktop\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .desktop\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .desktop\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .desktop\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .desktop\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .desktop\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .desktop\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .desktop\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .desktop\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .desktop\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .desktop\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .desktop\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .desktop\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .desktop\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .desktop\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .desktop\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .desktop\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .desktop\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .desktop\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .desktop\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .desktop\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .desktop\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .desktop\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .desktop\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .desktop\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .desktop\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .desktop\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .desktop\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .desktop\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .desktop\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .desktop\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .desktop\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .desktop\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .desktop\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .desktop\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .desktop\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .desktop\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .desktop\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .desktop\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .desktop\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .desktop\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .desktop\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .desktop\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .desktop\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .desktop\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .desktop\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .desktop\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .desktop\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .desktop\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .desktop\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .desktop\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .desktop\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .desktop\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .desktop\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .desktop\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .desktop\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .desktop\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .desktop\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .desktop\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .desktop\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .desktop\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .desktop\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .desktop\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .desktop\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .desktop\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .desktop\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .desktop\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .desktop\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .desktop\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .desktop\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .desktop\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .desktop\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .desktop\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .desktop\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .desktop\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .desktop\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .desktop\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .desktop\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .desktop\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .desktop\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .desktop\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .desktop\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .desktop\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .desktop\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .desktop\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .desktop\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .desktop\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .desktop\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .desktop\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .desktop\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .desktop\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .desktop\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .desktop\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .desktop\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .desktop\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .desktop\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .desktop\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .desktop\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .desktop\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .desktop\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .desktop\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .desktop\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .desktop\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .desktop\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .desktop\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .desktop\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .desktop\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .desktop\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .desktop\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .desktop\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .desktop\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .desktop\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .desktop\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .desktop\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .desktop\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .desktop\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .desktop\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .desktop\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .desktop\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .desktop\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .desktop\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .desktop\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .desktop\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .desktop\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .desktop\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .desktop\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .desktop\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .desktop\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .desktop\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .desktop\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .desktop\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .desktop\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .desktop\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .desktop\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .desktop\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .desktop\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .desktop\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .desktop\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .desktop\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .desktop\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .desktop\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .desktop\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .desktop\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .desktop\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .desktop\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .desktop\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .desktop\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .desktop\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .desktop\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .desktop\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .desktop\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .desktop\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .desktop\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .desktop\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .desktop\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .desktop\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .desktop\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .desktop\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .desktop\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .desktop\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .desktop\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .desktop\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .desktop\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .desktop\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .desktop\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .desktop\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .desktop\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .desktop\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .desktop\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .desktop\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .desktop\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .desktop\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .desktop\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .desktop\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .desktop\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .desktop\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .desktop\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .desktop\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .desktop\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .desktop\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .desktop\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .desktop\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .desktop\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .desktop\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .desktop\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .desktop\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .desktop\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .desktop\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .desktop\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .desktop\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .desktop\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .desktop\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .desktop\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .desktop\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .desktop\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .desktop\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .desktop\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .desktop\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .desktop\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .desktop\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .desktop\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .desktop\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .desktop\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .desktop\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .desktop\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .desktop\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .desktop\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .desktop\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .desktop\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .desktop\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .desktop\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .desktop\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .desktop\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .desktop\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .desktop\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .desktop\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .desktop\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .desktop\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .desktop\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .desktop\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .desktop\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .desktop\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .desktop\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .desktop\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .desktop\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .desktop\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .desktop\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .desktop\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .desktop\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .desktop\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .desktop\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .desktop\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .desktop\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .desktop\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .desktop\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .desktop\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .desktop\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .desktop\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .desktop\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .desktop\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .desktop\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .desktop\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .desktop\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .desktop\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .desktop\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .desktop\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .desktop\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .desktop\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .desktop\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .desktop\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .desktop\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desktop\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .desktop\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .desktop\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .desktop\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .desktop\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .desktop\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .desktop\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .desktop\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .desktop\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .desktop\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .desktop\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .desktop\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .desktop\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .desktop\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .desktop\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .desktop\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .desktop\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .desktop\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .desktop\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .desktop\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .desktop\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .desktop\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .desktop\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .desktop\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .desktop\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .desktop\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .desktop\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .desktop\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .desktop\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .desktop\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .desktop\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .desktop\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .desktop\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .desktop\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .desktop\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .desktop\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .desktop\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .desktop\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .desktop\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .desktop\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .desktop\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .desktop\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .desktop\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .desktop\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .desktop\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .desktop\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .desktop\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .desktop\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .desktop\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .desktop\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .desktop\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .desktop\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .desktop\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .desktop\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .desktop\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .desktop\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .desktop\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .desktop\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .desktop\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .desktop\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .desktop\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .desktop\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .desktop\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .desktop\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .desktop\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .desktop\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .desktop\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .desktop\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .desktop\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .desktop\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .desktop\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .desktop\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .desktop\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .desktop\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .desktop\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .desktop\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .desktop\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .desktop\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .desktop\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .desktop\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .desktop\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .desktop\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .desktop\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .desktop\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .desktop\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .desktop\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .desktop\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .desktop\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .desktop\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .desktop\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .desktop\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .desktop\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .desktop\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .desktop\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .desktop\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .desktop\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .desktop\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .desktop\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .desktop\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .desktop\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .desktop\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .desktop\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .desktop\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .desktop\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .desktop\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .desktop\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .desktop\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .desktop\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .desktop\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .desktop\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .desktop\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .desktop\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .desktop\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .desktop\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .desktop\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .desktop\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .desktop\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .desktop\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .desktop\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .desktop\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .desktop\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .desktop\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .desktop\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .desktop\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .desktop\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .desktop\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .desktop\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .desktop\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .desktop\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .desktop\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .desktop\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .desktop\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .desktop\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .desktop\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .desktop\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .desktop\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .desktop\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .desktop\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .desktop\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .desktop\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .desktop\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .desktop\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .desktop\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .desktop\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .desktop\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .desktop\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .desktop\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .desktop\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .desktop\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .desktop\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .desktop\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .desktop\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .desktop\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .desktop\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .desktop\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .desktop\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .desktop\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .desktop\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .desktop\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .desktop\:max-h-full {
    max-height: 100%;
  }

  .desktop\:max-h-screen {
    max-height: 100vh;
  }

  .desktop\:max-w-none {
    max-width: none;
  }

  .desktop\:max-w-xs {
    max-width: 20rem;
  }

  .desktop\:max-w-sm {
    max-width: 24rem;
  }

  .desktop\:max-w-md {
    max-width: 28rem;
  }

  .desktop\:max-w-lg {
    max-width: 32rem;
  }

  .desktop\:max-w-xl {
    max-width: 36rem;
  }

  .desktop\:max-w-2xl {
    max-width: 42rem;
  }

  .desktop\:max-w-3xl {
    max-width: 48rem;
  }

  .desktop\:max-w-4xl {
    max-width: 56rem;
  }

  .desktop\:max-w-5xl {
    max-width: 64rem;
  }

  .desktop\:max-w-6xl {
    max-width: 72rem;
  }

  .desktop\:max-w-full {
    max-width: 100%;
  }

  .desktop\:max-w-screen-tablet {
    max-width: 801px;
  }

  .desktop\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .desktop\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .desktop\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .desktop\:min-h-0 {
    min-height: 0;
  }

  .desktop\:min-h-full {
    min-height: 100%;
  }

  .desktop\:min-h-screen {
    min-height: 100vh;
  }

  .desktop\:min-w-0 {
    min-width: 0;
  }

  .desktop\:min-w-full {
    min-width: 100%;
  }

  .desktop\:object-contain {
    object-fit: contain;
  }

  .desktop\:object-cover {
    object-fit: cover;
  }

  .desktop\:object-fill {
    object-fit: fill;
  }

  .desktop\:object-none {
    object-fit: none;
  }

  .desktop\:object-scale-down {
    object-fit: scale-down;
  }

  .desktop\:object-bottom {
    object-position: bottom;
  }

  .desktop\:object-center {
    object-position: center;
  }

  .desktop\:object-left {
    object-position: left;
  }

  .desktop\:object-left-bottom {
    object-position: left bottom;
  }

  .desktop\:object-left-top {
    object-position: left top;
  }

  .desktop\:object-right {
    object-position: right;
  }

  .desktop\:object-right-bottom {
    object-position: right bottom;
  }

  .desktop\:object-right-top {
    object-position: right top;
  }

  .desktop\:object-top {
    object-position: top;
  }

  .desktop\:opacity-0 {
    opacity: 0;
  }

  .desktop\:opacity-25 {
    opacity: 0.25;
  }

  .desktop\:opacity-50 {
    opacity: 0.5;
  }

  .desktop\:opacity-75 {
    opacity: 0.75;
  }

  .desktop\:opacity-100 {
    opacity: 1;
  }

  .desktop\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .desktop\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .desktop\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .desktop\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .desktop\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .desktop\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .desktop\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .desktop\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .desktop\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .desktop\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .desktop\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .desktop\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .desktop\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .desktop\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .desktop\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .desktop\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .desktop\:overflow-auto {
    overflow: auto;
  }

  .desktop\:overflow-hidden {
    overflow: hidden;
  }

  .desktop\:overflow-visible {
    overflow: visible;
  }

  .desktop\:overflow-scroll {
    overflow: scroll;
  }

  .desktop\:overflow-x-auto {
    overflow-x: auto;
  }

  .desktop\:overflow-y-auto {
    overflow-y: auto;
  }

  .desktop\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .desktop\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .desktop\:overflow-x-visible {
    overflow-x: visible;
  }

  .desktop\:overflow-y-visible {
    overflow-y: visible;
  }

  .desktop\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .desktop\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .desktop\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .desktop\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .desktop\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .desktop\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .desktop\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .desktop\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .desktop\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .desktop\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .desktop\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .desktop\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .desktop\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .desktop\:p-1 {
    padding: 5px;
  }

  .desktop\:p-2 {
    padding: 10px;
  }

  .desktop\:p-3 {
    padding: 15px;
  }

  .desktop\:p-4 {
    padding: 30px;
  }

  .desktop\:p-5 {
    padding: 50px;
  }

  .desktop\:p-6 {
    padding: 75px;
  }

  .desktop\:p-7 {
    padding: 100px;
  }

  .desktop\:p-none {
    padding: 0px;
  }

  .desktop\:p-tiny {
    padding: 10px;
  }

  .desktop\:p-small {
    padding: 20px;
  }

  .desktop\:p-medium {
    padding: 30px;
  }

  .desktop\:p-large {
    padding: 40px;
  }

  .desktop\:p-huge {
    padding: 60px;
  }

  .desktop\:p-margin-label-height {
    padding: 26px;
  }

  .desktop\:p-nav-height {
    padding: 80px;
  }

  .desktop\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .desktop\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .desktop\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .desktop\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .desktop\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .desktop\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .desktop\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .desktop\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .desktop\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .desktop\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop\:pt-1 {
    padding-top: 5px;
  }

  .desktop\:pr-1 {
    padding-right: 5px;
  }

  .desktop\:pb-1 {
    padding-bottom: 5px;
  }

  .desktop\:pl-1 {
    padding-left: 5px;
  }

  .desktop\:pt-2 {
    padding-top: 10px;
  }

  .desktop\:pr-2 {
    padding-right: 10px;
  }

  .desktop\:pb-2 {
    padding-bottom: 10px;
  }

  .desktop\:pl-2 {
    padding-left: 10px;
  }

  .desktop\:pt-3 {
    padding-top: 15px;
  }

  .desktop\:pr-3 {
    padding-right: 15px;
  }

  .desktop\:pb-3 {
    padding-bottom: 15px;
  }

  .desktop\:pl-3 {
    padding-left: 15px;
  }

  .desktop\:pt-4 {
    padding-top: 30px;
  }

  .desktop\:pr-4 {
    padding-right: 30px;
  }

  .desktop\:pb-4 {
    padding-bottom: 30px;
  }

  .desktop\:pl-4 {
    padding-left: 30px;
  }

  .desktop\:pt-5 {
    padding-top: 50px;
  }

  .desktop\:pr-5 {
    padding-right: 50px;
  }

  .desktop\:pb-5 {
    padding-bottom: 50px;
  }

  .desktop\:pl-5 {
    padding-left: 50px;
  }

  .desktop\:pt-6 {
    padding-top: 75px;
  }

  .desktop\:pr-6 {
    padding-right: 75px;
  }

  .desktop\:pb-6 {
    padding-bottom: 75px;
  }

  .desktop\:pl-6 {
    padding-left: 75px;
  }

  .desktop\:pt-7 {
    padding-top: 100px;
  }

  .desktop\:pr-7 {
    padding-right: 100px;
  }

  .desktop\:pb-7 {
    padding-bottom: 100px;
  }

  .desktop\:pl-7 {
    padding-left: 100px;
  }

  .desktop\:pt-none {
    padding-top: 0px;
  }

  .desktop\:pr-none {
    padding-right: 0px;
  }

  .desktop\:pb-none {
    padding-bottom: 0px;
  }

  .desktop\:pl-none {
    padding-left: 0px;
  }

  .desktop\:pt-tiny {
    padding-top: 10px;
  }

  .desktop\:pr-tiny {
    padding-right: 10px;
  }

  .desktop\:pb-tiny {
    padding-bottom: 10px;
  }

  .desktop\:pl-tiny {
    padding-left: 10px;
  }

  .desktop\:pt-small {
    padding-top: 20px;
  }

  .desktop\:pr-small {
    padding-right: 20px;
  }

  .desktop\:pb-small {
    padding-bottom: 20px;
  }

  .desktop\:pl-small {
    padding-left: 20px;
  }

  .desktop\:pt-medium {
    padding-top: 30px;
  }

  .desktop\:pr-medium {
    padding-right: 30px;
  }

  .desktop\:pb-medium {
    padding-bottom: 30px;
  }

  .desktop\:pl-medium {
    padding-left: 30px;
  }

  .desktop\:pt-large {
    padding-top: 40px;
  }

  .desktop\:pr-large {
    padding-right: 40px;
  }

  .desktop\:pb-large {
    padding-bottom: 40px;
  }

  .desktop\:pl-large {
    padding-left: 40px;
  }

  .desktop\:pt-huge {
    padding-top: 60px;
  }

  .desktop\:pr-huge {
    padding-right: 60px;
  }

  .desktop\:pb-huge {
    padding-bottom: 60px;
  }

  .desktop\:pl-huge {
    padding-left: 60px;
  }

  .desktop\:pt-margin-label-height {
    padding-top: 26px;
  }

  .desktop\:pr-margin-label-height {
    padding-right: 26px;
  }

  .desktop\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .desktop\:pl-margin-label-height {
    padding-left: 26px;
  }

  .desktop\:pt-nav-height {
    padding-top: 80px;
  }

  .desktop\:pr-nav-height {
    padding-right: 80px;
  }

  .desktop\:pb-nav-height {
    padding-bottom: 80px;
  }

  .desktop\:pl-nav-height {
    padding-left: 80px;
  }

  .desktop\:hover\:p-1:hover {
    padding: 5px;
  }

  .desktop\:hover\:p-2:hover {
    padding: 10px;
  }

  .desktop\:hover\:p-3:hover {
    padding: 15px;
  }

  .desktop\:hover\:p-4:hover {
    padding: 30px;
  }

  .desktop\:hover\:p-5:hover {
    padding: 50px;
  }

  .desktop\:hover\:p-6:hover {
    padding: 75px;
  }

  .desktop\:hover\:p-7:hover {
    padding: 100px;
  }

  .desktop\:hover\:p-none:hover {
    padding: 0px;
  }

  .desktop\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .desktop\:hover\:p-small:hover {
    padding: 20px;
  }

  .desktop\:hover\:p-medium:hover {
    padding: 30px;
  }

  .desktop\:hover\:p-large:hover {
    padding: 40px;
  }

  .desktop\:hover\:p-huge:hover {
    padding: 60px;
  }

  .desktop\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .desktop\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .desktop\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .desktop\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .desktop\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .desktop\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .desktop\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .desktop\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .desktop\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .desktop\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .desktop\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .desktop\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .desktop\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .desktop\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .desktop\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .desktop\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .desktop\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .desktop\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .desktop\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .desktop\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .desktop\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .desktop\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .desktop\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .desktop\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .desktop\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .desktop\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .desktop\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .desktop\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .desktop\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .desktop\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .desktop\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .desktop\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .desktop\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .desktop\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .desktop\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .desktop\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .desktop\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .desktop\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .desktop\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .desktop\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .desktop\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .desktop\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .desktop\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .desktop\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .desktop\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .desktop\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .desktop\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .desktop\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .desktop\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .desktop\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .desktop\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .desktop\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .desktop\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .desktop\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .desktop\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .desktop\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .desktop\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .desktop\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .desktop\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .desktop\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .desktop\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .desktop\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .desktop\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .desktop\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .desktop\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .desktop\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .desktop\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .desktop\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .desktop\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .desktop\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .desktop\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .desktop\:focus\:p-1:focus {
    padding: 5px;
  }

  .desktop\:focus\:p-2:focus {
    padding: 10px;
  }

  .desktop\:focus\:p-3:focus {
    padding: 15px;
  }

  .desktop\:focus\:p-4:focus {
    padding: 30px;
  }

  .desktop\:focus\:p-5:focus {
    padding: 50px;
  }

  .desktop\:focus\:p-6:focus {
    padding: 75px;
  }

  .desktop\:focus\:p-7:focus {
    padding: 100px;
  }

  .desktop\:focus\:p-none:focus {
    padding: 0px;
  }

  .desktop\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .desktop\:focus\:p-small:focus {
    padding: 20px;
  }

  .desktop\:focus\:p-medium:focus {
    padding: 30px;
  }

  .desktop\:focus\:p-large:focus {
    padding: 40px;
  }

  .desktop\:focus\:p-huge:focus {
    padding: 60px;
  }

  .desktop\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .desktop\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .desktop\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .desktop\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .desktop\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .desktop\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .desktop\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .desktop\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .desktop\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .desktop\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .desktop\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .desktop\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .desktop\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .desktop\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .desktop\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .desktop\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .desktop\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .desktop\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .desktop\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .desktop\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .desktop\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .desktop\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .desktop\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .desktop\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .desktop\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .desktop\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .desktop\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .desktop\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .desktop\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .desktop\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .desktop\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .desktop\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .desktop\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .desktop\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .desktop\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .desktop\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .desktop\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .desktop\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .desktop\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .desktop\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .desktop\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .desktop\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .desktop\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .desktop\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .desktop\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .desktop\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .desktop\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .desktop\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .desktop\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .desktop\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .desktop\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .desktop\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .desktop\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .desktop\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .desktop\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .desktop\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .desktop\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .desktop\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .desktop\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .desktop\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .desktop\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .desktop\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .desktop\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .desktop\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .desktop\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .desktop\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .desktop\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .desktop\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .desktop\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .desktop\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .desktop\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .desktop\:first\:p-1:first-child {
    padding: 5px;
  }

  .desktop\:first\:p-2:first-child {
    padding: 10px;
  }

  .desktop\:first\:p-3:first-child {
    padding: 15px;
  }

  .desktop\:first\:p-4:first-child {
    padding: 30px;
  }

  .desktop\:first\:p-5:first-child {
    padding: 50px;
  }

  .desktop\:first\:p-6:first-child {
    padding: 75px;
  }

  .desktop\:first\:p-7:first-child {
    padding: 100px;
  }

  .desktop\:first\:p-none:first-child {
    padding: 0px;
  }

  .desktop\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .desktop\:first\:p-small:first-child {
    padding: 20px;
  }

  .desktop\:first\:p-medium:first-child {
    padding: 30px;
  }

  .desktop\:first\:p-large:first-child {
    padding: 40px;
  }

  .desktop\:first\:p-huge:first-child {
    padding: 60px;
  }

  .desktop\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .desktop\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .desktop\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .desktop\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .desktop\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .desktop\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .desktop\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .desktop\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .desktop\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .desktop\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .desktop\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .desktop\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .desktop\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .desktop\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .desktop\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .desktop\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .desktop\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .desktop\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .desktop\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .desktop\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .desktop\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .desktop\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .desktop\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .desktop\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .desktop\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .desktop\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .desktop\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .desktop\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .desktop\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .desktop\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .desktop\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .desktop\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .desktop\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .desktop\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .desktop\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .desktop\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .desktop\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .desktop\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .desktop\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .desktop\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .desktop\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .desktop\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .desktop\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .desktop\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .desktop\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .desktop\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .desktop\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .desktop\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .desktop\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .desktop\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .desktop\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .desktop\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .desktop\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .desktop\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .desktop\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .desktop\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .desktop\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .desktop\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .desktop\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .desktop\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .desktop\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .desktop\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .desktop\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .desktop\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .desktop\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .desktop\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .desktop\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .desktop\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .desktop\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .desktop\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .desktop\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .desktop\:last\:p-1:last-child {
    padding: 5px;
  }

  .desktop\:last\:p-2:last-child {
    padding: 10px;
  }

  .desktop\:last\:p-3:last-child {
    padding: 15px;
  }

  .desktop\:last\:p-4:last-child {
    padding: 30px;
  }

  .desktop\:last\:p-5:last-child {
    padding: 50px;
  }

  .desktop\:last\:p-6:last-child {
    padding: 75px;
  }

  .desktop\:last\:p-7:last-child {
    padding: 100px;
  }

  .desktop\:last\:p-none:last-child {
    padding: 0px;
  }

  .desktop\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .desktop\:last\:p-small:last-child {
    padding: 20px;
  }

  .desktop\:last\:p-medium:last-child {
    padding: 30px;
  }

  .desktop\:last\:p-large:last-child {
    padding: 40px;
  }

  .desktop\:last\:p-huge:last-child {
    padding: 60px;
  }

  .desktop\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .desktop\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .desktop\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .desktop\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .desktop\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .desktop\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .desktop\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .desktop\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .desktop\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .desktop\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .desktop\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .desktop\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .desktop\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .desktop\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .desktop\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .desktop\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .desktop\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .desktop\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .desktop\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .desktop\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .desktop\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .desktop\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .desktop\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .desktop\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .desktop\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .desktop\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .desktop\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .desktop\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .desktop\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .desktop\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .desktop\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .desktop\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .desktop\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .desktop\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .desktop\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .desktop\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .desktop\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .desktop\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .desktop\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .desktop\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .desktop\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .desktop\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .desktop\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .desktop\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .desktop\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .desktop\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .desktop\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .desktop\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .desktop\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .desktop\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .desktop\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .desktop\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .desktop\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .desktop\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .desktop\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .desktop\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .desktop\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .desktop\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .desktop\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .desktop\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .desktop\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .desktop\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .desktop\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .desktop\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .desktop\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .desktop\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .desktop\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .desktop\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .desktop\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .desktop\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .desktop\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .desktop\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .desktop\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .desktop\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .desktop\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .desktop\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .desktop\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .desktop\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .desktop\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .desktop\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .desktop\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .desktop\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .desktop\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .desktop\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .desktop\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .desktop\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .desktop\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .desktop\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .desktop\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .desktop\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .desktop\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .desktop\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .desktop\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .desktop\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .desktop\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .desktop\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .desktop\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .desktop\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .desktop\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .desktop\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .desktop\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .desktop\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .desktop\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .desktop\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .desktop\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .desktop\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .desktop\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .desktop\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .desktop\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .desktop\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .desktop\:pointer-events-none {
    pointer-events: none;
  }

  .desktop\:pointer-events-auto {
    pointer-events: auto;
  }

  .desktop\:static {
    position: static;
  }

  .desktop\:fixed {
    position: fixed;
  }

  .desktop\:absolute {
    position: absolute;
  }

  .desktop\:relative {
    position: relative;
  }

  .desktop\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .desktop\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .desktop\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .desktop\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .desktop\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .desktop\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .desktop\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .desktop\:top-0 {
    top: 0;
  }

  .desktop\:right-0 {
    right: 0;
  }

  .desktop\:bottom-0 {
    bottom: 0;
  }

  .desktop\:left-0 {
    left: 0;
  }

  .desktop\:top-auto {
    top: auto;
  }

  .desktop\:right-auto {
    right: auto;
  }

  .desktop\:bottom-auto {
    bottom: auto;
  }

  .desktop\:left-auto {
    left: auto;
  }

  .desktop\:resize-none {
    resize: none;
  }

  .desktop\:resize-y {
    resize: vertical;
  }

  .desktop\:resize-x {
    resize: horizontal;
  }

  .desktop\:resize {
    resize: both;
  }

  .desktop\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .desktop\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .desktop\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .desktop\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .desktop\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .desktop\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .desktop\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .desktop\:shadow-none {
    box-shadow: none;
  }

  .desktop\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .desktop\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .desktop\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .desktop\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .desktop\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .desktop\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .desktop\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .desktop\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .desktop\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .desktop\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .desktop\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .desktop\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .desktop\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .desktop\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .desktop\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .desktop\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .desktop\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .desktop\:fill-current {
    fill: currentColor;
  }

  .desktop\:stroke-current {
    stroke: currentColor;
  }

  .desktop\:stroke-0 {
    stroke-width: 0;
  }

  .desktop\:stroke-1 {
    stroke-width: 1;
  }

  .desktop\:stroke-2 {
    stroke-width: 2;
  }

  .desktop\:table-auto {
    table-layout: auto;
  }

  .desktop\:table-fixed {
    table-layout: fixed;
  }

  .desktop\:text-left {
    text-align: left;
  }

  .desktop\:text-center {
    text-align: center;
  }

  .desktop\:text-right {
    text-align: right;
  }

  .desktop\:text-justify {
    text-align: justify;
  }

  .desktop\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .desktop\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .desktop\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .desktop\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .desktop\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .desktop\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .desktop\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .desktop\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .desktop\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .desktop\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .desktop\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .desktop\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .desktop\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .desktop\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .desktop\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .desktop\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .desktop\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .desktop\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .desktop\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .desktop\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .desktop\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .desktop\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .desktop\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .desktop\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .desktop\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .desktop\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .desktop\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .desktop\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .desktop\:text-state-danger {
    color: #FF8274B3;
  }

  .desktop\:text-state-warning {
    color: #F9C66BB3;
  }

  .desktop\:text-state-success {
    color: #C7F6C9B3;
  }

  .desktop\:text-state-primary {
    color: #B3F5FFB3;
  }

  .desktop\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .desktop\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .desktop\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .desktop\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .desktop\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .desktop\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .desktop\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .desktop\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .desktop\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .desktop\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .desktop\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .desktop\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .desktop\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .desktop\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .desktop\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .desktop\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .desktop\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .desktop\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .desktop\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .desktop\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .desktop\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .desktop\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .desktop\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .desktop\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .desktop\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .desktop\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .desktop\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .desktop\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .desktop\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .desktop\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .desktop\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .desktop\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .desktop\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .desktop\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .desktop\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .desktop\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .desktop\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .desktop\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .desktop\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .desktop\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .desktop\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .desktop\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .desktop\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .desktop\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .desktop\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .desktop\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .desktop\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .desktop\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .desktop\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .desktop\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .desktop\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .desktop\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .desktop\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .desktop\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .desktop\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .desktop\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .desktop\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .desktop\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .desktop\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .desktop\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .desktop\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .desktop\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .desktop\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .desktop\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .desktop\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .desktop\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .desktop\:text-opacity-0 {
    --text-opacity: 0;
  }

  .desktop\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .desktop\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .desktop\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .desktop\:text-opacity-100 {
    --text-opacity: 1;
  }

  .desktop\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .desktop\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .desktop\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .desktop\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .desktop\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .desktop\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .desktop\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .desktop\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .desktop\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .desktop\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .desktop\:italic {
    font-style: italic;
  }

  .desktop\:not-italic {
    font-style: normal;
  }

  .desktop\:uppercase {
    text-transform: uppercase;
  }

  .desktop\:lowercase {
    text-transform: lowercase;
  }

  .desktop\:capitalize {
    text-transform: capitalize;
  }

  .desktop\:normal-case {
    text-transform: none;
  }

  .desktop\:underline {
    text-decoration: underline;
  }

  .desktop\:line-through {
    text-decoration: line-through;
  }

  .desktop\:no-underline {
    text-decoration: none;
  }

  .desktop\:hover\:underline:hover {
    text-decoration: underline;
  }

  .desktop\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .desktop\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .desktop\:focus\:underline:focus {
    text-decoration: underline;
  }

  .desktop\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .desktop\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .desktop\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .desktop\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .desktop\:ordinal, .desktop\:slashed-zero, .desktop\:lining-nums, .desktop\:oldstyle-nums, .desktop\:proportional-nums, .desktop\:tabular-nums, .desktop\:diagonal-fractions, .desktop\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .desktop\:normal-nums {
    font-variant-numeric: normal;
  }

  .desktop\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .desktop\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .desktop\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .desktop\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .desktop\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .desktop\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .desktop\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .desktop\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .desktop\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .desktop\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .desktop\:tracking-normal {
    letter-spacing: 0;
  }

  .desktop\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .desktop\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .desktop\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .desktop\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .desktop\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .desktop\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .desktop\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .desktop\:align-baseline {
    vertical-align: baseline;
  }

  .desktop\:align-top {
    vertical-align: top;
  }

  .desktop\:align-middle {
    vertical-align: middle;
  }

  .desktop\:align-bottom {
    vertical-align: bottom;
  }

  .desktop\:align-text-top {
    vertical-align: text-top;
  }

  .desktop\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .desktop\:visible {
    visibility: visible;
  }

  .desktop\:invisible {
    visibility: hidden;
  }

  .desktop\:whitespace-normal {
    white-space: normal;
  }

  .desktop\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .desktop\:whitespace-pre {
    white-space: pre;
  }

  .desktop\:whitespace-pre-line {
    white-space: pre-line;
  }

  .desktop\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .desktop\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .desktop\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .desktop\:break-all {
    word-break: break-all;
  }

  .desktop\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .desktop\:w-1 {
    width: 5px;
  }

  .desktop\:w-2 {
    width: 10px;
  }

  .desktop\:w-3 {
    width: 15px;
  }

  .desktop\:w-4 {
    width: 30px;
  }

  .desktop\:w-5 {
    width: 50px;
  }

  .desktop\:w-6 {
    width: 75px;
  }

  .desktop\:w-7 {
    width: 100px;
  }

  .desktop\:w-auto {
    width: auto;
  }

  .desktop\:w-none {
    width: 0px;
  }

  .desktop\:w-tiny {
    width: 10px;
  }

  .desktop\:w-small {
    width: 20px;
  }

  .desktop\:w-medium {
    width: 30px;
  }

  .desktop\:w-large {
    width: 40px;
  }

  .desktop\:w-huge {
    width: 60px;
  }

  .desktop\:w-margin-label-height {
    width: 26px;
  }

  .desktop\:w-nav-height {
    width: 80px;
  }

  .desktop\:w-1\/2 {
    width: 50%;
  }

  .desktop\:w-1\/3 {
    width: 33.333333%;
  }

  .desktop\:w-2\/3 {
    width: 66.666667%;
  }

  .desktop\:w-1\/4 {
    width: 25%;
  }

  .desktop\:w-2\/4 {
    width: 50%;
  }

  .desktop\:w-3\/4 {
    width: 75%;
  }

  .desktop\:w-1\/5 {
    width: 20%;
  }

  .desktop\:w-2\/5 {
    width: 40%;
  }

  .desktop\:w-3\/5 {
    width: 60%;
  }

  .desktop\:w-4\/5 {
    width: 80%;
  }

  .desktop\:w-1\/6 {
    width: 16.666667%;
  }

  .desktop\:w-2\/6 {
    width: 33.333333%;
  }

  .desktop\:w-3\/6 {
    width: 50%;
  }

  .desktop\:w-4\/6 {
    width: 66.666667%;
  }

  .desktop\:w-5\/6 {
    width: 83.333333%;
  }

  .desktop\:w-1\/12 {
    width: 8.333333%;
  }

  .desktop\:w-2\/12 {
    width: 16.666667%;
  }

  .desktop\:w-3\/12 {
    width: 25%;
  }

  .desktop\:w-4\/12 {
    width: 33.333333%;
  }

  .desktop\:w-5\/12 {
    width: 41.666667%;
  }

  .desktop\:w-6\/12 {
    width: 50%;
  }

  .desktop\:w-7\/12 {
    width: 58.333333%;
  }

  .desktop\:w-8\/12 {
    width: 66.666667%;
  }

  .desktop\:w-9\/12 {
    width: 75%;
  }

  .desktop\:w-10\/12 {
    width: 83.333333%;
  }

  .desktop\:w-11\/12 {
    width: 91.666667%;
  }

  .desktop\:w-full {
    width: 100%;
  }

  .desktop\:w-screen {
    width: 100vw;
  }

  .desktop\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .desktop\:z-0 {
    z-index: 0;
  }

  .desktop\:z-10 {
    z-index: 10;
  }

  .desktop\:z-20 {
    z-index: 20;
  }

  .desktop\:z-30 {
    z-index: 30;
  }

  .desktop\:z-40 {
    z-index: 40;
  }

  .desktop\:z-50 {
    z-index: 50;
  }

  .desktop\:z-auto {
    z-index: auto;
  }

  .desktop\:z-nav {
    z-index: 90;
  }

  .desktop\:z-menu {
    z-index: 100;
  }

  .desktop\:z-popup {
    z-index: 110;
  }

  .desktop\:z-modalBackdrop {
    z-index: 120;
  }

  .desktop\:z-modal {
    z-index: 130;
  }

  .desktop\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .desktop\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .desktop\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .desktop\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .desktop\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .desktop\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .desktop\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .desktop\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .desktop\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .desktop\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .desktop\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .desktop\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .desktop\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .desktop\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .desktop\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .desktop\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .desktop\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .desktop\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .desktop\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .desktop\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .desktop\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .desktop\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .desktop\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .desktop\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .desktop\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .desktop\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .desktop\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .desktop\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .desktop\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .desktop\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .desktop\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .desktop\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .desktop\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .desktop\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .desktop\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .desktop\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .desktop\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .desktop\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .desktop\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .desktop\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .desktop\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .desktop\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .desktop\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .desktop\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .desktop\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .desktop\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .desktop\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .desktop\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .desktop\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .desktop\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .desktop\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .desktop\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .desktop\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .desktop\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .desktop\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .desktop\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .desktop\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .desktop\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .desktop\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .desktop\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .desktop\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .desktop\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .desktop\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .desktop\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .desktop\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .desktop\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .desktop\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .desktop\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .desktop\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .desktop\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .desktop\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .desktop\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .desktop\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .desktop\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .desktop\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .desktop\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .desktop\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .desktop\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .desktop\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .desktop\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .desktop\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .desktop\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .desktop\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .desktop\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .desktop\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .desktop\:grid-flow-row {
    grid-auto-flow: row;
  }

  .desktop\:grid-flow-col {
    grid-auto-flow: column;
  }

  .desktop\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .desktop\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .desktop\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .desktop\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .desktop\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .desktop\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .desktop\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .desktop\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .desktop\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .desktop\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .desktop\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .desktop\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .desktop\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .desktop\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .desktop\:grid-cols-none {
    grid-template-columns: none;
  }

  .desktop\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .desktop\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .desktop\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .desktop\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .desktop\:col-auto {
    grid-column: auto;
  }

  .desktop\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .desktop\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .desktop\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .desktop\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .desktop\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .desktop\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .desktop\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .desktop\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .desktop\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .desktop\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .desktop\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .desktop\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .desktop\:col-span-full {
    grid-column: 1 / -1;
  }

  .desktop\:col-start-1 {
    grid-column-start: 1;
  }

  .desktop\:col-start-2 {
    grid-column-start: 2;
  }

  .desktop\:col-start-3 {
    grid-column-start: 3;
  }

  .desktop\:col-start-4 {
    grid-column-start: 4;
  }

  .desktop\:col-start-5 {
    grid-column-start: 5;
  }

  .desktop\:col-start-6 {
    grid-column-start: 6;
  }

  .desktop\:col-start-7 {
    grid-column-start: 7;
  }

  .desktop\:col-start-8 {
    grid-column-start: 8;
  }

  .desktop\:col-start-9 {
    grid-column-start: 9;
  }

  .desktop\:col-start-10 {
    grid-column-start: 10;
  }

  .desktop\:col-start-11 {
    grid-column-start: 11;
  }

  .desktop\:col-start-12 {
    grid-column-start: 12;
  }

  .desktop\:col-start-13 {
    grid-column-start: 13;
  }

  .desktop\:col-start-auto {
    grid-column-start: auto;
  }

  .desktop\:col-end-1 {
    grid-column-end: 1;
  }

  .desktop\:col-end-2 {
    grid-column-end: 2;
  }

  .desktop\:col-end-3 {
    grid-column-end: 3;
  }

  .desktop\:col-end-4 {
    grid-column-end: 4;
  }

  .desktop\:col-end-5 {
    grid-column-end: 5;
  }

  .desktop\:col-end-6 {
    grid-column-end: 6;
  }

  .desktop\:col-end-7 {
    grid-column-end: 7;
  }

  .desktop\:col-end-8 {
    grid-column-end: 8;
  }

  .desktop\:col-end-9 {
    grid-column-end: 9;
  }

  .desktop\:col-end-10 {
    grid-column-end: 10;
  }

  .desktop\:col-end-11 {
    grid-column-end: 11;
  }

  .desktop\:col-end-12 {
    grid-column-end: 12;
  }

  .desktop\:col-end-13 {
    grid-column-end: 13;
  }

  .desktop\:col-end-auto {
    grid-column-end: auto;
  }

  .desktop\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .desktop\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .desktop\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .desktop\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .desktop\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .desktop\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .desktop\:grid-rows-none {
    grid-template-rows: none;
  }

  .desktop\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .desktop\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .desktop\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .desktop\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .desktop\:row-auto {
    grid-row: auto;
  }

  .desktop\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .desktop\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .desktop\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .desktop\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .desktop\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .desktop\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .desktop\:row-span-full {
    grid-row: 1 / -1;
  }

  .desktop\:row-start-1 {
    grid-row-start: 1;
  }

  .desktop\:row-start-2 {
    grid-row-start: 2;
  }

  .desktop\:row-start-3 {
    grid-row-start: 3;
  }

  .desktop\:row-start-4 {
    grid-row-start: 4;
  }

  .desktop\:row-start-5 {
    grid-row-start: 5;
  }

  .desktop\:row-start-6 {
    grid-row-start: 6;
  }

  .desktop\:row-start-7 {
    grid-row-start: 7;
  }

  .desktop\:row-start-auto {
    grid-row-start: auto;
  }

  .desktop\:row-end-1 {
    grid-row-end: 1;
  }

  .desktop\:row-end-2 {
    grid-row-end: 2;
  }

  .desktop\:row-end-3 {
    grid-row-end: 3;
  }

  .desktop\:row-end-4 {
    grid-row-end: 4;
  }

  .desktop\:row-end-5 {
    grid-row-end: 5;
  }

  .desktop\:row-end-6 {
    grid-row-end: 6;
  }

  .desktop\:row-end-7 {
    grid-row-end: 7;
  }

  .desktop\:row-end-auto {
    grid-row-end: auto;
  }

  .desktop\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .desktop\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .desktop\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .desktop\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .desktop\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .desktop\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .desktop\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .desktop\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .desktop\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .desktop\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .desktop\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .desktop\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .desktop\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .desktop\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .desktop\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .desktop\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .desktop\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .desktop\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .desktop\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .desktop\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .desktop\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .desktop\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .desktop\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .desktop\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .desktop\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .desktop\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .desktop\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .desktop\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .desktop\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .desktop\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .desktop\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .desktop\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .desktop\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .desktop\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .desktop\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .desktop\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .desktop\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .desktop\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .desktop\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .desktop\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .desktop\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .desktop\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .desktop\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .desktop\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .desktop\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .desktop\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .desktop\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .desktop\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .desktop\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .desktop\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .desktop\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .desktop\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .desktop\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .desktop\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .desktop\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .desktop\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .desktop\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .desktop\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .desktop\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .desktop\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .desktop\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .desktop\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .desktop\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .desktop\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .desktop\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .desktop\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .desktop\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .desktop\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .desktop\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .desktop\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .desktop\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .desktop\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .desktop\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .desktop\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .desktop\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .desktop\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .desktop\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .desktop\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .desktop\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .desktop\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .desktop\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .desktop\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .desktop\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .desktop\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .desktop\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .desktop\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .desktop\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .desktop\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .desktop\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .desktop\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .desktop\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .desktop\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .desktop\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .desktop\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .desktop\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .desktop\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .desktop\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .desktop\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .desktop\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .desktop\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .desktop\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .desktop\:rotate-0 {
    --transform-rotate: 0;
  }

  .desktop\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .desktop\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .desktop\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .desktop\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .desktop\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .desktop\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .desktop\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .desktop\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .desktop\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .desktop\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .desktop\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .desktop\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .desktop\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .desktop\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .desktop\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .desktop\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .desktop\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .desktop\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .desktop\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .desktop\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .desktop\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .desktop\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .desktop\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .desktop\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .desktop\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .desktop\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .desktop\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .desktop\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .desktop\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .desktop\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .desktop\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .desktop\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .desktop\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .desktop\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .desktop\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .desktop\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .desktop\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .desktop\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .desktop\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .desktop\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .desktop\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .desktop\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .desktop\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .desktop\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .desktop\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .desktop\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .desktop\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .desktop\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .desktop\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .desktop\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .desktop\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .desktop\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .desktop\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .desktop\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .desktop\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .desktop\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .desktop\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .desktop\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .desktop\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .desktop\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .desktop\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .desktop\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .desktop\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .desktop\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .desktop\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .desktop\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .desktop\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .desktop\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .desktop\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .desktop\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .desktop\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .desktop\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .desktop\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .desktop\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .desktop\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .desktop\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .desktop\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .desktop\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .desktop\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .desktop\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .desktop\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .desktop\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .desktop\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .desktop\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .desktop\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .desktop\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .desktop\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .desktop\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .desktop\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .desktop\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .desktop\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .desktop\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .desktop\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .desktop\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .desktop\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .desktop\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .desktop\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .desktop\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .desktop\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .desktop\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .desktop\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .desktop\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .desktop\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .desktop\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .desktop\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .desktop\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .desktop\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .desktop\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .desktop\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .desktop\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .desktop\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .desktop\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .desktop\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .desktop\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .desktop\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .desktop\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .desktop\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .desktop\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .desktop\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .desktop\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .desktop\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .desktop\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .desktop\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .desktop\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .desktop\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .desktop\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .desktop\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .desktop\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .desktop\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .desktop\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .desktop\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .desktop\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .desktop\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .desktop\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .desktop\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .desktop\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .desktop\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .desktop\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .desktop\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .desktop\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .desktop\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .desktop\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .desktop\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .desktop\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .desktop\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .desktop\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .desktop\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .desktop\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .desktop\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .desktop\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .desktop\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .desktop\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .desktop\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .desktop\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .desktop\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .desktop\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .desktop\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .desktop\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .desktop\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .desktop\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .desktop\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .desktop\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .desktop\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .desktop\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .desktop\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .desktop\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .desktop\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .desktop\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .desktop\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .desktop\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .desktop\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .desktop\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .desktop\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .desktop\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .desktop\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .desktop\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .desktop\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .desktop\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .desktop\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .desktop\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .desktop\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .desktop\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .desktop\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .desktop\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .desktop\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .desktop\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .desktop\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .desktop\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .desktop\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .desktop\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .desktop\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .desktop\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .desktop\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .desktop\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .desktop\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .desktop\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .desktop\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .desktop\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .desktop\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .desktop\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .desktop\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .desktop\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .desktop\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .desktop\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .desktop\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .desktop\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .desktop\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .desktop\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .desktop\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .desktop\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .desktop\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .desktop\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .desktop\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .desktop\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .desktop\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .desktop\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .desktop\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .desktop\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .desktop\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .desktop\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .desktop\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .desktop\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .desktop\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .desktop\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .desktop\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .desktop\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .desktop\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .desktop\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .desktop\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .desktop\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .desktop\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .desktop\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .desktop\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .desktop\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .desktop\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .desktop\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .desktop\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .desktop\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .desktop\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .desktop\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .desktop\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .desktop\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .desktop\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .desktop\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .desktop\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .desktop\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .desktop\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .desktop\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .desktop\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .desktop\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .desktop\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .desktop\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .desktop\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .desktop\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .desktop\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .desktop\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .desktop\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .desktop\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .desktop\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .desktop\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .desktop\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .desktop\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .desktop\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .desktop\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .desktop\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .desktop\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .desktop\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .desktop\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .desktop\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .desktop\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .desktop\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .desktop\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .desktop\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .desktop\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .desktop\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .desktop\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .desktop\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .desktop\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .desktop\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .desktop\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .desktop\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .desktop\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .desktop\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .desktop\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .desktop\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .desktop\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .desktop\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .desktop\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .desktop\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .desktop\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .desktop\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .desktop\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .desktop\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .desktop\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .desktop\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .desktop\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .desktop\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .desktop\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .desktop\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .desktop\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .desktop\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .desktop\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .desktop\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .desktop\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .desktop\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .desktop\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .desktop\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .desktop\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .desktop\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .desktop\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .desktop\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .desktop\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .desktop\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .desktop\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .desktop\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .desktop\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .desktop\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .desktop\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .desktop\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .desktop\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .desktop\:transition-none {
    transition-property: none;
  }

  .desktop\:transition-all {
    transition-property: all;
  }

  .desktop\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .desktop\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .desktop\:transition-opacity {
    transition-property: opacity;
  }

  .desktop\:transition-shadow {
    transition-property: box-shadow;
  }

  .desktop\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .desktop\:ease-linear {
    transition-timing-function: linear;
  }

  .desktop\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .desktop\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .desktop\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .desktop\:duration-75 {
    transition-duration: 75ms;
  }

  .desktop\:duration-100 {
    transition-duration: 100ms;
  }

  .desktop\:duration-150 {
    transition-duration: 150ms;
  }

  .desktop\:duration-200 {
    transition-duration: 200ms;
  }

  .desktop\:duration-300 {
    transition-duration: 300ms;
  }

  .desktop\:duration-500 {
    transition-duration: 500ms;
  }

  .desktop\:duration-700 {
    transition-duration: 700ms;
  }

  .desktop\:duration-1000 {
    transition-duration: 1000ms;
  }

  .desktop\:delay-75 {
    transition-delay: 75ms;
  }

  .desktop\:delay-100 {
    transition-delay: 100ms;
  }

  .desktop\:delay-150 {
    transition-delay: 150ms;
  }

  .desktop\:delay-200 {
    transition-delay: 200ms;
  }

  .desktop\:delay-300 {
    transition-delay: 300ms;
  }

  .desktop\:delay-500 {
    transition-delay: 500ms;
  }

  .desktop\:delay-700 {
    transition-delay: 700ms;
  }

  .desktop\:delay-1000 {
    transition-delay: 1000ms;
  }

  .desktop\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .desktop\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .desktop\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .desktop\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .desktop\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}

@media (min-width: 1216px) {
  .widescreen\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .widescreen\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .widescreen\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .widescreen\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .widescreen\:container {
      max-width: 1408px;
    }
  }

  .widescreen\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .widescreen\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .widescreen\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .widescreen\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .widescreen\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .widescreen\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .widescreen\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .widescreen\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .widescreen\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .widescreen\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .widescreen\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .widescreen\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .widescreen\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .widescreen\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .widescreen\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .widescreen\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .widescreen\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .widescreen\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .widescreen\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .widescreen\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .widescreen\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .widescreen\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .widescreen\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .widescreen\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .widescreen\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .widescreen\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .widescreen\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .widescreen\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .widescreen\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .widescreen\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .widescreen\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .widescreen\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .widescreen\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .widescreen\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .widescreen\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .widescreen\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .widescreen\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .widescreen\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .widescreen\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .widescreen\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .widescreen\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .widescreen\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .widescreen\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .widescreen\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .widescreen\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .widescreen\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .widescreen\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .widescreen\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .widescreen\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .widescreen\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .widescreen\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .widescreen\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .widescreen\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .widescreen\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .widescreen\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .widescreen\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .widescreen\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .widescreen\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .widescreen\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .widescreen\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .widescreen\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .widescreen\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .widescreen\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .widescreen\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .widescreen\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .widescreen\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .widescreen\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .widescreen\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .widescreen\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .widescreen\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .widescreen\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .widescreen\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .widescreen\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .widescreen\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .widescreen\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .widescreen\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .widescreen\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .widescreen\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .widescreen\:bg-fixed {
    background-attachment: fixed;
  }

  .widescreen\:bg-local {
    background-attachment: local;
  }

  .widescreen\:bg-scroll {
    background-attachment: scroll;
  }

  .widescreen\:bg-clip-border {
    background-clip: border-box;
  }

  .widescreen\:bg-clip-padding {
    background-clip: padding-box;
  }

  .widescreen\:bg-clip-content {
    background-clip: content-box;
  }

  .widescreen\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .widescreen\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .widescreen\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .widescreen\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .widescreen\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .widescreen\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .widescreen\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .widescreen\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .widescreen\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .widescreen\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .widescreen\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .widescreen\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .widescreen\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .widescreen\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .widescreen\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .widescreen\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .widescreen\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .widescreen\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .widescreen\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .widescreen\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .widescreen\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .widescreen\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .widescreen\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .widescreen\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .widescreen\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .widescreen\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .widescreen\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .widescreen\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .widescreen\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .widescreen\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .widescreen\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .widescreen\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .widescreen\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .widescreen\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .widescreen\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .widescreen\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .widescreen\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .widescreen\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .widescreen\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .widescreen\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .widescreen\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .widescreen\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .widescreen\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .widescreen\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .widescreen\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .widescreen\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .widescreen\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .widescreen\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .widescreen\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .widescreen\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .widescreen\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .widescreen\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .widescreen\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .widescreen\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .widescreen\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .widescreen\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .widescreen\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .widescreen\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .widescreen\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .widescreen\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .widescreen\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .widescreen\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .widescreen\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .widescreen\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .widescreen\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .widescreen\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .widescreen\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .widescreen\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .widescreen\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .widescreen\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .widescreen\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .widescreen\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .widescreen\:bg-none {
    background-image: none;
  }

  .widescreen\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .widescreen\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .widescreen\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .widescreen\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .widescreen\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .widescreen\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .widescreen\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .widescreen\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .widescreen\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .widescreen\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .widescreen\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .widescreen\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .widescreen\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .widescreen\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .widescreen\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .widescreen\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .widescreen\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .widescreen\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .widescreen\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .widescreen\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .widescreen\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .widescreen\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .widescreen\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .widescreen\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .widescreen\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .widescreen\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .widescreen\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .widescreen\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .widescreen\:to-red {
    --gradient-to-color: #ff8274;
  }

  .widescreen\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .widescreen\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .widescreen\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .widescreen\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .widescreen\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .widescreen\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .widescreen\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .widescreen\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .widescreen\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .widescreen\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .widescreen\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .widescreen\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .widescreen\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .widescreen\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .widescreen\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .widescreen\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .widescreen\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .widescreen\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .widescreen\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .widescreen\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .widescreen\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .widescreen\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .widescreen\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .widescreen\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .widescreen\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .widescreen\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .widescreen\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .widescreen\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .widescreen\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .widescreen\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .widescreen\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .widescreen\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .widescreen\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .widescreen\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .widescreen\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .widescreen\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .widescreen\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .widescreen\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .widescreen\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .widescreen\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .widescreen\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .widescreen\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .widescreen\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .widescreen\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .widescreen\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .widescreen\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .widescreen\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .widescreen\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .widescreen\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .widescreen\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .widescreen\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .widescreen\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .widescreen\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .widescreen\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .widescreen\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .widescreen\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .widescreen\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .widescreen\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .widescreen\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .widescreen\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .widescreen\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .widescreen\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .widescreen\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .widescreen\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .widescreen\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .widescreen\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .widescreen\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .widescreen\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .widescreen\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .widescreen\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .widescreen\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .widescreen\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .widescreen\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .widescreen\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .widescreen\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .widescreen\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .widescreen\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .widescreen\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .widescreen\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .widescreen\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .widescreen\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .widescreen\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .widescreen\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .widescreen\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .widescreen\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .widescreen\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .widescreen\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .widescreen\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .widescreen\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .widescreen\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .widescreen\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .widescreen\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .widescreen\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .widescreen\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .widescreen\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .widescreen\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .widescreen\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .widescreen\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .widescreen\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .widescreen\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .widescreen\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .widescreen\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .widescreen\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .widescreen\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .widescreen\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .widescreen\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .widescreen\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .widescreen\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .widescreen\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .widescreen\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .widescreen\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .widescreen\:bg-bottom {
    background-position: bottom;
  }

  .widescreen\:bg-center {
    background-position: center;
  }

  .widescreen\:bg-left {
    background-position: left;
  }

  .widescreen\:bg-left-bottom {
    background-position: left bottom;
  }

  .widescreen\:bg-left-top {
    background-position: left top;
  }

  .widescreen\:bg-right {
    background-position: right;
  }

  .widescreen\:bg-right-bottom {
    background-position: right bottom;
  }

  .widescreen\:bg-right-top {
    background-position: right top;
  }

  .widescreen\:bg-top {
    background-position: top;
  }

  .widescreen\:bg-repeat {
    background-repeat: repeat;
  }

  .widescreen\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .widescreen\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .widescreen\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .widescreen\:bg-repeat-round {
    background-repeat: round;
  }

  .widescreen\:bg-repeat-space {
    background-repeat: space;
  }

  .widescreen\:bg-auto {
    background-size: auto;
  }

  .widescreen\:bg-cover {
    background-size: cover;
  }

  .widescreen\:bg-contain {
    background-size: contain;
  }

  .widescreen\:border-collapse {
    border-collapse: collapse;
  }

  .widescreen\:border-separate {
    border-collapse: separate;
  }

  .widescreen\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .widescreen\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .widescreen\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .widescreen\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .widescreen\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .widescreen\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .widescreen\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .widescreen\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .widescreen\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .widescreen\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .widescreen\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .widescreen\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .widescreen\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .widescreen\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .widescreen\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .widescreen\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .widescreen\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .widescreen\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .widescreen\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .widescreen\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .widescreen\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .widescreen\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .widescreen\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .widescreen\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .widescreen\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .widescreen\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .widescreen\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .widescreen\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .widescreen\:border-state-danger {
    border-color: #FF8274B3;
  }

  .widescreen\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .widescreen\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .widescreen\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .widescreen\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .widescreen\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .widescreen\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .widescreen\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .widescreen\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .widescreen\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .widescreen\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .widescreen\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .widescreen\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .widescreen\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .widescreen\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .widescreen\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .widescreen\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .widescreen\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .widescreen\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .widescreen\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .widescreen\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .widescreen\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .widescreen\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .widescreen\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .widescreen\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .widescreen\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .widescreen\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .widescreen\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .widescreen\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .widescreen\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .widescreen\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .widescreen\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .widescreen\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .widescreen\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .widescreen\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .widescreen\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .widescreen\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .widescreen\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .widescreen\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .widescreen\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .widescreen\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .widescreen\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .widescreen\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .widescreen\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .widescreen\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .widescreen\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .widescreen\:border-opacity-0 {
    --border-opacity: 0;
  }

  .widescreen\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .widescreen\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .widescreen\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .widescreen\:border-opacity-100 {
    --border-opacity: 1;
  }

  .widescreen\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .widescreen\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .widescreen\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .widescreen\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .widescreen\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .widescreen\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .widescreen\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .widescreen\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .widescreen\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .widescreen\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .widescreen\:rounded-none {
    border-radius: 0;
  }

  .widescreen\:rounded-sm {
    border-radius: 0.125rem;
  }

  .widescreen\:rounded {
    border-radius: 0.25rem;
  }

  .widescreen\:rounded-md {
    border-radius: 0.375rem;
  }

  .widescreen\:rounded-lg {
    border-radius: 0.5rem;
  }

  .widescreen\:rounded-xl {
    border-radius: 0.75rem;
  }

  .widescreen\:rounded-2xl {
    border-radius: 1rem;
  }

  .widescreen\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .widescreen\:rounded-full {
    border-radius: 9999px;
  }

  .widescreen\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .widescreen\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .widescreen\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .widescreen\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .widescreen\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .widescreen\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .widescreen\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .widescreen\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .widescreen\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .widescreen\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .widescreen\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .widescreen\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .widescreen\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .widescreen\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .widescreen\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .widescreen\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .widescreen\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .widescreen\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .widescreen\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .widescreen\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .widescreen\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .widescreen\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .widescreen\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .widescreen\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .widescreen\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .widescreen\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .widescreen\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .widescreen\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .widescreen\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .widescreen\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .widescreen\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .widescreen\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .widescreen\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .widescreen\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .widescreen\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .widescreen\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .widescreen\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .widescreen\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .widescreen\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .widescreen\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .widescreen\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .widescreen\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .widescreen\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .widescreen\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .widescreen\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .widescreen\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .widescreen\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .widescreen\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .widescreen\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .widescreen\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .widescreen\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .widescreen\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .widescreen\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .widescreen\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .widescreen\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .widescreen\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .widescreen\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .widescreen\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .widescreen\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .widescreen\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .widescreen\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .widescreen\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .widescreen\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .widescreen\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .widescreen\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .widescreen\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .widescreen\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .widescreen\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .widescreen\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .widescreen\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .widescreen\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .widescreen\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .widescreen\:border-solid {
    border-style: solid;
  }

  .widescreen\:border-dashed {
    border-style: dashed;
  }

  .widescreen\:border-dotted {
    border-style: dotted;
  }

  .widescreen\:border-double {
    border-style: double;
  }

  .widescreen\:border-none {
    border-style: none;
  }

  .widescreen\:border-0 {
    border-width: 0;
  }

  .widescreen\:border-2 {
    border-width: 2px;
  }

  .widescreen\:border-4 {
    border-width: 4px;
  }

  .widescreen\:border-8 {
    border-width: 8px;
  }

  .widescreen\:border {
    border-width: 1px;
  }

  .widescreen\:border-t-0 {
    border-top-width: 0;
  }

  .widescreen\:border-r-0 {
    border-right-width: 0;
  }

  .widescreen\:border-b-0 {
    border-bottom-width: 0;
  }

  .widescreen\:border-l-0 {
    border-left-width: 0;
  }

  .widescreen\:border-t-2 {
    border-top-width: 2px;
  }

  .widescreen\:border-r-2 {
    border-right-width: 2px;
  }

  .widescreen\:border-b-2 {
    border-bottom-width: 2px;
  }

  .widescreen\:border-l-2 {
    border-left-width: 2px;
  }

  .widescreen\:border-t-4 {
    border-top-width: 4px;
  }

  .widescreen\:border-r-4 {
    border-right-width: 4px;
  }

  .widescreen\:border-b-4 {
    border-bottom-width: 4px;
  }

  .widescreen\:border-l-4 {
    border-left-width: 4px;
  }

  .widescreen\:border-t-8 {
    border-top-width: 8px;
  }

  .widescreen\:border-r-8 {
    border-right-width: 8px;
  }

  .widescreen\:border-b-8 {
    border-bottom-width: 8px;
  }

  .widescreen\:border-l-8 {
    border-left-width: 8px;
  }

  .widescreen\:border-t {
    border-top-width: 1px;
  }

  .widescreen\:border-r {
    border-right-width: 1px;
  }

  .widescreen\:border-b {
    border-bottom-width: 1px;
  }

  .widescreen\:border-l {
    border-left-width: 1px;
  }

  .widescreen\:hover\:border-0:hover {
    border-width: 0;
  }

  .widescreen\:hover\:border-2:hover {
    border-width: 2px;
  }

  .widescreen\:hover\:border-4:hover {
    border-width: 4px;
  }

  .widescreen\:hover\:border-8:hover {
    border-width: 8px;
  }

  .widescreen\:hover\:border:hover {
    border-width: 1px;
  }

  .widescreen\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .widescreen\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .widescreen\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .widescreen\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .widescreen\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .widescreen\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .widescreen\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .widescreen\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .widescreen\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .widescreen\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .widescreen\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .widescreen\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .widescreen\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .widescreen\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .widescreen\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .widescreen\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .widescreen\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .widescreen\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .widescreen\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .widescreen\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .widescreen\:focus\:border-0:focus {
    border-width: 0;
  }

  .widescreen\:focus\:border-2:focus {
    border-width: 2px;
  }

  .widescreen\:focus\:border-4:focus {
    border-width: 4px;
  }

  .widescreen\:focus\:border-8:focus {
    border-width: 8px;
  }

  .widescreen\:focus\:border:focus {
    border-width: 1px;
  }

  .widescreen\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .widescreen\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .widescreen\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .widescreen\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .widescreen\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .widescreen\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .widescreen\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .widescreen\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .widescreen\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .widescreen\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .widescreen\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .widescreen\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .widescreen\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .widescreen\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .widescreen\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .widescreen\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .widescreen\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .widescreen\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .widescreen\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .widescreen\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .widescreen\:box-border {
    box-sizing: border-box;
  }

  .widescreen\:box-content {
    box-sizing: content-box;
  }

  .widescreen\:cursor-auto {
    cursor: auto;
  }

  .widescreen\:cursor-default {
    cursor: default;
  }

  .widescreen\:cursor-pointer {
    cursor: pointer;
  }

  .widescreen\:cursor-wait {
    cursor: wait;
  }

  .widescreen\:cursor-text {
    cursor: text;
  }

  .widescreen\:cursor-move {
    cursor: move;
  }

  .widescreen\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .widescreen\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .widescreen\:hover\:cursor-default:hover {
    cursor: default;
  }

  .widescreen\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .widescreen\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .widescreen\:hover\:cursor-text:hover {
    cursor: text;
  }

  .widescreen\:hover\:cursor-move:hover {
    cursor: move;
  }

  .widescreen\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .widescreen\:block {
    display: block;
  }

  .widescreen\:inline-block {
    display: inline-block;
  }

  .widescreen\:inline {
    display: inline;
  }

  .widescreen\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .widescreen\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .widescreen\:table {
    display: table;
  }

  .widescreen\:table-caption {
    display: table-caption;
  }

  .widescreen\:table-cell {
    display: table-cell;
  }

  .widescreen\:table-column {
    display: table-column;
  }

  .widescreen\:table-column-group {
    display: table-column-group;
  }

  .widescreen\:table-footer-group {
    display: table-footer-group;
  }

  .widescreen\:table-header-group {
    display: table-header-group;
  }

  .widescreen\:table-row-group {
    display: table-row-group;
  }

  .widescreen\:table-row {
    display: table-row;
  }

  .widescreen\:flow-root {
    display: flow-root;
  }

  .widescreen\:grid {
    display: grid;
  }

  .widescreen\:inline-grid {
    display: inline-grid;
  }

  .widescreen\:contents {
    display: contents;
  }

  .widescreen\:hidden {
    display: none;
  }

  .widescreen\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .widescreen\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .widescreen\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .widescreen\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .widescreen\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .widescreen\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .widescreen\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .widescreen\:place-items-auto {
    place-items: auto;
  }

  .widescreen\:place-items-start {
    place-items: start;
  }

  .widescreen\:place-items-end {
    place-items: end;
  }

  .widescreen\:place-items-center {
    place-items: center;
  }

  .widescreen\:place-items-stretch {
    place-items: stretch;
  }

  .widescreen\:place-content-center {
    place-content: center;
  }

  .widescreen\:place-content-start {
    place-content: start;
  }

  .widescreen\:place-content-end {
    place-content: end;
  }

  .widescreen\:place-content-between {
    place-content: space-between;
  }

  .widescreen\:place-content-around {
    place-content: space-around;
  }

  .widescreen\:place-content-evenly {
    place-content: space-evenly;
  }

  .widescreen\:place-content-stretch {
    place-content: stretch;
  }

  .widescreen\:place-self-auto {
    place-self: auto;
  }

  .widescreen\:place-self-start {
    place-self: start;
  }

  .widescreen\:place-self-end {
    place-self: end;
  }

  .widescreen\:place-self-center {
    place-self: center;
  }

  .widescreen\:place-self-stretch {
    place-self: stretch;
  }

  .widescreen\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .widescreen\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .widescreen\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .widescreen\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .widescreen\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .widescreen\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .widescreen\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .widescreen\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .widescreen\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .widescreen\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .widescreen\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .widescreen\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .widescreen\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .widescreen\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .widescreen\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .widescreen\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .widescreen\:justify-items-auto {
    justify-items: auto;
  }

  .widescreen\:justify-items-start {
    justify-items: start;
  }

  .widescreen\:justify-items-end {
    justify-items: end;
  }

  .widescreen\:justify-items-center {
    justify-items: center;
  }

  .widescreen\:justify-items-stretch {
    justify-items: stretch;
  }

  .widescreen\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .widescreen\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .widescreen\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .widescreen\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .widescreen\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .widescreen\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .widescreen\:justify-self-auto {
    justify-self: auto;
  }

  .widescreen\:justify-self-start {
    justify-self: start;
  }

  .widescreen\:justify-self-end {
    justify-self: end;
  }

  .widescreen\:justify-self-center {
    justify-self: center;
  }

  .widescreen\:justify-self-stretch {
    justify-self: stretch;
  }

  .widescreen\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .widescreen\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .widescreen\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .widescreen\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .widescreen\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .widescreen\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .widescreen\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .widescreen\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .widescreen\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .widescreen\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .widescreen\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .widescreen\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .widescreen\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .widescreen\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .widescreen\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .widescreen\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .widescreen\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .widescreen\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .widescreen\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .widescreen\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .widescreen\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .widescreen\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .widescreen\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .widescreen\:float-right {
    float: right;
  }

  .widescreen\:float-left {
    float: left;
  }

  .widescreen\:float-none {
    float: none;
  }

  .widescreen\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .widescreen\:clear-left {
    clear: left;
  }

  .widescreen\:clear-right {
    clear: right;
  }

  .widescreen\:clear-both {
    clear: both;
  }

  .widescreen\:clear-none {
    clear: none;
  }

  .widescreen\:font-hairline {
    font-weight: 100;
  }

  .widescreen\:font-thin {
    font-weight: 200;
  }

  .widescreen\:font-light {
    font-weight: 300;
  }

  .widescreen\:font-normal {
    font-weight: 400;
  }

  .widescreen\:font-medium {
    font-weight: 500;
  }

  .widescreen\:font-semibold {
    font-weight: 600;
  }

  .widescreen\:font-bold {
    font-weight: 700;
  }

  .widescreen\:font-extrabold {
    font-weight: 800;
  }

  .widescreen\:font-black {
    font-weight: 900;
  }

  .widescreen\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .widescreen\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .widescreen\:hover\:font-light:hover {
    font-weight: 300;
  }

  .widescreen\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .widescreen\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .widescreen\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .widescreen\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .widescreen\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .widescreen\:hover\:font-black:hover {
    font-weight: 900;
  }

  .widescreen\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .widescreen\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .widescreen\:focus\:font-light:focus {
    font-weight: 300;
  }

  .widescreen\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .widescreen\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .widescreen\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .widescreen\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .widescreen\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .widescreen\:focus\:font-black:focus {
    font-weight: 900;
  }

  .widescreen\:h-1 {
    height: 5px;
  }

  .widescreen\:h-2 {
    height: 10px;
  }

  .widescreen\:h-3 {
    height: 15px;
  }

  .widescreen\:h-4 {
    height: 30px;
  }

  .widescreen\:h-5 {
    height: 50px;
  }

  .widescreen\:h-6 {
    height: 75px;
  }

  .widescreen\:h-7 {
    height: 100px;
  }

  .widescreen\:h-logo-height-small {
    height: 55px;
  }

  .widescreen\:h-logo-height-large {
    height: 65px;
  }

  .widescreen\:leading-3 {
    line-height: .75rem;
  }

  .widescreen\:leading-4 {
    line-height: 1rem;
  }

  .widescreen\:leading-5 {
    line-height: 1.25rem;
  }

  .widescreen\:leading-6 {
    line-height: 1.5rem;
  }

  .widescreen\:leading-7 {
    line-height: 1.75rem;
  }

  .widescreen\:leading-8 {
    line-height: 2rem;
  }

  .widescreen\:leading-9 {
    line-height: 2.25rem;
  }

  .widescreen\:leading-10 {
    line-height: 2.5rem;
  }

  .widescreen\:leading-none {
    line-height: 1;
  }

  .widescreen\:leading-tight {
    line-height: 1.25;
  }

  .widescreen\:leading-snug {
    line-height: 1.375;
  }

  .widescreen\:leading-normal {
    line-height: 1.5;
  }

  .widescreen\:leading-relaxed {
    line-height: 1.625;
  }

  .widescreen\:leading-loose {
    line-height: 2;
  }

  .widescreen\:list-inside {
    list-style-position: inside;
  }

  .widescreen\:list-outside {
    list-style-position: outside;
  }

  .widescreen\:list-none {
    list-style-type: none;
  }

  .widescreen\:list-disc {
    list-style-type: disc;
  }

  .widescreen\:list-decimal {
    list-style-type: decimal;
  }

  .widescreen\:m-1 {
    margin: 5px;
  }

  .widescreen\:m-2 {
    margin: 10px;
  }

  .widescreen\:m-3 {
    margin: 15px;
  }

  .widescreen\:m-4 {
    margin: 30px;
  }

  .widescreen\:m-5 {
    margin: 50px;
  }

  .widescreen\:m-6 {
    margin: 75px;
  }

  .widescreen\:m-7 {
    margin: 100px;
  }

  .widescreen\:m-auto {
    margin: auto;
  }

  .widescreen\:m-none {
    margin: 0px;
  }

  .widescreen\:m-tiny {
    margin: 10px;
  }

  .widescreen\:m-small {
    margin: 20px;
  }

  .widescreen\:m-medium {
    margin: 30px;
  }

  .widescreen\:m-large {
    margin: 40px;
  }

  .widescreen\:m-huge {
    margin: 60px;
  }

  .widescreen\:m-margin-label-height {
    margin: 26px;
  }

  .widescreen\:m-nav-height {
    margin: 80px;
  }

  .widescreen\:-m-1 {
    margin: -5px;
  }

  .widescreen\:-m-2 {
    margin: -10px;
  }

  .widescreen\:-m-3 {
    margin: -15px;
  }

  .widescreen\:-m-4 {
    margin: -30px;
  }

  .widescreen\:-m-5 {
    margin: -50px;
  }

  .widescreen\:-m-6 {
    margin: -75px;
  }

  .widescreen\:-m-7 {
    margin: -100px;
  }

  .widescreen\:-m-none {
    margin: 0px;
  }

  .widescreen\:-m-tiny {
    margin: -10px;
  }

  .widescreen\:-m-small {
    margin: -20px;
  }

  .widescreen\:-m-medium {
    margin: -30px;
  }

  .widescreen\:-m-large {
    margin: -40px;
  }

  .widescreen\:-m-huge {
    margin: -60px;
  }

  .widescreen\:-m-margin-label-height {
    margin: -26px;
  }

  .widescreen\:-m-nav-height {
    margin: -80px;
  }

  .widescreen\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:mt-1 {
    margin-top: 5px;
  }

  .widescreen\:mr-1 {
    margin-right: 5px;
  }

  .widescreen\:mb-1 {
    margin-bottom: 5px;
  }

  .widescreen\:ml-1 {
    margin-left: 5px;
  }

  .widescreen\:mt-2 {
    margin-top: 10px;
  }

  .widescreen\:mr-2 {
    margin-right: 10px;
  }

  .widescreen\:mb-2 {
    margin-bottom: 10px;
  }

  .widescreen\:ml-2 {
    margin-left: 10px;
  }

  .widescreen\:mt-3 {
    margin-top: 15px;
  }

  .widescreen\:mr-3 {
    margin-right: 15px;
  }

  .widescreen\:mb-3 {
    margin-bottom: 15px;
  }

  .widescreen\:ml-3 {
    margin-left: 15px;
  }

  .widescreen\:mt-4 {
    margin-top: 30px;
  }

  .widescreen\:mr-4 {
    margin-right: 30px;
  }

  .widescreen\:mb-4 {
    margin-bottom: 30px;
  }

  .widescreen\:ml-4 {
    margin-left: 30px;
  }

  .widescreen\:mt-5 {
    margin-top: 50px;
  }

  .widescreen\:mr-5 {
    margin-right: 50px;
  }

  .widescreen\:mb-5 {
    margin-bottom: 50px;
  }

  .widescreen\:ml-5 {
    margin-left: 50px;
  }

  .widescreen\:mt-6 {
    margin-top: 75px;
  }

  .widescreen\:mr-6 {
    margin-right: 75px;
  }

  .widescreen\:mb-6 {
    margin-bottom: 75px;
  }

  .widescreen\:ml-6 {
    margin-left: 75px;
  }

  .widescreen\:mt-7 {
    margin-top: 100px;
  }

  .widescreen\:mr-7 {
    margin-right: 100px;
  }

  .widescreen\:mb-7 {
    margin-bottom: 100px;
  }

  .widescreen\:ml-7 {
    margin-left: 100px;
  }

  .widescreen\:mt-auto {
    margin-top: auto;
  }

  .widescreen\:mr-auto {
    margin-right: auto;
  }

  .widescreen\:mb-auto {
    margin-bottom: auto;
  }

  .widescreen\:ml-auto {
    margin-left: auto;
  }

  .widescreen\:mt-none {
    margin-top: 0px;
  }

  .widescreen\:mr-none {
    margin-right: 0px;
  }

  .widescreen\:mb-none {
    margin-bottom: 0px;
  }

  .widescreen\:ml-none {
    margin-left: 0px;
  }

  .widescreen\:mt-tiny {
    margin-top: 10px;
  }

  .widescreen\:mr-tiny {
    margin-right: 10px;
  }

  .widescreen\:mb-tiny {
    margin-bottom: 10px;
  }

  .widescreen\:ml-tiny {
    margin-left: 10px;
  }

  .widescreen\:mt-small {
    margin-top: 20px;
  }

  .widescreen\:mr-small {
    margin-right: 20px;
  }

  .widescreen\:mb-small {
    margin-bottom: 20px;
  }

  .widescreen\:ml-small {
    margin-left: 20px;
  }

  .widescreen\:mt-medium {
    margin-top: 30px;
  }

  .widescreen\:mr-medium {
    margin-right: 30px;
  }

  .widescreen\:mb-medium {
    margin-bottom: 30px;
  }

  .widescreen\:ml-medium {
    margin-left: 30px;
  }

  .widescreen\:mt-large {
    margin-top: 40px;
  }

  .widescreen\:mr-large {
    margin-right: 40px;
  }

  .widescreen\:mb-large {
    margin-bottom: 40px;
  }

  .widescreen\:ml-large {
    margin-left: 40px;
  }

  .widescreen\:mt-huge {
    margin-top: 60px;
  }

  .widescreen\:mr-huge {
    margin-right: 60px;
  }

  .widescreen\:mb-huge {
    margin-bottom: 60px;
  }

  .widescreen\:ml-huge {
    margin-left: 60px;
  }

  .widescreen\:mt-margin-label-height {
    margin-top: 26px;
  }

  .widescreen\:mr-margin-label-height {
    margin-right: 26px;
  }

  .widescreen\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .widescreen\:ml-margin-label-height {
    margin-left: 26px;
  }

  .widescreen\:mt-nav-height {
    margin-top: 80px;
  }

  .widescreen\:mr-nav-height {
    margin-right: 80px;
  }

  .widescreen\:mb-nav-height {
    margin-bottom: 80px;
  }

  .widescreen\:ml-nav-height {
    margin-left: 80px;
  }

  .widescreen\:-mt-1 {
    margin-top: -5px;
  }

  .widescreen\:-mr-1 {
    margin-right: -5px;
  }

  .widescreen\:-mb-1 {
    margin-bottom: -5px;
  }

  .widescreen\:-ml-1 {
    margin-left: -5px;
  }

  .widescreen\:-mt-2 {
    margin-top: -10px;
  }

  .widescreen\:-mr-2 {
    margin-right: -10px;
  }

  .widescreen\:-mb-2 {
    margin-bottom: -10px;
  }

  .widescreen\:-ml-2 {
    margin-left: -10px;
  }

  .widescreen\:-mt-3 {
    margin-top: -15px;
  }

  .widescreen\:-mr-3 {
    margin-right: -15px;
  }

  .widescreen\:-mb-3 {
    margin-bottom: -15px;
  }

  .widescreen\:-ml-3 {
    margin-left: -15px;
  }

  .widescreen\:-mt-4 {
    margin-top: -30px;
  }

  .widescreen\:-mr-4 {
    margin-right: -30px;
  }

  .widescreen\:-mb-4 {
    margin-bottom: -30px;
  }

  .widescreen\:-ml-4 {
    margin-left: -30px;
  }

  .widescreen\:-mt-5 {
    margin-top: -50px;
  }

  .widescreen\:-mr-5 {
    margin-right: -50px;
  }

  .widescreen\:-mb-5 {
    margin-bottom: -50px;
  }

  .widescreen\:-ml-5 {
    margin-left: -50px;
  }

  .widescreen\:-mt-6 {
    margin-top: -75px;
  }

  .widescreen\:-mr-6 {
    margin-right: -75px;
  }

  .widescreen\:-mb-6 {
    margin-bottom: -75px;
  }

  .widescreen\:-ml-6 {
    margin-left: -75px;
  }

  .widescreen\:-mt-7 {
    margin-top: -100px;
  }

  .widescreen\:-mr-7 {
    margin-right: -100px;
  }

  .widescreen\:-mb-7 {
    margin-bottom: -100px;
  }

  .widescreen\:-ml-7 {
    margin-left: -100px;
  }

  .widescreen\:-mt-none {
    margin-top: 0px;
  }

  .widescreen\:-mr-none {
    margin-right: 0px;
  }

  .widescreen\:-mb-none {
    margin-bottom: 0px;
  }

  .widescreen\:-ml-none {
    margin-left: 0px;
  }

  .widescreen\:-mt-tiny {
    margin-top: -10px;
  }

  .widescreen\:-mr-tiny {
    margin-right: -10px;
  }

  .widescreen\:-mb-tiny {
    margin-bottom: -10px;
  }

  .widescreen\:-ml-tiny {
    margin-left: -10px;
  }

  .widescreen\:-mt-small {
    margin-top: -20px;
  }

  .widescreen\:-mr-small {
    margin-right: -20px;
  }

  .widescreen\:-mb-small {
    margin-bottom: -20px;
  }

  .widescreen\:-ml-small {
    margin-left: -20px;
  }

  .widescreen\:-mt-medium {
    margin-top: -30px;
  }

  .widescreen\:-mr-medium {
    margin-right: -30px;
  }

  .widescreen\:-mb-medium {
    margin-bottom: -30px;
  }

  .widescreen\:-ml-medium {
    margin-left: -30px;
  }

  .widescreen\:-mt-large {
    margin-top: -40px;
  }

  .widescreen\:-mr-large {
    margin-right: -40px;
  }

  .widescreen\:-mb-large {
    margin-bottom: -40px;
  }

  .widescreen\:-ml-large {
    margin-left: -40px;
  }

  .widescreen\:-mt-huge {
    margin-top: -60px;
  }

  .widescreen\:-mr-huge {
    margin-right: -60px;
  }

  .widescreen\:-mb-huge {
    margin-bottom: -60px;
  }

  .widescreen\:-ml-huge {
    margin-left: -60px;
  }

  .widescreen\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .widescreen\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .widescreen\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .widescreen\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .widescreen\:-mt-nav-height {
    margin-top: -80px;
  }

  .widescreen\:-mr-nav-height {
    margin-right: -80px;
  }

  .widescreen\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .widescreen\:-ml-nav-height {
    margin-left: -80px;
  }

  .widescreen\:hover\:m-1:hover {
    margin: 5px;
  }

  .widescreen\:hover\:m-2:hover {
    margin: 10px;
  }

  .widescreen\:hover\:m-3:hover {
    margin: 15px;
  }

  .widescreen\:hover\:m-4:hover {
    margin: 30px;
  }

  .widescreen\:hover\:m-5:hover {
    margin: 50px;
  }

  .widescreen\:hover\:m-6:hover {
    margin: 75px;
  }

  .widescreen\:hover\:m-7:hover {
    margin: 100px;
  }

  .widescreen\:hover\:m-auto:hover {
    margin: auto;
  }

  .widescreen\:hover\:m-none:hover {
    margin: 0px;
  }

  .widescreen\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .widescreen\:hover\:m-small:hover {
    margin: 20px;
  }

  .widescreen\:hover\:m-medium:hover {
    margin: 30px;
  }

  .widescreen\:hover\:m-large:hover {
    margin: 40px;
  }

  .widescreen\:hover\:m-huge:hover {
    margin: 60px;
  }

  .widescreen\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .widescreen\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .widescreen\:hover\:-m-1:hover {
    margin: -5px;
  }

  .widescreen\:hover\:-m-2:hover {
    margin: -10px;
  }

  .widescreen\:hover\:-m-3:hover {
    margin: -15px;
  }

  .widescreen\:hover\:-m-4:hover {
    margin: -30px;
  }

  .widescreen\:hover\:-m-5:hover {
    margin: -50px;
  }

  .widescreen\:hover\:-m-6:hover {
    margin: -75px;
  }

  .widescreen\:hover\:-m-7:hover {
    margin: -100px;
  }

  .widescreen\:hover\:-m-none:hover {
    margin: 0px;
  }

  .widescreen\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .widescreen\:hover\:-m-small:hover {
    margin: -20px;
  }

  .widescreen\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .widescreen\:hover\:-m-large:hover {
    margin: -40px;
  }

  .widescreen\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .widescreen\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .widescreen\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .widescreen\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .widescreen\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .widescreen\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .widescreen\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .widescreen\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .widescreen\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .widescreen\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .widescreen\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .widescreen\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .widescreen\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .widescreen\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .widescreen\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .widescreen\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .widescreen\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .widescreen\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .widescreen\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .widescreen\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .widescreen\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .widescreen\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .widescreen\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .widescreen\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .widescreen\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .widescreen\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .widescreen\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .widescreen\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .widescreen\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .widescreen\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .widescreen\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .widescreen\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .widescreen\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .widescreen\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .widescreen\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .widescreen\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .widescreen\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .widescreen\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .widescreen\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .widescreen\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .widescreen\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .widescreen\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .widescreen\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .widescreen\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .widescreen\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .widescreen\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .widescreen\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .widescreen\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .widescreen\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .widescreen\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .widescreen\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .widescreen\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .widescreen\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .widescreen\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .widescreen\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .widescreen\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .widescreen\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .widescreen\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .widescreen\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .widescreen\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .widescreen\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .widescreen\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .widescreen\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .widescreen\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .widescreen\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .widescreen\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .widescreen\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .widescreen\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .widescreen\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .widescreen\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .widescreen\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .widescreen\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .widescreen\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .widescreen\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .widescreen\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .widescreen\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .widescreen\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .widescreen\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .widescreen\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .widescreen\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .widescreen\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .widescreen\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .widescreen\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .widescreen\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .widescreen\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .widescreen\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .widescreen\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .widescreen\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .widescreen\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .widescreen\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .widescreen\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .widescreen\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .widescreen\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .widescreen\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .widescreen\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .widescreen\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .widescreen\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .widescreen\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .widescreen\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .widescreen\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .widescreen\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .widescreen\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .widescreen\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .widescreen\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .widescreen\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .widescreen\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .widescreen\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .widescreen\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .widescreen\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .widescreen\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .widescreen\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .widescreen\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .widescreen\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .widescreen\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .widescreen\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .widescreen\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .widescreen\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .widescreen\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .widescreen\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .widescreen\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .widescreen\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .widescreen\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .widescreen\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .widescreen\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .widescreen\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .widescreen\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .widescreen\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .widescreen\:focus\:m-1:focus {
    margin: 5px;
  }

  .widescreen\:focus\:m-2:focus {
    margin: 10px;
  }

  .widescreen\:focus\:m-3:focus {
    margin: 15px;
  }

  .widescreen\:focus\:m-4:focus {
    margin: 30px;
  }

  .widescreen\:focus\:m-5:focus {
    margin: 50px;
  }

  .widescreen\:focus\:m-6:focus {
    margin: 75px;
  }

  .widescreen\:focus\:m-7:focus {
    margin: 100px;
  }

  .widescreen\:focus\:m-auto:focus {
    margin: auto;
  }

  .widescreen\:focus\:m-none:focus {
    margin: 0px;
  }

  .widescreen\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .widescreen\:focus\:m-small:focus {
    margin: 20px;
  }

  .widescreen\:focus\:m-medium:focus {
    margin: 30px;
  }

  .widescreen\:focus\:m-large:focus {
    margin: 40px;
  }

  .widescreen\:focus\:m-huge:focus {
    margin: 60px;
  }

  .widescreen\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .widescreen\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .widescreen\:focus\:-m-1:focus {
    margin: -5px;
  }

  .widescreen\:focus\:-m-2:focus {
    margin: -10px;
  }

  .widescreen\:focus\:-m-3:focus {
    margin: -15px;
  }

  .widescreen\:focus\:-m-4:focus {
    margin: -30px;
  }

  .widescreen\:focus\:-m-5:focus {
    margin: -50px;
  }

  .widescreen\:focus\:-m-6:focus {
    margin: -75px;
  }

  .widescreen\:focus\:-m-7:focus {
    margin: -100px;
  }

  .widescreen\:focus\:-m-none:focus {
    margin: 0px;
  }

  .widescreen\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .widescreen\:focus\:-m-small:focus {
    margin: -20px;
  }

  .widescreen\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .widescreen\:focus\:-m-large:focus {
    margin: -40px;
  }

  .widescreen\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .widescreen\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .widescreen\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .widescreen\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .widescreen\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .widescreen\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .widescreen\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .widescreen\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .widescreen\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .widescreen\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .widescreen\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .widescreen\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .widescreen\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .widescreen\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .widescreen\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .widescreen\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .widescreen\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .widescreen\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .widescreen\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .widescreen\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .widescreen\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .widescreen\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .widescreen\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .widescreen\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .widescreen\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .widescreen\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .widescreen\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .widescreen\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .widescreen\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .widescreen\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .widescreen\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .widescreen\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .widescreen\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .widescreen\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .widescreen\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .widescreen\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .widescreen\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .widescreen\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .widescreen\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .widescreen\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .widescreen\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .widescreen\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .widescreen\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .widescreen\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .widescreen\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .widescreen\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .widescreen\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .widescreen\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .widescreen\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .widescreen\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .widescreen\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .widescreen\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .widescreen\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .widescreen\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .widescreen\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .widescreen\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .widescreen\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .widescreen\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .widescreen\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .widescreen\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .widescreen\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .widescreen\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .widescreen\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .widescreen\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .widescreen\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .widescreen\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .widescreen\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .widescreen\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .widescreen\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .widescreen\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .widescreen\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .widescreen\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .widescreen\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .widescreen\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .widescreen\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .widescreen\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .widescreen\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .widescreen\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .widescreen\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .widescreen\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .widescreen\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .widescreen\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .widescreen\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .widescreen\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .widescreen\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .widescreen\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .widescreen\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .widescreen\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .widescreen\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .widescreen\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .widescreen\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .widescreen\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .widescreen\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .widescreen\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .widescreen\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .widescreen\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .widescreen\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .widescreen\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .widescreen\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .widescreen\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .widescreen\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .widescreen\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .widescreen\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .widescreen\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .widescreen\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .widescreen\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .widescreen\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .widescreen\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .widescreen\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .widescreen\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .widescreen\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .widescreen\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .widescreen\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .widescreen\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .widescreen\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .widescreen\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .widescreen\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .widescreen\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .widescreen\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .widescreen\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .widescreen\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .widescreen\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .widescreen\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .widescreen\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .widescreen\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .widescreen\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .widescreen\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .widescreen\:first\:m-1:first-child {
    margin: 5px;
  }

  .widescreen\:first\:m-2:first-child {
    margin: 10px;
  }

  .widescreen\:first\:m-3:first-child {
    margin: 15px;
  }

  .widescreen\:first\:m-4:first-child {
    margin: 30px;
  }

  .widescreen\:first\:m-5:first-child {
    margin: 50px;
  }

  .widescreen\:first\:m-6:first-child {
    margin: 75px;
  }

  .widescreen\:first\:m-7:first-child {
    margin: 100px;
  }

  .widescreen\:first\:m-auto:first-child {
    margin: auto;
  }

  .widescreen\:first\:m-none:first-child {
    margin: 0px;
  }

  .widescreen\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .widescreen\:first\:m-small:first-child {
    margin: 20px;
  }

  .widescreen\:first\:m-medium:first-child {
    margin: 30px;
  }

  .widescreen\:first\:m-large:first-child {
    margin: 40px;
  }

  .widescreen\:first\:m-huge:first-child {
    margin: 60px;
  }

  .widescreen\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .widescreen\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .widescreen\:first\:-m-1:first-child {
    margin: -5px;
  }

  .widescreen\:first\:-m-2:first-child {
    margin: -10px;
  }

  .widescreen\:first\:-m-3:first-child {
    margin: -15px;
  }

  .widescreen\:first\:-m-4:first-child {
    margin: -30px;
  }

  .widescreen\:first\:-m-5:first-child {
    margin: -50px;
  }

  .widescreen\:first\:-m-6:first-child {
    margin: -75px;
  }

  .widescreen\:first\:-m-7:first-child {
    margin: -100px;
  }

  .widescreen\:first\:-m-none:first-child {
    margin: 0px;
  }

  .widescreen\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .widescreen\:first\:-m-small:first-child {
    margin: -20px;
  }

  .widescreen\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .widescreen\:first\:-m-large:first-child {
    margin: -40px;
  }

  .widescreen\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .widescreen\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .widescreen\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .widescreen\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .widescreen\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .widescreen\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .widescreen\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .widescreen\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .widescreen\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .widescreen\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .widescreen\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .widescreen\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .widescreen\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .widescreen\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .widescreen\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .widescreen\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .widescreen\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .widescreen\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .widescreen\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .widescreen\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .widescreen\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .widescreen\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .widescreen\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .widescreen\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .widescreen\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .widescreen\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .widescreen\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .widescreen\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .widescreen\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .widescreen\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .widescreen\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .widescreen\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .widescreen\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .widescreen\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .widescreen\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .widescreen\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .widescreen\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .widescreen\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .widescreen\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .widescreen\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .widescreen\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .widescreen\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .widescreen\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .widescreen\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .widescreen\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .widescreen\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .widescreen\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .widescreen\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .widescreen\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .widescreen\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .widescreen\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .widescreen\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .widescreen\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .widescreen\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .widescreen\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .widescreen\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .widescreen\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .widescreen\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .widescreen\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .widescreen\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .widescreen\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .widescreen\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .widescreen\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .widescreen\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .widescreen\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .widescreen\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .widescreen\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .widescreen\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .widescreen\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .widescreen\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .widescreen\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .widescreen\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .widescreen\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .widescreen\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .widescreen\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .widescreen\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .widescreen\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .widescreen\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .widescreen\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .widescreen\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .widescreen\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .widescreen\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .widescreen\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .widescreen\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .widescreen\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .widescreen\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .widescreen\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .widescreen\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .widescreen\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .widescreen\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .widescreen\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .widescreen\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .widescreen\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .widescreen\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .widescreen\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .widescreen\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .widescreen\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .widescreen\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .widescreen\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .widescreen\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .widescreen\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .widescreen\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .widescreen\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .widescreen\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .widescreen\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .widescreen\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .widescreen\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .widescreen\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .widescreen\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .widescreen\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .widescreen\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .widescreen\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .widescreen\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .widescreen\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .widescreen\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .widescreen\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .widescreen\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .widescreen\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .widescreen\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .widescreen\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .widescreen\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .widescreen\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .widescreen\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .widescreen\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .widescreen\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .widescreen\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .widescreen\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .widescreen\:last\:m-1:last-child {
    margin: 5px;
  }

  .widescreen\:last\:m-2:last-child {
    margin: 10px;
  }

  .widescreen\:last\:m-3:last-child {
    margin: 15px;
  }

  .widescreen\:last\:m-4:last-child {
    margin: 30px;
  }

  .widescreen\:last\:m-5:last-child {
    margin: 50px;
  }

  .widescreen\:last\:m-6:last-child {
    margin: 75px;
  }

  .widescreen\:last\:m-7:last-child {
    margin: 100px;
  }

  .widescreen\:last\:m-auto:last-child {
    margin: auto;
  }

  .widescreen\:last\:m-none:last-child {
    margin: 0px;
  }

  .widescreen\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .widescreen\:last\:m-small:last-child {
    margin: 20px;
  }

  .widescreen\:last\:m-medium:last-child {
    margin: 30px;
  }

  .widescreen\:last\:m-large:last-child {
    margin: 40px;
  }

  .widescreen\:last\:m-huge:last-child {
    margin: 60px;
  }

  .widescreen\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .widescreen\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .widescreen\:last\:-m-1:last-child {
    margin: -5px;
  }

  .widescreen\:last\:-m-2:last-child {
    margin: -10px;
  }

  .widescreen\:last\:-m-3:last-child {
    margin: -15px;
  }

  .widescreen\:last\:-m-4:last-child {
    margin: -30px;
  }

  .widescreen\:last\:-m-5:last-child {
    margin: -50px;
  }

  .widescreen\:last\:-m-6:last-child {
    margin: -75px;
  }

  .widescreen\:last\:-m-7:last-child {
    margin: -100px;
  }

  .widescreen\:last\:-m-none:last-child {
    margin: 0px;
  }

  .widescreen\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .widescreen\:last\:-m-small:last-child {
    margin: -20px;
  }

  .widescreen\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .widescreen\:last\:-m-large:last-child {
    margin: -40px;
  }

  .widescreen\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .widescreen\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .widescreen\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .widescreen\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .widescreen\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .widescreen\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .widescreen\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .widescreen\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .widescreen\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .widescreen\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .widescreen\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .widescreen\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .widescreen\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .widescreen\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .widescreen\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .widescreen\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .widescreen\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .widescreen\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .widescreen\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .widescreen\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .widescreen\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .widescreen\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .widescreen\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .widescreen\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .widescreen\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .widescreen\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .widescreen\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .widescreen\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .widescreen\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .widescreen\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .widescreen\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .widescreen\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .widescreen\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .widescreen\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .widescreen\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .widescreen\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .widescreen\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .widescreen\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .widescreen\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .widescreen\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .widescreen\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .widescreen\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .widescreen\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .widescreen\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .widescreen\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .widescreen\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .widescreen\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .widescreen\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .widescreen\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .widescreen\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .widescreen\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .widescreen\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .widescreen\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .widescreen\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .widescreen\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .widescreen\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .widescreen\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .widescreen\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .widescreen\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .widescreen\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .widescreen\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .widescreen\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .widescreen\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .widescreen\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .widescreen\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .widescreen\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .widescreen\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .widescreen\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .widescreen\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .widescreen\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .widescreen\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .widescreen\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .widescreen\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .widescreen\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .widescreen\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .widescreen\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .widescreen\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .widescreen\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .widescreen\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .widescreen\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .widescreen\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .widescreen\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .widescreen\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .widescreen\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .widescreen\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .widescreen\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .widescreen\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .widescreen\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .widescreen\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .widescreen\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .widescreen\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .widescreen\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .widescreen\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .widescreen\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .widescreen\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .widescreen\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .widescreen\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .widescreen\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .widescreen\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .widescreen\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .widescreen\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .widescreen\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .widescreen\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .widescreen\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .widescreen\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .widescreen\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .widescreen\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .widescreen\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .widescreen\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .widescreen\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .widescreen\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .widescreen\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .widescreen\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .widescreen\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .widescreen\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .widescreen\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .widescreen\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .widescreen\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .widescreen\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .widescreen\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .widescreen\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .widescreen\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .widescreen\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .widescreen\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .widescreen\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .widescreen\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .widescreen\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .widescreen\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .widescreen\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .widescreen\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .widescreen\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .widescreen\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .widescreen\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .widescreen\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .widescreen\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .widescreen\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .widescreen\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .widescreen\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .widescreen\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .widescreen\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .widescreen\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .widescreen\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .widescreen\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .widescreen\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .widescreen\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .widescreen\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .widescreen\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .widescreen\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .widescreen\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .widescreen\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .widescreen\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .widescreen\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .widescreen\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .widescreen\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .widescreen\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .widescreen\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .widescreen\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .widescreen\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .widescreen\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .widescreen\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .widescreen\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .widescreen\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .widescreen\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .widescreen\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .widescreen\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .widescreen\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .widescreen\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .widescreen\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .widescreen\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .widescreen\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .widescreen\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .widescreen\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .widescreen\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .widescreen\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .widescreen\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .widescreen\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .widescreen\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .widescreen\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .widescreen\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .widescreen\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .widescreen\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .widescreen\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .widescreen\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .widescreen\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .widescreen\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .widescreen\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .widescreen\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .widescreen\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .widescreen\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .widescreen\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .widescreen\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .widescreen\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .widescreen\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .widescreen\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .widescreen\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .widescreen\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .widescreen\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .widescreen\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .widescreen\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .widescreen\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .widescreen\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .widescreen\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .widescreen\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .widescreen\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .widescreen\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .widescreen\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .widescreen\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .widescreen\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .widescreen\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .widescreen\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .widescreen\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .widescreen\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .widescreen\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .widescreen\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .widescreen\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .widescreen\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .widescreen\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .widescreen\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .widescreen\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .widescreen\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .widescreen\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .widescreen\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .widescreen\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .widescreen\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .widescreen\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .widescreen\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .widescreen\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .widescreen\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .widescreen\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .widescreen\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .widescreen\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .widescreen\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .widescreen\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .widescreen\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .widescreen\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .widescreen\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .widescreen\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .widescreen\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .widescreen\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .widescreen\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .widescreen\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .widescreen\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .widescreen\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .widescreen\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .widescreen\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .widescreen\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .widescreen\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .widescreen\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .widescreen\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .widescreen\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .widescreen\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .widescreen\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .widescreen\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .widescreen\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .widescreen\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .widescreen\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .widescreen\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .widescreen\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .widescreen\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .widescreen\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .widescreen\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .widescreen\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .widescreen\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .widescreen\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .widescreen\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .widescreen\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .widescreen\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .widescreen\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .widescreen\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .widescreen\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .widescreen\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .widescreen\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .widescreen\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .widescreen\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .widescreen\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .widescreen\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .widescreen\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .widescreen\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .widescreen\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .widescreen\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .widescreen\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .widescreen\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .widescreen\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .widescreen\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .widescreen\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .widescreen\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .widescreen\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .widescreen\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .widescreen\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .widescreen\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .widescreen\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .widescreen\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .widescreen\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .widescreen\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .widescreen\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .widescreen\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .widescreen\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .widescreen\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .widescreen\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .widescreen\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .widescreen\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .widescreen\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .widescreen\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .widescreen\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .widescreen\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .widescreen\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .widescreen\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .widescreen\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .widescreen\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .widescreen\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .widescreen\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .widescreen\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .widescreen\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .widescreen\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .widescreen\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .widescreen\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .widescreen\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .widescreen\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .widescreen\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .widescreen\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .widescreen\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .widescreen\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .widescreen\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .widescreen\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .widescreen\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .widescreen\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .widescreen\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .widescreen\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .widescreen\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .widescreen\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widescreen\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .widescreen\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widescreen\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .widescreen\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .widescreen\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .widescreen\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .widescreen\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .widescreen\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .widescreen\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .widescreen\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .widescreen\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .widescreen\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .widescreen\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .widescreen\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .widescreen\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .widescreen\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .widescreen\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .widescreen\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .widescreen\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .widescreen\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .widescreen\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .widescreen\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .widescreen\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .widescreen\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .widescreen\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widescreen\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .widescreen\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .widescreen\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .widescreen\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .widescreen\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .widescreen\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .widescreen\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .widescreen\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .widescreen\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .widescreen\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .widescreen\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .widescreen\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .widescreen\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .widescreen\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .widescreen\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .widescreen\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .widescreen\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .widescreen\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .widescreen\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .widescreen\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .widescreen\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .widescreen\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .widescreen\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .widescreen\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .widescreen\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .widescreen\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .widescreen\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .widescreen\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .widescreen\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .widescreen\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .widescreen\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .widescreen\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .widescreen\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .widescreen\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .widescreen\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .widescreen\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .widescreen\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .widescreen\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .widescreen\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .widescreen\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .widescreen\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .widescreen\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .widescreen\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .widescreen\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .widescreen\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .widescreen\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .widescreen\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .widescreen\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .widescreen\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .widescreen\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .widescreen\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .widescreen\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .widescreen\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .widescreen\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .widescreen\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .widescreen\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .widescreen\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .widescreen\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .widescreen\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .widescreen\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .widescreen\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .widescreen\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .widescreen\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .widescreen\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .widescreen\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .widescreen\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .widescreen\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .widescreen\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .widescreen\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .widescreen\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .widescreen\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .widescreen\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .widescreen\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .widescreen\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .widescreen\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .widescreen\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .widescreen\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .widescreen\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .widescreen\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .widescreen\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .widescreen\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .widescreen\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .widescreen\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .widescreen\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .widescreen\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .widescreen\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .widescreen\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .widescreen\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .widescreen\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .widescreen\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .widescreen\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .widescreen\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .widescreen\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .widescreen\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .widescreen\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .widescreen\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .widescreen\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .widescreen\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .widescreen\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .widescreen\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .widescreen\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .widescreen\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .widescreen\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .widescreen\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .widescreen\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .widescreen\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .widescreen\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .widescreen\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .widescreen\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .widescreen\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .widescreen\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .widescreen\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .widescreen\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .widescreen\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .widescreen\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .widescreen\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .widescreen\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .widescreen\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .widescreen\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .widescreen\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .widescreen\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .widescreen\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .widescreen\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .widescreen\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .widescreen\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .widescreen\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .widescreen\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .widescreen\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .widescreen\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .widescreen\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .widescreen\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .widescreen\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .widescreen\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .widescreen\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .widescreen\:max-h-full {
    max-height: 100%;
  }

  .widescreen\:max-h-screen {
    max-height: 100vh;
  }

  .widescreen\:max-w-none {
    max-width: none;
  }

  .widescreen\:max-w-xs {
    max-width: 20rem;
  }

  .widescreen\:max-w-sm {
    max-width: 24rem;
  }

  .widescreen\:max-w-md {
    max-width: 28rem;
  }

  .widescreen\:max-w-lg {
    max-width: 32rem;
  }

  .widescreen\:max-w-xl {
    max-width: 36rem;
  }

  .widescreen\:max-w-2xl {
    max-width: 42rem;
  }

  .widescreen\:max-w-3xl {
    max-width: 48rem;
  }

  .widescreen\:max-w-4xl {
    max-width: 56rem;
  }

  .widescreen\:max-w-5xl {
    max-width: 64rem;
  }

  .widescreen\:max-w-6xl {
    max-width: 72rem;
  }

  .widescreen\:max-w-full {
    max-width: 100%;
  }

  .widescreen\:max-w-screen-tablet {
    max-width: 801px;
  }

  .widescreen\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .widescreen\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .widescreen\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .widescreen\:min-h-0 {
    min-height: 0;
  }

  .widescreen\:min-h-full {
    min-height: 100%;
  }

  .widescreen\:min-h-screen {
    min-height: 100vh;
  }

  .widescreen\:min-w-0 {
    min-width: 0;
  }

  .widescreen\:min-w-full {
    min-width: 100%;
  }

  .widescreen\:object-contain {
    object-fit: contain;
  }

  .widescreen\:object-cover {
    object-fit: cover;
  }

  .widescreen\:object-fill {
    object-fit: fill;
  }

  .widescreen\:object-none {
    object-fit: none;
  }

  .widescreen\:object-scale-down {
    object-fit: scale-down;
  }

  .widescreen\:object-bottom {
    object-position: bottom;
  }

  .widescreen\:object-center {
    object-position: center;
  }

  .widescreen\:object-left {
    object-position: left;
  }

  .widescreen\:object-left-bottom {
    object-position: left bottom;
  }

  .widescreen\:object-left-top {
    object-position: left top;
  }

  .widescreen\:object-right {
    object-position: right;
  }

  .widescreen\:object-right-bottom {
    object-position: right bottom;
  }

  .widescreen\:object-right-top {
    object-position: right top;
  }

  .widescreen\:object-top {
    object-position: top;
  }

  .widescreen\:opacity-0 {
    opacity: 0;
  }

  .widescreen\:opacity-25 {
    opacity: 0.25;
  }

  .widescreen\:opacity-50 {
    opacity: 0.5;
  }

  .widescreen\:opacity-75 {
    opacity: 0.75;
  }

  .widescreen\:opacity-100 {
    opacity: 1;
  }

  .widescreen\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .widescreen\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .widescreen\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .widescreen\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .widescreen\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .widescreen\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .widescreen\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .widescreen\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .widescreen\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .widescreen\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .widescreen\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .widescreen\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .widescreen\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .widescreen\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .widescreen\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .widescreen\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .widescreen\:overflow-auto {
    overflow: auto;
  }

  .widescreen\:overflow-hidden {
    overflow: hidden;
  }

  .widescreen\:overflow-visible {
    overflow: visible;
  }

  .widescreen\:overflow-scroll {
    overflow: scroll;
  }

  .widescreen\:overflow-x-auto {
    overflow-x: auto;
  }

  .widescreen\:overflow-y-auto {
    overflow-y: auto;
  }

  .widescreen\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .widescreen\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .widescreen\:overflow-x-visible {
    overflow-x: visible;
  }

  .widescreen\:overflow-y-visible {
    overflow-y: visible;
  }

  .widescreen\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .widescreen\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .widescreen\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .widescreen\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .widescreen\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .widescreen\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .widescreen\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .widescreen\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .widescreen\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .widescreen\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .widescreen\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .widescreen\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .widescreen\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .widescreen\:p-1 {
    padding: 5px;
  }

  .widescreen\:p-2 {
    padding: 10px;
  }

  .widescreen\:p-3 {
    padding: 15px;
  }

  .widescreen\:p-4 {
    padding: 30px;
  }

  .widescreen\:p-5 {
    padding: 50px;
  }

  .widescreen\:p-6 {
    padding: 75px;
  }

  .widescreen\:p-7 {
    padding: 100px;
  }

  .widescreen\:p-none {
    padding: 0px;
  }

  .widescreen\:p-tiny {
    padding: 10px;
  }

  .widescreen\:p-small {
    padding: 20px;
  }

  .widescreen\:p-medium {
    padding: 30px;
  }

  .widescreen\:p-large {
    padding: 40px;
  }

  .widescreen\:p-huge {
    padding: 60px;
  }

  .widescreen\:p-margin-label-height {
    padding: 26px;
  }

  .widescreen\:p-nav-height {
    padding: 80px;
  }

  .widescreen\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .widescreen\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .widescreen\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .widescreen\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widescreen\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .widescreen\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .widescreen\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .widescreen\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .widescreen\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .widescreen\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .widescreen\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .widescreen\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .widescreen\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .widescreen\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widescreen\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .widescreen\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .widescreen\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .widescreen\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .widescreen\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .widescreen\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .widescreen\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .widescreen\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .widescreen\:pt-1 {
    padding-top: 5px;
  }

  .widescreen\:pr-1 {
    padding-right: 5px;
  }

  .widescreen\:pb-1 {
    padding-bottom: 5px;
  }

  .widescreen\:pl-1 {
    padding-left: 5px;
  }

  .widescreen\:pt-2 {
    padding-top: 10px;
  }

  .widescreen\:pr-2 {
    padding-right: 10px;
  }

  .widescreen\:pb-2 {
    padding-bottom: 10px;
  }

  .widescreen\:pl-2 {
    padding-left: 10px;
  }

  .widescreen\:pt-3 {
    padding-top: 15px;
  }

  .widescreen\:pr-3 {
    padding-right: 15px;
  }

  .widescreen\:pb-3 {
    padding-bottom: 15px;
  }

  .widescreen\:pl-3 {
    padding-left: 15px;
  }

  .widescreen\:pt-4 {
    padding-top: 30px;
  }

  .widescreen\:pr-4 {
    padding-right: 30px;
  }

  .widescreen\:pb-4 {
    padding-bottom: 30px;
  }

  .widescreen\:pl-4 {
    padding-left: 30px;
  }

  .widescreen\:pt-5 {
    padding-top: 50px;
  }

  .widescreen\:pr-5 {
    padding-right: 50px;
  }

  .widescreen\:pb-5 {
    padding-bottom: 50px;
  }

  .widescreen\:pl-5 {
    padding-left: 50px;
  }

  .widescreen\:pt-6 {
    padding-top: 75px;
  }

  .widescreen\:pr-6 {
    padding-right: 75px;
  }

  .widescreen\:pb-6 {
    padding-bottom: 75px;
  }

  .widescreen\:pl-6 {
    padding-left: 75px;
  }

  .widescreen\:pt-7 {
    padding-top: 100px;
  }

  .widescreen\:pr-7 {
    padding-right: 100px;
  }

  .widescreen\:pb-7 {
    padding-bottom: 100px;
  }

  .widescreen\:pl-7 {
    padding-left: 100px;
  }

  .widescreen\:pt-none {
    padding-top: 0px;
  }

  .widescreen\:pr-none {
    padding-right: 0px;
  }

  .widescreen\:pb-none {
    padding-bottom: 0px;
  }

  .widescreen\:pl-none {
    padding-left: 0px;
  }

  .widescreen\:pt-tiny {
    padding-top: 10px;
  }

  .widescreen\:pr-tiny {
    padding-right: 10px;
  }

  .widescreen\:pb-tiny {
    padding-bottom: 10px;
  }

  .widescreen\:pl-tiny {
    padding-left: 10px;
  }

  .widescreen\:pt-small {
    padding-top: 20px;
  }

  .widescreen\:pr-small {
    padding-right: 20px;
  }

  .widescreen\:pb-small {
    padding-bottom: 20px;
  }

  .widescreen\:pl-small {
    padding-left: 20px;
  }

  .widescreen\:pt-medium {
    padding-top: 30px;
  }

  .widescreen\:pr-medium {
    padding-right: 30px;
  }

  .widescreen\:pb-medium {
    padding-bottom: 30px;
  }

  .widescreen\:pl-medium {
    padding-left: 30px;
  }

  .widescreen\:pt-large {
    padding-top: 40px;
  }

  .widescreen\:pr-large {
    padding-right: 40px;
  }

  .widescreen\:pb-large {
    padding-bottom: 40px;
  }

  .widescreen\:pl-large {
    padding-left: 40px;
  }

  .widescreen\:pt-huge {
    padding-top: 60px;
  }

  .widescreen\:pr-huge {
    padding-right: 60px;
  }

  .widescreen\:pb-huge {
    padding-bottom: 60px;
  }

  .widescreen\:pl-huge {
    padding-left: 60px;
  }

  .widescreen\:pt-margin-label-height {
    padding-top: 26px;
  }

  .widescreen\:pr-margin-label-height {
    padding-right: 26px;
  }

  .widescreen\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .widescreen\:pl-margin-label-height {
    padding-left: 26px;
  }

  .widescreen\:pt-nav-height {
    padding-top: 80px;
  }

  .widescreen\:pr-nav-height {
    padding-right: 80px;
  }

  .widescreen\:pb-nav-height {
    padding-bottom: 80px;
  }

  .widescreen\:pl-nav-height {
    padding-left: 80px;
  }

  .widescreen\:hover\:p-1:hover {
    padding: 5px;
  }

  .widescreen\:hover\:p-2:hover {
    padding: 10px;
  }

  .widescreen\:hover\:p-3:hover {
    padding: 15px;
  }

  .widescreen\:hover\:p-4:hover {
    padding: 30px;
  }

  .widescreen\:hover\:p-5:hover {
    padding: 50px;
  }

  .widescreen\:hover\:p-6:hover {
    padding: 75px;
  }

  .widescreen\:hover\:p-7:hover {
    padding: 100px;
  }

  .widescreen\:hover\:p-none:hover {
    padding: 0px;
  }

  .widescreen\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .widescreen\:hover\:p-small:hover {
    padding: 20px;
  }

  .widescreen\:hover\:p-medium:hover {
    padding: 30px;
  }

  .widescreen\:hover\:p-large:hover {
    padding: 40px;
  }

  .widescreen\:hover\:p-huge:hover {
    padding: 60px;
  }

  .widescreen\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .widescreen\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .widescreen\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .widescreen\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .widescreen\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .widescreen\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widescreen\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .widescreen\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .widescreen\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .widescreen\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .widescreen\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .widescreen\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .widescreen\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .widescreen\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .widescreen\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .widescreen\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widescreen\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .widescreen\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .widescreen\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .widescreen\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .widescreen\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .widescreen\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .widescreen\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .widescreen\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .widescreen\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .widescreen\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .widescreen\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .widescreen\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .widescreen\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .widescreen\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .widescreen\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .widescreen\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .widescreen\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .widescreen\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .widescreen\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .widescreen\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .widescreen\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .widescreen\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .widescreen\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .widescreen\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .widescreen\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .widescreen\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .widescreen\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .widescreen\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .widescreen\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .widescreen\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .widescreen\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .widescreen\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .widescreen\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .widescreen\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .widescreen\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .widescreen\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .widescreen\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .widescreen\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .widescreen\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .widescreen\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .widescreen\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .widescreen\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .widescreen\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .widescreen\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .widescreen\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .widescreen\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .widescreen\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .widescreen\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .widescreen\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .widescreen\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .widescreen\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .widescreen\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .widescreen\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .widescreen\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .widescreen\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .widescreen\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .widescreen\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .widescreen\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .widescreen\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .widescreen\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .widescreen\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .widescreen\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .widescreen\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .widescreen\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .widescreen\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .widescreen\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .widescreen\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .widescreen\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .widescreen\:focus\:p-1:focus {
    padding: 5px;
  }

  .widescreen\:focus\:p-2:focus {
    padding: 10px;
  }

  .widescreen\:focus\:p-3:focus {
    padding: 15px;
  }

  .widescreen\:focus\:p-4:focus {
    padding: 30px;
  }

  .widescreen\:focus\:p-5:focus {
    padding: 50px;
  }

  .widescreen\:focus\:p-6:focus {
    padding: 75px;
  }

  .widescreen\:focus\:p-7:focus {
    padding: 100px;
  }

  .widescreen\:focus\:p-none:focus {
    padding: 0px;
  }

  .widescreen\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .widescreen\:focus\:p-small:focus {
    padding: 20px;
  }

  .widescreen\:focus\:p-medium:focus {
    padding: 30px;
  }

  .widescreen\:focus\:p-large:focus {
    padding: 40px;
  }

  .widescreen\:focus\:p-huge:focus {
    padding: 60px;
  }

  .widescreen\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .widescreen\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .widescreen\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .widescreen\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .widescreen\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .widescreen\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widescreen\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .widescreen\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .widescreen\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .widescreen\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .widescreen\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .widescreen\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .widescreen\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .widescreen\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .widescreen\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .widescreen\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widescreen\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .widescreen\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .widescreen\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .widescreen\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .widescreen\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .widescreen\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .widescreen\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .widescreen\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .widescreen\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .widescreen\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .widescreen\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .widescreen\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .widescreen\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .widescreen\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .widescreen\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .widescreen\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .widescreen\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .widescreen\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .widescreen\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .widescreen\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .widescreen\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .widescreen\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .widescreen\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .widescreen\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .widescreen\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .widescreen\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .widescreen\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .widescreen\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .widescreen\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .widescreen\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .widescreen\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .widescreen\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .widescreen\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .widescreen\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .widescreen\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .widescreen\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .widescreen\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .widescreen\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .widescreen\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .widescreen\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .widescreen\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .widescreen\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .widescreen\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .widescreen\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .widescreen\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .widescreen\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .widescreen\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .widescreen\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .widescreen\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .widescreen\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .widescreen\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .widescreen\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .widescreen\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .widescreen\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .widescreen\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .widescreen\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .widescreen\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .widescreen\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .widescreen\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .widescreen\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .widescreen\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .widescreen\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .widescreen\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .widescreen\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .widescreen\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .widescreen\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .widescreen\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .widescreen\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .widescreen\:first\:p-1:first-child {
    padding: 5px;
  }

  .widescreen\:first\:p-2:first-child {
    padding: 10px;
  }

  .widescreen\:first\:p-3:first-child {
    padding: 15px;
  }

  .widescreen\:first\:p-4:first-child {
    padding: 30px;
  }

  .widescreen\:first\:p-5:first-child {
    padding: 50px;
  }

  .widescreen\:first\:p-6:first-child {
    padding: 75px;
  }

  .widescreen\:first\:p-7:first-child {
    padding: 100px;
  }

  .widescreen\:first\:p-none:first-child {
    padding: 0px;
  }

  .widescreen\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .widescreen\:first\:p-small:first-child {
    padding: 20px;
  }

  .widescreen\:first\:p-medium:first-child {
    padding: 30px;
  }

  .widescreen\:first\:p-large:first-child {
    padding: 40px;
  }

  .widescreen\:first\:p-huge:first-child {
    padding: 60px;
  }

  .widescreen\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .widescreen\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .widescreen\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .widescreen\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .widescreen\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .widescreen\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widescreen\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .widescreen\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .widescreen\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .widescreen\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .widescreen\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .widescreen\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .widescreen\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .widescreen\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .widescreen\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .widescreen\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widescreen\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .widescreen\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .widescreen\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .widescreen\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .widescreen\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .widescreen\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .widescreen\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .widescreen\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .widescreen\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .widescreen\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .widescreen\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .widescreen\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .widescreen\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .widescreen\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .widescreen\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .widescreen\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .widescreen\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .widescreen\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .widescreen\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .widescreen\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .widescreen\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .widescreen\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .widescreen\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .widescreen\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .widescreen\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .widescreen\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .widescreen\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .widescreen\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .widescreen\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .widescreen\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .widescreen\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .widescreen\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .widescreen\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .widescreen\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .widescreen\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .widescreen\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .widescreen\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .widescreen\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .widescreen\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .widescreen\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .widescreen\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .widescreen\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .widescreen\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .widescreen\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .widescreen\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .widescreen\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .widescreen\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .widescreen\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .widescreen\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .widescreen\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .widescreen\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .widescreen\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .widescreen\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .widescreen\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .widescreen\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .widescreen\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .widescreen\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .widescreen\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .widescreen\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .widescreen\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .widescreen\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .widescreen\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .widescreen\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .widescreen\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .widescreen\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .widescreen\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .widescreen\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .widescreen\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .widescreen\:last\:p-1:last-child {
    padding: 5px;
  }

  .widescreen\:last\:p-2:last-child {
    padding: 10px;
  }

  .widescreen\:last\:p-3:last-child {
    padding: 15px;
  }

  .widescreen\:last\:p-4:last-child {
    padding: 30px;
  }

  .widescreen\:last\:p-5:last-child {
    padding: 50px;
  }

  .widescreen\:last\:p-6:last-child {
    padding: 75px;
  }

  .widescreen\:last\:p-7:last-child {
    padding: 100px;
  }

  .widescreen\:last\:p-none:last-child {
    padding: 0px;
  }

  .widescreen\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .widescreen\:last\:p-small:last-child {
    padding: 20px;
  }

  .widescreen\:last\:p-medium:last-child {
    padding: 30px;
  }

  .widescreen\:last\:p-large:last-child {
    padding: 40px;
  }

  .widescreen\:last\:p-huge:last-child {
    padding: 60px;
  }

  .widescreen\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .widescreen\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .widescreen\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .widescreen\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .widescreen\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .widescreen\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .widescreen\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .widescreen\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .widescreen\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .widescreen\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .widescreen\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .widescreen\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .widescreen\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .widescreen\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .widescreen\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widescreen\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widescreen\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .widescreen\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widescreen\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .widescreen\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .widescreen\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .widescreen\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .widescreen\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .widescreen\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .widescreen\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .widescreen\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .widescreen\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .widescreen\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .widescreen\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .widescreen\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .widescreen\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .widescreen\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .widescreen\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .widescreen\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .widescreen\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .widescreen\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .widescreen\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .widescreen\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .widescreen\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .widescreen\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .widescreen\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .widescreen\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .widescreen\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .widescreen\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .widescreen\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .widescreen\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .widescreen\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .widescreen\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .widescreen\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .widescreen\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .widescreen\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .widescreen\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .widescreen\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .widescreen\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .widescreen\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .widescreen\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .widescreen\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .widescreen\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .widescreen\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .widescreen\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .widescreen\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .widescreen\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .widescreen\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .widescreen\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .widescreen\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .widescreen\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .widescreen\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .widescreen\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .widescreen\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .widescreen\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .widescreen\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .widescreen\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .widescreen\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .widescreen\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .widescreen\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .widescreen\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .widescreen\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .widescreen\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .widescreen\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .widescreen\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .widescreen\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .widescreen\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .widescreen\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .widescreen\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .widescreen\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .widescreen\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .widescreen\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .widescreen\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .widescreen\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .widescreen\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .widescreen\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .widescreen\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .widescreen\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .widescreen\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .widescreen\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .widescreen\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .widescreen\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .widescreen\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .widescreen\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .widescreen\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .widescreen\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .widescreen\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .widescreen\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .widescreen\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .widescreen\:pointer-events-none {
    pointer-events: none;
  }

  .widescreen\:pointer-events-auto {
    pointer-events: auto;
  }

  .widescreen\:static {
    position: static;
  }

  .widescreen\:fixed {
    position: fixed;
  }

  .widescreen\:absolute {
    position: absolute;
  }

  .widescreen\:relative {
    position: relative;
  }

  .widescreen\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .widescreen\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .widescreen\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .widescreen\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .widescreen\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .widescreen\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .widescreen\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .widescreen\:top-0 {
    top: 0;
  }

  .widescreen\:right-0 {
    right: 0;
  }

  .widescreen\:bottom-0 {
    bottom: 0;
  }

  .widescreen\:left-0 {
    left: 0;
  }

  .widescreen\:top-auto {
    top: auto;
  }

  .widescreen\:right-auto {
    right: auto;
  }

  .widescreen\:bottom-auto {
    bottom: auto;
  }

  .widescreen\:left-auto {
    left: auto;
  }

  .widescreen\:resize-none {
    resize: none;
  }

  .widescreen\:resize-y {
    resize: vertical;
  }

  .widescreen\:resize-x {
    resize: horizontal;
  }

  .widescreen\:resize {
    resize: both;
  }

  .widescreen\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .widescreen\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .widescreen\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .widescreen\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .widescreen\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .widescreen\:shadow-none {
    box-shadow: none;
  }

  .widescreen\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .widescreen\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .widescreen\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .widescreen\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .widescreen\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .widescreen\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .widescreen\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .widescreen\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .widescreen\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .widescreen\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .widescreen\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .widescreen\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .widescreen\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .widescreen\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .widescreen\:fill-current {
    fill: currentColor;
  }

  .widescreen\:stroke-current {
    stroke: currentColor;
  }

  .widescreen\:stroke-0 {
    stroke-width: 0;
  }

  .widescreen\:stroke-1 {
    stroke-width: 1;
  }

  .widescreen\:stroke-2 {
    stroke-width: 2;
  }

  .widescreen\:table-auto {
    table-layout: auto;
  }

  .widescreen\:table-fixed {
    table-layout: fixed;
  }

  .widescreen\:text-left {
    text-align: left;
  }

  .widescreen\:text-center {
    text-align: center;
  }

  .widescreen\:text-right {
    text-align: right;
  }

  .widescreen\:text-justify {
    text-align: justify;
  }

  .widescreen\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .widescreen\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .widescreen\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .widescreen\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .widescreen\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .widescreen\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .widescreen\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .widescreen\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .widescreen\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .widescreen\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .widescreen\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .widescreen\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .widescreen\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .widescreen\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .widescreen\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .widescreen\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .widescreen\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .widescreen\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .widescreen\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .widescreen\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .widescreen\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .widescreen\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .widescreen\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .widescreen\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .widescreen\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .widescreen\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .widescreen\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .widescreen\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .widescreen\:text-state-danger {
    color: #FF8274B3;
  }

  .widescreen\:text-state-warning {
    color: #F9C66BB3;
  }

  .widescreen\:text-state-success {
    color: #C7F6C9B3;
  }

  .widescreen\:text-state-primary {
    color: #B3F5FFB3;
  }

  .widescreen\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .widescreen\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .widescreen\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .widescreen\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .widescreen\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .widescreen\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .widescreen\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .widescreen\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .widescreen\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .widescreen\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .widescreen\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .widescreen\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .widescreen\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .widescreen\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .widescreen\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .widescreen\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .widescreen\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .widescreen\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .widescreen\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .widescreen\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .widescreen\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .widescreen\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .widescreen\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .widescreen\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .widescreen\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .widescreen\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .widescreen\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .widescreen\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .widescreen\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .widescreen\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .widescreen\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .widescreen\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .widescreen\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .widescreen\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .widescreen\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .widescreen\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .widescreen\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .widescreen\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .widescreen\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .widescreen\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .widescreen\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .widescreen\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .widescreen\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .widescreen\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .widescreen\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .widescreen\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .widescreen\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .widescreen\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .widescreen\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .widescreen\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .widescreen\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .widescreen\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .widescreen\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .widescreen\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .widescreen\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .widescreen\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .widescreen\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .widescreen\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .widescreen\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .widescreen\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .widescreen\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .widescreen\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .widescreen\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .widescreen\:text-opacity-0 {
    --text-opacity: 0;
  }

  .widescreen\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .widescreen\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .widescreen\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .widescreen\:text-opacity-100 {
    --text-opacity: 1;
  }

  .widescreen\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .widescreen\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .widescreen\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .widescreen\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .widescreen\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .widescreen\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .widescreen\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .widescreen\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .widescreen\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .widescreen\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .widescreen\:italic {
    font-style: italic;
  }

  .widescreen\:not-italic {
    font-style: normal;
  }

  .widescreen\:uppercase {
    text-transform: uppercase;
  }

  .widescreen\:lowercase {
    text-transform: lowercase;
  }

  .widescreen\:capitalize {
    text-transform: capitalize;
  }

  .widescreen\:normal-case {
    text-transform: none;
  }

  .widescreen\:underline {
    text-decoration: underline;
  }

  .widescreen\:line-through {
    text-decoration: line-through;
  }

  .widescreen\:no-underline {
    text-decoration: none;
  }

  .widescreen\:hover\:underline:hover {
    text-decoration: underline;
  }

  .widescreen\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .widescreen\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .widescreen\:focus\:underline:focus {
    text-decoration: underline;
  }

  .widescreen\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .widescreen\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .widescreen\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .widescreen\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .widescreen\:ordinal, .widescreen\:slashed-zero, .widescreen\:lining-nums, .widescreen\:oldstyle-nums, .widescreen\:proportional-nums, .widescreen\:tabular-nums, .widescreen\:diagonal-fractions, .widescreen\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .widescreen\:normal-nums {
    font-variant-numeric: normal;
  }

  .widescreen\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .widescreen\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .widescreen\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .widescreen\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .widescreen\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .widescreen\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .widescreen\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .widescreen\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .widescreen\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .widescreen\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .widescreen\:tracking-normal {
    letter-spacing: 0;
  }

  .widescreen\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .widescreen\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .widescreen\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .widescreen\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .widescreen\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .widescreen\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .widescreen\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .widescreen\:align-baseline {
    vertical-align: baseline;
  }

  .widescreen\:align-top {
    vertical-align: top;
  }

  .widescreen\:align-middle {
    vertical-align: middle;
  }

  .widescreen\:align-bottom {
    vertical-align: bottom;
  }

  .widescreen\:align-text-top {
    vertical-align: text-top;
  }

  .widescreen\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .widescreen\:visible {
    visibility: visible;
  }

  .widescreen\:invisible {
    visibility: hidden;
  }

  .widescreen\:whitespace-normal {
    white-space: normal;
  }

  .widescreen\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .widescreen\:whitespace-pre {
    white-space: pre;
  }

  .widescreen\:whitespace-pre-line {
    white-space: pre-line;
  }

  .widescreen\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .widescreen\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .widescreen\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .widescreen\:break-all {
    word-break: break-all;
  }

  .widescreen\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .widescreen\:w-1 {
    width: 5px;
  }

  .widescreen\:w-2 {
    width: 10px;
  }

  .widescreen\:w-3 {
    width: 15px;
  }

  .widescreen\:w-4 {
    width: 30px;
  }

  .widescreen\:w-5 {
    width: 50px;
  }

  .widescreen\:w-6 {
    width: 75px;
  }

  .widescreen\:w-7 {
    width: 100px;
  }

  .widescreen\:w-auto {
    width: auto;
  }

  .widescreen\:w-none {
    width: 0px;
  }

  .widescreen\:w-tiny {
    width: 10px;
  }

  .widescreen\:w-small {
    width: 20px;
  }

  .widescreen\:w-medium {
    width: 30px;
  }

  .widescreen\:w-large {
    width: 40px;
  }

  .widescreen\:w-huge {
    width: 60px;
  }

  .widescreen\:w-margin-label-height {
    width: 26px;
  }

  .widescreen\:w-nav-height {
    width: 80px;
  }

  .widescreen\:w-1\/2 {
    width: 50%;
  }

  .widescreen\:w-1\/3 {
    width: 33.333333%;
  }

  .widescreen\:w-2\/3 {
    width: 66.666667%;
  }

  .widescreen\:w-1\/4 {
    width: 25%;
  }

  .widescreen\:w-2\/4 {
    width: 50%;
  }

  .widescreen\:w-3\/4 {
    width: 75%;
  }

  .widescreen\:w-1\/5 {
    width: 20%;
  }

  .widescreen\:w-2\/5 {
    width: 40%;
  }

  .widescreen\:w-3\/5 {
    width: 60%;
  }

  .widescreen\:w-4\/5 {
    width: 80%;
  }

  .widescreen\:w-1\/6 {
    width: 16.666667%;
  }

  .widescreen\:w-2\/6 {
    width: 33.333333%;
  }

  .widescreen\:w-3\/6 {
    width: 50%;
  }

  .widescreen\:w-4\/6 {
    width: 66.666667%;
  }

  .widescreen\:w-5\/6 {
    width: 83.333333%;
  }

  .widescreen\:w-1\/12 {
    width: 8.333333%;
  }

  .widescreen\:w-2\/12 {
    width: 16.666667%;
  }

  .widescreen\:w-3\/12 {
    width: 25%;
  }

  .widescreen\:w-4\/12 {
    width: 33.333333%;
  }

  .widescreen\:w-5\/12 {
    width: 41.666667%;
  }

  .widescreen\:w-6\/12 {
    width: 50%;
  }

  .widescreen\:w-7\/12 {
    width: 58.333333%;
  }

  .widescreen\:w-8\/12 {
    width: 66.666667%;
  }

  .widescreen\:w-9\/12 {
    width: 75%;
  }

  .widescreen\:w-10\/12 {
    width: 83.333333%;
  }

  .widescreen\:w-11\/12 {
    width: 91.666667%;
  }

  .widescreen\:w-full {
    width: 100%;
  }

  .widescreen\:w-screen {
    width: 100vw;
  }

  .widescreen\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .widescreen\:z-0 {
    z-index: 0;
  }

  .widescreen\:z-10 {
    z-index: 10;
  }

  .widescreen\:z-20 {
    z-index: 20;
  }

  .widescreen\:z-30 {
    z-index: 30;
  }

  .widescreen\:z-40 {
    z-index: 40;
  }

  .widescreen\:z-50 {
    z-index: 50;
  }

  .widescreen\:z-auto {
    z-index: auto;
  }

  .widescreen\:z-nav {
    z-index: 90;
  }

  .widescreen\:z-menu {
    z-index: 100;
  }

  .widescreen\:z-popup {
    z-index: 110;
  }

  .widescreen\:z-modalBackdrop {
    z-index: 120;
  }

  .widescreen\:z-modal {
    z-index: 130;
  }

  .widescreen\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .widescreen\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .widescreen\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .widescreen\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .widescreen\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .widescreen\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .widescreen\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .widescreen\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .widescreen\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .widescreen\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .widescreen\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .widescreen\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .widescreen\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .widescreen\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .widescreen\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .widescreen\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .widescreen\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .widescreen\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .widescreen\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .widescreen\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .widescreen\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .widescreen\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .widescreen\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .widescreen\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .widescreen\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .widescreen\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .widescreen\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .widescreen\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .widescreen\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .widescreen\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .widescreen\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .widescreen\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .widescreen\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .widescreen\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .widescreen\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .widescreen\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .widescreen\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .widescreen\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .widescreen\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .widescreen\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .widescreen\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .widescreen\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .widescreen\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .widescreen\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .widescreen\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .widescreen\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .widescreen\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .widescreen\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .widescreen\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .widescreen\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .widescreen\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .widescreen\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .widescreen\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .widescreen\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .widescreen\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .widescreen\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .widescreen\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .widescreen\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .widescreen\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .widescreen\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .widescreen\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .widescreen\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .widescreen\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .widescreen\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .widescreen\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .widescreen\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .widescreen\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .widescreen\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .widescreen\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .widescreen\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .widescreen\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .widescreen\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .widescreen\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .widescreen\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .widescreen\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .widescreen\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .widescreen\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .widescreen\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .widescreen\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .widescreen\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .widescreen\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .widescreen\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .widescreen\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .widescreen\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .widescreen\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .widescreen\:grid-flow-row {
    grid-auto-flow: row;
  }

  .widescreen\:grid-flow-col {
    grid-auto-flow: column;
  }

  .widescreen\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .widescreen\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .widescreen\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .widescreen\:grid-cols-none {
    grid-template-columns: none;
  }

  .widescreen\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .widescreen\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .widescreen\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .widescreen\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .widescreen\:col-auto {
    grid-column: auto;
  }

  .widescreen\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .widescreen\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .widescreen\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .widescreen\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .widescreen\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .widescreen\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .widescreen\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .widescreen\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .widescreen\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .widescreen\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .widescreen\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .widescreen\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .widescreen\:col-span-full {
    grid-column: 1 / -1;
  }

  .widescreen\:col-start-1 {
    grid-column-start: 1;
  }

  .widescreen\:col-start-2 {
    grid-column-start: 2;
  }

  .widescreen\:col-start-3 {
    grid-column-start: 3;
  }

  .widescreen\:col-start-4 {
    grid-column-start: 4;
  }

  .widescreen\:col-start-5 {
    grid-column-start: 5;
  }

  .widescreen\:col-start-6 {
    grid-column-start: 6;
  }

  .widescreen\:col-start-7 {
    grid-column-start: 7;
  }

  .widescreen\:col-start-8 {
    grid-column-start: 8;
  }

  .widescreen\:col-start-9 {
    grid-column-start: 9;
  }

  .widescreen\:col-start-10 {
    grid-column-start: 10;
  }

  .widescreen\:col-start-11 {
    grid-column-start: 11;
  }

  .widescreen\:col-start-12 {
    grid-column-start: 12;
  }

  .widescreen\:col-start-13 {
    grid-column-start: 13;
  }

  .widescreen\:col-start-auto {
    grid-column-start: auto;
  }

  .widescreen\:col-end-1 {
    grid-column-end: 1;
  }

  .widescreen\:col-end-2 {
    grid-column-end: 2;
  }

  .widescreen\:col-end-3 {
    grid-column-end: 3;
  }

  .widescreen\:col-end-4 {
    grid-column-end: 4;
  }

  .widescreen\:col-end-5 {
    grid-column-end: 5;
  }

  .widescreen\:col-end-6 {
    grid-column-end: 6;
  }

  .widescreen\:col-end-7 {
    grid-column-end: 7;
  }

  .widescreen\:col-end-8 {
    grid-column-end: 8;
  }

  .widescreen\:col-end-9 {
    grid-column-end: 9;
  }

  .widescreen\:col-end-10 {
    grid-column-end: 10;
  }

  .widescreen\:col-end-11 {
    grid-column-end: 11;
  }

  .widescreen\:col-end-12 {
    grid-column-end: 12;
  }

  .widescreen\:col-end-13 {
    grid-column-end: 13;
  }

  .widescreen\:col-end-auto {
    grid-column-end: auto;
  }

  .widescreen\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .widescreen\:grid-rows-none {
    grid-template-rows: none;
  }

  .widescreen\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .widescreen\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .widescreen\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .widescreen\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .widescreen\:row-auto {
    grid-row: auto;
  }

  .widescreen\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .widescreen\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .widescreen\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .widescreen\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .widescreen\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .widescreen\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .widescreen\:row-span-full {
    grid-row: 1 / -1;
  }

  .widescreen\:row-start-1 {
    grid-row-start: 1;
  }

  .widescreen\:row-start-2 {
    grid-row-start: 2;
  }

  .widescreen\:row-start-3 {
    grid-row-start: 3;
  }

  .widescreen\:row-start-4 {
    grid-row-start: 4;
  }

  .widescreen\:row-start-5 {
    grid-row-start: 5;
  }

  .widescreen\:row-start-6 {
    grid-row-start: 6;
  }

  .widescreen\:row-start-7 {
    grid-row-start: 7;
  }

  .widescreen\:row-start-auto {
    grid-row-start: auto;
  }

  .widescreen\:row-end-1 {
    grid-row-end: 1;
  }

  .widescreen\:row-end-2 {
    grid-row-end: 2;
  }

  .widescreen\:row-end-3 {
    grid-row-end: 3;
  }

  .widescreen\:row-end-4 {
    grid-row-end: 4;
  }

  .widescreen\:row-end-5 {
    grid-row-end: 5;
  }

  .widescreen\:row-end-6 {
    grid-row-end: 6;
  }

  .widescreen\:row-end-7 {
    grid-row-end: 7;
  }

  .widescreen\:row-end-auto {
    grid-row-end: auto;
  }

  .widescreen\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .widescreen\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .widescreen\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .widescreen\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .widescreen\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .widescreen\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .widescreen\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .widescreen\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .widescreen\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .widescreen\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .widescreen\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .widescreen\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .widescreen\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .widescreen\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .widescreen\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .widescreen\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .widescreen\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .widescreen\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .widescreen\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .widescreen\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .widescreen\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .widescreen\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .widescreen\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .widescreen\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .widescreen\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .widescreen\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .widescreen\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .widescreen\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .widescreen\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .widescreen\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .widescreen\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .widescreen\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .widescreen\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .widescreen\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .widescreen\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .widescreen\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .widescreen\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .widescreen\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .widescreen\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .widescreen\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .widescreen\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .widescreen\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .widescreen\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .widescreen\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .widescreen\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .widescreen\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .widescreen\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .widescreen\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .widescreen\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .widescreen\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .widescreen\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .widescreen\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .widescreen\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .widescreen\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .widescreen\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .widescreen\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .widescreen\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .widescreen\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .widescreen\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .widescreen\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .widescreen\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .widescreen\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .widescreen\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .widescreen\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .widescreen\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .widescreen\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .widescreen\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .widescreen\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .widescreen\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .widescreen\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .widescreen\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .widescreen\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .widescreen\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .widescreen\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .widescreen\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .widescreen\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .widescreen\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .widescreen\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .widescreen\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .widescreen\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .widescreen\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .widescreen\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .widescreen\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .widescreen\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .widescreen\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .widescreen\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .widescreen\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .widescreen\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .widescreen\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .widescreen\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .widescreen\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .widescreen\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .widescreen\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .widescreen\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .widescreen\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .widescreen\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .widescreen\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .widescreen\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .widescreen\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .widescreen\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .widescreen\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .widescreen\:rotate-0 {
    --transform-rotate: 0;
  }

  .widescreen\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .widescreen\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .widescreen\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .widescreen\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .widescreen\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .widescreen\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .widescreen\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .widescreen\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .widescreen\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .widescreen\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .widescreen\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .widescreen\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .widescreen\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .widescreen\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .widescreen\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .widescreen\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .widescreen\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .widescreen\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .widescreen\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .widescreen\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .widescreen\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .widescreen\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .widescreen\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .widescreen\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .widescreen\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .widescreen\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .widescreen\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .widescreen\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .widescreen\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .widescreen\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .widescreen\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .widescreen\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .widescreen\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .widescreen\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .widescreen\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .widescreen\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .widescreen\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .widescreen\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .widescreen\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .widescreen\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .widescreen\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .widescreen\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .widescreen\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .widescreen\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .widescreen\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .widescreen\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .widescreen\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .widescreen\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .widescreen\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .widescreen\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .widescreen\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .widescreen\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .widescreen\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .widescreen\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .widescreen\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .widescreen\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .widescreen\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .widescreen\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .widescreen\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .widescreen\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .widescreen\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .widescreen\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .widescreen\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .widescreen\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .widescreen\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .widescreen\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .widescreen\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .widescreen\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .widescreen\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .widescreen\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .widescreen\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .widescreen\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .widescreen\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .widescreen\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .widescreen\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .widescreen\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .widescreen\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .widescreen\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .widescreen\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .widescreen\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .widescreen\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .widescreen\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .widescreen\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .widescreen\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .widescreen\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .widescreen\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .widescreen\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .widescreen\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .widescreen\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .widescreen\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .widescreen\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .widescreen\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .widescreen\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .widescreen\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .widescreen\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .widescreen\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .widescreen\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .widescreen\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .widescreen\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .widescreen\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .widescreen\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .widescreen\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .widescreen\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .widescreen\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .widescreen\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .widescreen\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .widescreen\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .widescreen\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .widescreen\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .widescreen\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .widescreen\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .widescreen\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .widescreen\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .widescreen\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .widescreen\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .widescreen\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .widescreen\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .widescreen\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .widescreen\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .widescreen\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .widescreen\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .widescreen\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .widescreen\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .widescreen\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .widescreen\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .widescreen\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .widescreen\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .widescreen\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .widescreen\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .widescreen\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .widescreen\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .widescreen\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .widescreen\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .widescreen\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .widescreen\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .widescreen\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .widescreen\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .widescreen\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .widescreen\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .widescreen\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .widescreen\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .widescreen\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .widescreen\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .widescreen\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .widescreen\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .widescreen\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .widescreen\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .widescreen\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .widescreen\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .widescreen\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .widescreen\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .widescreen\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .widescreen\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .widescreen\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .widescreen\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .widescreen\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .widescreen\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .widescreen\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .widescreen\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .widescreen\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .widescreen\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .widescreen\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .widescreen\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .widescreen\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .widescreen\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .widescreen\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .widescreen\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .widescreen\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .widescreen\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .widescreen\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .widescreen\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .widescreen\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .widescreen\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .widescreen\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .widescreen\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .widescreen\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .widescreen\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .widescreen\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .widescreen\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .widescreen\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .widescreen\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .widescreen\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .widescreen\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .widescreen\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .widescreen\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .widescreen\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .widescreen\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .widescreen\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .widescreen\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .widescreen\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .widescreen\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .widescreen\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .widescreen\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .widescreen\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .widescreen\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .widescreen\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .widescreen\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .widescreen\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .widescreen\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .widescreen\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .widescreen\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .widescreen\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .widescreen\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .widescreen\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .widescreen\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .widescreen\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .widescreen\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .widescreen\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .widescreen\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .widescreen\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .widescreen\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .widescreen\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .widescreen\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .widescreen\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .widescreen\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .widescreen\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .widescreen\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .widescreen\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .widescreen\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .widescreen\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .widescreen\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .widescreen\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .widescreen\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .widescreen\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .widescreen\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .widescreen\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .widescreen\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .widescreen\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .widescreen\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .widescreen\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .widescreen\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .widescreen\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .widescreen\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .widescreen\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .widescreen\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .widescreen\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .widescreen\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .widescreen\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .widescreen\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .widescreen\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .widescreen\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .widescreen\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .widescreen\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .widescreen\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .widescreen\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .widescreen\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .widescreen\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .widescreen\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .widescreen\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .widescreen\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .widescreen\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .widescreen\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .widescreen\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .widescreen\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .widescreen\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .widescreen\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .widescreen\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .widescreen\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .widescreen\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .widescreen\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .widescreen\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .widescreen\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .widescreen\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .widescreen\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .widescreen\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .widescreen\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .widescreen\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .widescreen\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .widescreen\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .widescreen\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .widescreen\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .widescreen\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .widescreen\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .widescreen\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .widescreen\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .widescreen\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .widescreen\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .widescreen\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .widescreen\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .widescreen\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .widescreen\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .widescreen\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .widescreen\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .widescreen\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .widescreen\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .widescreen\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .widescreen\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .widescreen\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .widescreen\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .widescreen\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .widescreen\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .widescreen\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .widescreen\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .widescreen\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .widescreen\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .widescreen\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .widescreen\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .widescreen\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .widescreen\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .widescreen\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .widescreen\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .widescreen\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .widescreen\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .widescreen\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .widescreen\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .widescreen\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .widescreen\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .widescreen\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .widescreen\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .widescreen\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .widescreen\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .widescreen\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .widescreen\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .widescreen\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .widescreen\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .widescreen\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .widescreen\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .widescreen\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .widescreen\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .widescreen\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .widescreen\:transition-none {
    transition-property: none;
  }

  .widescreen\:transition-all {
    transition-property: all;
  }

  .widescreen\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .widescreen\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .widescreen\:transition-opacity {
    transition-property: opacity;
  }

  .widescreen\:transition-shadow {
    transition-property: box-shadow;
  }

  .widescreen\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .widescreen\:ease-linear {
    transition-timing-function: linear;
  }

  .widescreen\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .widescreen\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .widescreen\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .widescreen\:duration-75 {
    transition-duration: 75ms;
  }

  .widescreen\:duration-100 {
    transition-duration: 100ms;
  }

  .widescreen\:duration-150 {
    transition-duration: 150ms;
  }

  .widescreen\:duration-200 {
    transition-duration: 200ms;
  }

  .widescreen\:duration-300 {
    transition-duration: 300ms;
  }

  .widescreen\:duration-500 {
    transition-duration: 500ms;
  }

  .widescreen\:duration-700 {
    transition-duration: 700ms;
  }

  .widescreen\:duration-1000 {
    transition-duration: 1000ms;
  }

  .widescreen\:delay-75 {
    transition-delay: 75ms;
  }

  .widescreen\:delay-100 {
    transition-delay: 100ms;
  }

  .widescreen\:delay-150 {
    transition-delay: 150ms;
  }

  .widescreen\:delay-200 {
    transition-delay: 200ms;
  }

  .widescreen\:delay-300 {
    transition-delay: 300ms;
  }

  .widescreen\:delay-500 {
    transition-delay: 500ms;
  }

  .widescreen\:delay-700 {
    transition-delay: 700ms;
  }

  .widescreen\:delay-1000 {
    transition-delay: 1000ms;
  }

  .widescreen\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .widescreen\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .widescreen\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .widescreen\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .widescreen\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}

@media (min-width: 1408px) {
  .fullhd\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .fullhd\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .fullhd\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .fullhd\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .fullhd\:container {
      max-width: 1408px;
    }
  }

  .fullhd\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .fullhd\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .fullhd\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .fullhd\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .fullhd\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .fullhd\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .fullhd\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .fullhd\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .fullhd\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .fullhd\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .fullhd\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .fullhd\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .fullhd\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .fullhd\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .fullhd\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .fullhd\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .fullhd\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .fullhd\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .fullhd\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .fullhd\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .fullhd\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .fullhd\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .fullhd\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .fullhd\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .fullhd\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .fullhd\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .fullhd\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .fullhd\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .fullhd\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .fullhd\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .fullhd\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .fullhd\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .fullhd\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .fullhd\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .fullhd\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .fullhd\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .fullhd\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .fullhd\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .fullhd\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .fullhd\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .fullhd\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .fullhd\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .fullhd\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .fullhd\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .fullhd\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .fullhd\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .fullhd\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .fullhd\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .fullhd\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .fullhd\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .fullhd\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .fullhd\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .fullhd\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .fullhd\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .fullhd\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .fullhd\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .fullhd\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .fullhd\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .fullhd\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .fullhd\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .fullhd\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .fullhd\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .fullhd\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .fullhd\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .fullhd\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .fullhd\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .fullhd\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .fullhd\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .fullhd\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .fullhd\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .fullhd\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .fullhd\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .fullhd\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .fullhd\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .fullhd\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .fullhd\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .fullhd\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .fullhd\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .fullhd\:bg-fixed {
    background-attachment: fixed;
  }

  .fullhd\:bg-local {
    background-attachment: local;
  }

  .fullhd\:bg-scroll {
    background-attachment: scroll;
  }

  .fullhd\:bg-clip-border {
    background-clip: border-box;
  }

  .fullhd\:bg-clip-padding {
    background-clip: padding-box;
  }

  .fullhd\:bg-clip-content {
    background-clip: content-box;
  }

  .fullhd\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .fullhd\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .fullhd\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .fullhd\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .fullhd\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .fullhd\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .fullhd\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .fullhd\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .fullhd\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .fullhd\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .fullhd\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .fullhd\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .fullhd\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .fullhd\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .fullhd\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .fullhd\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .fullhd\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .fullhd\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .fullhd\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .fullhd\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .fullhd\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .fullhd\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .fullhd\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .fullhd\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .fullhd\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .fullhd\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .fullhd\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .fullhd\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .fullhd\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .fullhd\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .fullhd\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .fullhd\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .fullhd\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .fullhd\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .fullhd\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .fullhd\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .fullhd\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .fullhd\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .fullhd\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .fullhd\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .fullhd\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .fullhd\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .fullhd\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .fullhd\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .fullhd\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .fullhd\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .fullhd\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .fullhd\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .fullhd\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .fullhd\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .fullhd\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .fullhd\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .fullhd\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .fullhd\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .fullhd\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .fullhd\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .fullhd\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .fullhd\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .fullhd\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .fullhd\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .fullhd\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .fullhd\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .fullhd\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .fullhd\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .fullhd\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .fullhd\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .fullhd\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .fullhd\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .fullhd\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .fullhd\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .fullhd\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .fullhd\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .fullhd\:bg-none {
    background-image: none;
  }

  .fullhd\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .fullhd\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .fullhd\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .fullhd\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .fullhd\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .fullhd\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .fullhd\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .fullhd\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .fullhd\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .fullhd\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .fullhd\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .fullhd\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .fullhd\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .fullhd\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .fullhd\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .fullhd\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .fullhd\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .fullhd\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .fullhd\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .fullhd\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .fullhd\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .fullhd\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .fullhd\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .fullhd\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .fullhd\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .fullhd\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .fullhd\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .fullhd\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .fullhd\:to-red {
    --gradient-to-color: #ff8274;
  }

  .fullhd\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .fullhd\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .fullhd\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .fullhd\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .fullhd\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .fullhd\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .fullhd\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .fullhd\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .fullhd\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .fullhd\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .fullhd\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .fullhd\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .fullhd\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .fullhd\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .fullhd\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .fullhd\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .fullhd\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .fullhd\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .fullhd\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .fullhd\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .fullhd\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .fullhd\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .fullhd\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .fullhd\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .fullhd\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .fullhd\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .fullhd\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .fullhd\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .fullhd\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .fullhd\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .fullhd\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .fullhd\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .fullhd\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .fullhd\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .fullhd\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .fullhd\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .fullhd\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .fullhd\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .fullhd\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .fullhd\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .fullhd\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .fullhd\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .fullhd\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .fullhd\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .fullhd\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .fullhd\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .fullhd\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .fullhd\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .fullhd\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .fullhd\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .fullhd\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .fullhd\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .fullhd\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .fullhd\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .fullhd\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .fullhd\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .fullhd\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .fullhd\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .fullhd\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .fullhd\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .fullhd\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .fullhd\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .fullhd\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .fullhd\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .fullhd\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .fullhd\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .fullhd\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .fullhd\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .fullhd\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .fullhd\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .fullhd\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .fullhd\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .fullhd\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .fullhd\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .fullhd\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .fullhd\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .fullhd\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .fullhd\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .fullhd\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .fullhd\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .fullhd\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .fullhd\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .fullhd\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .fullhd\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .fullhd\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .fullhd\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .fullhd\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .fullhd\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .fullhd\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .fullhd\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .fullhd\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .fullhd\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .fullhd\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .fullhd\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .fullhd\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .fullhd\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .fullhd\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .fullhd\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .fullhd\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .fullhd\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .fullhd\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .fullhd\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .fullhd\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .fullhd\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .fullhd\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .fullhd\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .fullhd\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .fullhd\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .fullhd\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .fullhd\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .fullhd\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .fullhd\:bg-bottom {
    background-position: bottom;
  }

  .fullhd\:bg-center {
    background-position: center;
  }

  .fullhd\:bg-left {
    background-position: left;
  }

  .fullhd\:bg-left-bottom {
    background-position: left bottom;
  }

  .fullhd\:bg-left-top {
    background-position: left top;
  }

  .fullhd\:bg-right {
    background-position: right;
  }

  .fullhd\:bg-right-bottom {
    background-position: right bottom;
  }

  .fullhd\:bg-right-top {
    background-position: right top;
  }

  .fullhd\:bg-top {
    background-position: top;
  }

  .fullhd\:bg-repeat {
    background-repeat: repeat;
  }

  .fullhd\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .fullhd\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .fullhd\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .fullhd\:bg-repeat-round {
    background-repeat: round;
  }

  .fullhd\:bg-repeat-space {
    background-repeat: space;
  }

  .fullhd\:bg-auto {
    background-size: auto;
  }

  .fullhd\:bg-cover {
    background-size: cover;
  }

  .fullhd\:bg-contain {
    background-size: contain;
  }

  .fullhd\:border-collapse {
    border-collapse: collapse;
  }

  .fullhd\:border-separate {
    border-collapse: separate;
  }

  .fullhd\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .fullhd\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .fullhd\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .fullhd\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .fullhd\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .fullhd\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .fullhd\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .fullhd\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .fullhd\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .fullhd\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .fullhd\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .fullhd\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .fullhd\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .fullhd\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .fullhd\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .fullhd\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .fullhd\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .fullhd\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .fullhd\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .fullhd\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .fullhd\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .fullhd\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .fullhd\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .fullhd\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .fullhd\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .fullhd\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .fullhd\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .fullhd\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .fullhd\:border-state-danger {
    border-color: #FF8274B3;
  }

  .fullhd\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .fullhd\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .fullhd\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .fullhd\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .fullhd\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .fullhd\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .fullhd\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .fullhd\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .fullhd\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .fullhd\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .fullhd\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .fullhd\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .fullhd\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .fullhd\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .fullhd\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .fullhd\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .fullhd\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .fullhd\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .fullhd\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .fullhd\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .fullhd\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .fullhd\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .fullhd\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .fullhd\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .fullhd\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .fullhd\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .fullhd\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .fullhd\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .fullhd\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .fullhd\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .fullhd\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .fullhd\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .fullhd\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .fullhd\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .fullhd\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .fullhd\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .fullhd\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .fullhd\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .fullhd\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .fullhd\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .fullhd\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .fullhd\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .fullhd\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .fullhd\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .fullhd\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .fullhd\:border-opacity-0 {
    --border-opacity: 0;
  }

  .fullhd\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .fullhd\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .fullhd\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .fullhd\:border-opacity-100 {
    --border-opacity: 1;
  }

  .fullhd\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .fullhd\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .fullhd\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .fullhd\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .fullhd\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .fullhd\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .fullhd\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .fullhd\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .fullhd\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .fullhd\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .fullhd\:rounded-none {
    border-radius: 0;
  }

  .fullhd\:rounded-sm {
    border-radius: 0.125rem;
  }

  .fullhd\:rounded {
    border-radius: 0.25rem;
  }

  .fullhd\:rounded-md {
    border-radius: 0.375rem;
  }

  .fullhd\:rounded-lg {
    border-radius: 0.5rem;
  }

  .fullhd\:rounded-xl {
    border-radius: 0.75rem;
  }

  .fullhd\:rounded-2xl {
    border-radius: 1rem;
  }

  .fullhd\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .fullhd\:rounded-full {
    border-radius: 9999px;
  }

  .fullhd\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .fullhd\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fullhd\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .fullhd\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .fullhd\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .fullhd\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .fullhd\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .fullhd\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .fullhd\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .fullhd\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .fullhd\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .fullhd\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .fullhd\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .fullhd\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .fullhd\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .fullhd\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .fullhd\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .fullhd\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .fullhd\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .fullhd\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .fullhd\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .fullhd\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .fullhd\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .fullhd\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .fullhd\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .fullhd\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .fullhd\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .fullhd\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .fullhd\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .fullhd\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .fullhd\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .fullhd\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .fullhd\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .fullhd\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .fullhd\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .fullhd\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .fullhd\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .fullhd\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .fullhd\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .fullhd\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .fullhd\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .fullhd\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .fullhd\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .fullhd\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .fullhd\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .fullhd\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .fullhd\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .fullhd\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .fullhd\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .fullhd\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .fullhd\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .fullhd\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .fullhd\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .fullhd\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .fullhd\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .fullhd\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .fullhd\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .fullhd\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .fullhd\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .fullhd\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .fullhd\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .fullhd\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .fullhd\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .fullhd\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .fullhd\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .fullhd\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .fullhd\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .fullhd\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .fullhd\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .fullhd\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .fullhd\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .fullhd\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .fullhd\:border-solid {
    border-style: solid;
  }

  .fullhd\:border-dashed {
    border-style: dashed;
  }

  .fullhd\:border-dotted {
    border-style: dotted;
  }

  .fullhd\:border-double {
    border-style: double;
  }

  .fullhd\:border-none {
    border-style: none;
  }

  .fullhd\:border-0 {
    border-width: 0;
  }

  .fullhd\:border-2 {
    border-width: 2px;
  }

  .fullhd\:border-4 {
    border-width: 4px;
  }

  .fullhd\:border-8 {
    border-width: 8px;
  }

  .fullhd\:border {
    border-width: 1px;
  }

  .fullhd\:border-t-0 {
    border-top-width: 0;
  }

  .fullhd\:border-r-0 {
    border-right-width: 0;
  }

  .fullhd\:border-b-0 {
    border-bottom-width: 0;
  }

  .fullhd\:border-l-0 {
    border-left-width: 0;
  }

  .fullhd\:border-t-2 {
    border-top-width: 2px;
  }

  .fullhd\:border-r-2 {
    border-right-width: 2px;
  }

  .fullhd\:border-b-2 {
    border-bottom-width: 2px;
  }

  .fullhd\:border-l-2 {
    border-left-width: 2px;
  }

  .fullhd\:border-t-4 {
    border-top-width: 4px;
  }

  .fullhd\:border-r-4 {
    border-right-width: 4px;
  }

  .fullhd\:border-b-4 {
    border-bottom-width: 4px;
  }

  .fullhd\:border-l-4 {
    border-left-width: 4px;
  }

  .fullhd\:border-t-8 {
    border-top-width: 8px;
  }

  .fullhd\:border-r-8 {
    border-right-width: 8px;
  }

  .fullhd\:border-b-8 {
    border-bottom-width: 8px;
  }

  .fullhd\:border-l-8 {
    border-left-width: 8px;
  }

  .fullhd\:border-t {
    border-top-width: 1px;
  }

  .fullhd\:border-r {
    border-right-width: 1px;
  }

  .fullhd\:border-b {
    border-bottom-width: 1px;
  }

  .fullhd\:border-l {
    border-left-width: 1px;
  }

  .fullhd\:hover\:border-0:hover {
    border-width: 0;
  }

  .fullhd\:hover\:border-2:hover {
    border-width: 2px;
  }

  .fullhd\:hover\:border-4:hover {
    border-width: 4px;
  }

  .fullhd\:hover\:border-8:hover {
    border-width: 8px;
  }

  .fullhd\:hover\:border:hover {
    border-width: 1px;
  }

  .fullhd\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .fullhd\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .fullhd\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .fullhd\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .fullhd\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .fullhd\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .fullhd\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .fullhd\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .fullhd\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .fullhd\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .fullhd\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .fullhd\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .fullhd\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .fullhd\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .fullhd\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .fullhd\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .fullhd\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .fullhd\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .fullhd\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .fullhd\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .fullhd\:focus\:border-0:focus {
    border-width: 0;
  }

  .fullhd\:focus\:border-2:focus {
    border-width: 2px;
  }

  .fullhd\:focus\:border-4:focus {
    border-width: 4px;
  }

  .fullhd\:focus\:border-8:focus {
    border-width: 8px;
  }

  .fullhd\:focus\:border:focus {
    border-width: 1px;
  }

  .fullhd\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .fullhd\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .fullhd\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .fullhd\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .fullhd\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .fullhd\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .fullhd\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .fullhd\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .fullhd\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .fullhd\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .fullhd\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .fullhd\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .fullhd\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .fullhd\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .fullhd\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .fullhd\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .fullhd\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .fullhd\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .fullhd\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .fullhd\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .fullhd\:box-border {
    box-sizing: border-box;
  }

  .fullhd\:box-content {
    box-sizing: content-box;
  }

  .fullhd\:cursor-auto {
    cursor: auto;
  }

  .fullhd\:cursor-default {
    cursor: default;
  }

  .fullhd\:cursor-pointer {
    cursor: pointer;
  }

  .fullhd\:cursor-wait {
    cursor: wait;
  }

  .fullhd\:cursor-text {
    cursor: text;
  }

  .fullhd\:cursor-move {
    cursor: move;
  }

  .fullhd\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .fullhd\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .fullhd\:hover\:cursor-default:hover {
    cursor: default;
  }

  .fullhd\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .fullhd\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .fullhd\:hover\:cursor-text:hover {
    cursor: text;
  }

  .fullhd\:hover\:cursor-move:hover {
    cursor: move;
  }

  .fullhd\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .fullhd\:block {
    display: block;
  }

  .fullhd\:inline-block {
    display: inline-block;
  }

  .fullhd\:inline {
    display: inline;
  }

  .fullhd\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .fullhd\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .fullhd\:table {
    display: table;
  }

  .fullhd\:table-caption {
    display: table-caption;
  }

  .fullhd\:table-cell {
    display: table-cell;
  }

  .fullhd\:table-column {
    display: table-column;
  }

  .fullhd\:table-column-group {
    display: table-column-group;
  }

  .fullhd\:table-footer-group {
    display: table-footer-group;
  }

  .fullhd\:table-header-group {
    display: table-header-group;
  }

  .fullhd\:table-row-group {
    display: table-row-group;
  }

  .fullhd\:table-row {
    display: table-row;
  }

  .fullhd\:flow-root {
    display: flow-root;
  }

  .fullhd\:grid {
    display: grid;
  }

  .fullhd\:inline-grid {
    display: inline-grid;
  }

  .fullhd\:contents {
    display: contents;
  }

  .fullhd\:hidden {
    display: none;
  }

  .fullhd\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .fullhd\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .fullhd\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .fullhd\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .fullhd\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .fullhd\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .fullhd\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .fullhd\:place-items-auto {
    place-items: auto;
  }

  .fullhd\:place-items-start {
    place-items: start;
  }

  .fullhd\:place-items-end {
    place-items: end;
  }

  .fullhd\:place-items-center {
    place-items: center;
  }

  .fullhd\:place-items-stretch {
    place-items: stretch;
  }

  .fullhd\:place-content-center {
    place-content: center;
  }

  .fullhd\:place-content-start {
    place-content: start;
  }

  .fullhd\:place-content-end {
    place-content: end;
  }

  .fullhd\:place-content-between {
    place-content: space-between;
  }

  .fullhd\:place-content-around {
    place-content: space-around;
  }

  .fullhd\:place-content-evenly {
    place-content: space-evenly;
  }

  .fullhd\:place-content-stretch {
    place-content: stretch;
  }

  .fullhd\:place-self-auto {
    place-self: auto;
  }

  .fullhd\:place-self-start {
    place-self: start;
  }

  .fullhd\:place-self-end {
    place-self: end;
  }

  .fullhd\:place-self-center {
    place-self: center;
  }

  .fullhd\:place-self-stretch {
    place-self: stretch;
  }

  .fullhd\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .fullhd\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .fullhd\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .fullhd\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .fullhd\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .fullhd\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .fullhd\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .fullhd\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .fullhd\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .fullhd\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .fullhd\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .fullhd\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .fullhd\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .fullhd\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .fullhd\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .fullhd\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .fullhd\:justify-items-auto {
    justify-items: auto;
  }

  .fullhd\:justify-items-start {
    justify-items: start;
  }

  .fullhd\:justify-items-end {
    justify-items: end;
  }

  .fullhd\:justify-items-center {
    justify-items: center;
  }

  .fullhd\:justify-items-stretch {
    justify-items: stretch;
  }

  .fullhd\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .fullhd\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .fullhd\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .fullhd\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .fullhd\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .fullhd\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .fullhd\:justify-self-auto {
    justify-self: auto;
  }

  .fullhd\:justify-self-start {
    justify-self: start;
  }

  .fullhd\:justify-self-end {
    justify-self: end;
  }

  .fullhd\:justify-self-center {
    justify-self: center;
  }

  .fullhd\:justify-self-stretch {
    justify-self: stretch;
  }

  .fullhd\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .fullhd\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .fullhd\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .fullhd\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .fullhd\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .fullhd\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .fullhd\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .fullhd\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .fullhd\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .fullhd\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .fullhd\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .fullhd\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .fullhd\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .fullhd\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .fullhd\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .fullhd\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .fullhd\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .fullhd\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .fullhd\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .fullhd\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .fullhd\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .fullhd\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .fullhd\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .fullhd\:float-right {
    float: right;
  }

  .fullhd\:float-left {
    float: left;
  }

  .fullhd\:float-none {
    float: none;
  }

  .fullhd\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .fullhd\:clear-left {
    clear: left;
  }

  .fullhd\:clear-right {
    clear: right;
  }

  .fullhd\:clear-both {
    clear: both;
  }

  .fullhd\:clear-none {
    clear: none;
  }

  .fullhd\:font-hairline {
    font-weight: 100;
  }

  .fullhd\:font-thin {
    font-weight: 200;
  }

  .fullhd\:font-light {
    font-weight: 300;
  }

  .fullhd\:font-normal {
    font-weight: 400;
  }

  .fullhd\:font-medium {
    font-weight: 500;
  }

  .fullhd\:font-semibold {
    font-weight: 600;
  }

  .fullhd\:font-bold {
    font-weight: 700;
  }

  .fullhd\:font-extrabold {
    font-weight: 800;
  }

  .fullhd\:font-black {
    font-weight: 900;
  }

  .fullhd\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .fullhd\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .fullhd\:hover\:font-light:hover {
    font-weight: 300;
  }

  .fullhd\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .fullhd\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .fullhd\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .fullhd\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .fullhd\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .fullhd\:hover\:font-black:hover {
    font-weight: 900;
  }

  .fullhd\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .fullhd\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .fullhd\:focus\:font-light:focus {
    font-weight: 300;
  }

  .fullhd\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .fullhd\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .fullhd\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .fullhd\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .fullhd\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .fullhd\:focus\:font-black:focus {
    font-weight: 900;
  }

  .fullhd\:h-1 {
    height: 5px;
  }

  .fullhd\:h-2 {
    height: 10px;
  }

  .fullhd\:h-3 {
    height: 15px;
  }

  .fullhd\:h-4 {
    height: 30px;
  }

  .fullhd\:h-5 {
    height: 50px;
  }

  .fullhd\:h-6 {
    height: 75px;
  }

  .fullhd\:h-7 {
    height: 100px;
  }

  .fullhd\:h-logo-height-small {
    height: 55px;
  }

  .fullhd\:h-logo-height-large {
    height: 65px;
  }

  .fullhd\:leading-3 {
    line-height: .75rem;
  }

  .fullhd\:leading-4 {
    line-height: 1rem;
  }

  .fullhd\:leading-5 {
    line-height: 1.25rem;
  }

  .fullhd\:leading-6 {
    line-height: 1.5rem;
  }

  .fullhd\:leading-7 {
    line-height: 1.75rem;
  }

  .fullhd\:leading-8 {
    line-height: 2rem;
  }

  .fullhd\:leading-9 {
    line-height: 2.25rem;
  }

  .fullhd\:leading-10 {
    line-height: 2.5rem;
  }

  .fullhd\:leading-none {
    line-height: 1;
  }

  .fullhd\:leading-tight {
    line-height: 1.25;
  }

  .fullhd\:leading-snug {
    line-height: 1.375;
  }

  .fullhd\:leading-normal {
    line-height: 1.5;
  }

  .fullhd\:leading-relaxed {
    line-height: 1.625;
  }

  .fullhd\:leading-loose {
    line-height: 2;
  }

  .fullhd\:list-inside {
    list-style-position: inside;
  }

  .fullhd\:list-outside {
    list-style-position: outside;
  }

  .fullhd\:list-none {
    list-style-type: none;
  }

  .fullhd\:list-disc {
    list-style-type: disc;
  }

  .fullhd\:list-decimal {
    list-style-type: decimal;
  }

  .fullhd\:m-1 {
    margin: 5px;
  }

  .fullhd\:m-2 {
    margin: 10px;
  }

  .fullhd\:m-3 {
    margin: 15px;
  }

  .fullhd\:m-4 {
    margin: 30px;
  }

  .fullhd\:m-5 {
    margin: 50px;
  }

  .fullhd\:m-6 {
    margin: 75px;
  }

  .fullhd\:m-7 {
    margin: 100px;
  }

  .fullhd\:m-auto {
    margin: auto;
  }

  .fullhd\:m-none {
    margin: 0px;
  }

  .fullhd\:m-tiny {
    margin: 10px;
  }

  .fullhd\:m-small {
    margin: 20px;
  }

  .fullhd\:m-medium {
    margin: 30px;
  }

  .fullhd\:m-large {
    margin: 40px;
  }

  .fullhd\:m-huge {
    margin: 60px;
  }

  .fullhd\:m-margin-label-height {
    margin: 26px;
  }

  .fullhd\:m-nav-height {
    margin: 80px;
  }

  .fullhd\:-m-1 {
    margin: -5px;
  }

  .fullhd\:-m-2 {
    margin: -10px;
  }

  .fullhd\:-m-3 {
    margin: -15px;
  }

  .fullhd\:-m-4 {
    margin: -30px;
  }

  .fullhd\:-m-5 {
    margin: -50px;
  }

  .fullhd\:-m-6 {
    margin: -75px;
  }

  .fullhd\:-m-7 {
    margin: -100px;
  }

  .fullhd\:-m-none {
    margin: 0px;
  }

  .fullhd\:-m-tiny {
    margin: -10px;
  }

  .fullhd\:-m-small {
    margin: -20px;
  }

  .fullhd\:-m-medium {
    margin: -30px;
  }

  .fullhd\:-m-large {
    margin: -40px;
  }

  .fullhd\:-m-huge {
    margin: -60px;
  }

  .fullhd\:-m-margin-label-height {
    margin: -26px;
  }

  .fullhd\:-m-nav-height {
    margin: -80px;
  }

  .fullhd\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:mt-1 {
    margin-top: 5px;
  }

  .fullhd\:mr-1 {
    margin-right: 5px;
  }

  .fullhd\:mb-1 {
    margin-bottom: 5px;
  }

  .fullhd\:ml-1 {
    margin-left: 5px;
  }

  .fullhd\:mt-2 {
    margin-top: 10px;
  }

  .fullhd\:mr-2 {
    margin-right: 10px;
  }

  .fullhd\:mb-2 {
    margin-bottom: 10px;
  }

  .fullhd\:ml-2 {
    margin-left: 10px;
  }

  .fullhd\:mt-3 {
    margin-top: 15px;
  }

  .fullhd\:mr-3 {
    margin-right: 15px;
  }

  .fullhd\:mb-3 {
    margin-bottom: 15px;
  }

  .fullhd\:ml-3 {
    margin-left: 15px;
  }

  .fullhd\:mt-4 {
    margin-top: 30px;
  }

  .fullhd\:mr-4 {
    margin-right: 30px;
  }

  .fullhd\:mb-4 {
    margin-bottom: 30px;
  }

  .fullhd\:ml-4 {
    margin-left: 30px;
  }

  .fullhd\:mt-5 {
    margin-top: 50px;
  }

  .fullhd\:mr-5 {
    margin-right: 50px;
  }

  .fullhd\:mb-5 {
    margin-bottom: 50px;
  }

  .fullhd\:ml-5 {
    margin-left: 50px;
  }

  .fullhd\:mt-6 {
    margin-top: 75px;
  }

  .fullhd\:mr-6 {
    margin-right: 75px;
  }

  .fullhd\:mb-6 {
    margin-bottom: 75px;
  }

  .fullhd\:ml-6 {
    margin-left: 75px;
  }

  .fullhd\:mt-7 {
    margin-top: 100px;
  }

  .fullhd\:mr-7 {
    margin-right: 100px;
  }

  .fullhd\:mb-7 {
    margin-bottom: 100px;
  }

  .fullhd\:ml-7 {
    margin-left: 100px;
  }

  .fullhd\:mt-auto {
    margin-top: auto;
  }

  .fullhd\:mr-auto {
    margin-right: auto;
  }

  .fullhd\:mb-auto {
    margin-bottom: auto;
  }

  .fullhd\:ml-auto {
    margin-left: auto;
  }

  .fullhd\:mt-none {
    margin-top: 0px;
  }

  .fullhd\:mr-none {
    margin-right: 0px;
  }

  .fullhd\:mb-none {
    margin-bottom: 0px;
  }

  .fullhd\:ml-none {
    margin-left: 0px;
  }

  .fullhd\:mt-tiny {
    margin-top: 10px;
  }

  .fullhd\:mr-tiny {
    margin-right: 10px;
  }

  .fullhd\:mb-tiny {
    margin-bottom: 10px;
  }

  .fullhd\:ml-tiny {
    margin-left: 10px;
  }

  .fullhd\:mt-small {
    margin-top: 20px;
  }

  .fullhd\:mr-small {
    margin-right: 20px;
  }

  .fullhd\:mb-small {
    margin-bottom: 20px;
  }

  .fullhd\:ml-small {
    margin-left: 20px;
  }

  .fullhd\:mt-medium {
    margin-top: 30px;
  }

  .fullhd\:mr-medium {
    margin-right: 30px;
  }

  .fullhd\:mb-medium {
    margin-bottom: 30px;
  }

  .fullhd\:ml-medium {
    margin-left: 30px;
  }

  .fullhd\:mt-large {
    margin-top: 40px;
  }

  .fullhd\:mr-large {
    margin-right: 40px;
  }

  .fullhd\:mb-large {
    margin-bottom: 40px;
  }

  .fullhd\:ml-large {
    margin-left: 40px;
  }

  .fullhd\:mt-huge {
    margin-top: 60px;
  }

  .fullhd\:mr-huge {
    margin-right: 60px;
  }

  .fullhd\:mb-huge {
    margin-bottom: 60px;
  }

  .fullhd\:ml-huge {
    margin-left: 60px;
  }

  .fullhd\:mt-margin-label-height {
    margin-top: 26px;
  }

  .fullhd\:mr-margin-label-height {
    margin-right: 26px;
  }

  .fullhd\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .fullhd\:ml-margin-label-height {
    margin-left: 26px;
  }

  .fullhd\:mt-nav-height {
    margin-top: 80px;
  }

  .fullhd\:mr-nav-height {
    margin-right: 80px;
  }

  .fullhd\:mb-nav-height {
    margin-bottom: 80px;
  }

  .fullhd\:ml-nav-height {
    margin-left: 80px;
  }

  .fullhd\:-mt-1 {
    margin-top: -5px;
  }

  .fullhd\:-mr-1 {
    margin-right: -5px;
  }

  .fullhd\:-mb-1 {
    margin-bottom: -5px;
  }

  .fullhd\:-ml-1 {
    margin-left: -5px;
  }

  .fullhd\:-mt-2 {
    margin-top: -10px;
  }

  .fullhd\:-mr-2 {
    margin-right: -10px;
  }

  .fullhd\:-mb-2 {
    margin-bottom: -10px;
  }

  .fullhd\:-ml-2 {
    margin-left: -10px;
  }

  .fullhd\:-mt-3 {
    margin-top: -15px;
  }

  .fullhd\:-mr-3 {
    margin-right: -15px;
  }

  .fullhd\:-mb-3 {
    margin-bottom: -15px;
  }

  .fullhd\:-ml-3 {
    margin-left: -15px;
  }

  .fullhd\:-mt-4 {
    margin-top: -30px;
  }

  .fullhd\:-mr-4 {
    margin-right: -30px;
  }

  .fullhd\:-mb-4 {
    margin-bottom: -30px;
  }

  .fullhd\:-ml-4 {
    margin-left: -30px;
  }

  .fullhd\:-mt-5 {
    margin-top: -50px;
  }

  .fullhd\:-mr-5 {
    margin-right: -50px;
  }

  .fullhd\:-mb-5 {
    margin-bottom: -50px;
  }

  .fullhd\:-ml-5 {
    margin-left: -50px;
  }

  .fullhd\:-mt-6 {
    margin-top: -75px;
  }

  .fullhd\:-mr-6 {
    margin-right: -75px;
  }

  .fullhd\:-mb-6 {
    margin-bottom: -75px;
  }

  .fullhd\:-ml-6 {
    margin-left: -75px;
  }

  .fullhd\:-mt-7 {
    margin-top: -100px;
  }

  .fullhd\:-mr-7 {
    margin-right: -100px;
  }

  .fullhd\:-mb-7 {
    margin-bottom: -100px;
  }

  .fullhd\:-ml-7 {
    margin-left: -100px;
  }

  .fullhd\:-mt-none {
    margin-top: 0px;
  }

  .fullhd\:-mr-none {
    margin-right: 0px;
  }

  .fullhd\:-mb-none {
    margin-bottom: 0px;
  }

  .fullhd\:-ml-none {
    margin-left: 0px;
  }

  .fullhd\:-mt-tiny {
    margin-top: -10px;
  }

  .fullhd\:-mr-tiny {
    margin-right: -10px;
  }

  .fullhd\:-mb-tiny {
    margin-bottom: -10px;
  }

  .fullhd\:-ml-tiny {
    margin-left: -10px;
  }

  .fullhd\:-mt-small {
    margin-top: -20px;
  }

  .fullhd\:-mr-small {
    margin-right: -20px;
  }

  .fullhd\:-mb-small {
    margin-bottom: -20px;
  }

  .fullhd\:-ml-small {
    margin-left: -20px;
  }

  .fullhd\:-mt-medium {
    margin-top: -30px;
  }

  .fullhd\:-mr-medium {
    margin-right: -30px;
  }

  .fullhd\:-mb-medium {
    margin-bottom: -30px;
  }

  .fullhd\:-ml-medium {
    margin-left: -30px;
  }

  .fullhd\:-mt-large {
    margin-top: -40px;
  }

  .fullhd\:-mr-large {
    margin-right: -40px;
  }

  .fullhd\:-mb-large {
    margin-bottom: -40px;
  }

  .fullhd\:-ml-large {
    margin-left: -40px;
  }

  .fullhd\:-mt-huge {
    margin-top: -60px;
  }

  .fullhd\:-mr-huge {
    margin-right: -60px;
  }

  .fullhd\:-mb-huge {
    margin-bottom: -60px;
  }

  .fullhd\:-ml-huge {
    margin-left: -60px;
  }

  .fullhd\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .fullhd\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .fullhd\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .fullhd\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .fullhd\:-mt-nav-height {
    margin-top: -80px;
  }

  .fullhd\:-mr-nav-height {
    margin-right: -80px;
  }

  .fullhd\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .fullhd\:-ml-nav-height {
    margin-left: -80px;
  }

  .fullhd\:hover\:m-1:hover {
    margin: 5px;
  }

  .fullhd\:hover\:m-2:hover {
    margin: 10px;
  }

  .fullhd\:hover\:m-3:hover {
    margin: 15px;
  }

  .fullhd\:hover\:m-4:hover {
    margin: 30px;
  }

  .fullhd\:hover\:m-5:hover {
    margin: 50px;
  }

  .fullhd\:hover\:m-6:hover {
    margin: 75px;
  }

  .fullhd\:hover\:m-7:hover {
    margin: 100px;
  }

  .fullhd\:hover\:m-auto:hover {
    margin: auto;
  }

  .fullhd\:hover\:m-none:hover {
    margin: 0px;
  }

  .fullhd\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .fullhd\:hover\:m-small:hover {
    margin: 20px;
  }

  .fullhd\:hover\:m-medium:hover {
    margin: 30px;
  }

  .fullhd\:hover\:m-large:hover {
    margin: 40px;
  }

  .fullhd\:hover\:m-huge:hover {
    margin: 60px;
  }

  .fullhd\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .fullhd\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .fullhd\:hover\:-m-1:hover {
    margin: -5px;
  }

  .fullhd\:hover\:-m-2:hover {
    margin: -10px;
  }

  .fullhd\:hover\:-m-3:hover {
    margin: -15px;
  }

  .fullhd\:hover\:-m-4:hover {
    margin: -30px;
  }

  .fullhd\:hover\:-m-5:hover {
    margin: -50px;
  }

  .fullhd\:hover\:-m-6:hover {
    margin: -75px;
  }

  .fullhd\:hover\:-m-7:hover {
    margin: -100px;
  }

  .fullhd\:hover\:-m-none:hover {
    margin: 0px;
  }

  .fullhd\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .fullhd\:hover\:-m-small:hover {
    margin: -20px;
  }

  .fullhd\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .fullhd\:hover\:-m-large:hover {
    margin: -40px;
  }

  .fullhd\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .fullhd\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .fullhd\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .fullhd\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .fullhd\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .fullhd\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .fullhd\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .fullhd\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .fullhd\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .fullhd\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .fullhd\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .fullhd\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .fullhd\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .fullhd\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .fullhd\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .fullhd\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .fullhd\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .fullhd\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .fullhd\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .fullhd\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .fullhd\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .fullhd\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .fullhd\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .fullhd\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .fullhd\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .fullhd\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .fullhd\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .fullhd\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .fullhd\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .fullhd\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .fullhd\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .fullhd\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .fullhd\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .fullhd\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .fullhd\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .fullhd\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .fullhd\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .fullhd\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .fullhd\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .fullhd\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .fullhd\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .fullhd\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .fullhd\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .fullhd\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .fullhd\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .fullhd\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .fullhd\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .fullhd\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .fullhd\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .fullhd\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .fullhd\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .fullhd\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .fullhd\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .fullhd\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .fullhd\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .fullhd\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .fullhd\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .fullhd\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .fullhd\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .fullhd\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .fullhd\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .fullhd\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .fullhd\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .fullhd\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .fullhd\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .fullhd\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .fullhd\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .fullhd\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .fullhd\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .fullhd\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .fullhd\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .fullhd\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .fullhd\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .fullhd\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .fullhd\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .fullhd\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .fullhd\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .fullhd\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .fullhd\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .fullhd\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .fullhd\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .fullhd\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .fullhd\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .fullhd\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .fullhd\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .fullhd\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .fullhd\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .fullhd\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .fullhd\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .fullhd\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .fullhd\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .fullhd\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .fullhd\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .fullhd\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .fullhd\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .fullhd\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .fullhd\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .fullhd\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .fullhd\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .fullhd\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .fullhd\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .fullhd\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .fullhd\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .fullhd\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .fullhd\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .fullhd\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .fullhd\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .fullhd\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .fullhd\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .fullhd\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .fullhd\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .fullhd\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .fullhd\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .fullhd\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .fullhd\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .fullhd\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .fullhd\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .fullhd\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .fullhd\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .fullhd\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .fullhd\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .fullhd\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .fullhd\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .fullhd\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .fullhd\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .fullhd\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .fullhd\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .fullhd\:focus\:m-1:focus {
    margin: 5px;
  }

  .fullhd\:focus\:m-2:focus {
    margin: 10px;
  }

  .fullhd\:focus\:m-3:focus {
    margin: 15px;
  }

  .fullhd\:focus\:m-4:focus {
    margin: 30px;
  }

  .fullhd\:focus\:m-5:focus {
    margin: 50px;
  }

  .fullhd\:focus\:m-6:focus {
    margin: 75px;
  }

  .fullhd\:focus\:m-7:focus {
    margin: 100px;
  }

  .fullhd\:focus\:m-auto:focus {
    margin: auto;
  }

  .fullhd\:focus\:m-none:focus {
    margin: 0px;
  }

  .fullhd\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .fullhd\:focus\:m-small:focus {
    margin: 20px;
  }

  .fullhd\:focus\:m-medium:focus {
    margin: 30px;
  }

  .fullhd\:focus\:m-large:focus {
    margin: 40px;
  }

  .fullhd\:focus\:m-huge:focus {
    margin: 60px;
  }

  .fullhd\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .fullhd\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .fullhd\:focus\:-m-1:focus {
    margin: -5px;
  }

  .fullhd\:focus\:-m-2:focus {
    margin: -10px;
  }

  .fullhd\:focus\:-m-3:focus {
    margin: -15px;
  }

  .fullhd\:focus\:-m-4:focus {
    margin: -30px;
  }

  .fullhd\:focus\:-m-5:focus {
    margin: -50px;
  }

  .fullhd\:focus\:-m-6:focus {
    margin: -75px;
  }

  .fullhd\:focus\:-m-7:focus {
    margin: -100px;
  }

  .fullhd\:focus\:-m-none:focus {
    margin: 0px;
  }

  .fullhd\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .fullhd\:focus\:-m-small:focus {
    margin: -20px;
  }

  .fullhd\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .fullhd\:focus\:-m-large:focus {
    margin: -40px;
  }

  .fullhd\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .fullhd\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .fullhd\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .fullhd\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .fullhd\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .fullhd\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .fullhd\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .fullhd\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .fullhd\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .fullhd\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .fullhd\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .fullhd\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .fullhd\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .fullhd\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .fullhd\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .fullhd\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .fullhd\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .fullhd\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .fullhd\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .fullhd\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .fullhd\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .fullhd\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .fullhd\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .fullhd\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .fullhd\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .fullhd\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .fullhd\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .fullhd\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .fullhd\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .fullhd\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .fullhd\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .fullhd\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .fullhd\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .fullhd\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .fullhd\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .fullhd\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .fullhd\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .fullhd\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .fullhd\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .fullhd\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .fullhd\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .fullhd\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .fullhd\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .fullhd\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .fullhd\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .fullhd\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .fullhd\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .fullhd\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .fullhd\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .fullhd\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .fullhd\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .fullhd\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .fullhd\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .fullhd\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .fullhd\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .fullhd\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .fullhd\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .fullhd\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .fullhd\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .fullhd\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .fullhd\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .fullhd\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .fullhd\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .fullhd\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .fullhd\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .fullhd\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .fullhd\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .fullhd\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .fullhd\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .fullhd\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .fullhd\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .fullhd\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .fullhd\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .fullhd\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .fullhd\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .fullhd\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .fullhd\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .fullhd\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .fullhd\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .fullhd\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .fullhd\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .fullhd\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .fullhd\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .fullhd\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .fullhd\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .fullhd\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .fullhd\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .fullhd\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .fullhd\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .fullhd\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .fullhd\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .fullhd\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .fullhd\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .fullhd\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .fullhd\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .fullhd\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .fullhd\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .fullhd\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .fullhd\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .fullhd\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .fullhd\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .fullhd\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .fullhd\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .fullhd\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .fullhd\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .fullhd\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .fullhd\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .fullhd\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .fullhd\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .fullhd\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .fullhd\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .fullhd\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .fullhd\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .fullhd\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .fullhd\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .fullhd\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .fullhd\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .fullhd\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .fullhd\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .fullhd\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .fullhd\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .fullhd\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .fullhd\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .fullhd\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .fullhd\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .fullhd\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .fullhd\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .fullhd\:first\:m-1:first-child {
    margin: 5px;
  }

  .fullhd\:first\:m-2:first-child {
    margin: 10px;
  }

  .fullhd\:first\:m-3:first-child {
    margin: 15px;
  }

  .fullhd\:first\:m-4:first-child {
    margin: 30px;
  }

  .fullhd\:first\:m-5:first-child {
    margin: 50px;
  }

  .fullhd\:first\:m-6:first-child {
    margin: 75px;
  }

  .fullhd\:first\:m-7:first-child {
    margin: 100px;
  }

  .fullhd\:first\:m-auto:first-child {
    margin: auto;
  }

  .fullhd\:first\:m-none:first-child {
    margin: 0px;
  }

  .fullhd\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .fullhd\:first\:m-small:first-child {
    margin: 20px;
  }

  .fullhd\:first\:m-medium:first-child {
    margin: 30px;
  }

  .fullhd\:first\:m-large:first-child {
    margin: 40px;
  }

  .fullhd\:first\:m-huge:first-child {
    margin: 60px;
  }

  .fullhd\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .fullhd\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .fullhd\:first\:-m-1:first-child {
    margin: -5px;
  }

  .fullhd\:first\:-m-2:first-child {
    margin: -10px;
  }

  .fullhd\:first\:-m-3:first-child {
    margin: -15px;
  }

  .fullhd\:first\:-m-4:first-child {
    margin: -30px;
  }

  .fullhd\:first\:-m-5:first-child {
    margin: -50px;
  }

  .fullhd\:first\:-m-6:first-child {
    margin: -75px;
  }

  .fullhd\:first\:-m-7:first-child {
    margin: -100px;
  }

  .fullhd\:first\:-m-none:first-child {
    margin: 0px;
  }

  .fullhd\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .fullhd\:first\:-m-small:first-child {
    margin: -20px;
  }

  .fullhd\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .fullhd\:first\:-m-large:first-child {
    margin: -40px;
  }

  .fullhd\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .fullhd\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .fullhd\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .fullhd\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .fullhd\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .fullhd\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .fullhd\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .fullhd\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .fullhd\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .fullhd\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .fullhd\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .fullhd\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .fullhd\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .fullhd\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .fullhd\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .fullhd\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .fullhd\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .fullhd\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .fullhd\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .fullhd\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .fullhd\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .fullhd\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .fullhd\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .fullhd\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .fullhd\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .fullhd\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .fullhd\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .fullhd\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .fullhd\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .fullhd\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .fullhd\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .fullhd\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .fullhd\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .fullhd\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .fullhd\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .fullhd\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .fullhd\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .fullhd\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .fullhd\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .fullhd\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .fullhd\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .fullhd\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .fullhd\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .fullhd\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .fullhd\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .fullhd\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .fullhd\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .fullhd\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .fullhd\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .fullhd\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .fullhd\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .fullhd\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .fullhd\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .fullhd\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .fullhd\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .fullhd\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .fullhd\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .fullhd\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .fullhd\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .fullhd\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .fullhd\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .fullhd\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .fullhd\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .fullhd\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .fullhd\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .fullhd\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .fullhd\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .fullhd\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .fullhd\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .fullhd\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .fullhd\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .fullhd\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .fullhd\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .fullhd\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .fullhd\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .fullhd\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .fullhd\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .fullhd\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .fullhd\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .fullhd\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .fullhd\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .fullhd\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .fullhd\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .fullhd\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .fullhd\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .fullhd\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .fullhd\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .fullhd\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .fullhd\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .fullhd\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .fullhd\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .fullhd\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .fullhd\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .fullhd\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .fullhd\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .fullhd\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .fullhd\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .fullhd\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .fullhd\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .fullhd\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .fullhd\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .fullhd\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .fullhd\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .fullhd\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .fullhd\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .fullhd\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .fullhd\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .fullhd\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .fullhd\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .fullhd\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .fullhd\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .fullhd\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .fullhd\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .fullhd\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .fullhd\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .fullhd\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .fullhd\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .fullhd\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .fullhd\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .fullhd\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .fullhd\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .fullhd\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .fullhd\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .fullhd\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .fullhd\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .fullhd\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .fullhd\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .fullhd\:last\:m-1:last-child {
    margin: 5px;
  }

  .fullhd\:last\:m-2:last-child {
    margin: 10px;
  }

  .fullhd\:last\:m-3:last-child {
    margin: 15px;
  }

  .fullhd\:last\:m-4:last-child {
    margin: 30px;
  }

  .fullhd\:last\:m-5:last-child {
    margin: 50px;
  }

  .fullhd\:last\:m-6:last-child {
    margin: 75px;
  }

  .fullhd\:last\:m-7:last-child {
    margin: 100px;
  }

  .fullhd\:last\:m-auto:last-child {
    margin: auto;
  }

  .fullhd\:last\:m-none:last-child {
    margin: 0px;
  }

  .fullhd\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .fullhd\:last\:m-small:last-child {
    margin: 20px;
  }

  .fullhd\:last\:m-medium:last-child {
    margin: 30px;
  }

  .fullhd\:last\:m-large:last-child {
    margin: 40px;
  }

  .fullhd\:last\:m-huge:last-child {
    margin: 60px;
  }

  .fullhd\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .fullhd\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .fullhd\:last\:-m-1:last-child {
    margin: -5px;
  }

  .fullhd\:last\:-m-2:last-child {
    margin: -10px;
  }

  .fullhd\:last\:-m-3:last-child {
    margin: -15px;
  }

  .fullhd\:last\:-m-4:last-child {
    margin: -30px;
  }

  .fullhd\:last\:-m-5:last-child {
    margin: -50px;
  }

  .fullhd\:last\:-m-6:last-child {
    margin: -75px;
  }

  .fullhd\:last\:-m-7:last-child {
    margin: -100px;
  }

  .fullhd\:last\:-m-none:last-child {
    margin: 0px;
  }

  .fullhd\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .fullhd\:last\:-m-small:last-child {
    margin: -20px;
  }

  .fullhd\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .fullhd\:last\:-m-large:last-child {
    margin: -40px;
  }

  .fullhd\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .fullhd\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .fullhd\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .fullhd\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .fullhd\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .fullhd\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .fullhd\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .fullhd\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .fullhd\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .fullhd\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .fullhd\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .fullhd\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .fullhd\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .fullhd\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .fullhd\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .fullhd\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .fullhd\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .fullhd\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .fullhd\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .fullhd\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .fullhd\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .fullhd\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .fullhd\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .fullhd\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .fullhd\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .fullhd\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .fullhd\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .fullhd\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .fullhd\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .fullhd\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .fullhd\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .fullhd\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .fullhd\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .fullhd\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .fullhd\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .fullhd\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .fullhd\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .fullhd\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .fullhd\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .fullhd\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .fullhd\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .fullhd\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .fullhd\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .fullhd\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .fullhd\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .fullhd\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .fullhd\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .fullhd\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .fullhd\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .fullhd\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .fullhd\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .fullhd\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .fullhd\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .fullhd\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .fullhd\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .fullhd\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .fullhd\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .fullhd\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .fullhd\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .fullhd\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .fullhd\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .fullhd\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .fullhd\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .fullhd\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .fullhd\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .fullhd\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .fullhd\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .fullhd\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .fullhd\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .fullhd\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .fullhd\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .fullhd\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .fullhd\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .fullhd\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .fullhd\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .fullhd\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .fullhd\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .fullhd\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .fullhd\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .fullhd\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .fullhd\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .fullhd\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .fullhd\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .fullhd\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .fullhd\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .fullhd\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .fullhd\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .fullhd\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .fullhd\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .fullhd\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .fullhd\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .fullhd\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .fullhd\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .fullhd\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .fullhd\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .fullhd\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .fullhd\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .fullhd\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .fullhd\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .fullhd\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .fullhd\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .fullhd\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .fullhd\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .fullhd\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .fullhd\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .fullhd\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .fullhd\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .fullhd\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .fullhd\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .fullhd\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .fullhd\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .fullhd\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .fullhd\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .fullhd\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .fullhd\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .fullhd\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .fullhd\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .fullhd\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .fullhd\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .fullhd\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .fullhd\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .fullhd\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .fullhd\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .fullhd\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .fullhd\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .fullhd\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .fullhd\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .fullhd\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .fullhd\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .fullhd\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .fullhd\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .fullhd\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .fullhd\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .fullhd\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .fullhd\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .fullhd\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .fullhd\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .fullhd\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .fullhd\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .fullhd\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .fullhd\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .fullhd\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .fullhd\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .fullhd\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .fullhd\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .fullhd\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .fullhd\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .fullhd\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .fullhd\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .fullhd\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .fullhd\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .fullhd\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .fullhd\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .fullhd\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .fullhd\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .fullhd\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .fullhd\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .fullhd\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .fullhd\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .fullhd\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .fullhd\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .fullhd\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .fullhd\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .fullhd\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .fullhd\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .fullhd\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .fullhd\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .fullhd\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .fullhd\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .fullhd\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .fullhd\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .fullhd\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .fullhd\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .fullhd\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .fullhd\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .fullhd\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .fullhd\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .fullhd\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .fullhd\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .fullhd\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .fullhd\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .fullhd\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .fullhd\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .fullhd\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .fullhd\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .fullhd\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .fullhd\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .fullhd\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .fullhd\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .fullhd\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .fullhd\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .fullhd\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .fullhd\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .fullhd\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .fullhd\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .fullhd\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .fullhd\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .fullhd\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .fullhd\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .fullhd\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .fullhd\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .fullhd\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .fullhd\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .fullhd\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .fullhd\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .fullhd\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .fullhd\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .fullhd\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .fullhd\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .fullhd\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .fullhd\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .fullhd\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .fullhd\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .fullhd\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .fullhd\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .fullhd\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .fullhd\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .fullhd\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .fullhd\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .fullhd\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .fullhd\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .fullhd\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .fullhd\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .fullhd\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .fullhd\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .fullhd\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .fullhd\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .fullhd\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .fullhd\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .fullhd\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .fullhd\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .fullhd\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .fullhd\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .fullhd\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .fullhd\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .fullhd\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .fullhd\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .fullhd\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .fullhd\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .fullhd\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .fullhd\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .fullhd\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .fullhd\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .fullhd\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .fullhd\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .fullhd\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .fullhd\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .fullhd\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .fullhd\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .fullhd\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .fullhd\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .fullhd\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .fullhd\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .fullhd\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .fullhd\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .fullhd\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .fullhd\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .fullhd\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .fullhd\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .fullhd\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .fullhd\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .fullhd\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .fullhd\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .fullhd\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .fullhd\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .fullhd\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .fullhd\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .fullhd\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .fullhd\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .fullhd\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .fullhd\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .fullhd\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .fullhd\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .fullhd\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .fullhd\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .fullhd\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .fullhd\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .fullhd\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .fullhd\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .fullhd\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .fullhd\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .fullhd\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .fullhd\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .fullhd\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .fullhd\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .fullhd\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .fullhd\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .fullhd\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .fullhd\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .fullhd\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .fullhd\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .fullhd\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .fullhd\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .fullhd\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .fullhd\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .fullhd\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .fullhd\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .fullhd\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .fullhd\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .fullhd\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .fullhd\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .fullhd\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .fullhd\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .fullhd\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .fullhd\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .fullhd\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .fullhd\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .fullhd\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .fullhd\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .fullhd\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .fullhd\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .fullhd\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .fullhd\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .fullhd\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fullhd\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .fullhd\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fullhd\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .fullhd\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .fullhd\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .fullhd\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .fullhd\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .fullhd\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .fullhd\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .fullhd\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .fullhd\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .fullhd\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fullhd\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .fullhd\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .fullhd\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fullhd\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullhd\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .fullhd\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .fullhd\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .fullhd\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .fullhd\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .fullhd\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .fullhd\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .fullhd\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fullhd\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .fullhd\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .fullhd\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .fullhd\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .fullhd\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .fullhd\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .fullhd\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .fullhd\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .fullhd\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .fullhd\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .fullhd\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .fullhd\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fullhd\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .fullhd\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .fullhd\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fullhd\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .fullhd\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .fullhd\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .fullhd\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .fullhd\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .fullhd\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .fullhd\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .fullhd\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .fullhd\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .fullhd\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .fullhd\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .fullhd\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .fullhd\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .fullhd\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .fullhd\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .fullhd\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .fullhd\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .fullhd\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .fullhd\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .fullhd\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .fullhd\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .fullhd\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .fullhd\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .fullhd\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .fullhd\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .fullhd\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .fullhd\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .fullhd\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .fullhd\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .fullhd\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .fullhd\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .fullhd\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .fullhd\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .fullhd\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .fullhd\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .fullhd\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .fullhd\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .fullhd\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .fullhd\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .fullhd\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .fullhd\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .fullhd\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .fullhd\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .fullhd\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .fullhd\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .fullhd\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .fullhd\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .fullhd\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .fullhd\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .fullhd\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .fullhd\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .fullhd\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .fullhd\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .fullhd\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .fullhd\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .fullhd\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .fullhd\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .fullhd\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .fullhd\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .fullhd\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .fullhd\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .fullhd\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .fullhd\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .fullhd\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .fullhd\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .fullhd\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .fullhd\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .fullhd\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .fullhd\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .fullhd\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .fullhd\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .fullhd\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .fullhd\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .fullhd\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .fullhd\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .fullhd\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .fullhd\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .fullhd\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .fullhd\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .fullhd\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .fullhd\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .fullhd\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .fullhd\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .fullhd\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .fullhd\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .fullhd\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .fullhd\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .fullhd\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .fullhd\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .fullhd\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .fullhd\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .fullhd\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .fullhd\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .fullhd\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .fullhd\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .fullhd\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .fullhd\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .fullhd\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .fullhd\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .fullhd\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .fullhd\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .fullhd\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .fullhd\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .fullhd\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .fullhd\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .fullhd\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .fullhd\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .fullhd\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .fullhd\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .fullhd\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .fullhd\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .fullhd\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .fullhd\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .fullhd\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .fullhd\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .fullhd\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .fullhd\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .fullhd\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .fullhd\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .fullhd\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .fullhd\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .fullhd\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .fullhd\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .fullhd\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .fullhd\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .fullhd\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .fullhd\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .fullhd\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .fullhd\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .fullhd\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .fullhd\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .fullhd\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .fullhd\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .fullhd\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .fullhd\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .fullhd\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .fullhd\:max-h-full {
    max-height: 100%;
  }

  .fullhd\:max-h-screen {
    max-height: 100vh;
  }

  .fullhd\:max-w-none {
    max-width: none;
  }

  .fullhd\:max-w-xs {
    max-width: 20rem;
  }

  .fullhd\:max-w-sm {
    max-width: 24rem;
  }

  .fullhd\:max-w-md {
    max-width: 28rem;
  }

  .fullhd\:max-w-lg {
    max-width: 32rem;
  }

  .fullhd\:max-w-xl {
    max-width: 36rem;
  }

  .fullhd\:max-w-2xl {
    max-width: 42rem;
  }

  .fullhd\:max-w-3xl {
    max-width: 48rem;
  }

  .fullhd\:max-w-4xl {
    max-width: 56rem;
  }

  .fullhd\:max-w-5xl {
    max-width: 64rem;
  }

  .fullhd\:max-w-6xl {
    max-width: 72rem;
  }

  .fullhd\:max-w-full {
    max-width: 100%;
  }

  .fullhd\:max-w-screen-tablet {
    max-width: 801px;
  }

  .fullhd\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .fullhd\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .fullhd\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .fullhd\:min-h-0 {
    min-height: 0;
  }

  .fullhd\:min-h-full {
    min-height: 100%;
  }

  .fullhd\:min-h-screen {
    min-height: 100vh;
  }

  .fullhd\:min-w-0 {
    min-width: 0;
  }

  .fullhd\:min-w-full {
    min-width: 100%;
  }

  .fullhd\:object-contain {
    object-fit: contain;
  }

  .fullhd\:object-cover {
    object-fit: cover;
  }

  .fullhd\:object-fill {
    object-fit: fill;
  }

  .fullhd\:object-none {
    object-fit: none;
  }

  .fullhd\:object-scale-down {
    object-fit: scale-down;
  }

  .fullhd\:object-bottom {
    object-position: bottom;
  }

  .fullhd\:object-center {
    object-position: center;
  }

  .fullhd\:object-left {
    object-position: left;
  }

  .fullhd\:object-left-bottom {
    object-position: left bottom;
  }

  .fullhd\:object-left-top {
    object-position: left top;
  }

  .fullhd\:object-right {
    object-position: right;
  }

  .fullhd\:object-right-bottom {
    object-position: right bottom;
  }

  .fullhd\:object-right-top {
    object-position: right top;
  }

  .fullhd\:object-top {
    object-position: top;
  }

  .fullhd\:opacity-0 {
    opacity: 0;
  }

  .fullhd\:opacity-25 {
    opacity: 0.25;
  }

  .fullhd\:opacity-50 {
    opacity: 0.5;
  }

  .fullhd\:opacity-75 {
    opacity: 0.75;
  }

  .fullhd\:opacity-100 {
    opacity: 1;
  }

  .fullhd\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .fullhd\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .fullhd\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .fullhd\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .fullhd\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .fullhd\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .fullhd\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .fullhd\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .fullhd\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .fullhd\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .fullhd\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .fullhd\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .fullhd\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .fullhd\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .fullhd\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .fullhd\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .fullhd\:overflow-auto {
    overflow: auto;
  }

  .fullhd\:overflow-hidden {
    overflow: hidden;
  }

  .fullhd\:overflow-visible {
    overflow: visible;
  }

  .fullhd\:overflow-scroll {
    overflow: scroll;
  }

  .fullhd\:overflow-x-auto {
    overflow-x: auto;
  }

  .fullhd\:overflow-y-auto {
    overflow-y: auto;
  }

  .fullhd\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .fullhd\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .fullhd\:overflow-x-visible {
    overflow-x: visible;
  }

  .fullhd\:overflow-y-visible {
    overflow-y: visible;
  }

  .fullhd\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .fullhd\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .fullhd\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .fullhd\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .fullhd\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .fullhd\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .fullhd\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .fullhd\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .fullhd\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .fullhd\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .fullhd\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .fullhd\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .fullhd\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .fullhd\:p-1 {
    padding: 5px;
  }

  .fullhd\:p-2 {
    padding: 10px;
  }

  .fullhd\:p-3 {
    padding: 15px;
  }

  .fullhd\:p-4 {
    padding: 30px;
  }

  .fullhd\:p-5 {
    padding: 50px;
  }

  .fullhd\:p-6 {
    padding: 75px;
  }

  .fullhd\:p-7 {
    padding: 100px;
  }

  .fullhd\:p-none {
    padding: 0px;
  }

  .fullhd\:p-tiny {
    padding: 10px;
  }

  .fullhd\:p-small {
    padding: 20px;
  }

  .fullhd\:p-medium {
    padding: 30px;
  }

  .fullhd\:p-large {
    padding: 40px;
  }

  .fullhd\:p-huge {
    padding: 60px;
  }

  .fullhd\:p-margin-label-height {
    padding: 26px;
  }

  .fullhd\:p-nav-height {
    padding: 80px;
  }

  .fullhd\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullhd\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fullhd\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fullhd\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullhd\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fullhd\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .fullhd\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .fullhd\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .fullhd\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fullhd\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .fullhd\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fullhd\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fullhd\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fullhd\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullhd\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fullhd\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fullhd\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fullhd\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .fullhd\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .fullhd\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .fullhd\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .fullhd\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .fullhd\:pt-1 {
    padding-top: 5px;
  }

  .fullhd\:pr-1 {
    padding-right: 5px;
  }

  .fullhd\:pb-1 {
    padding-bottom: 5px;
  }

  .fullhd\:pl-1 {
    padding-left: 5px;
  }

  .fullhd\:pt-2 {
    padding-top: 10px;
  }

  .fullhd\:pr-2 {
    padding-right: 10px;
  }

  .fullhd\:pb-2 {
    padding-bottom: 10px;
  }

  .fullhd\:pl-2 {
    padding-left: 10px;
  }

  .fullhd\:pt-3 {
    padding-top: 15px;
  }

  .fullhd\:pr-3 {
    padding-right: 15px;
  }

  .fullhd\:pb-3 {
    padding-bottom: 15px;
  }

  .fullhd\:pl-3 {
    padding-left: 15px;
  }

  .fullhd\:pt-4 {
    padding-top: 30px;
  }

  .fullhd\:pr-4 {
    padding-right: 30px;
  }

  .fullhd\:pb-4 {
    padding-bottom: 30px;
  }

  .fullhd\:pl-4 {
    padding-left: 30px;
  }

  .fullhd\:pt-5 {
    padding-top: 50px;
  }

  .fullhd\:pr-5 {
    padding-right: 50px;
  }

  .fullhd\:pb-5 {
    padding-bottom: 50px;
  }

  .fullhd\:pl-5 {
    padding-left: 50px;
  }

  .fullhd\:pt-6 {
    padding-top: 75px;
  }

  .fullhd\:pr-6 {
    padding-right: 75px;
  }

  .fullhd\:pb-6 {
    padding-bottom: 75px;
  }

  .fullhd\:pl-6 {
    padding-left: 75px;
  }

  .fullhd\:pt-7 {
    padding-top: 100px;
  }

  .fullhd\:pr-7 {
    padding-right: 100px;
  }

  .fullhd\:pb-7 {
    padding-bottom: 100px;
  }

  .fullhd\:pl-7 {
    padding-left: 100px;
  }

  .fullhd\:pt-none {
    padding-top: 0px;
  }

  .fullhd\:pr-none {
    padding-right: 0px;
  }

  .fullhd\:pb-none {
    padding-bottom: 0px;
  }

  .fullhd\:pl-none {
    padding-left: 0px;
  }

  .fullhd\:pt-tiny {
    padding-top: 10px;
  }

  .fullhd\:pr-tiny {
    padding-right: 10px;
  }

  .fullhd\:pb-tiny {
    padding-bottom: 10px;
  }

  .fullhd\:pl-tiny {
    padding-left: 10px;
  }

  .fullhd\:pt-small {
    padding-top: 20px;
  }

  .fullhd\:pr-small {
    padding-right: 20px;
  }

  .fullhd\:pb-small {
    padding-bottom: 20px;
  }

  .fullhd\:pl-small {
    padding-left: 20px;
  }

  .fullhd\:pt-medium {
    padding-top: 30px;
  }

  .fullhd\:pr-medium {
    padding-right: 30px;
  }

  .fullhd\:pb-medium {
    padding-bottom: 30px;
  }

  .fullhd\:pl-medium {
    padding-left: 30px;
  }

  .fullhd\:pt-large {
    padding-top: 40px;
  }

  .fullhd\:pr-large {
    padding-right: 40px;
  }

  .fullhd\:pb-large {
    padding-bottom: 40px;
  }

  .fullhd\:pl-large {
    padding-left: 40px;
  }

  .fullhd\:pt-huge {
    padding-top: 60px;
  }

  .fullhd\:pr-huge {
    padding-right: 60px;
  }

  .fullhd\:pb-huge {
    padding-bottom: 60px;
  }

  .fullhd\:pl-huge {
    padding-left: 60px;
  }

  .fullhd\:pt-margin-label-height {
    padding-top: 26px;
  }

  .fullhd\:pr-margin-label-height {
    padding-right: 26px;
  }

  .fullhd\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .fullhd\:pl-margin-label-height {
    padding-left: 26px;
  }

  .fullhd\:pt-nav-height {
    padding-top: 80px;
  }

  .fullhd\:pr-nav-height {
    padding-right: 80px;
  }

  .fullhd\:pb-nav-height {
    padding-bottom: 80px;
  }

  .fullhd\:pl-nav-height {
    padding-left: 80px;
  }

  .fullhd\:hover\:p-1:hover {
    padding: 5px;
  }

  .fullhd\:hover\:p-2:hover {
    padding: 10px;
  }

  .fullhd\:hover\:p-3:hover {
    padding: 15px;
  }

  .fullhd\:hover\:p-4:hover {
    padding: 30px;
  }

  .fullhd\:hover\:p-5:hover {
    padding: 50px;
  }

  .fullhd\:hover\:p-6:hover {
    padding: 75px;
  }

  .fullhd\:hover\:p-7:hover {
    padding: 100px;
  }

  .fullhd\:hover\:p-none:hover {
    padding: 0px;
  }

  .fullhd\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .fullhd\:hover\:p-small:hover {
    padding: 20px;
  }

  .fullhd\:hover\:p-medium:hover {
    padding: 30px;
  }

  .fullhd\:hover\:p-large:hover {
    padding: 40px;
  }

  .fullhd\:hover\:p-huge:hover {
    padding: 60px;
  }

  .fullhd\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .fullhd\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .fullhd\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullhd\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fullhd\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fullhd\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullhd\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fullhd\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .fullhd\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .fullhd\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .fullhd\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fullhd\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .fullhd\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fullhd\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fullhd\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fullhd\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullhd\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fullhd\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fullhd\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fullhd\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .fullhd\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .fullhd\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .fullhd\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .fullhd\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .fullhd\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .fullhd\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .fullhd\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .fullhd\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .fullhd\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .fullhd\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .fullhd\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .fullhd\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .fullhd\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .fullhd\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .fullhd\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .fullhd\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .fullhd\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .fullhd\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .fullhd\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .fullhd\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .fullhd\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .fullhd\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .fullhd\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .fullhd\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .fullhd\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .fullhd\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .fullhd\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .fullhd\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .fullhd\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .fullhd\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .fullhd\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .fullhd\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .fullhd\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .fullhd\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .fullhd\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .fullhd\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .fullhd\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .fullhd\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .fullhd\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .fullhd\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .fullhd\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .fullhd\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .fullhd\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .fullhd\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .fullhd\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .fullhd\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .fullhd\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .fullhd\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .fullhd\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .fullhd\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .fullhd\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .fullhd\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .fullhd\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .fullhd\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .fullhd\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .fullhd\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .fullhd\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .fullhd\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .fullhd\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .fullhd\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .fullhd\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .fullhd\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .fullhd\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .fullhd\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .fullhd\:focus\:p-1:focus {
    padding: 5px;
  }

  .fullhd\:focus\:p-2:focus {
    padding: 10px;
  }

  .fullhd\:focus\:p-3:focus {
    padding: 15px;
  }

  .fullhd\:focus\:p-4:focus {
    padding: 30px;
  }

  .fullhd\:focus\:p-5:focus {
    padding: 50px;
  }

  .fullhd\:focus\:p-6:focus {
    padding: 75px;
  }

  .fullhd\:focus\:p-7:focus {
    padding: 100px;
  }

  .fullhd\:focus\:p-none:focus {
    padding: 0px;
  }

  .fullhd\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .fullhd\:focus\:p-small:focus {
    padding: 20px;
  }

  .fullhd\:focus\:p-medium:focus {
    padding: 30px;
  }

  .fullhd\:focus\:p-large:focus {
    padding: 40px;
  }

  .fullhd\:focus\:p-huge:focus {
    padding: 60px;
  }

  .fullhd\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .fullhd\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .fullhd\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullhd\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fullhd\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fullhd\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullhd\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fullhd\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .fullhd\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .fullhd\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .fullhd\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fullhd\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .fullhd\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fullhd\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fullhd\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fullhd\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullhd\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fullhd\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fullhd\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fullhd\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .fullhd\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .fullhd\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .fullhd\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .fullhd\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .fullhd\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .fullhd\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .fullhd\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .fullhd\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .fullhd\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .fullhd\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .fullhd\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .fullhd\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .fullhd\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .fullhd\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .fullhd\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .fullhd\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .fullhd\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .fullhd\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .fullhd\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .fullhd\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .fullhd\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .fullhd\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .fullhd\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .fullhd\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .fullhd\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .fullhd\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .fullhd\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .fullhd\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .fullhd\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .fullhd\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .fullhd\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .fullhd\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .fullhd\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .fullhd\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .fullhd\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .fullhd\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .fullhd\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .fullhd\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .fullhd\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .fullhd\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .fullhd\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .fullhd\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .fullhd\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .fullhd\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .fullhd\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .fullhd\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .fullhd\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .fullhd\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .fullhd\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .fullhd\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .fullhd\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .fullhd\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .fullhd\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .fullhd\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .fullhd\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .fullhd\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .fullhd\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .fullhd\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .fullhd\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .fullhd\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .fullhd\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .fullhd\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .fullhd\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .fullhd\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .fullhd\:first\:p-1:first-child {
    padding: 5px;
  }

  .fullhd\:first\:p-2:first-child {
    padding: 10px;
  }

  .fullhd\:first\:p-3:first-child {
    padding: 15px;
  }

  .fullhd\:first\:p-4:first-child {
    padding: 30px;
  }

  .fullhd\:first\:p-5:first-child {
    padding: 50px;
  }

  .fullhd\:first\:p-6:first-child {
    padding: 75px;
  }

  .fullhd\:first\:p-7:first-child {
    padding: 100px;
  }

  .fullhd\:first\:p-none:first-child {
    padding: 0px;
  }

  .fullhd\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .fullhd\:first\:p-small:first-child {
    padding: 20px;
  }

  .fullhd\:first\:p-medium:first-child {
    padding: 30px;
  }

  .fullhd\:first\:p-large:first-child {
    padding: 40px;
  }

  .fullhd\:first\:p-huge:first-child {
    padding: 60px;
  }

  .fullhd\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .fullhd\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .fullhd\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullhd\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fullhd\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fullhd\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullhd\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fullhd\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .fullhd\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .fullhd\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .fullhd\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fullhd\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .fullhd\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fullhd\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fullhd\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fullhd\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullhd\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fullhd\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fullhd\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fullhd\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .fullhd\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .fullhd\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .fullhd\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .fullhd\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .fullhd\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .fullhd\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .fullhd\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .fullhd\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .fullhd\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .fullhd\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .fullhd\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .fullhd\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .fullhd\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .fullhd\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .fullhd\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .fullhd\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .fullhd\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .fullhd\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .fullhd\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .fullhd\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .fullhd\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .fullhd\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .fullhd\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .fullhd\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .fullhd\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .fullhd\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .fullhd\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .fullhd\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .fullhd\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .fullhd\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .fullhd\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .fullhd\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .fullhd\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .fullhd\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .fullhd\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .fullhd\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .fullhd\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .fullhd\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .fullhd\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .fullhd\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .fullhd\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .fullhd\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .fullhd\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .fullhd\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .fullhd\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .fullhd\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .fullhd\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .fullhd\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .fullhd\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .fullhd\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .fullhd\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .fullhd\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .fullhd\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .fullhd\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .fullhd\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .fullhd\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .fullhd\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .fullhd\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .fullhd\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .fullhd\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .fullhd\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .fullhd\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .fullhd\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .fullhd\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .fullhd\:last\:p-1:last-child {
    padding: 5px;
  }

  .fullhd\:last\:p-2:last-child {
    padding: 10px;
  }

  .fullhd\:last\:p-3:last-child {
    padding: 15px;
  }

  .fullhd\:last\:p-4:last-child {
    padding: 30px;
  }

  .fullhd\:last\:p-5:last-child {
    padding: 50px;
  }

  .fullhd\:last\:p-6:last-child {
    padding: 75px;
  }

  .fullhd\:last\:p-7:last-child {
    padding: 100px;
  }

  .fullhd\:last\:p-none:last-child {
    padding: 0px;
  }

  .fullhd\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .fullhd\:last\:p-small:last-child {
    padding: 20px;
  }

  .fullhd\:last\:p-medium:last-child {
    padding: 30px;
  }

  .fullhd\:last\:p-large:last-child {
    padding: 40px;
  }

  .fullhd\:last\:p-huge:last-child {
    padding: 60px;
  }

  .fullhd\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .fullhd\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .fullhd\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullhd\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fullhd\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fullhd\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullhd\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fullhd\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .fullhd\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .fullhd\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .fullhd\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fullhd\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .fullhd\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fullhd\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fullhd\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fullhd\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fullhd\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fullhd\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullhd\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .fullhd\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fullhd\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fullhd\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .fullhd\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .fullhd\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .fullhd\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .fullhd\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .fullhd\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .fullhd\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .fullhd\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .fullhd\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .fullhd\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .fullhd\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .fullhd\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .fullhd\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .fullhd\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .fullhd\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .fullhd\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .fullhd\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .fullhd\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .fullhd\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .fullhd\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .fullhd\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .fullhd\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .fullhd\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .fullhd\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .fullhd\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .fullhd\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .fullhd\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .fullhd\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .fullhd\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .fullhd\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .fullhd\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .fullhd\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .fullhd\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .fullhd\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .fullhd\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .fullhd\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .fullhd\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .fullhd\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .fullhd\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .fullhd\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .fullhd\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .fullhd\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .fullhd\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .fullhd\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .fullhd\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .fullhd\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .fullhd\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .fullhd\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .fullhd\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .fullhd\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .fullhd\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .fullhd\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .fullhd\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .fullhd\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .fullhd\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .fullhd\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .fullhd\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .fullhd\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .fullhd\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .fullhd\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .fullhd\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .fullhd\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .fullhd\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .fullhd\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .fullhd\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .fullhd\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .fullhd\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .fullhd\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .fullhd\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .fullhd\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .fullhd\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .fullhd\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .fullhd\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .fullhd\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .fullhd\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .fullhd\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .fullhd\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .fullhd\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .fullhd\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .fullhd\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .fullhd\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .fullhd\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .fullhd\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .fullhd\:pointer-events-none {
    pointer-events: none;
  }

  .fullhd\:pointer-events-auto {
    pointer-events: auto;
  }

  .fullhd\:static {
    position: static;
  }

  .fullhd\:fixed {
    position: fixed;
  }

  .fullhd\:absolute {
    position: absolute;
  }

  .fullhd\:relative {
    position: relative;
  }

  .fullhd\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .fullhd\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .fullhd\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .fullhd\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .fullhd\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .fullhd\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .fullhd\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .fullhd\:top-0 {
    top: 0;
  }

  .fullhd\:right-0 {
    right: 0;
  }

  .fullhd\:bottom-0 {
    bottom: 0;
  }

  .fullhd\:left-0 {
    left: 0;
  }

  .fullhd\:top-auto {
    top: auto;
  }

  .fullhd\:right-auto {
    right: auto;
  }

  .fullhd\:bottom-auto {
    bottom: auto;
  }

  .fullhd\:left-auto {
    left: auto;
  }

  .fullhd\:resize-none {
    resize: none;
  }

  .fullhd\:resize-y {
    resize: vertical;
  }

  .fullhd\:resize-x {
    resize: horizontal;
  }

  .fullhd\:resize {
    resize: both;
  }

  .fullhd\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .fullhd\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .fullhd\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .fullhd\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .fullhd\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .fullhd\:shadow-none {
    box-shadow: none;
  }

  .fullhd\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .fullhd\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .fullhd\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .fullhd\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .fullhd\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .fullhd\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .fullhd\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .fullhd\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .fullhd\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .fullhd\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .fullhd\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .fullhd\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .fullhd\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .fullhd\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .fullhd\:fill-current {
    fill: currentColor;
  }

  .fullhd\:stroke-current {
    stroke: currentColor;
  }

  .fullhd\:stroke-0 {
    stroke-width: 0;
  }

  .fullhd\:stroke-1 {
    stroke-width: 1;
  }

  .fullhd\:stroke-2 {
    stroke-width: 2;
  }

  .fullhd\:table-auto {
    table-layout: auto;
  }

  .fullhd\:table-fixed {
    table-layout: fixed;
  }

  .fullhd\:text-left {
    text-align: left;
  }

  .fullhd\:text-center {
    text-align: center;
  }

  .fullhd\:text-right {
    text-align: right;
  }

  .fullhd\:text-justify {
    text-align: justify;
  }

  .fullhd\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .fullhd\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .fullhd\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .fullhd\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .fullhd\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .fullhd\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .fullhd\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .fullhd\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .fullhd\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .fullhd\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .fullhd\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .fullhd\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .fullhd\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .fullhd\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .fullhd\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .fullhd\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .fullhd\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .fullhd\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .fullhd\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .fullhd\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .fullhd\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .fullhd\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .fullhd\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .fullhd\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .fullhd\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .fullhd\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .fullhd\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .fullhd\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .fullhd\:text-state-danger {
    color: #FF8274B3;
  }

  .fullhd\:text-state-warning {
    color: #F9C66BB3;
  }

  .fullhd\:text-state-success {
    color: #C7F6C9B3;
  }

  .fullhd\:text-state-primary {
    color: #B3F5FFB3;
  }

  .fullhd\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .fullhd\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .fullhd\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .fullhd\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .fullhd\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .fullhd\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .fullhd\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .fullhd\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .fullhd\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .fullhd\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .fullhd\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .fullhd\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .fullhd\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .fullhd\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .fullhd\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .fullhd\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .fullhd\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .fullhd\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .fullhd\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .fullhd\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .fullhd\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .fullhd\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .fullhd\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .fullhd\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .fullhd\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .fullhd\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .fullhd\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .fullhd\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .fullhd\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .fullhd\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .fullhd\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .fullhd\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .fullhd\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .fullhd\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .fullhd\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .fullhd\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .fullhd\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .fullhd\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .fullhd\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .fullhd\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .fullhd\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .fullhd\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .fullhd\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .fullhd\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .fullhd\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .fullhd\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .fullhd\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .fullhd\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .fullhd\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .fullhd\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .fullhd\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .fullhd\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .fullhd\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .fullhd\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .fullhd\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .fullhd\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .fullhd\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .fullhd\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .fullhd\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .fullhd\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .fullhd\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .fullhd\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .fullhd\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .fullhd\:text-opacity-0 {
    --text-opacity: 0;
  }

  .fullhd\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .fullhd\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .fullhd\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .fullhd\:text-opacity-100 {
    --text-opacity: 1;
  }

  .fullhd\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .fullhd\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .fullhd\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .fullhd\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .fullhd\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .fullhd\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .fullhd\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .fullhd\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .fullhd\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .fullhd\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .fullhd\:italic {
    font-style: italic;
  }

  .fullhd\:not-italic {
    font-style: normal;
  }

  .fullhd\:uppercase {
    text-transform: uppercase;
  }

  .fullhd\:lowercase {
    text-transform: lowercase;
  }

  .fullhd\:capitalize {
    text-transform: capitalize;
  }

  .fullhd\:normal-case {
    text-transform: none;
  }

  .fullhd\:underline {
    text-decoration: underline;
  }

  .fullhd\:line-through {
    text-decoration: line-through;
  }

  .fullhd\:no-underline {
    text-decoration: none;
  }

  .fullhd\:hover\:underline:hover {
    text-decoration: underline;
  }

  .fullhd\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .fullhd\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .fullhd\:focus\:underline:focus {
    text-decoration: underline;
  }

  .fullhd\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .fullhd\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .fullhd\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fullhd\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .fullhd\:ordinal, .fullhd\:slashed-zero, .fullhd\:lining-nums, .fullhd\:oldstyle-nums, .fullhd\:proportional-nums, .fullhd\:tabular-nums, .fullhd\:diagonal-fractions, .fullhd\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .fullhd\:normal-nums {
    font-variant-numeric: normal;
  }

  .fullhd\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .fullhd\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .fullhd\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .fullhd\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .fullhd\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .fullhd\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .fullhd\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .fullhd\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .fullhd\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .fullhd\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .fullhd\:tracking-normal {
    letter-spacing: 0;
  }

  .fullhd\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .fullhd\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .fullhd\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .fullhd\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .fullhd\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .fullhd\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .fullhd\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .fullhd\:align-baseline {
    vertical-align: baseline;
  }

  .fullhd\:align-top {
    vertical-align: top;
  }

  .fullhd\:align-middle {
    vertical-align: middle;
  }

  .fullhd\:align-bottom {
    vertical-align: bottom;
  }

  .fullhd\:align-text-top {
    vertical-align: text-top;
  }

  .fullhd\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .fullhd\:visible {
    visibility: visible;
  }

  .fullhd\:invisible {
    visibility: hidden;
  }

  .fullhd\:whitespace-normal {
    white-space: normal;
  }

  .fullhd\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .fullhd\:whitespace-pre {
    white-space: pre;
  }

  .fullhd\:whitespace-pre-line {
    white-space: pre-line;
  }

  .fullhd\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .fullhd\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .fullhd\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .fullhd\:break-all {
    word-break: break-all;
  }

  .fullhd\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fullhd\:w-1 {
    width: 5px;
  }

  .fullhd\:w-2 {
    width: 10px;
  }

  .fullhd\:w-3 {
    width: 15px;
  }

  .fullhd\:w-4 {
    width: 30px;
  }

  .fullhd\:w-5 {
    width: 50px;
  }

  .fullhd\:w-6 {
    width: 75px;
  }

  .fullhd\:w-7 {
    width: 100px;
  }

  .fullhd\:w-auto {
    width: auto;
  }

  .fullhd\:w-none {
    width: 0px;
  }

  .fullhd\:w-tiny {
    width: 10px;
  }

  .fullhd\:w-small {
    width: 20px;
  }

  .fullhd\:w-medium {
    width: 30px;
  }

  .fullhd\:w-large {
    width: 40px;
  }

  .fullhd\:w-huge {
    width: 60px;
  }

  .fullhd\:w-margin-label-height {
    width: 26px;
  }

  .fullhd\:w-nav-height {
    width: 80px;
  }

  .fullhd\:w-1\/2 {
    width: 50%;
  }

  .fullhd\:w-1\/3 {
    width: 33.333333%;
  }

  .fullhd\:w-2\/3 {
    width: 66.666667%;
  }

  .fullhd\:w-1\/4 {
    width: 25%;
  }

  .fullhd\:w-2\/4 {
    width: 50%;
  }

  .fullhd\:w-3\/4 {
    width: 75%;
  }

  .fullhd\:w-1\/5 {
    width: 20%;
  }

  .fullhd\:w-2\/5 {
    width: 40%;
  }

  .fullhd\:w-3\/5 {
    width: 60%;
  }

  .fullhd\:w-4\/5 {
    width: 80%;
  }

  .fullhd\:w-1\/6 {
    width: 16.666667%;
  }

  .fullhd\:w-2\/6 {
    width: 33.333333%;
  }

  .fullhd\:w-3\/6 {
    width: 50%;
  }

  .fullhd\:w-4\/6 {
    width: 66.666667%;
  }

  .fullhd\:w-5\/6 {
    width: 83.333333%;
  }

  .fullhd\:w-1\/12 {
    width: 8.333333%;
  }

  .fullhd\:w-2\/12 {
    width: 16.666667%;
  }

  .fullhd\:w-3\/12 {
    width: 25%;
  }

  .fullhd\:w-4\/12 {
    width: 33.333333%;
  }

  .fullhd\:w-5\/12 {
    width: 41.666667%;
  }

  .fullhd\:w-6\/12 {
    width: 50%;
  }

  .fullhd\:w-7\/12 {
    width: 58.333333%;
  }

  .fullhd\:w-8\/12 {
    width: 66.666667%;
  }

  .fullhd\:w-9\/12 {
    width: 75%;
  }

  .fullhd\:w-10\/12 {
    width: 83.333333%;
  }

  .fullhd\:w-11\/12 {
    width: 91.666667%;
  }

  .fullhd\:w-full {
    width: 100%;
  }

  .fullhd\:w-screen {
    width: 100vw;
  }

  .fullhd\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .fullhd\:z-0 {
    z-index: 0;
  }

  .fullhd\:z-10 {
    z-index: 10;
  }

  .fullhd\:z-20 {
    z-index: 20;
  }

  .fullhd\:z-30 {
    z-index: 30;
  }

  .fullhd\:z-40 {
    z-index: 40;
  }

  .fullhd\:z-50 {
    z-index: 50;
  }

  .fullhd\:z-auto {
    z-index: auto;
  }

  .fullhd\:z-nav {
    z-index: 90;
  }

  .fullhd\:z-menu {
    z-index: 100;
  }

  .fullhd\:z-popup {
    z-index: 110;
  }

  .fullhd\:z-modalBackdrop {
    z-index: 120;
  }

  .fullhd\:z-modal {
    z-index: 130;
  }

  .fullhd\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .fullhd\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .fullhd\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .fullhd\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .fullhd\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .fullhd\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .fullhd\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .fullhd\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .fullhd\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .fullhd\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .fullhd\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .fullhd\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .fullhd\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .fullhd\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .fullhd\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .fullhd\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .fullhd\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .fullhd\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .fullhd\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .fullhd\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .fullhd\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .fullhd\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .fullhd\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .fullhd\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .fullhd\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .fullhd\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .fullhd\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .fullhd\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .fullhd\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .fullhd\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .fullhd\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .fullhd\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .fullhd\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .fullhd\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .fullhd\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .fullhd\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .fullhd\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .fullhd\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .fullhd\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .fullhd\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .fullhd\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .fullhd\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .fullhd\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .fullhd\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .fullhd\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .fullhd\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .fullhd\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .fullhd\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .fullhd\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .fullhd\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .fullhd\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .fullhd\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .fullhd\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .fullhd\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .fullhd\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .fullhd\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .fullhd\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .fullhd\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .fullhd\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .fullhd\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .fullhd\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .fullhd\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .fullhd\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .fullhd\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .fullhd\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .fullhd\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .fullhd\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .fullhd\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .fullhd\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .fullhd\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .fullhd\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .fullhd\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .fullhd\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .fullhd\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .fullhd\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .fullhd\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .fullhd\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .fullhd\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .fullhd\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .fullhd\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .fullhd\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .fullhd\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .fullhd\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .fullhd\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .fullhd\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .fullhd\:grid-flow-row {
    grid-auto-flow: row;
  }

  .fullhd\:grid-flow-col {
    grid-auto-flow: column;
  }

  .fullhd\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .fullhd\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .fullhd\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .fullhd\:grid-cols-none {
    grid-template-columns: none;
  }

  .fullhd\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .fullhd\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .fullhd\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .fullhd\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .fullhd\:col-auto {
    grid-column: auto;
  }

  .fullhd\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .fullhd\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .fullhd\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .fullhd\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .fullhd\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .fullhd\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .fullhd\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .fullhd\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .fullhd\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .fullhd\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .fullhd\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .fullhd\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .fullhd\:col-span-full {
    grid-column: 1 / -1;
  }

  .fullhd\:col-start-1 {
    grid-column-start: 1;
  }

  .fullhd\:col-start-2 {
    grid-column-start: 2;
  }

  .fullhd\:col-start-3 {
    grid-column-start: 3;
  }

  .fullhd\:col-start-4 {
    grid-column-start: 4;
  }

  .fullhd\:col-start-5 {
    grid-column-start: 5;
  }

  .fullhd\:col-start-6 {
    grid-column-start: 6;
  }

  .fullhd\:col-start-7 {
    grid-column-start: 7;
  }

  .fullhd\:col-start-8 {
    grid-column-start: 8;
  }

  .fullhd\:col-start-9 {
    grid-column-start: 9;
  }

  .fullhd\:col-start-10 {
    grid-column-start: 10;
  }

  .fullhd\:col-start-11 {
    grid-column-start: 11;
  }

  .fullhd\:col-start-12 {
    grid-column-start: 12;
  }

  .fullhd\:col-start-13 {
    grid-column-start: 13;
  }

  .fullhd\:col-start-auto {
    grid-column-start: auto;
  }

  .fullhd\:col-end-1 {
    grid-column-end: 1;
  }

  .fullhd\:col-end-2 {
    grid-column-end: 2;
  }

  .fullhd\:col-end-3 {
    grid-column-end: 3;
  }

  .fullhd\:col-end-4 {
    grid-column-end: 4;
  }

  .fullhd\:col-end-5 {
    grid-column-end: 5;
  }

  .fullhd\:col-end-6 {
    grid-column-end: 6;
  }

  .fullhd\:col-end-7 {
    grid-column-end: 7;
  }

  .fullhd\:col-end-8 {
    grid-column-end: 8;
  }

  .fullhd\:col-end-9 {
    grid-column-end: 9;
  }

  .fullhd\:col-end-10 {
    grid-column-end: 10;
  }

  .fullhd\:col-end-11 {
    grid-column-end: 11;
  }

  .fullhd\:col-end-12 {
    grid-column-end: 12;
  }

  .fullhd\:col-end-13 {
    grid-column-end: 13;
  }

  .fullhd\:col-end-auto {
    grid-column-end: auto;
  }

  .fullhd\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .fullhd\:grid-rows-none {
    grid-template-rows: none;
  }

  .fullhd\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .fullhd\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .fullhd\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .fullhd\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .fullhd\:row-auto {
    grid-row: auto;
  }

  .fullhd\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .fullhd\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .fullhd\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .fullhd\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .fullhd\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .fullhd\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .fullhd\:row-span-full {
    grid-row: 1 / -1;
  }

  .fullhd\:row-start-1 {
    grid-row-start: 1;
  }

  .fullhd\:row-start-2 {
    grid-row-start: 2;
  }

  .fullhd\:row-start-3 {
    grid-row-start: 3;
  }

  .fullhd\:row-start-4 {
    grid-row-start: 4;
  }

  .fullhd\:row-start-5 {
    grid-row-start: 5;
  }

  .fullhd\:row-start-6 {
    grid-row-start: 6;
  }

  .fullhd\:row-start-7 {
    grid-row-start: 7;
  }

  .fullhd\:row-start-auto {
    grid-row-start: auto;
  }

  .fullhd\:row-end-1 {
    grid-row-end: 1;
  }

  .fullhd\:row-end-2 {
    grid-row-end: 2;
  }

  .fullhd\:row-end-3 {
    grid-row-end: 3;
  }

  .fullhd\:row-end-4 {
    grid-row-end: 4;
  }

  .fullhd\:row-end-5 {
    grid-row-end: 5;
  }

  .fullhd\:row-end-6 {
    grid-row-end: 6;
  }

  .fullhd\:row-end-7 {
    grid-row-end: 7;
  }

  .fullhd\:row-end-auto {
    grid-row-end: auto;
  }

  .fullhd\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .fullhd\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .fullhd\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .fullhd\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .fullhd\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .fullhd\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .fullhd\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .fullhd\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .fullhd\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .fullhd\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .fullhd\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .fullhd\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .fullhd\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .fullhd\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .fullhd\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .fullhd\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .fullhd\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .fullhd\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .fullhd\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .fullhd\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .fullhd\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .fullhd\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .fullhd\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .fullhd\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .fullhd\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .fullhd\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .fullhd\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .fullhd\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .fullhd\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .fullhd\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .fullhd\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .fullhd\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .fullhd\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .fullhd\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .fullhd\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .fullhd\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .fullhd\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .fullhd\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .fullhd\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .fullhd\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .fullhd\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .fullhd\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .fullhd\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .fullhd\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .fullhd\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .fullhd\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .fullhd\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .fullhd\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .fullhd\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .fullhd\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .fullhd\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .fullhd\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .fullhd\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .fullhd\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .fullhd\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .fullhd\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .fullhd\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .fullhd\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .fullhd\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .fullhd\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .fullhd\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .fullhd\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .fullhd\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .fullhd\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .fullhd\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .fullhd\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .fullhd\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .fullhd\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .fullhd\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .fullhd\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .fullhd\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .fullhd\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .fullhd\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .fullhd\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .fullhd\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .fullhd\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .fullhd\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .fullhd\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .fullhd\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .fullhd\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .fullhd\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .fullhd\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .fullhd\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .fullhd\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .fullhd\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .fullhd\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .fullhd\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .fullhd\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .fullhd\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .fullhd\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .fullhd\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .fullhd\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .fullhd\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .fullhd\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .fullhd\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .fullhd\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .fullhd\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .fullhd\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .fullhd\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .fullhd\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .fullhd\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .fullhd\:rotate-0 {
    --transform-rotate: 0;
  }

  .fullhd\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .fullhd\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .fullhd\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .fullhd\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .fullhd\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .fullhd\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .fullhd\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .fullhd\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .fullhd\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .fullhd\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .fullhd\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .fullhd\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .fullhd\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .fullhd\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .fullhd\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .fullhd\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .fullhd\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .fullhd\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .fullhd\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .fullhd\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .fullhd\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .fullhd\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .fullhd\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .fullhd\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .fullhd\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .fullhd\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .fullhd\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .fullhd\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .fullhd\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .fullhd\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .fullhd\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .fullhd\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .fullhd\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .fullhd\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .fullhd\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .fullhd\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .fullhd\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .fullhd\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .fullhd\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .fullhd\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .fullhd\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .fullhd\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .fullhd\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .fullhd\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .fullhd\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .fullhd\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .fullhd\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .fullhd\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .fullhd\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .fullhd\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .fullhd\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .fullhd\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .fullhd\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .fullhd\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .fullhd\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .fullhd\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .fullhd\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .fullhd\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .fullhd\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .fullhd\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .fullhd\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .fullhd\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .fullhd\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .fullhd\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .fullhd\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .fullhd\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .fullhd\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .fullhd\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .fullhd\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .fullhd\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .fullhd\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .fullhd\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .fullhd\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .fullhd\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .fullhd\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .fullhd\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .fullhd\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .fullhd\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .fullhd\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .fullhd\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .fullhd\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .fullhd\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .fullhd\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .fullhd\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .fullhd\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .fullhd\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .fullhd\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .fullhd\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .fullhd\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .fullhd\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .fullhd\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .fullhd\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .fullhd\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .fullhd\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .fullhd\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .fullhd\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .fullhd\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .fullhd\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .fullhd\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .fullhd\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .fullhd\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .fullhd\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .fullhd\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .fullhd\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .fullhd\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .fullhd\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .fullhd\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .fullhd\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .fullhd\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .fullhd\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .fullhd\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .fullhd\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .fullhd\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .fullhd\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .fullhd\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .fullhd\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .fullhd\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .fullhd\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .fullhd\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .fullhd\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .fullhd\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .fullhd\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .fullhd\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .fullhd\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .fullhd\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .fullhd\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .fullhd\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .fullhd\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .fullhd\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .fullhd\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .fullhd\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .fullhd\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .fullhd\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .fullhd\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .fullhd\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .fullhd\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .fullhd\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .fullhd\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .fullhd\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .fullhd\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .fullhd\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .fullhd\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .fullhd\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .fullhd\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .fullhd\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .fullhd\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .fullhd\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .fullhd\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .fullhd\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .fullhd\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .fullhd\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .fullhd\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .fullhd\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .fullhd\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .fullhd\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .fullhd\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .fullhd\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .fullhd\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .fullhd\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .fullhd\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .fullhd\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .fullhd\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .fullhd\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .fullhd\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .fullhd\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .fullhd\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .fullhd\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .fullhd\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .fullhd\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .fullhd\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .fullhd\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .fullhd\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .fullhd\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .fullhd\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .fullhd\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .fullhd\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .fullhd\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .fullhd\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .fullhd\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .fullhd\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .fullhd\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .fullhd\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .fullhd\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .fullhd\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .fullhd\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .fullhd\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .fullhd\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .fullhd\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .fullhd\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .fullhd\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .fullhd\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .fullhd\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .fullhd\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .fullhd\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .fullhd\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .fullhd\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .fullhd\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .fullhd\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .fullhd\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .fullhd\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .fullhd\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .fullhd\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .fullhd\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .fullhd\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .fullhd\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .fullhd\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .fullhd\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .fullhd\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .fullhd\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .fullhd\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .fullhd\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .fullhd\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .fullhd\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .fullhd\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .fullhd\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .fullhd\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .fullhd\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .fullhd\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .fullhd\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .fullhd\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .fullhd\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .fullhd\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .fullhd\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .fullhd\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .fullhd\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .fullhd\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .fullhd\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .fullhd\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .fullhd\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .fullhd\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .fullhd\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .fullhd\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .fullhd\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .fullhd\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .fullhd\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .fullhd\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .fullhd\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .fullhd\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .fullhd\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .fullhd\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .fullhd\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .fullhd\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .fullhd\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .fullhd\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .fullhd\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .fullhd\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .fullhd\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .fullhd\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .fullhd\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .fullhd\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .fullhd\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .fullhd\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .fullhd\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .fullhd\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .fullhd\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .fullhd\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .fullhd\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .fullhd\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .fullhd\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .fullhd\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .fullhd\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .fullhd\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .fullhd\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .fullhd\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .fullhd\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .fullhd\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .fullhd\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .fullhd\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .fullhd\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .fullhd\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .fullhd\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .fullhd\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .fullhd\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .fullhd\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .fullhd\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .fullhd\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .fullhd\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .fullhd\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .fullhd\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .fullhd\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .fullhd\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .fullhd\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .fullhd\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .fullhd\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .fullhd\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .fullhd\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .fullhd\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .fullhd\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .fullhd\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .fullhd\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .fullhd\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .fullhd\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .fullhd\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .fullhd\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .fullhd\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .fullhd\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .fullhd\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .fullhd\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .fullhd\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .fullhd\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .fullhd\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .fullhd\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .fullhd\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .fullhd\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .fullhd\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .fullhd\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .fullhd\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .fullhd\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .fullhd\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .fullhd\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .fullhd\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .fullhd\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .fullhd\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .fullhd\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .fullhd\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .fullhd\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .fullhd\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .fullhd\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .fullhd\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .fullhd\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .fullhd\:transition-none {
    transition-property: none;
  }

  .fullhd\:transition-all {
    transition-property: all;
  }

  .fullhd\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .fullhd\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .fullhd\:transition-opacity {
    transition-property: opacity;
  }

  .fullhd\:transition-shadow {
    transition-property: box-shadow;
  }

  .fullhd\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .fullhd\:ease-linear {
    transition-timing-function: linear;
  }

  .fullhd\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .fullhd\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .fullhd\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .fullhd\:duration-75 {
    transition-duration: 75ms;
  }

  .fullhd\:duration-100 {
    transition-duration: 100ms;
  }

  .fullhd\:duration-150 {
    transition-duration: 150ms;
  }

  .fullhd\:duration-200 {
    transition-duration: 200ms;
  }

  .fullhd\:duration-300 {
    transition-duration: 300ms;
  }

  .fullhd\:duration-500 {
    transition-duration: 500ms;
  }

  .fullhd\:duration-700 {
    transition-duration: 700ms;
  }

  .fullhd\:duration-1000 {
    transition-duration: 1000ms;
  }

  .fullhd\:delay-75 {
    transition-delay: 75ms;
  }

  .fullhd\:delay-100 {
    transition-delay: 100ms;
  }

  .fullhd\:delay-150 {
    transition-delay: 150ms;
  }

  .fullhd\:delay-200 {
    transition-delay: 200ms;
  }

  .fullhd\:delay-300 {
    transition-delay: 300ms;
  }

  .fullhd\:delay-500 {
    transition-delay: 500ms;
  }

  .fullhd\:delay-700 {
    transition-delay: 700ms;
  }

  .fullhd\:delay-1000 {
    transition-delay: 1000ms;
  }

  .fullhd\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .fullhd\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .fullhd\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .fullhd\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .fullhd\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}

@media print {
  .print\:container {
    width: 100%;
  }

  @media (min-width: 801px) {
    .print\:container {
      max-width: 801px;
    }
  }

  @media (min-width: 1024px) {
    .print\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1216px) {
    .print\:container {
      max-width: 1216px;
    }
  }

  @media (min-width: 1408px) {
    .print\:container {
      max-width: 1408px;
    }
  }

  .print\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5px * var(--space-y-reverse));
  }

  .print\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5px * var(--space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .print\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(15px * var(--space-y-reverse));
  }

  .print\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15px * var(--space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .print\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(50px * var(--space-y-reverse));
  }

  .print\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50px * var(--space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(75px * var(--space-y-reverse));
  }

  .print\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75px * var(--space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(100px * var(--space-y-reverse));
  }

  .print\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100px * var(--space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .print\:space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10px * var(--space-y-reverse));
  }

  .print\:space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10px * var(--space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(20px * var(--space-y-reverse));
  }

  .print\:space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20px * var(--space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(30px * var(--space-y-reverse));
  }

  .print\:space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(30px * var(--space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(40px * var(--space-y-reverse));
  }

  .print\:space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40px * var(--space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(60px * var(--space-y-reverse));
  }

  .print\:space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60px * var(--space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(26px * var(--space-y-reverse));
  }

  .print\:space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(26px * var(--space-x-reverse));
    margin-left: calc(26px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(80px * var(--space-y-reverse));
  }

  .print\:space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80px * var(--space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5px * var(--space-y-reverse));
  }

  .print\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5px * var(--space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .print\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-15px * var(--space-y-reverse));
  }

  .print\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15px * var(--space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .print\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-50px * var(--space-y-reverse));
  }

  .print\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50px * var(--space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-75px * var(--space-y-reverse));
  }

  .print\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75px * var(--space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-100px * var(--space-y-reverse));
  }

  .print\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100px * var(--space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-none > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .print\:-space-x-none > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-tiny > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10px * var(--space-y-reverse));
  }

  .print\:-space-x-tiny > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10px * var(--space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-small > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-20px * var(--space-y-reverse));
  }

  .print\:-space-x-small > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20px * var(--space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-medium > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-30px * var(--space-y-reverse));
  }

  .print\:-space-x-medium > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-30px * var(--space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-large > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-40px * var(--space-y-reverse));
  }

  .print\:-space-x-large > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40px * var(--space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-huge > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-60px * var(--space-y-reverse));
  }

  .print\:-space-x-huge > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60px * var(--space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-margin-label-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-26px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-26px * var(--space-y-reverse));
  }

  .print\:-space-x-margin-label-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-26px * var(--space-x-reverse));
    margin-left: calc(-26px * calc(1 - var(--space-x-reverse)));
  }

  .print\:-space-y-nav-height > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-80px * var(--space-y-reverse));
  }

  .print\:-space-x-nav-height > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80px * var(--space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--space-x-reverse)));
  }

  .print\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .print\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .print\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .print\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .print\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .print\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .print\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .print\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .print\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .print\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .print\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .print\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .print\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .print\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .print\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .print\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--divide-opacity));
  }

  .print\:divide-black-backdrop > :not(template) ~ :not(template) {
    border-color: #2c2c2c99;
  }

  .print\:divide-beige > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--divide-opacity));
  }

  .print\:divide-beige-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--divide-opacity));
  }

  .print\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--divide-opacity));
  }

  .print\:divide-blue-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--divide-opacity));
  }

  .print\:divide-blue-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--divide-opacity));
  }

  .print\:divide-blue-hover > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--divide-opacity));
  }

  .print\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--divide-opacity));
  }

  .print\:divide-green-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--divide-opacity));
  }

  .print\:divide-green-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--divide-opacity));
  }

  .print\:divide-grey-10 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--divide-opacity));
  }

  .print\:divide-grey-20 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--divide-opacity));
  }

  .print\:divide-grey-30 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--divide-opacity));
  }

  .print\:divide-grey-40 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--divide-opacity));
  }

  .print\:divide-grey-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--divide-opacity));
  }

  .print\:divide-grey-60 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--divide-opacity));
  }

  .print\:divide-grey-70 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--divide-opacity));
  }

  .print\:divide-grey-80 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--divide-opacity));
  }

  .print\:divide-grey-90 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--divide-opacity));
  }

  .print\:divide-grey > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--divide-opacity));
  }

  .print\:divide-grey-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--divide-opacity));
  }

  .print\:divide-grey-dark > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--divide-opacity));
  }

  .print\:divide-purple-visited > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--divide-opacity));
  }

  .print\:divide-purple-visited-light > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--divide-opacity));
  }

  .print\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--divide-opacity));
  }

  .print\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--divide-opacity));
  }

  .print\:divide-state-danger > :not(template) ~ :not(template) {
    border-color: #FF8274B3;
  }

  .print\:divide-state-warning > :not(template) ~ :not(template) {
    border-color: #F9C66BB3;
  }

  .print\:divide-state-success > :not(template) ~ :not(template) {
    border-color: #C7F6C9B3;
  }

  .print\:divide-state-primary > :not(template) ~ :not(template) {
    border-color: #B3F5FFB3;
  }

  .print\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .print\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .print\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .print\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .print\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .print\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .print\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .print\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .print\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .print\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .print\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .print\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .print\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .print\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .print\:appearance-none {
    -webkit-appearance: none;
            appearance: none;
  }

  .print\:bg-fixed {
    background-attachment: fixed;
  }

  .print\:bg-local {
    background-attachment: local;
  }

  .print\:bg-scroll {
    background-attachment: scroll;
  }

  .print\:bg-clip-border {
    background-clip: border-box;
  }

  .print\:bg-clip-padding {
    background-clip: padding-box;
  }

  .print\:bg-clip-content {
    background-clip: content-box;
  }

  .print\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .print\:bg-white {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .print\:bg-black {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .print\:bg-black-backdrop {
    background-color: #2c2c2c99;
  }

  .print\:bg-beige {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .print\:bg-beige-dark {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .print\:bg-blue {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .print\:bg-blue-dark {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .print\:bg-blue-light {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .print\:bg-blue-hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .print\:bg-green {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .print\:bg-green-light {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .print\:bg-green-dark {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .print\:bg-grey-10 {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .print\:bg-grey-20 {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .print\:bg-grey-30 {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .print\:bg-grey-40 {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .print\:bg-grey-50 {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .print\:bg-grey-60 {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .print\:bg-grey-70 {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .print\:bg-grey-80 {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .print\:bg-grey-90 {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .print\:bg-grey {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .print\:bg-grey-light {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .print\:bg-grey-dark {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .print\:bg-purple-visited {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .print\:bg-purple-visited-light {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .print\:bg-red {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .print\:bg-yellow {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .print\:bg-state-danger {
    background-color: #FF8274B3;
  }

  .print\:bg-state-warning {
    background-color: #F9C66BB3;
  }

  .print\:bg-state-success {
    background-color: #C7F6C9B3;
  }

  .print\:bg-state-primary {
    background-color: #B3F5FFB3;
  }

  .print\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .print\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .print\:hover\:bg-black-backdrop:hover {
    background-color: #2c2c2c99;
  }

  .print\:hover\:bg-beige:hover {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .print\:hover\:bg-beige-dark:hover {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .print\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .print\:hover\:bg-blue-dark:hover {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .print\:hover\:bg-blue-light:hover {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .print\:hover\:bg-blue-hover:hover {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .print\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .print\:hover\:bg-green-light:hover {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .print\:hover\:bg-green-dark:hover {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-10:hover {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-20:hover {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-30:hover {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-40:hover {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-50:hover {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-60:hover {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-70:hover {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-80:hover {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-90:hover {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .print\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-light:hover {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .print\:hover\:bg-grey-dark:hover {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .print\:hover\:bg-purple-visited:hover {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .print\:hover\:bg-purple-visited-light:hover {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .print\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .print\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .print\:hover\:bg-state-danger:hover {
    background-color: #FF8274B3;
  }

  .print\:hover\:bg-state-warning:hover {
    background-color: #F9C66BB3;
  }

  .print\:hover\:bg-state-success:hover {
    background-color: #C7F6C9B3;
  }

  .print\:hover\:bg-state-primary:hover {
    background-color: #B3F5FFB3;
  }

  .print\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .print\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .print\:focus\:bg-black-backdrop:focus {
    background-color: #2c2c2c99;
  }

  .print\:focus\:bg-beige:focus {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .print\:focus\:bg-beige-dark:focus {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .print\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .print\:focus\:bg-blue-dark:focus {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .print\:focus\:bg-blue-light:focus {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .print\:focus\:bg-blue-hover:focus {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .print\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .print\:focus\:bg-green-light:focus {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .print\:focus\:bg-green-dark:focus {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-10:focus {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-20:focus {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-30:focus {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-40:focus {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-50:focus {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-60:focus {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-70:focus {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-80:focus {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-90:focus {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .print\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-light:focus {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .print\:focus\:bg-grey-dark:focus {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .print\:focus\:bg-purple-visited:focus {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .print\:focus\:bg-purple-visited-light:focus {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .print\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .print\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .print\:focus\:bg-state-danger:focus {
    background-color: #FF8274B3;
  }

  .print\:focus\:bg-state-warning:focus {
    background-color: #F9C66BB3;
  }

  .print\:focus\:bg-state-success:focus {
    background-color: #C7F6C9B3;
  }

  .print\:focus\:bg-state-primary:focus {
    background-color: #B3F5FFB3;
  }

  .print\:odd\:bg-white:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .print\:odd\:bg-black:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .print\:odd\:bg-black-backdrop:nth-child(odd) {
    background-color: #2c2c2c99;
  }

  .print\:odd\:bg-beige:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .print\:odd\:bg-beige-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .print\:odd\:bg-blue:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .print\:odd\:bg-blue-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .print\:odd\:bg-blue-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .print\:odd\:bg-blue-hover:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .print\:odd\:bg-green:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .print\:odd\:bg-green-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .print\:odd\:bg-green-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-10:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-20:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-30:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-40:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-50:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-60:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-70:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-80:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-90:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .print\:odd\:bg-grey:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .print\:odd\:bg-grey-dark:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .print\:odd\:bg-purple-visited:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .print\:odd\:bg-purple-visited-light:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .print\:odd\:bg-red:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .print\:odd\:bg-yellow:nth-child(odd) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .print\:odd\:bg-state-danger:nth-child(odd) {
    background-color: #FF8274B3;
  }

  .print\:odd\:bg-state-warning:nth-child(odd) {
    background-color: #F9C66BB3;
  }

  .print\:odd\:bg-state-success:nth-child(odd) {
    background-color: #C7F6C9B3;
  }

  .print\:odd\:bg-state-primary:nth-child(odd) {
    background-color: #B3F5FFB3;
  }

  .print\:even\:bg-white:nth-child(even) {
    --bg-opacity: 1;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .print\:even\:bg-black:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2c2c2c;
    background-color: rgba(44, 44, 44, var(--bg-opacity));
  }

  .print\:even\:bg-black-backdrop:nth-child(even) {
    background-color: #2c2c2c99;
  }

  .print\:even\:bg-beige:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f8f0dd;
    background-color: rgba(248, 240, 221, var(--bg-opacity));
  }

  .print\:even\:bg-beige-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #d0bfae;
    background-color: rgba(208, 191, 174, var(--bg-opacity));
  }

  .print\:even\:bg-blue:nth-child(even) {
    --bg-opacity: 1;
    background-color: #6fe9ff;
    background-color: rgba(111, 233, 255, var(--bg-opacity));
  }

  .print\:even\:bg-blue-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #2a2859;
    background-color: rgba(42, 40, 89, var(--bg-opacity));
  }

  .print\:even\:bg-blue-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3f5ff;
    background-color: rgba(179, 245, 255, var(--bg-opacity));
  }

  .print\:even\:bg-blue-hover:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1f42aa;
    background-color: rgba(31, 66, 170, var(--bg-opacity));
  }

  .print\:even\:bg-green:nth-child(even) {
    --bg-opacity: 1;
    background-color: #43f8b6;
    background-color: rgba(67, 248, 182, var(--bg-opacity));
  }

  .print\:even\:bg-green-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #c7f6c9;
    background-color: rgba(199, 246, 201, var(--bg-opacity));
  }

  .print\:even\:bg-green-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #034b45;
    background-color: rgba(3, 75, 69, var(--bg-opacity));
  }

  .print\:even\:bg-grey-10:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e6e6e6;
    background-color: rgba(230, 230, 230, var(--bg-opacity));
  }

  .print\:even\:bg-grey-20:nth-child(even) {
    --bg-opacity: 1;
    background-color: #cccccc;
    background-color: rgba(204, 204, 204, var(--bg-opacity));
  }

  .print\:even\:bg-grey-30:nth-child(even) {
    --bg-opacity: 1;
    background-color: #b3b3b3;
    background-color: rgba(179, 179, 179, var(--bg-opacity));
  }

  .print\:even\:bg-grey-40:nth-child(even) {
    --bg-opacity: 1;
    background-color: #999999;
    background-color: rgba(153, 153, 153, var(--bg-opacity));
  }

  .print\:even\:bg-grey-50:nth-child(even) {
    --bg-opacity: 1;
    background-color: #808080;
    background-color: rgba(128, 128, 128, var(--bg-opacity));
  }

  .print\:even\:bg-grey-60:nth-child(even) {
    --bg-opacity: 1;
    background-color: #666666;
    background-color: rgba(102, 102, 102, var(--bg-opacity));
  }

  .print\:even\:bg-grey-70:nth-child(even) {
    --bg-opacity: 1;
    background-color: #4d4d4d;
    background-color: rgba(77, 77, 77, var(--bg-opacity));
  }

  .print\:even\:bg-grey-80:nth-child(even) {
    --bg-opacity: 1;
    background-color: #333333;
    background-color: rgba(51, 51, 51, var(--bg-opacity));
  }

  .print\:even\:bg-grey-90:nth-child(even) {
    --bg-opacity: 1;
    background-color: #1a1a1a;
    background-color: rgba(26, 26, 26, var(--bg-opacity));
  }

  .print\:even\:bg-grey:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f2f2f2;
    background-color: rgba(242, 242, 242, var(--bg-opacity));
  }

  .print\:even\:bg-grey-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9f9f9;
    background-color: rgba(249, 249, 249, var(--bg-opacity));
  }

  .print\:even\:bg-grey-dark:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e1e1e1;
    background-color: rgba(225, 225, 225, var(--bg-opacity));
  }

  .print\:even\:bg-purple-visited:nth-child(even) {
    --bg-opacity: 1;
    background-color: #8d50b1;
    background-color: rgba(141, 80, 177, var(--bg-opacity));
  }

  .print\:even\:bg-purple-visited-light:nth-child(even) {
    --bg-opacity: 1;
    background-color: #e0adff;
    background-color: rgba(224, 173, 255, var(--bg-opacity));
  }

  .print\:even\:bg-red:nth-child(even) {
    --bg-opacity: 1;
    background-color: #ff8274;
    background-color: rgba(255, 130, 116, var(--bg-opacity));
  }

  .print\:even\:bg-yellow:nth-child(even) {
    --bg-opacity: 1;
    background-color: #f9c66b;
    background-color: rgba(249, 198, 107, var(--bg-opacity));
  }

  .print\:even\:bg-state-danger:nth-child(even) {
    background-color: #FF8274B3;
  }

  .print\:even\:bg-state-warning:nth-child(even) {
    background-color: #F9C66BB3;
  }

  .print\:even\:bg-state-success:nth-child(even) {
    background-color: #C7F6C9B3;
  }

  .print\:even\:bg-state-primary:nth-child(even) {
    background-color: #B3F5FFB3;
  }

  .print\:bg-none {
    background-image: none;
  }

  .print\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .print\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .print\:from-white {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:from-black {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:from-black-backdrop {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:from-beige {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:from-beige-dark {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:from-blue {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:from-blue-dark {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:from-blue-light {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:from-blue-hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:from-green {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:from-green-light {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:from-green-dark {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:from-grey-10 {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:from-grey-20 {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:from-grey-30 {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:from-grey-40 {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:from-grey-50 {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:from-grey-60 {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:from-grey-70 {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:from-grey-80 {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:from-grey-90 {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:from-grey {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:from-grey-light {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:from-grey-dark {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:from-purple-visited {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:from-purple-visited-light {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:from-red {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:from-yellow {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:from-state-danger {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:from-state-warning {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:from-state-success {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:from-state-primary {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:via-white {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:via-black {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:via-black-backdrop {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:via-beige {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:via-beige-dark {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:via-blue {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:via-blue-dark {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:via-blue-light {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:via-blue-hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:via-green {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:via-green-light {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:via-green-dark {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:via-grey-10 {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:via-grey-20 {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:via-grey-30 {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:via-grey-40 {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:via-grey-50 {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:via-grey-60 {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:via-grey-70 {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:via-grey-80 {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:via-grey-90 {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:via-grey {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:via-grey-light {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:via-grey-dark {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:via-purple-visited {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:via-purple-visited-light {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:via-red {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:via-yellow {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:via-state-danger {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:via-state-warning {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:via-state-success {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:via-state-primary {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:to-white {
    --gradient-to-color: #FFFFFF;
  }

  .print\:to-black {
    --gradient-to-color: #2c2c2c;
  }

  .print\:to-black-backdrop {
    --gradient-to-color: #2c2c2c99;
  }

  .print\:to-beige {
    --gradient-to-color: #f8f0dd;
  }

  .print\:to-beige-dark {
    --gradient-to-color: #d0bfae;
  }

  .print\:to-blue {
    --gradient-to-color: #6fe9ff;
  }

  .print\:to-blue-dark {
    --gradient-to-color: #2a2859;
  }

  .print\:to-blue-light {
    --gradient-to-color: #b3f5ff;
  }

  .print\:to-blue-hover {
    --gradient-to-color: #1f42aa;
  }

  .print\:to-green {
    --gradient-to-color: #43f8b6;
  }

  .print\:to-green-light {
    --gradient-to-color: #c7f6c9;
  }

  .print\:to-green-dark {
    --gradient-to-color: #034b45;
  }

  .print\:to-grey-10 {
    --gradient-to-color: #e6e6e6;
  }

  .print\:to-grey-20 {
    --gradient-to-color: #cccccc;
  }

  .print\:to-grey-30 {
    --gradient-to-color: #b3b3b3;
  }

  .print\:to-grey-40 {
    --gradient-to-color: #999999;
  }

  .print\:to-grey-50 {
    --gradient-to-color: #808080;
  }

  .print\:to-grey-60 {
    --gradient-to-color: #666666;
  }

  .print\:to-grey-70 {
    --gradient-to-color: #4d4d4d;
  }

  .print\:to-grey-80 {
    --gradient-to-color: #333333;
  }

  .print\:to-grey-90 {
    --gradient-to-color: #1a1a1a;
  }

  .print\:to-grey {
    --gradient-to-color: #f2f2f2;
  }

  .print\:to-grey-light {
    --gradient-to-color: #f9f9f9;
  }

  .print\:to-grey-dark {
    --gradient-to-color: #e1e1e1;
  }

  .print\:to-purple-visited {
    --gradient-to-color: #8d50b1;
  }

  .print\:to-purple-visited-light {
    --gradient-to-color: #e0adff;
  }

  .print\:to-red {
    --gradient-to-color: #ff8274;
  }

  .print\:to-yellow {
    --gradient-to-color: #f9c66b;
  }

  .print\:to-state-danger {
    --gradient-to-color: #FF8274B3;
  }

  .print\:to-state-warning {
    --gradient-to-color: #F9C66BB3;
  }

  .print\:to-state-success {
    --gradient-to-color: #C7F6C9B3;
  }

  .print\:to-state-primary {
    --gradient-to-color: #B3F5FFB3;
  }

  .print\:hover\:from-white:hover {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:hover\:from-black:hover {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:hover\:from-black-backdrop:hover {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:hover\:from-beige:hover {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:hover\:from-beige-dark:hover {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:hover\:from-blue:hover {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:hover\:from-blue-dark:hover {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:hover\:from-blue-light:hover {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:hover\:from-blue-hover:hover {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:hover\:from-green:hover {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:hover\:from-green-light:hover {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:hover\:from-green-dark:hover {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:hover\:from-grey-10:hover {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:hover\:from-grey-20:hover {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:hover\:from-grey-30:hover {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:hover\:from-grey-40:hover {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:hover\:from-grey-50:hover {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:hover\:from-grey-60:hover {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:hover\:from-grey-70:hover {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:hover\:from-grey-80:hover {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:hover\:from-grey-90:hover {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:hover\:from-grey:hover {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:hover\:from-grey-light:hover {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:hover\:from-grey-dark:hover {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:hover\:from-purple-visited:hover {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:hover\:from-purple-visited-light:hover {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:hover\:from-red:hover {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:hover\:from-yellow:hover {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:hover\:from-state-danger:hover {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:hover\:from-state-warning:hover {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:hover\:from-state-success:hover {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:hover\:from-state-primary:hover {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:hover\:via-white:hover {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:hover\:via-black:hover {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:hover\:via-black-backdrop:hover {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:hover\:via-beige:hover {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:hover\:via-beige-dark:hover {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:hover\:via-blue:hover {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:hover\:via-blue-dark:hover {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:hover\:via-blue-light:hover {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:hover\:via-blue-hover:hover {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:hover\:via-green:hover {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:hover\:via-green-light:hover {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:hover\:via-green-dark:hover {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:hover\:via-grey-10:hover {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:hover\:via-grey-20:hover {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:hover\:via-grey-30:hover {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:hover\:via-grey-40:hover {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:hover\:via-grey-50:hover {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:hover\:via-grey-60:hover {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:hover\:via-grey-70:hover {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:hover\:via-grey-80:hover {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:hover\:via-grey-90:hover {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:hover\:via-grey:hover {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:hover\:via-grey-light:hover {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:hover\:via-grey-dark:hover {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:hover\:via-purple-visited:hover {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:hover\:via-purple-visited-light:hover {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:hover\:via-red:hover {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:hover\:via-yellow:hover {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:hover\:via-state-danger:hover {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:hover\:via-state-warning:hover {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:hover\:via-state-success:hover {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:hover\:via-state-primary:hover {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:hover\:to-white:hover {
    --gradient-to-color: #FFFFFF;
  }

  .print\:hover\:to-black:hover {
    --gradient-to-color: #2c2c2c;
  }

  .print\:hover\:to-black-backdrop:hover {
    --gradient-to-color: #2c2c2c99;
  }

  .print\:hover\:to-beige:hover {
    --gradient-to-color: #f8f0dd;
  }

  .print\:hover\:to-beige-dark:hover {
    --gradient-to-color: #d0bfae;
  }

  .print\:hover\:to-blue:hover {
    --gradient-to-color: #6fe9ff;
  }

  .print\:hover\:to-blue-dark:hover {
    --gradient-to-color: #2a2859;
  }

  .print\:hover\:to-blue-light:hover {
    --gradient-to-color: #b3f5ff;
  }

  .print\:hover\:to-blue-hover:hover {
    --gradient-to-color: #1f42aa;
  }

  .print\:hover\:to-green:hover {
    --gradient-to-color: #43f8b6;
  }

  .print\:hover\:to-green-light:hover {
    --gradient-to-color: #c7f6c9;
  }

  .print\:hover\:to-green-dark:hover {
    --gradient-to-color: #034b45;
  }

  .print\:hover\:to-grey-10:hover {
    --gradient-to-color: #e6e6e6;
  }

  .print\:hover\:to-grey-20:hover {
    --gradient-to-color: #cccccc;
  }

  .print\:hover\:to-grey-30:hover {
    --gradient-to-color: #b3b3b3;
  }

  .print\:hover\:to-grey-40:hover {
    --gradient-to-color: #999999;
  }

  .print\:hover\:to-grey-50:hover {
    --gradient-to-color: #808080;
  }

  .print\:hover\:to-grey-60:hover {
    --gradient-to-color: #666666;
  }

  .print\:hover\:to-grey-70:hover {
    --gradient-to-color: #4d4d4d;
  }

  .print\:hover\:to-grey-80:hover {
    --gradient-to-color: #333333;
  }

  .print\:hover\:to-grey-90:hover {
    --gradient-to-color: #1a1a1a;
  }

  .print\:hover\:to-grey:hover {
    --gradient-to-color: #f2f2f2;
  }

  .print\:hover\:to-grey-light:hover {
    --gradient-to-color: #f9f9f9;
  }

  .print\:hover\:to-grey-dark:hover {
    --gradient-to-color: #e1e1e1;
  }

  .print\:hover\:to-purple-visited:hover {
    --gradient-to-color: #8d50b1;
  }

  .print\:hover\:to-purple-visited-light:hover {
    --gradient-to-color: #e0adff;
  }

  .print\:hover\:to-red:hover {
    --gradient-to-color: #ff8274;
  }

  .print\:hover\:to-yellow:hover {
    --gradient-to-color: #f9c66b;
  }

  .print\:hover\:to-state-danger:hover {
    --gradient-to-color: #FF8274B3;
  }

  .print\:hover\:to-state-warning:hover {
    --gradient-to-color: #F9C66BB3;
  }

  .print\:hover\:to-state-success:hover {
    --gradient-to-color: #C7F6C9B3;
  }

  .print\:hover\:to-state-primary:hover {
    --gradient-to-color: #B3F5FFB3;
  }

  .print\:focus\:from-white:focus {
    --gradient-from-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:focus\:from-black:focus {
    --gradient-from-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:focus\:from-black-backdrop:focus {
    --gradient-from-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:focus\:from-beige:focus {
    --gradient-from-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:focus\:from-beige-dark:focus {
    --gradient-from-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:focus\:from-blue:focus {
    --gradient-from-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:focus\:from-blue-dark:focus {
    --gradient-from-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:focus\:from-blue-light:focus {
    --gradient-from-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:focus\:from-blue-hover:focus {
    --gradient-from-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:focus\:from-green:focus {
    --gradient-from-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:focus\:from-green-light:focus {
    --gradient-from-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:focus\:from-green-dark:focus {
    --gradient-from-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:focus\:from-grey-10:focus {
    --gradient-from-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:focus\:from-grey-20:focus {
    --gradient-from-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:focus\:from-grey-30:focus {
    --gradient-from-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:focus\:from-grey-40:focus {
    --gradient-from-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:focus\:from-grey-50:focus {
    --gradient-from-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:focus\:from-grey-60:focus {
    --gradient-from-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:focus\:from-grey-70:focus {
    --gradient-from-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:focus\:from-grey-80:focus {
    --gradient-from-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:focus\:from-grey-90:focus {
    --gradient-from-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:focus\:from-grey:focus {
    --gradient-from-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:focus\:from-grey-light:focus {
    --gradient-from-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:focus\:from-grey-dark:focus {
    --gradient-from-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:focus\:from-purple-visited:focus {
    --gradient-from-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:focus\:from-purple-visited-light:focus {
    --gradient-from-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:focus\:from-red:focus {
    --gradient-from-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:focus\:from-yellow:focus {
    --gradient-from-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:focus\:from-state-danger:focus {
    --gradient-from-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:focus\:from-state-warning:focus {
    --gradient-from-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:focus\:from-state-success:focus {
    --gradient-from-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:focus\:from-state-primary:focus {
    --gradient-from-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:focus\:via-white:focus {
    --gradient-via-color: #FFFFFF;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .print\:focus\:via-black:focus {
    --gradient-via-color: #2c2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:focus\:via-black-backdrop:focus {
    --gradient-via-color: #2c2c2c99;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 44, 44, 0));
  }

  .print\:focus\:via-beige:focus {
    --gradient-via-color: #f8f0dd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(248, 240, 221, 0));
  }

  .print\:focus\:via-beige-dark:focus {
    --gradient-via-color: #d0bfae;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(208, 191, 174, 0));
  }

  .print\:focus\:via-blue:focus {
    --gradient-via-color: #6fe9ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(111, 233, 255, 0));
  }

  .print\:focus\:via-blue-dark:focus {
    --gradient-via-color: #2a2859;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 40, 89, 0));
  }

  .print\:focus\:via-blue-light:focus {
    --gradient-via-color: #b3f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:focus\:via-blue-hover:focus {
    --gradient-via-color: #1f42aa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(31, 66, 170, 0));
  }

  .print\:focus\:via-green:focus {
    --gradient-via-color: #43f8b6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 248, 182, 0));
  }

  .print\:focus\:via-green-light:focus {
    --gradient-via-color: #c7f6c9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:focus\:via-green-dark:focus {
    --gradient-via-color: #034b45;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(3, 75, 69, 0));
  }

  .print\:focus\:via-grey-10:focus {
    --gradient-via-color: #e6e6e6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 230, 230, 0));
  }

  .print\:focus\:via-grey-20:focus {
    --gradient-via-color: #cccccc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(204, 204, 204, 0));
  }

  .print\:focus\:via-grey-30:focus {
    --gradient-via-color: #b3b3b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 179, 179, 0));
  }

  .print\:focus\:via-grey-40:focus {
    --gradient-via-color: #999999;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(153, 153, 153, 0));
  }

  .print\:focus\:via-grey-50:focus {
    --gradient-via-color: #808080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 128, 128, 0));
  }

  .print\:focus\:via-grey-60:focus {
    --gradient-via-color: #666666;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 102, 102, 0));
  }

  .print\:focus\:via-grey-70:focus {
    --gradient-via-color: #4d4d4d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(77, 77, 77, 0));
  }

  .print\:focus\:via-grey-80:focus {
    --gradient-via-color: #333333;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(51, 51, 51, 0));
  }

  .print\:focus\:via-grey-90:focus {
    --gradient-via-color: #1a1a1a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 26, 26, 0));
  }

  .print\:focus\:via-grey:focus {
    --gradient-via-color: #f2f2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(242, 242, 242, 0));
  }

  .print\:focus\:via-grey-light:focus {
    --gradient-via-color: #f9f9f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 249, 249, 0));
  }

  .print\:focus\:via-grey-dark:focus {
    --gradient-via-color: #e1e1e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(225, 225, 225, 0));
  }

  .print\:focus\:via-purple-visited:focus {
    --gradient-via-color: #8d50b1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(141, 80, 177, 0));
  }

  .print\:focus\:via-purple-visited-light:focus {
    --gradient-via-color: #e0adff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 173, 255, 0));
  }

  .print\:focus\:via-red:focus {
    --gradient-via-color: #ff8274;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:focus\:via-yellow:focus {
    --gradient-via-color: #f9c66b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:focus\:via-state-danger:focus {
    --gradient-via-color: #FF8274B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 130, 116, 0));
  }

  .print\:focus\:via-state-warning:focus {
    --gradient-via-color: #F9C66BB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(249, 198, 107, 0));
  }

  .print\:focus\:via-state-success:focus {
    --gradient-via-color: #C7F6C9B3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(199, 246, 201, 0));
  }

  .print\:focus\:via-state-primary:focus {
    --gradient-via-color: #B3F5FFB3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(179, 245, 255, 0));
  }

  .print\:focus\:to-white:focus {
    --gradient-to-color: #FFFFFF;
  }

  .print\:focus\:to-black:focus {
    --gradient-to-color: #2c2c2c;
  }

  .print\:focus\:to-black-backdrop:focus {
    --gradient-to-color: #2c2c2c99;
  }

  .print\:focus\:to-beige:focus {
    --gradient-to-color: #f8f0dd;
  }

  .print\:focus\:to-beige-dark:focus {
    --gradient-to-color: #d0bfae;
  }

  .print\:focus\:to-blue:focus {
    --gradient-to-color: #6fe9ff;
  }

  .print\:focus\:to-blue-dark:focus {
    --gradient-to-color: #2a2859;
  }

  .print\:focus\:to-blue-light:focus {
    --gradient-to-color: #b3f5ff;
  }

  .print\:focus\:to-blue-hover:focus {
    --gradient-to-color: #1f42aa;
  }

  .print\:focus\:to-green:focus {
    --gradient-to-color: #43f8b6;
  }

  .print\:focus\:to-green-light:focus {
    --gradient-to-color: #c7f6c9;
  }

  .print\:focus\:to-green-dark:focus {
    --gradient-to-color: #034b45;
  }

  .print\:focus\:to-grey-10:focus {
    --gradient-to-color: #e6e6e6;
  }

  .print\:focus\:to-grey-20:focus {
    --gradient-to-color: #cccccc;
  }

  .print\:focus\:to-grey-30:focus {
    --gradient-to-color: #b3b3b3;
  }

  .print\:focus\:to-grey-40:focus {
    --gradient-to-color: #999999;
  }

  .print\:focus\:to-grey-50:focus {
    --gradient-to-color: #808080;
  }

  .print\:focus\:to-grey-60:focus {
    --gradient-to-color: #666666;
  }

  .print\:focus\:to-grey-70:focus {
    --gradient-to-color: #4d4d4d;
  }

  .print\:focus\:to-grey-80:focus {
    --gradient-to-color: #333333;
  }

  .print\:focus\:to-grey-90:focus {
    --gradient-to-color: #1a1a1a;
  }

  .print\:focus\:to-grey:focus {
    --gradient-to-color: #f2f2f2;
  }

  .print\:focus\:to-grey-light:focus {
    --gradient-to-color: #f9f9f9;
  }

  .print\:focus\:to-grey-dark:focus {
    --gradient-to-color: #e1e1e1;
  }

  .print\:focus\:to-purple-visited:focus {
    --gradient-to-color: #8d50b1;
  }

  .print\:focus\:to-purple-visited-light:focus {
    --gradient-to-color: #e0adff;
  }

  .print\:focus\:to-red:focus {
    --gradient-to-color: #ff8274;
  }

  .print\:focus\:to-yellow:focus {
    --gradient-to-color: #f9c66b;
  }

  .print\:focus\:to-state-danger:focus {
    --gradient-to-color: #FF8274B3;
  }

  .print\:focus\:to-state-warning:focus {
    --gradient-to-color: #F9C66BB3;
  }

  .print\:focus\:to-state-success:focus {
    --gradient-to-color: #C7F6C9B3;
  }

  .print\:focus\:to-state-primary:focus {
    --gradient-to-color: #B3F5FFB3;
  }

  .print\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .print\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .print\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .print\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .print\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .print\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .print\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .print\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .print\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .print\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .print\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .print\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .print\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .print\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .print\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .print\:bg-bottom {
    background-position: bottom;
  }

  .print\:bg-center {
    background-position: center;
  }

  .print\:bg-left {
    background-position: left;
  }

  .print\:bg-left-bottom {
    background-position: left bottom;
  }

  .print\:bg-left-top {
    background-position: left top;
  }

  .print\:bg-right {
    background-position: right;
  }

  .print\:bg-right-bottom {
    background-position: right bottom;
  }

  .print\:bg-right-top {
    background-position: right top;
  }

  .print\:bg-top {
    background-position: top;
  }

  .print\:bg-repeat {
    background-repeat: repeat;
  }

  .print\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .print\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .print\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .print\:bg-repeat-round {
    background-repeat: round;
  }

  .print\:bg-repeat-space {
    background-repeat: space;
  }

  .print\:bg-auto {
    background-size: auto;
  }

  .print\:bg-cover {
    background-size: cover;
  }

  .print\:bg-contain {
    background-size: contain;
  }

  .print\:border-collapse {
    border-collapse: collapse;
  }

  .print\:border-separate {
    border-collapse: separate;
  }

  .print\:border-white {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .print\:border-black {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .print\:border-black-backdrop {
    border-color: #2c2c2c99;
  }

  .print\:border-beige {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .print\:border-beige-dark {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .print\:border-blue {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .print\:border-blue-dark {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .print\:border-blue-light {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .print\:border-blue-hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .print\:border-green {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .print\:border-green-light {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .print\:border-green-dark {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .print\:border-grey-10 {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .print\:border-grey-20 {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .print\:border-grey-30 {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .print\:border-grey-40 {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .print\:border-grey-50 {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .print\:border-grey-60 {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .print\:border-grey-70 {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .print\:border-grey-80 {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .print\:border-grey-90 {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .print\:border-grey {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .print\:border-grey-light {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .print\:border-grey-dark {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .print\:border-purple-visited {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .print\:border-purple-visited-light {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .print\:border-red {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .print\:border-yellow {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .print\:border-state-danger {
    border-color: #FF8274B3;
  }

  .print\:border-state-warning {
    border-color: #F9C66BB3;
  }

  .print\:border-state-success {
    border-color: #C7F6C9B3;
  }

  .print\:border-state-primary {
    border-color: #B3F5FFB3;
  }

  .print\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .print\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .print\:hover\:border-black-backdrop:hover {
    border-color: #2c2c2c99;
  }

  .print\:hover\:border-beige:hover {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .print\:hover\:border-beige-dark:hover {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .print\:hover\:border-blue:hover {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .print\:hover\:border-blue-dark:hover {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .print\:hover\:border-blue-light:hover {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .print\:hover\:border-blue-hover:hover {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .print\:hover\:border-green:hover {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .print\:hover\:border-green-light:hover {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .print\:hover\:border-green-dark:hover {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .print\:hover\:border-grey-10:hover {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .print\:hover\:border-grey-20:hover {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .print\:hover\:border-grey-30:hover {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .print\:hover\:border-grey-40:hover {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .print\:hover\:border-grey-50:hover {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .print\:hover\:border-grey-60:hover {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .print\:hover\:border-grey-70:hover {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .print\:hover\:border-grey-80:hover {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .print\:hover\:border-grey-90:hover {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .print\:hover\:border-grey:hover {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .print\:hover\:border-grey-light:hover {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .print\:hover\:border-grey-dark:hover {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .print\:hover\:border-purple-visited:hover {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .print\:hover\:border-purple-visited-light:hover {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .print\:hover\:border-red:hover {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .print\:hover\:border-yellow:hover {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .print\:hover\:border-state-danger:hover {
    border-color: #FF8274B3;
  }

  .print\:hover\:border-state-warning:hover {
    border-color: #F9C66BB3;
  }

  .print\:hover\:border-state-success:hover {
    border-color: #C7F6C9B3;
  }

  .print\:hover\:border-state-primary:hover {
    border-color: #B3F5FFB3;
  }

  .print\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #FFFFFF;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .print\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #2c2c2c;
    border-color: rgba(44, 44, 44, var(--border-opacity));
  }

  .print\:focus\:border-black-backdrop:focus {
    border-color: #2c2c2c99;
  }

  .print\:focus\:border-beige:focus {
    --border-opacity: 1;
    border-color: #f8f0dd;
    border-color: rgba(248, 240, 221, var(--border-opacity));
  }

  .print\:focus\:border-beige-dark:focus {
    --border-opacity: 1;
    border-color: #d0bfae;
    border-color: rgba(208, 191, 174, var(--border-opacity));
  }

  .print\:focus\:border-blue:focus {
    --border-opacity: 1;
    border-color: #6fe9ff;
    border-color: rgba(111, 233, 255, var(--border-opacity));
  }

  .print\:focus\:border-blue-dark:focus {
    --border-opacity: 1;
    border-color: #2a2859;
    border-color: rgba(42, 40, 89, var(--border-opacity));
  }

  .print\:focus\:border-blue-light:focus {
    --border-opacity: 1;
    border-color: #b3f5ff;
    border-color: rgba(179, 245, 255, var(--border-opacity));
  }

  .print\:focus\:border-blue-hover:focus {
    --border-opacity: 1;
    border-color: #1f42aa;
    border-color: rgba(31, 66, 170, var(--border-opacity));
  }

  .print\:focus\:border-green:focus {
    --border-opacity: 1;
    border-color: #43f8b6;
    border-color: rgba(67, 248, 182, var(--border-opacity));
  }

  .print\:focus\:border-green-light:focus {
    --border-opacity: 1;
    border-color: #c7f6c9;
    border-color: rgba(199, 246, 201, var(--border-opacity));
  }

  .print\:focus\:border-green-dark:focus {
    --border-opacity: 1;
    border-color: #034b45;
    border-color: rgba(3, 75, 69, var(--border-opacity));
  }

  .print\:focus\:border-grey-10:focus {
    --border-opacity: 1;
    border-color: #e6e6e6;
    border-color: rgba(230, 230, 230, var(--border-opacity));
  }

  .print\:focus\:border-grey-20:focus {
    --border-opacity: 1;
    border-color: #cccccc;
    border-color: rgba(204, 204, 204, var(--border-opacity));
  }

  .print\:focus\:border-grey-30:focus {
    --border-opacity: 1;
    border-color: #b3b3b3;
    border-color: rgba(179, 179, 179, var(--border-opacity));
  }

  .print\:focus\:border-grey-40:focus {
    --border-opacity: 1;
    border-color: #999999;
    border-color: rgba(153, 153, 153, var(--border-opacity));
  }

  .print\:focus\:border-grey-50:focus {
    --border-opacity: 1;
    border-color: #808080;
    border-color: rgba(128, 128, 128, var(--border-opacity));
  }

  .print\:focus\:border-grey-60:focus {
    --border-opacity: 1;
    border-color: #666666;
    border-color: rgba(102, 102, 102, var(--border-opacity));
  }

  .print\:focus\:border-grey-70:focus {
    --border-opacity: 1;
    border-color: #4d4d4d;
    border-color: rgba(77, 77, 77, var(--border-opacity));
  }

  .print\:focus\:border-grey-80:focus {
    --border-opacity: 1;
    border-color: #333333;
    border-color: rgba(51, 51, 51, var(--border-opacity));
  }

  .print\:focus\:border-grey-90:focus {
    --border-opacity: 1;
    border-color: #1a1a1a;
    border-color: rgba(26, 26, 26, var(--border-opacity));
  }

  .print\:focus\:border-grey:focus {
    --border-opacity: 1;
    border-color: #f2f2f2;
    border-color: rgba(242, 242, 242, var(--border-opacity));
  }

  .print\:focus\:border-grey-light:focus {
    --border-opacity: 1;
    border-color: #f9f9f9;
    border-color: rgba(249, 249, 249, var(--border-opacity));
  }

  .print\:focus\:border-grey-dark:focus {
    --border-opacity: 1;
    border-color: #e1e1e1;
    border-color: rgba(225, 225, 225, var(--border-opacity));
  }

  .print\:focus\:border-purple-visited:focus {
    --border-opacity: 1;
    border-color: #8d50b1;
    border-color: rgba(141, 80, 177, var(--border-opacity));
  }

  .print\:focus\:border-purple-visited-light:focus {
    --border-opacity: 1;
    border-color: #e0adff;
    border-color: rgba(224, 173, 255, var(--border-opacity));
  }

  .print\:focus\:border-red:focus {
    --border-opacity: 1;
    border-color: #ff8274;
    border-color: rgba(255, 130, 116, var(--border-opacity));
  }

  .print\:focus\:border-yellow:focus {
    --border-opacity: 1;
    border-color: #f9c66b;
    border-color: rgba(249, 198, 107, var(--border-opacity));
  }

  .print\:focus\:border-state-danger:focus {
    border-color: #FF8274B3;
  }

  .print\:focus\:border-state-warning:focus {
    border-color: #F9C66BB3;
  }

  .print\:focus\:border-state-success:focus {
    border-color: #C7F6C9B3;
  }

  .print\:focus\:border-state-primary:focus {
    border-color: #B3F5FFB3;
  }

  .print\:border-opacity-0 {
    --border-opacity: 0;
  }

  .print\:border-opacity-25 {
    --border-opacity: 0.25;
  }

  .print\:border-opacity-50 {
    --border-opacity: 0.5;
  }

  .print\:border-opacity-75 {
    --border-opacity: 0.75;
  }

  .print\:border-opacity-100 {
    --border-opacity: 1;
  }

  .print\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .print\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .print\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .print\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .print\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .print\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .print\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .print\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .print\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .print\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .print\:rounded-none {
    border-radius: 0;
  }

  .print\:rounded-sm {
    border-radius: 0.125rem;
  }

  .print\:rounded {
    border-radius: 0.25rem;
  }

  .print\:rounded-md {
    border-radius: 0.375rem;
  }

  .print\:rounded-lg {
    border-radius: 0.5rem;
  }

  .print\:rounded-xl {
    border-radius: 0.75rem;
  }

  .print\:rounded-2xl {
    border-radius: 1rem;
  }

  .print\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .print\:rounded-full {
    border-radius: 9999px;
  }

  .print\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .print\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .print\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .print\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .print\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .print\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .print\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .print\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .print\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .print\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .print\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .print\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .print\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .print\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .print\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .print\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .print\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .print\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .print\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .print\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .print\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .print\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .print\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .print\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .print\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .print\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .print\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .print\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .print\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .print\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .print\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .print\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .print\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .print\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .print\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .print\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .print\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .print\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .print\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .print\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .print\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .print\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .print\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .print\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .print\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .print\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .print\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .print\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .print\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .print\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .print\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .print\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .print\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .print\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .print\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .print\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .print\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .print\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .print\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .print\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .print\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .print\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .print\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .print\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .print\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .print\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .print\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .print\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .print\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .print\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .print\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .print\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .print\:border-solid {
    border-style: solid;
  }

  .print\:border-dashed {
    border-style: dashed;
  }

  .print\:border-dotted {
    border-style: dotted;
  }

  .print\:border-double {
    border-style: double;
  }

  .print\:border-none {
    border-style: none;
  }

  .print\:border-0 {
    border-width: 0;
  }

  .print\:border-2 {
    border-width: 2px;
  }

  .print\:border-4 {
    border-width: 4px;
  }

  .print\:border-8 {
    border-width: 8px;
  }

  .print\:border {
    border-width: 1px;
  }

  .print\:border-t-0 {
    border-top-width: 0;
  }

  .print\:border-r-0 {
    border-right-width: 0;
  }

  .print\:border-b-0 {
    border-bottom-width: 0;
  }

  .print\:border-l-0 {
    border-left-width: 0;
  }

  .print\:border-t-2 {
    border-top-width: 2px;
  }

  .print\:border-r-2 {
    border-right-width: 2px;
  }

  .print\:border-b-2 {
    border-bottom-width: 2px;
  }

  .print\:border-l-2 {
    border-left-width: 2px;
  }

  .print\:border-t-4 {
    border-top-width: 4px;
  }

  .print\:border-r-4 {
    border-right-width: 4px;
  }

  .print\:border-b-4 {
    border-bottom-width: 4px;
  }

  .print\:border-l-4 {
    border-left-width: 4px;
  }

  .print\:border-t-8 {
    border-top-width: 8px;
  }

  .print\:border-r-8 {
    border-right-width: 8px;
  }

  .print\:border-b-8 {
    border-bottom-width: 8px;
  }

  .print\:border-l-8 {
    border-left-width: 8px;
  }

  .print\:border-t {
    border-top-width: 1px;
  }

  .print\:border-r {
    border-right-width: 1px;
  }

  .print\:border-b {
    border-bottom-width: 1px;
  }

  .print\:border-l {
    border-left-width: 1px;
  }

  .print\:hover\:border-0:hover {
    border-width: 0;
  }

  .print\:hover\:border-2:hover {
    border-width: 2px;
  }

  .print\:hover\:border-4:hover {
    border-width: 4px;
  }

  .print\:hover\:border-8:hover {
    border-width: 8px;
  }

  .print\:hover\:border:hover {
    border-width: 1px;
  }

  .print\:hover\:border-t-0:hover {
    border-top-width: 0;
  }

  .print\:hover\:border-r-0:hover {
    border-right-width: 0;
  }

  .print\:hover\:border-b-0:hover {
    border-bottom-width: 0;
  }

  .print\:hover\:border-l-0:hover {
    border-left-width: 0;
  }

  .print\:hover\:border-t-2:hover {
    border-top-width: 2px;
  }

  .print\:hover\:border-r-2:hover {
    border-right-width: 2px;
  }

  .print\:hover\:border-b-2:hover {
    border-bottom-width: 2px;
  }

  .print\:hover\:border-l-2:hover {
    border-left-width: 2px;
  }

  .print\:hover\:border-t-4:hover {
    border-top-width: 4px;
  }

  .print\:hover\:border-r-4:hover {
    border-right-width: 4px;
  }

  .print\:hover\:border-b-4:hover {
    border-bottom-width: 4px;
  }

  .print\:hover\:border-l-4:hover {
    border-left-width: 4px;
  }

  .print\:hover\:border-t-8:hover {
    border-top-width: 8px;
  }

  .print\:hover\:border-r-8:hover {
    border-right-width: 8px;
  }

  .print\:hover\:border-b-8:hover {
    border-bottom-width: 8px;
  }

  .print\:hover\:border-l-8:hover {
    border-left-width: 8px;
  }

  .print\:hover\:border-t:hover {
    border-top-width: 1px;
  }

  .print\:hover\:border-r:hover {
    border-right-width: 1px;
  }

  .print\:hover\:border-b:hover {
    border-bottom-width: 1px;
  }

  .print\:hover\:border-l:hover {
    border-left-width: 1px;
  }

  .print\:focus\:border-0:focus {
    border-width: 0;
  }

  .print\:focus\:border-2:focus {
    border-width: 2px;
  }

  .print\:focus\:border-4:focus {
    border-width: 4px;
  }

  .print\:focus\:border-8:focus {
    border-width: 8px;
  }

  .print\:focus\:border:focus {
    border-width: 1px;
  }

  .print\:focus\:border-t-0:focus {
    border-top-width: 0;
  }

  .print\:focus\:border-r-0:focus {
    border-right-width: 0;
  }

  .print\:focus\:border-b-0:focus {
    border-bottom-width: 0;
  }

  .print\:focus\:border-l-0:focus {
    border-left-width: 0;
  }

  .print\:focus\:border-t-2:focus {
    border-top-width: 2px;
  }

  .print\:focus\:border-r-2:focus {
    border-right-width: 2px;
  }

  .print\:focus\:border-b-2:focus {
    border-bottom-width: 2px;
  }

  .print\:focus\:border-l-2:focus {
    border-left-width: 2px;
  }

  .print\:focus\:border-t-4:focus {
    border-top-width: 4px;
  }

  .print\:focus\:border-r-4:focus {
    border-right-width: 4px;
  }

  .print\:focus\:border-b-4:focus {
    border-bottom-width: 4px;
  }

  .print\:focus\:border-l-4:focus {
    border-left-width: 4px;
  }

  .print\:focus\:border-t-8:focus {
    border-top-width: 8px;
  }

  .print\:focus\:border-r-8:focus {
    border-right-width: 8px;
  }

  .print\:focus\:border-b-8:focus {
    border-bottom-width: 8px;
  }

  .print\:focus\:border-l-8:focus {
    border-left-width: 8px;
  }

  .print\:focus\:border-t:focus {
    border-top-width: 1px;
  }

  .print\:focus\:border-r:focus {
    border-right-width: 1px;
  }

  .print\:focus\:border-b:focus {
    border-bottom-width: 1px;
  }

  .print\:focus\:border-l:focus {
    border-left-width: 1px;
  }

  .print\:box-border {
    box-sizing: border-box;
  }

  .print\:box-content {
    box-sizing: content-box;
  }

  .print\:cursor-auto {
    cursor: auto;
  }

  .print\:cursor-default {
    cursor: default;
  }

  .print\:cursor-pointer {
    cursor: pointer;
  }

  .print\:cursor-wait {
    cursor: wait;
  }

  .print\:cursor-text {
    cursor: text;
  }

  .print\:cursor-move {
    cursor: move;
  }

  .print\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .print\:hover\:cursor-auto:hover {
    cursor: auto;
  }

  .print\:hover\:cursor-default:hover {
    cursor: default;
  }

  .print\:hover\:cursor-pointer:hover {
    cursor: pointer;
  }

  .print\:hover\:cursor-wait:hover {
    cursor: wait;
  }

  .print\:hover\:cursor-text:hover {
    cursor: text;
  }

  .print\:hover\:cursor-move:hover {
    cursor: move;
  }

  .print\:hover\:cursor-not-allowed:hover {
    cursor: not-allowed;
  }

  .print\:block {
    display: block;
  }

  .print\:inline-block {
    display: inline-block;
  }

  .print\:inline {
    display: inline;
  }

  .print\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .print\:inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .print\:table {
    display: table;
  }

  .print\:table-caption {
    display: table-caption;
  }

  .print\:table-cell {
    display: table-cell;
  }

  .print\:table-column {
    display: table-column;
  }

  .print\:table-column-group {
    display: table-column-group;
  }

  .print\:table-footer-group {
    display: table-footer-group;
  }

  .print\:table-header-group {
    display: table-header-group;
  }

  .print\:table-row-group {
    display: table-row-group;
  }

  .print\:table-row {
    display: table-row;
  }

  .print\:flow-root {
    display: flow-root;
  }

  .print\:grid {
    display: grid;
  }

  .print\:inline-grid {
    display: inline-grid;
  }

  .print\:contents {
    display: contents;
  }

  .print\:hidden {
    display: none;
  }

  .print\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .print\:flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .print\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .print\:flex-col-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .print\:flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .print\:flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }

  .print\:flex-no-wrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .print\:place-items-auto {
    place-items: auto;
  }

  .print\:place-items-start {
    place-items: start;
  }

  .print\:place-items-end {
    place-items: end;
  }

  .print\:place-items-center {
    place-items: center;
  }

  .print\:place-items-stretch {
    place-items: stretch;
  }

  .print\:place-content-center {
    place-content: center;
  }

  .print\:place-content-start {
    place-content: start;
  }

  .print\:place-content-end {
    place-content: end;
  }

  .print\:place-content-between {
    place-content: space-between;
  }

  .print\:place-content-around {
    place-content: space-around;
  }

  .print\:place-content-evenly {
    place-content: space-evenly;
  }

  .print\:place-content-stretch {
    place-content: stretch;
  }

  .print\:place-self-auto {
    place-self: auto;
  }

  .print\:place-self-start {
    place-self: start;
  }

  .print\:place-self-end {
    place-self: end;
  }

  .print\:place-self-center {
    place-self: center;
  }

  .print\:place-self-stretch {
    place-self: stretch;
  }

  .print\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .print\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .print\:items-center {
    -webkit-align-items: center;
            align-items: center;
  }

  .print\:items-baseline {
    -webkit-align-items: baseline;
            align-items: baseline;
  }

  .print\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .print\:content-center {
    -webkit-align-content: center;
            align-content: center;
  }

  .print\:content-start {
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .print\:content-end {
    -webkit-align-content: flex-end;
            align-content: flex-end;
  }

  .print\:content-between {
    -webkit-align-content: space-between;
            align-content: space-between;
  }

  .print\:content-around {
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .print\:content-evenly {
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
  }

  .print\:self-auto {
    -webkit-align-self: auto;
            align-self: auto;
  }

  .print\:self-start {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }

  .print\:self-end {
    -webkit-align-self: flex-end;
            align-self: flex-end;
  }

  .print\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .print\:self-stretch {
    -webkit-align-self: stretch;
            align-self: stretch;
  }

  .print\:justify-items-auto {
    justify-items: auto;
  }

  .print\:justify-items-start {
    justify-items: start;
  }

  .print\:justify-items-end {
    justify-items: end;
  }

  .print\:justify-items-center {
    justify-items: center;
  }

  .print\:justify-items-stretch {
    justify-items: stretch;
  }

  .print\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .print\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .print\:justify-center {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .print\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .print\:justify-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .print\:justify-evenly {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .print\:justify-self-auto {
    justify-self: auto;
  }

  .print\:justify-self-start {
    justify-self: start;
  }

  .print\:justify-self-end {
    justify-self: end;
  }

  .print\:justify-self-center {
    justify-self: center;
  }

  .print\:justify-self-stretch {
    justify-self: stretch;
  }

  .print\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%;
  }

  .print\:flex-auto {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }

  .print\:flex-initial {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .print\:flex-none {
    -webkit-flex: none;
            flex: none;
  }

  .print\:flex-grow-0 {
    -webkit-flex-grow: 0;
            flex-grow: 0;
  }

  .print\:flex-grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .print\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }

  .print\:flex-shrink {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  .print\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .print\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .print\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .print\:order-4 {
    -webkit-order: 4;
            order: 4;
  }

  .print\:order-5 {
    -webkit-order: 5;
            order: 5;
  }

  .print\:order-6 {
    -webkit-order: 6;
            order: 6;
  }

  .print\:order-7 {
    -webkit-order: 7;
            order: 7;
  }

  .print\:order-8 {
    -webkit-order: 8;
            order: 8;
  }

  .print\:order-9 {
    -webkit-order: 9;
            order: 9;
  }

  .print\:order-10 {
    -webkit-order: 10;
            order: 10;
  }

  .print\:order-11 {
    -webkit-order: 11;
            order: 11;
  }

  .print\:order-12 {
    -webkit-order: 12;
            order: 12;
  }

  .print\:order-first {
    -webkit-order: -9999;
            order: -9999;
  }

  .print\:order-last {
    -webkit-order: 9999;
            order: 9999;
  }

  .print\:order-none {
    -webkit-order: 0;
            order: 0;
  }

  .print\:float-right {
    float: right;
  }

  .print\:float-left {
    float: left;
  }

  .print\:float-none {
    float: none;
  }

  .print\:clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .print\:clear-left {
    clear: left;
  }

  .print\:clear-right {
    clear: right;
  }

  .print\:clear-both {
    clear: both;
  }

  .print\:clear-none {
    clear: none;
  }

  .print\:font-hairline {
    font-weight: 100;
  }

  .print\:font-thin {
    font-weight: 200;
  }

  .print\:font-light {
    font-weight: 300;
  }

  .print\:font-normal {
    font-weight: 400;
  }

  .print\:font-medium {
    font-weight: 500;
  }

  .print\:font-semibold {
    font-weight: 600;
  }

  .print\:font-bold {
    font-weight: 700;
  }

  .print\:font-extrabold {
    font-weight: 800;
  }

  .print\:font-black {
    font-weight: 900;
  }

  .print\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .print\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .print\:hover\:font-light:hover {
    font-weight: 300;
  }

  .print\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .print\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .print\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .print\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .print\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .print\:hover\:font-black:hover {
    font-weight: 900;
  }

  .print\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .print\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .print\:focus\:font-light:focus {
    font-weight: 300;
  }

  .print\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .print\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .print\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .print\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .print\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .print\:focus\:font-black:focus {
    font-weight: 900;
  }

  .print\:h-1 {
    height: 5px;
  }

  .print\:h-2 {
    height: 10px;
  }

  .print\:h-3 {
    height: 15px;
  }

  .print\:h-4 {
    height: 30px;
  }

  .print\:h-5 {
    height: 50px;
  }

  .print\:h-6 {
    height: 75px;
  }

  .print\:h-7 {
    height: 100px;
  }

  .print\:h-logo-height-small {
    height: 55px;
  }

  .print\:h-logo-height-large {
    height: 65px;
  }

  .print\:leading-3 {
    line-height: .75rem;
  }

  .print\:leading-4 {
    line-height: 1rem;
  }

  .print\:leading-5 {
    line-height: 1.25rem;
  }

  .print\:leading-6 {
    line-height: 1.5rem;
  }

  .print\:leading-7 {
    line-height: 1.75rem;
  }

  .print\:leading-8 {
    line-height: 2rem;
  }

  .print\:leading-9 {
    line-height: 2.25rem;
  }

  .print\:leading-10 {
    line-height: 2.5rem;
  }

  .print\:leading-none {
    line-height: 1;
  }

  .print\:leading-tight {
    line-height: 1.25;
  }

  .print\:leading-snug {
    line-height: 1.375;
  }

  .print\:leading-normal {
    line-height: 1.5;
  }

  .print\:leading-relaxed {
    line-height: 1.625;
  }

  .print\:leading-loose {
    line-height: 2;
  }

  .print\:list-inside {
    list-style-position: inside;
  }

  .print\:list-outside {
    list-style-position: outside;
  }

  .print\:list-none {
    list-style-type: none;
  }

  .print\:list-disc {
    list-style-type: disc;
  }

  .print\:list-decimal {
    list-style-type: decimal;
  }

  .print\:m-1 {
    margin: 5px;
  }

  .print\:m-2 {
    margin: 10px;
  }

  .print\:m-3 {
    margin: 15px;
  }

  .print\:m-4 {
    margin: 30px;
  }

  .print\:m-5 {
    margin: 50px;
  }

  .print\:m-6 {
    margin: 75px;
  }

  .print\:m-7 {
    margin: 100px;
  }

  .print\:m-auto {
    margin: auto;
  }

  .print\:m-none {
    margin: 0px;
  }

  .print\:m-tiny {
    margin: 10px;
  }

  .print\:m-small {
    margin: 20px;
  }

  .print\:m-medium {
    margin: 30px;
  }

  .print\:m-large {
    margin: 40px;
  }

  .print\:m-huge {
    margin: 60px;
  }

  .print\:m-margin-label-height {
    margin: 26px;
  }

  .print\:m-nav-height {
    margin: 80px;
  }

  .print\:-m-1 {
    margin: -5px;
  }

  .print\:-m-2 {
    margin: -10px;
  }

  .print\:-m-3 {
    margin: -15px;
  }

  .print\:-m-4 {
    margin: -30px;
  }

  .print\:-m-5 {
    margin: -50px;
  }

  .print\:-m-6 {
    margin: -75px;
  }

  .print\:-m-7 {
    margin: -100px;
  }

  .print\:-m-none {
    margin: 0px;
  }

  .print\:-m-tiny {
    margin: -10px;
  }

  .print\:-m-small {
    margin: -20px;
  }

  .print\:-m-medium {
    margin: -30px;
  }

  .print\:-m-large {
    margin: -40px;
  }

  .print\:-m-huge {
    margin: -60px;
  }

  .print\:-m-margin-label-height {
    margin: -26px;
  }

  .print\:-m-nav-height {
    margin: -80px;
  }

  .print\:my-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:mx-1 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:my-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:mx-3 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:my-4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:mx-4 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:mx-5 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:my-6 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:mx-6 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:my-7 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:mx-7 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:my-tiny {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:mx-tiny {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:my-small {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:mx-small {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:my-medium {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:mx-medium {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:my-large {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:mx-large {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:my-huge {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:mx-huge {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:my-margin-label-height {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:mx-margin-label-height {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:my-nav-height {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:mx-nav-height {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:-my-1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:-mx-1 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:-my-2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:-mx-2 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:-my-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:-mx-3 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:-my-4 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:-mx-4 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:-my-5 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:-mx-5 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:-my-6 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:-mx-6 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:-my-7 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:-mx-7 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:-my-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:-mx-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:-my-tiny {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:-mx-tiny {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:-my-small {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:-mx-small {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:-my-medium {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:-mx-medium {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:-my-large {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:-mx-large {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:-my-huge {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:-mx-huge {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:-my-margin-label-height {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:-mx-margin-label-height {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:-my-nav-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:-mx-nav-height {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:mt-1 {
    margin-top: 5px;
  }

  .print\:mr-1 {
    margin-right: 5px;
  }

  .print\:mb-1 {
    margin-bottom: 5px;
  }

  .print\:ml-1 {
    margin-left: 5px;
  }

  .print\:mt-2 {
    margin-top: 10px;
  }

  .print\:mr-2 {
    margin-right: 10px;
  }

  .print\:mb-2 {
    margin-bottom: 10px;
  }

  .print\:ml-2 {
    margin-left: 10px;
  }

  .print\:mt-3 {
    margin-top: 15px;
  }

  .print\:mr-3 {
    margin-right: 15px;
  }

  .print\:mb-3 {
    margin-bottom: 15px;
  }

  .print\:ml-3 {
    margin-left: 15px;
  }

  .print\:mt-4 {
    margin-top: 30px;
  }

  .print\:mr-4 {
    margin-right: 30px;
  }

  .print\:mb-4 {
    margin-bottom: 30px;
  }

  .print\:ml-4 {
    margin-left: 30px;
  }

  .print\:mt-5 {
    margin-top: 50px;
  }

  .print\:mr-5 {
    margin-right: 50px;
  }

  .print\:mb-5 {
    margin-bottom: 50px;
  }

  .print\:ml-5 {
    margin-left: 50px;
  }

  .print\:mt-6 {
    margin-top: 75px;
  }

  .print\:mr-6 {
    margin-right: 75px;
  }

  .print\:mb-6 {
    margin-bottom: 75px;
  }

  .print\:ml-6 {
    margin-left: 75px;
  }

  .print\:mt-7 {
    margin-top: 100px;
  }

  .print\:mr-7 {
    margin-right: 100px;
  }

  .print\:mb-7 {
    margin-bottom: 100px;
  }

  .print\:ml-7 {
    margin-left: 100px;
  }

  .print\:mt-auto {
    margin-top: auto;
  }

  .print\:mr-auto {
    margin-right: auto;
  }

  .print\:mb-auto {
    margin-bottom: auto;
  }

  .print\:ml-auto {
    margin-left: auto;
  }

  .print\:mt-none {
    margin-top: 0px;
  }

  .print\:mr-none {
    margin-right: 0px;
  }

  .print\:mb-none {
    margin-bottom: 0px;
  }

  .print\:ml-none {
    margin-left: 0px;
  }

  .print\:mt-tiny {
    margin-top: 10px;
  }

  .print\:mr-tiny {
    margin-right: 10px;
  }

  .print\:mb-tiny {
    margin-bottom: 10px;
  }

  .print\:ml-tiny {
    margin-left: 10px;
  }

  .print\:mt-small {
    margin-top: 20px;
  }

  .print\:mr-small {
    margin-right: 20px;
  }

  .print\:mb-small {
    margin-bottom: 20px;
  }

  .print\:ml-small {
    margin-left: 20px;
  }

  .print\:mt-medium {
    margin-top: 30px;
  }

  .print\:mr-medium {
    margin-right: 30px;
  }

  .print\:mb-medium {
    margin-bottom: 30px;
  }

  .print\:ml-medium {
    margin-left: 30px;
  }

  .print\:mt-large {
    margin-top: 40px;
  }

  .print\:mr-large {
    margin-right: 40px;
  }

  .print\:mb-large {
    margin-bottom: 40px;
  }

  .print\:ml-large {
    margin-left: 40px;
  }

  .print\:mt-huge {
    margin-top: 60px;
  }

  .print\:mr-huge {
    margin-right: 60px;
  }

  .print\:mb-huge {
    margin-bottom: 60px;
  }

  .print\:ml-huge {
    margin-left: 60px;
  }

  .print\:mt-margin-label-height {
    margin-top: 26px;
  }

  .print\:mr-margin-label-height {
    margin-right: 26px;
  }

  .print\:mb-margin-label-height {
    margin-bottom: 26px;
  }

  .print\:ml-margin-label-height {
    margin-left: 26px;
  }

  .print\:mt-nav-height {
    margin-top: 80px;
  }

  .print\:mr-nav-height {
    margin-right: 80px;
  }

  .print\:mb-nav-height {
    margin-bottom: 80px;
  }

  .print\:ml-nav-height {
    margin-left: 80px;
  }

  .print\:-mt-1 {
    margin-top: -5px;
  }

  .print\:-mr-1 {
    margin-right: -5px;
  }

  .print\:-mb-1 {
    margin-bottom: -5px;
  }

  .print\:-ml-1 {
    margin-left: -5px;
  }

  .print\:-mt-2 {
    margin-top: -10px;
  }

  .print\:-mr-2 {
    margin-right: -10px;
  }

  .print\:-mb-2 {
    margin-bottom: -10px;
  }

  .print\:-ml-2 {
    margin-left: -10px;
  }

  .print\:-mt-3 {
    margin-top: -15px;
  }

  .print\:-mr-3 {
    margin-right: -15px;
  }

  .print\:-mb-3 {
    margin-bottom: -15px;
  }

  .print\:-ml-3 {
    margin-left: -15px;
  }

  .print\:-mt-4 {
    margin-top: -30px;
  }

  .print\:-mr-4 {
    margin-right: -30px;
  }

  .print\:-mb-4 {
    margin-bottom: -30px;
  }

  .print\:-ml-4 {
    margin-left: -30px;
  }

  .print\:-mt-5 {
    margin-top: -50px;
  }

  .print\:-mr-5 {
    margin-right: -50px;
  }

  .print\:-mb-5 {
    margin-bottom: -50px;
  }

  .print\:-ml-5 {
    margin-left: -50px;
  }

  .print\:-mt-6 {
    margin-top: -75px;
  }

  .print\:-mr-6 {
    margin-right: -75px;
  }

  .print\:-mb-6 {
    margin-bottom: -75px;
  }

  .print\:-ml-6 {
    margin-left: -75px;
  }

  .print\:-mt-7 {
    margin-top: -100px;
  }

  .print\:-mr-7 {
    margin-right: -100px;
  }

  .print\:-mb-7 {
    margin-bottom: -100px;
  }

  .print\:-ml-7 {
    margin-left: -100px;
  }

  .print\:-mt-none {
    margin-top: 0px;
  }

  .print\:-mr-none {
    margin-right: 0px;
  }

  .print\:-mb-none {
    margin-bottom: 0px;
  }

  .print\:-ml-none {
    margin-left: 0px;
  }

  .print\:-mt-tiny {
    margin-top: -10px;
  }

  .print\:-mr-tiny {
    margin-right: -10px;
  }

  .print\:-mb-tiny {
    margin-bottom: -10px;
  }

  .print\:-ml-tiny {
    margin-left: -10px;
  }

  .print\:-mt-small {
    margin-top: -20px;
  }

  .print\:-mr-small {
    margin-right: -20px;
  }

  .print\:-mb-small {
    margin-bottom: -20px;
  }

  .print\:-ml-small {
    margin-left: -20px;
  }

  .print\:-mt-medium {
    margin-top: -30px;
  }

  .print\:-mr-medium {
    margin-right: -30px;
  }

  .print\:-mb-medium {
    margin-bottom: -30px;
  }

  .print\:-ml-medium {
    margin-left: -30px;
  }

  .print\:-mt-large {
    margin-top: -40px;
  }

  .print\:-mr-large {
    margin-right: -40px;
  }

  .print\:-mb-large {
    margin-bottom: -40px;
  }

  .print\:-ml-large {
    margin-left: -40px;
  }

  .print\:-mt-huge {
    margin-top: -60px;
  }

  .print\:-mr-huge {
    margin-right: -60px;
  }

  .print\:-mb-huge {
    margin-bottom: -60px;
  }

  .print\:-ml-huge {
    margin-left: -60px;
  }

  .print\:-mt-margin-label-height {
    margin-top: -26px;
  }

  .print\:-mr-margin-label-height {
    margin-right: -26px;
  }

  .print\:-mb-margin-label-height {
    margin-bottom: -26px;
  }

  .print\:-ml-margin-label-height {
    margin-left: -26px;
  }

  .print\:-mt-nav-height {
    margin-top: -80px;
  }

  .print\:-mr-nav-height {
    margin-right: -80px;
  }

  .print\:-mb-nav-height {
    margin-bottom: -80px;
  }

  .print\:-ml-nav-height {
    margin-left: -80px;
  }

  .print\:hover\:m-1:hover {
    margin: 5px;
  }

  .print\:hover\:m-2:hover {
    margin: 10px;
  }

  .print\:hover\:m-3:hover {
    margin: 15px;
  }

  .print\:hover\:m-4:hover {
    margin: 30px;
  }

  .print\:hover\:m-5:hover {
    margin: 50px;
  }

  .print\:hover\:m-6:hover {
    margin: 75px;
  }

  .print\:hover\:m-7:hover {
    margin: 100px;
  }

  .print\:hover\:m-auto:hover {
    margin: auto;
  }

  .print\:hover\:m-none:hover {
    margin: 0px;
  }

  .print\:hover\:m-tiny:hover {
    margin: 10px;
  }

  .print\:hover\:m-small:hover {
    margin: 20px;
  }

  .print\:hover\:m-medium:hover {
    margin: 30px;
  }

  .print\:hover\:m-large:hover {
    margin: 40px;
  }

  .print\:hover\:m-huge:hover {
    margin: 60px;
  }

  .print\:hover\:m-margin-label-height:hover {
    margin: 26px;
  }

  .print\:hover\:m-nav-height:hover {
    margin: 80px;
  }

  .print\:hover\:-m-1:hover {
    margin: -5px;
  }

  .print\:hover\:-m-2:hover {
    margin: -10px;
  }

  .print\:hover\:-m-3:hover {
    margin: -15px;
  }

  .print\:hover\:-m-4:hover {
    margin: -30px;
  }

  .print\:hover\:-m-5:hover {
    margin: -50px;
  }

  .print\:hover\:-m-6:hover {
    margin: -75px;
  }

  .print\:hover\:-m-7:hover {
    margin: -100px;
  }

  .print\:hover\:-m-none:hover {
    margin: 0px;
  }

  .print\:hover\:-m-tiny:hover {
    margin: -10px;
  }

  .print\:hover\:-m-small:hover {
    margin: -20px;
  }

  .print\:hover\:-m-medium:hover {
    margin: -30px;
  }

  .print\:hover\:-m-large:hover {
    margin: -40px;
  }

  .print\:hover\:-m-huge:hover {
    margin: -60px;
  }

  .print\:hover\:-m-margin-label-height:hover {
    margin: -26px;
  }

  .print\:hover\:-m-nav-height:hover {
    margin: -80px;
  }

  .print\:hover\:my-1:hover {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:hover\:mx-1:hover {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:hover\:my-2:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:hover\:mx-2:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:hover\:my-3:hover {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:hover\:mx-3:hover {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:hover\:my-4:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:hover\:mx-4:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:hover\:my-5:hover {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:hover\:mx-5:hover {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:hover\:my-6:hover {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:hover\:mx-6:hover {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:hover\:my-7:hover {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:hover\:mx-7:hover {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:hover\:my-auto:hover {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:hover\:mx-auto:hover {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:hover\:my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:hover\:mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:hover\:my-tiny:hover {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:hover\:mx-tiny:hover {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:hover\:my-small:hover {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:hover\:mx-small:hover {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:hover\:my-medium:hover {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:hover\:mx-medium:hover {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:hover\:my-large:hover {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:hover\:mx-large:hover {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:hover\:my-huge:hover {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:hover\:mx-huge:hover {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:hover\:my-margin-label-height:hover {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:hover\:mx-margin-label-height:hover {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:hover\:my-nav-height:hover {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:hover\:mx-nav-height:hover {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:hover\:-my-1:hover {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:hover\:-mx-1:hover {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:hover\:-my-2:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:hover\:-mx-2:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:hover\:-my-3:hover {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:hover\:-mx-3:hover {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:hover\:-my-4:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:hover\:-mx-4:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:hover\:-my-5:hover {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:hover\:-mx-5:hover {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:hover\:-my-6:hover {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:hover\:-mx-6:hover {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:hover\:-my-7:hover {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:hover\:-mx-7:hover {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:hover\:-my-none:hover {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:hover\:-mx-none:hover {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:hover\:-my-tiny:hover {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:hover\:-mx-tiny:hover {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:hover\:-my-small:hover {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:hover\:-mx-small:hover {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:hover\:-my-medium:hover {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:hover\:-mx-medium:hover {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:hover\:-my-large:hover {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:hover\:-mx-large:hover {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:hover\:-my-huge:hover {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:hover\:-mx-huge:hover {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:hover\:-my-margin-label-height:hover {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:hover\:-mx-margin-label-height:hover {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:hover\:-my-nav-height:hover {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:hover\:-mx-nav-height:hover {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:hover\:mt-1:hover {
    margin-top: 5px;
  }

  .print\:hover\:mr-1:hover {
    margin-right: 5px;
  }

  .print\:hover\:mb-1:hover {
    margin-bottom: 5px;
  }

  .print\:hover\:ml-1:hover {
    margin-left: 5px;
  }

  .print\:hover\:mt-2:hover {
    margin-top: 10px;
  }

  .print\:hover\:mr-2:hover {
    margin-right: 10px;
  }

  .print\:hover\:mb-2:hover {
    margin-bottom: 10px;
  }

  .print\:hover\:ml-2:hover {
    margin-left: 10px;
  }

  .print\:hover\:mt-3:hover {
    margin-top: 15px;
  }

  .print\:hover\:mr-3:hover {
    margin-right: 15px;
  }

  .print\:hover\:mb-3:hover {
    margin-bottom: 15px;
  }

  .print\:hover\:ml-3:hover {
    margin-left: 15px;
  }

  .print\:hover\:mt-4:hover {
    margin-top: 30px;
  }

  .print\:hover\:mr-4:hover {
    margin-right: 30px;
  }

  .print\:hover\:mb-4:hover {
    margin-bottom: 30px;
  }

  .print\:hover\:ml-4:hover {
    margin-left: 30px;
  }

  .print\:hover\:mt-5:hover {
    margin-top: 50px;
  }

  .print\:hover\:mr-5:hover {
    margin-right: 50px;
  }

  .print\:hover\:mb-5:hover {
    margin-bottom: 50px;
  }

  .print\:hover\:ml-5:hover {
    margin-left: 50px;
  }

  .print\:hover\:mt-6:hover {
    margin-top: 75px;
  }

  .print\:hover\:mr-6:hover {
    margin-right: 75px;
  }

  .print\:hover\:mb-6:hover {
    margin-bottom: 75px;
  }

  .print\:hover\:ml-6:hover {
    margin-left: 75px;
  }

  .print\:hover\:mt-7:hover {
    margin-top: 100px;
  }

  .print\:hover\:mr-7:hover {
    margin-right: 100px;
  }

  .print\:hover\:mb-7:hover {
    margin-bottom: 100px;
  }

  .print\:hover\:ml-7:hover {
    margin-left: 100px;
  }

  .print\:hover\:mt-auto:hover {
    margin-top: auto;
  }

  .print\:hover\:mr-auto:hover {
    margin-right: auto;
  }

  .print\:hover\:mb-auto:hover {
    margin-bottom: auto;
  }

  .print\:hover\:ml-auto:hover {
    margin-left: auto;
  }

  .print\:hover\:mt-none:hover {
    margin-top: 0px;
  }

  .print\:hover\:mr-none:hover {
    margin-right: 0px;
  }

  .print\:hover\:mb-none:hover {
    margin-bottom: 0px;
  }

  .print\:hover\:ml-none:hover {
    margin-left: 0px;
  }

  .print\:hover\:mt-tiny:hover {
    margin-top: 10px;
  }

  .print\:hover\:mr-tiny:hover {
    margin-right: 10px;
  }

  .print\:hover\:mb-tiny:hover {
    margin-bottom: 10px;
  }

  .print\:hover\:ml-tiny:hover {
    margin-left: 10px;
  }

  .print\:hover\:mt-small:hover {
    margin-top: 20px;
  }

  .print\:hover\:mr-small:hover {
    margin-right: 20px;
  }

  .print\:hover\:mb-small:hover {
    margin-bottom: 20px;
  }

  .print\:hover\:ml-small:hover {
    margin-left: 20px;
  }

  .print\:hover\:mt-medium:hover {
    margin-top: 30px;
  }

  .print\:hover\:mr-medium:hover {
    margin-right: 30px;
  }

  .print\:hover\:mb-medium:hover {
    margin-bottom: 30px;
  }

  .print\:hover\:ml-medium:hover {
    margin-left: 30px;
  }

  .print\:hover\:mt-large:hover {
    margin-top: 40px;
  }

  .print\:hover\:mr-large:hover {
    margin-right: 40px;
  }

  .print\:hover\:mb-large:hover {
    margin-bottom: 40px;
  }

  .print\:hover\:ml-large:hover {
    margin-left: 40px;
  }

  .print\:hover\:mt-huge:hover {
    margin-top: 60px;
  }

  .print\:hover\:mr-huge:hover {
    margin-right: 60px;
  }

  .print\:hover\:mb-huge:hover {
    margin-bottom: 60px;
  }

  .print\:hover\:ml-huge:hover {
    margin-left: 60px;
  }

  .print\:hover\:mt-margin-label-height:hover {
    margin-top: 26px;
  }

  .print\:hover\:mr-margin-label-height:hover {
    margin-right: 26px;
  }

  .print\:hover\:mb-margin-label-height:hover {
    margin-bottom: 26px;
  }

  .print\:hover\:ml-margin-label-height:hover {
    margin-left: 26px;
  }

  .print\:hover\:mt-nav-height:hover {
    margin-top: 80px;
  }

  .print\:hover\:mr-nav-height:hover {
    margin-right: 80px;
  }

  .print\:hover\:mb-nav-height:hover {
    margin-bottom: 80px;
  }

  .print\:hover\:ml-nav-height:hover {
    margin-left: 80px;
  }

  .print\:hover\:-mt-1:hover {
    margin-top: -5px;
  }

  .print\:hover\:-mr-1:hover {
    margin-right: -5px;
  }

  .print\:hover\:-mb-1:hover {
    margin-bottom: -5px;
  }

  .print\:hover\:-ml-1:hover {
    margin-left: -5px;
  }

  .print\:hover\:-mt-2:hover {
    margin-top: -10px;
  }

  .print\:hover\:-mr-2:hover {
    margin-right: -10px;
  }

  .print\:hover\:-mb-2:hover {
    margin-bottom: -10px;
  }

  .print\:hover\:-ml-2:hover {
    margin-left: -10px;
  }

  .print\:hover\:-mt-3:hover {
    margin-top: -15px;
  }

  .print\:hover\:-mr-3:hover {
    margin-right: -15px;
  }

  .print\:hover\:-mb-3:hover {
    margin-bottom: -15px;
  }

  .print\:hover\:-ml-3:hover {
    margin-left: -15px;
  }

  .print\:hover\:-mt-4:hover {
    margin-top: -30px;
  }

  .print\:hover\:-mr-4:hover {
    margin-right: -30px;
  }

  .print\:hover\:-mb-4:hover {
    margin-bottom: -30px;
  }

  .print\:hover\:-ml-4:hover {
    margin-left: -30px;
  }

  .print\:hover\:-mt-5:hover {
    margin-top: -50px;
  }

  .print\:hover\:-mr-5:hover {
    margin-right: -50px;
  }

  .print\:hover\:-mb-5:hover {
    margin-bottom: -50px;
  }

  .print\:hover\:-ml-5:hover {
    margin-left: -50px;
  }

  .print\:hover\:-mt-6:hover {
    margin-top: -75px;
  }

  .print\:hover\:-mr-6:hover {
    margin-right: -75px;
  }

  .print\:hover\:-mb-6:hover {
    margin-bottom: -75px;
  }

  .print\:hover\:-ml-6:hover {
    margin-left: -75px;
  }

  .print\:hover\:-mt-7:hover {
    margin-top: -100px;
  }

  .print\:hover\:-mr-7:hover {
    margin-right: -100px;
  }

  .print\:hover\:-mb-7:hover {
    margin-bottom: -100px;
  }

  .print\:hover\:-ml-7:hover {
    margin-left: -100px;
  }

  .print\:hover\:-mt-none:hover {
    margin-top: 0px;
  }

  .print\:hover\:-mr-none:hover {
    margin-right: 0px;
  }

  .print\:hover\:-mb-none:hover {
    margin-bottom: 0px;
  }

  .print\:hover\:-ml-none:hover {
    margin-left: 0px;
  }

  .print\:hover\:-mt-tiny:hover {
    margin-top: -10px;
  }

  .print\:hover\:-mr-tiny:hover {
    margin-right: -10px;
  }

  .print\:hover\:-mb-tiny:hover {
    margin-bottom: -10px;
  }

  .print\:hover\:-ml-tiny:hover {
    margin-left: -10px;
  }

  .print\:hover\:-mt-small:hover {
    margin-top: -20px;
  }

  .print\:hover\:-mr-small:hover {
    margin-right: -20px;
  }

  .print\:hover\:-mb-small:hover {
    margin-bottom: -20px;
  }

  .print\:hover\:-ml-small:hover {
    margin-left: -20px;
  }

  .print\:hover\:-mt-medium:hover {
    margin-top: -30px;
  }

  .print\:hover\:-mr-medium:hover {
    margin-right: -30px;
  }

  .print\:hover\:-mb-medium:hover {
    margin-bottom: -30px;
  }

  .print\:hover\:-ml-medium:hover {
    margin-left: -30px;
  }

  .print\:hover\:-mt-large:hover {
    margin-top: -40px;
  }

  .print\:hover\:-mr-large:hover {
    margin-right: -40px;
  }

  .print\:hover\:-mb-large:hover {
    margin-bottom: -40px;
  }

  .print\:hover\:-ml-large:hover {
    margin-left: -40px;
  }

  .print\:hover\:-mt-huge:hover {
    margin-top: -60px;
  }

  .print\:hover\:-mr-huge:hover {
    margin-right: -60px;
  }

  .print\:hover\:-mb-huge:hover {
    margin-bottom: -60px;
  }

  .print\:hover\:-ml-huge:hover {
    margin-left: -60px;
  }

  .print\:hover\:-mt-margin-label-height:hover {
    margin-top: -26px;
  }

  .print\:hover\:-mr-margin-label-height:hover {
    margin-right: -26px;
  }

  .print\:hover\:-mb-margin-label-height:hover {
    margin-bottom: -26px;
  }

  .print\:hover\:-ml-margin-label-height:hover {
    margin-left: -26px;
  }

  .print\:hover\:-mt-nav-height:hover {
    margin-top: -80px;
  }

  .print\:hover\:-mr-nav-height:hover {
    margin-right: -80px;
  }

  .print\:hover\:-mb-nav-height:hover {
    margin-bottom: -80px;
  }

  .print\:hover\:-ml-nav-height:hover {
    margin-left: -80px;
  }

  .print\:focus\:m-1:focus {
    margin: 5px;
  }

  .print\:focus\:m-2:focus {
    margin: 10px;
  }

  .print\:focus\:m-3:focus {
    margin: 15px;
  }

  .print\:focus\:m-4:focus {
    margin: 30px;
  }

  .print\:focus\:m-5:focus {
    margin: 50px;
  }

  .print\:focus\:m-6:focus {
    margin: 75px;
  }

  .print\:focus\:m-7:focus {
    margin: 100px;
  }

  .print\:focus\:m-auto:focus {
    margin: auto;
  }

  .print\:focus\:m-none:focus {
    margin: 0px;
  }

  .print\:focus\:m-tiny:focus {
    margin: 10px;
  }

  .print\:focus\:m-small:focus {
    margin: 20px;
  }

  .print\:focus\:m-medium:focus {
    margin: 30px;
  }

  .print\:focus\:m-large:focus {
    margin: 40px;
  }

  .print\:focus\:m-huge:focus {
    margin: 60px;
  }

  .print\:focus\:m-margin-label-height:focus {
    margin: 26px;
  }

  .print\:focus\:m-nav-height:focus {
    margin: 80px;
  }

  .print\:focus\:-m-1:focus {
    margin: -5px;
  }

  .print\:focus\:-m-2:focus {
    margin: -10px;
  }

  .print\:focus\:-m-3:focus {
    margin: -15px;
  }

  .print\:focus\:-m-4:focus {
    margin: -30px;
  }

  .print\:focus\:-m-5:focus {
    margin: -50px;
  }

  .print\:focus\:-m-6:focus {
    margin: -75px;
  }

  .print\:focus\:-m-7:focus {
    margin: -100px;
  }

  .print\:focus\:-m-none:focus {
    margin: 0px;
  }

  .print\:focus\:-m-tiny:focus {
    margin: -10px;
  }

  .print\:focus\:-m-small:focus {
    margin: -20px;
  }

  .print\:focus\:-m-medium:focus {
    margin: -30px;
  }

  .print\:focus\:-m-large:focus {
    margin: -40px;
  }

  .print\:focus\:-m-huge:focus {
    margin: -60px;
  }

  .print\:focus\:-m-margin-label-height:focus {
    margin: -26px;
  }

  .print\:focus\:-m-nav-height:focus {
    margin: -80px;
  }

  .print\:focus\:my-1:focus {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:focus\:mx-1:focus {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:focus\:my-2:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:focus\:mx-2:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:focus\:my-3:focus {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:focus\:mx-3:focus {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:focus\:my-4:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:focus\:mx-4:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:focus\:my-5:focus {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:focus\:mx-5:focus {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:focus\:my-6:focus {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:focus\:mx-6:focus {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:focus\:my-7:focus {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:focus\:mx-7:focus {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:focus\:my-auto:focus {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:focus\:mx-auto:focus {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:focus\:my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:focus\:mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:focus\:my-tiny:focus {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:focus\:mx-tiny:focus {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:focus\:my-small:focus {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:focus\:mx-small:focus {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:focus\:my-medium:focus {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:focus\:mx-medium:focus {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:focus\:my-large:focus {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:focus\:mx-large:focus {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:focus\:my-huge:focus {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:focus\:mx-huge:focus {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:focus\:my-margin-label-height:focus {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:focus\:mx-margin-label-height:focus {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:focus\:my-nav-height:focus {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:focus\:mx-nav-height:focus {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:focus\:-my-1:focus {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:focus\:-mx-1:focus {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:focus\:-my-2:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:focus\:-mx-2:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:focus\:-my-3:focus {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:focus\:-mx-3:focus {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:focus\:-my-4:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:focus\:-mx-4:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:focus\:-my-5:focus {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:focus\:-mx-5:focus {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:focus\:-my-6:focus {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:focus\:-mx-6:focus {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:focus\:-my-7:focus {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:focus\:-mx-7:focus {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:focus\:-my-none:focus {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:focus\:-mx-none:focus {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:focus\:-my-tiny:focus {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:focus\:-mx-tiny:focus {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:focus\:-my-small:focus {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:focus\:-mx-small:focus {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:focus\:-my-medium:focus {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:focus\:-mx-medium:focus {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:focus\:-my-large:focus {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:focus\:-mx-large:focus {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:focus\:-my-huge:focus {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:focus\:-mx-huge:focus {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:focus\:-my-margin-label-height:focus {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:focus\:-mx-margin-label-height:focus {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:focus\:-my-nav-height:focus {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:focus\:-mx-nav-height:focus {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:focus\:mt-1:focus {
    margin-top: 5px;
  }

  .print\:focus\:mr-1:focus {
    margin-right: 5px;
  }

  .print\:focus\:mb-1:focus {
    margin-bottom: 5px;
  }

  .print\:focus\:ml-1:focus {
    margin-left: 5px;
  }

  .print\:focus\:mt-2:focus {
    margin-top: 10px;
  }

  .print\:focus\:mr-2:focus {
    margin-right: 10px;
  }

  .print\:focus\:mb-2:focus {
    margin-bottom: 10px;
  }

  .print\:focus\:ml-2:focus {
    margin-left: 10px;
  }

  .print\:focus\:mt-3:focus {
    margin-top: 15px;
  }

  .print\:focus\:mr-3:focus {
    margin-right: 15px;
  }

  .print\:focus\:mb-3:focus {
    margin-bottom: 15px;
  }

  .print\:focus\:ml-3:focus {
    margin-left: 15px;
  }

  .print\:focus\:mt-4:focus {
    margin-top: 30px;
  }

  .print\:focus\:mr-4:focus {
    margin-right: 30px;
  }

  .print\:focus\:mb-4:focus {
    margin-bottom: 30px;
  }

  .print\:focus\:ml-4:focus {
    margin-left: 30px;
  }

  .print\:focus\:mt-5:focus {
    margin-top: 50px;
  }

  .print\:focus\:mr-5:focus {
    margin-right: 50px;
  }

  .print\:focus\:mb-5:focus {
    margin-bottom: 50px;
  }

  .print\:focus\:ml-5:focus {
    margin-left: 50px;
  }

  .print\:focus\:mt-6:focus {
    margin-top: 75px;
  }

  .print\:focus\:mr-6:focus {
    margin-right: 75px;
  }

  .print\:focus\:mb-6:focus {
    margin-bottom: 75px;
  }

  .print\:focus\:ml-6:focus {
    margin-left: 75px;
  }

  .print\:focus\:mt-7:focus {
    margin-top: 100px;
  }

  .print\:focus\:mr-7:focus {
    margin-right: 100px;
  }

  .print\:focus\:mb-7:focus {
    margin-bottom: 100px;
  }

  .print\:focus\:ml-7:focus {
    margin-left: 100px;
  }

  .print\:focus\:mt-auto:focus {
    margin-top: auto;
  }

  .print\:focus\:mr-auto:focus {
    margin-right: auto;
  }

  .print\:focus\:mb-auto:focus {
    margin-bottom: auto;
  }

  .print\:focus\:ml-auto:focus {
    margin-left: auto;
  }

  .print\:focus\:mt-none:focus {
    margin-top: 0px;
  }

  .print\:focus\:mr-none:focus {
    margin-right: 0px;
  }

  .print\:focus\:mb-none:focus {
    margin-bottom: 0px;
  }

  .print\:focus\:ml-none:focus {
    margin-left: 0px;
  }

  .print\:focus\:mt-tiny:focus {
    margin-top: 10px;
  }

  .print\:focus\:mr-tiny:focus {
    margin-right: 10px;
  }

  .print\:focus\:mb-tiny:focus {
    margin-bottom: 10px;
  }

  .print\:focus\:ml-tiny:focus {
    margin-left: 10px;
  }

  .print\:focus\:mt-small:focus {
    margin-top: 20px;
  }

  .print\:focus\:mr-small:focus {
    margin-right: 20px;
  }

  .print\:focus\:mb-small:focus {
    margin-bottom: 20px;
  }

  .print\:focus\:ml-small:focus {
    margin-left: 20px;
  }

  .print\:focus\:mt-medium:focus {
    margin-top: 30px;
  }

  .print\:focus\:mr-medium:focus {
    margin-right: 30px;
  }

  .print\:focus\:mb-medium:focus {
    margin-bottom: 30px;
  }

  .print\:focus\:ml-medium:focus {
    margin-left: 30px;
  }

  .print\:focus\:mt-large:focus {
    margin-top: 40px;
  }

  .print\:focus\:mr-large:focus {
    margin-right: 40px;
  }

  .print\:focus\:mb-large:focus {
    margin-bottom: 40px;
  }

  .print\:focus\:ml-large:focus {
    margin-left: 40px;
  }

  .print\:focus\:mt-huge:focus {
    margin-top: 60px;
  }

  .print\:focus\:mr-huge:focus {
    margin-right: 60px;
  }

  .print\:focus\:mb-huge:focus {
    margin-bottom: 60px;
  }

  .print\:focus\:ml-huge:focus {
    margin-left: 60px;
  }

  .print\:focus\:mt-margin-label-height:focus {
    margin-top: 26px;
  }

  .print\:focus\:mr-margin-label-height:focus {
    margin-right: 26px;
  }

  .print\:focus\:mb-margin-label-height:focus {
    margin-bottom: 26px;
  }

  .print\:focus\:ml-margin-label-height:focus {
    margin-left: 26px;
  }

  .print\:focus\:mt-nav-height:focus {
    margin-top: 80px;
  }

  .print\:focus\:mr-nav-height:focus {
    margin-right: 80px;
  }

  .print\:focus\:mb-nav-height:focus {
    margin-bottom: 80px;
  }

  .print\:focus\:ml-nav-height:focus {
    margin-left: 80px;
  }

  .print\:focus\:-mt-1:focus {
    margin-top: -5px;
  }

  .print\:focus\:-mr-1:focus {
    margin-right: -5px;
  }

  .print\:focus\:-mb-1:focus {
    margin-bottom: -5px;
  }

  .print\:focus\:-ml-1:focus {
    margin-left: -5px;
  }

  .print\:focus\:-mt-2:focus {
    margin-top: -10px;
  }

  .print\:focus\:-mr-2:focus {
    margin-right: -10px;
  }

  .print\:focus\:-mb-2:focus {
    margin-bottom: -10px;
  }

  .print\:focus\:-ml-2:focus {
    margin-left: -10px;
  }

  .print\:focus\:-mt-3:focus {
    margin-top: -15px;
  }

  .print\:focus\:-mr-3:focus {
    margin-right: -15px;
  }

  .print\:focus\:-mb-3:focus {
    margin-bottom: -15px;
  }

  .print\:focus\:-ml-3:focus {
    margin-left: -15px;
  }

  .print\:focus\:-mt-4:focus {
    margin-top: -30px;
  }

  .print\:focus\:-mr-4:focus {
    margin-right: -30px;
  }

  .print\:focus\:-mb-4:focus {
    margin-bottom: -30px;
  }

  .print\:focus\:-ml-4:focus {
    margin-left: -30px;
  }

  .print\:focus\:-mt-5:focus {
    margin-top: -50px;
  }

  .print\:focus\:-mr-5:focus {
    margin-right: -50px;
  }

  .print\:focus\:-mb-5:focus {
    margin-bottom: -50px;
  }

  .print\:focus\:-ml-5:focus {
    margin-left: -50px;
  }

  .print\:focus\:-mt-6:focus {
    margin-top: -75px;
  }

  .print\:focus\:-mr-6:focus {
    margin-right: -75px;
  }

  .print\:focus\:-mb-6:focus {
    margin-bottom: -75px;
  }

  .print\:focus\:-ml-6:focus {
    margin-left: -75px;
  }

  .print\:focus\:-mt-7:focus {
    margin-top: -100px;
  }

  .print\:focus\:-mr-7:focus {
    margin-right: -100px;
  }

  .print\:focus\:-mb-7:focus {
    margin-bottom: -100px;
  }

  .print\:focus\:-ml-7:focus {
    margin-left: -100px;
  }

  .print\:focus\:-mt-none:focus {
    margin-top: 0px;
  }

  .print\:focus\:-mr-none:focus {
    margin-right: 0px;
  }

  .print\:focus\:-mb-none:focus {
    margin-bottom: 0px;
  }

  .print\:focus\:-ml-none:focus {
    margin-left: 0px;
  }

  .print\:focus\:-mt-tiny:focus {
    margin-top: -10px;
  }

  .print\:focus\:-mr-tiny:focus {
    margin-right: -10px;
  }

  .print\:focus\:-mb-tiny:focus {
    margin-bottom: -10px;
  }

  .print\:focus\:-ml-tiny:focus {
    margin-left: -10px;
  }

  .print\:focus\:-mt-small:focus {
    margin-top: -20px;
  }

  .print\:focus\:-mr-small:focus {
    margin-right: -20px;
  }

  .print\:focus\:-mb-small:focus {
    margin-bottom: -20px;
  }

  .print\:focus\:-ml-small:focus {
    margin-left: -20px;
  }

  .print\:focus\:-mt-medium:focus {
    margin-top: -30px;
  }

  .print\:focus\:-mr-medium:focus {
    margin-right: -30px;
  }

  .print\:focus\:-mb-medium:focus {
    margin-bottom: -30px;
  }

  .print\:focus\:-ml-medium:focus {
    margin-left: -30px;
  }

  .print\:focus\:-mt-large:focus {
    margin-top: -40px;
  }

  .print\:focus\:-mr-large:focus {
    margin-right: -40px;
  }

  .print\:focus\:-mb-large:focus {
    margin-bottom: -40px;
  }

  .print\:focus\:-ml-large:focus {
    margin-left: -40px;
  }

  .print\:focus\:-mt-huge:focus {
    margin-top: -60px;
  }

  .print\:focus\:-mr-huge:focus {
    margin-right: -60px;
  }

  .print\:focus\:-mb-huge:focus {
    margin-bottom: -60px;
  }

  .print\:focus\:-ml-huge:focus {
    margin-left: -60px;
  }

  .print\:focus\:-mt-margin-label-height:focus {
    margin-top: -26px;
  }

  .print\:focus\:-mr-margin-label-height:focus {
    margin-right: -26px;
  }

  .print\:focus\:-mb-margin-label-height:focus {
    margin-bottom: -26px;
  }

  .print\:focus\:-ml-margin-label-height:focus {
    margin-left: -26px;
  }

  .print\:focus\:-mt-nav-height:focus {
    margin-top: -80px;
  }

  .print\:focus\:-mr-nav-height:focus {
    margin-right: -80px;
  }

  .print\:focus\:-mb-nav-height:focus {
    margin-bottom: -80px;
  }

  .print\:focus\:-ml-nav-height:focus {
    margin-left: -80px;
  }

  .print\:first\:m-1:first-child {
    margin: 5px;
  }

  .print\:first\:m-2:first-child {
    margin: 10px;
  }

  .print\:first\:m-3:first-child {
    margin: 15px;
  }

  .print\:first\:m-4:first-child {
    margin: 30px;
  }

  .print\:first\:m-5:first-child {
    margin: 50px;
  }

  .print\:first\:m-6:first-child {
    margin: 75px;
  }

  .print\:first\:m-7:first-child {
    margin: 100px;
  }

  .print\:first\:m-auto:first-child {
    margin: auto;
  }

  .print\:first\:m-none:first-child {
    margin: 0px;
  }

  .print\:first\:m-tiny:first-child {
    margin: 10px;
  }

  .print\:first\:m-small:first-child {
    margin: 20px;
  }

  .print\:first\:m-medium:first-child {
    margin: 30px;
  }

  .print\:first\:m-large:first-child {
    margin: 40px;
  }

  .print\:first\:m-huge:first-child {
    margin: 60px;
  }

  .print\:first\:m-margin-label-height:first-child {
    margin: 26px;
  }

  .print\:first\:m-nav-height:first-child {
    margin: 80px;
  }

  .print\:first\:-m-1:first-child {
    margin: -5px;
  }

  .print\:first\:-m-2:first-child {
    margin: -10px;
  }

  .print\:first\:-m-3:first-child {
    margin: -15px;
  }

  .print\:first\:-m-4:first-child {
    margin: -30px;
  }

  .print\:first\:-m-5:first-child {
    margin: -50px;
  }

  .print\:first\:-m-6:first-child {
    margin: -75px;
  }

  .print\:first\:-m-7:first-child {
    margin: -100px;
  }

  .print\:first\:-m-none:first-child {
    margin: 0px;
  }

  .print\:first\:-m-tiny:first-child {
    margin: -10px;
  }

  .print\:first\:-m-small:first-child {
    margin: -20px;
  }

  .print\:first\:-m-medium:first-child {
    margin: -30px;
  }

  .print\:first\:-m-large:first-child {
    margin: -40px;
  }

  .print\:first\:-m-huge:first-child {
    margin: -60px;
  }

  .print\:first\:-m-margin-label-height:first-child {
    margin: -26px;
  }

  .print\:first\:-m-nav-height:first-child {
    margin: -80px;
  }

  .print\:first\:my-1:first-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:first\:mx-1:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:first\:my-2:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:first\:mx-2:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:first\:my-3:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:first\:mx-3:first-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:first\:my-4:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:first\:mx-4:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:first\:my-5:first-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:first\:mx-5:first-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:first\:my-6:first-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:first\:mx-6:first-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:first\:my-7:first-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:first\:mx-7:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:first\:my-auto:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:first\:mx-auto:first-child {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:first\:my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:first\:mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:first\:my-tiny:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:first\:mx-tiny:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:first\:my-small:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:first\:mx-small:first-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:first\:my-medium:first-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:first\:mx-medium:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:first\:my-large:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:first\:mx-large:first-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:first\:my-huge:first-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:first\:mx-huge:first-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:first\:my-margin-label-height:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:first\:mx-margin-label-height:first-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:first\:my-nav-height:first-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:first\:mx-nav-height:first-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:first\:-my-1:first-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:first\:-mx-1:first-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:first\:-my-2:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:first\:-mx-2:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:first\:-my-3:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:first\:-mx-3:first-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:first\:-my-4:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:first\:-mx-4:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:first\:-my-5:first-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:first\:-mx-5:first-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:first\:-my-6:first-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:first\:-mx-6:first-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:first\:-my-7:first-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:first\:-mx-7:first-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:first\:-my-none:first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:first\:-mx-none:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:first\:-my-tiny:first-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:first\:-mx-tiny:first-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:first\:-my-small:first-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:first\:-mx-small:first-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:first\:-my-medium:first-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:first\:-mx-medium:first-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:first\:-my-large:first-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:first\:-mx-large:first-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:first\:-my-huge:first-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:first\:-mx-huge:first-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:first\:-my-margin-label-height:first-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:first\:-mx-margin-label-height:first-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:first\:-my-nav-height:first-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:first\:-mx-nav-height:first-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:first\:mt-1:first-child {
    margin-top: 5px;
  }

  .print\:first\:mr-1:first-child {
    margin-right: 5px;
  }

  .print\:first\:mb-1:first-child {
    margin-bottom: 5px;
  }

  .print\:first\:ml-1:first-child {
    margin-left: 5px;
  }

  .print\:first\:mt-2:first-child {
    margin-top: 10px;
  }

  .print\:first\:mr-2:first-child {
    margin-right: 10px;
  }

  .print\:first\:mb-2:first-child {
    margin-bottom: 10px;
  }

  .print\:first\:ml-2:first-child {
    margin-left: 10px;
  }

  .print\:first\:mt-3:first-child {
    margin-top: 15px;
  }

  .print\:first\:mr-3:first-child {
    margin-right: 15px;
  }

  .print\:first\:mb-3:first-child {
    margin-bottom: 15px;
  }

  .print\:first\:ml-3:first-child {
    margin-left: 15px;
  }

  .print\:first\:mt-4:first-child {
    margin-top: 30px;
  }

  .print\:first\:mr-4:first-child {
    margin-right: 30px;
  }

  .print\:first\:mb-4:first-child {
    margin-bottom: 30px;
  }

  .print\:first\:ml-4:first-child {
    margin-left: 30px;
  }

  .print\:first\:mt-5:first-child {
    margin-top: 50px;
  }

  .print\:first\:mr-5:first-child {
    margin-right: 50px;
  }

  .print\:first\:mb-5:first-child {
    margin-bottom: 50px;
  }

  .print\:first\:ml-5:first-child {
    margin-left: 50px;
  }

  .print\:first\:mt-6:first-child {
    margin-top: 75px;
  }

  .print\:first\:mr-6:first-child {
    margin-right: 75px;
  }

  .print\:first\:mb-6:first-child {
    margin-bottom: 75px;
  }

  .print\:first\:ml-6:first-child {
    margin-left: 75px;
  }

  .print\:first\:mt-7:first-child {
    margin-top: 100px;
  }

  .print\:first\:mr-7:first-child {
    margin-right: 100px;
  }

  .print\:first\:mb-7:first-child {
    margin-bottom: 100px;
  }

  .print\:first\:ml-7:first-child {
    margin-left: 100px;
  }

  .print\:first\:mt-auto:first-child {
    margin-top: auto;
  }

  .print\:first\:mr-auto:first-child {
    margin-right: auto;
  }

  .print\:first\:mb-auto:first-child {
    margin-bottom: auto;
  }

  .print\:first\:ml-auto:first-child {
    margin-left: auto;
  }

  .print\:first\:mt-none:first-child {
    margin-top: 0px;
  }

  .print\:first\:mr-none:first-child {
    margin-right: 0px;
  }

  .print\:first\:mb-none:first-child {
    margin-bottom: 0px;
  }

  .print\:first\:ml-none:first-child {
    margin-left: 0px;
  }

  .print\:first\:mt-tiny:first-child {
    margin-top: 10px;
  }

  .print\:first\:mr-tiny:first-child {
    margin-right: 10px;
  }

  .print\:first\:mb-tiny:first-child {
    margin-bottom: 10px;
  }

  .print\:first\:ml-tiny:first-child {
    margin-left: 10px;
  }

  .print\:first\:mt-small:first-child {
    margin-top: 20px;
  }

  .print\:first\:mr-small:first-child {
    margin-right: 20px;
  }

  .print\:first\:mb-small:first-child {
    margin-bottom: 20px;
  }

  .print\:first\:ml-small:first-child {
    margin-left: 20px;
  }

  .print\:first\:mt-medium:first-child {
    margin-top: 30px;
  }

  .print\:first\:mr-medium:first-child {
    margin-right: 30px;
  }

  .print\:first\:mb-medium:first-child {
    margin-bottom: 30px;
  }

  .print\:first\:ml-medium:first-child {
    margin-left: 30px;
  }

  .print\:first\:mt-large:first-child {
    margin-top: 40px;
  }

  .print\:first\:mr-large:first-child {
    margin-right: 40px;
  }

  .print\:first\:mb-large:first-child {
    margin-bottom: 40px;
  }

  .print\:first\:ml-large:first-child {
    margin-left: 40px;
  }

  .print\:first\:mt-huge:first-child {
    margin-top: 60px;
  }

  .print\:first\:mr-huge:first-child {
    margin-right: 60px;
  }

  .print\:first\:mb-huge:first-child {
    margin-bottom: 60px;
  }

  .print\:first\:ml-huge:first-child {
    margin-left: 60px;
  }

  .print\:first\:mt-margin-label-height:first-child {
    margin-top: 26px;
  }

  .print\:first\:mr-margin-label-height:first-child {
    margin-right: 26px;
  }

  .print\:first\:mb-margin-label-height:first-child {
    margin-bottom: 26px;
  }

  .print\:first\:ml-margin-label-height:first-child {
    margin-left: 26px;
  }

  .print\:first\:mt-nav-height:first-child {
    margin-top: 80px;
  }

  .print\:first\:mr-nav-height:first-child {
    margin-right: 80px;
  }

  .print\:first\:mb-nav-height:first-child {
    margin-bottom: 80px;
  }

  .print\:first\:ml-nav-height:first-child {
    margin-left: 80px;
  }

  .print\:first\:-mt-1:first-child {
    margin-top: -5px;
  }

  .print\:first\:-mr-1:first-child {
    margin-right: -5px;
  }

  .print\:first\:-mb-1:first-child {
    margin-bottom: -5px;
  }

  .print\:first\:-ml-1:first-child {
    margin-left: -5px;
  }

  .print\:first\:-mt-2:first-child {
    margin-top: -10px;
  }

  .print\:first\:-mr-2:first-child {
    margin-right: -10px;
  }

  .print\:first\:-mb-2:first-child {
    margin-bottom: -10px;
  }

  .print\:first\:-ml-2:first-child {
    margin-left: -10px;
  }

  .print\:first\:-mt-3:first-child {
    margin-top: -15px;
  }

  .print\:first\:-mr-3:first-child {
    margin-right: -15px;
  }

  .print\:first\:-mb-3:first-child {
    margin-bottom: -15px;
  }

  .print\:first\:-ml-3:first-child {
    margin-left: -15px;
  }

  .print\:first\:-mt-4:first-child {
    margin-top: -30px;
  }

  .print\:first\:-mr-4:first-child {
    margin-right: -30px;
  }

  .print\:first\:-mb-4:first-child {
    margin-bottom: -30px;
  }

  .print\:first\:-ml-4:first-child {
    margin-left: -30px;
  }

  .print\:first\:-mt-5:first-child {
    margin-top: -50px;
  }

  .print\:first\:-mr-5:first-child {
    margin-right: -50px;
  }

  .print\:first\:-mb-5:first-child {
    margin-bottom: -50px;
  }

  .print\:first\:-ml-5:first-child {
    margin-left: -50px;
  }

  .print\:first\:-mt-6:first-child {
    margin-top: -75px;
  }

  .print\:first\:-mr-6:first-child {
    margin-right: -75px;
  }

  .print\:first\:-mb-6:first-child {
    margin-bottom: -75px;
  }

  .print\:first\:-ml-6:first-child {
    margin-left: -75px;
  }

  .print\:first\:-mt-7:first-child {
    margin-top: -100px;
  }

  .print\:first\:-mr-7:first-child {
    margin-right: -100px;
  }

  .print\:first\:-mb-7:first-child {
    margin-bottom: -100px;
  }

  .print\:first\:-ml-7:first-child {
    margin-left: -100px;
  }

  .print\:first\:-mt-none:first-child {
    margin-top: 0px;
  }

  .print\:first\:-mr-none:first-child {
    margin-right: 0px;
  }

  .print\:first\:-mb-none:first-child {
    margin-bottom: 0px;
  }

  .print\:first\:-ml-none:first-child {
    margin-left: 0px;
  }

  .print\:first\:-mt-tiny:first-child {
    margin-top: -10px;
  }

  .print\:first\:-mr-tiny:first-child {
    margin-right: -10px;
  }

  .print\:first\:-mb-tiny:first-child {
    margin-bottom: -10px;
  }

  .print\:first\:-ml-tiny:first-child {
    margin-left: -10px;
  }

  .print\:first\:-mt-small:first-child {
    margin-top: -20px;
  }

  .print\:first\:-mr-small:first-child {
    margin-right: -20px;
  }

  .print\:first\:-mb-small:first-child {
    margin-bottom: -20px;
  }

  .print\:first\:-ml-small:first-child {
    margin-left: -20px;
  }

  .print\:first\:-mt-medium:first-child {
    margin-top: -30px;
  }

  .print\:first\:-mr-medium:first-child {
    margin-right: -30px;
  }

  .print\:first\:-mb-medium:first-child {
    margin-bottom: -30px;
  }

  .print\:first\:-ml-medium:first-child {
    margin-left: -30px;
  }

  .print\:first\:-mt-large:first-child {
    margin-top: -40px;
  }

  .print\:first\:-mr-large:first-child {
    margin-right: -40px;
  }

  .print\:first\:-mb-large:first-child {
    margin-bottom: -40px;
  }

  .print\:first\:-ml-large:first-child {
    margin-left: -40px;
  }

  .print\:first\:-mt-huge:first-child {
    margin-top: -60px;
  }

  .print\:first\:-mr-huge:first-child {
    margin-right: -60px;
  }

  .print\:first\:-mb-huge:first-child {
    margin-bottom: -60px;
  }

  .print\:first\:-ml-huge:first-child {
    margin-left: -60px;
  }

  .print\:first\:-mt-margin-label-height:first-child {
    margin-top: -26px;
  }

  .print\:first\:-mr-margin-label-height:first-child {
    margin-right: -26px;
  }

  .print\:first\:-mb-margin-label-height:first-child {
    margin-bottom: -26px;
  }

  .print\:first\:-ml-margin-label-height:first-child {
    margin-left: -26px;
  }

  .print\:first\:-mt-nav-height:first-child {
    margin-top: -80px;
  }

  .print\:first\:-mr-nav-height:first-child {
    margin-right: -80px;
  }

  .print\:first\:-mb-nav-height:first-child {
    margin-bottom: -80px;
  }

  .print\:first\:-ml-nav-height:first-child {
    margin-left: -80px;
  }

  .print\:last\:m-1:last-child {
    margin: 5px;
  }

  .print\:last\:m-2:last-child {
    margin: 10px;
  }

  .print\:last\:m-3:last-child {
    margin: 15px;
  }

  .print\:last\:m-4:last-child {
    margin: 30px;
  }

  .print\:last\:m-5:last-child {
    margin: 50px;
  }

  .print\:last\:m-6:last-child {
    margin: 75px;
  }

  .print\:last\:m-7:last-child {
    margin: 100px;
  }

  .print\:last\:m-auto:last-child {
    margin: auto;
  }

  .print\:last\:m-none:last-child {
    margin: 0px;
  }

  .print\:last\:m-tiny:last-child {
    margin: 10px;
  }

  .print\:last\:m-small:last-child {
    margin: 20px;
  }

  .print\:last\:m-medium:last-child {
    margin: 30px;
  }

  .print\:last\:m-large:last-child {
    margin: 40px;
  }

  .print\:last\:m-huge:last-child {
    margin: 60px;
  }

  .print\:last\:m-margin-label-height:last-child {
    margin: 26px;
  }

  .print\:last\:m-nav-height:last-child {
    margin: 80px;
  }

  .print\:last\:-m-1:last-child {
    margin: -5px;
  }

  .print\:last\:-m-2:last-child {
    margin: -10px;
  }

  .print\:last\:-m-3:last-child {
    margin: -15px;
  }

  .print\:last\:-m-4:last-child {
    margin: -30px;
  }

  .print\:last\:-m-5:last-child {
    margin: -50px;
  }

  .print\:last\:-m-6:last-child {
    margin: -75px;
  }

  .print\:last\:-m-7:last-child {
    margin: -100px;
  }

  .print\:last\:-m-none:last-child {
    margin: 0px;
  }

  .print\:last\:-m-tiny:last-child {
    margin: -10px;
  }

  .print\:last\:-m-small:last-child {
    margin: -20px;
  }

  .print\:last\:-m-medium:last-child {
    margin: -30px;
  }

  .print\:last\:-m-large:last-child {
    margin: -40px;
  }

  .print\:last\:-m-huge:last-child {
    margin: -60px;
  }

  .print\:last\:-m-margin-label-height:last-child {
    margin: -26px;
  }

  .print\:last\:-m-nav-height:last-child {
    margin: -80px;
  }

  .print\:last\:my-1:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:last\:mx-1:last-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:last\:my-2:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:last\:mx-2:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:last\:my-3:last-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:last\:mx-3:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:last\:my-4:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:last\:mx-4:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:last\:my-5:last-child {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:last\:mx-5:last-child {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:last\:my-6:last-child {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:last\:mx-6:last-child {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:last\:my-7:last-child {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:last\:mx-7:last-child {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:last\:my-auto:last-child {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:last\:mx-auto:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:last\:my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:last\:mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:last\:my-tiny:last-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:last\:mx-tiny:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:last\:my-small:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:last\:mx-small:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:last\:my-medium:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:last\:mx-medium:last-child {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:last\:my-large:last-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:last\:mx-large:last-child {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:last\:my-huge:last-child {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:last\:mx-huge:last-child {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:last\:my-margin-label-height:last-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:last\:mx-margin-label-height:last-child {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:last\:my-nav-height:last-child {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:last\:mx-nav-height:last-child {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:last\:-my-1:last-child {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:last\:-mx-1:last-child {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:last\:-my-2:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:last\:-mx-2:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:last\:-my-3:last-child {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:last\:-mx-3:last-child {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:last\:-my-4:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:last\:-mx-4:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:last\:-my-5:last-child {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:last\:-mx-5:last-child {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:last\:-my-6:last-child {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:last\:-mx-6:last-child {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:last\:-my-7:last-child {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:last\:-mx-7:last-child {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:last\:-my-none:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:last\:-mx-none:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:last\:-my-tiny:last-child {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:last\:-mx-tiny:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:last\:-my-small:last-child {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:last\:-mx-small:last-child {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:last\:-my-medium:last-child {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:last\:-mx-medium:last-child {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:last\:-my-large:last-child {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:last\:-mx-large:last-child {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:last\:-my-huge:last-child {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:last\:-mx-huge:last-child {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:last\:-my-margin-label-height:last-child {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:last\:-mx-margin-label-height:last-child {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:last\:-my-nav-height:last-child {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:last\:-mx-nav-height:last-child {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:last\:mt-1:last-child {
    margin-top: 5px;
  }

  .print\:last\:mr-1:last-child {
    margin-right: 5px;
  }

  .print\:last\:mb-1:last-child {
    margin-bottom: 5px;
  }

  .print\:last\:ml-1:last-child {
    margin-left: 5px;
  }

  .print\:last\:mt-2:last-child {
    margin-top: 10px;
  }

  .print\:last\:mr-2:last-child {
    margin-right: 10px;
  }

  .print\:last\:mb-2:last-child {
    margin-bottom: 10px;
  }

  .print\:last\:ml-2:last-child {
    margin-left: 10px;
  }

  .print\:last\:mt-3:last-child {
    margin-top: 15px;
  }

  .print\:last\:mr-3:last-child {
    margin-right: 15px;
  }

  .print\:last\:mb-3:last-child {
    margin-bottom: 15px;
  }

  .print\:last\:ml-3:last-child {
    margin-left: 15px;
  }

  .print\:last\:mt-4:last-child {
    margin-top: 30px;
  }

  .print\:last\:mr-4:last-child {
    margin-right: 30px;
  }

  .print\:last\:mb-4:last-child {
    margin-bottom: 30px;
  }

  .print\:last\:ml-4:last-child {
    margin-left: 30px;
  }

  .print\:last\:mt-5:last-child {
    margin-top: 50px;
  }

  .print\:last\:mr-5:last-child {
    margin-right: 50px;
  }

  .print\:last\:mb-5:last-child {
    margin-bottom: 50px;
  }

  .print\:last\:ml-5:last-child {
    margin-left: 50px;
  }

  .print\:last\:mt-6:last-child {
    margin-top: 75px;
  }

  .print\:last\:mr-6:last-child {
    margin-right: 75px;
  }

  .print\:last\:mb-6:last-child {
    margin-bottom: 75px;
  }

  .print\:last\:ml-6:last-child {
    margin-left: 75px;
  }

  .print\:last\:mt-7:last-child {
    margin-top: 100px;
  }

  .print\:last\:mr-7:last-child {
    margin-right: 100px;
  }

  .print\:last\:mb-7:last-child {
    margin-bottom: 100px;
  }

  .print\:last\:ml-7:last-child {
    margin-left: 100px;
  }

  .print\:last\:mt-auto:last-child {
    margin-top: auto;
  }

  .print\:last\:mr-auto:last-child {
    margin-right: auto;
  }

  .print\:last\:mb-auto:last-child {
    margin-bottom: auto;
  }

  .print\:last\:ml-auto:last-child {
    margin-left: auto;
  }

  .print\:last\:mt-none:last-child {
    margin-top: 0px;
  }

  .print\:last\:mr-none:last-child {
    margin-right: 0px;
  }

  .print\:last\:mb-none:last-child {
    margin-bottom: 0px;
  }

  .print\:last\:ml-none:last-child {
    margin-left: 0px;
  }

  .print\:last\:mt-tiny:last-child {
    margin-top: 10px;
  }

  .print\:last\:mr-tiny:last-child {
    margin-right: 10px;
  }

  .print\:last\:mb-tiny:last-child {
    margin-bottom: 10px;
  }

  .print\:last\:ml-tiny:last-child {
    margin-left: 10px;
  }

  .print\:last\:mt-small:last-child {
    margin-top: 20px;
  }

  .print\:last\:mr-small:last-child {
    margin-right: 20px;
  }

  .print\:last\:mb-small:last-child {
    margin-bottom: 20px;
  }

  .print\:last\:ml-small:last-child {
    margin-left: 20px;
  }

  .print\:last\:mt-medium:last-child {
    margin-top: 30px;
  }

  .print\:last\:mr-medium:last-child {
    margin-right: 30px;
  }

  .print\:last\:mb-medium:last-child {
    margin-bottom: 30px;
  }

  .print\:last\:ml-medium:last-child {
    margin-left: 30px;
  }

  .print\:last\:mt-large:last-child {
    margin-top: 40px;
  }

  .print\:last\:mr-large:last-child {
    margin-right: 40px;
  }

  .print\:last\:mb-large:last-child {
    margin-bottom: 40px;
  }

  .print\:last\:ml-large:last-child {
    margin-left: 40px;
  }

  .print\:last\:mt-huge:last-child {
    margin-top: 60px;
  }

  .print\:last\:mr-huge:last-child {
    margin-right: 60px;
  }

  .print\:last\:mb-huge:last-child {
    margin-bottom: 60px;
  }

  .print\:last\:ml-huge:last-child {
    margin-left: 60px;
  }

  .print\:last\:mt-margin-label-height:last-child {
    margin-top: 26px;
  }

  .print\:last\:mr-margin-label-height:last-child {
    margin-right: 26px;
  }

  .print\:last\:mb-margin-label-height:last-child {
    margin-bottom: 26px;
  }

  .print\:last\:ml-margin-label-height:last-child {
    margin-left: 26px;
  }

  .print\:last\:mt-nav-height:last-child {
    margin-top: 80px;
  }

  .print\:last\:mr-nav-height:last-child {
    margin-right: 80px;
  }

  .print\:last\:mb-nav-height:last-child {
    margin-bottom: 80px;
  }

  .print\:last\:ml-nav-height:last-child {
    margin-left: 80px;
  }

  .print\:last\:-mt-1:last-child {
    margin-top: -5px;
  }

  .print\:last\:-mr-1:last-child {
    margin-right: -5px;
  }

  .print\:last\:-mb-1:last-child {
    margin-bottom: -5px;
  }

  .print\:last\:-ml-1:last-child {
    margin-left: -5px;
  }

  .print\:last\:-mt-2:last-child {
    margin-top: -10px;
  }

  .print\:last\:-mr-2:last-child {
    margin-right: -10px;
  }

  .print\:last\:-mb-2:last-child {
    margin-bottom: -10px;
  }

  .print\:last\:-ml-2:last-child {
    margin-left: -10px;
  }

  .print\:last\:-mt-3:last-child {
    margin-top: -15px;
  }

  .print\:last\:-mr-3:last-child {
    margin-right: -15px;
  }

  .print\:last\:-mb-3:last-child {
    margin-bottom: -15px;
  }

  .print\:last\:-ml-3:last-child {
    margin-left: -15px;
  }

  .print\:last\:-mt-4:last-child {
    margin-top: -30px;
  }

  .print\:last\:-mr-4:last-child {
    margin-right: -30px;
  }

  .print\:last\:-mb-4:last-child {
    margin-bottom: -30px;
  }

  .print\:last\:-ml-4:last-child {
    margin-left: -30px;
  }

  .print\:last\:-mt-5:last-child {
    margin-top: -50px;
  }

  .print\:last\:-mr-5:last-child {
    margin-right: -50px;
  }

  .print\:last\:-mb-5:last-child {
    margin-bottom: -50px;
  }

  .print\:last\:-ml-5:last-child {
    margin-left: -50px;
  }

  .print\:last\:-mt-6:last-child {
    margin-top: -75px;
  }

  .print\:last\:-mr-6:last-child {
    margin-right: -75px;
  }

  .print\:last\:-mb-6:last-child {
    margin-bottom: -75px;
  }

  .print\:last\:-ml-6:last-child {
    margin-left: -75px;
  }

  .print\:last\:-mt-7:last-child {
    margin-top: -100px;
  }

  .print\:last\:-mr-7:last-child {
    margin-right: -100px;
  }

  .print\:last\:-mb-7:last-child {
    margin-bottom: -100px;
  }

  .print\:last\:-ml-7:last-child {
    margin-left: -100px;
  }

  .print\:last\:-mt-none:last-child {
    margin-top: 0px;
  }

  .print\:last\:-mr-none:last-child {
    margin-right: 0px;
  }

  .print\:last\:-mb-none:last-child {
    margin-bottom: 0px;
  }

  .print\:last\:-ml-none:last-child {
    margin-left: 0px;
  }

  .print\:last\:-mt-tiny:last-child {
    margin-top: -10px;
  }

  .print\:last\:-mr-tiny:last-child {
    margin-right: -10px;
  }

  .print\:last\:-mb-tiny:last-child {
    margin-bottom: -10px;
  }

  .print\:last\:-ml-tiny:last-child {
    margin-left: -10px;
  }

  .print\:last\:-mt-small:last-child {
    margin-top: -20px;
  }

  .print\:last\:-mr-small:last-child {
    margin-right: -20px;
  }

  .print\:last\:-mb-small:last-child {
    margin-bottom: -20px;
  }

  .print\:last\:-ml-small:last-child {
    margin-left: -20px;
  }

  .print\:last\:-mt-medium:last-child {
    margin-top: -30px;
  }

  .print\:last\:-mr-medium:last-child {
    margin-right: -30px;
  }

  .print\:last\:-mb-medium:last-child {
    margin-bottom: -30px;
  }

  .print\:last\:-ml-medium:last-child {
    margin-left: -30px;
  }

  .print\:last\:-mt-large:last-child {
    margin-top: -40px;
  }

  .print\:last\:-mr-large:last-child {
    margin-right: -40px;
  }

  .print\:last\:-mb-large:last-child {
    margin-bottom: -40px;
  }

  .print\:last\:-ml-large:last-child {
    margin-left: -40px;
  }

  .print\:last\:-mt-huge:last-child {
    margin-top: -60px;
  }

  .print\:last\:-mr-huge:last-child {
    margin-right: -60px;
  }

  .print\:last\:-mb-huge:last-child {
    margin-bottom: -60px;
  }

  .print\:last\:-ml-huge:last-child {
    margin-left: -60px;
  }

  .print\:last\:-mt-margin-label-height:last-child {
    margin-top: -26px;
  }

  .print\:last\:-mr-margin-label-height:last-child {
    margin-right: -26px;
  }

  .print\:last\:-mb-margin-label-height:last-child {
    margin-bottom: -26px;
  }

  .print\:last\:-ml-margin-label-height:last-child {
    margin-left: -26px;
  }

  .print\:last\:-mt-nav-height:last-child {
    margin-top: -80px;
  }

  .print\:last\:-mr-nav-height:last-child {
    margin-right: -80px;
  }

  .print\:last\:-mb-nav-height:last-child {
    margin-bottom: -80px;
  }

  .print\:last\:-ml-nav-height:last-child {
    margin-left: -80px;
  }

  .print\:odd\:m-1:nth-child(odd) {
    margin: 5px;
  }

  .print\:odd\:m-2:nth-child(odd) {
    margin: 10px;
  }

  .print\:odd\:m-3:nth-child(odd) {
    margin: 15px;
  }

  .print\:odd\:m-4:nth-child(odd) {
    margin: 30px;
  }

  .print\:odd\:m-5:nth-child(odd) {
    margin: 50px;
  }

  .print\:odd\:m-6:nth-child(odd) {
    margin: 75px;
  }

  .print\:odd\:m-7:nth-child(odd) {
    margin: 100px;
  }

  .print\:odd\:m-auto:nth-child(odd) {
    margin: auto;
  }

  .print\:odd\:m-none:nth-child(odd) {
    margin: 0px;
  }

  .print\:odd\:m-tiny:nth-child(odd) {
    margin: 10px;
  }

  .print\:odd\:m-small:nth-child(odd) {
    margin: 20px;
  }

  .print\:odd\:m-medium:nth-child(odd) {
    margin: 30px;
  }

  .print\:odd\:m-large:nth-child(odd) {
    margin: 40px;
  }

  .print\:odd\:m-huge:nth-child(odd) {
    margin: 60px;
  }

  .print\:odd\:m-margin-label-height:nth-child(odd) {
    margin: 26px;
  }

  .print\:odd\:m-nav-height:nth-child(odd) {
    margin: 80px;
  }

  .print\:odd\:-m-1:nth-child(odd) {
    margin: -5px;
  }

  .print\:odd\:-m-2:nth-child(odd) {
    margin: -10px;
  }

  .print\:odd\:-m-3:nth-child(odd) {
    margin: -15px;
  }

  .print\:odd\:-m-4:nth-child(odd) {
    margin: -30px;
  }

  .print\:odd\:-m-5:nth-child(odd) {
    margin: -50px;
  }

  .print\:odd\:-m-6:nth-child(odd) {
    margin: -75px;
  }

  .print\:odd\:-m-7:nth-child(odd) {
    margin: -100px;
  }

  .print\:odd\:-m-none:nth-child(odd) {
    margin: 0px;
  }

  .print\:odd\:-m-tiny:nth-child(odd) {
    margin: -10px;
  }

  .print\:odd\:-m-small:nth-child(odd) {
    margin: -20px;
  }

  .print\:odd\:-m-medium:nth-child(odd) {
    margin: -30px;
  }

  .print\:odd\:-m-large:nth-child(odd) {
    margin: -40px;
  }

  .print\:odd\:-m-huge:nth-child(odd) {
    margin: -60px;
  }

  .print\:odd\:-m-margin-label-height:nth-child(odd) {
    margin: -26px;
  }

  .print\:odd\:-m-nav-height:nth-child(odd) {
    margin: -80px;
  }

  .print\:odd\:my-1:nth-child(odd) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:odd\:mx-1:nth-child(odd) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:odd\:my-2:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:odd\:mx-2:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:odd\:my-3:nth-child(odd) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:odd\:mx-3:nth-child(odd) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:odd\:my-4:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:odd\:mx-4:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:odd\:my-5:nth-child(odd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:odd\:mx-5:nth-child(odd) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:odd\:my-6:nth-child(odd) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:odd\:mx-6:nth-child(odd) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:odd\:my-7:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:odd\:mx-7:nth-child(odd) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:odd\:my-auto:nth-child(odd) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:odd\:mx-auto:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:odd\:my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:odd\:mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:odd\:my-tiny:nth-child(odd) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:odd\:mx-tiny:nth-child(odd) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:odd\:my-small:nth-child(odd) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:odd\:mx-small:nth-child(odd) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:odd\:my-medium:nth-child(odd) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:odd\:mx-medium:nth-child(odd) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:odd\:my-large:nth-child(odd) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:odd\:mx-large:nth-child(odd) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:odd\:my-huge:nth-child(odd) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:odd\:mx-huge:nth-child(odd) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:odd\:my-margin-label-height:nth-child(odd) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:odd\:mx-margin-label-height:nth-child(odd) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:odd\:my-nav-height:nth-child(odd) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:odd\:mx-nav-height:nth-child(odd) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:odd\:-my-1:nth-child(odd) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:odd\:-mx-1:nth-child(odd) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:odd\:-my-2:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:odd\:-mx-2:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:odd\:-my-3:nth-child(odd) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:odd\:-mx-3:nth-child(odd) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:odd\:-my-4:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:odd\:-mx-4:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:odd\:-my-5:nth-child(odd) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:odd\:-mx-5:nth-child(odd) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:odd\:-my-6:nth-child(odd) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:odd\:-mx-6:nth-child(odd) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:odd\:-my-7:nth-child(odd) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:odd\:-mx-7:nth-child(odd) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:odd\:-my-none:nth-child(odd) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:odd\:-mx-none:nth-child(odd) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:odd\:-my-tiny:nth-child(odd) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:odd\:-mx-tiny:nth-child(odd) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:odd\:-my-small:nth-child(odd) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:odd\:-mx-small:nth-child(odd) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:odd\:-my-medium:nth-child(odd) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:odd\:-mx-medium:nth-child(odd) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:odd\:-my-large:nth-child(odd) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:odd\:-mx-large:nth-child(odd) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:odd\:-my-huge:nth-child(odd) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:odd\:-mx-huge:nth-child(odd) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:odd\:-my-margin-label-height:nth-child(odd) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:odd\:-mx-margin-label-height:nth-child(odd) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:odd\:-my-nav-height:nth-child(odd) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:odd\:-mx-nav-height:nth-child(odd) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:odd\:mt-1:nth-child(odd) {
    margin-top: 5px;
  }

  .print\:odd\:mr-1:nth-child(odd) {
    margin-right: 5px;
  }

  .print\:odd\:mb-1:nth-child(odd) {
    margin-bottom: 5px;
  }

  .print\:odd\:ml-1:nth-child(odd) {
    margin-left: 5px;
  }

  .print\:odd\:mt-2:nth-child(odd) {
    margin-top: 10px;
  }

  .print\:odd\:mr-2:nth-child(odd) {
    margin-right: 10px;
  }

  .print\:odd\:mb-2:nth-child(odd) {
    margin-bottom: 10px;
  }

  .print\:odd\:ml-2:nth-child(odd) {
    margin-left: 10px;
  }

  .print\:odd\:mt-3:nth-child(odd) {
    margin-top: 15px;
  }

  .print\:odd\:mr-3:nth-child(odd) {
    margin-right: 15px;
  }

  .print\:odd\:mb-3:nth-child(odd) {
    margin-bottom: 15px;
  }

  .print\:odd\:ml-3:nth-child(odd) {
    margin-left: 15px;
  }

  .print\:odd\:mt-4:nth-child(odd) {
    margin-top: 30px;
  }

  .print\:odd\:mr-4:nth-child(odd) {
    margin-right: 30px;
  }

  .print\:odd\:mb-4:nth-child(odd) {
    margin-bottom: 30px;
  }

  .print\:odd\:ml-4:nth-child(odd) {
    margin-left: 30px;
  }

  .print\:odd\:mt-5:nth-child(odd) {
    margin-top: 50px;
  }

  .print\:odd\:mr-5:nth-child(odd) {
    margin-right: 50px;
  }

  .print\:odd\:mb-5:nth-child(odd) {
    margin-bottom: 50px;
  }

  .print\:odd\:ml-5:nth-child(odd) {
    margin-left: 50px;
  }

  .print\:odd\:mt-6:nth-child(odd) {
    margin-top: 75px;
  }

  .print\:odd\:mr-6:nth-child(odd) {
    margin-right: 75px;
  }

  .print\:odd\:mb-6:nth-child(odd) {
    margin-bottom: 75px;
  }

  .print\:odd\:ml-6:nth-child(odd) {
    margin-left: 75px;
  }

  .print\:odd\:mt-7:nth-child(odd) {
    margin-top: 100px;
  }

  .print\:odd\:mr-7:nth-child(odd) {
    margin-right: 100px;
  }

  .print\:odd\:mb-7:nth-child(odd) {
    margin-bottom: 100px;
  }

  .print\:odd\:ml-7:nth-child(odd) {
    margin-left: 100px;
  }

  .print\:odd\:mt-auto:nth-child(odd) {
    margin-top: auto;
  }

  .print\:odd\:mr-auto:nth-child(odd) {
    margin-right: auto;
  }

  .print\:odd\:mb-auto:nth-child(odd) {
    margin-bottom: auto;
  }

  .print\:odd\:ml-auto:nth-child(odd) {
    margin-left: auto;
  }

  .print\:odd\:mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .print\:odd\:mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .print\:odd\:mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .print\:odd\:ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .print\:odd\:mt-tiny:nth-child(odd) {
    margin-top: 10px;
  }

  .print\:odd\:mr-tiny:nth-child(odd) {
    margin-right: 10px;
  }

  .print\:odd\:mb-tiny:nth-child(odd) {
    margin-bottom: 10px;
  }

  .print\:odd\:ml-tiny:nth-child(odd) {
    margin-left: 10px;
  }

  .print\:odd\:mt-small:nth-child(odd) {
    margin-top: 20px;
  }

  .print\:odd\:mr-small:nth-child(odd) {
    margin-right: 20px;
  }

  .print\:odd\:mb-small:nth-child(odd) {
    margin-bottom: 20px;
  }

  .print\:odd\:ml-small:nth-child(odd) {
    margin-left: 20px;
  }

  .print\:odd\:mt-medium:nth-child(odd) {
    margin-top: 30px;
  }

  .print\:odd\:mr-medium:nth-child(odd) {
    margin-right: 30px;
  }

  .print\:odd\:mb-medium:nth-child(odd) {
    margin-bottom: 30px;
  }

  .print\:odd\:ml-medium:nth-child(odd) {
    margin-left: 30px;
  }

  .print\:odd\:mt-large:nth-child(odd) {
    margin-top: 40px;
  }

  .print\:odd\:mr-large:nth-child(odd) {
    margin-right: 40px;
  }

  .print\:odd\:mb-large:nth-child(odd) {
    margin-bottom: 40px;
  }

  .print\:odd\:ml-large:nth-child(odd) {
    margin-left: 40px;
  }

  .print\:odd\:mt-huge:nth-child(odd) {
    margin-top: 60px;
  }

  .print\:odd\:mr-huge:nth-child(odd) {
    margin-right: 60px;
  }

  .print\:odd\:mb-huge:nth-child(odd) {
    margin-bottom: 60px;
  }

  .print\:odd\:ml-huge:nth-child(odd) {
    margin-left: 60px;
  }

  .print\:odd\:mt-margin-label-height:nth-child(odd) {
    margin-top: 26px;
  }

  .print\:odd\:mr-margin-label-height:nth-child(odd) {
    margin-right: 26px;
  }

  .print\:odd\:mb-margin-label-height:nth-child(odd) {
    margin-bottom: 26px;
  }

  .print\:odd\:ml-margin-label-height:nth-child(odd) {
    margin-left: 26px;
  }

  .print\:odd\:mt-nav-height:nth-child(odd) {
    margin-top: 80px;
  }

  .print\:odd\:mr-nav-height:nth-child(odd) {
    margin-right: 80px;
  }

  .print\:odd\:mb-nav-height:nth-child(odd) {
    margin-bottom: 80px;
  }

  .print\:odd\:ml-nav-height:nth-child(odd) {
    margin-left: 80px;
  }

  .print\:odd\:-mt-1:nth-child(odd) {
    margin-top: -5px;
  }

  .print\:odd\:-mr-1:nth-child(odd) {
    margin-right: -5px;
  }

  .print\:odd\:-mb-1:nth-child(odd) {
    margin-bottom: -5px;
  }

  .print\:odd\:-ml-1:nth-child(odd) {
    margin-left: -5px;
  }

  .print\:odd\:-mt-2:nth-child(odd) {
    margin-top: -10px;
  }

  .print\:odd\:-mr-2:nth-child(odd) {
    margin-right: -10px;
  }

  .print\:odd\:-mb-2:nth-child(odd) {
    margin-bottom: -10px;
  }

  .print\:odd\:-ml-2:nth-child(odd) {
    margin-left: -10px;
  }

  .print\:odd\:-mt-3:nth-child(odd) {
    margin-top: -15px;
  }

  .print\:odd\:-mr-3:nth-child(odd) {
    margin-right: -15px;
  }

  .print\:odd\:-mb-3:nth-child(odd) {
    margin-bottom: -15px;
  }

  .print\:odd\:-ml-3:nth-child(odd) {
    margin-left: -15px;
  }

  .print\:odd\:-mt-4:nth-child(odd) {
    margin-top: -30px;
  }

  .print\:odd\:-mr-4:nth-child(odd) {
    margin-right: -30px;
  }

  .print\:odd\:-mb-4:nth-child(odd) {
    margin-bottom: -30px;
  }

  .print\:odd\:-ml-4:nth-child(odd) {
    margin-left: -30px;
  }

  .print\:odd\:-mt-5:nth-child(odd) {
    margin-top: -50px;
  }

  .print\:odd\:-mr-5:nth-child(odd) {
    margin-right: -50px;
  }

  .print\:odd\:-mb-5:nth-child(odd) {
    margin-bottom: -50px;
  }

  .print\:odd\:-ml-5:nth-child(odd) {
    margin-left: -50px;
  }

  .print\:odd\:-mt-6:nth-child(odd) {
    margin-top: -75px;
  }

  .print\:odd\:-mr-6:nth-child(odd) {
    margin-right: -75px;
  }

  .print\:odd\:-mb-6:nth-child(odd) {
    margin-bottom: -75px;
  }

  .print\:odd\:-ml-6:nth-child(odd) {
    margin-left: -75px;
  }

  .print\:odd\:-mt-7:nth-child(odd) {
    margin-top: -100px;
  }

  .print\:odd\:-mr-7:nth-child(odd) {
    margin-right: -100px;
  }

  .print\:odd\:-mb-7:nth-child(odd) {
    margin-bottom: -100px;
  }

  .print\:odd\:-ml-7:nth-child(odd) {
    margin-left: -100px;
  }

  .print\:odd\:-mt-none:nth-child(odd) {
    margin-top: 0px;
  }

  .print\:odd\:-mr-none:nth-child(odd) {
    margin-right: 0px;
  }

  .print\:odd\:-mb-none:nth-child(odd) {
    margin-bottom: 0px;
  }

  .print\:odd\:-ml-none:nth-child(odd) {
    margin-left: 0px;
  }

  .print\:odd\:-mt-tiny:nth-child(odd) {
    margin-top: -10px;
  }

  .print\:odd\:-mr-tiny:nth-child(odd) {
    margin-right: -10px;
  }

  .print\:odd\:-mb-tiny:nth-child(odd) {
    margin-bottom: -10px;
  }

  .print\:odd\:-ml-tiny:nth-child(odd) {
    margin-left: -10px;
  }

  .print\:odd\:-mt-small:nth-child(odd) {
    margin-top: -20px;
  }

  .print\:odd\:-mr-small:nth-child(odd) {
    margin-right: -20px;
  }

  .print\:odd\:-mb-small:nth-child(odd) {
    margin-bottom: -20px;
  }

  .print\:odd\:-ml-small:nth-child(odd) {
    margin-left: -20px;
  }

  .print\:odd\:-mt-medium:nth-child(odd) {
    margin-top: -30px;
  }

  .print\:odd\:-mr-medium:nth-child(odd) {
    margin-right: -30px;
  }

  .print\:odd\:-mb-medium:nth-child(odd) {
    margin-bottom: -30px;
  }

  .print\:odd\:-ml-medium:nth-child(odd) {
    margin-left: -30px;
  }

  .print\:odd\:-mt-large:nth-child(odd) {
    margin-top: -40px;
  }

  .print\:odd\:-mr-large:nth-child(odd) {
    margin-right: -40px;
  }

  .print\:odd\:-mb-large:nth-child(odd) {
    margin-bottom: -40px;
  }

  .print\:odd\:-ml-large:nth-child(odd) {
    margin-left: -40px;
  }

  .print\:odd\:-mt-huge:nth-child(odd) {
    margin-top: -60px;
  }

  .print\:odd\:-mr-huge:nth-child(odd) {
    margin-right: -60px;
  }

  .print\:odd\:-mb-huge:nth-child(odd) {
    margin-bottom: -60px;
  }

  .print\:odd\:-ml-huge:nth-child(odd) {
    margin-left: -60px;
  }

  .print\:odd\:-mt-margin-label-height:nth-child(odd) {
    margin-top: -26px;
  }

  .print\:odd\:-mr-margin-label-height:nth-child(odd) {
    margin-right: -26px;
  }

  .print\:odd\:-mb-margin-label-height:nth-child(odd) {
    margin-bottom: -26px;
  }

  .print\:odd\:-ml-margin-label-height:nth-child(odd) {
    margin-left: -26px;
  }

  .print\:odd\:-mt-nav-height:nth-child(odd) {
    margin-top: -80px;
  }

  .print\:odd\:-mr-nav-height:nth-child(odd) {
    margin-right: -80px;
  }

  .print\:odd\:-mb-nav-height:nth-child(odd) {
    margin-bottom: -80px;
  }

  .print\:odd\:-ml-nav-height:nth-child(odd) {
    margin-left: -80px;
  }

  .print\:even\:m-1:nth-child(even) {
    margin: 5px;
  }

  .print\:even\:m-2:nth-child(even) {
    margin: 10px;
  }

  .print\:even\:m-3:nth-child(even) {
    margin: 15px;
  }

  .print\:even\:m-4:nth-child(even) {
    margin: 30px;
  }

  .print\:even\:m-5:nth-child(even) {
    margin: 50px;
  }

  .print\:even\:m-6:nth-child(even) {
    margin: 75px;
  }

  .print\:even\:m-7:nth-child(even) {
    margin: 100px;
  }

  .print\:even\:m-auto:nth-child(even) {
    margin: auto;
  }

  .print\:even\:m-none:nth-child(even) {
    margin: 0px;
  }

  .print\:even\:m-tiny:nth-child(even) {
    margin: 10px;
  }

  .print\:even\:m-small:nth-child(even) {
    margin: 20px;
  }

  .print\:even\:m-medium:nth-child(even) {
    margin: 30px;
  }

  .print\:even\:m-large:nth-child(even) {
    margin: 40px;
  }

  .print\:even\:m-huge:nth-child(even) {
    margin: 60px;
  }

  .print\:even\:m-margin-label-height:nth-child(even) {
    margin: 26px;
  }

  .print\:even\:m-nav-height:nth-child(even) {
    margin: 80px;
  }

  .print\:even\:-m-1:nth-child(even) {
    margin: -5px;
  }

  .print\:even\:-m-2:nth-child(even) {
    margin: -10px;
  }

  .print\:even\:-m-3:nth-child(even) {
    margin: -15px;
  }

  .print\:even\:-m-4:nth-child(even) {
    margin: -30px;
  }

  .print\:even\:-m-5:nth-child(even) {
    margin: -50px;
  }

  .print\:even\:-m-6:nth-child(even) {
    margin: -75px;
  }

  .print\:even\:-m-7:nth-child(even) {
    margin: -100px;
  }

  .print\:even\:-m-none:nth-child(even) {
    margin: 0px;
  }

  .print\:even\:-m-tiny:nth-child(even) {
    margin: -10px;
  }

  .print\:even\:-m-small:nth-child(even) {
    margin: -20px;
  }

  .print\:even\:-m-medium:nth-child(even) {
    margin: -30px;
  }

  .print\:even\:-m-large:nth-child(even) {
    margin: -40px;
  }

  .print\:even\:-m-huge:nth-child(even) {
    margin: -60px;
  }

  .print\:even\:-m-margin-label-height:nth-child(even) {
    margin: -26px;
  }

  .print\:even\:-m-nav-height:nth-child(even) {
    margin: -80px;
  }

  .print\:even\:my-1:nth-child(even) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .print\:even\:mx-1:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
  }

  .print\:even\:my-2:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:even\:mx-2:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:even\:my-3:nth-child(even) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .print\:even\:mx-3:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .print\:even\:my-4:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:even\:mx-4:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:even\:my-5:nth-child(even) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .print\:even\:mx-5:nth-child(even) {
    margin-left: 50px;
    margin-right: 50px;
  }

  .print\:even\:my-6:nth-child(even) {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .print\:even\:mx-6:nth-child(even) {
    margin-left: 75px;
    margin-right: 75px;
  }

  .print\:even\:my-7:nth-child(even) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .print\:even\:mx-7:nth-child(even) {
    margin-left: 100px;
    margin-right: 100px;
  }

  .print\:even\:my-auto:nth-child(even) {
    margin-top: auto;
    margin-bottom: auto;
  }

  .print\:even\:mx-auto:nth-child(even) {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:even\:my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:even\:mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:even\:my-tiny:nth-child(even) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .print\:even\:mx-tiny:nth-child(even) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .print\:even\:my-small:nth-child(even) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .print\:even\:mx-small:nth-child(even) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print\:even\:my-medium:nth-child(even) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print\:even\:mx-medium:nth-child(even) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .print\:even\:my-large:nth-child(even) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .print\:even\:mx-large:nth-child(even) {
    margin-left: 40px;
    margin-right: 40px;
  }

  .print\:even\:my-huge:nth-child(even) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .print\:even\:mx-huge:nth-child(even) {
    margin-left: 60px;
    margin-right: 60px;
  }

  .print\:even\:my-margin-label-height:nth-child(even) {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .print\:even\:mx-margin-label-height:nth-child(even) {
    margin-left: 26px;
    margin-right: 26px;
  }

  .print\:even\:my-nav-height:nth-child(even) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .print\:even\:mx-nav-height:nth-child(even) {
    margin-left: 80px;
    margin-right: 80px;
  }

  .print\:even\:-my-1:nth-child(even) {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .print\:even\:-mx-1:nth-child(even) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .print\:even\:-my-2:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:even\:-mx-2:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:even\:-my-3:nth-child(even) {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .print\:even\:-mx-3:nth-child(even) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .print\:even\:-my-4:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:even\:-mx-4:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:even\:-my-5:nth-child(even) {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .print\:even\:-mx-5:nth-child(even) {
    margin-left: -50px;
    margin-right: -50px;
  }

  .print\:even\:-my-6:nth-child(even) {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .print\:even\:-mx-6:nth-child(even) {
    margin-left: -75px;
    margin-right: -75px;
  }

  .print\:even\:-my-7:nth-child(even) {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .print\:even\:-mx-7:nth-child(even) {
    margin-left: -100px;
    margin-right: -100px;
  }

  .print\:even\:-my-none:nth-child(even) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .print\:even\:-mx-none:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .print\:even\:-my-tiny:nth-child(even) {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .print\:even\:-mx-tiny:nth-child(even) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .print\:even\:-my-small:nth-child(even) {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .print\:even\:-mx-small:nth-child(even) {
    margin-left: -20px;
    margin-right: -20px;
  }

  .print\:even\:-my-medium:nth-child(even) {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .print\:even\:-mx-medium:nth-child(even) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .print\:even\:-my-large:nth-child(even) {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .print\:even\:-mx-large:nth-child(even) {
    margin-left: -40px;
    margin-right: -40px;
  }

  .print\:even\:-my-huge:nth-child(even) {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .print\:even\:-mx-huge:nth-child(even) {
    margin-left: -60px;
    margin-right: -60px;
  }

  .print\:even\:-my-margin-label-height:nth-child(even) {
    margin-top: -26px;
    margin-bottom: -26px;
  }

  .print\:even\:-mx-margin-label-height:nth-child(even) {
    margin-left: -26px;
    margin-right: -26px;
  }

  .print\:even\:-my-nav-height:nth-child(even) {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .print\:even\:-mx-nav-height:nth-child(even) {
    margin-left: -80px;
    margin-right: -80px;
  }

  .print\:even\:mt-1:nth-child(even) {
    margin-top: 5px;
  }

  .print\:even\:mr-1:nth-child(even) {
    margin-right: 5px;
  }

  .print\:even\:mb-1:nth-child(even) {
    margin-bottom: 5px;
  }

  .print\:even\:ml-1:nth-child(even) {
    margin-left: 5px;
  }

  .print\:even\:mt-2:nth-child(even) {
    margin-top: 10px;
  }

  .print\:even\:mr-2:nth-child(even) {
    margin-right: 10px;
  }

  .print\:even\:mb-2:nth-child(even) {
    margin-bottom: 10px;
  }

  .print\:even\:ml-2:nth-child(even) {
    margin-left: 10px;
  }

  .print\:even\:mt-3:nth-child(even) {
    margin-top: 15px;
  }

  .print\:even\:mr-3:nth-child(even) {
    margin-right: 15px;
  }

  .print\:even\:mb-3:nth-child(even) {
    margin-bottom: 15px;
  }

  .print\:even\:ml-3:nth-child(even) {
    margin-left: 15px;
  }

  .print\:even\:mt-4:nth-child(even) {
    margin-top: 30px;
  }

  .print\:even\:mr-4:nth-child(even) {
    margin-right: 30px;
  }

  .print\:even\:mb-4:nth-child(even) {
    margin-bottom: 30px;
  }

  .print\:even\:ml-4:nth-child(even) {
    margin-left: 30px;
  }

  .print\:even\:mt-5:nth-child(even) {
    margin-top: 50px;
  }

  .print\:even\:mr-5:nth-child(even) {
    margin-right: 50px;
  }

  .print\:even\:mb-5:nth-child(even) {
    margin-bottom: 50px;
  }

  .print\:even\:ml-5:nth-child(even) {
    margin-left: 50px;
  }

  .print\:even\:mt-6:nth-child(even) {
    margin-top: 75px;
  }

  .print\:even\:mr-6:nth-child(even) {
    margin-right: 75px;
  }

  .print\:even\:mb-6:nth-child(even) {
    margin-bottom: 75px;
  }

  .print\:even\:ml-6:nth-child(even) {
    margin-left: 75px;
  }

  .print\:even\:mt-7:nth-child(even) {
    margin-top: 100px;
  }

  .print\:even\:mr-7:nth-child(even) {
    margin-right: 100px;
  }

  .print\:even\:mb-7:nth-child(even) {
    margin-bottom: 100px;
  }

  .print\:even\:ml-7:nth-child(even) {
    margin-left: 100px;
  }

  .print\:even\:mt-auto:nth-child(even) {
    margin-top: auto;
  }

  .print\:even\:mr-auto:nth-child(even) {
    margin-right: auto;
  }

  .print\:even\:mb-auto:nth-child(even) {
    margin-bottom: auto;
  }

  .print\:even\:ml-auto:nth-child(even) {
    margin-left: auto;
  }

  .print\:even\:mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .print\:even\:mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .print\:even\:mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .print\:even\:ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .print\:even\:mt-tiny:nth-child(even) {
    margin-top: 10px;
  }

  .print\:even\:mr-tiny:nth-child(even) {
    margin-right: 10px;
  }

  .print\:even\:mb-tiny:nth-child(even) {
    margin-bottom: 10px;
  }

  .print\:even\:ml-tiny:nth-child(even) {
    margin-left: 10px;
  }

  .print\:even\:mt-small:nth-child(even) {
    margin-top: 20px;
  }

  .print\:even\:mr-small:nth-child(even) {
    margin-right: 20px;
  }

  .print\:even\:mb-small:nth-child(even) {
    margin-bottom: 20px;
  }

  .print\:even\:ml-small:nth-child(even) {
    margin-left: 20px;
  }

  .print\:even\:mt-medium:nth-child(even) {
    margin-top: 30px;
  }

  .print\:even\:mr-medium:nth-child(even) {
    margin-right: 30px;
  }

  .print\:even\:mb-medium:nth-child(even) {
    margin-bottom: 30px;
  }

  .print\:even\:ml-medium:nth-child(even) {
    margin-left: 30px;
  }

  .print\:even\:mt-large:nth-child(even) {
    margin-top: 40px;
  }

  .print\:even\:mr-large:nth-child(even) {
    margin-right: 40px;
  }

  .print\:even\:mb-large:nth-child(even) {
    margin-bottom: 40px;
  }

  .print\:even\:ml-large:nth-child(even) {
    margin-left: 40px;
  }

  .print\:even\:mt-huge:nth-child(even) {
    margin-top: 60px;
  }

  .print\:even\:mr-huge:nth-child(even) {
    margin-right: 60px;
  }

  .print\:even\:mb-huge:nth-child(even) {
    margin-bottom: 60px;
  }

  .print\:even\:ml-huge:nth-child(even) {
    margin-left: 60px;
  }

  .print\:even\:mt-margin-label-height:nth-child(even) {
    margin-top: 26px;
  }

  .print\:even\:mr-margin-label-height:nth-child(even) {
    margin-right: 26px;
  }

  .print\:even\:mb-margin-label-height:nth-child(even) {
    margin-bottom: 26px;
  }

  .print\:even\:ml-margin-label-height:nth-child(even) {
    margin-left: 26px;
  }

  .print\:even\:mt-nav-height:nth-child(even) {
    margin-top: 80px;
  }

  .print\:even\:mr-nav-height:nth-child(even) {
    margin-right: 80px;
  }

  .print\:even\:mb-nav-height:nth-child(even) {
    margin-bottom: 80px;
  }

  .print\:even\:ml-nav-height:nth-child(even) {
    margin-left: 80px;
  }

  .print\:even\:-mt-1:nth-child(even) {
    margin-top: -5px;
  }

  .print\:even\:-mr-1:nth-child(even) {
    margin-right: -5px;
  }

  .print\:even\:-mb-1:nth-child(even) {
    margin-bottom: -5px;
  }

  .print\:even\:-ml-1:nth-child(even) {
    margin-left: -5px;
  }

  .print\:even\:-mt-2:nth-child(even) {
    margin-top: -10px;
  }

  .print\:even\:-mr-2:nth-child(even) {
    margin-right: -10px;
  }

  .print\:even\:-mb-2:nth-child(even) {
    margin-bottom: -10px;
  }

  .print\:even\:-ml-2:nth-child(even) {
    margin-left: -10px;
  }

  .print\:even\:-mt-3:nth-child(even) {
    margin-top: -15px;
  }

  .print\:even\:-mr-3:nth-child(even) {
    margin-right: -15px;
  }

  .print\:even\:-mb-3:nth-child(even) {
    margin-bottom: -15px;
  }

  .print\:even\:-ml-3:nth-child(even) {
    margin-left: -15px;
  }

  .print\:even\:-mt-4:nth-child(even) {
    margin-top: -30px;
  }

  .print\:even\:-mr-4:nth-child(even) {
    margin-right: -30px;
  }

  .print\:even\:-mb-4:nth-child(even) {
    margin-bottom: -30px;
  }

  .print\:even\:-ml-4:nth-child(even) {
    margin-left: -30px;
  }

  .print\:even\:-mt-5:nth-child(even) {
    margin-top: -50px;
  }

  .print\:even\:-mr-5:nth-child(even) {
    margin-right: -50px;
  }

  .print\:even\:-mb-5:nth-child(even) {
    margin-bottom: -50px;
  }

  .print\:even\:-ml-5:nth-child(even) {
    margin-left: -50px;
  }

  .print\:even\:-mt-6:nth-child(even) {
    margin-top: -75px;
  }

  .print\:even\:-mr-6:nth-child(even) {
    margin-right: -75px;
  }

  .print\:even\:-mb-6:nth-child(even) {
    margin-bottom: -75px;
  }

  .print\:even\:-ml-6:nth-child(even) {
    margin-left: -75px;
  }

  .print\:even\:-mt-7:nth-child(even) {
    margin-top: -100px;
  }

  .print\:even\:-mr-7:nth-child(even) {
    margin-right: -100px;
  }

  .print\:even\:-mb-7:nth-child(even) {
    margin-bottom: -100px;
  }

  .print\:even\:-ml-7:nth-child(even) {
    margin-left: -100px;
  }

  .print\:even\:-mt-none:nth-child(even) {
    margin-top: 0px;
  }

  .print\:even\:-mr-none:nth-child(even) {
    margin-right: 0px;
  }

  .print\:even\:-mb-none:nth-child(even) {
    margin-bottom: 0px;
  }

  .print\:even\:-ml-none:nth-child(even) {
    margin-left: 0px;
  }

  .print\:even\:-mt-tiny:nth-child(even) {
    margin-top: -10px;
  }

  .print\:even\:-mr-tiny:nth-child(even) {
    margin-right: -10px;
  }

  .print\:even\:-mb-tiny:nth-child(even) {
    margin-bottom: -10px;
  }

  .print\:even\:-ml-tiny:nth-child(even) {
    margin-left: -10px;
  }

  .print\:even\:-mt-small:nth-child(even) {
    margin-top: -20px;
  }

  .print\:even\:-mr-small:nth-child(even) {
    margin-right: -20px;
  }

  .print\:even\:-mb-small:nth-child(even) {
    margin-bottom: -20px;
  }

  .print\:even\:-ml-small:nth-child(even) {
    margin-left: -20px;
  }

  .print\:even\:-mt-medium:nth-child(even) {
    margin-top: -30px;
  }

  .print\:even\:-mr-medium:nth-child(even) {
    margin-right: -30px;
  }

  .print\:even\:-mb-medium:nth-child(even) {
    margin-bottom: -30px;
  }

  .print\:even\:-ml-medium:nth-child(even) {
    margin-left: -30px;
  }

  .print\:even\:-mt-large:nth-child(even) {
    margin-top: -40px;
  }

  .print\:even\:-mr-large:nth-child(even) {
    margin-right: -40px;
  }

  .print\:even\:-mb-large:nth-child(even) {
    margin-bottom: -40px;
  }

  .print\:even\:-ml-large:nth-child(even) {
    margin-left: -40px;
  }

  .print\:even\:-mt-huge:nth-child(even) {
    margin-top: -60px;
  }

  .print\:even\:-mr-huge:nth-child(even) {
    margin-right: -60px;
  }

  .print\:even\:-mb-huge:nth-child(even) {
    margin-bottom: -60px;
  }

  .print\:even\:-ml-huge:nth-child(even) {
    margin-left: -60px;
  }

  .print\:even\:-mt-margin-label-height:nth-child(even) {
    margin-top: -26px;
  }

  .print\:even\:-mr-margin-label-height:nth-child(even) {
    margin-right: -26px;
  }

  .print\:even\:-mb-margin-label-height:nth-child(even) {
    margin-bottom: -26px;
  }

  .print\:even\:-ml-margin-label-height:nth-child(even) {
    margin-left: -26px;
  }

  .print\:even\:-mt-nav-height:nth-child(even) {
    margin-top: -80px;
  }

  .print\:even\:-mr-nav-height:nth-child(even) {
    margin-right: -80px;
  }

  .print\:even\:-mb-nav-height:nth-child(even) {
    margin-bottom: -80px;
  }

  .print\:even\:-ml-nav-height:nth-child(even) {
    margin-left: -80px;
  }

  .print\:max-h-full {
    max-height: 100%;
  }

  .print\:max-h-screen {
    max-height: 100vh;
  }

  .print\:max-w-none {
    max-width: none;
  }

  .print\:max-w-xs {
    max-width: 20rem;
  }

  .print\:max-w-sm {
    max-width: 24rem;
  }

  .print\:max-w-md {
    max-width: 28rem;
  }

  .print\:max-w-lg {
    max-width: 32rem;
  }

  .print\:max-w-xl {
    max-width: 36rem;
  }

  .print\:max-w-2xl {
    max-width: 42rem;
  }

  .print\:max-w-3xl {
    max-width: 48rem;
  }

  .print\:max-w-4xl {
    max-width: 56rem;
  }

  .print\:max-w-5xl {
    max-width: 64rem;
  }

  .print\:max-w-6xl {
    max-width: 72rem;
  }

  .print\:max-w-full {
    max-width: 100%;
  }

  .print\:max-w-screen-tablet {
    max-width: 801px;
  }

  .print\:max-w-screen-desktop {
    max-width: 1024px;
  }

  .print\:max-w-screen-widescreen {
    max-width: 1216px;
  }

  .print\:max-w-screen-fullhd {
    max-width: 1408px;
  }

  .print\:min-h-0 {
    min-height: 0;
  }

  .print\:min-h-full {
    min-height: 100%;
  }

  .print\:min-h-screen {
    min-height: 100vh;
  }

  .print\:min-w-0 {
    min-width: 0;
  }

  .print\:min-w-full {
    min-width: 100%;
  }

  .print\:object-contain {
    object-fit: contain;
  }

  .print\:object-cover {
    object-fit: cover;
  }

  .print\:object-fill {
    object-fit: fill;
  }

  .print\:object-none {
    object-fit: none;
  }

  .print\:object-scale-down {
    object-fit: scale-down;
  }

  .print\:object-bottom {
    object-position: bottom;
  }

  .print\:object-center {
    object-position: center;
  }

  .print\:object-left {
    object-position: left;
  }

  .print\:object-left-bottom {
    object-position: left bottom;
  }

  .print\:object-left-top {
    object-position: left top;
  }

  .print\:object-right {
    object-position: right;
  }

  .print\:object-right-bottom {
    object-position: right bottom;
  }

  .print\:object-right-top {
    object-position: right top;
  }

  .print\:object-top {
    object-position: top;
  }

  .print\:opacity-0 {
    opacity: 0;
  }

  .print\:opacity-25 {
    opacity: 0.25;
  }

  .print\:opacity-50 {
    opacity: 0.5;
  }

  .print\:opacity-75 {
    opacity: 0.75;
  }

  .print\:opacity-100 {
    opacity: 1;
  }

  .print\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .print\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .print\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .print\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .print\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .print\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .print\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .print\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .print\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .print\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .print\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .print\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .print\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .print\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .print\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .print\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .print\:overflow-auto {
    overflow: auto;
  }

  .print\:overflow-hidden {
    overflow: hidden;
  }

  .print\:overflow-visible {
    overflow: visible;
  }

  .print\:overflow-scroll {
    overflow: scroll;
  }

  .print\:overflow-x-auto {
    overflow-x: auto;
  }

  .print\:overflow-y-auto {
    overflow-y: auto;
  }

  .print\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .print\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .print\:overflow-x-visible {
    overflow-x: visible;
  }

  .print\:overflow-y-visible {
    overflow-y: visible;
  }

  .print\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .print\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .print\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .print\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .print\:overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .print\:overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .print\:overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .print\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .print\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .print\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .print\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .print\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .print\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .print\:p-1 {
    padding: 5px;
  }

  .print\:p-2 {
    padding: 10px;
  }

  .print\:p-3 {
    padding: 15px;
  }

  .print\:p-4 {
    padding: 30px;
  }

  .print\:p-5 {
    padding: 50px;
  }

  .print\:p-6 {
    padding: 75px;
  }

  .print\:p-7 {
    padding: 100px;
  }

  .print\:p-none {
    padding: 0px;
  }

  .print\:p-tiny {
    padding: 10px;
  }

  .print\:p-small {
    padding: 20px;
  }

  .print\:p-medium {
    padding: 30px;
  }

  .print\:p-large {
    padding: 40px;
  }

  .print\:p-huge {
    padding: 60px;
  }

  .print\:p-margin-label-height {
    padding: 26px;
  }

  .print\:p-nav-height {
    padding: 80px;
  }

  .print\:py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .print\:px-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .print\:py-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:px-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .print\:px-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .print\:py-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:px-4 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:py-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .print\:px-5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .print\:py-6 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .print\:px-6 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .print\:py-7 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .print\:px-7 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .print\:py-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .print\:px-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  .print\:py-tiny {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:px-tiny {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:py-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .print\:px-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  .print\:py-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:px-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:py-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .print\:px-large {
    padding-left: 40px;
    padding-right: 40px;
  }

  .print\:py-huge {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .print\:px-huge {
    padding-left: 60px;
    padding-right: 60px;
  }

  .print\:py-margin-label-height {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .print\:px-margin-label-height {
    padding-left: 26px;
    padding-right: 26px;
  }

  .print\:py-nav-height {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .print\:px-nav-height {
    padding-left: 80px;
    padding-right: 80px;
  }

  .print\:pt-1 {
    padding-top: 5px;
  }

  .print\:pr-1 {
    padding-right: 5px;
  }

  .print\:pb-1 {
    padding-bottom: 5px;
  }

  .print\:pl-1 {
    padding-left: 5px;
  }

  .print\:pt-2 {
    padding-top: 10px;
  }

  .print\:pr-2 {
    padding-right: 10px;
  }

  .print\:pb-2 {
    padding-bottom: 10px;
  }

  .print\:pl-2 {
    padding-left: 10px;
  }

  .print\:pt-3 {
    padding-top: 15px;
  }

  .print\:pr-3 {
    padding-right: 15px;
  }

  .print\:pb-3 {
    padding-bottom: 15px;
  }

  .print\:pl-3 {
    padding-left: 15px;
  }

  .print\:pt-4 {
    padding-top: 30px;
  }

  .print\:pr-4 {
    padding-right: 30px;
  }

  .print\:pb-4 {
    padding-bottom: 30px;
  }

  .print\:pl-4 {
    padding-left: 30px;
  }

  .print\:pt-5 {
    padding-top: 50px;
  }

  .print\:pr-5 {
    padding-right: 50px;
  }

  .print\:pb-5 {
    padding-bottom: 50px;
  }

  .print\:pl-5 {
    padding-left: 50px;
  }

  .print\:pt-6 {
    padding-top: 75px;
  }

  .print\:pr-6 {
    padding-right: 75px;
  }

  .print\:pb-6 {
    padding-bottom: 75px;
  }

  .print\:pl-6 {
    padding-left: 75px;
  }

  .print\:pt-7 {
    padding-top: 100px;
  }

  .print\:pr-7 {
    padding-right: 100px;
  }

  .print\:pb-7 {
    padding-bottom: 100px;
  }

  .print\:pl-7 {
    padding-left: 100px;
  }

  .print\:pt-none {
    padding-top: 0px;
  }

  .print\:pr-none {
    padding-right: 0px;
  }

  .print\:pb-none {
    padding-bottom: 0px;
  }

  .print\:pl-none {
    padding-left: 0px;
  }

  .print\:pt-tiny {
    padding-top: 10px;
  }

  .print\:pr-tiny {
    padding-right: 10px;
  }

  .print\:pb-tiny {
    padding-bottom: 10px;
  }

  .print\:pl-tiny {
    padding-left: 10px;
  }

  .print\:pt-small {
    padding-top: 20px;
  }

  .print\:pr-small {
    padding-right: 20px;
  }

  .print\:pb-small {
    padding-bottom: 20px;
  }

  .print\:pl-small {
    padding-left: 20px;
  }

  .print\:pt-medium {
    padding-top: 30px;
  }

  .print\:pr-medium {
    padding-right: 30px;
  }

  .print\:pb-medium {
    padding-bottom: 30px;
  }

  .print\:pl-medium {
    padding-left: 30px;
  }

  .print\:pt-large {
    padding-top: 40px;
  }

  .print\:pr-large {
    padding-right: 40px;
  }

  .print\:pb-large {
    padding-bottom: 40px;
  }

  .print\:pl-large {
    padding-left: 40px;
  }

  .print\:pt-huge {
    padding-top: 60px;
  }

  .print\:pr-huge {
    padding-right: 60px;
  }

  .print\:pb-huge {
    padding-bottom: 60px;
  }

  .print\:pl-huge {
    padding-left: 60px;
  }

  .print\:pt-margin-label-height {
    padding-top: 26px;
  }

  .print\:pr-margin-label-height {
    padding-right: 26px;
  }

  .print\:pb-margin-label-height {
    padding-bottom: 26px;
  }

  .print\:pl-margin-label-height {
    padding-left: 26px;
  }

  .print\:pt-nav-height {
    padding-top: 80px;
  }

  .print\:pr-nav-height {
    padding-right: 80px;
  }

  .print\:pb-nav-height {
    padding-bottom: 80px;
  }

  .print\:pl-nav-height {
    padding-left: 80px;
  }

  .print\:hover\:p-1:hover {
    padding: 5px;
  }

  .print\:hover\:p-2:hover {
    padding: 10px;
  }

  .print\:hover\:p-3:hover {
    padding: 15px;
  }

  .print\:hover\:p-4:hover {
    padding: 30px;
  }

  .print\:hover\:p-5:hover {
    padding: 50px;
  }

  .print\:hover\:p-6:hover {
    padding: 75px;
  }

  .print\:hover\:p-7:hover {
    padding: 100px;
  }

  .print\:hover\:p-none:hover {
    padding: 0px;
  }

  .print\:hover\:p-tiny:hover {
    padding: 10px;
  }

  .print\:hover\:p-small:hover {
    padding: 20px;
  }

  .print\:hover\:p-medium:hover {
    padding: 30px;
  }

  .print\:hover\:p-large:hover {
    padding: 40px;
  }

  .print\:hover\:p-huge:hover {
    padding: 60px;
  }

  .print\:hover\:p-margin-label-height:hover {
    padding: 26px;
  }

  .print\:hover\:p-nav-height:hover {
    padding: 80px;
  }

  .print\:hover\:py-1:hover {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .print\:hover\:px-1:hover {
    padding-left: 5px;
    padding-right: 5px;
  }

  .print\:hover\:py-2:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:hover\:px-2:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:hover\:py-3:hover {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .print\:hover\:px-3:hover {
    padding-left: 15px;
    padding-right: 15px;
  }

  .print\:hover\:py-4:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:hover\:px-4:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:hover\:py-5:hover {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .print\:hover\:px-5:hover {
    padding-left: 50px;
    padding-right: 50px;
  }

  .print\:hover\:py-6:hover {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .print\:hover\:px-6:hover {
    padding-left: 75px;
    padding-right: 75px;
  }

  .print\:hover\:py-7:hover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .print\:hover\:px-7:hover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .print\:hover\:py-none:hover {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .print\:hover\:px-none:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  .print\:hover\:py-tiny:hover {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:hover\:px-tiny:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:hover\:py-small:hover {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .print\:hover\:px-small:hover {
    padding-left: 20px;
    padding-right: 20px;
  }

  .print\:hover\:py-medium:hover {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:hover\:px-medium:hover {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:hover\:py-large:hover {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .print\:hover\:px-large:hover {
    padding-left: 40px;
    padding-right: 40px;
  }

  .print\:hover\:py-huge:hover {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .print\:hover\:px-huge:hover {
    padding-left: 60px;
    padding-right: 60px;
  }

  .print\:hover\:py-margin-label-height:hover {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .print\:hover\:px-margin-label-height:hover {
    padding-left: 26px;
    padding-right: 26px;
  }

  .print\:hover\:py-nav-height:hover {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .print\:hover\:px-nav-height:hover {
    padding-left: 80px;
    padding-right: 80px;
  }

  .print\:hover\:pt-1:hover {
    padding-top: 5px;
  }

  .print\:hover\:pr-1:hover {
    padding-right: 5px;
  }

  .print\:hover\:pb-1:hover {
    padding-bottom: 5px;
  }

  .print\:hover\:pl-1:hover {
    padding-left: 5px;
  }

  .print\:hover\:pt-2:hover {
    padding-top: 10px;
  }

  .print\:hover\:pr-2:hover {
    padding-right: 10px;
  }

  .print\:hover\:pb-2:hover {
    padding-bottom: 10px;
  }

  .print\:hover\:pl-2:hover {
    padding-left: 10px;
  }

  .print\:hover\:pt-3:hover {
    padding-top: 15px;
  }

  .print\:hover\:pr-3:hover {
    padding-right: 15px;
  }

  .print\:hover\:pb-3:hover {
    padding-bottom: 15px;
  }

  .print\:hover\:pl-3:hover {
    padding-left: 15px;
  }

  .print\:hover\:pt-4:hover {
    padding-top: 30px;
  }

  .print\:hover\:pr-4:hover {
    padding-right: 30px;
  }

  .print\:hover\:pb-4:hover {
    padding-bottom: 30px;
  }

  .print\:hover\:pl-4:hover {
    padding-left: 30px;
  }

  .print\:hover\:pt-5:hover {
    padding-top: 50px;
  }

  .print\:hover\:pr-5:hover {
    padding-right: 50px;
  }

  .print\:hover\:pb-5:hover {
    padding-bottom: 50px;
  }

  .print\:hover\:pl-5:hover {
    padding-left: 50px;
  }

  .print\:hover\:pt-6:hover {
    padding-top: 75px;
  }

  .print\:hover\:pr-6:hover {
    padding-right: 75px;
  }

  .print\:hover\:pb-6:hover {
    padding-bottom: 75px;
  }

  .print\:hover\:pl-6:hover {
    padding-left: 75px;
  }

  .print\:hover\:pt-7:hover {
    padding-top: 100px;
  }

  .print\:hover\:pr-7:hover {
    padding-right: 100px;
  }

  .print\:hover\:pb-7:hover {
    padding-bottom: 100px;
  }

  .print\:hover\:pl-7:hover {
    padding-left: 100px;
  }

  .print\:hover\:pt-none:hover {
    padding-top: 0px;
  }

  .print\:hover\:pr-none:hover {
    padding-right: 0px;
  }

  .print\:hover\:pb-none:hover {
    padding-bottom: 0px;
  }

  .print\:hover\:pl-none:hover {
    padding-left: 0px;
  }

  .print\:hover\:pt-tiny:hover {
    padding-top: 10px;
  }

  .print\:hover\:pr-tiny:hover {
    padding-right: 10px;
  }

  .print\:hover\:pb-tiny:hover {
    padding-bottom: 10px;
  }

  .print\:hover\:pl-tiny:hover {
    padding-left: 10px;
  }

  .print\:hover\:pt-small:hover {
    padding-top: 20px;
  }

  .print\:hover\:pr-small:hover {
    padding-right: 20px;
  }

  .print\:hover\:pb-small:hover {
    padding-bottom: 20px;
  }

  .print\:hover\:pl-small:hover {
    padding-left: 20px;
  }

  .print\:hover\:pt-medium:hover {
    padding-top: 30px;
  }

  .print\:hover\:pr-medium:hover {
    padding-right: 30px;
  }

  .print\:hover\:pb-medium:hover {
    padding-bottom: 30px;
  }

  .print\:hover\:pl-medium:hover {
    padding-left: 30px;
  }

  .print\:hover\:pt-large:hover {
    padding-top: 40px;
  }

  .print\:hover\:pr-large:hover {
    padding-right: 40px;
  }

  .print\:hover\:pb-large:hover {
    padding-bottom: 40px;
  }

  .print\:hover\:pl-large:hover {
    padding-left: 40px;
  }

  .print\:hover\:pt-huge:hover {
    padding-top: 60px;
  }

  .print\:hover\:pr-huge:hover {
    padding-right: 60px;
  }

  .print\:hover\:pb-huge:hover {
    padding-bottom: 60px;
  }

  .print\:hover\:pl-huge:hover {
    padding-left: 60px;
  }

  .print\:hover\:pt-margin-label-height:hover {
    padding-top: 26px;
  }

  .print\:hover\:pr-margin-label-height:hover {
    padding-right: 26px;
  }

  .print\:hover\:pb-margin-label-height:hover {
    padding-bottom: 26px;
  }

  .print\:hover\:pl-margin-label-height:hover {
    padding-left: 26px;
  }

  .print\:hover\:pt-nav-height:hover {
    padding-top: 80px;
  }

  .print\:hover\:pr-nav-height:hover {
    padding-right: 80px;
  }

  .print\:hover\:pb-nav-height:hover {
    padding-bottom: 80px;
  }

  .print\:hover\:pl-nav-height:hover {
    padding-left: 80px;
  }

  .print\:focus\:p-1:focus {
    padding: 5px;
  }

  .print\:focus\:p-2:focus {
    padding: 10px;
  }

  .print\:focus\:p-3:focus {
    padding: 15px;
  }

  .print\:focus\:p-4:focus {
    padding: 30px;
  }

  .print\:focus\:p-5:focus {
    padding: 50px;
  }

  .print\:focus\:p-6:focus {
    padding: 75px;
  }

  .print\:focus\:p-7:focus {
    padding: 100px;
  }

  .print\:focus\:p-none:focus {
    padding: 0px;
  }

  .print\:focus\:p-tiny:focus {
    padding: 10px;
  }

  .print\:focus\:p-small:focus {
    padding: 20px;
  }

  .print\:focus\:p-medium:focus {
    padding: 30px;
  }

  .print\:focus\:p-large:focus {
    padding: 40px;
  }

  .print\:focus\:p-huge:focus {
    padding: 60px;
  }

  .print\:focus\:p-margin-label-height:focus {
    padding: 26px;
  }

  .print\:focus\:p-nav-height:focus {
    padding: 80px;
  }

  .print\:focus\:py-1:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .print\:focus\:px-1:focus {
    padding-left: 5px;
    padding-right: 5px;
  }

  .print\:focus\:py-2:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:focus\:px-2:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:focus\:py-3:focus {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .print\:focus\:px-3:focus {
    padding-left: 15px;
    padding-right: 15px;
  }

  .print\:focus\:py-4:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:focus\:px-4:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:focus\:py-5:focus {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .print\:focus\:px-5:focus {
    padding-left: 50px;
    padding-right: 50px;
  }

  .print\:focus\:py-6:focus {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .print\:focus\:px-6:focus {
    padding-left: 75px;
    padding-right: 75px;
  }

  .print\:focus\:py-7:focus {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .print\:focus\:px-7:focus {
    padding-left: 100px;
    padding-right: 100px;
  }

  .print\:focus\:py-none:focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .print\:focus\:px-none:focus {
    padding-left: 0px;
    padding-right: 0px;
  }

  .print\:focus\:py-tiny:focus {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:focus\:px-tiny:focus {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:focus\:py-small:focus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .print\:focus\:px-small:focus {
    padding-left: 20px;
    padding-right: 20px;
  }

  .print\:focus\:py-medium:focus {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:focus\:px-medium:focus {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:focus\:py-large:focus {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .print\:focus\:px-large:focus {
    padding-left: 40px;
    padding-right: 40px;
  }

  .print\:focus\:py-huge:focus {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .print\:focus\:px-huge:focus {
    padding-left: 60px;
    padding-right: 60px;
  }

  .print\:focus\:py-margin-label-height:focus {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .print\:focus\:px-margin-label-height:focus {
    padding-left: 26px;
    padding-right: 26px;
  }

  .print\:focus\:py-nav-height:focus {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .print\:focus\:px-nav-height:focus {
    padding-left: 80px;
    padding-right: 80px;
  }

  .print\:focus\:pt-1:focus {
    padding-top: 5px;
  }

  .print\:focus\:pr-1:focus {
    padding-right: 5px;
  }

  .print\:focus\:pb-1:focus {
    padding-bottom: 5px;
  }

  .print\:focus\:pl-1:focus {
    padding-left: 5px;
  }

  .print\:focus\:pt-2:focus {
    padding-top: 10px;
  }

  .print\:focus\:pr-2:focus {
    padding-right: 10px;
  }

  .print\:focus\:pb-2:focus {
    padding-bottom: 10px;
  }

  .print\:focus\:pl-2:focus {
    padding-left: 10px;
  }

  .print\:focus\:pt-3:focus {
    padding-top: 15px;
  }

  .print\:focus\:pr-3:focus {
    padding-right: 15px;
  }

  .print\:focus\:pb-3:focus {
    padding-bottom: 15px;
  }

  .print\:focus\:pl-3:focus {
    padding-left: 15px;
  }

  .print\:focus\:pt-4:focus {
    padding-top: 30px;
  }

  .print\:focus\:pr-4:focus {
    padding-right: 30px;
  }

  .print\:focus\:pb-4:focus {
    padding-bottom: 30px;
  }

  .print\:focus\:pl-4:focus {
    padding-left: 30px;
  }

  .print\:focus\:pt-5:focus {
    padding-top: 50px;
  }

  .print\:focus\:pr-5:focus {
    padding-right: 50px;
  }

  .print\:focus\:pb-5:focus {
    padding-bottom: 50px;
  }

  .print\:focus\:pl-5:focus {
    padding-left: 50px;
  }

  .print\:focus\:pt-6:focus {
    padding-top: 75px;
  }

  .print\:focus\:pr-6:focus {
    padding-right: 75px;
  }

  .print\:focus\:pb-6:focus {
    padding-bottom: 75px;
  }

  .print\:focus\:pl-6:focus {
    padding-left: 75px;
  }

  .print\:focus\:pt-7:focus {
    padding-top: 100px;
  }

  .print\:focus\:pr-7:focus {
    padding-right: 100px;
  }

  .print\:focus\:pb-7:focus {
    padding-bottom: 100px;
  }

  .print\:focus\:pl-7:focus {
    padding-left: 100px;
  }

  .print\:focus\:pt-none:focus {
    padding-top: 0px;
  }

  .print\:focus\:pr-none:focus {
    padding-right: 0px;
  }

  .print\:focus\:pb-none:focus {
    padding-bottom: 0px;
  }

  .print\:focus\:pl-none:focus {
    padding-left: 0px;
  }

  .print\:focus\:pt-tiny:focus {
    padding-top: 10px;
  }

  .print\:focus\:pr-tiny:focus {
    padding-right: 10px;
  }

  .print\:focus\:pb-tiny:focus {
    padding-bottom: 10px;
  }

  .print\:focus\:pl-tiny:focus {
    padding-left: 10px;
  }

  .print\:focus\:pt-small:focus {
    padding-top: 20px;
  }

  .print\:focus\:pr-small:focus {
    padding-right: 20px;
  }

  .print\:focus\:pb-small:focus {
    padding-bottom: 20px;
  }

  .print\:focus\:pl-small:focus {
    padding-left: 20px;
  }

  .print\:focus\:pt-medium:focus {
    padding-top: 30px;
  }

  .print\:focus\:pr-medium:focus {
    padding-right: 30px;
  }

  .print\:focus\:pb-medium:focus {
    padding-bottom: 30px;
  }

  .print\:focus\:pl-medium:focus {
    padding-left: 30px;
  }

  .print\:focus\:pt-large:focus {
    padding-top: 40px;
  }

  .print\:focus\:pr-large:focus {
    padding-right: 40px;
  }

  .print\:focus\:pb-large:focus {
    padding-bottom: 40px;
  }

  .print\:focus\:pl-large:focus {
    padding-left: 40px;
  }

  .print\:focus\:pt-huge:focus {
    padding-top: 60px;
  }

  .print\:focus\:pr-huge:focus {
    padding-right: 60px;
  }

  .print\:focus\:pb-huge:focus {
    padding-bottom: 60px;
  }

  .print\:focus\:pl-huge:focus {
    padding-left: 60px;
  }

  .print\:focus\:pt-margin-label-height:focus {
    padding-top: 26px;
  }

  .print\:focus\:pr-margin-label-height:focus {
    padding-right: 26px;
  }

  .print\:focus\:pb-margin-label-height:focus {
    padding-bottom: 26px;
  }

  .print\:focus\:pl-margin-label-height:focus {
    padding-left: 26px;
  }

  .print\:focus\:pt-nav-height:focus {
    padding-top: 80px;
  }

  .print\:focus\:pr-nav-height:focus {
    padding-right: 80px;
  }

  .print\:focus\:pb-nav-height:focus {
    padding-bottom: 80px;
  }

  .print\:focus\:pl-nav-height:focus {
    padding-left: 80px;
  }

  .print\:first\:p-1:first-child {
    padding: 5px;
  }

  .print\:first\:p-2:first-child {
    padding: 10px;
  }

  .print\:first\:p-3:first-child {
    padding: 15px;
  }

  .print\:first\:p-4:first-child {
    padding: 30px;
  }

  .print\:first\:p-5:first-child {
    padding: 50px;
  }

  .print\:first\:p-6:first-child {
    padding: 75px;
  }

  .print\:first\:p-7:first-child {
    padding: 100px;
  }

  .print\:first\:p-none:first-child {
    padding: 0px;
  }

  .print\:first\:p-tiny:first-child {
    padding: 10px;
  }

  .print\:first\:p-small:first-child {
    padding: 20px;
  }

  .print\:first\:p-medium:first-child {
    padding: 30px;
  }

  .print\:first\:p-large:first-child {
    padding: 40px;
  }

  .print\:first\:p-huge:first-child {
    padding: 60px;
  }

  .print\:first\:p-margin-label-height:first-child {
    padding: 26px;
  }

  .print\:first\:p-nav-height:first-child {
    padding: 80px;
  }

  .print\:first\:py-1:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .print\:first\:px-1:first-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .print\:first\:py-2:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:first\:px-2:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:first\:py-3:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .print\:first\:px-3:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .print\:first\:py-4:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:first\:px-4:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:first\:py-5:first-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .print\:first\:px-5:first-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .print\:first\:py-6:first-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .print\:first\:px-6:first-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .print\:first\:py-7:first-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .print\:first\:px-7:first-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .print\:first\:py-none:first-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .print\:first\:px-none:first-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .print\:first\:py-tiny:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:first\:px-tiny:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:first\:py-small:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .print\:first\:px-small:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .print\:first\:py-medium:first-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:first\:px-medium:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:first\:py-large:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .print\:first\:px-large:first-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .print\:first\:py-huge:first-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .print\:first\:px-huge:first-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .print\:first\:py-margin-label-height:first-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .print\:first\:px-margin-label-height:first-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .print\:first\:py-nav-height:first-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .print\:first\:px-nav-height:first-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .print\:first\:pt-1:first-child {
    padding-top: 5px;
  }

  .print\:first\:pr-1:first-child {
    padding-right: 5px;
  }

  .print\:first\:pb-1:first-child {
    padding-bottom: 5px;
  }

  .print\:first\:pl-1:first-child {
    padding-left: 5px;
  }

  .print\:first\:pt-2:first-child {
    padding-top: 10px;
  }

  .print\:first\:pr-2:first-child {
    padding-right: 10px;
  }

  .print\:first\:pb-2:first-child {
    padding-bottom: 10px;
  }

  .print\:first\:pl-2:first-child {
    padding-left: 10px;
  }

  .print\:first\:pt-3:first-child {
    padding-top: 15px;
  }

  .print\:first\:pr-3:first-child {
    padding-right: 15px;
  }

  .print\:first\:pb-3:first-child {
    padding-bottom: 15px;
  }

  .print\:first\:pl-3:first-child {
    padding-left: 15px;
  }

  .print\:first\:pt-4:first-child {
    padding-top: 30px;
  }

  .print\:first\:pr-4:first-child {
    padding-right: 30px;
  }

  .print\:first\:pb-4:first-child {
    padding-bottom: 30px;
  }

  .print\:first\:pl-4:first-child {
    padding-left: 30px;
  }

  .print\:first\:pt-5:first-child {
    padding-top: 50px;
  }

  .print\:first\:pr-5:first-child {
    padding-right: 50px;
  }

  .print\:first\:pb-5:first-child {
    padding-bottom: 50px;
  }

  .print\:first\:pl-5:first-child {
    padding-left: 50px;
  }

  .print\:first\:pt-6:first-child {
    padding-top: 75px;
  }

  .print\:first\:pr-6:first-child {
    padding-right: 75px;
  }

  .print\:first\:pb-6:first-child {
    padding-bottom: 75px;
  }

  .print\:first\:pl-6:first-child {
    padding-left: 75px;
  }

  .print\:first\:pt-7:first-child {
    padding-top: 100px;
  }

  .print\:first\:pr-7:first-child {
    padding-right: 100px;
  }

  .print\:first\:pb-7:first-child {
    padding-bottom: 100px;
  }

  .print\:first\:pl-7:first-child {
    padding-left: 100px;
  }

  .print\:first\:pt-none:first-child {
    padding-top: 0px;
  }

  .print\:first\:pr-none:first-child {
    padding-right: 0px;
  }

  .print\:first\:pb-none:first-child {
    padding-bottom: 0px;
  }

  .print\:first\:pl-none:first-child {
    padding-left: 0px;
  }

  .print\:first\:pt-tiny:first-child {
    padding-top: 10px;
  }

  .print\:first\:pr-tiny:first-child {
    padding-right: 10px;
  }

  .print\:first\:pb-tiny:first-child {
    padding-bottom: 10px;
  }

  .print\:first\:pl-tiny:first-child {
    padding-left: 10px;
  }

  .print\:first\:pt-small:first-child {
    padding-top: 20px;
  }

  .print\:first\:pr-small:first-child {
    padding-right: 20px;
  }

  .print\:first\:pb-small:first-child {
    padding-bottom: 20px;
  }

  .print\:first\:pl-small:first-child {
    padding-left: 20px;
  }

  .print\:first\:pt-medium:first-child {
    padding-top: 30px;
  }

  .print\:first\:pr-medium:first-child {
    padding-right: 30px;
  }

  .print\:first\:pb-medium:first-child {
    padding-bottom: 30px;
  }

  .print\:first\:pl-medium:first-child {
    padding-left: 30px;
  }

  .print\:first\:pt-large:first-child {
    padding-top: 40px;
  }

  .print\:first\:pr-large:first-child {
    padding-right: 40px;
  }

  .print\:first\:pb-large:first-child {
    padding-bottom: 40px;
  }

  .print\:first\:pl-large:first-child {
    padding-left: 40px;
  }

  .print\:first\:pt-huge:first-child {
    padding-top: 60px;
  }

  .print\:first\:pr-huge:first-child {
    padding-right: 60px;
  }

  .print\:first\:pb-huge:first-child {
    padding-bottom: 60px;
  }

  .print\:first\:pl-huge:first-child {
    padding-left: 60px;
  }

  .print\:first\:pt-margin-label-height:first-child {
    padding-top: 26px;
  }

  .print\:first\:pr-margin-label-height:first-child {
    padding-right: 26px;
  }

  .print\:first\:pb-margin-label-height:first-child {
    padding-bottom: 26px;
  }

  .print\:first\:pl-margin-label-height:first-child {
    padding-left: 26px;
  }

  .print\:first\:pt-nav-height:first-child {
    padding-top: 80px;
  }

  .print\:first\:pr-nav-height:first-child {
    padding-right: 80px;
  }

  .print\:first\:pb-nav-height:first-child {
    padding-bottom: 80px;
  }

  .print\:first\:pl-nav-height:first-child {
    padding-left: 80px;
  }

  .print\:last\:p-1:last-child {
    padding: 5px;
  }

  .print\:last\:p-2:last-child {
    padding: 10px;
  }

  .print\:last\:p-3:last-child {
    padding: 15px;
  }

  .print\:last\:p-4:last-child {
    padding: 30px;
  }

  .print\:last\:p-5:last-child {
    padding: 50px;
  }

  .print\:last\:p-6:last-child {
    padding: 75px;
  }

  .print\:last\:p-7:last-child {
    padding: 100px;
  }

  .print\:last\:p-none:last-child {
    padding: 0px;
  }

  .print\:last\:p-tiny:last-child {
    padding: 10px;
  }

  .print\:last\:p-small:last-child {
    padding: 20px;
  }

  .print\:last\:p-medium:last-child {
    padding: 30px;
  }

  .print\:last\:p-large:last-child {
    padding: 40px;
  }

  .print\:last\:p-huge:last-child {
    padding: 60px;
  }

  .print\:last\:p-margin-label-height:last-child {
    padding: 26px;
  }

  .print\:last\:p-nav-height:last-child {
    padding: 80px;
  }

  .print\:last\:py-1:last-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .print\:last\:px-1:last-child {
    padding-left: 5px;
    padding-right: 5px;
  }

  .print\:last\:py-2:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:last\:px-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:last\:py-3:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .print\:last\:px-3:last-child {
    padding-left: 15px;
    padding-right: 15px;
  }

  .print\:last\:py-4:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:last\:px-4:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:last\:py-5:last-child {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .print\:last\:px-5:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .print\:last\:py-6:last-child {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .print\:last\:px-6:last-child {
    padding-left: 75px;
    padding-right: 75px;
  }

  .print\:last\:py-7:last-child {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .print\:last\:px-7:last-child {
    padding-left: 100px;
    padding-right: 100px;
  }

  .print\:last\:py-none:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .print\:last\:px-none:last-child {
    padding-left: 0px;
    padding-right: 0px;
  }

  .print\:last\:py-tiny:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .print\:last\:px-tiny:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  .print\:last\:py-small:last-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .print\:last\:px-small:last-child {
    padding-left: 20px;
    padding-right: 20px;
  }

  .print\:last\:py-medium:last-child {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .print\:last\:px-medium:last-child {
    padding-left: 30px;
    padding-right: 30px;
  }

  .print\:last\:py-large:last-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .print\:last\:px-large:last-child {
    padding-left: 40px;
    padding-right: 40px;
  }

  .print\:last\:py-huge:last-child {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .print\:last\:px-huge:last-child {
    padding-left: 60px;
    padding-right: 60px;
  }

  .print\:last\:py-margin-label-height:last-child {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .print\:last\:px-margin-label-height:last-child {
    padding-left: 26px;
    padding-right: 26px;
  }

  .print\:last\:py-nav-height:last-child {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .print\:last\:px-nav-height:last-child {
    padding-left: 80px;
    padding-right: 80px;
  }

  .print\:last\:pt-1:last-child {
    padding-top: 5px;
  }

  .print\:last\:pr-1:last-child {
    padding-right: 5px;
  }

  .print\:last\:pb-1:last-child {
    padding-bottom: 5px;
  }

  .print\:last\:pl-1:last-child {
    padding-left: 5px;
  }

  .print\:last\:pt-2:last-child {
    padding-top: 10px;
  }

  .print\:last\:pr-2:last-child {
    padding-right: 10px;
  }

  .print\:last\:pb-2:last-child {
    padding-bottom: 10px;
  }

  .print\:last\:pl-2:last-child {
    padding-left: 10px;
  }

  .print\:last\:pt-3:last-child {
    padding-top: 15px;
  }

  .print\:last\:pr-3:last-child {
    padding-right: 15px;
  }

  .print\:last\:pb-3:last-child {
    padding-bottom: 15px;
  }

  .print\:last\:pl-3:last-child {
    padding-left: 15px;
  }

  .print\:last\:pt-4:last-child {
    padding-top: 30px;
  }

  .print\:last\:pr-4:last-child {
    padding-right: 30px;
  }

  .print\:last\:pb-4:last-child {
    padding-bottom: 30px;
  }

  .print\:last\:pl-4:last-child {
    padding-left: 30px;
  }

  .print\:last\:pt-5:last-child {
    padding-top: 50px;
  }

  .print\:last\:pr-5:last-child {
    padding-right: 50px;
  }

  .print\:last\:pb-5:last-child {
    padding-bottom: 50px;
  }

  .print\:last\:pl-5:last-child {
    padding-left: 50px;
  }

  .print\:last\:pt-6:last-child {
    padding-top: 75px;
  }

  .print\:last\:pr-6:last-child {
    padding-right: 75px;
  }

  .print\:last\:pb-6:last-child {
    padding-bottom: 75px;
  }

  .print\:last\:pl-6:last-child {
    padding-left: 75px;
  }

  .print\:last\:pt-7:last-child {
    padding-top: 100px;
  }

  .print\:last\:pr-7:last-child {
    padding-right: 100px;
  }

  .print\:last\:pb-7:last-child {
    padding-bottom: 100px;
  }

  .print\:last\:pl-7:last-child {
    padding-left: 100px;
  }

  .print\:last\:pt-none:last-child {
    padding-top: 0px;
  }

  .print\:last\:pr-none:last-child {
    padding-right: 0px;
  }

  .print\:last\:pb-none:last-child {
    padding-bottom: 0px;
  }

  .print\:last\:pl-none:last-child {
    padding-left: 0px;
  }

  .print\:last\:pt-tiny:last-child {
    padding-top: 10px;
  }

  .print\:last\:pr-tiny:last-child {
    padding-right: 10px;
  }

  .print\:last\:pb-tiny:last-child {
    padding-bottom: 10px;
  }

  .print\:last\:pl-tiny:last-child {
    padding-left: 10px;
  }

  .print\:last\:pt-small:last-child {
    padding-top: 20px;
  }

  .print\:last\:pr-small:last-child {
    padding-right: 20px;
  }

  .print\:last\:pb-small:last-child {
    padding-bottom: 20px;
  }

  .print\:last\:pl-small:last-child {
    padding-left: 20px;
  }

  .print\:last\:pt-medium:last-child {
    padding-top: 30px;
  }

  .print\:last\:pr-medium:last-child {
    padding-right: 30px;
  }

  .print\:last\:pb-medium:last-child {
    padding-bottom: 30px;
  }

  .print\:last\:pl-medium:last-child {
    padding-left: 30px;
  }

  .print\:last\:pt-large:last-child {
    padding-top: 40px;
  }

  .print\:last\:pr-large:last-child {
    padding-right: 40px;
  }

  .print\:last\:pb-large:last-child {
    padding-bottom: 40px;
  }

  .print\:last\:pl-large:last-child {
    padding-left: 40px;
  }

  .print\:last\:pt-huge:last-child {
    padding-top: 60px;
  }

  .print\:last\:pr-huge:last-child {
    padding-right: 60px;
  }

  .print\:last\:pb-huge:last-child {
    padding-bottom: 60px;
  }

  .print\:last\:pl-huge:last-child {
    padding-left: 60px;
  }

  .print\:last\:pt-margin-label-height:last-child {
    padding-top: 26px;
  }

  .print\:last\:pr-margin-label-height:last-child {
    padding-right: 26px;
  }

  .print\:last\:pb-margin-label-height:last-child {
    padding-bottom: 26px;
  }

  .print\:last\:pl-margin-label-height:last-child {
    padding-left: 26px;
  }

  .print\:last\:pt-nav-height:last-child {
    padding-top: 80px;
  }

  .print\:last\:pr-nav-height:last-child {
    padding-right: 80px;
  }

  .print\:last\:pb-nav-height:last-child {
    padding-bottom: 80px;
  }

  .print\:last\:pl-nav-height:last-child {
    padding-left: 80px;
  }

  .print\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-white:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:placeholder-black:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:placeholder-black-backdrop::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .print\:placeholder-black-backdrop:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .print\:placeholder-black-backdrop::placeholder {
    color: #2c2c2c99;
  }

  .print\:placeholder-beige::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:placeholder-beige:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:placeholder-beige::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:placeholder-beige-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:placeholder-beige-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:placeholder-beige-dark::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:placeholder-blue::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-dark::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-light::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-hover::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-hover:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:placeholder-blue-hover::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:placeholder-green::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:placeholder-green:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:placeholder-green::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:placeholder-green-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:placeholder-green-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:placeholder-green-light::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:placeholder-green-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:placeholder-green-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:placeholder-green-dark::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-10::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-10:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-10::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-20::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-20:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-20::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-30::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-30:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-30::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-40::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-40:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-40::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-50::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-50:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-50::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-60::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-60:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-60::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-70::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-70:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-70::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-80::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-80:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-80::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-90::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-90:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-90::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:placeholder-grey::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:placeholder-grey:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:placeholder-grey::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-light::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-dark::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-dark:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:placeholder-grey-dark::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited-light::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited-light:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-purple-visited-light::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:placeholder-red::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:placeholder-red:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:placeholder-red::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:placeholder-yellow::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:placeholder-yellow:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:placeholder-yellow::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:placeholder-state-danger::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .print\:placeholder-state-danger:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .print\:placeholder-state-danger::placeholder {
    color: #FF8274B3;
  }

  .print\:placeholder-state-warning::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .print\:placeholder-state-warning:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .print\:placeholder-state-warning::placeholder {
    color: #F9C66BB3;
  }

  .print\:placeholder-state-success::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .print\:placeholder-state-success:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .print\:placeholder-state-success::placeholder {
    color: #C7F6C9B3;
  }

  .print\:placeholder-state-primary::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .print\:placeholder-state-primary:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .print\:placeholder-state-primary::placeholder {
    color: #B3F5FFB3;
  }

  .print\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-white:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-black:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-black-backdrop:focus::-webkit-input-placeholder {
    color: #2c2c2c99;
  }

  .print\:focus\:placeholder-black-backdrop:focus:-ms-input-placeholder {
    color: #2c2c2c99;
  }

  .print\:focus\:placeholder-black-backdrop:focus::placeholder {
    color: #2c2c2c99;
  }

  .print\:focus\:placeholder-beige:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-beige:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-beige:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-beige-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-beige-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-beige-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-hover:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-hover:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-blue-hover:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green:focus::placeholder {
    --placeholder-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-green-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-10:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-10:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-10:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-20:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-20:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-20:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-30:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-30:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-30:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-40:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-40:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-40:focus::placeholder {
    --placeholder-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-60:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-60:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-60:focus::placeholder {
    --placeholder-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-70:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-70:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-80:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-80:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-80:focus::placeholder {
    --placeholder-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-90:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-90:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-90:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-dark:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-dark:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-grey-dark:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited-light:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited-light:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-purple-visited-light:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-red:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-red:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-red:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-yellow:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-yellow:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-yellow:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--placeholder-opacity));
  }

  .print\:focus\:placeholder-state-danger:focus::-webkit-input-placeholder {
    color: #FF8274B3;
  }

  .print\:focus\:placeholder-state-danger:focus:-ms-input-placeholder {
    color: #FF8274B3;
  }

  .print\:focus\:placeholder-state-danger:focus::placeholder {
    color: #FF8274B3;
  }

  .print\:focus\:placeholder-state-warning:focus::-webkit-input-placeholder {
    color: #F9C66BB3;
  }

  .print\:focus\:placeholder-state-warning:focus:-ms-input-placeholder {
    color: #F9C66BB3;
  }

  .print\:focus\:placeholder-state-warning:focus::placeholder {
    color: #F9C66BB3;
  }

  .print\:focus\:placeholder-state-success:focus::-webkit-input-placeholder {
    color: #C7F6C9B3;
  }

  .print\:focus\:placeholder-state-success:focus:-ms-input-placeholder {
    color: #C7F6C9B3;
  }

  .print\:focus\:placeholder-state-success:focus::placeholder {
    color: #C7F6C9B3;
  }

  .print\:focus\:placeholder-state-primary:focus::-webkit-input-placeholder {
    color: #B3F5FFB3;
  }

  .print\:focus\:placeholder-state-primary:focus:-ms-input-placeholder {
    color: #B3F5FFB3;
  }

  .print\:focus\:placeholder-state-primary:focus::placeholder {
    color: #B3F5FFB3;
  }

  .print\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .print\:placeholder-opacity-0:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .print\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .print\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:placeholder-opacity-25:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:placeholder-opacity-50:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:placeholder-opacity-75:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .print\:placeholder-opacity-100:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .print\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .print\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0;
  }

  .print\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder {
    --placeholder-opacity: 0;
  }

  .print\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .print\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .print\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .print\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .print\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1;
  }

  .print\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder {
    --placeholder-opacity: 1;
  }

  .print\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .print\:pointer-events-none {
    pointer-events: none;
  }

  .print\:pointer-events-auto {
    pointer-events: auto;
  }

  .print\:static {
    position: static;
  }

  .print\:fixed {
    position: fixed;
  }

  .print\:absolute {
    position: absolute;
  }

  .print\:relative {
    position: relative;
  }

  .print\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .print\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .print\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .print\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .print\:inset-x-0 {
    right: 0;
    left: 0;
  }

  .print\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .print\:inset-x-auto {
    right: auto;
    left: auto;
  }

  .print\:top-0 {
    top: 0;
  }

  .print\:right-0 {
    right: 0;
  }

  .print\:bottom-0 {
    bottom: 0;
  }

  .print\:left-0 {
    left: 0;
  }

  .print\:top-auto {
    top: auto;
  }

  .print\:right-auto {
    right: auto;
  }

  .print\:bottom-auto {
    bottom: auto;
  }

  .print\:left-auto {
    left: auto;
  }

  .print\:resize-none {
    resize: none;
  }

  .print\:resize-y {
    resize: vertical;
  }

  .print\:resize-x {
    resize: horizontal;
  }

  .print\:resize {
    resize: both;
  }

  .print\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .print\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .print\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .print\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .print\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .print\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .print\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .print\:shadow-none {
    box-shadow: none;
  }

  .print\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .print\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .print\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .print\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .print\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .print\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .print\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .print\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .print\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .print\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .print\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .print\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .print\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .print\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .print\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .print\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .print\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .print\:fill-current {
    fill: currentColor;
  }

  .print\:stroke-current {
    stroke: currentColor;
  }

  .print\:stroke-0 {
    stroke-width: 0;
  }

  .print\:stroke-1 {
    stroke-width: 1;
  }

  .print\:stroke-2 {
    stroke-width: 2;
  }

  .print\:table-auto {
    table-layout: auto;
  }

  .print\:table-fixed {
    table-layout: fixed;
  }

  .print\:text-left {
    text-align: left;
  }

  .print\:text-center {
    text-align: center;
  }

  .print\:text-right {
    text-align: right;
  }

  .print\:text-justify {
    text-align: justify;
  }

  .print\:text-white {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .print\:text-black {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .print\:text-black-backdrop {
    color: #2c2c2c99;
  }

  .print\:text-beige {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .print\:text-beige-dark {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .print\:text-blue {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .print\:text-blue-dark {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .print\:text-blue-light {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .print\:text-blue-hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .print\:text-green {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .print\:text-green-light {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .print\:text-green-dark {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .print\:text-grey-10 {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .print\:text-grey-20 {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .print\:text-grey-30 {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .print\:text-grey-40 {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .print\:text-grey-50 {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .print\:text-grey-60 {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .print\:text-grey-70 {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .print\:text-grey-80 {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .print\:text-grey-90 {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .print\:text-grey {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .print\:text-grey-light {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .print\:text-grey-dark {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .print\:text-purple-visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .print\:text-purple-visited-light {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .print\:text-red {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .print\:text-yellow {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .print\:text-state-danger {
    color: #FF8274B3;
  }

  .print\:text-state-warning {
    color: #F9C66BB3;
  }

  .print\:text-state-success {
    color: #C7F6C9B3;
  }

  .print\:text-state-primary {
    color: #B3F5FFB3;
  }

  .print\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .print\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .print\:focus\:text-black-backdrop:focus {
    color: #2c2c2c99;
  }

  .print\:focus\:text-beige:focus {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .print\:focus\:text-beige-dark:focus {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .print\:focus\:text-blue:focus {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .print\:focus\:text-blue-dark:focus {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .print\:focus\:text-blue-light:focus {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .print\:focus\:text-blue-hover:focus {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .print\:focus\:text-green:focus {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .print\:focus\:text-green-light:focus {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .print\:focus\:text-green-dark:focus {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .print\:focus\:text-grey-10:focus {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .print\:focus\:text-grey-20:focus {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .print\:focus\:text-grey-30:focus {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .print\:focus\:text-grey-40:focus {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .print\:focus\:text-grey-50:focus {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .print\:focus\:text-grey-60:focus {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .print\:focus\:text-grey-70:focus {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .print\:focus\:text-grey-80:focus {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .print\:focus\:text-grey-90:focus {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .print\:focus\:text-grey:focus {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .print\:focus\:text-grey-light:focus {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .print\:focus\:text-grey-dark:focus {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .print\:focus\:text-purple-visited:focus {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .print\:focus\:text-purple-visited-light:focus {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .print\:focus\:text-red:focus {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .print\:focus\:text-yellow:focus {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .print\:focus\:text-state-danger:focus {
    color: #FF8274B3;
  }

  .print\:focus\:text-state-warning:focus {
    color: #F9C66BB3;
  }

  .print\:focus\:text-state-success:focus {
    color: #C7F6C9B3;
  }

  .print\:focus\:text-state-primary:focus {
    color: #B3F5FFB3;
  }

  .print\:visited\:text-white:visited {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .print\:visited\:text-black:visited {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .print\:visited\:text-black-backdrop:visited {
    color: #2c2c2c99;
  }

  .print\:visited\:text-beige:visited {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .print\:visited\:text-beige-dark:visited {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .print\:visited\:text-blue:visited {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .print\:visited\:text-blue-dark:visited {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .print\:visited\:text-blue-light:visited {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .print\:visited\:text-blue-hover:visited {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .print\:visited\:text-green:visited {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .print\:visited\:text-green-light:visited {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .print\:visited\:text-green-dark:visited {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .print\:visited\:text-grey-10:visited {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .print\:visited\:text-grey-20:visited {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .print\:visited\:text-grey-30:visited {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .print\:visited\:text-grey-40:visited {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .print\:visited\:text-grey-50:visited {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .print\:visited\:text-grey-60:visited {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .print\:visited\:text-grey-70:visited {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .print\:visited\:text-grey-80:visited {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .print\:visited\:text-grey-90:visited {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .print\:visited\:text-grey:visited {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .print\:visited\:text-grey-light:visited {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .print\:visited\:text-grey-dark:visited {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .print\:visited\:text-purple-visited:visited {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .print\:visited\:text-purple-visited-light:visited {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .print\:visited\:text-red:visited {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .print\:visited\:text-yellow:visited {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .print\:visited\:text-state-danger:visited {
    color: #FF8274B3;
  }

  .print\:visited\:text-state-warning:visited {
    color: #F9C66BB3;
  }

  .print\:visited\:text-state-success:visited {
    color: #C7F6C9B3;
  }

  .print\:visited\:text-state-primary:visited {
    color: #B3F5FFB3;
  }

  .print\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #FFFFFF;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .print\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #2c2c2c;
    color: rgba(44, 44, 44, var(--text-opacity));
  }

  .print\:hover\:text-black-backdrop:hover {
    color: #2c2c2c99;
  }

  .print\:hover\:text-beige:hover {
    --text-opacity: 1;
    color: #f8f0dd;
    color: rgba(248, 240, 221, var(--text-opacity));
  }

  .print\:hover\:text-beige-dark:hover {
    --text-opacity: 1;
    color: #d0bfae;
    color: rgba(208, 191, 174, var(--text-opacity));
  }

  .print\:hover\:text-blue:hover {
    --text-opacity: 1;
    color: #6fe9ff;
    color: rgba(111, 233, 255, var(--text-opacity));
  }

  .print\:hover\:text-blue-dark:hover {
    --text-opacity: 1;
    color: #2a2859;
    color: rgba(42, 40, 89, var(--text-opacity));
  }

  .print\:hover\:text-blue-light:hover {
    --text-opacity: 1;
    color: #b3f5ff;
    color: rgba(179, 245, 255, var(--text-opacity));
  }

  .print\:hover\:text-blue-hover:hover {
    --text-opacity: 1;
    color: #1f42aa;
    color: rgba(31, 66, 170, var(--text-opacity));
  }

  .print\:hover\:text-green:hover {
    --text-opacity: 1;
    color: #43f8b6;
    color: rgba(67, 248, 182, var(--text-opacity));
  }

  .print\:hover\:text-green-light:hover {
    --text-opacity: 1;
    color: #c7f6c9;
    color: rgba(199, 246, 201, var(--text-opacity));
  }

  .print\:hover\:text-green-dark:hover {
    --text-opacity: 1;
    color: #034b45;
    color: rgba(3, 75, 69, var(--text-opacity));
  }

  .print\:hover\:text-grey-10:hover {
    --text-opacity: 1;
    color: #e6e6e6;
    color: rgba(230, 230, 230, var(--text-opacity));
  }

  .print\:hover\:text-grey-20:hover {
    --text-opacity: 1;
    color: #cccccc;
    color: rgba(204, 204, 204, var(--text-opacity));
  }

  .print\:hover\:text-grey-30:hover {
    --text-opacity: 1;
    color: #b3b3b3;
    color: rgba(179, 179, 179, var(--text-opacity));
  }

  .print\:hover\:text-grey-40:hover {
    --text-opacity: 1;
    color: #999999;
    color: rgba(153, 153, 153, var(--text-opacity));
  }

  .print\:hover\:text-grey-50:hover {
    --text-opacity: 1;
    color: #808080;
    color: rgba(128, 128, 128, var(--text-opacity));
  }

  .print\:hover\:text-grey-60:hover {
    --text-opacity: 1;
    color: #666666;
    color: rgba(102, 102, 102, var(--text-opacity));
  }

  .print\:hover\:text-grey-70:hover {
    --text-opacity: 1;
    color: #4d4d4d;
    color: rgba(77, 77, 77, var(--text-opacity));
  }

  .print\:hover\:text-grey-80:hover {
    --text-opacity: 1;
    color: #333333;
    color: rgba(51, 51, 51, var(--text-opacity));
  }

  .print\:hover\:text-grey-90:hover {
    --text-opacity: 1;
    color: #1a1a1a;
    color: rgba(26, 26, 26, var(--text-opacity));
  }

  .print\:hover\:text-grey:hover {
    --text-opacity: 1;
    color: #f2f2f2;
    color: rgba(242, 242, 242, var(--text-opacity));
  }

  .print\:hover\:text-grey-light:hover {
    --text-opacity: 1;
    color: #f9f9f9;
    color: rgba(249, 249, 249, var(--text-opacity));
  }

  .print\:hover\:text-grey-dark:hover {
    --text-opacity: 1;
    color: #e1e1e1;
    color: rgba(225, 225, 225, var(--text-opacity));
  }

  .print\:hover\:text-purple-visited:hover {
    --text-opacity: 1;
    color: #8d50b1;
    color: rgba(141, 80, 177, var(--text-opacity));
  }

  .print\:hover\:text-purple-visited-light:hover {
    --text-opacity: 1;
    color: #e0adff;
    color: rgba(224, 173, 255, var(--text-opacity));
  }

  .print\:hover\:text-red:hover {
    --text-opacity: 1;
    color: #ff8274;
    color: rgba(255, 130, 116, var(--text-opacity));
  }

  .print\:hover\:text-yellow:hover {
    --text-opacity: 1;
    color: #f9c66b;
    color: rgba(249, 198, 107, var(--text-opacity));
  }

  .print\:hover\:text-state-danger:hover {
    color: #FF8274B3;
  }

  .print\:hover\:text-state-warning:hover {
    color: #F9C66BB3;
  }

  .print\:hover\:text-state-success:hover {
    color: #C7F6C9B3;
  }

  .print\:hover\:text-state-primary:hover {
    color: #B3F5FFB3;
  }

  .print\:text-opacity-0 {
    --text-opacity: 0;
  }

  .print\:text-opacity-25 {
    --text-opacity: 0.25;
  }

  .print\:text-opacity-50 {
    --text-opacity: 0.5;
  }

  .print\:text-opacity-75 {
    --text-opacity: 0.75;
  }

  .print\:text-opacity-100 {
    --text-opacity: 1;
  }

  .print\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .print\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .print\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .print\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .print\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .print\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .print\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .print\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .print\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .print\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .print\:italic {
    font-style: italic;
  }

  .print\:not-italic {
    font-style: normal;
  }

  .print\:uppercase {
    text-transform: uppercase;
  }

  .print\:lowercase {
    text-transform: lowercase;
  }

  .print\:capitalize {
    text-transform: capitalize;
  }

  .print\:normal-case {
    text-transform: none;
  }

  .print\:underline {
    text-decoration: underline;
  }

  .print\:line-through {
    text-decoration: line-through;
  }

  .print\:no-underline {
    text-decoration: none;
  }

  .print\:hover\:underline:hover {
    text-decoration: underline;
  }

  .print\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .print\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .print\:focus\:underline:focus {
    text-decoration: underline;
  }

  .print\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .print\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .print\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .print\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .print\:ordinal, .print\:slashed-zero, .print\:lining-nums, .print\:oldstyle-nums, .print\:proportional-nums, .print\:tabular-nums, .print\:diagonal-fractions, .print\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .print\:normal-nums {
    font-variant-numeric: normal;
  }

  .print\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .print\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .print\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .print\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .print\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .print\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .print\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .print\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .print\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .print\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .print\:tracking-normal {
    letter-spacing: 0;
  }

  .print\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .print\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .print\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .print\:select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .print\:select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .print\:select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .print\:select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .print\:align-baseline {
    vertical-align: baseline;
  }

  .print\:align-top {
    vertical-align: top;
  }

  .print\:align-middle {
    vertical-align: middle;
  }

  .print\:align-bottom {
    vertical-align: bottom;
  }

  .print\:align-text-top {
    vertical-align: text-top;
  }

  .print\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .print\:visible {
    visibility: visible;
  }

  .print\:invisible {
    visibility: hidden;
  }

  .print\:whitespace-normal {
    white-space: normal;
  }

  .print\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .print\:whitespace-pre {
    white-space: pre;
  }

  .print\:whitespace-pre-line {
    white-space: pre-line;
  }

  .print\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .print\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .print\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .print\:break-all {
    word-break: break-all;
  }

  .print\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .print\:w-1 {
    width: 5px;
  }

  .print\:w-2 {
    width: 10px;
  }

  .print\:w-3 {
    width: 15px;
  }

  .print\:w-4 {
    width: 30px;
  }

  .print\:w-5 {
    width: 50px;
  }

  .print\:w-6 {
    width: 75px;
  }

  .print\:w-7 {
    width: 100px;
  }

  .print\:w-auto {
    width: auto;
  }

  .print\:w-none {
    width: 0px;
  }

  .print\:w-tiny {
    width: 10px;
  }

  .print\:w-small {
    width: 20px;
  }

  .print\:w-medium {
    width: 30px;
  }

  .print\:w-large {
    width: 40px;
  }

  .print\:w-huge {
    width: 60px;
  }

  .print\:w-margin-label-height {
    width: 26px;
  }

  .print\:w-nav-height {
    width: 80px;
  }

  .print\:w-1\/2 {
    width: 50%;
  }

  .print\:w-1\/3 {
    width: 33.333333%;
  }

  .print\:w-2\/3 {
    width: 66.666667%;
  }

  .print\:w-1\/4 {
    width: 25%;
  }

  .print\:w-2\/4 {
    width: 50%;
  }

  .print\:w-3\/4 {
    width: 75%;
  }

  .print\:w-1\/5 {
    width: 20%;
  }

  .print\:w-2\/5 {
    width: 40%;
  }

  .print\:w-3\/5 {
    width: 60%;
  }

  .print\:w-4\/5 {
    width: 80%;
  }

  .print\:w-1\/6 {
    width: 16.666667%;
  }

  .print\:w-2\/6 {
    width: 33.333333%;
  }

  .print\:w-3\/6 {
    width: 50%;
  }

  .print\:w-4\/6 {
    width: 66.666667%;
  }

  .print\:w-5\/6 {
    width: 83.333333%;
  }

  .print\:w-1\/12 {
    width: 8.333333%;
  }

  .print\:w-2\/12 {
    width: 16.666667%;
  }

  .print\:w-3\/12 {
    width: 25%;
  }

  .print\:w-4\/12 {
    width: 33.333333%;
  }

  .print\:w-5\/12 {
    width: 41.666667%;
  }

  .print\:w-6\/12 {
    width: 50%;
  }

  .print\:w-7\/12 {
    width: 58.333333%;
  }

  .print\:w-8\/12 {
    width: 66.666667%;
  }

  .print\:w-9\/12 {
    width: 75%;
  }

  .print\:w-10\/12 {
    width: 83.333333%;
  }

  .print\:w-11\/12 {
    width: 91.666667%;
  }

  .print\:w-full {
    width: 100%;
  }

  .print\:w-screen {
    width: 100vw;
  }

  .print\:w-widescreen-content-max-width {
    width: 1146px;
  }

  .print\:z-0 {
    z-index: 0;
  }

  .print\:z-10 {
    z-index: 10;
  }

  .print\:z-20 {
    z-index: 20;
  }

  .print\:z-30 {
    z-index: 30;
  }

  .print\:z-40 {
    z-index: 40;
  }

  .print\:z-50 {
    z-index: 50;
  }

  .print\:z-auto {
    z-index: auto;
  }

  .print\:z-nav {
    z-index: 90;
  }

  .print\:z-menu {
    z-index: 100;
  }

  .print\:z-popup {
    z-index: 110;
  }

  .print\:z-modalBackdrop {
    z-index: 120;
  }

  .print\:z-modal {
    z-index: 130;
  }

  .print\:gap-0 {
    grid-gap: 0px;
    gap: 0px;
  }

  .print\:gap-1 {
    grid-gap: 5px;
    gap: 5px;
  }

  .print\:gap-2 {
    grid-gap: 10px;
    gap: 10px;
  }

  .print\:gap-3 {
    grid-gap: 15px;
    gap: 15px;
  }

  .print\:gap-4 {
    grid-gap: 30px;
    gap: 30px;
  }

  .print\:gap-5 {
    grid-gap: 50px;
    gap: 50px;
  }

  .print\:gap-6 {
    grid-gap: 75px;
    gap: 75px;
  }

  .print\:gap-7 {
    grid-gap: 100px;
    gap: 100px;
  }

  .print\:gap-none {
    grid-gap: 0px;
    gap: 0px;
  }

  .print\:gap-tiny {
    grid-gap: 10px;
    gap: 10px;
  }

  .print\:gap-small {
    grid-gap: 20px;
    gap: 20px;
  }

  .print\:gap-medium {
    grid-gap: 30px;
    gap: 30px;
  }

  .print\:gap-large {
    grid-gap: 40px;
    gap: 40px;
  }

  .print\:gap-huge {
    grid-gap: 60px;
    gap: 60px;
  }

  .print\:gap-margin-label-height {
    grid-gap: 26px;
    gap: 26px;
  }

  .print\:gap-nav-height {
    grid-gap: 80px;
    gap: 80px;
  }

  .print\:gap {
    grid-gap: 32px;
    gap: 32px;
  }

  .print\:col-gap-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .print\:col-gap-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .print\:col-gap-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .print\:col-gap-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .print\:col-gap-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .print\:col-gap-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .print\:col-gap-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .print\:col-gap-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .print\:col-gap-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .print\:col-gap-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .print\:col-gap-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .print\:col-gap-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .print\:col-gap-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .print\:col-gap-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .print\:col-gap-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .print\:col-gap-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .print\:col-gap {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .print\:gap-x-0 {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .print\:gap-x-1 {
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .print\:gap-x-2 {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .print\:gap-x-3 {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
  }

  .print\:gap-x-4 {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .print\:gap-x-5 {
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
  }

  .print\:gap-x-6 {
    grid-column-gap: 75px;
    -webkit-column-gap: 75px;
            column-gap: 75px;
  }

  .print\:gap-x-7 {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .print\:gap-x-none {
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .print\:gap-x-tiny {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .print\:gap-x-small {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .print\:gap-x-medium {
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .print\:gap-x-large {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .print\:gap-x-huge {
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }

  .print\:gap-x-margin-label-height {
    grid-column-gap: 26px;
    -webkit-column-gap: 26px;
            column-gap: 26px;
  }

  .print\:gap-x-nav-height {
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }

  .print\:gap-x {
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }

  .print\:row-gap-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .print\:row-gap-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .print\:row-gap-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .print\:row-gap-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .print\:row-gap-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .print\:row-gap-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .print\:row-gap-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .print\:row-gap-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .print\:row-gap-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .print\:row-gap-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .print\:row-gap-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .print\:row-gap-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .print\:row-gap-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .print\:row-gap-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .print\:row-gap-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .print\:row-gap-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .print\:row-gap {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .print\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .print\:gap-y-1 {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .print\:gap-y-2 {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .print\:gap-y-3 {
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .print\:gap-y-4 {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .print\:gap-y-5 {
    grid-row-gap: 50px;
    row-gap: 50px;
  }

  .print\:gap-y-6 {
    grid-row-gap: 75px;
    row-gap: 75px;
  }

  .print\:gap-y-7 {
    grid-row-gap: 100px;
    row-gap: 100px;
  }

  .print\:gap-y-none {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .print\:gap-y-tiny {
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .print\:gap-y-small {
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .print\:gap-y-medium {
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .print\:gap-y-large {
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .print\:gap-y-huge {
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .print\:gap-y-margin-label-height {
    grid-row-gap: 26px;
    row-gap: 26px;
  }

  .print\:gap-y-nav-height {
    grid-row-gap: 80px;
    row-gap: 80px;
  }

  .print\:gap-y {
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .print\:grid-flow-row {
    grid-auto-flow: row;
  }

  .print\:grid-flow-col {
    grid-auto-flow: column;
  }

  .print\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .print\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .print\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .print\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .print\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .print\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .print\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .print\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .print\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .print\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .print\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .print\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .print\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .print\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .print\:grid-cols-none {
    grid-template-columns: none;
  }

  .print\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .print\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .print\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .print\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .print\:col-auto {
    grid-column: auto;
  }

  .print\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .print\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .print\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .print\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .print\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .print\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .print\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .print\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .print\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .print\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .print\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .print\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .print\:col-span-full {
    grid-column: 1 / -1;
  }

  .print\:col-start-1 {
    grid-column-start: 1;
  }

  .print\:col-start-2 {
    grid-column-start: 2;
  }

  .print\:col-start-3 {
    grid-column-start: 3;
  }

  .print\:col-start-4 {
    grid-column-start: 4;
  }

  .print\:col-start-5 {
    grid-column-start: 5;
  }

  .print\:col-start-6 {
    grid-column-start: 6;
  }

  .print\:col-start-7 {
    grid-column-start: 7;
  }

  .print\:col-start-8 {
    grid-column-start: 8;
  }

  .print\:col-start-9 {
    grid-column-start: 9;
  }

  .print\:col-start-10 {
    grid-column-start: 10;
  }

  .print\:col-start-11 {
    grid-column-start: 11;
  }

  .print\:col-start-12 {
    grid-column-start: 12;
  }

  .print\:col-start-13 {
    grid-column-start: 13;
  }

  .print\:col-start-auto {
    grid-column-start: auto;
  }

  .print\:col-end-1 {
    grid-column-end: 1;
  }

  .print\:col-end-2 {
    grid-column-end: 2;
  }

  .print\:col-end-3 {
    grid-column-end: 3;
  }

  .print\:col-end-4 {
    grid-column-end: 4;
  }

  .print\:col-end-5 {
    grid-column-end: 5;
  }

  .print\:col-end-6 {
    grid-column-end: 6;
  }

  .print\:col-end-7 {
    grid-column-end: 7;
  }

  .print\:col-end-8 {
    grid-column-end: 8;
  }

  .print\:col-end-9 {
    grid-column-end: 9;
  }

  .print\:col-end-10 {
    grid-column-end: 10;
  }

  .print\:col-end-11 {
    grid-column-end: 11;
  }

  .print\:col-end-12 {
    grid-column-end: 12;
  }

  .print\:col-end-13 {
    grid-column-end: 13;
  }

  .print\:col-end-auto {
    grid-column-end: auto;
  }

  .print\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .print\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .print\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .print\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .print\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .print\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .print\:grid-rows-none {
    grid-template-rows: none;
  }

  .print\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .print\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .print\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .print\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .print\:row-auto {
    grid-row: auto;
  }

  .print\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .print\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .print\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .print\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .print\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .print\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .print\:row-span-full {
    grid-row: 1 / -1;
  }

  .print\:row-start-1 {
    grid-row-start: 1;
  }

  .print\:row-start-2 {
    grid-row-start: 2;
  }

  .print\:row-start-3 {
    grid-row-start: 3;
  }

  .print\:row-start-4 {
    grid-row-start: 4;
  }

  .print\:row-start-5 {
    grid-row-start: 5;
  }

  .print\:row-start-6 {
    grid-row-start: 6;
  }

  .print\:row-start-7 {
    grid-row-start: 7;
  }

  .print\:row-start-auto {
    grid-row-start: auto;
  }

  .print\:row-end-1 {
    grid-row-end: 1;
  }

  .print\:row-end-2 {
    grid-row-end: 2;
  }

  .print\:row-end-3 {
    grid-row-end: 3;
  }

  .print\:row-end-4 {
    grid-row-end: 4;
  }

  .print\:row-end-5 {
    grid-row-end: 5;
  }

  .print\:row-end-6 {
    grid-row-end: 6;
  }

  .print\:row-end-7 {
    grid-row-end: 7;
  }

  .print\:row-end-auto {
    grid-row-end: auto;
  }

  .print\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
            transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .print\:transform-none {
    -webkit-transform: none;
            transform: none;
  }

  .print\:origin-center {
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .print\:origin-top {
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  .print\:origin-top-right {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  .print\:origin-right {
    -webkit-transform-origin: right;
            transform-origin: right;
  }

  .print\:origin-bottom-right {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  .print\:origin-bottom {
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

  .print\:origin-bottom-left {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .print\:origin-left {
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .print\:origin-top-left {
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .print\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .print\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .print\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .print\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .print\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .print\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .print\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .print\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .print\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .print\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .print\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .print\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .print\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .print\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .print\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .print\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .print\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .print\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .print\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .print\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .print\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .print\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .print\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .print\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .print\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .print\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .print\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .print\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .print\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .print\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .print\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .print\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .print\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .print\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .print\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .print\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .print\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .print\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .print\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .print\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .print\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .print\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .print\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .print\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .print\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .print\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .print\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .print\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .print\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .print\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .print\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .print\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .print\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .print\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .print\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .print\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .print\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .print\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .print\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .print\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .print\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .print\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .print\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .print\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .print\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .print\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .print\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .print\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .print\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .print\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .print\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .print\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .print\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .print\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .print\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .print\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .print\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .print\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .print\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .print\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .print\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .print\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .print\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .print\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .print\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .print\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .print\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .print\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .print\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .print\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .print\:rotate-0 {
    --transform-rotate: 0;
  }

  .print\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .print\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .print\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .print\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .print\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .print\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .print\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .print\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .print\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .print\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .print\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .print\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .print\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .print\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .print\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .print\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .print\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .print\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .print\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .print\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .print\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .print\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .print\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .print\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .print\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .print\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .print\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .print\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .print\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .print\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .print\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .print\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .print\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .print\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .print\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .print\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .print\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .print\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .print\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .print\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .print\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .print\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .print\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .print\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .print\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .print\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .print\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .print\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .print\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .print\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .print\:translate-x-1 {
    --transform-translate-x: 5px;
  }

  .print\:translate-x-2 {
    --transform-translate-x: 10px;
  }

  .print\:translate-x-3 {
    --transform-translate-x: 15px;
  }

  .print\:translate-x-4 {
    --transform-translate-x: 30px;
  }

  .print\:translate-x-5 {
    --transform-translate-x: 50px;
  }

  .print\:translate-x-6 {
    --transform-translate-x: 75px;
  }

  .print\:translate-x-7 {
    --transform-translate-x: 100px;
  }

  .print\:translate-x-none {
    --transform-translate-x: 0px;
  }

  .print\:translate-x-tiny {
    --transform-translate-x: 10px;
  }

  .print\:translate-x-small {
    --transform-translate-x: 20px;
  }

  .print\:translate-x-medium {
    --transform-translate-x: 30px;
  }

  .print\:translate-x-large {
    --transform-translate-x: 40px;
  }

  .print\:translate-x-huge {
    --transform-translate-x: 60px;
  }

  .print\:translate-x-margin-label-height {
    --transform-translate-x: 26px;
  }

  .print\:translate-x-nav-height {
    --transform-translate-x: 80px;
  }

  .print\:-translate-x-1 {
    --transform-translate-x: -5px;
  }

  .print\:-translate-x-2 {
    --transform-translate-x: -10px;
  }

  .print\:-translate-x-3 {
    --transform-translate-x: -15px;
  }

  .print\:-translate-x-4 {
    --transform-translate-x: -30px;
  }

  .print\:-translate-x-5 {
    --transform-translate-x: -50px;
  }

  .print\:-translate-x-6 {
    --transform-translate-x: -75px;
  }

  .print\:-translate-x-7 {
    --transform-translate-x: -100px;
  }

  .print\:-translate-x-none {
    --transform-translate-x: 0px;
  }

  .print\:-translate-x-tiny {
    --transform-translate-x: -10px;
  }

  .print\:-translate-x-small {
    --transform-translate-x: -20px;
  }

  .print\:-translate-x-medium {
    --transform-translate-x: -30px;
  }

  .print\:-translate-x-large {
    --transform-translate-x: -40px;
  }

  .print\:-translate-x-huge {
    --transform-translate-x: -60px;
  }

  .print\:-translate-x-margin-label-height {
    --transform-translate-x: -26px;
  }

  .print\:-translate-x-nav-height {
    --transform-translate-x: -80px;
  }

  .print\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .print\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .print\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .print\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .print\:translate-y-1 {
    --transform-translate-y: 5px;
  }

  .print\:translate-y-2 {
    --transform-translate-y: 10px;
  }

  .print\:translate-y-3 {
    --transform-translate-y: 15px;
  }

  .print\:translate-y-4 {
    --transform-translate-y: 30px;
  }

  .print\:translate-y-5 {
    --transform-translate-y: 50px;
  }

  .print\:translate-y-6 {
    --transform-translate-y: 75px;
  }

  .print\:translate-y-7 {
    --transform-translate-y: 100px;
  }

  .print\:translate-y-none {
    --transform-translate-y: 0px;
  }

  .print\:translate-y-tiny {
    --transform-translate-y: 10px;
  }

  .print\:translate-y-small {
    --transform-translate-y: 20px;
  }

  .print\:translate-y-medium {
    --transform-translate-y: 30px;
  }

  .print\:translate-y-large {
    --transform-translate-y: 40px;
  }

  .print\:translate-y-huge {
    --transform-translate-y: 60px;
  }

  .print\:translate-y-margin-label-height {
    --transform-translate-y: 26px;
  }

  .print\:translate-y-nav-height {
    --transform-translate-y: 80px;
  }

  .print\:-translate-y-1 {
    --transform-translate-y: -5px;
  }

  .print\:-translate-y-2 {
    --transform-translate-y: -10px;
  }

  .print\:-translate-y-3 {
    --transform-translate-y: -15px;
  }

  .print\:-translate-y-4 {
    --transform-translate-y: -30px;
  }

  .print\:-translate-y-5 {
    --transform-translate-y: -50px;
  }

  .print\:-translate-y-6 {
    --transform-translate-y: -75px;
  }

  .print\:-translate-y-7 {
    --transform-translate-y: -100px;
  }

  .print\:-translate-y-none {
    --transform-translate-y: 0px;
  }

  .print\:-translate-y-tiny {
    --transform-translate-y: -10px;
  }

  .print\:-translate-y-small {
    --transform-translate-y: -20px;
  }

  .print\:-translate-y-medium {
    --transform-translate-y: -30px;
  }

  .print\:-translate-y-large {
    --transform-translate-y: -40px;
  }

  .print\:-translate-y-huge {
    --transform-translate-y: -60px;
  }

  .print\:-translate-y-margin-label-height {
    --transform-translate-y: -26px;
  }

  .print\:-translate-y-nav-height {
    --transform-translate-y: -80px;
  }

  .print\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .print\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .print\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .print\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .print\:hover\:translate-x-1:hover {
    --transform-translate-x: 5px;
  }

  .print\:hover\:translate-x-2:hover {
    --transform-translate-x: 10px;
  }

  .print\:hover\:translate-x-3:hover {
    --transform-translate-x: 15px;
  }

  .print\:hover\:translate-x-4:hover {
    --transform-translate-x: 30px;
  }

  .print\:hover\:translate-x-5:hover {
    --transform-translate-x: 50px;
  }

  .print\:hover\:translate-x-6:hover {
    --transform-translate-x: 75px;
  }

  .print\:hover\:translate-x-7:hover {
    --transform-translate-x: 100px;
  }

  .print\:hover\:translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .print\:hover\:translate-x-tiny:hover {
    --transform-translate-x: 10px;
  }

  .print\:hover\:translate-x-small:hover {
    --transform-translate-x: 20px;
  }

  .print\:hover\:translate-x-medium:hover {
    --transform-translate-x: 30px;
  }

  .print\:hover\:translate-x-large:hover {
    --transform-translate-x: 40px;
  }

  .print\:hover\:translate-x-huge:hover {
    --transform-translate-x: 60px;
  }

  .print\:hover\:translate-x-margin-label-height:hover {
    --transform-translate-x: 26px;
  }

  .print\:hover\:translate-x-nav-height:hover {
    --transform-translate-x: 80px;
  }

  .print\:hover\:-translate-x-1:hover {
    --transform-translate-x: -5px;
  }

  .print\:hover\:-translate-x-2:hover {
    --transform-translate-x: -10px;
  }

  .print\:hover\:-translate-x-3:hover {
    --transform-translate-x: -15px;
  }

  .print\:hover\:-translate-x-4:hover {
    --transform-translate-x: -30px;
  }

  .print\:hover\:-translate-x-5:hover {
    --transform-translate-x: -50px;
  }

  .print\:hover\:-translate-x-6:hover {
    --transform-translate-x: -75px;
  }

  .print\:hover\:-translate-x-7:hover {
    --transform-translate-x: -100px;
  }

  .print\:hover\:-translate-x-none:hover {
    --transform-translate-x: 0px;
  }

  .print\:hover\:-translate-x-tiny:hover {
    --transform-translate-x: -10px;
  }

  .print\:hover\:-translate-x-small:hover {
    --transform-translate-x: -20px;
  }

  .print\:hover\:-translate-x-medium:hover {
    --transform-translate-x: -30px;
  }

  .print\:hover\:-translate-x-large:hover {
    --transform-translate-x: -40px;
  }

  .print\:hover\:-translate-x-huge:hover {
    --transform-translate-x: -60px;
  }

  .print\:hover\:-translate-x-margin-label-height:hover {
    --transform-translate-x: -26px;
  }

  .print\:hover\:-translate-x-nav-height:hover {
    --transform-translate-x: -80px;
  }

  .print\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .print\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .print\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .print\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .print\:hover\:translate-y-1:hover {
    --transform-translate-y: 5px;
  }

  .print\:hover\:translate-y-2:hover {
    --transform-translate-y: 10px;
  }

  .print\:hover\:translate-y-3:hover {
    --transform-translate-y: 15px;
  }

  .print\:hover\:translate-y-4:hover {
    --transform-translate-y: 30px;
  }

  .print\:hover\:translate-y-5:hover {
    --transform-translate-y: 50px;
  }

  .print\:hover\:translate-y-6:hover {
    --transform-translate-y: 75px;
  }

  .print\:hover\:translate-y-7:hover {
    --transform-translate-y: 100px;
  }

  .print\:hover\:translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .print\:hover\:translate-y-tiny:hover {
    --transform-translate-y: 10px;
  }

  .print\:hover\:translate-y-small:hover {
    --transform-translate-y: 20px;
  }

  .print\:hover\:translate-y-medium:hover {
    --transform-translate-y: 30px;
  }

  .print\:hover\:translate-y-large:hover {
    --transform-translate-y: 40px;
  }

  .print\:hover\:translate-y-huge:hover {
    --transform-translate-y: 60px;
  }

  .print\:hover\:translate-y-margin-label-height:hover {
    --transform-translate-y: 26px;
  }

  .print\:hover\:translate-y-nav-height:hover {
    --transform-translate-y: 80px;
  }

  .print\:hover\:-translate-y-1:hover {
    --transform-translate-y: -5px;
  }

  .print\:hover\:-translate-y-2:hover {
    --transform-translate-y: -10px;
  }

  .print\:hover\:-translate-y-3:hover {
    --transform-translate-y: -15px;
  }

  .print\:hover\:-translate-y-4:hover {
    --transform-translate-y: -30px;
  }

  .print\:hover\:-translate-y-5:hover {
    --transform-translate-y: -50px;
  }

  .print\:hover\:-translate-y-6:hover {
    --transform-translate-y: -75px;
  }

  .print\:hover\:-translate-y-7:hover {
    --transform-translate-y: -100px;
  }

  .print\:hover\:-translate-y-none:hover {
    --transform-translate-y: 0px;
  }

  .print\:hover\:-translate-y-tiny:hover {
    --transform-translate-y: -10px;
  }

  .print\:hover\:-translate-y-small:hover {
    --transform-translate-y: -20px;
  }

  .print\:hover\:-translate-y-medium:hover {
    --transform-translate-y: -30px;
  }

  .print\:hover\:-translate-y-large:hover {
    --transform-translate-y: -40px;
  }

  .print\:hover\:-translate-y-huge:hover {
    --transform-translate-y: -60px;
  }

  .print\:hover\:-translate-y-margin-label-height:hover {
    --transform-translate-y: -26px;
  }

  .print\:hover\:-translate-y-nav-height:hover {
    --transform-translate-y: -80px;
  }

  .print\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .print\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .print\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .print\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .print\:focus\:translate-x-1:focus {
    --transform-translate-x: 5px;
  }

  .print\:focus\:translate-x-2:focus {
    --transform-translate-x: 10px;
  }

  .print\:focus\:translate-x-3:focus {
    --transform-translate-x: 15px;
  }

  .print\:focus\:translate-x-4:focus {
    --transform-translate-x: 30px;
  }

  .print\:focus\:translate-x-5:focus {
    --transform-translate-x: 50px;
  }

  .print\:focus\:translate-x-6:focus {
    --transform-translate-x: 75px;
  }

  .print\:focus\:translate-x-7:focus {
    --transform-translate-x: 100px;
  }

  .print\:focus\:translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .print\:focus\:translate-x-tiny:focus {
    --transform-translate-x: 10px;
  }

  .print\:focus\:translate-x-small:focus {
    --transform-translate-x: 20px;
  }

  .print\:focus\:translate-x-medium:focus {
    --transform-translate-x: 30px;
  }

  .print\:focus\:translate-x-large:focus {
    --transform-translate-x: 40px;
  }

  .print\:focus\:translate-x-huge:focus {
    --transform-translate-x: 60px;
  }

  .print\:focus\:translate-x-margin-label-height:focus {
    --transform-translate-x: 26px;
  }

  .print\:focus\:translate-x-nav-height:focus {
    --transform-translate-x: 80px;
  }

  .print\:focus\:-translate-x-1:focus {
    --transform-translate-x: -5px;
  }

  .print\:focus\:-translate-x-2:focus {
    --transform-translate-x: -10px;
  }

  .print\:focus\:-translate-x-3:focus {
    --transform-translate-x: -15px;
  }

  .print\:focus\:-translate-x-4:focus {
    --transform-translate-x: -30px;
  }

  .print\:focus\:-translate-x-5:focus {
    --transform-translate-x: -50px;
  }

  .print\:focus\:-translate-x-6:focus {
    --transform-translate-x: -75px;
  }

  .print\:focus\:-translate-x-7:focus {
    --transform-translate-x: -100px;
  }

  .print\:focus\:-translate-x-none:focus {
    --transform-translate-x: 0px;
  }

  .print\:focus\:-translate-x-tiny:focus {
    --transform-translate-x: -10px;
  }

  .print\:focus\:-translate-x-small:focus {
    --transform-translate-x: -20px;
  }

  .print\:focus\:-translate-x-medium:focus {
    --transform-translate-x: -30px;
  }

  .print\:focus\:-translate-x-large:focus {
    --transform-translate-x: -40px;
  }

  .print\:focus\:-translate-x-huge:focus {
    --transform-translate-x: -60px;
  }

  .print\:focus\:-translate-x-margin-label-height:focus {
    --transform-translate-x: -26px;
  }

  .print\:focus\:-translate-x-nav-height:focus {
    --transform-translate-x: -80px;
  }

  .print\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .print\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .print\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .print\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .print\:focus\:translate-y-1:focus {
    --transform-translate-y: 5px;
  }

  .print\:focus\:translate-y-2:focus {
    --transform-translate-y: 10px;
  }

  .print\:focus\:translate-y-3:focus {
    --transform-translate-y: 15px;
  }

  .print\:focus\:translate-y-4:focus {
    --transform-translate-y: 30px;
  }

  .print\:focus\:translate-y-5:focus {
    --transform-translate-y: 50px;
  }

  .print\:focus\:translate-y-6:focus {
    --transform-translate-y: 75px;
  }

  .print\:focus\:translate-y-7:focus {
    --transform-translate-y: 100px;
  }

  .print\:focus\:translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .print\:focus\:translate-y-tiny:focus {
    --transform-translate-y: 10px;
  }

  .print\:focus\:translate-y-small:focus {
    --transform-translate-y: 20px;
  }

  .print\:focus\:translate-y-medium:focus {
    --transform-translate-y: 30px;
  }

  .print\:focus\:translate-y-large:focus {
    --transform-translate-y: 40px;
  }

  .print\:focus\:translate-y-huge:focus {
    --transform-translate-y: 60px;
  }

  .print\:focus\:translate-y-margin-label-height:focus {
    --transform-translate-y: 26px;
  }

  .print\:focus\:translate-y-nav-height:focus {
    --transform-translate-y: 80px;
  }

  .print\:focus\:-translate-y-1:focus {
    --transform-translate-y: -5px;
  }

  .print\:focus\:-translate-y-2:focus {
    --transform-translate-y: -10px;
  }

  .print\:focus\:-translate-y-3:focus {
    --transform-translate-y: -15px;
  }

  .print\:focus\:-translate-y-4:focus {
    --transform-translate-y: -30px;
  }

  .print\:focus\:-translate-y-5:focus {
    --transform-translate-y: -50px;
  }

  .print\:focus\:-translate-y-6:focus {
    --transform-translate-y: -75px;
  }

  .print\:focus\:-translate-y-7:focus {
    --transform-translate-y: -100px;
  }

  .print\:focus\:-translate-y-none:focus {
    --transform-translate-y: 0px;
  }

  .print\:focus\:-translate-y-tiny:focus {
    --transform-translate-y: -10px;
  }

  .print\:focus\:-translate-y-small:focus {
    --transform-translate-y: -20px;
  }

  .print\:focus\:-translate-y-medium:focus {
    --transform-translate-y: -30px;
  }

  .print\:focus\:-translate-y-large:focus {
    --transform-translate-y: -40px;
  }

  .print\:focus\:-translate-y-huge:focus {
    --transform-translate-y: -60px;
  }

  .print\:focus\:-translate-y-margin-label-height:focus {
    --transform-translate-y: -26px;
  }

  .print\:focus\:-translate-y-nav-height:focus {
    --transform-translate-y: -80px;
  }

  .print\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .print\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .print\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .print\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .print\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .print\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .print\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .print\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .print\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .print\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .print\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .print\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .print\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .print\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .print\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .print\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .print\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .print\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .print\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .print\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .print\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .print\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .print\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .print\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .print\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .print\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .print\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .print\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .print\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .print\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .print\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .print\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .print\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .print\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .print\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .print\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .print\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .print\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .print\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .print\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .print\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .print\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .print\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .print\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .print\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .print\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .print\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .print\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .print\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .print\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .print\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .print\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .print\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .print\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .print\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .print\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .print\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .print\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .print\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .print\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .print\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .print\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .print\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .print\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .print\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .print\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .print\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .print\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .print\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .print\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .print\:transition-none {
    transition-property: none;
  }

  .print\:transition-all {
    transition-property: all;
  }

  .print\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  }

  .print\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .print\:transition-opacity {
    transition-property: opacity;
  }

  .print\:transition-shadow {
    transition-property: box-shadow;
  }

  .print\:transition-transform {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .print\:ease-linear {
    transition-timing-function: linear;
  }

  .print\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .print\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .print\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .print\:duration-75 {
    transition-duration: 75ms;
  }

  .print\:duration-100 {
    transition-duration: 100ms;
  }

  .print\:duration-150 {
    transition-duration: 150ms;
  }

  .print\:duration-200 {
    transition-duration: 200ms;
  }

  .print\:duration-300 {
    transition-duration: 300ms;
  }

  .print\:duration-500 {
    transition-duration: 500ms;
  }

  .print\:duration-700 {
    transition-duration: 700ms;
  }

  .print\:duration-1000 {
    transition-duration: 1000ms;
  }

  .print\:delay-75 {
    transition-delay: 75ms;
  }

  .print\:delay-100 {
    transition-delay: 100ms;
  }

  .print\:delay-150 {
    transition-delay: 150ms;
  }

  .print\:delay-200 {
    transition-delay: 200ms;
  }

  .print\:delay-300 {
    transition-delay: 300ms;
  }

  .print\:delay-500 {
    transition-delay: 500ms;
  }

  .print\:delay-700 {
    transition-delay: 700ms;
  }

  .print\:delay-1000 {
    transition-delay: 1000ms;
  }

  .print\:animate-none {
    -webkit-animation: none;
            animation: none;
  }

  .print\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }

  .print\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .print\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .print\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite;
  }
}