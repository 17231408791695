@import "node_modules/styleguide/src/assets/sass/common.sass";

$timingfunction: cubic-bezier(0.175, 0.885, 0.32, 1.275);

.ok-menu {
  position: relative;
  width: 40px;
  height: 40px;
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: -100%;
    top: 0px;
    right: 0px;
    padding-top: 60px;
    transition: right ease-in-out 300ms;
    background-color: white;
    height: 100%;
    width: 260px;
    box-shadow: none;
    overflow-y: auto;

    @include osg-mq-tablet-and-up {
      width: 400px;
      box-shadow: 0px 0px 40px 1px #2c2c2c;
      padding-top: 100px;
    }
  }

  &-hamburger {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;

    span {
      position: absolute;
      left: calc(50% - (40px / 2));
      width: 40px;
      display: block;
      height: 2px;
      background-color: #2c2c2c;
      transform: rotate(0deg);
      transition: transform 300ms $osg-cubic-bezier, opacity 0ms;

      &:first-child {
        top: 7.5px;
      }
      &:nth-last-of-type(2) {
        top: 20px;
      }
      &:last-child {
        top: 32.5px;
      }
    }

    &:hover {
      span {
        background-color: #868585;
      }
    }
  }

  &-name {
    padding: 15px 0px;
    position: absolute;
    top: 0px;
    left: 0;
    width: calc(100% - 52px);
    @include osg-mq-tablet-and-up() {
      position: absolute;
      padding: 25px 0px;
      top: 10px;
      left: 0;
      width: calc(100% - 78px);
    }
  }

  &-content {
    visibility: hidden;
    padding-bottom: 60px;
    @include osg-mq-tablet-and-up() {
      padding-bottom: 100px;
    }
  }

  &.open {
    &::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      content: " ";
      background-color: #2c2c2c99; // Backdrop color
      z-index: 120; // Modal backdrop z-index, see tailwinf.config.js
    }

    .ok-menu-hamburger {
      position: fixed;
      z-index: 131; // Modal backdrop z-index + 1 so it gets on top
      right: 5px;
      top: 5px;
      height: 50px;
      width: 50px;
      box-sizing: border-box;
      background-color: #f9c66b;
      border-radius: 50%;
      transition: background-color 500ms $timingfunction 0ms;
      @include osg-mq-tablet-and-up() {
        height: 60px;
        width: 60px;
        right: 20px;
        top: 20px;
      }
      span {
        width: 28px;
        left: calc(50% - (28px / 2));

        &:first-child {
          top: 50%;
          transform: rotate(45deg);
        }
        &:nth-last-of-type(2) {
          opacity: 0%;
        }
        &:last-child {
          top: 50%;
          transform: rotate(-45deg);
        }
      }
      &:hover {
        background-color: $osg-color-blue-hover;
        span {
          background-color: white;
        }
      }
    }

    .ok-menu-wrapper {
      z-index: 130; // Modal z-index
    }

    .ok-menu-content {
      visibility: visible;
    }
  }

  &.closed {
    .ok-menu-wrapper {
      transition: right ease-in-out 300ms;
      right: -100%;
      height: 100vh;
    }
  }
}
