.endre-modal {
  display: inline;
}
.endre-modal-button{
    padding: 2px;
    border: none;
    display: block;
}
.endre-modal-body {
  display: block;
  height: auto;
  padding: 20px;
}

.endre-modal-info {
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.endre-modal-form {
  display: flex;
  flex-direction: row;
  width: 100%;

}
.endringsskjema {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.select-kjoretoy {
  width: 50%;
}

.oblat-detaljer {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-flow: column;

}
.oblat-detaljer > span > b {
  margin-right: 8px;
}

.endre-oblat-radio > label:focus-within {
  outline: rgb(77, 144, 254) auto 5px;
}

input[type="radio"][name="OblatTypeGroup"]{
  position: absolute;
  left: -2000px;
}
input[type="radio"][name="KjoretoyGroup"]{
  position: absolute;
  left: -2000px;
}
.endre-oblat-radio.radio {
  margin: 0;
}

.endre-oblat-radio.selected.radio > label > .checkmark {
  position: absolute;
  left: 8px;
  top: 10px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #656565;
  -webkit-box-shadow:inset 0px 0px 0px 2px #FFF;
  -moz-box-shadow:inset 0px 0px 0px 2px #FFF;
  box-shadow:inset 0px 0px 0px 2px #FFF;
}

.endre-oblat-radio.radio > label > .checkmark {
  position: absolute;
  left: 8px;
  top: 10px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid #656565;
}

.endre-oblat-radio.radio > label {
  width: 100%;
  cursor: pointer;
  padding: 8px;
  padding-left: 30px;
  border-radius: 8px;
}

.oblattype-not-selected{
  color: #717171;
  font-style: italic;
}


.endre-oblat-radio > label > .glyphicon {
  margin-left: 5px;
}

.endre-oblat-radio.radio > label:hover {
  background-color: #97979735;
}

.full-height-col{
  height:100%;
}

.velg-kjoretoy {
  position: absolute;
  bottom: 0;
}