@import "~styleguide/src/assets/sass/common/";
.message-to-user-box {
  .message-to-user-box--icon {
    margin-top: 15px;
    font-size: 50px;
    @include osg-mq-tablet-and-up {
      margin-top: 30px;
      font-size: 60px;
    }
  }
}
