.radio-label {
  display: inline-block;
  position: relative;
  padding-left: 20px;

  .radio-checkmark {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0; // calc(20px / 2 - 5px / 2);
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #2a2859;
    background-color: white;
    border-radius: 50%;
  }

  .radio-label-text {
    display: inline-block;
    margin-left: 5px;
  }

  input {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: -1;
  }

  input:disabled ~ .radio-checkmark {
    border-color: #b9b9b9;
  }

  input:disabled ~ .radio-label-text {
    color: #b9b9b9;
  }

  input:focus ~ .radio-checkmark {
    outline: auto 5px -webkit-focus-ring-color;
    border-color: #1f42aa;
  }

  input:focus ~ .radio-label-text {
    color: #1f42aa;
  }

  &:hover {
    cursor: pointer;

    .radio-checkmark {
      border-color: #1f42aa;
    }
    .radio-label-text {
      color: #1f42aa;
    }
  }

  input:checked ~ .radio-checkmark {
    border: none;
    background-color: #1f42aa;
  }

  input:checked ~ .radio-label-text {
    font-weight: 500;
  }

  input:checked ~ .radio-checkmark:after {
    $checkmarkSize: 8px;
    position: absolute;
    top: calc((20px / 2) - (#{$checkmarkSize} / 2));
    left: calc((20px / 2) - (#{$checkmarkSize} / 2));
    display: block;
    width: $checkmarkSize;
    height: $checkmarkSize;
    background-color: white;
    border-radius: 50%;
    content: "";
  }
}
