.footer-link {
  display: flex;
  align-items: flex-start;
  color: white;
  line-height: 2.1em;
  &:hover {
    cursor: pointer;
    color: #b3f5ff;
    .footer-link--icon {
      text-decoration: none;
      color: #b3f5ff;
    }
    .footer-link--text {
      color: #b3f5ff;
      text-decoration: underline;
      text-underline-position: below;
    }
  }
  &--text {
    padding-top: 2px;
  }
  &--icon {
    font-size: 1.1rem;
  }
  &:focus {
    color: #b3f5ff;
    .footer-link--text {
      color: #b3f5ff;
      text-decoration: underline;
      text-underline-position: below;
    }
  }
}
