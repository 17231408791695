@import "node_modules/styleguide/src/assets/sass/common.sass";

.receipt-table-container-min-height {
  min-height: 568px;
  @include osg-mq-tablet-and-up {
    // width: 1100px;
    min-height: 775px;
  }
}

.osg-simple-table {
  thead > tr > th {
    padding: 20px 5px;
  }
  tbody > tr > td {
    padding: 20px 5px;
  }

  @include osg-mq-tablet-and-up {
    thead > tr > th {
      padding: 20px 40px;
    }
    tbody > tr > td {
      padding: 20px 40px;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      background-color: white;
      &:hover {
        filter: brightness(97%);
      }
    }
  }
}
