.osg-button--big {
  // height: 72px;
}

.osg-button--normal {
  max-height: 52px;
  min-width: 52px;
}

.osg-button--normal.osg-button--circle {
  max-height: unset;
  min-width: unset;
}

.osg-button--small {
  height: 36px;
}
