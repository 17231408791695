.line-items-container {
  position: relative;
  height: 100%;
  border-bottom: solid 2px #e1e1e1;
}

.line-items-heading {
  position: absolute;
  top: -40px;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: white;
}

.line-items {
  margin-top: 40px;
  max-height: calc(100% - 80px);
  overflow-y: auto;
}

.total-price {
  margin-top: 20px;
}
