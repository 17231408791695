.kvittering-betalingsdetaljer {
  max-width: 400px;
  display: block;
}

.kvittering-betalingsdetaljer + .kvittering-betalingsdetaljer {
  margin-top: 5px;
}

.kvittering-detalj-label {
  display: block;
  margin: 0;
  padding: 0;
}

.kvittering-detalj-value {
  margin: 0;
  padding: 0;
}

.kvittering-kvitteringsnummer {
  font-size: 21px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 5px;
}

.kvittering-knapperad {
  border-top: solid 1px #cccccc;
}

.kvittering-knapperad-avbryt {
  display: flex;
  border-top: solid 1px #cccccc;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 15px 0;
  padding: 10px 5px;
}

.kvittering-table-borderless {
  border: none;
  width: 100%;
}

.kvittering-table-borderless > tbody > tr > td {
  height: 35px;
  vertical-align: text-top !important;
}
.kvittering-table-borderless > tbody > .total-amount-row > td > div > b {
  font-size: 18px;
}

.kvittering-table-borderless > tbody > .total-amount-row > td > .sum-div {
  border-top: solid 1px #979797;
  padding: 13px 5px 0 5px;
  display: inline;
}
.kvittering-table-borderless > tbody > .total-amount-row > td {
  vertical-align: bottom;
  height: 70px;
}

.kvittering-oblater {
  padding: 40px 20px;
  margin-bottom: 50px;
  border-radius: 0px;
  background-color: rgba(229, 229, 229, 0.1);
  border: solid 1px #e5e5e5;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.ingen-betalinger-infoboks {
  text-align: center;
}

.spinner-div {
  display: flex;
  justify-content: center;
}

.address-for-print {
  display: none;
  text-align: start;
}
.address-for-print > ul {
  list-style: none;
}
.address-for-print > h2 {
  font-size: 20px;
}

.totalsum-div {
  display: inline-flex;
  border-top: solid 1px #979797;
}

.manuell-kjøp-melding {
  display: none;
}

.theadComponent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.theadComponentText {
  color: #4d4d4d;
}

.arrow-indicator {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  margin-left: 15px;
}

.arrow-indicator-up {
  width: 0px;
  height: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #c5c4c4;
}

.arrow-indicator-down {
  margin-top: 2px;
  width: 0px;
  height: 0px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #c5c4c4;
}

.-sort-desc {
  box-shadow: none !important;
}

.-sort-desc::before {
  content: "";
  float: right;
}

.-sort-asc {
  box-shadow: none !important;
}

.-sort-asc::before {
  content: "";
  float: right;
}

.-sort-desc .arrow-indicator > .arrow-indicator-down {
  border-top-color: #4d4d4d;
}

.-sort-asc .arrow-indicator > .arrow-indicator-up {
  border-bottom-color: #4d4d4d;
}

@media print {
  header {
    display: none;
  }

  footer {
    display: none !important;
  }

  nav {
    display: none;
  }

  .page-header > h1 {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    font-size: 25px;
  }

  .page-body {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .manuell-kjøp-melding {
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .kvittering-knapperad {
    display: none;
  }

  .kvittering-knapperad-avbryt {
    display: none;
  }

  .breadcrumbs-wrap {
    display: none;
  }

  .address-for-print {
    display: block;
  }
}

@media (max-width: 500px) {
}

.ordrelinje-print-row {
  font-size: 11px;
}
.kvittering-print-info {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 5px;
  text-align: start;
}
.kvittering-print-header {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 40px 5px 10px;
}
.kvittering-print-table-borderless {
  border: none;
  width: 100%;
}
.printdetails {
  padding-left: 5px;
  padding-right: 5px;
}
.kjoretoybeskrivelse {
  max-width: 250px;
  width: 250px;
}

.maincontentdescription {
  max-width: 500px;
}

.kvittering-print-table-borderless > tbody > tr > td {
  height: 20px;
  vertical-align: text-top;
  padding-top: 8px;
}

.kvittering-print-table-borderless > tbody > .total-amount-row > td > .sum-div {
  border-top: solid 1px #979797;
  padding: 8px 5px 0 5px;
  display: inline;
}
.kvittering-print-table-borderless > tbody > .total-amount-row > td {
  vertical-align: bottom;
  height: 35px;
  font-size: 14px;
}

.kvittering-print-oblater {
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 0px;
  background-color: rgba(229, 229, 229, 0.1);
  border: solid 1px #ededed;
}
