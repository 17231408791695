@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
  width: 100%;
  margin-top: 2px;
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__navigation {
      top: 5px;
      width: 25px;
      height: 25px;
      border: none;
      font-size: 0px;
      text-align: center;
      line-height: 20px;
      font-weight: 500;
      &:hover {
        color: #1f42aa;
      }
      &.react-datepicker__navigation--previous::after,
      &.react-datepicker__navigation--next::after {

        display: inline-block;
        font-family: "Oslo Icons";
        font-size: 25px;
        font-weight: 500;
        color: inherit;
        content: "\ea0f";
      }
      &.react-datepicker__navigation--previous::after {
        transform: rotate(-90deg);
      }
      &.react-datepicker__navigation--next::after {
        transform: rotate(90deg);
      }
    }

    // margin-left: -12px;
    &__month-container {
      box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.2);
      border: solid 2px #eaeaea;
    }
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    border-radius: unset;
    border: unset;
    &__header {
      border-radius: unset;
    }
    &__month-container {
      border-radius: unset;
    }
    &__day {
      &--selected {
        background-color: #2a2859;
        color: white;
      }
      &:hover {
        border-radius: unset;
        background-color: #1f42aa;
        color: white;
      }
      border-radius: unset;
    }
  }
}

.date-picker {
  &-wrapper {
    display: block;
    width: 100%;
  }

  &-input {
    &:disabled {
      outline: 2px #e1e1e1 solid;
      outline-offset: -2px;
      background-color: #f9f9f9;
    }

    &.state-danger {
      &:hover,
      &:focus {
        outline: 0px solid transparent;
      }
      border: 4px solid #ff8274b3;
    }

    &.state-warning {
      border: 4px solid #f9c66bb3;
    }

    &.state-success {
      border: 4px solid #c7f6c9b3;
    }
  }
}
