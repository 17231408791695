.cancel-modal {
  display: inline;
}
.cancel-modal-body {
  display: block;
  height: auto;
  padding: 20px;
}
.cancel-modal-button{
  padding: 2px;
  border: none;
  display: block;
}

.cancel-modal-info-div {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: solid 1px #faebcc;
  padding: 10px;
  border-radius: 5px;
}
.cancel-modal-info-div {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: solid 1px #faebcc;
  padding: 10px;
  border-radius: 5px;
}