.spinner-circle {
  $numOfItems: 10;
  $animationTime: calc(#{$numOfItems} * 0.1s);
  $diameter: 1em;
  $center: calc(#{$diameter} / 2);
  display: block;
  position: relative;
  width: $diameter;
  height: $diameter;
  // background-color: inherit;

  > * {
    opacity: 0;
    display: block;
    transform-origin: $center $center;
    animation: spinn $animationTime linear infinite;
    display: none;
  }

  > *:after {
    content: " ";
    $width: calc(#{$diameter} / 14);
    $height: calc(#{$diameter} / 4.5);
    display: block;
    position: absolute;
    color: inherit;
    width: $width;
    height: $height;
    left: calc(#{$center} - #{$width / 2});
    top: calc(#{$height} / 3);
    border-radius: 50%;
    background-color: currentColor;
  }

  $angleStep: calc(360deg / #{$numOfItems});
  @for $i from 0 through $numOfItems + 1 {
    $angle: calc(#{$angleStep} * #{$i});
    $delay: calc(#{$animationTime / #{$numOfItems}} * #{$i});
    > *:nth-child(#{$i}) {
      transform: rotate($angle);
      animation-delay: $delay;
      display: block;
    }
  }

  @keyframes spinn {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
