.tooltip {
  position: absolute;
  border: 1px solid theme("colors.blue.dark");
  padding: 5px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 7px);
  min-width: fit-content;
  word-wrap: none;
  .pointer {
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    width: 10px;
    height: 10px;
    top: 0px;
    left: 50%;
    transform: rotate(45deg) translate(-50%, -3px);
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    border-color: theme("colors.blue.dark");
    background-color: inherit;
  }
}
