.link {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.5em;
  border: solid currentColor;
  border-width: 0 0 0.15em 0;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
