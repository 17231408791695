/* React root element*/
.margin-label-height {
  margin-top: 25px;
}

body,
html {
  font-family: "Oslo Sans", sans-serif;
}

// Common components
.text-button {
  all: unset;
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.5em;
  border: solid currentColor;
  border-width: 0 0 0.15em 0;
  &:hover {
    cursor: pointer;
    color: #1f42aa;
  }
}

input::placeholder {
  color: #808080 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.no-breadcrumbs .main-content-margin {
  margin-top: 80px;
}

.main-content-margin {
  margin-top: 95px;
}
