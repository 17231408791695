.x-button {
  background: none;
  border: none;
  padding: 0px 0px;
  &:hover {
    cursor: pointer;
    i {
      color: #1f42aa;
    }
  }
}
