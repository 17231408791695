@import "../CommonStyles/InputElement.scss";

.input-element {
  textarea {
    line-height: inherit;
    width: auto;
    height: auto;
    resize: both;
  }
}
