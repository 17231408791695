.mbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &-message {
    margin: 5.33px;
  }
  &-icon.left {
    order: -1;
  }
}
