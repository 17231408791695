@import "node_modules/styleguide/src/assets/sass/common.sass";

.vehicle-table-header {
  border-bottom: 2px solid;
}

.vehicle-table {
  width: 100%;
  @include osg-mq-tablet-and-up {
    width: 90%;
  }
}

.vehicle-setting-table-body {
  min-height: 310px;
  @include osg-mq-tablet-and-up {
    min-height: 600px;
  }
}

.vehicle-table-row {
  @apply border-grey-dark;
  border: solid 0px 0px 1px 0px;
}
