.skip-link button {
  position: fixed;
  top: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.user-is-tabbing {
  .skip-link button:focus {
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
}
body:not(.user-is-tabbing) {
  .skip-link {
    display: none;
  }
}
