.--DOM-text-searcher-wrapper {
  position: relative;
}

.--DOM-text-searcher-wrapper,
.form-group {
  margin: 0;
}

.--DOM-text-searcher-wrapper > .form-group > span,
.--DOM-text-searcher-wrapper > .form-group > .input-group > span {
  height: 100%;
}

.--DOM-text-searcher-controll-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 10px 0px;
  right: 90px;
}

.--DOM-text-searcher-controll-btn {
  height: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  i {
    font-size: 20px;
  }
}

.--DOM-text-searcher-controll-btn:first-of-type {
  // &:after {
  //   position: relative;
  //   left: 16px;
  //   display: inline-block;
  //   content: " ";
  //   width: 0px;
  //   height: 70%;
  //   top:0;
  //   border-right: 1px solid grey;
  // }
  & i {
    transform: rotate(90deg);
  }
}

.--DOM-text-searcher-controll-btn:last-of-type {
  & i {
    transform: rotate(90deg);
  }
}
