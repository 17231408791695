.input-timecomponent-wrapper {
  position: relative;
}

.input-timecomponent-wrapper:focus-within .time-picker-container,
.input-timecomponent-wrapper .time-picker-container:hover,
.input-timecomponent-wrapper .time-picker-container:focus-within {
  display: flex;
}

.time-picker-container {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 280px;
  padding: 20px;
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.2);
  border: solid 2px #eaeaea;
  background-color: #ffffff;

  div:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.control-container {
  display: inline-flex;
  flex-direction: column;
  font-size: inherit;
  font-family: inherit;

  button {
    all: unset;
  }

  button:focus {
    outline: inherit;
    outline-style: auto;
    outline-color: auto;
    outline-width: 1px;
  }

  span {
    text-align: center;
    user-select: none;
  }

  [role="button"]:first-child,
  [role="button"]:last-child {
    color: #2a2859;
    &:hover {
      cursor: pointer;
      color: #1f42aa;
    }
    &:focus {
      color: #1f42aa;
    }
  }
}

.time-picker-input {
  &:hover {
    outline-color: #1f42aa;
  }

  &:focus {
    outline: 4px #1f42aa solid;
    outline-offset: -4px;
  }

  &:disabled {
    outline: 2px #e1e1e1 solid;
    outline-offset: -2px;
    background-color: #f9f9f9;
  }

  &.state-danger {
    &:hover,
    &:focus {
      outline: 0px solid transparent;
    }
    border: 4px solid #ff8274b3;
  }

  &.state-warning {
    border: 4px solid #f9c66bb3;
  }

  &.state-success {
    border: 4px solid #c7f6c9b3;
  }
}
