@import "node_modules/styleguide/src/assets/sass/common.sass";

.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @include osg-mq-tablet-and-up() {
    width: 100vw;
    height: 100vh;
  }
}

.modal-dialogue {
  width: 100%;
  height: 100%;
  max-width: 100%;
  // max-height: calc(100 * var("--vh"));
  @include osg-mq-tablet-and-up() {
    box-shadow: -6px 6px 12px -10px rgba(0, 0, 0, 0.8);
    height: initial;
    max-height: 100%;
    &.small {
      width: 550px;
    }
    &.medium {
      width: 675px;
    }
    &.large {
      width: 950px;
    }
    margin: 25px auto;
  }

  .modal-heading {
    background-color: white;
    display: flex;
    align-items: flex-start;
    &-space {
      align-self: stretch;
      width: 100%;
      background-color: white;
      min-height: 60px;
    }
    &-close-button {
      background-color: none;
      box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.6);
    }
    @include osg-mq-tablet-and-up() {
      background-color: inherit;
      &-close-button {
        position: initial;
        box-shadow: none;
      }
    }
  }
  .modal-content {
    margin-top: 0px;
    background-color: white;
    height: 100%;
  }
}

body {
  .modal-backdrop {
    display: none;
  }
  .modal-backdrop:last-child {
    display: block;
    transition: all ease-in-out 200ms;
  }
}

.modal-enter {
  opacity: 0 !important;
  .modal-dialogue {
    transform: scale(0, 0);
  }
}
.modal-enter-active {
  opacity: 1 !important;
  transition: opacity 400ms ease-in-out;
  .modal-dialogue {
    transform: scale(1, 1);
    transition: transform ease-in-out 400ms -200ms;
  }
}
.modal-enter-done {
  .modal-dialogue {
    opacity: 1;
    transform: scale(1, 1);
  }
}
.modal-exit {
  opacity: 1;
  .modal-dialogue {
    transform: scale(1, 1);
  }
}
.modal-exit-active {
  opacity: 0 !important;
  transition: opacity ease-in-out 400ms;
  .modal-dialogue {
    transform: scale(0, 0);
    transition: transform ease-in-out 400ms 0ms;
  }
}
