@import "node_modules/styleguide/src/assets/sass/common.sass";

.formfield-container {
  @include osg-mq-tablet-and-up {
    min-height: 330px;
  }
  fieldset {
    border: 0 none;
  }
}
