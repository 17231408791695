.overlapping-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  empty-cells: show;

  th {
    padding: 0px 10px;
  }
  th:first-child {
    padding: 10px 0px 10px 15px;
  }

  th:last-child {
    padding: 10px 15px 10px 0px;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
    td {
      padding: 0px 20px;
    }
    td:first-child {
      padding: 20px 0px 20px 15px;
    }

    td:last-child {
      padding: 20px 15px 20px 0px;
    }
  }
}

.table-row {
  width: 100%;
}
