.spinner-container {
  .dot {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #6fe9ff;
    & + & {
      margin-left: 15px;
    }
    animation: bounce 1.4s infinite ease-in-out both;
  }

  .dot.one {
    animation-delay: -0.32s;
  }

  .dot.two {
    animation-delay: -0.16s;
  }
}

.dot + .dot {
  margin-left: 3px;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
