@import "/node_modules/styleguide/src/assets/sass/common.sass";

.ok-breadcrumb {
  &:hover {
    span {
      border-bottom: 1px solid $osg-color-blue-hover;
    }
    i {
      color: $osg-color-blue-hover;
    }
  }
}
