.gjenomført-betaling-body {
  padding: 31px 0px 81px 0px;
  width: 100%;
  border-radius: 0px;
  background-color: #f9f9f9;
  border: solid 1px #f2f2f2; /*#f2f2f2*/
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gjenomført-betaling-body-icon {
  margin: 0 auto;
}

.gjenomført-betaling-body-header {
  font-size: 36px;
  margin-bottom: 42px;
  text-align: center;
}

.gjenomført-betaling-body-text {
  align-self: center;
  font-size: 23px;
  padding: 0px;
  max-width: 769px;
  text-align: center;
}

.gjenomført-betaling-button-group {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  justify-content: center;
}

.gjenomført-betaling-button {
  margin: 0;
}

.gjenomført-betaling-button > a {
  all: unset;
  font-family: inherit;
  color: inherit;
  margin: 0;
}

.gjenomført-betaling-button > a:hover {
  text-decoration: none;
}

.gjenomført-betaling-button + .gjenomført-betaling-button {
  margin: 0 0 0 30px;
}

.spinner-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 68px 14px;
  padding: 31px 0 81px 0;
  font-size: 60px;
}

@media (max-width: 769px) {
  .gjenomført-betaling-body {
    text-align: center;
    margin: 0px;
  }

  .gjenomført-betaling-button-group {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .gjenomført-betaling-button-group > a,
  .gjenomført-betaling-button + .gjenomført-betaling-button {
    margin: unset;
    margin-top: 15px;
    width: 90%;
  }
}
