.modal-dialogue{
    display: table
}

.alert-message{
    display: flex;
    flex-flow: row;
    align-items: stretch;
}

.alert-message > p{
    color: #4d4d4d;
}

.alert-logo{
    width: 22px;
    height: 22px;
    color: #f29f04;
    margin-right: 10px;
}

.ok-btn {
  min-width: 150px;
}