@import "src/styles/piggav/utils";

.faktura-betaling-body {
  width: 100%;
  padding: 31px 0px 81px 0px;
  border-radius: 0px;
  background-color: #f9f9f9;
  border: solid 1px #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.faktura-betaling-button-group {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  justify-content: space-between;
}

.faktura-betaling-button {
  margin: 0;
}

.faktura-betaling-button + .faktura-betaling-button {
  margin: 0 0 0 30px;
}

.faktura-betaling-body-headline {
  text-align: center;
  max-width: 563px;
  font-size: 36px;
  margin-bottom: 42px;
}

.faktura-betaling-body-text {
  max-width: 769px;
  padding: 0px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-size: 23px;

  .faktura-exclamation {
    margin: 0px 4px 0 0;
    width: 50px;
    height: 50px;
  }
}

.spinner-div {
  display: inline-flex;
  flex-direction: column;
  border: none;
  background: none;
  font-size: 60px;
}

@media (max-width: 769px) {
  .faktura-betaling-button-group {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .faktura-betaling-body-text {
  }

  .faktura-betaling-button {
    margin: unset;
    width: 90%;
  }

  .faktura-betaling-button + .faktura-betaling-button {
    margin: 0;
    margin-top: 15px;
  }
}
