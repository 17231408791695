.expandable-message {
  max-height: 0px;
  transition: max-height ease-in-out 200ms;
  // display: none;
  overflow: hidden;

  &.is-expanded {
    max-height: 400px;
    // display: initial;
  }
}
