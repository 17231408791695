.badge {
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
  &.development {
    background-color: #b91c1c;
  }
  &.test {
    background-color: #034b45;
  }
}

.tooltip-badge {
  margin-top: 5px;
  margin-left: 20px;

  @screen tablet {
    margin-left: 50px;
  }
}

.piggav-navbar {
  transition: top 0.5s ease-in-out;
}

.user-is-scrolling-down:not(.navbar-has-focus) .piggav-navbar {
  top: -200px;
}

.user-is-scrolling-up .piggav-navbar {
  top: 0;
}
