@import "node_modules/styleguide/src/assets/sass/common.sass";

@mixin openingTransition($duration, $delay) {
  transition: opacity $duration ease-in-out, visibility 0ms linear $delay;
}

.shoppingcart {
  position: relative;

  &-line-items-container {
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 100; //Popover z-index, see tailwind config
    padding-bottom: 200px !important;
    opacity: 0;
    visibility: hidden;

    @include osg-mq-tablet-and-up() {
      position: absolute;
      top: 100%;
      left: unset;
      right: 0;
      width: 650px;
      height: unset;
      height: 600px;
      min-height: 300px;
      max-height: calc(100vh - (200px));
      box-shadow: 0px 4px 10px -2px #2c2c2c;
      overflow-y: hidden;
      padding-bottom: 115px !important;
    }

    .shoppingcart-line-items {
      height: 100%;
    }

    .shopping-cart-button-group {
      background-color: white;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }

  &.open > &-line-items-container {
    @include openingTransition(200ms, 0ms);
    opacity: 1;
    visibility: visible;
  }

  &.not-open > &-line-items-container {
    @include openingTransition(200ms, 200ms);
    opacity: 0;
    visibility: hidden;
  }

  @include osg-mq-tablet-and-up() {
    &::before {
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
      content: " ";
      background-color: #2c2c2c99; // Backdrop color
      z-index: 110;
      opacity: 0;
      top: -7000%;
    }

    &.open::before {
      transition: opacity 200ms ease-in-out;
      top: 0;
      opacity: 1;
    }

    &.not-open::before {
      transition: opacity 200ms ease-in-out, top 0ms linear 200ms;
      top: -7000%;
      opacity: 0;
    }
  }
}

.shoppingcart-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .num-of-items {
    position: absolute;
    font-size: 0.8em;
    top: 12px;
    text-align: center;
  }
}
